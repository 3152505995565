import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

import { AMapLoaderService } from './amap-loader.service';
import { AMapLoggerService } from './amap-logger.service';

const TAG = 'PluginLoader';

@Injectable({ providedIn: 'root' })
export class AMapPluginLoaderService {
    private readonly state = new Map<string, ReplaySubject<void>>();

    constructor(
        private readonly loader: AMapLoaderService,
        private readonly logger: AMapLoggerService
    ) {
    }

    /**
     * load any speified plugin
     */
    load(name: string | string[]): Observable<void> {
        const pKey = (Array.isArray(name) ? name.join(',') : name);
        const { state, logger, loader } = this;

        if (state.has(pKey)) {
            return state.get(pKey)!;
        }

        logger.d(TAG, 'loading plugin:', pKey, '...');
        return loader.load().pipe(
            switchMap(() => {
                const loading$ = new ReplaySubject<void>(1);

                AMap.plugin(name, () => {
                    logger.d(TAG, 'loading plugin:', pKey, 'COMPLETE');

                    loading$.next();
                    loading$.complete();
                });

                state.set(pKey, loading$);
                return loading$;
            })
        );
    }
}