import { Component, Input, OnInit } from "@angular/core";
import { ECElementEvent } from "echarts/types/dist/echarts";

import { Report } from '../../../application/service/backface/report';
import { AppService } from "../../../application/service/app.service";

@Component({
    selector: "gov-prjsumcard, [gov-prjsumcard]",
    templateUrl: "./gov.prjsumcard.component.html",
    styleUrls: ["./gov.prjsumcard.component.scss"]
})
export class GovPrjSumCardComponent implements OnInit {
    private _props = Prop.Of(this);

    @Input('projectsum')
    set projectsum(val: Report.IChartPieData) {
        const { _props, _props: { projectsum } } = this;
        if (projectsum == val) return;

        _props.projectsum = val;
        this.updateOption();
    };

    get projectsum(): Report.IChartPieData | undefined {
        return this._props.projectsum;
    }

    get option(): typeof Report.ReportTemplate.ChartPie {
        const { _props: props } = this;
        return props.option || (
            props.option = Report.ReportTemplate.ChartPie
        )
    }

    constructor(
        public app: AppService
    ) {
    }

    ngOnInit(): void {
    }

    updateOption() {
        const { projectsum: { totalname, totalsum, title, data, id } = {} } = this;
        const { option, option: { graphic, series: [series] } } = this;
        option.resetmerge();

        graphic.style.text = `${totalname}\n${totalsum}`;
        series.name = title;
        series.data = data;
        series.id = id
    }

    onClickCharBar(params: ECElementEvent): void {
        if (params && this.projectsum?.trigger) {
            this.projectsum?.trigger({
                serialid: params.seriesId,
                dataindex: params.dataIndex
            });
        }
    }
}