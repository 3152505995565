import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { AuthService } from '../../application/service/auth.service';

@Component({
    templateUrl: "./resetpwd.component.html",
    styleUrls: ['./resetpwd.component.scss']
})
export class ResetpwdComponent implements OnInit, AfterViewInit {
    private readonly _props = Prop.Of(this);

    set status(val: false | Observable<boolean>) {
        this._props.status = val;
        if (_.isBoolean(val)) return;

        const { dialogRef } = this;
        const sub = val.subscribe({
            next(value) {
                tick(() => sub.unsubscribe());
                if (_.isBoolean(value) && value) {
                    dialogRef.close(value);
                }
            },
            error(error) {
                tick(() => sub.unsubscribe());
            }
        })
    }

    get status(): false | undefined | Observable<boolean> {
        return this._props.status;
    }

    constructor(
        public dialogRef: MatDialogRef<ResetpwdComponent>,
        public auth: AuthService
    ) {

    }

    ngOnInit() {
        const { auth: { me: { mobile = '' } = {}, form } } = this;

        form.reset();
        form.mobile = mobile;
    }

    ngAfterViewInit() {
    }

    static open(dialog: PuDialogService, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: ResetpwdComponent,
            injector: injector,
            maximized: false,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
        })
    }
}
