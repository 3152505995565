import { Component, AfterViewInit, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PuSchemaService } from '../../../utils/puzzle/pu.service/pu.schema.service';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';

@Component({
    selector: 'reportsupervise, [reportsupervise]',
    templateUrl: "./reportsupervise.component.html",
    styleUrls: ['./reportsupervise.component.scss'],
    providers: [PuSchemaService]
})
export class ReportSuperviseComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = false;
    readonly noinputtxt: boolean = true;

    @Input('toolbar')
    toolbar?: GovEditor.ISetBinder;

    constructor(
        public app: AppService,
        public es: PuSchemaService,
        public override router: ActivatedRoute
    ) {
        super(router, 'reportsupervise', {
            selectedYear: app.dict.currentYear
        });
    }

    ngOnInit() {
        this.toolbar?.bindsource(this);
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    protected override dosearch(changed: GovEditor.ToolBar.Changed, content: GovEditor.ToolBar.Content): void {
        const sub = this.es.update(content).subscribe({
            complete() {
                tick(() => sub?.unsubscribe())
            },
            next() {
                tick(() => sub?.unsubscribe())
            }
        });
    }
}
