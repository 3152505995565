import { map, Observable, ReplaySubject, zip } from "rxjs";
import { Injectable, NgZone } from "@angular/core";

import { NgxAMapCreaterService } from "./ngx-amap.creater.service";
import { NgxAMapPluginService } from "./ngx-amap.plugin.service";
import { AMapLoggerService } from "../amap-logger.service";
import { Getter } from "../../base/types";

const TAG = 'AMapToolBarCreater'

@Injectable()
export class NgxAMapToolBarCreaterService implements Getter<any> {
    private readonly toolBar$ = new ReplaySubject<AMap.ToolBar>(1);
    private toolBar?: AMap.ToolBar;

    constructor(
        private readonly creater: NgxAMapCreaterService,
        private readonly plugin: NgxAMapPluginService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    /**
     * 获取工具条
     */
    get(): Observable<AMap.ToolBar> {
        return this.toolBar$;
    }

    /**
     * 创建工具条 AMap.ToolBar
     */
    create(options: AMap.ToolBar.Options): Observable<AMap.ToolBar> {
        const { creater, plugin, logger, ngZone } = this;
        return zip(creater.get(), plugin.load('AMap.ToolBar')).pipe(
            map(
                ([amap]) => {
                    this.toolBar = ngZone.runOutsideAngular(
                        () => new AMap.ToolBar(options)
                    );

                    logger.d(TAG, 'new toolBar created.');
                    amap.addControl(this.toolBar);

                    this.toolBar$.next(this.toolBar);
                    this.toolBar$.complete();
                    return this.toolBar;
                }
            )
        );
    }

    /**
     * 销毁工具条
     */
    destroy(): void {
        const { creater, logger } = this;
        zip(creater.get(), this.get()).subscribe(
            ([m, toolBar]) => {
                m.removeControl(toolBar);
                logger.d(TAG, 'toolBar destroyed.');
                delete this.toolBar;
            }
        );
    }
}
