import { Injectable, NgZone } from '@angular/core';
import { map, Observable, ReplaySubject } from 'rxjs';

import { NgxAMapCreaterService } from './ngx-amap.creater.service';
import { AMapLoggerService } from '../amap-logger.service';
import { Getter } from '../../base/types';

const TAG = 'AMapMarker';

@Injectable()
export class NgxAMapMarkerService implements Getter<AMap.Marker> {
    private readonly marker$ = new ReplaySubject<AMap.Marker<any>>(1);
    private marker?: AMap.Marker<any>;

    constructor(
        private readonly amaps: NgxAMapCreaterService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    /**
     * 获取点标记
     */
    get(): Observable<AMap.Marker<any>> {
        return this.marker$.asObservable();
    }

    /**
     * 创建点标记 AMap.Marker
     * @param options 选项
     * @param addToMap 是否直接加进地图
     */
    create(options: AMap.Marker.Options, addToMap?: boolean): Observable<AMap.Marker<any>> {
        return this.amaps.get().pipe(
            map(
                m => {
                    if (addToMap) {
                        options.map = m;
                    }

                    this.marker = this.ngZone.runOutsideAngular(
                        () => new AMap.Marker(options)
                    );

                    this.logger.d(TAG, 'new marker created.');
                    this.marker$.next(this.marker);
                    this.marker$.complete();
                    return this.marker;
                }
            )
        );
    }

    /**
     * 销毁点标记
     */
    destroy(): void {
        this.get().subscribe(
            m => {
                m.setMap(null);
                this.logger.d(TAG, 'marker destroyed.');
                delete this.marker;
            }
        );
    }
}
