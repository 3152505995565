import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { NGX_AMAP_CONFIG, NgxAMapConfig } from './ngx-amap.config';
import { AMapLoggerService } from './amap-logger.service';

const OnAMapLoaded = '_NgxAMapAPILoader';
const OnAMapUIInit = 'initAMapUI';
const TAG = 'AMapLoader';

@Injectable({ providedIn: 'root' })
export class AMapLoaderService {
    private uiLoading$?: ReplaySubject<void>;
    private loading$?: ReplaySubject<void>;

    private readonly defaultUIVersion: string = '1.0.11';
    private readonly defaultProtocol: string = 'https';
    private readonly defaultVersion: string = '1.4.15';

    constructor(
        @Optional()
        @Inject(NGX_AMAP_CONFIG)
        private readonly config: NgxAMapConfig = {},
        private readonly logger: AMapLoggerService,
    ) {
    }

    load(): Observable<void> {
        if (this.loading$) {
            return this.loading$;
        }

        const { logger } = this;
        const script = document.createElement('script');
        const loading$ = (this.loading$ = new ReplaySubject());

        logger.d(TAG, 'loading AMap API ...');

        script.type = 'text/javascript';
        script.async = script.defer = true;
        script.src = this.getSrcFromConfig(OnAMapLoaded);

        script.onerror = ((err) => {
            logger.e('failed to load AMap API.');
            loading$.error(err);
        });

        (window as any)[OnAMapLoaded] = (() => {
            logger.d(TAG, 'loading AMap API COMPLETE');

            loading$.next();
            loading$.complete();
        });

        document.body.appendChild(script);
        return loading$;
    }

    loadUI(): Observable<void> {
        if (this.uiLoading$) {
            return this.uiLoading$;
        }

        const { logger } = this;
        const uiScript = document.createElement('script');
        const uiLoading$ = (this.uiLoading$ = new ReplaySubject());

        logger.d(TAG, 'loading AMap UI ...');

        uiScript.type = 'text/javascript';
        uiScript.async = uiScript.defer = true;
        uiScript.src = this.getUISrcFromConfig();

        uiScript.onerror = ((err) => {
            logger.e('failed to load AMap API.');
        });

        uiScript.onload = (() => {
            (window as any)[OnAMapUIInit]();
            logger.d(TAG, 'loading AMap UI COMPLETE');

            uiLoading$.next();
            uiLoading$.complete();
        });

        document.body.appendChild(uiScript);
        return uiLoading$;
    }

    private getSrcFromConfig(callbackName: string): string {
        const { defaultProtocol, defaultVersion } = this;
        const { config: { protocol = defaultProtocol }, } = this;
        const urlBase = `${protocol}://webapi.amap.com/maps`;

        const { config: { apiKey, apiVersion = defaultVersion } } = this;
        const queryParams = {
            callback: callbackName,
            v: apiVersion,
            key: apiKey,
        };

        const params = (Object.keys(queryParams) as (keyof typeof queryParams)[])
            .filter(((k) => queryParams[k] != null))
            .filter(((k) => {
                const p = queryParams[k];
                return (!Array.isArray(p) || (
                    Array.isArray(p) && p.length > 0
                ));
            }))
            .map(((k) => {
                // join arrays as comma seperated strings
                const p = queryParams[k];
                if (Array.isArray(p)) {
                    return {
                        value: p.join(','),
                        key: k,
                    };
                }

                return {
                    value: p,
                    key: k,
                };
            }))
            .map(({ key, value }) => (
                `${key}=${value}`
            ))
            .join('&');

        return `${urlBase}?${params}`;
    }

    private getUISrcFromConfig() {
        const { defaultProtocol, defaultUIVersion } = this;
        const { config: { protocol = defaultProtocol, uiVersion = defaultUIVersion } } = this;
        return `${protocol}://webapi.amap.com/ui/1.0/main-async.js?v=${uiVersion}`;
    }
}