import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import type { PuHttpService } from "./pu.http.service";
import { PuAlertService } from "./pu.alert.service";
import { Async } from "../../libs/async";

@Injectable({ providedIn: 'root' })
export class PuMsgService {
    private _props = Prop.Of<PuMsgService, {
        onAppAlert: EventEmitter<PuMsgService.IAlert>,
        onMeChanged: Async.Cache<{
            pre?: PuMsgService.IMe | undefined;
            cur?: PuMsgService.IMe;
        }>

        request: {
            any: EventEmitter<PuHttpService.Error>,
            each: {
                [P: string]: EventEmitter<PuHttpService.Error>
            }
        }
    }>(this, values => {
        values.request = {
            any: new EventEmitter(),
            each: {}
        }
    });

    constructor(
        private alerts: PuAlertService
    ) {
    }

    onRequestError(errKey?: string): Observable<PuHttpService.Error> {
        const { _props: { request: { any, each } } } = this;
        if (!errKey) return any;

        return each[errKey] || (
            each[errKey] = new EventEmitter()
        );
    }

    emitRequestError(err: PuHttpService.Error) {
        const { _props: { request: { any, each } } } = this;
        err.errkey && each[err.errkey]?.emit(err);
        any.emit(err);

        this.alerts.push({
            ...err,
            type: "danger",
            dismiss: 5000
        })
    }

    get onAppAlert(): Observable<PuMsgService.IAlert> {
        const { _props: props } = this;
        return (props.onAppAlert || (
            props.onAppAlert = new EventEmitter()
        ));
    }

    emitAppAlert(msg: PuMsgService.IAlert) {
        const { _props: { onAppAlert } } = this;
        onAppAlert?.emit(msg);
    }

    get onMeChanged(): Observable<{
        pre?: PuMsgService.IMe,
        cur?: PuMsgService.IMe,
    }> {
        const { _props: props } = this;
        return props.onMeChanged || (
            props.onMeChanged = new Async.Cache(
                { value: {} }, 1, false,
            )
        );
    }

    emitMeChanged(cur?: PuMsgService.IMe, pre?: PuMsgService.IMe) {
        const { _props: { onMeChanged } } = this;
        onMeChanged?.emit({ cur, pre });
    }
}

export namespace PuMsgService {
    export interface IAlert {
        type: "warning" | "success" | "info",
        dismiss: number,
        msg: string,
    }

    export interface IMe {
        token?: string,
        name?: string,
        id?: string,
    }
}