import { FormControl, FormGroup } from '@angular/forms';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PuTemplateService } from '../pu.template/pu.template';
import { Component, Directive, Input } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { Observable, Subscription } from 'rxjs';

import { PuSchemaService } from '../pu.service/pu.schema.service';

type IFormControl = Schema.Form.IFormControl;
type IFormGroup = Schema.Form.IFormGroup;
type IFielder = Schema.Form.IFielder;
type IStage = Schema.Form.IStage;
type IRecord = Schema.IRecord;
type IEntity = Schema.IEntity;
type ISchema = Schema.ISchema;
type IValue = Schema.IValue;

const { typeOf, Form: { fieldOf, optionOf, controlOf, formOf, bindeach }, Validate } = Schema;
const fielderSearcherKey = Prop.newSymbol('Filter').toString();

/**
    value vs schema mapping: the name just treat as display info, and will be ignore in below description


        {←-----------------value|flds--------------→{flds: [{
              ↓￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣￣|
            [***]: xvalue                               key: ***
                    ↑------value|flds-------------------flds: [{ }]
                    |------value|deps-------------------deps: [{}]
                    └------value|value------------------value: primitive | [{}]
        }                                           }]}


        xvalue                                      {flds: [{
        ***←---------------value|value-----------------→value: primitive
         ↑＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿↑
                                                    }]}


        xvalue                                      {flds: [{
        ***←---------------value|value-----------------→value: [{key: ***}]
         ↑＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿↑
                                                    }]}


        xvalue                                      {flds: [{
        {
            deps: {},                                   deps
            {key/value},                                flds
            value: ***←----value|value-----------------→value: primitive
                    ↑＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿↑
        }                                           }]}


        xvalue                                      {flds: [{
        {
            deps: {},                                   deps
            {key/value},                                flds
            value: ***←----value|value-----------------→value: [{key: ***}]
                    ↑＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿↑
        }                                           }]}


        xvalue                                      {flds: [{
        {                                                   deps?, flds?,
            value: {←---------------value|value-----------------→value: [{key: ***, value:***}]
                key:***                                                         ↑          ↑
                     ↑＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿|          |
                value: ***                                                                 |
                        ↑＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿ |
            }
        }                                           }]}
 */

@Component({
    templateUrl: "./pu.form.component.html",
    styleUrls: ["./pu.form.component.scss"],
    selector: "puForm, [puForm]",
    providers: [PuTemplateService],
    exportAs: "PuForm"
}) export class PuFormComponent {
    private _props = Prop.Of<PuFormComponent, {
        puform: {
            schema?: ISchema,
            record?: IRecord
        }
    }>(this, values => {
        values.puform = {}
    });

    public readonly fieldersearcher = Fielder.getHandler(fielderSearcherKey)!;
    public readonly tick = tick;
    public readonly _ = _;

    public readonly jsoneditoroptions: JsonEditorOptions = _.extend(
        new JsonEditorOptions(), {
        modes: ['code', 'text', 'tree', 'view'],
        //options.schema = schema,
        statusBar: false,
        mode: 'code'
    });

    @Input('puForm')
    get puform(): {
        schema?: ISchema,
        record?: IRecord,
    } {
        return this._props.puform;
    }

    set puform(val: {
        schema?: ISchema,
        record?: IRecord,
    }) {
        this._props.puform = val ?? {};
    }

    @Input('schema')
    get schema(): ISchema | undefined {
        const { _props: { puform } } = this;
        return puform.schema;
    }

    set schema(val: ISchema | undefined) {
        const { _props: { puform } } = this;
        puform.schema = val;
    }

    @Input('record')
    get record(): IRecord | undefined {
        const { _props: { puform } } = this;
        return puform.record;
    }

    set record(val: IRecord | undefined) {
        const { _props: { puform } } = this;
        this.formOf(puform.record = val);
    }

    @Input('readonly')
    get readonly(): boolean {
        return !!this._props.readonly;
    }

    set readonly(val: boolean | ((field: IFielder) => boolean)) {
        const { _props: props } = this;
        if (_.isFunction(val)) {
            props.isReadonly = val;
            props.readonly = false;
            return;
        }

        props.readonly = val;
    }

    @Input('invisible')
    get invisible(): ((field: IFielder) => boolean) | undefined {
        return this._props.isInvisible;
    }

    set invisible(val: ((field: IFielder) => boolean) | undefined) {
        this._props.isInvisible = val;
    }

    @Input('error')
    get error(): ((field: IFielder, value: any) => string | undefined) | undefined {
        return this._props.hasError;
    }

    set error(val: ((field: IFielder, value: any) => string | undefined)) {
        this._props.hasError = val;
    }

    @Input('managable')
    get managable(): boolean {
        const { _props: { managable } } = this;
        return !!managable;
    }

    set managable(val: boolean) {
        const { _props: props, _props: { managable } } = this;
        if (val == managable) return;
        props.managable = val;
    }

    get formGroup(): IFormGroup | undefined {
        return this.formOf(this.record);
    }

    constructor(
        public es: PuSchemaService
    ) {
        this.optionsCompareWith = this.optionsCompareWith.bind(this);
    }

    isReadonly(field: IFielder): boolean {
        const { _props: { isReadonly } } = this;
        return !!isReadonly?.(field);
    }

    isInvisible(field: IFielder): boolean {
        const { _props: { isInvisible } } = this;
        return !!isInvisible?.(field);
    }

    hasError(field: IFielder, value: any): string | undefined {
        const { _props: { hasError } } = this;
        return hasError?.(field, value);
    }

    isvalid(record: IRecord, schema: ISchema): boolean {
        const { readonly, es } = this;
        if (readonly) return true;

        return Validate.validate(record, schema, es);
    }

    initdeps(field: IFielder, sdep: ISchema): {} {
        const host = field.host as any, deps = (host.deps = host.deps || {});
        const dep = (deps[sdep.key] = deps[sdep.key] || {});
        dep.key = sdep.key;
        return dep;
    }

    optionsCompareWith(o1: any, o2: any): boolean {
        return o1?.id == o2?.id;
    }

    formOf(record?: IRecord): IFormGroup | undefined {
        let form = formOf(record);
        if (!record || (form?.$hoster == this)) return form;

        form = Object.setPrototypeOf({
            $hoster: this,
            $record: record,
            $schema: this.schema,
            get $isvalid(): boolean {
                const { $hoster, $record, $schema } = this;
                return $hoster.isvalid($record, $schema);
            },
            $rollback(): void {
                _.forEach(form!.controls, (control, key) => {
                    (control as IFormControl)?.rollback();
                })
            },
            $commit(): void {
                _.forEach(form!.controls, (control, key) => {
                    (control as IFormControl)?.commit();
                })
            }
        }, new FormGroup({}))

        if (!record.id) { form!.markAsDirty() }
        bindeach(record, form!);
        return form;
    }

    typeOf(schema?: ISchema) {
        return typeOf(schema);
    }

    optionOf(field: IFielder, opt: ISchema, schema: ISchema) {
        return optionOf(field, opt, schema);
    }

    fieldOf(value: IValue, schema: ISchema, stage: IStage): IFielder | undefined {
        return fieldOf(this.record, value, schema, stage, this.es);
    }

    controlOf(field: IFielder, schema: ISchema, form: IFormGroup): IFormControl {
        return controlOf(field, schema, form);
    }
}

type StatusMap<T extends object> = (
    WeakMap<T, [refs: number, parent?: T]>
)

enum SelectStatus {
    indeterminate = 0.5,
    nonselected = 0,
    selected = 1,
}

const emptySource: any[] = [];

@Directive({
    selector: '[pu-nested-tree]',
    exportAs: "PuNestedTree",
}) export class PuNestedTreeDirective<TNode extends { [k: string]: any } = {}> {
    private _props = Prop.Of<PuNestedTreeDirective<TNode>, {
        whenChecker?: (idx: number, node: any) => boolean,
        dataSource?: MatTreeNestedDataSource<any>,
        control?: NestedTreeControl<any, any>,
        statusmap?: StatusMap<TNode>,
        source?: TNode | TNode[],
        waitting?: Subscription
    }>(this);

    readonly indeterminate = SelectStatus.indeterminate;
    readonly nonselected = SelectStatus.nonselected;
    readonly selected = SelectStatus.selected;

    @Input('pu-nested-tree')
    set setting(val: {
        formControl?: FormControl,
        field: { value: TNode[] },
        source: TNode | TNode[],
        leafonly: boolean,
        schema: ISchema,
    }) {
        const { _props: props, setting: { schema, source, field } = {} } = this;
        const { field: vfield, source: vsource, schema: vschema } = val;

        if (_.has(vsource, 'loaded') && _.has(vsource, 'loader') && !(vsource as any)['loaded']) {
            const wait = (waitsource: TNode | TNode[]) => {
                const loader: Observable<any> = (waitsource as any)['loader'];
                props.waitting = loader.subscribe(() => {
                    props.control = props.whenChecker = props.statusmap = undefined;
                    props.dataSource = props.source = undefined;
                    tick(() => {
                        props.waitting?.unsubscribe();
                        props.waitting = undefined;
                    })
                })
            }

            if (vsource != source) {
                props.waitting?.unsubscribe();
                props.waitting = undefined;
                wait(vsource);
            } else if (!props.waitting) {
                wait(vsource)
            }
        }

        if (vschema != schema || vsource != source) {
            props.control = props.whenChecker = props.statusmap = undefined;
            props.dataSource = props.source = undefined;
        }

        if (vfield != field) {
            props.control = props.whenChecker = props.statusmap = undefined;
        }

        props.setting = val;
    };

    get setting(): {
        formControl?: FormControl,
        field: { value: TNode[] },
        source: TNode | TNode[],
        leafonly: boolean,
        schema: ISchema,
    } | undefined {
        return this._props.setting;
    }

    get whenChecker(): (idx: number, node: TNode) => boolean {
        const { _props: props } = this, _this = this;
        return props.whenChecker = props.whenChecker || ((_: number, node: TNode) => {
            return (node[_this.setting?.schema?.children ?? '']?.length ?? 0) > 0
        });
    }

    get dataSource(): MatTreeNestedDataSource<TNode> {
        const { _props: props, source, setting: { schema: { children = '' } = {} } = {} } = this;

        return props.dataSource = (props.dataSource || (
            props.dataSource = new MatTreeNestedDataSource<TNode>(),
            props.dataSource.data = _.isArray(source) ? source : (
                source && children ? source[children] : []
            ),
            props.dataSource
        ));
    }

    get control(): NestedTreeControl<TNode> {
        const { _props: props } = this, _this = this;
        return props.control = (props.control || (
            new NestedTreeControl<TNode>(node => (
                node[_this.setting?.schema?.children ?? '']
            ))
        ));
    }

    get source(): TNode | TNode[] {
        const { _props: { source: matSource } } = this;
        if (matSource) return matSource;

        const { _props: props, setting: { source } = {} } = this;
        const s = source ?? (emptySource as TNode[]);
        return props.source = (_.isArray(s) ? s : [s]);
    }

    constructor(
    ) {
    }

    select(node: TNode) {
        this.update(node, true);
    }

    deselect(node: TNode) {
        this.update(node, false);
    }

    isselected(node: TNode): boolean {
        return this.status(node) == SelectStatus.selected;
    }

    isnonselected(node: TNode): boolean {
        return this.status(node) == SelectStatus.nonselected;
    }

    isindeterminate(node: TNode): boolean {
        return this.status(node) == SelectStatus.indeterminate;
    }

    status(node: TNode): SelectStatus {
        const { setting: { schema: { children = '' } = {} } = {} } = this;
        if (!children) return SelectStatus.nonselected;

        // update the status map firstly;
        const statusmap = this.update();
        const slen = statusmap?.get(node)?.[0] ?? 0;
        const clen = node[children]?.length ?? 0;

        if (clen <= 0) {
            if (slen > 0) return SelectStatus.selected;
            return SelectStatus.nonselected;
        }

        if (slen == 0) return SelectStatus.nonselected;
        if (clen != slen) return SelectStatus.indeterminate;
        return SelectStatus.selected;
    }

    update(node?: TNode, selected?: boolean): StatusMap<TNode> | undefined {
        const { setting: { field } = {} } = this;
        if (!field) return;

        const { _props: props, source, setting: { schema: { children = '' } = {} } = {} } = this;
        const build = (node: TNode | TNode[], parent?: TNode, statusmap?: StatusMap<TNode>): [refs: number, map: StatusMap<TNode>] | undefined => {
            if (!node) return;

            statusmap = statusmap || new WeakMap();

            if (!_.isArray(node)) {
                const clen = node[children]?.length ?? 0;
                let selcount = 0;

                if (clen <= 0) {
                    // leaf node
                    const seled = field.value?.findIndex((v) => v['id'] == node['id']) >= 0;
                    selcount = seled ? SelectStatus.selected : SelectStatus.nonselected;
                } else {
                    // non-leaf node
                    selcount = build(node[children], node, statusmap)?.[0] ?? 0;
                }

                statusmap.set(node, [selcount, parent]);
                return [(selcount <= 0 ? SelectStatus.nonselected : (selcount > 0 && selcount < clen ? SelectStatus.indeterminate : SelectStatus.selected)), statusmap];
            }

            const refval = _.reduce(node, (refs, n): number => {
                return refs + (build(n, parent, statusmap)?.[0] ?? 0);
            }, 0);

            return [refval, statusmap];
        }

        const statusmap: StatusMap<TNode> | undefined = (props.statusmap = props.statusmap || (children && source && field && (
            build(_.isArray(source) ? source : (source && children ? source[children] : null))?.[1]
        ) || undefined))

        if (!node || !statusmap) {
            return statusmap;
        }

        const targetcode = (selected = !!selected) ? SelectStatus.selected : SelectStatus.nonselected;
        if (targetcode == this.status(node)) return statusmap;

        // mark and collect decendents
        const update = (node: TNode | TNode[], starting: boolean = true, colls?: TNode[]): TNode[] | undefined => {
            colls = (colls || []);

            if (starting) {
                // start point
                if (_.isArray(node)) {
                    console.assert(!_.isArray(node));
                    return;
                }

                const clen = node[children]?.length ?? 0, nbag = statusmap.get(node);
                for (let _node: TNode | undefined = node, delta = ((selected ? Math.max(clen, 1) : 0) - (nbag?.[0] ?? 0)); _node;) {
                    const statusbag = statusmap.get(_node);
                    const prestatus = this.status(_node);
                    statusbag && (statusbag[0] += delta);

                    const pststatus = this.status(_node);
                    if (prestatus == pststatus) break;

                    delta = pststatus - prestatus;
                    _node = statusbag?.[1];
                }

                if (clen <= 0) return colls.push(node), colls;
                return update(node[children], false, colls);
            }

            if (!_.isArray(node)) {
                const clen = node[children]?.length ?? 0;
                const nbag = statusmap.get(node);

                if (clen <= 0) {
                    nbag && (nbag[0] = targetcode);
                    return colls.push(node), colls;
                }

                nbag && (nbag[0] = selected ? clen : 0);
                return update(node[children], false, colls);
            }

            return _.transform(node, (colls, n) => {
                if (this.status(n) == targetcode) return;
                update(n, false, colls);
            }, colls);
        }

        const { setting: { formControl } = {} } = this;
        const colls = update(node) || [];
        colls.forEach(n => {
            const { key, id } = n;
            if (selected) {
                const v = { key, id };
                field.value.push(v as any);
            } else {
                const v = field.value.find(v => v['id'] == n['id']);
                v && field.value.remove(v);
            }
        })

        if (colls.length > 0) {
            formControl?.markAsDirty();
        }

        return statusmap;
    }
};
