
import { ContextPadConfig, ContextPadEntries, ContextPadEntry } from 'bpmn-js/lib/features/context-pad/ContextPadProvider';
import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider';
import AppendPreview from 'bpmn-js/lib/features/append-preview/AppendPreview';
import PaletteProvider from 'bpmn-js/lib/features/palette/PaletteProvider';
import ElementFactory from 'bpmn-js/lib/features/modeling/ElementFactory';
import { ModdleElement, ModuleDeclaration } from 'bpmn-js/lib/BaseViewer';
import ContextPadProviderModule from 'bpmn-js/lib/features/context-pad';
import { getBusinessObject, getDi } from 'bpmn-js/lib/util/ModelUtil';
import SpaceTool from 'bpmn-js/lib/features/space-tool/BpmnSpaceTool';
import BpmnFactory from 'bpmn-js/lib/features/modeling/BpmnFactory';
import PaletteProviderModule from 'bpmn-js/lib/features/palette';
import Modeling from 'bpmn-js/lib/features/modeling/Modeling';
import { Shape, Element } from "bpmn-js/lib/model/Types";
import ModelingModule from 'bpmn-js/lib/features/modeling';

import { PaletteEntry, PaletteEntriesCallback } from 'diagram-js/lib/features/palette/PaletteProvider';
import { ContextPadTarget } from "diagram-js/lib/features/context-pad/ContextPad";
import GlobalConnect from 'diagram-js/lib/features/global-connect/GlobalConnect';
import { PaletteEntries } from 'diagram-js/lib/features/palette/Palette';
import ContextPad from "diagram-js/lib/features/context-pad/ContextPad";
import LassoTool from 'diagram-js/lib/features/lasso-tool/LassoTool';
import AutoPlace from 'diagram-js/lib/features/auto-place/AutoPlace';
import PopupMenu from 'diagram-js/lib/features/popup-menu/PopupMenu';
import HandTool from 'diagram-js/lib/features/hand-tool/HandTool';
import BPMNPalette from 'diagram-js/lib/features/palette/Palette';
import Translate from "diagram-js/lib/i18n/translate/translate";
import Connect from 'diagram-js/lib/features/connect/Connect';
import Create from 'diagram-js/lib/features/create/Create';
import Rules from 'diagram-js/lib/features/rules/Rules';
import EventBus from 'diagram-js/lib/core/EventBus.js';
import Canvas from 'diagram-js/lib/core/Canvas';

import { KeyValue as svgKeyValue } from 'tiny-svg';
import { Injector } from 'didi/dist/index';
import { assign } from 'min-dash';

import { GovDiagramDirective } from './diagram.directive';

/**
    Shape: {
        [k:string]:any;

        businessObject?: any;
        id: string;
 
        incoming: Connection[];
        outgoing: Connection[];
        parent?: Element;
        labels: Label[];
        label?: Label;

        height: number;
        width: number;
        x: number;
        y: number;

        children: Element[];
        attachers: Shape[];
        isFrame?: boolean;
        host?: Shape;
    }
 */


interface IListener {
    (event: PointerEvent, target: ContextPadTarget, autoActivate?: boolean): void;
}

interface IAction {
    dragstart?: IListener;
    click?: IListener;
}

interface ICreator {
    (entry: IEntry, autoPlace?: AutoPlace): IListener
}

type IEntry = Palette.IEntry;

type IEntries = Record<string, IEntry>

enum gstatus {
    notuse = 0x00,
    grouped = 0x01,
    used = 0x02,
}

enum Available {
    viewer = 0x01,
    modeler = 0x02,
    all = 0x03
}

const GroupFilter: {
    [group: string]: gstatus | {
        [key: string]: gstatus
    }
} = {
    'lane-insert-above': gstatus.notuse,
    'lane-insert-below': gstatus.notuse,
    'collaboration': gstatus.notuse,
    'data-object': gstatus.notuse,
    'lane-divide': gstatus.notuse,
    'data-store': gstatus.notuse,
    'artifact': gstatus.notuse,
    'activity': gstatus.notuse,
    'gateway': gstatus.notuse,
    'event': gstatus.notuse,
    'model': gstatus.notuse,

    'edit': {
        'replace': gstatus.notuse,
    },

    'tools': {
        'space-tool': gstatus.notuse
    }
}

export class Entries {
    constructor(
        public diagram: GovDiagramDirective
    ) {
        _.forEach(this.entries, (entry, key) => {
            entry.entity = entry.entity || key;
        })
    }

    contextPadEntries(shape: Shape, entries: ContextPadEntries, host: ContextPadHook): ContextPadEntries {
        entries = this.filterEntries(entries);
        const { diagram: { readonly } } = this;
        const useon = readonly ?
            Available.viewer :
            Available.modeler;

        _.forEach(this.entries, (entry, key) => {
            const { apply, padfor, available = Available.modeler } = entry;

            if (apply && apply != 'ContextPad') {
                return;
            }

            if (!(available & useon)) {
                return;
            }

            if (padfor && !padfor.find(k => (
                shape?.businessObject?.$instanceOf(k)
            ))) {
                return;
            }

            entries[key] = this.createContextPad(entry, host);
        })

        return entries;
    }

    paletteEntries(entries: PaletteEntries, host: PaletteHook): PaletteEntries {
        entries = this.filterEntries(entries);
        _.forEach(this.entries, (entry, key) => {
            const { apply } = entry;

            if (apply && apply != 'Palette') {
                return;
            }

            entries[key] = this.createPalette(entry, host);
        })

        return entries;
    }

    createContextPad(entry: IEntry, host: ContextPadHook): ContextPadEntry {
        const { config: { autoPlace: isAutoPlace } = {}, autoPlace, translate } = host;
        const { type, group, className, title } = entry;
        const shortType = type?.replace(/^bpmn:/i, '');
        const { creator, action } = entry;

        const _action = action || ((() => {
            const listener = creator?.(entry);
            const aplistener = creator?.(entry, isAutoPlace ? autoPlace : undefined);
            return <any>{ click: aplistener, dragstart: listener };
        })())

        return {
            action: _action, className, group,
            title: title || translate('Create {type}', {
                type: shortType ?? ''
            }),
        }
    }

    createPalette(entry: IEntry, host: PaletteHook): PaletteEntry {
        const { type, group, className, title } = entry;
        const shortType = type?.replace(/^bpmn:/, '');
        const { creator, action } = entry;
        const { translate } = host;

        const _action = action || ((() => {
            const listener = creator?.(entry);
            return { click: listener, dragstart: listener }
        })())

        return {
            action: (_action as any), className, group,
            title: title || translate('Create {type}', {
                type: shortType ?? ''
            })
        };
    }

    shapeCreator(entry: IEntry, autoPlace?: AutoPlace) {
        return (event: PointerEvent, target: ContextPadTarget, autoActivate?: boolean): void => {
            const { diagram: { create, elementFactory: ef, bpmnFactory: bf }, entries } = this;
            const id = Prop.uuid(undefined, '', false);
            const { type, options = {} } = entry;

            const setting: Palette.IBizGov = {
                type: entry.entity,
                id: `sid-${id}`
            }

            if (entry != entries['gov.workitem']) {
                setting.entity = id;
            }

            const newshape = ef.createShape({
                businessObject: type && bf.create(type, setting),
                type, ...options
            });

            if (options) {
                const { isExpanded } = options;
                const di = getDi(newshape as any);
                di.isExpanded = isExpanded;
            }

            if (autoPlace) {
                autoPlace.append(target as Shape, newshape);
            } else {
                create.start(event, newshape, target);
            }
        }
    }

    settingAction(command: string, event: PointerEvent, target: ContextPadTarget) {
        const { diagram, diagram: { command: _command } } = this;
        _command.emit({ diagram, target, event, command })
    }

    entries: IEntries = {
        ['gov.stage']: {
            creator: this.shapeCreator.bind(this),
            className: 'bpmn-icon-task',
            group: 'gov-activity',
            type: 'bpmn:Task',
            title: '工作阶段',
        },

        ['gov.workset']: {
            creator: this.shapeCreator.bind(this),
            className: 'bpmn-icon-subprocess-expanded',
            type: "bpmn:SubProcess",
            group: 'gov-activity',
            title: '工作集',
            options: {
                isExpanded: true,
            },
            attr: {
                'stroke': 'rgb(34, 36, 42)',
                'stroke-linejoin': 'round',
                'stroke-linecap': 'unset',
                'stroke-dasharray': '10',
                'pointer-events': 'none',
                'stroke-width': '1.5px',
                'fill': 'none',
            }
        },

        ['gov.workitem']: {
            creator: this.shapeCreator.bind(this),
            className: 'bpmn-icon-service-task',
            type: 'bpmn:ServiceTask',
            group: 'gov-activity',
            editable: false,
            title: '工作项',
        },

        ['gov.setting']: {
            action: {
                click: this.settingAction.bind(this, 'gov.setting')
            },
            padfor: ['bpmn:Task', 'bpmn:ServiceTask'],
            className: 'bpmn-icon-service',
            apply: 'ContextPad',
            group: 'edit',
            title: '设置',
        },

        ['gov.history']: {
            action: {
                click: this.settingAction.bind(this, 'gov.history')
            },
            padfor: ['bpmn:ServiceTask'],
            className: 'bpmn-icon-script-task',
            available: Available.viewer,
            apply: 'ContextPad',
            title: '历史记录',
            group: 'edit',
        }
    };

    private filterEntries<
        TEntries extends Record<string, {
            separator?: boolean;
            group?: string;
        }>
    >(entries: TEntries): TEntries {
        const { diagram: { readonly } } = this;
        if (readonly) return {} as TEntries;

        const _grouped: {
            [group: string]: {
                count: number,
                groupentrykey: string,
            }
        } = {};

        // remove notused entries, and record the group status
        _.forEach(entries, ({ group, separator }, key) => {
            if (!group) return;

            const groupspec = GroupFilter[group];
            if (groupspec === gstatus.notuse || (
                _.isObject(groupspec) && (
                    groupspec[key] === gstatus.notuse
                )
            )) {
                delete entries[key];
                return;
            }

            const groupstat = (_grouped[group] || (
                _grouped[group] = {
                    groupentrykey: '', count: 0
                }
            ));

            if (separator) {
                groupstat.groupentrykey = key;
                return;
            }

            groupstat.count += 1;
        })

        // check group entry and add/remove if need
        _.forEach(_grouped, ({ groupentrykey, count }, group) => {
            if (count == 0) {
                // no entries in the group
                delete entries[groupentrykey];
                return;
            }

            if (!groupentrykey && false) {
                // no group entry defined for the group
                assign(entries, {
                    [`${group}-separator`]: {
                        group: `${group}`,
                        separator: true
                    }
                })
            }
        })

        return entries
    }
}

export class ContextPadHook extends ContextPadProvider {
    get entries(): Entries {
        const { diagram: { entries } } = this;
        return entries;
    }

    constructor(
        public diagram: GovDiagramDirective,
        public autoPlace: AutoPlace,
        public config: ContextPadConfig,
        public injector: Injector,
        public eventBus: EventBus,
        public contextPad: ContextPad,
        public modeling: Modeling,
        public elementFactory: ElementFactory,
        public connect: Connect,
        public create: Create,
        public popupMenu: PopupMenu,
        public canvas: Canvas,
        public rules: Rules,
        public translate: typeof Translate,
        public appendPreview: AppendPreview
    ) {
        super(config, injector, eventBus, contextPad, modeling, elementFactory, connect, create, popupMenu, canvas, rules, translate, appendPreview);
        contextPad.registerProvider(this as any);
    }

    override getContextPadEntries(shape: Shape): ContextPadEntries {
        const superentries = super.getContextPadEntries(shape);
        return this.entries.contextPadEntries(shape, superentries, this);
    }

    static Module: ModuleDeclaration = {
        __depends__: [
            ...ContextPadProviderModule.__depends__,
            ModelingModule
        ],
        contextPadProvider: ['type', ContextPadHook],
        __init__: ['contextPadProvider']
    };

    static override $inject = [
        'govDiagram',
        'autoPlace',
        ...ContextPadProvider.$inject
    ];
}

export class PaletteHook extends PaletteProvider {
    get entries(): Entries {
        const { diagram: { entries } } = this;
        return entries;
    }

    get autoPlace(): AutoPlace | undefined {
        return;
    }

    constructor(
        public diagram: GovDiagramDirective,
        public palette: BPMNPalette,
        public create: Create,
        public elementFactory: ElementFactory,
        public spaceTool: SpaceTool,
        public lassoTool: LassoTool,
        public handTool: HandTool,
        public globalConnect: GlobalConnect,
        public translate: typeof Translate
    ) {
        super(palette, create, elementFactory, spaceTool, lassoTool, handTool, globalConnect, translate);
        palette.registerProvider(this);
    }

    override getPaletteEntries(): PaletteEntries {
        const superentries = super.getPaletteEntries();
        return this.entries.paletteEntries(superentries, this);
    }

    static Module: ModuleDeclaration = {
        ...PaletteProviderModule,
        paletteProvider: ['type', PaletteHook],
        __init__: ['paletteProvider']
    };

    static override $inject = [
        'govDiagram',
        ...PaletteProvider.$inject
    ];
}

export namespace Palette {
    export type EntryApply = 'Palette' | 'ContextPad';

    export interface IEntry {
        creator?: ICreator;
        action?: IAction;

        className?: string;
        imageUrl?: string;
        title?: string;
        html?: string;

        separator?: boolean;
        group?: string;

        options?: Partial<Shape>;
        attr?: svgKeyValue;
        type?: string;

        available?: Available,
        editable?: boolean;
        apply?: EntryApply;
        entity?: string;
        padfor?: string[];
    }

    export interface IBizGov {
        extensionElements?: {
            get(key: 'values' | 'valueRef' | 'extensionAttributeDefinition'): any;
        };
        entity?: string,
        type?: string;
        key?: string;
        name?: string;
        id: string;
    }

    export interface IBizWorkitem extends IBizGov {
        critical?: boolean
    }

    export interface IBizDepStage {
        entity?: string;
        type?: string;
    }

    export interface IBizStage extends IBizGov {
        depstages?: IBizDepStage[]
    }

    export function getBizObject<T extends IBizGov = IBizGov>(element?: Element | ModdleElement): T | undefined {
        return getBusinessObject(element);
    }
}