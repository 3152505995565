import { map, Observable, ReplaySubject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';

import { AMapLoaderService } from '../amap-loader.service';
import { AMapLoggerService } from '../amap-logger.service';
import { Getter } from '../../base/types';

const TAG = 'AMap';

@Injectable()
export class NgxAMapCreaterService implements Getter<AMap.Map> {
    private readonly map$ = new ReplaySubject<AMap.Map>();
    private map?: AMap.Map;

    constructor(
        private readonly loader: AMapLoaderService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    /**
     * 获取地图对象 AMap.Map
     */
    get(): Observable<AMap.Map> {
        return this.map$;
    }

    /**
     * 创建地图对象 AMap.Map
     * @param container 地图容器的DOM元素
     * @param options 选项
     */
    create(container: HTMLElement, options: AMap.Map.Options): Observable<AMap.Map> {
        const { ngZone, logger, map$ } = this;

        return this.loader.load().pipe(
            map(() => {
                this.map = ngZone.runOutsideAngular(
                    () => new AMap.Map(container, options)
                );

                logger.d(TAG, 'new map created.');
                map$.next(this.map);
                map$.complete();
                return this.map;
            })
        );
    }

    /**
     * 销毁地图对象
     */
    destroy(): void {
        const { logger } = this;

        this.get().subscribe(
            (amap: AMap.Map) => {
                amap.destroy();
                logger.d(TAG, 'map destroyed.');
                delete this.map;
            }
        );
    }
}
