import { map, Observable, ReplaySubject, zip } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';

import { NgxAMapCreaterService } from './ngx-amap.creater.service';
import { AMapLoggerService } from '../amap-logger.service';
import { Getter } from '../../base/types';

const TAG = 'AMapInfoWindow';

@Injectable()
export class NgxAMapInfoWindowService implements Getter<AMap.InfoWindow> {
    private infoWindow$ = new ReplaySubject<AMap.InfoWindow<any>>(1);
    private infoWindow?: AMap.InfoWindow;
    private map?: AMap.Map;

    constructor(
        private readonly amaps: NgxAMapCreaterService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    /**
     * 获取信息窗体
     */
    get(): Observable<AMap.InfoWindow<any>> {
        return this.infoWindow$;
    }

    /**
     * 创建 AMap.InfoWindow
     * @param options 选项
     */
    create(options: AMap.InfoWindow.Options): Observable<AMap.InfoWindow<any>> {
        return this.amaps.get().pipe(
            map(
                m => {
                    this.map = m;
                    this.infoWindow = this.ngZone.runOutsideAngular(
                        () => new AMap.InfoWindow(options)
                    );

                    this.logger.d(TAG, 'new InfoWindow created.');
                    this.infoWindow$.next(this.infoWindow);
                    this.infoWindow$.complete();
                    return this.infoWindow;
                }
            )
        );
    }

    /**
     * 销毁
     */
    destroy(): void {
        this.get().subscribe(
            w => {
                w.close();
                this.logger.d(TAG, 'InfoWindow destroyed.');
                delete this.infoWindow;
                delete this.map;
            }
        );
    }

    /**
     * 打开
     */
    open(position?: AMap.LocationValue): void {
        this.get().subscribe(
            w => w.open(this.map!, position)
        );
    }

    /**
     * 在覆盖物上打开窗体
     */
    openOnMark(marker: Observable<AMap.Marker | AMap.Text>): void {
        zip(marker, this.get()).subscribe(
            ([m, w]) => {
                w.open(this.map!, m.getPosition());
            }
        );
    }

    /**
     * 关闭
     */
    close(): void {
        this.get().subscribe(
            w => w.close()
        );
    }
}
