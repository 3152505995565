import { Component, Input, OnDestroy, TemplateRef } from "@angular/core";

import { AppService } from "../../../application/service/app.service";
import { GovEditor } from "../model/form.editting";
import { Differ } from "../../libs/differ";

@Component({
    selector: "gov-toolbar, [gov-toolbar]",
    templateUrl: "./gov.toolbar.component.html",
    styleUrls: ["./gov.toolbar.component.scss"],
    exportAs: "GovToolbar"
})
export class GovToolbarComponent implements GovEditor.ISetBinder, OnDestroy {
    private _props = Prop.Of<GovToolbarComponent, {
        searchcontent: Record<string, any>,
    }>(this, values => {
        values.searchcontent = {}
    });

    get differ(): Differ<{
        onreload: Differ.Event
    }> {
        const { _props: props } = this;
        return props.differ || (
            props.differ = Differ.create()
        )
    }

    @Input('toolbar')
    get toolbar(): GovEditor.IToolbar | undefined {
        return this._props.toolbar;
    }

    set toolbar(val: GovEditor.IToolbar | undefined) {
        const { _props: props } = this;
        if (props.toolbar == val) return;
        props.toolbar = val;

        this.reload();
        this.differ.extend({
            onreload: Differ.Event.create(
                val?.reloader, this.reload, this
            )
        })
    }

    get filterdata(): GovEditor.IFilterData[] | undefined {
        return this.toolbar?.filterdata;
    }

    get noinputtxt(): boolean {
        return !!this.toolbar?.noinputtxt;
    }

    get noselectedProcess(): boolean {
        return !!this.toolbar?.noselectedProcess;
    }

    get noselectedYear(): boolean {
        return !!this.toolbar?.noselectedYear;
    }

    get inputtxt(): string {
        const { _props: { searchcontent } } = this;
        return searchcontent['*'] as string;
    }

    set inputtxt(val: string) {
        const { _props: { searchcontent } } = this;
        searchcontent['*'] = val;
        this.search();
    }

    get optionvalue(): GovEditor.IFilterData | undefined {
        return this._props.optionvalue;
    }

    set optionvalue(val: GovEditor.IFilterData | undefined) {
        const { _props: props, _props: { optionvalue, searchcontent } } = this;
        if (optionvalue == val) return;

        if (optionvalue?.key && optionvalue?.key != "") {
            // remove previous option
            delete searchcontent[optionvalue.key];
        }

        props.optionvalue = val;
        if (val?.key && val?.key != "") {
            // assign selected option
            searchcontent[val.key] = val.value;
        }

        this.search();
    }

    constructor(
        public app: AppService
    ) {
    }

    ngOnDestroy(): void {
        const { _props: { differ } } = this;
        differ?.clear();
    }

    reload() {
        const { _props: props, toolbar: { searcher, filterdata, noinputtxt } = {} } = this;

        // initialize searchcontent/optionvalue from toolbar
        const searchcontent: typeof props.searchcontent = _.isString(searcher) ? {
            '*': searcher
        } : {
            ...(searcher ?? {})
        };

        props.searchcontent = searchcontent;
        delete props.optionvalue;

        _.forEach(_.keys(searchcontent), (key) => {
            const fdata = filterdata?.find(fd => (
                fd.key == key && fd.value == searchcontent[key]
            ));

            fdata && (props.optionvalue = fdata);
            if (!fdata && (key != '*' || noinputtxt)) {
                delete searchcontent[key];
            }
        })

        if (!props.optionvalue) {
            props.optionvalue = filterdata?.find(p => p.isdefault);
        }

        this.search();
    }

    bindsource(val: GovEditor.IToolbar): void {
        this.toolbar = val;
    }

    search(): void {
        const { _props: { searchcontent }, toolbar } = this;
        toolbar && _.hasIn(toolbar, 'searcher') && (
            toolbar.searcher = searchcontent
        );
    }

    toTemplateRef(val: any): TemplateRef<any> | undefined {
        return val instanceof TemplateRef ? val : undefined;
    }

    isFunction(val: any): val is Function {
        return typeof val == "function";
    }

    advfilterex(): void {
        const { toolbar: { advfilter } = {} } = this;
        _.isFunction(advfilter) && advfilter();
    }
}