import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy, Type } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { share } from "rxjs/operators";
import { A } from "ts-toolbelt";

import { Org as TOrg, Prj as TPrj, Exec as TExec } from './types';
import { Editor } from "../../../utils/libs/editor";
import { Async } from "../../../utils/libs/async";

interface IEntities {
    [K: A.Key]: { // entity name vs the request payload
        [P: A.Key]: any
    }
}

interface IDefinitions {
    [K: A.Key]: Editor.TEntity // entity name vs the request parameters
}

interface ISection {
    name: string; // section name
    definitions: IDefinitions;
    entities: IEntities
}

const _EntityInits = _.extend(<(() => void)[]>[], {
    initialized: false
});

function EntityInitialize() {
    if (_EntityInits["initialized"]) return;

    _EntityInits.forEach((init) => init());
    _EntityInits["initialized"] = true;
}

const Setions: {
    [section: string]: ISection
} = {
    org: {
        name: "org",
        definitions: {
            priviledge: {},
            dept: {},
            peoples: {},
            role: {},
            actors: {},
            inspectors: {}
        },
        entities: {
            priviledge: {},
            dept: {},
            peoples: {},
            role: {},
            actors: {},
            inspectors: {}
        }
    },
    prj: {
        name: "prj",
        definitions: {
            checkpoints: {},
            workitems: {},
            worksets: {},
            stages: {},
            projecttypes: {},
            plans: {},
            project: {}
        },
        entities: {
            checkpoints: {},
            workitems: {},
            worksets: {},
            stages: {},
            projecttypes: {},
            plans: {},
            project: {}
        }
    },
    exec: {
        name: "exec",
        definitions: {
            results: {}
        },
        entities: {
            results: {}
        }
    }
}

namespace Net {

    interface IParam {
        headers?: HttpHeaders,
        url: string,
    }

    class Accessor<IValue = any> {
        private $props = Prop.Of<Accessor<IValue>, {
            cache: Observable<IValue>,
            definition: Editor.TEntity,
            httpClient: HttpClient,
            entity: string,
            values: any,
            url: string,
        }>(this);

        constructor(
            httpClient: HttpClient,
            definition: Editor.TEntity,
            entity: string,
        ) {
            const { $props: props } = this;

            props.url = `/entity/${entity}/retrieve`;
            props.definition = definition || {};
            props.httpClient = httpClient;
            props.entity = entity;
        }

        refetch() {
            const { $props: props } = this;
            const { definition } = props;
            definition!.loaded = false;
            delete props.values;
        }

        get(body: any) {
            return this.bysync(body);
        }

        private bysync(body: any) {
            const { $props: { values } } = this;
            if (values) return values;

            const { $props: { definition, url } } = this;
            const request = new XMLHttpRequest();
            request.open('POST', url!, false);

            request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            request.setRequestHeader('Accept', 'application/json');
            request.send(body);

            definition!.loaded = true;
            const { $props: props } = this;
            return (props.values = request.httpResponse?.body);
        }

        private byasync(body: any) {
            const { $props: props } = this;
            if (props.cache) return props.cache;

            const { $props: { httpClient, url } } = this;
            return props.cache = new Async.Cache<IValue, IParam>({
                query: (p?: IParam): Observable<IValue> => {
                    return httpClient!.post<IValue>(p!.url, body);
                }, param: { url: url! }
            }, 1, false);
        }
    }

    export class Handler implements Editor.TSettingHandler {
        private $props = Prop.Of<Handler, {
            definitions: IDefinitions,
            httpClient: HttpClient,
            entities: IEntities,
            section: string,
            settings: Record<A.Key, Accessor | any>,
            accessors: Record<A.Key, Accessor>,
        }>(this, values => {
            values.accessors = {};
            values.settings = {};
        });

        constructor(
            protected app: any,
            protected httpClient: HttpClient,
            protected section?: ISection,
        ) {
            const { $props: props } = this;
            props.httpClient = httpClient;
            if (!section) return;

            props.section = section.name;
            props.entities = section.entities;
            props.definitions = section.definitions;

            _.forEach(props.entities, (body, key) => {
                Object.defineProperty(this, key, {
                    enumerable: false, configurable: false,
                    get(this: Handler) {
                        EntityInitialize();

                        const definition = section.definitions[key];
                        const { settings, accessors, httpClient } = props;

                        settings[key] = (settings[key] || (accessors[key] || (accessors[key] = (
                            new Accessor<any>(
                                httpClient!, definition, definition?.entity || key
                            )
                        ))));

                        if (settings[key] instanceof Accessor) {
                            return settings[key].get(body);
                        }

                        return settings[key];
                    },
                    set(this: Handler, val: any) {
                        const { settings } = props;
                        settings[key] = val;
                    }
                })
            })
        }

        $mayRefetch(key: A.Key) {
            const { $props: { definitions, settings, accessors } } = this;
            if (!definitions[key]?.["refetch"]) return;
            accessors[key]?.refetch?.();
            delete settings[key];
        }

        $mayfail(key: A.Key) {
            this.$mayRefetch(key);

            const { $props: { settings, entities } } = this;
            if (!entities.hasOwnProperty(key)) return false;
            if (!settings.hasOwnProperty(key)) return true;
            return settings[key] instanceof Accessor;
        }

        $create?(val: any, entity: Editor.TEntity): boolean | Observable<any> {
            EntityInitialize();

            return entity ? this.httpClient.post(
                `/entity/${entity.entity}/create`,
                val
            ) : true;
        }

        $update?(val: any, entity: Editor.TEntity): boolean | Observable<any> {
            EntityInitialize();

            return entity ? this.httpClient.post(
                `/entity/${entity.entity}/update`,
                val
            ) : true;
        }

        $delete?(val: any, entity: Editor.TEntity): boolean | Observable<any> {
            EntityInitialize();

            return entity ? this.httpClient.post(
                `/entity/${entity.entity}/delete`,
                val
            ).pipe(
                share()
            ) : true;
        }

        $entity?(val: any): Editor.TEntity | undefined;
        $entity?(val: any, property: A.Key): Editor.TEntity | undefined
        $entity?(val: any, property?: A.Key): Editor.TEntity | undefined {
            return property ? Backface.Of(val, property) : Backface.Of(val);
        }
    };
}

export class Backface {
    private static Handler = Net.Handler;
    private $props = Prop.Of<Backface, {
        _queries: {
            [report: string]: Async.Cache<any, any>
        }
    }>(this);

    get org(): TOrg.IOrg & Editor.TSettingHandler {
        return (this.$props.org = this.$props.org || new Backface.Handler(
            this.app, this.httpClient, Setions['org']
        ));
    }

    get prj(): TPrj.IPrj & Editor.TSettingHandler {
        return (this.$props.prj = this.$props.prj || new Backface.Handler(
            this.app, this.httpClient, Setions['prj']
        ));
    }

    get exec(): TExec.IExec & Editor.TSettingHandler {
        return (this.$props.exec = this.$props.exec || new Backface.Handler(
            this.app, this.httpClient, Setions['exec']
        ));
    }

    get cud(): Editor.TSettingHandler {
        return (this.$props.cud = this.$props.cud || new Backface.Handler(
            this.app, this.httpClient
        ));
    }

    report<
        Value extends any = any,
        Body extends object = object
    >(report: string, querynow: boolean = false): Async.Cache<Value, Body> {
        const queries = (this.$props._queries = this.$props._queries || {});

        const query = (queries[report] = queries[report] || (
            new Async.Cache<Value, Body>({
                query: (p?: Body): Observable<any> => {
                    return this.httpClient.post(
                        `${report}`,
                        p || {}
                    );
                }
            }, 1, querynow)
        ));

        return query;
    }

    constructor(
        private httpClient: HttpClient,
        private app: any
    ) {
    }
}

export namespace Backface {
    type Slot = {
        handler: { query(p?: object): void },
        query(p?: object): Observable<any>,
        subscription?: Subscription,
        close(): Slot,

        error?(error: any): void,
        next?(value: any): void,
        complete?(): void
    }

    @Injectable(
    ) export class AsyncReport implements OnDestroy {
        private $props = Prop.Of<AsyncReport, {
            reporters: {
                [report: string]: Slot
            }
        }>(this);

        constructor(
            protected httpClient: HttpClient,
        ) {
            const { $props: props } = this;
            props.reporters = {};
        }

        ngOnDestroy(): void {
            const { $props: props } = this;
            _.forEach(props.reporters, (slot) => {
                slot.close();
            });

            props.reporters = {};
        }

        report(report: string, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void): {
            query: (param?: object) => void;
        } {
            const { $props: { reporters }, httpClient } = this, { tick } = window;
            const slot = (reporters[report] || (reporters[report] = {
                query(p?: object): Observable<any> {
                    return httpClient.post(
                        `${report}`,
                        p || {}
                    );
                },
                close(): Slot {
                    slot.subscription?.unsubscribe();
                    delete slot.subscription;
                    return slot;
                },
                handler: {
                    query(p?: object): void {
                        slot.subscription = slot.close().query(p).subscribe({
                            next(value: any): void {
                                const { next: _next } = slot;
                                _next && tick(() => _next?.(value));
                            },

                            error(error: any): void {
                                const { error: _error } = slot;
                                _error && tick(() => _error?.(error));
                                slot.close();
                            },

                            complete(): void {
                                const { complete: _complete } = slot;
                                _complete && tick(() => _complete?.());
                                slot.close();
                            }
                        });
                    }
                }
            }))

            slot.next = next;
            slot.error = error;
            slot.complete = complete;
            return reporters[report].handler;
        }
    }
}

export namespace Backface {
    const _ENTITYSlot = Prop.Slot<
        Editor.TEntity
    >('ENTITYKEYS');

    const _HOSTSlot = Prop.Slot<{
        [P in A.Key]: Editor.TEntity
    }>('HOSTKEYS');

    export function Entity<T>(
        type: (() => Type<T>),
        section: string | [
            section: string, refetch: boolean
        ] | [
            section: string, entity: string
        ] | [
            section: string, entity: string, refetch: boolean
        ],
        payload?: any
    ): PropertyDecorator {

        const Entity: string | undefined = Array.isArray(section) ? (_.isString(section[1]) ? section[1] : undefined) : undefined;
        const Refetch: boolean = Array.isArray(section) ? (_.isBoolean(section[1]) ? section[1] : !!section[2]) : false;
        const Section: string = Array.isArray(section) ? section[0] : section;

        // generate the property decorator function
        return function (prototype: Object, property: string | symbol): void {
            const _section = Setions[Section] = Setions[Section] || {
                name: Section,
                entities: {},
            }

            payload = payload || _section.entities[property] || {};
            _section.entities[property] = payload;

            _EntityInits.push(() => {
                const thisbag = _ENTITYSlot.Of(type().prototype, true);
                const hostbag = _HOSTSlot.Of(prototype, true);

                hostbag[property] = thisbag;
                thisbag.entity = (Entity || property as string);
                thisbag.refetch = Refetch;
                thisbag.section = Section;
                thisbag.payload = payload;
                thisbag.loaded = false;
                thisbag.cls = type();

                _section.definitions[property] = thisbag;
            })
        }

    }

    export function Of<T extends object>(obj: T): Editor.TEntity | undefined;
    export function Of<T extends object>(obj: T, property: A.Key): Editor.TEntity | undefined;
    export function Of<T extends object>(obj: T, property?: A.Key): Editor.TEntity | undefined {
        EntityInitialize();

        if (property) {
            const bag = _HOSTSlot.Of(obj, false);
            if (!bag) return;

            if (bag?.[property]) {
                return bag?.[property];
            }

            if (_.isString(property) && property[property.length - 1] == 's') {
                return bag?.[property.substring(0, property.length - 1)];
            }

            return;
        }

        return _ENTITYSlot.Of(obj, false);
    }
}