<ng-template id="reportworkitemexe" let-toolbar="toolbar">
  <ng-container *ngFor="let item of app?.prj?.projecttypes?.array">
    <reportworkitemexe [prjtypeid]="item?.id"></reportworkitemexe>
  </ng-container>
  <!-- <reportworkitemexe [toolbar]="toolbar"></reportworkitemexe> -->
</ng-template>

<div class="h-100 w-100" [toeditting]="editting" [gov-editor]="modules" (onFilterTemplate)="onFilter($event)"
  (onCurrentModule)="onCurrentModule($event)" (onCommandTemplate)="
    $event.config.disableClose = false;
    $event.template = component
  "></div>