import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./userinfo.component.html",
    styleUrls: ['./userinfo.component.scss'],
    providers: [
        PuTemplateService
    ]
})
export class UserinfoComponent implements OnInit, AfterViewInit {
    constructor(
        public auth: AuthService,
        public tpls: PuTemplateService,
        public app: AppService
    ) {

    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    static open(dialog: PuDialogService, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: UserinfoComponent,
            injector: injector,
            maximized: false,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '60vh',
            maxWidth: '100vw',
            minWidth: '40vw'
        })
    }
}
