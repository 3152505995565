<ng-template id="filter_selector">
  <div ngbDropdown #filterDropDown="ngbDropdown" role="group" container="body" autoClose="true" display="dynamic"
    class="btn-group btn-group-sm">
    <button type="button" ngbDropdownToggle class="btn btn-sm d-flex list-group-horizontal align-items-center">
      {{ searchFilter.name }}<mat-icon>filter_alt</mat-icon>
    </button>
    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" (click)="filterDropDown.close();">
      <div class="vh-40" style="overflow: hidden auto !important; background-color: rgb(246 244 240);">
        <mat-selection-list (selectionChange)="searchFilter = $event.options[0].value" [multiple]="false">
          <ng-container *ngFor="let filter of searchFilters.array">
            <mat-list-option *ngIf="filter != searchFilter" ngbDropdownItem [value]="filter" class="mx-0">
              <span class="mat-chip" style="font-size: 14px">{{ filter?.name }}</span>
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>

      <div class="dropdown-divider mt-0 mb-1"></div>
      <div class="w-100 px-2">
        <button class="btn btn-sm w-100 border-0" mat-flat-button color="primary" (click)="advfilterManagement()">
          {{ "general.advfilter.management" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<table id="sumprojectworkitem" class="summery h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
  <div class="w-100 d-flex flex-row justify-content-between align-items-center"
    style="margin-top: 10px; margin-bottom: 10px">
    <gov-statusbar style="visibility: hidden"></gov-statusbar>
    <table>
      <tr>
        <th style="text-align: center; font-size: 1.2em; white-space: nowrap">
          {{ "general.orgname" | translate }}
          {{ "summary.workitemprjtable" | translate }}
          ({{ searchFilter.name }})
        </th>
      </tr>
    </table>
    <gov-statusbar></gov-statusbar>
  </div>
  <table class="summery" style="width: 150%;" mat-table [dataSource]="dataSource">
    <ng-container *ngFor="let sumitem of sumFields; index as i" matColumnDef="{{ sumitem.key }}">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="getColspan(1, i)">
        {{ (app._.isFunction(sumitem.title) ? sumitem.title(app, sumitem) : (sumitem.title ?? '')) | translate }}
      </th>
    </ng-container>
    <ng-container *ngFor="let sumitem of sumFields2; index as m" matColumnDef="{{ sumitem.key }}">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="getColspan(2, m)">
        {{ (app._.isFunction(sumitem.title) ? sumitem.title(app, sumitem) : (sumitem.title ?? '')) | translate }}
      </th>
    </ng-container>

    <ng-container *ngFor="let col of colFields.array; index as j" matColumnDef="{{ col.key }}">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="getColspan(3, j)">
        {{ (app._.isFunction(col.title) ? col.title(app, col) : (col.title ?? '')) | translate }}
      </th>
      <td mat-cell [class]="col.cellcls" *matCellDef="let element; index as k" [attr.colspan]="getColspan(3, j)">
        {{ col.key == "id" ? k + 1 : fielder.getCellText(element, col.key, colFields) }}
      </td>
    </ng-container>

    <ng-container *ngFor="let sumcellcol of sumcells; let i = index">
      <ng-container *ngFor="let sumcell of sumcellcol.items; let j = index" matColumnDef="{{sumcell.key}}">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="sumcellcol.col" [attr.rowspan]="sumcell.row">
          {{ sumcell.title | translate }}
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let selectedws of selectedwscnt;let i = index">
      <ng-container *ngFor="let key of SUMKEYS" matColumnDef="{{selectedws.id}}{{key}}">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="1" [attr.rowspan]="1">{{ selectedws.wssum[key] }}</td>
      </ng-container>
    </ng-container>

    @for (key of SUMKEYS; track $index) {
    <ng-container matColumnDef="{{SUMRIGHT}}{{key}}">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="1" [attr.rowspan]="SUMKEYS.length">
        {{ sumallcount[key] }}
      </td>
    </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="sumdisplayedColumns; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="sum2displayedColumns; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        {{ "general.searchnone" | translate }}
      </td>
    </tr>

    <ng-container *ngFor="let item of sumcells; let i=index">
      <tr mat-footer-row *matFooterRowDef="getFootColDef(i)"></tr>
    </ng-container>
  </table>
</table>