import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { Subscription, timer } from "rxjs";

@Directive({
    selector: "[timer]"
}) export class TimerDirective implements OnDestroy, OnChanges {
    private _props = Prop.Of<TimerDirective, {
        _subscription?: Subscription,
    }>(this);

    @Input('timer')
    get interval(): number | undefined {
        const { _props: { interval } } = this;
        return interval;
    }

    set interval(val: number | undefined) {
        this._props.interval = val;
    }

    @Input('countdown')
    get countdown(): number {
        const { _props: { countdown = 1 } } = this;
        return countdown;
    }

    set countdown(val: number) {
        const { _props: props } = this;
        props.countdown = val;
    }

    @Output('finish')
    get finish(): EventEmitter<boolean> {
        const { _props: props } = this;
        return props.finish || (
            props.finish = new EventEmitter()
        );
    }

    constructor(
    ) {
    }

    ngOnDestroy(): void {
        const { _props: props, _props: { _subscription } } = this;
        _subscription?.unsubscribe();
        delete props._subscription;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ngOnDestroy();
        const { _props: { interval } } = this;
        if (!_.isNumber(interval)) return;

        const { finish, countdown, _props: props } = this;
        let _countdown = countdown;

        props._subscription = timer(interval).subscribe(() => {
            if (--_countdown <= 0) {
                tick(() => {
                    props._subscription?.unsubscribe();
                    delete props._subscription;
                })

                finish.emit(true);
                return;
            }

            finish.emit(false)
        })
    }
}
