import { Directive, EventEmitter, Input, OnDestroy, Output, Renderer2, ViewContainerRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgxXml2jsonService } from 'ngx-xml2json';
import { Observable, Subject, from } from "rxjs";
import { switchMap } from "rxjs/operators";

import { ContextPad, PopupMenu } from "bpmn-js/lib/features/context-pad/ContextPadProvider";
import BaseViewer, { ImportXMLResult, SaveXMLResult } from 'bpmn-js/lib/BaseViewer';
import GridSnappingModule from 'bpmn-js/lib/features/grid-snapping';
import BpmnFactory from "bpmn-js/lib/features/modeling/BpmnFactory";
import { Shape, Element, Moddle, } from "bpmn-js/lib/model/Types";
import Modeling from 'bpmn-js/lib/features/modeling/Modeling';
import Modeler from 'bpmn-js/lib/Modeler';
import Viewer from 'bpmn-js/lib/Viewer';

import { ContextPadTarget } from "diagram-js/lib/features/context-pad/ContextPad";
import ZoomScroll from 'diagram-js/lib/navigation/zoomscroll/ZoomScroll';
import KeyboardMoveModule from 'diagram-js/lib/navigation/keyboard-move';
import AutoPlace from 'diagram-js/lib/features/auto-place/AutoPlace';
import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas';
import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll';
import Canvas, { CanvasViewbox } from 'diagram-js/lib/core/Canvas';
import GraphicsFactory from 'diagram-js/lib/core/GraphicsFactory';
import ElementRegistry from 'diagram-js/lib/core/ElementRegistry';
import { Event as BusEvent } from 'diagram-js/lib/core/EventBus';
import Overlays from 'diagram-js/lib/features/overlays/Overlays';
import ElementFactory from 'diagram-js/lib/core/ElementFactory';
import Translate from "diagram-js/lib/i18n/translate/translate";
import CommandStack from 'diagram-js/lib/command/CommandStack';
import { Dimensions, Rect } from 'diagram-js/lib/util/Types';
// import TouchModule from 'diagram-js/lib/navigation/touch';
import Create from 'diagram-js/lib/features/create/Create';
import EventBus from 'diagram-js/lib/core/EventBus.js';
import Diagram from 'diagram-js/lib/Diagram';

import AlignToOriginModule, { AlignToOrigin } from '@bpmn-io/align-to-origin/lib';
import OriginModule, { ConfigureOrigin } from 'diagram-js-origin';
import minimapModule, { Minimap } from 'diagram-js-minimap';
import GridModule, { Grid } from 'diagram-js-grid';

import { ContextPadHook } from "./palette.module";
import { PaletteHook } from "./palette.module";
import { EdittingHook } from "./editting.module";
import { RendererHook } from "./render.module";
import { ResizeHook } from "./resize.module";
import { EventHook } from "./event.module";
import { Entries } from "./palette.module";

import govExt from './gov.extension.json';

type Destroied = GovDiagramDirective.Destroied;
type Imported = GovDiagramDirective.Imported;
type Command = GovDiagramDirective.Command;
type Translate = typeof Translate;

// fix defects of base library
const _getSize = Canvas.prototype.getSize;
Canvas.prototype.getSize = function getSize(this: Canvas): Dimensions {
    const _container = this.getContainer();
    if (_container) return _getSize.call(this);

    // fix undefined/null _container access for async viewbox method while diagram destroied.
    return { width: 0, height: 0 };
}

const _viewbox = Canvas.prototype.viewbox;
Canvas.prototype.viewbox = function viewbox(box?: Rect): CanvasViewbox {
    const _container = this.getContainer();
    if (_container) return _viewbox.call(this, box);

    // fix undefined/null _container access for async viewbox method while diagram destroied.
    return _viewbox.call(this, box);
}

// util methods
const i18n: Record<string, string> = {
    'Close minimap': '关闭导航',
    'Open minimap': '打开导航'
}

function govtranslate(s: string, r: any): string {
    const _s = i18n[s];
    if (_s) return _s;

    return s;
}

function getEvents(obj: { [k: string]: any }, curkey?: string, res: string[] = []): string[] {
    curkey = curkey ? `${curkey}.` : '';

    return _.reduce(obj, (res: string[], v, k) => {
        k = `${curkey}${k}`;

        if (_.isFunction(v)) {
            res.push(k);
            return res;
        }

        if (_.isObject(v)) {
            return getEvents(v, k, res);
        }

        return res;
    }, res)
}

// TOCK:
// namespace BPMNHack {
//     const bvproto = BaseViewer.prototype;
//     const bvinit = _.get(bvproto, '_init');

//     function _init(this: BaseViewer, container: HTMLElement, ...args: any[]) {
//         // clear all children content.
//         while (container?.firstChild) {
//             container.firstChild.remove();
//         }

//         return bvinit?.apply(
//             this, arguments
//         );
//     }

//     _.set(bvproto, '_init', _init);
// }

@Directive({
    selector: 'gov-diagram, [gov-diagram]',
    exportAs: 'GovDiagram'
}) export class GovDiagramDirective implements OnDestroy {
    private _props = Prop.Of(this);

    @Output('imported')
    get imported(): EventEmitter<Imported> {
        const { _props: props } = this;
        return props.imported || (
            props.imported = new EventEmitter()
        )
    };

    @Output('destroied')
    get destroied(): EventEmitter<Destroied> {
        const { _props: props } = this;
        return props.destroied || (
            props.destroied = new EventEmitter()
        )
    };

    @Output('command')
    get command(): EventEmitter<Command> {
        const { _props: props } = this;
        return props.command || (
            props.command = new EventEmitter()
        )
    }

    @Output('changed')
    get changed(): EventEmitter<Parameters<
        GovDiagramDirective['event']['element']['changed']
    >[0]> {
        const { _props: props } = this;
        return props.changed || (
            props.changed = new EventEmitter()
        )
    }

    @Output('gov-diagramChange')
    get diagramChanged(): EventEmitter<string> {
        const { _props: props } = this;
        return props.diagramChanged || (
            props.diagramChanged = new EventEmitter()
        )
    }

    @Input('gov-diagram')
    get diagram(): string | undefined {
        return this._props.diagram;
    }

    set diagram(val: string | undefined) {
        const { _props: props, _props: { diagram, viewer } } = this;
        if (diagram == val && viewer) return;
        props.diagram = val;

        // reload the diagram
        this.loadDiagram(val);
    }

    @Output('gov-svgChange')
    get svgChanged(): EventEmitter<string> {
        const { _props: props } = this;
        return props.svgChanged || (
            props.svgChanged = new EventEmitter()
        )
    }

    @Input('gov-svg')
    get svg(): string | undefined {
        return this._props.svg;
    }

    set svg(val: string | undefined) {
        const { _props: props, _props: { svg } } = this;
        if (svg == val) return;
        props.svg = val;
    }

    @Output('gov-jsonChange')
    get jsonChanged(): EventEmitter<object> {
        const { _props: props } = this;
        return props.jsonChanged || (
            props.jsonChanged = new EventEmitter()
        )
    }

    @Input('gov-json')
    get json(): object | string | undefined {
        return this._props.json;
    }

    set json(val: object | string | undefined) {
        const { _props: props, _props: { json } } = this;
        if (json == val) return;
        props.json = val;
    }

    @Input('readonly')
    get readonly(): boolean {
        return !!this._props.readonly;
    }

    set readonly(val: boolean) {
        const { _props: props, _props: { readonly = false } } = this;
        if (!!readonly == !!val) return;
        props.readonly = !!val;

        // destry exist view
        this.ngOnDestroy();

        // recreate diagram.
        const { _props: { diagram: url } } = this;
        this.loadDiagram(url);
    }

    get viewer(): Viewer | Modeler {
        let { _props: { viewer } } = this;
        if (viewer) return viewer;

        const { readonly, renderer, _props: props } = this;
        const BPMNViewer = (readonly ? Viewer : Modeler);
        viewer = (props.viewer = (new BPMNViewer({
            moddleExtensions: {
                gov: govExt
            },
            additionalModules: [
                { translate: ['value', govtranslate] },
                { govDiagram: ['value', this] },

                /** non-modeling modules */
                // interactionModules
                KeyboardMoveModule,
                MoveCanvasModule,
                ZoomScrollModule,
                // TouchModule,

                // enhanced extent modules
                ContextPadHook.Module,
                EdittingHook.Module,
                RendererHook.Module,
                ResizeHook.Module,
                EventHook.Module,

                // AlignToOriginModule,
                // OriginModule,
                minimapModule,
                GridModule,

                /** modeling modules */
                ...(readonly ? [] : [
                    PaletteHook.Module,
                ])
            ],
            common: {
                keyboard: {
                    bindTo: document
                }
            },
            bpmnRenderer: {
                ...(readonly ? {} : {
                    defaultFillColor: '#eeeeee',
                    defaultStrokeColor: '#2a2a2a',
                    defaultLabelColor: '#333333'
                })
            },
            gridSnapping: {
                active: true
            }
        })));

        const container: HTMLDivElement = (viewer as any)['_container'];
        Array.from(container.children).forEach((child) => {
            if (child instanceof HTMLAnchorElement) {
                renderer.removeChild(container, child);
            }
        });

        viewer.get<Grid>('grid', false)?.toggle(true);
        const { container: { element } } = this;
        const { nativeElement: el } = element;
        viewer.attachTo(el);
        return viewer;
    }

    get bpmnRender(): RendererHook {
        return this.viewer.get('bpmnRenderer')
    }

    get bpmnFactory(): BpmnFactory {
        return this.viewer.get('bpmnFactory');
    }

    get elementFactory(): ElementFactory {
        return this.viewer.get('elementFactory')
    }

    get graphicsFactory(): GraphicsFactory {
        return this.viewer.get('graphicsFactory')
    }

    get elementRegistry(): ElementRegistry {
        return this.viewer.get('elementRegistry')
    }

    get commandStack(): CommandStack {
        return this.viewer.get('commandStack')
    }

    get paletteProvider(): PaletteHook {
        return this.viewer.get('paletteProvider');
    }

    get contextPadProvider(): ContextPadHook {
        return this.viewer.get('contextPadProvider');
    }

    get contextPad(): ContextPad {
        return this.viewer.get('contextPad');
    }

    get popupMenu(): PopupMenu {
        return this.viewer.get('popupMenu');
    }

    get eventbus(): EventBus {
        return this.viewer.get('eventBus');
    }

    get modeling(): Modeling {
        return this.viewer.get('modeling');
    }

    get overlays(): Overlays {
        return this.viewer.get('overlays');
    }

    get canvas(): Canvas {
        return this.viewer.get('canvas');
    }

    get moddle(): Moddle {
        return this.viewer.get('moddle');
    }

    get translate(): Translate {
        return this.viewer.get('translate');
    }

    get create(): Create {
        return this.viewer.get('create');
    }

    get zoomScroll(): ZoomScroll {
        return this.viewer.get('zoomScroll')
    }

    get entries(): Entries {
        const { _props: props } = this;
        return props.entries || (
            props.entries = new Entries(this)
        )
    }

    get event() {
        const handles = () => {
            return {
                import: {
                    done(this: GovDiagramDirective, event: BusEvent & {
                        warnings: string[],
                        type: string,
                        error: any,
                    }) {
                        // diagram doc imported;
                        if (!event.error) {
                            // auto-fit the viewport width
                            this.fitWidth()
                        }

                        if ((event.warnings?.length ?? 0) > 0) {
                            console.warn(...event.warnings);
                        }

                        const { _props: { imported }, viewer } = this;
                        if (imported) {
                            const { error, warnings } = event;
                            if (error) {
                                imported.emit({
                                    type: "error",
                                    viewer: viewer,
                                    diagram: this,
                                    error
                                })
                            } else {
                                imported.emit({
                                    type: "success",
                                    viewer: viewer,
                                    diagram: this,
                                    warnings
                                })
                            }
                        }
                    }
                },
                diagram: {
                    destroy(this: GovDiagramDirective, event: BusEvent) {
                        const { _props: { destroied }, viewer } = this;
                        destroied?.emit({
                            diagram: this,
                            viewer: viewer
                        });
                    }
                },
                commandStack: {
                    changed(this: GovDiagramDirective, event: BusEvent & {
                        trigger: string // "execute"
                        type: string
                    }) {
                        // element created/modified/deleted in undo/redo stack
                    }
                },
                element: {
                    changed(this: GovDiagramDirective, event: BusEvent & {
                        element: Element,
                        gfx: SVGElement,
                        type: string
                    }) {
                        // element created/modified/deleted
                        const { _props: { changed, diagramChanged, svgChanged, jsonChanged } } = this;
                        const { _props: props, ngxXml2jsonService, readonly } = this;
                        changed?.emit(event);

                        if (!readonly && (diagramChanged || jsonChanged)) {
                            const xmlsub = this.saveXML(false).subscribe({
                                complete() {
                                    tick(() => xmlsub?.unsubscribe());
                                },
                                next(val) {
                                    const xml = val.xml;

                                    if (diagramChanged) {
                                        props.diagram = xml ?? '';
                                        diagramChanged.emit(xml);
                                    }

                                    if (jsonChanged) {
                                        const parser = new DOMParser();
                                        const _xml = xml?.replace(/>\s*</ig, '><') ?? '';
                                        const parsed = parser.parseFromString(_xml, 'text/xml');
                                        const json = ngxXml2jsonService.xmlToJson(parsed);

                                        props.json = json;
                                        jsonChanged.emit(json);
                                    }
                                }
                            })
                        }

                        if (!readonly && svgChanged) {
                            const svgsub = this.saveSVG(false).subscribe({
                                complete() {
                                    tick(() => svgsub?.unsubscribe());
                                },
                                next(val) {
                                    props.svg = val.svg;
                                    svgChanged.emit(val.svg);
                                }
                            })
                        }
                    },
                    hover(this: GovDiagramDirective, event: BusEvent & {
                        originalEvent: MouseEvent,
                        element: Element,
                        gfx: SVGElement,
                        type: string
                    }) {
                    },
                    contextmenu(this: GovDiagramDirective, event: BusEvent & {
                        originalEvent: MouseEvent,
                        element: Element,
                        gfx: SVGElement,
                        type: string
                    }) {
                        event.originalEvent.preventDefault();
                        event.originalEvent.stopPropagation();
                    },
                }
            }
        }

        type IHandler = ReturnType<typeof handles>;
        const props = this._props as { event: IHandler };
        return (props.event || (props.event = handles()));
    }

    constructor(
        public ngxXml2jsonService: NgxXml2jsonService,
        public container: ViewContainerRef,
        public renderer: Renderer2,
        private http: HttpClient
    ) {
    }

    ngOnDestroy(): void {
        const { _props: props, _props: { viewer } } = this;
        viewer?.detach(), viewer?.destroy();
        viewer && (viewer.open = (() => {
            return Promise.resolve({
                warnings: ['viewer changed while load diagram!']
            })
        }) as any)

        props.viewer = undefined;
    }

    fitWidth() {
        const { canvas } = this;
        if (!canvas) return;

        // auto-fit the viewport width
        const viewbox = canvas.viewbox();
        const { outer: { width: owidth } } = viewbox;
        const { inner: { width: iwidth, x: ix, y: iy } } = viewbox;
        const rect: Rect = { width: 0, x: 0, height: 0, y: iy };

        if (owidth < iwidth) {
            rect.width = iwidth;
            rect.x = ix;
        } else {
            rect.x = (ix - (owidth - iwidth) / 2);
            rect.width = owidth;
        }

        canvas.viewbox(rect);
    }

    zoomIn() {
        const { outer: { width, height } } = this.canvas.viewbox();
        this.zoomScroll?.stepZoom(1, { x: width / 2, y: height / 2 });
    }

    zoomOut() {
        const { outer: { width, height } } = this.canvas.viewbox();
        this.zoomScroll?.stepZoom(-1, { x: width / 2, y: height / 2 });
    }

    on<TThis>(events: {
        [e: string]: {
            [e: string]: (this: TThis, ...args: any[]) => any
        }
    }, thisarg: TThis, eventbus?: EventBus) {
        const props = Prop.Of<typeof events, {
            callback(this: TThis, event: BusEvent): void;
        }>(events);

        if (!props.callback) {
            props.callback = function (this: TThis, event: BusEvent & {
                type: string, error: any,
            }) {
                const handle = _.get(events, `${event.type}`);
                if (_.isFunction(handle)) handle.call(this, event);
            }
        }

        const _events = getEvents(events);
        eventbus = eventbus || this.eventbus;
        eventbus.on(_events, props.callback, thisarg)
    }

    off<TThis>(events: {
        [e: string]: {
            [e: string]: (this: TThis, ...args: any[]) => any
        }
    }, eventbus?: EventBus) {
        const { callback } = Prop.Of<typeof events, {
            callback(this: TThis, event: BusEvent): void;
        }>(events);

        if (!callback) return;
        const _events = getEvents(events);
        eventbus = eventbus || this.eventbus;
        eventbus.off(_events, callback);
    }

    saveXML(download?: boolean): Observable<SaveXMLResult> {
        const { viewer } = this;

        if (!download) {
            return from(viewer.saveXML({ format: true }));
        }

        const downloader = GovDiagramDirective.downloadToLocal;
        return from(viewer.saveXML({ format: true }).then(res => {
            const encodeXML = encodeURIComponent(res.xml ?? '');
            downloader(encodeXML);
            return res;
        }));
    }

    saveSVG(download?: boolean) {
        const { viewer, _props: props } = this;

        if (!download) {
            return from(this.viewer.saveSVG());
        }

        const downloader = GovDiagramDirective.downloadToLocal;
        return from(viewer.saveSVG().then(res => {
            const encodeSVG = encodeURIComponent(res.svg);
            downloader(encodeSVG);
            return res;
        }));
    }

    private loadDiagram(url_or_bpmn?: string): Observable<ImportXMLResult> | undefined {
        const { viewer } = this;

        if (!url_or_bpmn) {
            if (viewer instanceof Modeler) {
                // default has "bpmn:StartEvent", "bpmn:Process"
                viewer.createDiagram();
            }

            return;
        }

        if (/^\s*</i.test(url_or_bpmn)) {
            return this.importDiagram(url_or_bpmn, viewer);
        }

        const { http, imported } = this;
        const loader = (http.get(url_or_bpmn, { responseType: 'text' }).pipe(
            switchMap((xml: string): Observable<ImportXMLResult> => {
                const { _props: { viewer: _viewer } } = this;
                if (viewer != _viewer) throw 'viewer changed while load diagram!';
                return this.importDiagram(xml, viewer)
            })
        ));

        const _this = this;
        const sub = loader.subscribe({
            complete() {
                tick(() => sub?.unsubscribe());
            },
            error(err) {
                imported.emit({
                    diagram: _this,
                    viewer: viewer,
                    type: 'error',
                    error: err
                });
            }
        })

        return loader;
    }

    private importDiagram(xml: string | File, viewer: Viewer | Modeler): Observable<ImportXMLResult> {
        const importXML = (xmlData: string) => {
            const { _props: { viewer: _viewer } } = this;
            if (viewer != _viewer) throw 'viewer changed while import diagram!';

            return viewer.importXML(xmlData).then((value) => {
                return value;
            })
        }

        if (xml instanceof File) {
            const notifier: Subject<ImportXMLResult> = new Subject();
            const reader = new FileReader();
            reader.readAsText(xml);

            reader.onerror = () => {
                notifier.error('error is occured while reading file!');
                notifier.complete();
            };

            reader.onload = () => {
                const xmlData = reader.result?.toString() ?? '';
                importXML(xmlData).then((value) => {
                    notifier.next(value);
                    notifier.complete();
                }, (error) => {
                    notifier.error(error);
                    notifier.complete();
                });
            };

            return notifier;
        }

        return from(importXML(xml));
    }

    static downloadToLocal(encodedData: string, filename: string = 'diagram.bpmn'): void {
        const href = `data:application/bpmn20-xml;charset=UTF-8, ${encodedData}`;
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.download = filename;
        anchor.href = href;

        const { body } = document;
        body.appendChild(anchor);
        anchor.click();
        body.removeChild(anchor);
    }

    static getExtensionElement(element: Shape, type: string) {
        if (!element["extensionElements"]) {
            return;
        }

        return element["extensionElements"].values.filter(
            (extensionElement: any) => {
                return extensionElement.$instanceOf(type);
            }
        );
    }
}

export namespace GovDiagramDirective {
    export type Imported = {
        diagram: GovDiagramDirective,
        viewer: BaseViewer,
        type: 'success',
        warnings: string[],
    } | {
        diagram: GovDiagramDirective,
        viewer: BaseViewer,
        type: 'error',
        error: any
    }

    export type Destroied = {
        diagram: GovDiagramDirective,
        viewer: BaseViewer,
    }

    export type Command = {
        diagram: GovDiagramDirective,
        target: ContextPadTarget,
        event: PointerEvent,
        command: string,
    }
}