import { SidebarComponent } from "./view/sidebar/sidebar.component";
import { FooterComponent } from "./view/footer/footer.component";
import { NavbarComponent } from "./view/navbar/navbar.component";
import { FrameComponent } from "./view/frame/frame.component";
import { AppComponent } from "./view/app.component";

import { AppRoutingModule } from "./module/app.routing.module";
import { AuthService } from "./service/auth.service";
import { AppService } from "./service/app.service";
import { Backface } from "./service/backface/config";

export const Application = {
    Components: [
        AppComponent,

        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        FrameComponent,
    ],

    Services: [
        AuthService,
        AppService,
        Backface.AsyncReport
    ],

    Modules: [
        AppRoutingModule
    ]
}