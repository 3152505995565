import { Directive, ElementRef, Input, output, TemplateRef } from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';

@Directive({
    selector: '[ngx-cmenu-item]',
    exportAs: 'ngxCMenuItem'
})
export class NgxCMenuItemDirective implements Highlightable {
    isActive: boolean = false;
    currentItem: any;

    @Input() enabled: boolean | ((item: any) => boolean) = true;
    @Input() visible: boolean | ((item: any) => boolean) = true;
    @Input() passive: boolean = false;
    @Input() divider: boolean = false;
    @Input() subMenu: any;

    readonly execute = output<{
        event: MouseEvent | KeyboardEvent;
        item: any;
    }>();

    get disabled(): boolean {
        return this.passive || this.divider || !this.evaluateIfFunction(
            this.enabled, this.currentItem
        );
    }

    constructor(
        private readonly template: TemplateRef<{ item: any; }>,
        private readonly elementRef: ElementRef
    ) {

    }

    evaluateIfFunction(value: any, item: any): any {
        if (value instanceof Function) {
            return value(item);
        }
        return value;
    }

    setActiveStyles(): void {
        this.isActive = true;
    }

    setInactiveStyles(): void {
        this.isActive = false;
    }

    triggerExecute(item: any, event: MouseEvent | KeyboardEvent): void {
        if (!this.evaluateIfFunction(this.enabled, item)) {
            return;
        }

        this.execute.emit({ event, item });
    }
}