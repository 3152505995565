import { Component, OnInit, AfterViewInit, Injector } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';

import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { AppService } from "../../application/service/app.service";

@Component({
    selector: 'processhelp.component',
    templateUrl: "./processhelp.component.html",
    styleUrls: ['./processhelp.component.scss'],
    providers: [
        PuTemplateService
    ]
})
export class ProcesshelpComponent implements AfterViewInit, OnInit {
    constructor(public app: AppService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
    }

    static open(dialog: PuDialogService, injector?: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: ProcesshelpComponent,
            injector: injector,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '50vh',
            maxWidth: '100vw',
            minWidth: '50vw',
            height: '80vh',
            width: '50vw'
        })
    }
}
