import { SelectionModel } from "@angular/cdk/collections";
import { FileItem } from "ng2-file-upload";

import type { PuVersioinService } from "../../../utils/puzzle/pu.service/pu.version.service";
import { Editor } from "../../../utils/libs/editor";
import { PartialDeep } from "type-fest";

export namespace Org {
    export interface IOrg {
        dept?: IDept;
        priviledge?: IPriviledge;
        role?: IRole;
        actors?: IActor[];
        inspectors?: IInspector[];
    }

    export interface IDept {
        peoples?: IPeople[],
        depts?: IDept[],
        parent?: IDept,

        id?: Prop.guid,
        name?: string,
        location?: string,
        description?: string
    }

    export interface IPriviledge {
        priviledges?: IPriviledge[],

        id?: number,
        name?: string,
        prop?: JSON.Type,
        parent?: IPriviledge,
    }

    export interface IRole {
        priviledges?: IPriviledge[],
        roles?: IRole[],
        parent?: IRole,

        id?: Prop.guid,
        name?: string,
        description?: string
    }

    export interface IPeople {
        roles?: IRole[],
        dept?: IDept,

        id?: Prop.guid,
        name?: string,
        description?: string,
        mobile?: string,
        email?: string,

        disabled?: boolean,
        password?: string
    }

    export interface IActor {
        header?: IPeople,
        leaders?: IPeople[],
        actors?: IPeople[],
        domains?: IPeople[],
        sites?: IPeople[],
        prjowner?: IPeople,

        id?: Prop.guid,
        name?: string,
        description?: string,
    }

    export interface IInspector {
        kpileader?: IPeople,
        kpimembers?: IPeople[],

        cdtorleader?: IPeople,

        id?: Prop.guid,
        name?: string,
        description?: string,
    }
}


export namespace Prj {
    export interface IPrj {
        checkpoints?: Prj.ICheckpoint[],
        workitems?: Prj.IWorkitem[],
        worksets?: Prj.IWorkset[],
        stages?: Prj.IStage[],
        projecttypes?: Prj.IProjectType[],
        plans?: Prj.IPlan[]
    }

    export interface ICheckpoint extends Editor.IField {
        description?: string,
        dependfield?: ICheckpoint,
        id?: string,
    }

    export interface IWorkitem {
        checkpoints?: ICheckpoint[],
        dept?: Org.IDept,

        id?: Prop.guid,
        name?: string,
        description?: string,
        type?: IWorkitem.Type,

        duration?: number,
        occurancy?: Sys.Frequency,
        status?: IWorkitem.Status,
        enddate?: string | Date
    }

    export namespace IWorkitem {
        export enum Status {
            unset = 0x0001,
            unknown = 0x0002,
            notstart = 0x0004,
            ongoing = 0x0008,
            delaying = 0x0010,
            delayed = 0x0020,
            finished = 0x0040,
            notused = 0x0080
        }

        export enum Type {
            normal = 0,     //普通项
            verify = 1,     //审批项
            supervise = 2,  //督查项
        }
    }

    export interface IWorkset {
        workitems?: IWorkitem[]

        id?: Prop.guid;
        name?: string;
        description?: string;
    }

    export interface IStage {
        worksets?: IWorkset[],

        id?: Prop.guid;
        name?: string;
        description?: string;
    }

    export interface IProjectType {
        stages?: IStage[],

        id?: Prop.guid,
        name?: string,
        description?: string,
    }

    export enum Source {
        byCompany = 1,
        byGovement = 2,
        unknown = 3,
    }

    export enum ConstrType {
        unknown = 0x0000,

        continous = 0x0001,
        newcreate = 0x0002,
        cancelled = 0x0004,

        projectpre = 0x0010,
        constrpre = 0x0020,
        constring = 0x0040,
        accepting = 0x0080,

        myproject = 0x0200,

        all = 0x0100,
    }

    export enum Pool {
        input = 0x11,

        meditate = 0x21,
        candidate = 0x22,

        begun = 0x41,
        accept = 0x42,

        coll = 0x10,
        pilot = 0x20,
        authed = 0x40,
        poolmask = coll | pilot | authed,
    }

    export enum Investor {
        government = 0x01,
        enterprise = 0x02,
    }

    export enum Catalog {
        county = 0x01,
        city = 0x02,
        province = 0x03
    }

    export interface IBudget {
        source?: Source,        //资金来源
        amount?: number,        //年度计划投资
        consume?: number,       //年度完成投资
        start?: string | Date,  //建设开始年份
        end?: string | Date,    //建设结束年份
        target?: string,         //年度任务
    }

    export interface IPlan {
        id?: Prop.guid,
        name?: string,
        description?: string
    }

    export interface IPrjExec {
        duration?: number,        //整体项目工期
        constrtype?: ConstrType[],        //项目性质
        constrstatus?: IWorkitem.Status,       //执行状态
        begin?: Date,  //项目执行开始时间
        end?: Date,    //项目执行结束时间
        keystages?: {   // 关键阶段是否完成
            [stage_key: string]: boolean
        }
    }

    export type IPosition = {
        postion?: string[],
        district?: string,
        detail?: string
    }[];

    export interface IProject {
        plan?: IPlan           //项目类别   1: yes, 2: 引用打包项目
        projecttype?: IProjectType, //项目流程 非打包项目
        actor?: Org.IActor,            //项目包抓组 1: yes, 2: 引用打包项目
        inspector?: Org.IInspector,    //项目督办组 1: yes, 2: 引用打包项目

        ispatched?: boolean,     //是否打包
        cancelled?: boolean,     //是否取消   

        budgets?: IBudget[],         //年度投资 私有
        budget?: IBudget,           //投资     私有

        pool?: Pool;         //项目库：谋划、储备、开工、投产
        myproject?: boolean,        //是否我的项目
        exec?: IPrjExec,            //执行相关参数

        id?: Prop.guid,
        name?: string,              //项目名称 私有
        description?: string,       //建设内容 私有

        location?: string,          //项目地点 私有
        position?: IPosition,        //经纬度 私有

        owncompany?: string,    //建设单位 私有
        owndept?: string,       //责任单位 私有
        dept?: Org.IDept[],       //责任单位 私有
        memo?: string          //备注      私有

        isuniplan?: boolean,    //是否入统
        uniplan?: string,       //入统说明

        investor?: Investor,    //投资主体
        govinvest?: number,     //政府投资（万元）
        entinvest?: number,     //企业自筹（万元）
        catalog?: Catalog,      //项目等级
        preconstrdate?: string | Date,      //预计开工
        preacceptdate?: string | Date      //预计竣工
    }

    export interface ISearchFilter {
        id?: Prop.guid,
        name?: string,
        description?: string,
        nowyear?: boolean,
        start?: Date,
        end?: Date,
        date?: Date,
        people?: Org.IPeople,
        constrstatus?: IWorkitem.Status[],
        constrtype?: ConstrType[],
        plan?: IPlan[],
        dept?: Org.IDept[],
        workitems?: IWorkitem[]
    }

    export interface ISearchElement {
        name?: string,
        ids?: string[],
        children?: { id: string, name: string }[]
    }
}

export namespace Sys {
    export const enumInvisible = 0x8000;

    export enum Frequency {
        randomly = 0,
        daily = 1,
        weekly = 2,
        monthly = 3,
        quarterly = 4,
        halfyearly = 5,
        yearly = 6
    }

    export enum Severity {
        normal = 0,
        middle = 1,
        urgent = 2,
        alert = 3,
        punish = 4
    }

    export enum Result {
        none = 0,
        passed = 1,
        reject = 2,
    }

    export enum Reason {
        none = 0,
        paper_less = 1,
        paper_nosubmit = 2,
        dept_pending = 3,
        others = 4,
    }

    export interface IReminder {
        diffdays: number,
        freq: Frequency,
        serivity: Severity,
        message: string,
    }

    export namespace IWorkItem {
        export enum id {
            inspect = 1,
        }
    }

    export namespace ICheckpoint {
        export enum id {
            consume = 1,
            issued = 2,
        }
    }

    export interface IOperation {
        people: Org.IPeople,
        command: string,
        action: string,
        subject: string,
        data: JSON.Type
    }

    export interface ICardSumItem {
        title?: string,
        count?: number,
        warning?: boolean,
        year?: number
    }

    export interface IPrjBudgetSumItem {
        constrtype?: Prj.ConstrType,
        constrstatus?: Prj.IWorkitem.Status,
        count?: number,
        totalamount?: number,
        yearamount?: number,
        yearconsume?: number,
        warning?: boolean,
        year?: number,
        prjlist?: PartialDeep<Prj.IProject>[]
    }

    export interface IStatusItem {
        status: Prj.IWorkitem.Status,
        icon: string,
        icontext?: string,
        text: string,
        style?: string
    }

    export interface IStatusCount {
        status: Prj.IWorkitem.Status,
        count: number,
        percent: number
    }

    export interface IWSSumItem {
        status?: Prj.IWorkitem.Status,
        count?: number
    }

    export interface IStageSumItem {
        wstype?: Prj.IWorkitem.Type,
        wssum?: IWSSumItem[],
        project?: Prj.IProject,
        stage?: Prj.IStage,
        iscol?: boolean
    }

    export type IDatasetHeader<T extends Editor.Editor<any> = any> = Editor.IField<T>;
    export type IDatasetHeaders = Editor.IFields;

    export interface IDataset {
        rows?: any[] | XArray<any, any, any>;
        headers?: IDatasetHeaders;
        loading?: boolean;
        options?: {};
    }

    export interface IFilterData {
        isdefault?: boolean;
        title?: string;
        key?: string;
        value?: any;
    }

    export interface IDatasetDict {
        headers?: IDatasetHeader[],
        title?: string,
        icon?: string,
        filterdata?: IFilterData[]
    }

    export interface IDatasetModule extends IDataset {
        selection?: SelectionModel<object>,
        dict?: IDatasetDict,
        nopaginator?: boolean,
        monopage?: boolean,
        monorow?: object,
        noform?: boolean,
        accessable?: boolean,
        creatable?: boolean,
        deletable?: boolean,
        cudable?: boolean,
        template?: string,
        key: string,
        nonehint?: string
    }

    export interface IDatasetModules extends Array<IDatasetModule> {
        currentIndex: number,
    }
}

export namespace Exec {
    export interface IExec {
        resource_types?: IResourceType[];
        problems?: IProblem[];
        results?: IResult[];
    }

    export interface IResult {
        id?: Prop.guid,

        project?: Prj.IProject,
        projecttype?: Prj.IProjectType,
        stage?: Prj.IStage,
        workset?: Prj.IWorkset,
        workitem?: Prj.IWorkitem,

        value?: Editor.IDynamic,

        date?: string | Date,
        people?: Org.IPeople,

        result?: Sys.Result,
        reason?: Sys.Reason,
    }

    export namespace IResult {
        export namespace Audit {
            export enum Status {
                auditting = 1,
                nopass = 2,
                pass = 3,

                callback = Sys.enumInvisible | 4,
            }
        }

        export namespace Exec {
            export enum Status {
                exepre = "exepre",
                exeon = "exeon",
                exed = "exed"
            }
        }
    }

    export interface IAudit {
        status?: IResult.Audit.Status;
        people?: Org.IPeople;
        comment?: string;
        date?: Date;
        id?: string;
    }

    export interface IAuditResult extends IResult {
        status?: IResult.Audit.Status;
        audit?: IAudit;
    }

    export interface IResourceType {
        content_type?: string;
        mime_type?: string;
        id?: number;
    }

    export interface IResource {
        id?: Prop.guid;
        digest?: string;
        source?: string;
        url?: string;
        size?: number;
        duration?: number;
        resource_type?: IResourceType;
    }

    export interface IPic {
        id?: Prop.guid;
        comment?: string;
        resource?: IResource;

        fileItem?: FileItem;
    }

    export interface IXFile {
        id?: Prop.guid;
        comment?: string;
        resource?: IResource;

        fileItem?: FileItem;
    }

    export interface IProblem {
        id?: Prop.guid;
        auditid?: number,
        problem?: string,
        date?: string | Date,
        fixed?: boolean,
    }
}

export namespace App {
    export namespace Lang {
        export type Def = {
            [key: string]: string | Def
        }

        export type Res<T extends string | Def> = T extends string ? any : {
            [key in keyof T]: T[key] extends Def ? Res<T[key]> : any
        }
    }

    export type Priviledge = {
        [C: string]: Priviledge
    } & {
        id: number,
        name: string
    }

    export interface IMe extends Org.IPeople {
        id?: Prop.guid,
        name?: string,
        dept?: Org.IDept,
        email?: string,
        mobile?: string,
        profileimg?: string,

        token?: string,
        language?: string,

        priviledges?: Priviledge,
        role?: Org.IRole[],
        consumebyproject?: boolean,
        superviseaudit?: boolean,
        executeaudit?: boolean,
        prepareproject?: boolean
    }

    export interface IAppService {
        trans<T extends string | Lang.Def>(key: T, interpolateParams?: Object): Lang.Res<T>;

        readonly version: PuVersioinService;
        readonly consumeByProject: boolean;
        readonly prepareproject: boolean;

        backface: {
            exec: Exec.IExec & Editor.TSettingHandler
            org: Org.IOrg & Editor.TSettingHandler
            prj: Prj.IPrj & Editor.TSettingHandler
        }

        lang: {
            general: {
                mediamaxamount: number | string
            },

            sysenums: {
                prjfinished: string
            }
        }

        auth: {
            me?: IMe
        }

        dict: unknown

        prj: unknown

        org: unknown

        exec: unknown
    }
}