
import { OnInit, OnDestroy, SimpleChanges, OnChanges, NgZone, Directive, Input, output } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { Observable, zip } from 'rxjs';

import { NgxAMapToolBarCreaterService } from '../shared/inner/ngx-amap.toolbar.creater.service';
import { NgxAMapCreaterService } from '../shared/inner/ngx-amap.creater.service';
import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { AMapLoggerService } from '../shared/amap-logger.service';
import { AMapPixelService } from '../shared/amap-pixel.service';

import { ChangeFilter, getOptions } from '../utils/options';

const TAG = 'AMapToolBar'
const ToolBarOptions: Extract<
    keyof NgxAMapToolBarDirective,
    keyof AMap.ToolBar.Options
>[] = [
        'offset',
        'position',
        'ruler',
        'noIpLocate',
        'locate',
        'liteStyle',
        'direction',
        'autoPosition',
        'locationMarker',
        'useNative',
    ];

@Directive({
    selector: 'ngx-amap-tool-bar',
    exportAs: 'ngxAMapToolBar',
    providers: [NgxAMapToolBarCreaterService],
    host: {
        '[style.display]': "'none'"
    }
})
export class NgxAMapToolBarDirective implements AMap.ToolBar.Options, OnInit, OnChanges, OnDestroy {
    protected readonly _binder = this.binder.bindEvent<AMap.ToolBar.EventMap>();
    private inited: boolean = false;

    get toolbar(): Observable<AMap.ToolBar> {
        return this.toolbarcreater.get();
    }

    //#region AMap.ToolBar events
    readonly naShow = outputFromObservable(this._binder(this.toolbar, 'show'));
    readonly naHide = outputFromObservable(this._binder(this.toolbar, 'hide'));
    readonly naLocation = outputFromObservable(this._binder(this.toolbar, 'location'));
    readonly naZoomChanged = outputFromObservable(this._binder(this.toolbar, 'zoomchanged'));
    //#endregion

    readonly naReady = output<AMap.ToolBar>();

    //#region AMap.ToolBar.Options
    /**
     * 相对于地图容器左上角的偏移量
     */
    @Input()
    offset?: AMap.Pixel;

    /**
     * 控件停靠位置
     * LT: 左上角
     * RT: 右上角
     * LB: 左下角
     * RB: 右下角
     */
    @Input()
    position?: AMap.ToolBar.Position;

    /**
     * 标尺键盘是否可见
     */
    @Input()
    ruler?: boolean;

    /**
     * 定位失败后，是否开启 IP 定位
     */
    @Input()
    noIpLocate?: boolean;

    /**
     * 是否显示定位按钮
     */
    @Input()
    locate?: boolean;

    /**
     * 是否使用精简模式
     */
    @Input()
    liteStyle?: boolean;

    /**
     * 方向键盘是否可见
     */
    @Input()
    direction?: boolean;

    /**
     * 是否自动定位，即地图初始化加载完成后，是否自动定位的用户所在地，仅在支持 HTML5 的浏览器中有效
     */
    @Input()
    autoPosition?: boolean;

    /**
     * 自定义定位图标，值为 Marker 对象
     */
    @Input()
    locationMarker?: AMap.Marker;

    /**
     * 是否使用高德定位 SDK 用来辅助优化定位效果
     */
    @Input()
    useNative?: boolean;
    //#endregion

    /**
     * 额外: 是否隐藏
     */
    @Input()
    hidden: boolean = false;

    constructor(
        private readonly toolbarcreater: NgxAMapToolBarCreaterService,
        private readonly binder: AMapEventBinderService,
        private readonly mapcreater: NgxAMapCreaterService,
        private readonly pixels: AMapPixelService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    ngOnDestroy(): void {
        this.toolbarcreater.destroy();
    }

    ngOnInit(): void {
        const { mapcreater, toolbarcreater, logger, ngZone } = this;

        mapcreater.get().subscribe(
            (map) => {
                logger.d(TAG, 'initializing ...');

                const options = getOptions<NgxAMapToolBarDirective, AMap.ToolBar.Options>(this, ToolBarOptions);
                if (this.offset) {
                    options.offset = this.pixels.create(this.offset);
                }

                logger.d(TAG, 'options:', options);
                toolbarcreater.create(options).subscribe(
                    toolbar => {
                        logger.d(TAG, 'toolbar is ready.');

                        ngZone.run(() => {
                            this.naReady.emit(toolbar)
                        });
                    }
                );

                this.inited = true;
                this.get().subscribe(
                    toolbar => {
                        this.hidden ? toolbar.hide() : toolbar.show();
                    }
                );
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.inited) {
            return;
        }

        const toolbar = this.get(), { pixels } = this;
        const filter = ChangeFilter.from(changes);
        zip(filter.notEmpty('offset'), toolbar).subscribe(
            ([v, m]) => m.setOffset(pixels.create(v))
        );

        zip(filter.has('hidden'), toolbar).subscribe(
            ([v, m]) => (v ? m.hide() : m.show())
        );
    }

    /**
     * 获取已创建的 AMap.ToolBar 对象
     */
    get(): Observable<AMap.ToolBar> {
        return this.toolbarcreater.get();
    }

    doLocation(): void {
        this.toolbarcreater.get().subscribe((/**
         * @param {?} toolbar
         * @return {?}
         */
            toolbar => {
                toolbar.doLocation();
            }));
    }
}
