declare namespace _ {

    interface LoDashStatic {
        takeIf<T>(predicate: boolean, val: T): T | undefined;
        takeIf<TR, T>(predicate: ((val: any) => boolean), val: T | TR): TR | undefined;
        takeIf<TR, T>(predicate: ((val: any) => val is TR), val: T | TR): TR | undefined;

        takeIf<TR, T>(predicate: boolean, val: T | TR, elseVal: TR): TR;
        takeIf<TR, T>(predicate: ((val: any) => boolean), val: T | TR, elseVal: TR): TR;
        takeIf<TR, T>(predicate: ((val: any) => val is TR), val: T | TR, elseVal: TR): TR;
    }

}

_.mixin({

    takeIf(predicate: ((val: any) => boolean) | boolean, val: any, elseVal: any): any {
        predicate = typeof (predicate) == 'function' ? predicate(val) : predicate;
        return predicate ? val : elseVal;
    }

});