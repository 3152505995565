<div class="sidebar-wrapper overflow-x-hidden overflow-y-auto flex-fill d-flex flex-column justify-content-between">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let navitem of app.mainnavs" class="{{ navitem.class }} nav-item">
            <a *ngIf="navitem.path!='systemset'" class="d-flex flex-row nav-link m-0 py-2 ps-3 pe-4"
                [routerLink]="[navitem.path]">
                <i class="material-icons">{{ navitem.icon }}</i>
                <p>{{ (navitem.title ?? '') | translate }}</p>
            </a>
            <a *ngIf="navitem.path=='systemset'" class="d-flex flex-row nav-link m-0 py-2 ps-3 pe-4"
                (click)="onSysInfo()">
                <i class="material-icons">{{ navitem.icon }}</i>
                <p>{{ (navitem.title ?? '') | translate }}</p>
            </a>
        </li>
    </ul>
    <div class="">
        <ng-container puTemplateOutlet="mobileApp"></ng-container>
    </div>
</div>