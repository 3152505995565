
import { EventEmitter, TemplateRef, Injectable, Directive, OnInit, Output, Input, Optional, SkipSelf } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TemplateService {
    private templates: {
        [id: string]: TemplateRef<any>
    } = {};

    @Output()
    readonly changed: EventEmitter<{
        template: TemplateRef<any>,
        id: string,
    }> = new EventEmitter;

    constructor(
        @Optional()
        @SkipSelf()
        public parent?: TemplateService
    ) {
    }

    add(id: string | string[], template: TemplateRef<any>): void {
        id = _.isString(id) ? id.split(',') : id;
        const { templates, changed } = this;

        for (id of id) {
            id = id.trim();
            if (!id) continue;

            templates[id] = template;
            changed.emit({ template, id });
        }
    }

    get(id: string | undefined, selfonly: boolean = true): TemplateRef<any> | undefined {
        if (!id) return;

        for (let svrs: TemplateService | undefined = this; svrs; svrs = !selfonly ? this.parent : undefined) {
            const tmpl = svrs.templates[id];
            if (tmpl) return tmpl;
        }

        return;
    }
};

@Directive({
    selector: 'ng-template[id]'
}) export class TemplateDefine implements OnInit {
    @Input('id')
    id!: string;

    constructor(
        private tmpls: TemplateService,
        private tpl: TemplateRef<any>
    ) {
    }

    ngOnInit(): void {
        this.tmpls.add(this.id.split(','), this.tpl);
    }
};