import { XlsxExporterService, MatTableExporterDirective, ExcelOptions } from 'mat-table-exporter';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subject, shareReplay } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

import { PuSchemaService } from '../pu.service/pu.schema.service';
import { PuTemplateService } from '../pu.template/pu.template';
import { PuSysService } from '../pu.service/pu.sys.service';
import { Differ } from '../../libs/differ';

type IFielder = Schema.Form.IFielder;
type ISchemaset = Schema.ISchemaset;
type IRecordset = Schema.IRecordset;
type IRecord = Schema.IRecord;
type IEntity = Schema.IEntity;
type ISchema = Schema.ISchema;

type IHeaders = PuEditorComponent.IHeaders;
type IHeader = PuEditorComponent.IHeader;

const _ExpandColumnKey = Prop.newSymbol('ExpandPanel').toString();
const { Form: { formOf } } = Schema;

export class PuEditorExporter extends XlsxExporterService {
    private static readonly BOM = "\uFEFF";

    // Override
    public override createContent(rows: Array<any>, options?: ExcelOptions): any {
        // return PuEditorExporter.BOM + super.createContent(rows, options);
        return super.createContent(rows, options);
    }
}

@Component({
    templateUrl: "./pu.editor.component.html",
    styleUrls: ["./pu.editor.component.scss"],
    selector: "puEditor, [puEditor]",
    providers: [PuTemplateService, PuEditorExporter],
    exportAs: "puEditor"
}) export class PuEditorComponent {
    private _props = Prop.Of<PuEditorComponent, {
        dlgalert?: ReturnType<PuSysService['prompt']>,
        viewMode?: 'card' | 'table',
        preheaders: IHeader[],
        differ: Differ<{}>
    }>(this, values => {
        values.differ = Differ.create();
    });

    readonly pageSize: number[] & { current: number } = _.extend([
        5, 10, 25, 50, 100, 200
    ], { current: 2 })

    readonly formOf = formOf;
    readonly noop = _.noop;
    readonly JSON = JSON;
    readonly _ = _;

    @ViewChild(MatPaginator, { static: false })
    set defaultpaginator(val: MatPaginator) {
        const { _props: props } = this;
        props.defaultpaginator = val;
        this.paginator = val;
    }

    get defaultpaginator(): MatPaginator {
        const { _props: props } = this;
        return props.defaultpaginator!;
    }

    @ViewChild(MatTable, { static: false })
    set table(val: MatTable<any>) {
        const { _props: props } = this;
        props.table = val;
    }

    get table(): MatTable<any> {
        const { _props: props } = this;
        return props.table!;
    }

    @ViewChild(MatSort, { static: false })
    set viewsort(val: MatSort) {
        this.sort = val;
    }

    @Output('saved')
    get saved(): EventEmitter<{
        req: IRecord[],
        resp: IRecord[],
        schema?: ISchema
    }> {
        const { _props: props } = this;
        return props.saved || (
            props.saved = new EventEmitter()
        )
    }

    @Output('created')
    get created(): EventEmitter<{
        record: IRecord,
        schema?: ISchema
    }> {
        const { _props: props } = this;
        return props.created || (
            props.created = new EventEmitter()
        )
    }

    @Output('removed')
    get removed(): EventEmitter<{
        record: IRecord | IRecord[],
        schema?: ISchema
    }> {
        const { _props: props } = this;
        return props.removed || (
            props.removed = new EventEmitter()
        )
    }

    @Input('sort')
    get sort(): MatSort | null {
        return this.matDataSource.sort;
    }

    set sort(val: MatSort | null) {
        this.matDataSource.sort = val ?? this.sort;
    }

    @Input('paginator')
    set paginator(val: MatPaginator) {
        const { _props: props, matDataSource, defaultpaginator } = this;

        if (val !== defaultpaginator) {
            props.paginator = val;
        }

        if (props.paginator !== undefined) {
            matDataSource.paginator = props.paginator;
        } else {
            matDataSource.paginator = defaultpaginator;
        }
    }

    get paginator(): MatPaginator {
        const { _props: { paginator }, defaultpaginator } = this;
        return paginator || defaultpaginator;
    }

    @Input('puEditor')
    get pueditor(): {
        value?: IRecord[],
        headers?: IHeader[],
        entity?: string,
    } | undefined {
        const { _props: { pueditor } } = this;
        return pueditor;
    }

    set pueditor(val: {
        value?: IRecord[],
        headers?: IHeader[],
        entity?: string,
    }) {
        const { _props: props, _props: { pueditor: { entity, value, headers } = {} } } = this;
        if (val.entity == entity && val.value == value && val.headers == headers) return;

        props.pueditor = val;

        if (val.headers != headers) {
            this.headers = val.headers ?? [];
        }

        const { recordset, matDataSource, table, _props: { differ } } = this;
        const renderdatasource = () => {
            recordset && (matDataSource.data = recordset);
            table?.renderRows();
        }

        differ.extend({
            datasource: recordset && Differ.Event.create(
                recordset.onAddRemoved, renderdatasource, this
            )
        })

        renderdatasource();
    }

    @Input('removable')
    get removable(): boolean {
        const { _props: { removable }, readonly } = this;
        return !readonly && !!removable;
    }

    set removable(val: boolean | ((record: IRecord) => boolean)) {
        const { _props: props } = this;
        if (_.isFunction(val)) {
            props.isRemovable = val;
            props.removable = false;
            return;
        }

        props.removable = val;
    }

    @Input('searchable')
    get searchable(): boolean {
        const { _props: { searchable } } = this;
        return searchable ?? true;
    }

    set searchable(val: boolean) {
        const { _props: props } = this;
        props.searchable = val;
    }

    @Input('readonly')
    get readonly(): boolean {
        const { _props: { readonly } } = this;
        return !!readonly;
    }

    set readonly(val: boolean) {
        const { _props: props, _props: { readonly } } = this;
        if (val == readonly) return;
        props.readonly = val;
    }

    @Input('autosave')
    get autosave(): boolean {
        const { _props: { autosave }, readonly } = this;
        return !readonly && !!autosave;
    }

    set autosave(val: boolean) {
        const { _props: props, _props: { autosave } } = this;
        if (val == autosave) return;

        props.autosave = val;
    }

    @Input('nopanel')
    get nopanel(): boolean {
        const { _props: { nopanel } } = this;
        return !!nopanel;
    }

    set nopanel(val: boolean) {
        const { _props: props, _props: { nopanel } } = this;
        if (val == nopanel) return;

        props.nopanel = val;
    }

    @Input('noheader')
    get noheader(): boolean {
        const { _props: { noheader } } = this;
        return !!noheader;
    }

    set noheader(val: boolean) {
        const { _props: props, _props: { noheader } } = this;
        if (val == noheader) return;
        props.noheader = val;
    }

    @Input('managable')
    get managable(): boolean {
        const { _props: { managable }, readonly } = this;
        return !readonly && !!managable;
    }

    set managable(val: boolean) {
        const { _props: props, _props: { managable } } = this;
        if (val == managable) return;
        props.managable = val;
    }

    get headers(): IHeaders | undefined {
        const { _props: { headers } } = this;
        return headers;
    }

    set headers(val: IHeader[]) {
        const { _props: props, _props: { preheaders } } = this;
        if (val == preheaders) return;
        props.preheaders = val;

        const { entity, es } = this;
        const headers: IHeaders = (props.headers = Object.setPrototypeOf({
            fieldOf(this: IHeaders, key: string, record: IRecord): IFielder | undefined {
                const { indexed } = this;
                const header = indexed[key];
                if (!header) return;

                const { fielderhandler } = header;
                return fielderhandler?.getFielder(record);
            }
        }, _.extend(XArray.create<IHeader, 'key'>('key'), {
            ids: [] as string[],
            mobile: _.extend([] as IHeader[], {
                idswithexpand: [] as string[],
                ids: [] as string[],
            })
        })));

        const fullids = headers.ids;
        const fullheaders = headers;
        const mobileheaders = headers.mobile;
        const mobileids = headers.mobile.ids;
        const mobileidswithexpand = headers.mobile.idswithexpand;

        (val || []).forEach(header => {
            const _header: IHeader = { ...header };
            const { action, invisible } = _header;
            if (invisible) return;

            if (!action) {
                const handler = (_header.fielderhandler = Schema.Form.fieldHandlerOf(
                    _header, entity, es
                ));

                if (!_header.name && !handler?.schema) {
                    return;
                }
            }

            const { key } = _header;
            const { immobilable } = _header;
            const colkey = (_header.colkey = (
                _.isString(key) ? key : _header.$cid
            ));

            fullheaders.push(_header);
            fullids.push(colkey);

            if (!immobilable) {
                mobileheaders.push(_header);
                mobileids.push(colkey);
            }
        });

        const { expandColumn } = this;
        mobileidswithexpand.push(...mobileids, expandColumn)
    }

    @Input('noform')
    get noform(): boolean {
        const { entity: { type, deps, flds } = {} } = this;
        if (!(type || deps || flds)) {
            return true;
        }

        const { _props: { noform = false } } = this;
        return noform;
    }

    set noform(val: boolean) {
        const { _props: props, _props: { noform = false } } = this;
        if (val == noform) return;
        props.noform = val;
    }

    @Input('viewmode')
    get viewMode(): Schema.Dashboard.Block.Table.ViewMode {
        const { isMobile, _props: { viewMode = isMobile ? 'card' : 'table' } } = this;
        return isMobile ? 'card' : viewMode;
    }

    set viewMode(val: 'table' | 'card' | undefined) {
        const { _props: props, _props: { viewMode } } = this;
        if (val == viewMode) return;
        props.viewMode = val;
    }

    @Input('exporter')
    get exporter(): {
        file?: string,
        sheet?: string
    } {
        const { _props: { exporter } } = this;
        return exporter ?? {};
    }

    set exporter(val: {
        file?: string,
        sheet?: string
    }) {
        const { _props: props, _props: { exporter } } = this;
        if (val == exporter) return;
        props.exporter = val;
    }

    get recordsets() {
        const { es } = this;
        return es.recordsets;
    }

    get recordset(): IRecordset | IRecord[] | undefined {
        const { recordsets, pueditor: { entity, value } = {} } = this;
        if (value) return value;

        const ref = recordsets?.[entity ?? ''];
        if (_.isArray(ref)) return ref;
    }

    get entities() {
        const { es } = this;
        return es.entities;
    }

    get entity(): Schema.IEntity | undefined {
        const { entities, pueditor: { entity } = {} } = this;
        return entities?.[entity ?? ''];
    }

    get matDataSource(): MatTableDataSource<object> {
        const { _props: props, searcher } = this;

        return props.matDataSource = props.matDataSource || (
            props.matDataSource = new MatTableDataSource(),
            props.matDataSource.sortingDataAccessor = (
                (data: object, sortHeaderId: string) => {
                    const { headers: { indexed } = {} } = this;
                    const header = indexed?.[sortHeaderId];
                    if (!header) return 0;

                    return this.contentOf(header, data as IRecord, true);
                }
            ),
            props.matDataSource.filter = searcher,
            props.matDataSource
        )
    }

    get isMobile(): boolean {
        return !!this.sys.isMobile;
    }

    get editting(): {
        source?: IRecord
    } {
        const { _props: props } = this;
        const _this = this;

        const editting = <PuEditorComponent['editting'] & {
            _source?: IRecord
        }>(props.editting || (
            props.editting = {
                get source() {
                    const { recordset } = _this;
                    const source = editting._source;
                    return source || _this.recordOf(recordset?.[0]);
                },
                set source(val: IRecord | undefined) {
                    const cur = editting._source;
                    if (cur == val) return;

                    _this.tryComplete((completed: boolean) => {
                        completed && (editting._source = val);
                    })
                }
            }
        ));

        return editting;
    }

    get searcher(): string {
        const { _props: { searcher = '' } } = this;
        return searcher;
    }

    set searcher(val: string) {
        const { _props: props, _props: { searcher } } = this;
        if ((val = val ?? '') == searcher) return;

        const { matDataSource } = this;
        matDataSource.paginator?.firstPage();
        matDataSource.filter = val;
        props.searcher = val;
    }

    get expandColumn() {
        return _ExpandColumnKey;
    }

    get expand(): {
        toggle(host: object, record?: IRecord | string): boolean;
        get(host: object, record?: IRecord | string): boolean;
        close(host: object, record?: IRecord | string): void;
        open(host: object, record?: IRecord | string): void;
    } {
        const _this = this, bag = '$expand';
        const { _props: props } = this;

        const multi = () => ({
            toggle(host: object, record?: IRecord | string): boolean {
                record = _this.recordOf(record);
                if (!record) return false;

                const { $cid } = record;
                return _.get(_.update(host, [bag, $cid], (v) => (
                    !!!v
                )), [bag, $cid], false);
            },
            get(host: object, record?: IRecord | string): boolean {
                record = _this.recordOf(record);
                if (!record) return false;

                const { $cid } = record;
                return _.get(host, [bag, $cid], false);
            },
            close(host: object, record?: IRecord | string) {
                record = _this.recordOf(record);
                if (!record) return;

                const { $cid } = record;
                _.set(host, [bag, $cid], false);
            },
            open(host: object, record?: IRecord | string) {
                record = _this.recordOf(record);
                if (!record) return;

                const { $cid } = record;
                _.set(host, [bag, $cid], true);
            }
        })

        const single = () => ({
            toggle(host: object, record?: IRecord | string): boolean {
                record = _this.recordOf(record);
                if (!record) return false;

                const { $cid } = record;
                return $cid == _.get(_.update(host, [bag], (v) => (
                    v == $cid ? null : $cid
                )), [bag], null);
            },
            get(host: object, record?: IRecord | string): boolean {
                record = _this.recordOf(record);
                if (!record) return false;

                const { $cid } = record;
                return $cid == _.get(host, [bag], null);
            },
            close(host: object, record?: IRecord | string) {
                record = _this.recordOf(record);
                if (!record) return;

                const { $cid } = record;
                if ($cid == _.get(host, [bag], null)) {
                    _.set(host, [bag], null);
                }
            },
            open(host: object, record?: IRecord | string) {
                record = _this.recordOf(record);
                if (!record) return;

                const { $cid } = record;
                _.set(host, [bag], $cid)
            }
        })

        return props.expand || (
            props.expand = single()
        )
    }

    constructor(
        public puExporter: PuEditorExporter,
        public translate: TranslateService,
        public tpls: PuTemplateService,
        public es: PuSchemaService,
        public datePipe: DatePipe,
        public sys: PuSysService
    ) {
    }

    isRemovable(record: IRecord): boolean {
        const { _props: { isRemovable }, entity } = this;
        return !!entity && !!isRemovable?.(record);
    }

    save(records?: IRecord | IRecord[]): void {
        const { es, sys, recordset, saved, entity, _props: props } = this;
        const { pueditor: { entity: entityname } = {} } = this;
        if (!entity) return;

        if (records && !_.isArray(records)) {
            records = [records];
        }

        const tosave: IRecord[] = [];
        const _records = records ?? recordset ?? [];
        for (const record of _records) {
            if (!_.isObject(record)) {
                continue;
            }

            const form = formOf(record);
            if (!form && record.id) {
                // nochanged saved item;
                continue;
            }

            if (!form?.$isvalid) {
                // sys to fill full
                if (props.dlgalert) return;

                const sub = (props.dlgalert = sys?.prompt('needfillout')).afterClosed().subscribe({
                    complete() {
                        delete props.dlgalert;
                        tick(() => sub?.unsubscribe());
                    }
                });

                return;
            }

            if (form?.dirty) {
                tosave.push(record);
            }
        }

        if (tosave.length <= 0) {
            return;
        }

        const sub = es.save(tosave, entityname!).subscribe({
            next(value: boolean | IRecord[]): void {
                if (_.isBoolean(value)) return;
                if (value.isEmpty) return;

                value.forEach(rcd => {
                    const form = formOf(rcd);
                    form?.$commit();
                })

                saved.emit({ req: tosave, resp: value, schema: entity })
            },
            complete() {
                tick(() => sub?.unsubscribe());
            }
        })
    }

    cancel(records?: IRecord | IRecord[]): void {
        const { autosave } = this;
        if (autosave) return;

        if (records && !_.isArray(records)) {
            records = [records];
        }

        const { recordset, editting } = this;
        const _records = records ?? recordset ?? [];
        for (const record of _records) {
            if (!record.id) {
                recordset?.remove(record);
                if (editting.source == record) {
                    delete (editting as any)['_source'];
                }
            } else {
                const form = formOf(record);
                form?.$rollback();
            }
        }
    }

    remove(record: IRecord, records?: IRecord[]) {
        const { es, _props: props, sys, removed, entity, recordset } = this;
        if (props.dlgalert || !_.isObject(record) || !this.isRemovable(record)) return;

        const { pueditor: { entity: entityname } = {} } = this;
        const idxkey = entity!.index?.key ?? 'id';
        records = records ?? recordset ?? [];

        const sub = (
            props.dlgalert = sys?.prompt('delete', () => {
                const idxval = record[idxkey] as string;

                if (!idxval) {
                    records.remove(record);
                    if (record == props.editting?.source) {
                        delete (props.editting as any)['_source'];
                    }
                }

                const sub = es?.delete(record, entityname!).subscribe({
                    complete() {
                        tick(() => sub?.unsubscribe());
                    },
                    next(succeed: boolean) {
                        if (succeed) {
                            if (record == props.editting?.source) {
                                delete (props.editting as any)['_source'];
                            }

                            removed.emit({ record, schema: entity });
                        }
                    }
                });

            }, undefined, { count: 1 })
        ).afterClosed().subscribe({
            complete() {
                delete props.dlgalert;
                tick(() => sub?.unsubscribe());
            }
        });
    }

    isValid(record: IRecord | string): boolean {
        record = this.recordOf(record)!;
        if (!record) return false;

        const form = formOf(record);
        if (!form) return true; // !!record.id;
        if (!form.$isvalid) return false;

        const { autosave } = this;
        if (autosave && form.dirty) {
            this.save([record]);
        }

        return true;
    }

    createRecord(recordset: IRecordset | IRecord[] | undefined, schema: ISchema | undefined): Observable<IRecord | undefined> {
        const subject: Subject<IRecord | undefined> = new Subject();
        const observable = subject.pipe(
            shareReplay({
                refCount: false,
                bufferSize: 1,
            })
        );

        const sub = observable.subscribe({
            complete() {
                tick(() => sub?.unsubscribe());
            }
        })

        this.tryComplete((completed: boolean) => {
            if (!completed) {
                tick(() => {
                    subject.complete();
                })

                return;
            }

            const { key, name } = schema || {};
            const record: IRecord = {
                key: key?.toString(),
                name: name,
            };

            const { created } = this;
            created.emit({ record, schema })
            recordset?.unshift(record);
            subject.next(record);

            tick(() => {
                subject.complete();
            })
        })

        return observable;
    }

    onRowSelect(row: IRecord | undefined): IRecord | undefined {
        const { editting, editting: { source } } = this;
        row = this.recordOf(row);

        if (row != source) {
            editting.source = row;
        }

        return row;
    }

    tryComplete(): boolean | Observable<boolean>;
    tryComplete(record: IRecord): boolean | Observable<boolean>;
    tryComplete(action: ((val: boolean) => void)): boolean | Observable<boolean>;
    tryComplete(record: IRecord, action: ((val: boolean) => void)): boolean | Observable<boolean>;
    tryComplete(rcdoract?: IRecord | ((val: boolean) => void), action?: ((val: boolean) => void)): boolean | Observable<boolean> {
        action = _.takeIf(_.isFunction, rcdoract, action);

        const _record = _.takeIf(
            <(val: any) => val is IRecord>_.isObjectLike,
            rcdoract, this.editting.source
        );

        if (!_record) {
            action?.(true);
            return true;
        }

        const { sys, autosave } = this;
        const form = formOf(_record);
        if (form && !form.$isvalid) {
            sys?.prompt('needfillout');
            action?.(false);
            return false;
        }

        if (!autosave && form && form.dirty) {
            if (!sys) return false;
            return sys.prompt('save', () => {
                action?.(false);
                return false;
            }, () => {
                this.cancel(_record);
                action?.(true);
                return true;
            }).afterClosed();
        }

        action?.(true);
        return true;
    }

    filterPredicate(headers: IHeader[]): (data: object, filter: string) => boolean {
        return this.filter.bind(this, headers);
    }

    recordOf(record?: IRecord | string): IRecord | undefined {
        if (_.isObject(record)) return record;
        if (!record) return;

        const { recordsets, pueditor: { entity = '' } = {} } = this;
        return recordsets?.[entity]?.indexed?.[record];
    }

    contentOf(header: IHeader, record: IRecord, raw: boolean = false): string | number {
        const _record = this.recordOf(record);
        if (!_record) return "";

        const field = header.fielderhandler?.getFielder(_record);
        const { schema, type } = field || {};
        const { translate } = this;

        switch (type) {
            case "select": {
                const types = schema?.type as ISchemaset;
                return types?.indexed[field?.field.value]?.name ?? '';
            }

            case "date": {
                if (raw) return (field?.value as Date)?.valueOf();
                return this.datePipe.transform(field?.value) ?? '';
            }

            case "bool": {
                if (raw) return field?.value;
                return translate.instant(`general.${field?.value ? "yes" : "no"}`)
            }
        }

        if (raw) return field?.value;
        const value = field?.value ?? '';
        return _.isString(value) ? value : value.toString();
    }

    templateOf(header: IHeader, type: 'card' | 'table'): TemplateRef<any> | undefined {
        const template = header?.template;
        if (!template) return;

        const { tpls } = this;
        return tpls.get(`${type}-${template}`) || tpls.get(template);
    }

    filter(headers: IHeader[], data: object, filter: string): boolean {
        const record = this.recordOf(data as any);
        if (!filter || !record) return true;

        return headers.findIndex(h => {
            const text = this.contentOf(h, record);
            return (text as string).includes(filter);
        }) >= 0;
    }

    exportTable(exporter: MatTableExporterDirective, options?: ExcelOptions) {
        exporter.exportTable('xlsx', { ...options, cellStyles: true });
    }
}

export namespace PuEditorComponent {
    export type IHeader = Schema.IHeader & {
        colkey?: string
    }

    export interface IHeaders extends XArray<IHeader, IHeader, {
        [key: string]: IHeader
    }> {
        fieldOf(key: string, record: IRecord): IFielder | undefined,
        ids: string[],
        mobile: (IHeader[] & {
            idswithexpand: string[],
            ids: string[]
        })
    }
}