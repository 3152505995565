import { Component, Inject, OnInit, ViewChild, AfterViewInit, OnDestroy, Injector, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ECElementEvent, EChartsOption, LegendComponentOption, TitleComponentOption } from 'echarts';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { Exec as TExec, Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { GovEditorComponent } from '../../utils/view/gov.editor.component/gov.editor.component';
import { WorkItemListComponent } from '../../workitemlist/view/workitemlist.component';
import { ResultData, ResultsComponent } from '../../results/view/results.component';
import { ProblemListComponent } from '../../problemlist/view/problemlist.component';
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { PuSysService } from '../../utils/puzzle/pu.service/pu.sys.service';
import { Backface } from '../../../app/application/service/backface/config';
import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';
import { Report } from '../../application/service/backface/report';
import { GovEditor } from '../../utils/view/model/form.editting';
import { PlanComponent } from '../../plan/view/plan.component';
import { Exec } from '../../application/service/backface/exec';
import { Prj } from '../../application/service/backface/prj';
import { ProjectSupervise } from './projectsupervise';
import { Differ } from '../../utils/libs/differ';
import { Editor } from '../../utils/libs/editor';
import { ProjectExec } from './projectexec';

const Value = Editor.Value;

@Component({
    templateUrl: "./projectdetail.component.html",
    styleUrls: ['./projectdetail.component.scss'],
    providers: [PuTemplateService]
})
export class ProjectDetailComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    private _props = Prop.Of<ProjectDetailComponent, {
        _headerids: string[];
        _handlers: {
            projectsupervise: ProjectSupervise,
            projectexec: ProjectExec
        }
        differ: Differ
    }>(this);

    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = true;
    readonly noinputtxt: boolean = true;

    @ViewChild(GovEditorComponent, { static: false })
    goveditor?: GovEditorComponent;

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    get component() {
        return ResultsComponent;
    }

    get handlers() {
        const { _props: props, _props: { _handlers } } = this;
        if (_handlers) return _handlers;

        return (props._handlers || (
            props._handlers = {
                projectexec: new ProjectExec(this),
                projectsupervise: new ProjectSupervise(this)
            }
        ))
    }

    get summery(): TSys.IDatasetModule {
        const { _props: props, auth: { me: { priviledges } = {} } } = this;
        return props.summery = props.summery || {
            dict: { title: "projectdetail.status", icon: "visibility", },
            accessable: !!(priviledges?.plan?.project),
            template: 'project.summery',
            key: 'projectdetailsummery'
        }
    }

    get actor(): TSys.IDatasetModule {
        const { _props: props, auth: { me: { priviledges } = {} } } = this;
        return props.actor = props.actor || {
            dict: { title: "project.actor", icon: "group", },
            accessable: !!(priviledges?.plan?.project),
            template: 'project.actor',
            key: 'projectdetailactor'
        }
    }

    get inspector(): TSys.IDatasetModule {
        const { _props: props, auth: { me: { priviledges } = {} } } = this;
        return props.inspector = props.inspector || {
            dict: { title: "project.inspector", icon: "group", },
            accessable: !!(priviledges?.plan?.project),
            template: 'project.inspector',
            key: 'projectdetailinspector'
        }
    }

    get execute(): TSys.IDatasetModule {
        const { handlers: { projectexec } } = this;
        return projectexec.execute;
    }

    get executeaudit(): TSys.IDatasetModule {
        const { handlers: { projectexec } } = this;
        return projectexec.audit;
    }

    get supervise(): TSys.IDatasetModule {
        const { handlers: { projectsupervise } } = this;
        return projectsupervise.execute;
    }

    get superviseaudit(): TSys.IDatasetModule {
        const { handlers: { projectsupervise } } = this;
        return projectsupervise.audit;
    }

    get problem(): TSys.IDatasetModule {
        const { handlers: { projectsupervise } } = this;
        return projectsupervise.problem;
    }

    get modules(): TSys.IDatasetModules {
        const { execute, executeaudit, supervise, superviseaudit, problem } = this;
        const { _props: props, app, project, summery, actor, inspector } = this;

        return props.modules || (props.modules = app.createDatasetModules(
            summery,
            project?.actor ? actor : undefined,
            project?.inspector ? inspector : undefined,
            ...(project?.ispatched ? [] : [
                project?.projecttype ? execute : undefined,
                project?.projecttype ? executeaudit : undefined,
                project?.projecttype ? supervise : undefined,
                project?.projecttype ? superviseaudit : undefined,
                problem
            ])
        ))
    }

    get stageSummery(): TSys.IDatasetModule {
        const { _props: props, project: { ispatched } = {} } = this;

        return props.stageSummery || (props.stageSummery = {
            key: 'projectdetailstagesummery',
            headers: XArray.createIf<TSys.IDatasetHeader, 'key'>('key', ispatched ? [] : [{
                key: 'stage',
                title: 'stage.name',
                type: Value.Type.text,
                readonly: true
            }, {
                key: 'audit',
                title: 'audit.name',
                type: Value.Type.richtext,
                readonly: true
            }, {
                key: 'status',
                title: 'stage.status',
                type: Value.Type.text,
                readonly: true
            }]),
            rows: []
        })
    }

    get currentModule() {
        return this.goveditor?.currentmodule;
    }

    get project(): Prj.Project | undefined {
        return (this.data.object instanceof Prj.Project ? this.data.object : undefined);
    }

    get Report() {
        return Report;
    }

    get budgetoption(): EChartsOption {
        const { _props: props } = this;
        return (props.budgetoption = (props.budgetoption || Report.ReportTemplate.ChartBar));
    }

    get checkoption(): EChartsOption {
        const { _props: props, app: { report: { supervisemixlinebarcolors } } } = this;
        return (props.checkoption = (props.checkoption || Report.ReportTemplate.MixedLineBar(supervisemixlinebarcolors, 1, 3)));
    }

    get resultData(): ResultData {
        const { _props: props, app, app: { prj } } = this;
        return props.resultData || (props.resultData = {
            object: prj.workitems.find(ws => ws.type == TPrj.IWorkitem.Type.supervise) as Object as Prj.ProjWorkitem,
            results: Report.buildResult(app),
            title: '',
        });
    }

    get lastResultData(): ResultData {
        return this.resultData;
    }

    get selectedYearCost(): number {
        const { _props: props, app: { dict: { currentYear } } } = this;
        return props.selectedYearCost ?? currentYear;
    }

    set selectedYearCost(val: number) {
        const { _props: props } = this;
        if (val == props.selectedYearCost) return;

        props.selectedYearCost = val;

        const { selectedYearCost } = this;
        this.loadYearMonthCostDatabyYear(selectedYearCost, this.yearmonthcostchartParam);
    }

    get prjyearmonthcostoption(): EChartsOption {
        const { _props: props, app, app: { report: { } } } = this;
        return (props.prjyearmonthcostoption = (props.prjyearmonthcostoption || Report.ReportTemplate.ChartStackLine));
    }

    readonly bwsparamset: boolean = false; //工作项参数设置
    readonly bverifymodify: boolean = false; //审批项更新
    readonly bnormalmodify: boolean = false; //普通项更新
    readonly prjstatuscount: Array<TSys.IStatusCount> = new Array<TSys.IStatusCount>();

    constructor(
        public app: AppService,
        public sys: PuSysService,
        public auth: AuthService,
        public override router: ActivatedRoute,
        public httpClient: HttpClient,
        public dialog: PuDialogService,
        public injector: Injector,

        @Optional()
        dialogRef: MatDialogRef<any, any>,

        @Optional()
        plan: PlanComponent,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            from: any
            object: Prj.Project,
            header: TSys.IDatasetHeader,
            dataset: TSys.IDatasetModule
        }
    ) {
        super(router, 'projectdetail', {
            selectedYear: app.dict.currentYear
        });

        this.editting = this.editting.bind(this);

        if (this.project?.ispatched) return;

        const { app: { auth: { me: { priviledges } = {} } } } = this;
        this.bnormalmodify = !!priviledges?.plan?.project?.projectdetails?.normalmodify;
        this.bverifymodify = !!priviledges?.plan?.project?.projectdetails?.verifymodify;
        this.bwsparamset = !!priviledges?.plan?.project?.projectdetails?.wsparamset;

        if (dialogRef && plan) {
            const { cdf } = plan;
            cdf?.detach();

            // temporarily switch off the change detection of parent PlanComponent instance for performance optimization.
            const sub = cdf && dialogRef.beforeClosed().subscribe({
                complete() {
                    tick(() => sub?.unsubscribe());
                    cdf?.reattach();
                }
            })
        }
    }

    ngOnInit() {
        const { handlers: { projectexec, projectsupervise }, app: { dict: { statuslist } } } = this;
        projectsupervise?.ngOnInit();
        projectexec?.ngOnInit();

        const { prjstatuscount } = this;
        statuslist.forEach(s => {
            prjstatuscount.push(
                { status: s.status, count: 0, percent: 0 } as TSys.IStatusCount
            );
        });
    }

    ngAfterViewInit(): void {
        this.initBudget();
        this.initYearCost();
        this.initSupervise();
        this.loadLastSuperviseResult();
    }

    ngOnDestroy(): void {
        const { _props: { reporter, _handlers: { projectexec, projectsupervise } } } = this;
        projectsupervise?.ngOnDestroy();
        projectexec?.ngOnDestroy();
        reporter?.ngOnDestroy();
    }

    protected override dosearch(changed: GovEditor.ToolBar.Changed, content: GovEditor.ToolBar.Content): void {
        if (changed.selectedYear == null) return;
        this.loadSuperviseDatabyYear(changed.selectedYear);
    }

    onCurrentModule(module: TSys.IDatasetModule) {
        const { summery, execute, executeaudit, supervise, superviseaudit, problem } = this;
        const { handlers: { projectexec, projectsupervise }, selectedYear } = this;

        switch (module) {
            case summery:
                this.loadYearMonthCostDatabyYear(this.selectedYearCost, this.yearmonthcostchartParam);
                this.loadSuperviseDatabyYear(selectedYear!);
                this.loadLastSuperviseResult();
                this.loadStageSummary();
                return;

            case execute:
            case executeaudit:
                return projectexec.onCurrentModule(module);

            case problem:
            case supervise:
            case superviseaudit:
                return projectsupervise.onCurrentModule(module);
        }
    }

    editting<
        T extends Prj.ProjWorkitem | Exec.Problem | Exec.AuditResult
    >(obj: T, def?: ((obj: T) => GovEditor.IEditting)): GovEditor.IEditting | undefined {
        const { currentModule, handlers: { projectexec, projectsupervise } } = this;
        const { execute, executeaudit, supervise, superviseaudit, problem } = this;

        switch (currentModule) {
            case execute:
            case executeaudit:
                return projectexec.editting(obj, def);

            case problem:
            case supervise:
            case superviseaudit:
                return projectsupervise.editting(obj, def);
        }
    }

    initBudget() {
        const { app: { report: { prjcostChartConfig } }, budgetoption } = this;
        const series = budgetoption.series as Report.ECSeries<'bar'>[];
        const xAxis = budgetoption.xAxis as Report.ECXAxis<'category'>;
        const yAxis = budgetoption.yAxis as Report.ECYAxis<'value'>;
        const legend = budgetoption.legend as LegendComponentOption;
        const title = budgetoption.title as TitleComponentOption;

        const { app: { lang: { general: { annual, bunit }, budget: { amount, consume } } } } = this;
        const total = Report.ReportTemplate.ChartBarItem;
        const year = Report.ReportTemplate.ChartBarItem;
        const { project: { budgets } = {} } = this;

        total.name = `${annual}${amount}(${bunit})`;
        total.data = budgets?.map(budget => budget.amount);
        total.id = 'total';

        year.name = `${annual}${consume}(${bunit})`;
        year.data = budgets?.map(budget => budget.consume);
        year.id = 'year';

        series[0] = total;
        series[1] = year;

        title.text = prjcostChartConfig.title;
        xAxis.data = (budgets?.map(budget => budget.start?.getFullYear().toString()).filter(_.isString) ?? []);
        xAxis.name = prjcostChartConfig.xAxis;
        yAxis.name = prjcostChartConfig.yAxis;
    }

    loadStageSummary() {
        const { project, reporter, app: { dict: { AllStatus } } } = this;

        reporter.report("/report/workitem_count_per_project_stage_status_by_project_attribute", (res: Report.IWorkitemCountPerProjectStageStatus) => {
            if (res == null) { return; }

            const { count: rescount } = res;
            if (rescount != undefined) {
                //update count card
            }

            const { stage: resstages } = res;
            if (resstages != undefined) {
                const rows = (this.stageSummery.rows || (this.stageSummery.rows = []));

                //update stage
                rows.reset();
                this.prjstatuscount.forEach(ps => {
                    ps.count = 0;
                    ps.percent = 0;
                });

                let iTotal = 0;
                project?.projecttype?.stages?.forEach(stage => {
                    const audititem: TSys.IStageSumItem = {
                        wssum: new Array<TSys.IWSSumItem>(),
                        project: project,
                        stage: stage
                    };

                    const resstage = resstages.find(s => s.id == stage.id);

                    TPrj.IWorkitem.Status.forEach(element => {
                        if (!_.isNumber(element)) return;

                        const wsitem = <TSys.IWSSumItem>{
                            prjlist: [project.id],
                            status: element,
                            count: 0,
                        };

                        if (resstage != undefined) {
                            const key = Value.fromEnum(element, TPrj.IWorkitem.Status);
                            const count = key ? resstage.count[key] : 0;
                            wsitem.count = count ?? 0;
                        };

                        audititem.wssum!.push(wsitem);
                        const prjscnt = this.prjstatuscount.find(s => s.status == element);
                        if (prjscnt) {
                            prjscnt.count += wsitem.count!;
                            prjscnt.percent = 0;
                            iTotal += wsitem.count!;
                        }
                    });

                    const { status = 'notstart' } = resstage || {};
                    const row = {
                        status: AllStatus.indexed[status]?.title,
                        stage: stage.name,
                        audit: audititem
                    }

                    rows.push(row);
                });

                this.prjstatuscount.forEach(ps => {
                    ps.percent = (iTotal == 0) ? 0 : (Math.round(ps.count / iTotal * 1000) / 10);
                });
            }
        }).query({
            "project": project?.id && [{ "id": project.id }] || undefined,
        });
    }

    onWorkItemDetails(data: GovEditor.IWorkItemSumData) {
        if (data == null || data.stageitem == null || data.workitemsum == null) {
            return;
        }

        const { app: { dict: { AllStatus }, lang: { general: { item = '' } } }, project } = this;
        const { workitemsum: { status, count }, stageitem: { stage } = {} } = data;

        const wsstatus = status && AllStatus.indexed[status] || undefined;
        const header = `${stage?.name}/${wsstatus?.title}/${count}${item}`;

        WorkItemListComponent.open(this.dialog, {
            workitemstatus: [wsstatus?.key],
            prjidlist: [project?.id],
            stageidlist: [stage?.id],
            title: project?.name,
            header: header,
        }, this.injector)
    }

    initSupervise() {
        if (this.project?.ispatched) return;

        const { app, app: { report: { prjcostChartConfig } }, checkoption } = this;
        const series = checkoption.series as Report.ECSeries<'bar'>[];
        const xAxis = checkoption.xAxis as Report.ECXAxis<'category'>[];
        const yAxis = checkoption.yAxis as Report.ECYAxis<'value'>[];
        const legend = checkoption.legend as LegendComponentOption;
        const title = checkoption.title as TitleComponentOption;

        const { app: { dict: { months }, lang: { report } }, selectedYear } = this;
        title.text = report.supervisesummary;

        legend.data = (legend.data || []);
        legend.data.reset();

        legend.data.add([report.refproject]);
        legend.data.add([report.problemnotfixed]);
        legend.data.add([report.supervisecount]);
        legend.data.add([report.problemcount]);

        xAxis[0].data = months.map(m => m.name);

        yAxis[0].name = report.refproject;
        yAxis[1].name = report.problemnotfixed;
        yAxis[2].name = report.supervisecount;
        yAxis[3].name = report.problemcount;

        yAxis[0].max = 2;
        yAxis[1].max = 20;
        yAxis[2].max = 10;
        yAxis[3].max = 10;

        series[0].name = report.refproject;
        series[1].name = report.problemnotfixed;
        series[2].name = report.supervisecount;
        series[3].name = report.problemcount;

        series[0].id = ('project');
        series[1].id = ('problemnotfixed');
        series[2].id = ('supervisecount');
        series[3].id = ('problemcount');

        series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        series[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        series[3].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        this.loadSuperviseDatabyYear(selectedYear!);
    }

    loadSuperviseDatabyYear(year: number) {
        this.reporter.report("/report/supervise_per_month_by_project_attribute", (res: Report.ISupervisePerMonth) => {
            if (res == null) { return; }

            const { count: rescount } = res;
            if (rescount != undefined) {
                //update count card
            }

            const { month: resmonths } = res;
            const { checkoption, selectedYear } = this;
            const series = checkoption.series as Report.ECSeries<'bar'>[];
            const xAxis = checkoption.xAxis as Report.ECXAxis<'category'>[];
            const yAxis = checkoption.yAxis as Report.ECYAxis<'value'>[];
            const legend = checkoption.legend as LegendComponentOption;
            const title = checkoption.title as TitleComponentOption;

            if (resmonths != undefined) {
                //update stage
                for (let index = 1; index <= 12; index++) {
                    const month = `${selectedYear}-${index < 10 ? '0' : ''}${index}`;
                    const resmonth = resmonths.find(m => m.month == month);

                    if (resmonth == null) {
                        series[0].data![index - 1] = 0;
                        series[1].data![index - 1] = 0;
                        series[2].data![index - 1] = 0;
                        series[3].data![index - 1] = 0;
                        continue;
                    };

                    series[0].data![index - 1] = resmonth.count.project.nonpatched ?? 0;
                    series[1].data![index - 1] = resmonth.count.problem.tofix ?? 0;
                    series[2].data![index - 1] = resmonth.count.count ?? 0;
                    series[3].data![index - 1] = resmonth.count.problem.count ?? 0;
                }

                Report.RefreshEchart("prjsuperviseoption", checkoption);
            }
        }).query({
            "project": this.project?.id && [{ "id": this.project?.id }] || undefined,
            "year": [year, year]
        });
    }

    onClickCharBar(params?: ECElementEvent): void {
        if (!_.isNumber(params?.value) || params.value <= 0) return;

        const title = this.data?.object?.fullName ?? '';
        const { seriesId, dataIndex, name, seriesName, value } = params;
        const { selectedYear, reporter, app: { lang: { general: { year = '', unit = '' } } } } = this;
        const header = `${selectedYear}${year}/${name}/${seriesName}/${value}${unit}`;

        const month = dataIndex + 1;
        const start = `${selectedYear}-${month < 10 ? '0' : ''}${month}-01 00:00:00`;
        const end = `${selectedYear}-${(month + 1) < 10 ? '0' : ''}${month == 12 ? `${month}-31 23:59:59` : `${month + 1}-01 00:00:00`}`;

        if (seriesId == 'problemcount' || seriesId == 'problemnotfixed') {
            ProblemListComponent.open(this.dialog, {
                title: title,
                header: header,
                prjidlist: [this.data?.object?.id],
                date: [start, end],
                type: [seriesId == 'problemcount' ? 'newfound' : 'notfix']
            }, this.injector)
        }

        if (seriesId == 'supervisecount') {
            reporter.report("/entity/results/retrieve", (res: TExec.IResult[] = []) => {
                if (res == null) { return; }

                const { app, dialog } = this;
                ResultsComponent.open(dialog, {
                    title: `${title}/${header}`,
                    object: this.app.prj.workitems.find(ws => ws.type == TPrj.IWorkitem.Type.supervise),
                    results: Report.buildResult(app, res)
                }, this.injector)
            }).query({
                "project": [this.data?.object?.id],
                "date": [start, end],
                "workitem": '77',
            });
        }
    }

    loadLastSuperviseResult(): void {
        this.reporter.report("/xretrieve/lastsupervise", (res: TExec.IResult[] = []) => {
            if (res == null) { return; }

            const { app, lastResultData } = this;
            lastResultData.results = Report.buildResult(app, res);
        }).query({
            "project": [this.data?.object?.id]
        });
    }

    readonly yearmonthcostchartParam: Report.IChartParam = {
        charttype: Report.eChartType.line,
        chartid: "prjyearmonthcost",
        option: Report.ReportTemplate.ChartStackLine,
        reqURL: "/report/costconsume_per_month_by_project_attribute",
        chartCfg: this.app.report.monthChartConfig,
        legends: this.app.report.yearcostinvest,
        xAxisItems: this.app.dict.months,
        legtag: "month",
        dataMap: new Map()
    }

    initYearCost() {
        if (!!this.project?.ispatched) return;

        const { yearmonthcostchartParam } = this;
        const { app: { dict: { monthcostdefault } } } = this;

        Report.InitChartParam(yearmonthcostchartParam);

        const { option } = yearmonthcostchartParam;
        const series = option.series as Report.ECSeries<'bar'>[];
        series[0].data = monthcostdefault;
        series[1].data = monthcostdefault;
    }

    loadYearMonthCostDatabyYear(year: number, param: Report.IChartParam): void {
        if (param == null) return;
        if (!!this.project?.ispatched) return;

        const { selectedYear } = this;
        this.reporter.report(param.reqURL, (res: Report.ICostConsumePerMonth) => {
            const { chartid, option: ecoption } = param, { selectedYear } = this;
            Report.buildCostConsumePerMonth(selectedYear!, param, res);
            Report.RefreshEchart(chartid, ecoption);
        }).query({
            project: this.project?.id && [{ id: this.project?.id }] || undefined,
            year: [selectedYear!, selectedYear!],
        });
    }

    static open(dialog: PuDialogService, data: {} = {}, injector?: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: ProjectDetailComponent,
            injector: injector,
            maximized: true,
            resiable: true
        }, {
            ...data
        })
    }
}

export namespace ProjectDetailComponent {
    export interface IHandler {
        onCurrentModule(module: TSys.IDatasetModule): void;
        ngOnDestroy(): void;
        ngOnInit(): void;
    }
}