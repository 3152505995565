import { ElementRef, ViewContainerRef } from '@angular/core';

namespace _DOM {
    export class DOMZoomRect extends DOMRectReadOnly implements DOMZoomRect {
        private _props = Prop.Of<DOMZoomRect, {
            orignalRect: DOMRect,
            offPoint: DOMPoint,
            zoom: number,
        }>(this);

        constructor(x?: number, y?: number, width?: number, height?: number, xoff?: number, yoff?: number, zoom?: number) {
            super(x, y, width, height);

            const { _props: props } = this;
            props.orignalRect = DOMRect.fromRect({ x, y, width, height });
            props.offPoint = DOMPoint.fromPoint({ x: xoff, y: yoff });
            props.zoom = zoom ?? 1;
        }

        get orignalRect(): DOMRect {
            return this._props.orignalRect;
        }

        get offPoint(): DOMPoint {
            return this._props.offPoint;
        }

        get zoom(): number {
            return this._props.zoom;
        }

        set zoom(v: number) {
            this._props.zoom = v ?? 1;
        }

        override get x() {
            const { _props: { orignalRect, offPoint, zoom } } = this;
            return orignalRect.x * zoom + offPoint.x;
        }

        override get y() {
            const { _props: { orignalRect, offPoint, zoom } } = this;
            return orignalRect.y * zoom + offPoint.y;
        }

        override get height() {
            const { _props: { orignalRect, zoom } } = this;
            return orignalRect.height * zoom;
        }

        override get width() {
            const { _props: { orignalRect, zoom } } = this;
            return orignalRect.width * zoom;
        }

        override get bottom() {
            return this.y + this.height;
        }

        override get right() {
            return this.x + this.width;
        }

        override get left() {
            return this.x;
        }

        override get top() {
            return this.y;
        }

        override toJSON(): DOMZoomRectInit {
            const { _props: { orignalRect, offPoint, zoom } } = this;

            return {
                x: orignalRect.x,
                y: orignalRect.y,
                width: orignalRect.y,
                height: orignalRect.height,
                xoff: offPoint.x,
                yoff: offPoint.y,
                zoom: zoom
            };
        }

        static override fromRect(other?: DOMZoomRectInit): DOMZoomRect {
            return new DOMZoomRect(other?.x, other?.y, other?.width, other?.height, other?.xoff, other?.yoff, other?.zoom);
        }
    };

    const dombinder = Object.binder();
    export class DOM {
        constructor() {
        }

        static toNative<T extends { new(...args: any[]): Node }>(
            val: ViewContainerRef | ElementRef<Node> | EventTarget | Node | DOM | undefined | null,
            domtype: T
        ): {
            el?: InstanceType<T>,
            doc?: Document,
            wnd?: Window,
        } {
            if (val instanceof ViewContainerRef) {
                val = val.element;
            }

            if (val instanceof ElementRef) {
                val = val.nativeElement;
            }

            if (val instanceof DOM) {
                val = val.bindOf<Node>(val) as Node;
            }

            if (!(val instanceof Node)) {
                return {};
            }

            const doc = (val.nodeType == Node.DOCUMENT_NODE) ? val as Document : (val.ownerDocument || undefined);
            let wnd: Window = doc?.defaultView || (doc as any)?.['parentWindow'], pwnd = wnd;

            if (domtype) {
                do {
                    wnd = pwnd, pwnd = wnd.parent;

                    if (wnd && val instanceof (wnd as any)[domtype.name]) {
                        return {
                            el: val as InstanceType<T>,
                            doc: doc, wnd: wnd
                        }
                    }
                } while (wnd != pwnd)

                return {};
            }

            return {
                el: val as InstanceType<T>,
                doc: doc, wnd: wnd
            }
        }

        static get binder(): Object.IBinder {
            return dombinder;
        }

        static isNode(el: any): el is Node {
            return !!DOM.toNative(el, Node)?.el;
        }

        static isTaggableNode(el: ViewContainerRef | ElementRef<Node> | Node | DOM): boolean {
            const _el = DOM.toNative(el, Node)?.el;
            return !!(_el && !DOM.isTextNode(_el) && !DOM.isCommentNode(_el));
        }

        static isElement(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is Element & Node {
            return !!DOM.toNative(el, Element)?.el;
        };

        static isHTMLElement(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is HTMLElement {
            return !!DOM.toNative(el, HTMLElement)?.el;
        }

        static isTextNode(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is Element & Text & Node {
            return DOM.toNative(el, Node)?.el?.nodeType === Node.TEXT_NODE;
        }

        static isCommentNode(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is Element & Comment & Node {
            return DOM.toNative(el, Node)?.el?.nodeType == Node.TEXT_NODE;
        }

        static toHTMLElement(val: ViewContainerRef | ElementRef<Node> | Node | DOM): undefined | { value: HTMLElement } {
            const elm = DOM.toNative(val, HTMLElement)?.el;
            return elm && { value: elm } || undefined
        }

        static toElement(val: ViewContainerRef | ElementRef<Node> | Node | DOM): undefined | { value: Element } {
            const elm = DOM.toNative(val, Element)?.el;
            return elm && { value: elm } || undefined
        }

        static boundingViewRect(el: ViewContainerRef | ElementRef<HTMLElement> | HTMLElement | DOM | undefined): DOMRect {
            const _el = DOM.toNative(el, HTMLElement)?.el;
            const def = DOMRect.fromRect();
            if (!_el) return def;

            let rectText: DOMRect | undefined;
            if (DOM.isTextNode(_el)) {
                const range: Range = document.createRange();
                range.selectNode(_el);

                rectText = range.getBoundingClientRect();
                range.detach();
            }

            return (
                rectText || _el.getBoundingClientRect() || def
            );
        };

        static boundingDocRect(
            el: ViewContainerRef | ElementRef<HTMLElement> | HTMLElement | DOM | undefined,
            container?: ViewContainerRef | ElementRef<HTMLElement> | HTMLElement | DOM
        ): DOMRect {
            const { el: _el, doc: _doc } = DOM.toNative(el, HTMLElement);
            if (!_el) return DOMRect.fromRect();

            const _container = (DOM.toNative(container, HTMLElement)?.el || _doc?.body)!;
            const rect = DOM.boundingViewRect(_el);

            return DOMRect.fromRect({
                x: rect.left + _container.scrollLeft,
                y: rect.top + _container.scrollTop,
                height: rect.height,
                width: rect.width
            });
        }

        static switchIFrameEvents(silent: boolean, doc: Document = document): void {
            doc.querySelectorAll('iframe').forEach(
                frame => (frame.style.pointerEvents = (silent ? 'none' : ''))
            );
        }

        static polyfill(wnd: Window): void {
            _polyfill(wnd);
        }
    }
}

const PolyfillSlot = Prop.Slot<{ element?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);
    const Element: typeof globalThis.Element = (wnd as any)?.['Element'];
    if (!Element || slot.element) return;
    slot.element = true;

    const classDOMZoomRect: typeof DOMZoomRect = _DOM.DOMZoomRect;
    (wnd as any).DOMZoomRect = classDOMZoomRect;
    const classDOM: typeof DOM = _DOM.DOM;
    (wnd as any).DOM = classDOM;

    const fullscreenElement: PropertyDescriptor | undefined = [
        'fullscreenElement', 'webkitFullscreenElement ', 'msFullscreenElement',
        'mozFullScreenElement ', 'oFullscreenElement'
    ].map(func => Object.getOwnPropertyDescriptor(
        Document.prototype, func
    )).filter(func => !!func)[0];

    const requestFullscreen: Element['requestFullscreen'] = [
        'requestFullscreen', 'webkitRequestFullscreen', 'msRequestFullscreen',
        'mozRequestFullScreen', 'oRequestFullScreen'
    ].map(func => (Element.prototype as any)[func]).filter(func => !!func)[0];

    const exitFullscreen: Element['exitFullscreen'] = [
        'exitFullscreen', 'webkitExitFullScreen', 'msExitFullScreen',
        'mozCancleFullScreen', 'oExitFullScreen'
    ].map(func => (Document.prototype as any)[func]).filter(func => !!func)[0];

    const matches: Element['matches'] = [
        'matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector'
    ].map(func => (Element.prototype as any)[func]).filter(func => !!func)[0];

    let bodyheight: string | undefined, __vh: string | undefined;

    Object.defineProperties(Element.prototype, {
        matches: {
            writable: true, enumerable: false, configurable: true,
            value(this: Element, selectors: string): boolean {
                return matches?.call(this, selectors)
            }
        },
        fullscreenElement: {
            enumerable: false, configurable: true,
            get(this: Element): Element | null {
                return fullscreenElement?.get?.call(document) ?? null;
            }
        },
        requestFullscreen: {
            writable: true, enumerable: false, configurable: true,
            value(this: Element, options?: FullscreenOptions | undefined): Promise<void> {
                return requestFullscreen?.call(this, options);
            }
        },
        exitFullscreen: {
            writable: true, enumerable: false, configurable: true,
            value(this: Element): Promise<void> {
                return exitFullscreen?.call(document);
            }
        },
        hideAddressBar: {
            writable: true, enumerable: false, configurable: true,
            value(this: Element): void {
                const documentElement = document.documentElement;
                const body = document.body;

                try {
                    // try to full document
                    documentElement.requestFullscreen();

                    if (documentElement.scrollHeight <= documentElement.clientHeight) {
                        bodyheight = body.style.height;
                        body.style.height = (
                            documentElement.clientWidth / screen.width
                        ) * screen.height + 'px';
                    }

                    setTimeout(function () {
                        window.scrollTo(0, 1);
                    }, 100);
                } catch (e) {
                }

                // update the vh unit
                try {
                    const vh = window.innerHeight * 0.01;
                    const documentElement = document.documentElement;
                    __vh = documentElement.style.getPropertyValue('--vh');
                    documentElement.style.setProperty('--vh', vh + 'px');
                } catch (e) {
                }
            }
        },
        restorAddressBar: {
            writable: true, enumerable: false, configurable: true,
            value(this: Element): void {

                try {
                    if (this.fullscreenElement != document.documentElement) {
                        return;
                    }
                } catch (e) { }

                try {
                    const body = document.body;

                    // try to full document
                    document.exitFullscreen();
                    bodyheight != undefined && (body.style.height = bodyheight);
                    bodyheight = undefined;
                } catch (e) { }

                // update the vh unit
                try {
                    const documentElement = document.documentElement;
                    __vh != undefined && documentElement.style.setProperty('--vh', __vh);
                    __vh == null;
                } catch (e) { }
            }
        }
    })
}

_polyfill(window);

export { };

declare global {
    // type of DOMZoomRect
    interface DOMZoomRectInit extends DOMRectInit {
        xoff?: number;
        yoff?: number;
        zoom?: number;
    }

    // const DOMZoomRect: typeof DOMZoomRect;
    class DOMZoomRect extends DOMRectReadOnly {
        readonly orignalRect: DOMRect;
        readonly offPoint: DOMPoint;
        zoom: number;

        constructor(x?: number, y?: number, width?: number, height?: number, xoff?: number, yoff?: number, zoom?: number);

        static fromRect(other?: DOMZoomRectInit): DOMZoomRect;
    }

    // dom handling
    class DOM {
        constructor();

        static toNative<T extends { new(...args: any[]): Node }>(
            val: ViewContainerRef | ElementRef<Node> | EventTarget | Node | DOM | undefined | null,
            domtype: T
        ): {
            el?: InstanceType<T>,
            doc?: Document,
            wnd?: Window,
        }

        static polyfill(wnd: Window): void;
        static get binder(): Object.IBinder;

        static isNode(el: any): el is Node;
        static isTaggableNode(el: ViewContainerRef | ElementRef<Node> | Node | DOM): boolean;
        static isElement(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is Element & Node;
        static isHTMLElement(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is HTMLElement;
        static isTextNode(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is Element & Text & Node;
        static isCommentNode(el: ViewContainerRef | ElementRef<Node> | Node | DOM): el is Element & Comment & Node;

        static toElement(val: ViewContainerRef | ElementRef<Node> | Node | DOM): undefined | { value: Element }
        static toHTMLElement(val: ViewContainerRef | ElementRef<Node> | Node | DOM): undefined | { value: HTMLElement };

        static boundingViewRect(el: ViewContainerRef | ElementRef<HTMLElement> | HTMLElement | DOM | undefined): DOMRect;
        static boundingDocRect(
            el: ViewContainerRef | ElementRef<HTMLElement> | HTMLElement | DOM | undefined,
            container?: ViewContainerRef | ElementRef<HTMLElement> | HTMLElement | DOM
        ): DOMRect;

        static switchIFrameEvents(silent: boolean, doc?: Document): void;
    }

    // Element polyfill
    interface Element {
        readonly fullscreenElement: Element | null;
        requestFullscreen(options?: FullscreenOptions | undefined): Promise<void>;
        matches(selectors: string): boolean;
        exitFullscreen(): Promise<void>;
        restorAddressBar(): void;
        hideAddressBar(): void;
    }
}
