import { Directive, Input, output } from '@angular/core';

import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { AMapPathOverlay, PathOverlayOptions } from './amap-path-overlay';
import { Getter } from './types';

export const PolygonOptions: Extract<keyof AMapPolygon<AMap.EventEmitter>, keyof AMap.Polygon.Options>[] = [
    ...PathOverlayOptions,
    'fillOpacity',
    'fillColor',
    'path'
];

@Directive()
export class AMapPolygon<T extends AMap.EventEmitter> extends AMapPathOverlay<T> implements AMap.Polygon.Options {
    /**
     * 多边形轮廓线的节点坐标数组
     */
    @Input()
    path?: AMap.LocationValue[] | AMap.LocationValue[][];

    /**
     * 多边形填充颜色
     */
    @Input()
    fillColor?: string;

    /**
     * 边形填充透明度
     */
    @Input()
    fillOpacity?: number;

    /**
     * 额外: 是否隐藏
     */
    @Input()
    hidden: boolean;

    /**
     * 额外: 会覆盖其他属性的配置方式
     */
    @Input()
    options?: AMap.Polygon.Options;

    naReady = output<AMap.Polygon>();

    constructor(
        protected override readonly os: Getter<T>,
        protected override readonly binder: AMapEventBinderService
    ) {
        super(os, binder);
        this.hidden = false;
    }
}
