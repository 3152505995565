import { outputFromObservable } from '@angular/core/rxjs-interop';
import { Directive } from '@angular/core';

import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { AMapOverlay } from './amap-overlay';
import { Getter } from './types';

@Directive()
export class AMapShapeOverlay<T extends AMap.EventEmitter> extends AMapOverlay<T> {
    readonly naShow = outputFromObservable(this._binder(this.os.get(), 'show'));
    readonly naHide = outputFromObservable(this._binder(this.os.get(), 'hide'));
    readonly naChange = outputFromObservable(this._binder(this.os.get(), 'change'));

    constructor(
        protected override readonly os: Getter<T>,
        protected override readonly binder: AMapEventBinderService
    ) {
        super(os, binder);
    }
}
