import { Injectable } from "@angular/core";

import { AMapPixelService } from "./amap-pixel.service";
import { AMapSizeService } from "./amap-size.service";

@Injectable({ providedIn: 'root' })
export class AMapIconService {
    constructor(
        private readonly pixels: AMapPixelService,
        private readonly sizes: AMapSizeService
    ) {
    }

    create(options?: string | AMap.Icon | AMap.IIcon): string | AMap.Icon | undefined {
        if (!options) {
            return;
        }

        if (typeof options === 'string') {
            return options;
        }

        if (options instanceof AMap.Icon) {
            return options;
        }

        const iconOption: AMap.Icon.Options = {};
        const { size, image, imageOffset, imageSize } = options;

        if (size !== undefined) {
            iconOption.size = this.sizes.create(size);
        }

        if (image !== undefined) {
            iconOption.image = image;
        }

        if (imageOffset !== undefined) {
            iconOption.imageOffset = this.pixels.create(imageOffset);
        }

        if (imageSize !== undefined) {
            iconOption.imageSize = this.sizes.create(imageSize);
        }

        return new AMap.Icon(iconOption);
    }
}
