import { MatPaginatorIntl } from "@angular/material/paginator";
import { Type } from "@angular/core";

import { Editor } from "../../../utils/libs/editor";
import { Sys as TSys } from './types'

export namespace Sys {

    export function buildEnum(enm: any, nameMap: { [P: string]: string } = {}, ignore: number[] = []): Editor.IEnum {
        nameMap = nameMap ?? {}, ignore = ignore ?? [];

        return Object.keys(enm).reduce((res, x1) => {
            const x2 = enm[x1], x1isn = x1.match(/^\d/gi), x2isn = x2.match(/^\d/gi);
            const _key = x1isn ? x2 : (x2isn ? x1 : (nameMap[x2] ? x2 : x1));
            const _val = (x1isn || x2isn) ? parseInt(enm[_key]) : enm[_key];
            const { indexed } = res;

            if (indexed[_key] != null || ignore.has(_val) || !_.isNumber(_val) || (_val & TSys.enumInvisible)) {
                return res;
            }

            res.push({
                title: nameMap[_key] || _key,
                value: _val,
                key: _key,
            })

            return res;
        }, Editor.CreateIEnum());
    }

    export function stdEnumValue<T, EV extends {
        [P in keyof T]?: { [K: string]: string | number }
    }>(obj: T | undefined, enumvals: EV): T | undefined {
        if (!obj) return;

        for (const P in enumvals) {
            const okey = P as unknown as keyof T;
            const enm = enumvals[okey];
            const oval = obj[okey];

            if (_.isString(oval) && enm) {
                obj[okey] = enm[oval] as any;
            }
        }

        return obj;
    }

    export function cast<T>(val: any, type: Type<T>, errmsg: string): T;
    export function cast<T>(val: any, type: Type<T>, errmsg: ''): T | undefined;
    export function cast<T>(val: any, type: Type<T>, errmsg: string = ''): T | undefined {
        if (!(val instanceof type)) {
            if (errmsg) throw new Error(errmsg);
            return;
        }

        return val as T;
    }

    export function cast_org<T>(val: any, type: Type<T>, from: string = 'app'): T {
        return cast<T>(val, type, `${from}.org must be instance of Org`)
    }

    export function cast_prj<T>(val: any, type: Type<T>, from: string = 'app'): T {
        return cast<T>(val, type, `${from}.prj must be instance of Prj`)
    }

    export function cast_exec<T>(val: any, type: Type<T>, from: string = 'app'): T {
        return cast<T>(val, type, `${from}.exec must be instance of Exec`)
    }

    export function cast_dict<T>(val: any, type: Type<T>, from: string = 'app'): T {
        return cast<T>(val, type, `${from}.dict must be instance of Dict`)
    }
}

export namespace Sys {
    export const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = '每页显示：';
    paginatorIntl.previousPageLabel = '上一页:';
    paginatorIntl.nextPageLabel = '下一页:';
    paginatorIntl.firstPageLabel = '首页:';
    paginatorIntl.lastPageLabel = '尾页:';

    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 到 ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} 到 ${length}条`;
    };

    export const yearFormat = "yyyy年", yearMonthFormat = "yyyy年MM月", dateFormat = "yyyy年MM月dd日", datetimeFormat = "yyyy年MM月dd日 HH时mm分ss秒";

}