import { Inject, Injectable, Optional } from "@angular/core";

import { NGX_AMAP_CONFIG, NgxAMapConfig } from "./ngx-amap.config";

@Injectable({ providedIn: 'root' })
export class AMapLoggerService {
    constructor() {

    }

    i(...args: any[]): void {
        console.log(...args);
    }

    e(...args: any[]): void {

        console.error(...args);
    }

    w(...args: any[]): void {
        console.warn(...args);
    }

    d(...args: any[]): void {
        // only stub;
    }
}

@Injectable({ providedIn: 'root' })
export class AMapDebugLoggerService {
    private readonly debugTags: string[];
    private readonly debugAll: boolean;

    constructor(
        @Optional()
        @Inject(NGX_AMAP_CONFIG)
        private config: NgxAMapConfig = {}
    ) {
        this.config = config || {};
        this.debugAll = true;
        this.debugTags = [];

        if (config.debugTags) {
            if (config.debugTags === '*') {
                this.debugAll = true;
            } else {
                this.debugTags = config.debugTags.split(',');
                this.debugAll = false;
            }
        }
    }

    i(...args: any[]): void {
        console.log(...args);
    }

    e(...args: any[]): void {
        console.error(...args);
    }

    w(...args: any[]): void {
        console.warn(...args);
    }

    d(tag: string, ...args: any[]): void {
        if (this.debugAll || this.debugTags.includes(tag)) {
            console.log(`[${tag}]`, ...args);
        }
    }
}