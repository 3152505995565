import { Injectable } from "@angular/core";

import type { PuHttpService } from "./pu.http.service";

@Injectable({ providedIn: 'root' })
export class PuAlertService extends Array<PuAlertService.IAlert> {
    constructor() {
        super();

        Object.setPrototypeOf(
            this, new.target.prototype
        );
    }

    close(alert: PuAlertService.IAlert) {
        this.remove(alert);
    }
}

export namespace PuAlertService {
    export interface IAlert extends PuHttpService.Error {
        type: 'success' | 'info' | 'warning' | 'danger' | 'primary' | 'secondary' | 'light' | 'dark',
        dismiss: number
    }
}