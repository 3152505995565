<div class="card card-chart">
    <div class="card-header card-header-{{cardsum?.warning? 'warning' : 'success'}}">
        <div class="ct-chart" id="dailySalesChart">
            <h4 class="card-title">{{(cardsum?.title ?? '') | translate}}</h4>
        </div>
    </div>
    <div class="card-body" style="text-align:center">
        <h1 class="card-title" style="font-family: Verdana, Geneva, Tahoma, sans-serif;">
            {{cardsum?.count}}
        </h1>
    </div>
    <div class="card-footer">
        <div class="stats">
            <i class="material-icons">access_time</i>
            {{'general.annual'|translate}}：
            {{cardsum?.year}}
        </div>
    </div>
</div>