
import { EventEmitter, TemplateRef, Injectable, Directive, Output, Input, OnChanges, ViewContainerRef, SimpleChanges, EmbeddedViewRef, Optional, SkipSelf, OnDestroy, Type, Injector } from '@angular/core';

const DialogTemplate: (typeof PuTemplateService)['dialogTemplate'] = new EventEmitter();
type DialogTemplate = (typeof PuTemplateService)['dialogTemplate'] extends EventEmitter<infer T> ? T : never;

@Injectable({ providedIn: 'root' })
export class PuTemplateService {
    private _props = Prop.Of(this);

    @Output()
    get changed(): EventEmitter<{
        template: TemplateRef<any>,
        id: string,
    }> {
        const { _props: props } = this;
        return props.changed || (props.changed = new EventEmitter());
    }

    get templates(): {
        [id: string]: TemplateRef<any>
    } {
        const { _props: props } = this;
        return props.templates || (props.templates = {});
    };

    constructor(
        @Optional() @SkipSelf()
        public parent?: PuTemplateService
    ) {
    }

    add(id: string | string[], template: TemplateRef<any>): void {
        if (_.isArray(id)) {
            return id.forEach(
                sid => this.add(sid, template)
            );
        }

        id = id && id.trim() || "";
        const { templates } = this;
        if (!id || templates[id]) {
            return;
        }

        const { changed } = this;
        templates[id] = template;
        changed.emit({
            template: template,
            id: id,
        });
    }

    get(id?: string): TemplateRef<any> | undefined {
        if (!id) return;

        let cur: PuTemplateService | undefined = this;
        while (cur) {
            const tpl = cur.templates[id];
            if (tpl) return tpl;

            cur = cur.parent;
        }

        return;
    }

    static get dialogTemplate(): EventEmitter<
        TemplateRef<any> & {
            readonly matinfo: {
                readonly type: 'footer' | 'title',
                readonly onDestroy: EventEmitter<
                    TemplateRef<any> & {
                        readonly matinfo: {
                            readonly type: 'footer' | 'title'
                        }
                    }
                >,
            }
        }
    > {
        return DialogTemplate
    }
};

@Directive({
    selector: 'ng-template[pu-mat-dialog-actions],ng-template[puMatDialogActions]'
}) export class PuMatDialogActionsTemplateDetectorDirective implements OnDestroy {
    private onDestroy: DialogTemplate['matinfo']['onDestroy'] = new EventEmitter();
    private readonly dp: DialogTemplate;

    constructor(
        public tpl: TemplateRef<any>
    ) {
        const { onDestroy } = this;
        this.dp = _.extend(tpl, {
            matinfo: {
                type: <'footer' | 'title'>"footer",
                onDestroy
            }
        })

        DialogTemplate.emit(
            this.dp
        )
    }

    ngOnDestroy(): void {
        this.onDestroy.emit(this.dp);
    }
};

@Directive({
    selector: 'ng-template[pu-mat-dialog-title],ng-template[puMatDialogTitle]'
}) export class PuMatDialogTitleTemplateDetectorDirective implements OnDestroy {
    private onDestroy: DialogTemplate['matinfo']['onDestroy'] = new EventEmitter();
    private readonly dp: DialogTemplate;

    constructor(
        public tpl: TemplateRef<any>
    ) {
        const { onDestroy } = this;
        this.dp = _.extend(tpl, {
            matinfo: {
                type: <'footer' | 'title'>"title",
                onDestroy
            }
        })

        DialogTemplate.emit(
            this.dp
        )
    }

    ngOnDestroy(): void {
        this.onDestroy.emit(this.dp);
    }
};

@Directive({
    selector: 'ng-template[id]',
    exportAs: 'PuTemplate',
}) export class PuTemplateDirective {
    private _props = Prop.Of(this);

    @Input('id')
    get id(): string {
        return this._props.id || "";
    }

    set id(id: string) {
        const { _props: props, _props: { id: oid } } = this;
        if (!id || id == oid) return;
        props.id = id;

        const { tmpls, tpl } = this;
        tmpls.add(id.split(','), tpl);
    }

    constructor(
        private tmpls: PuTemplateService,
        private tpl: TemplateRef<any>
    ) {
    }

    static readonly Views: Type<any>[] = [
        PuMatDialogActionsTemplateDetectorDirective,
        PuMatDialogTitleTemplateDetectorDirective,
        PuTemplateDirective
    ]
};

@Directive({
    selector: '[puTemplateOutlet]',
    exportAs: 'PuTemplateOutlet',
}) export class PuTemplateOutletDirective implements OnChanges {
    private _props = Prop.Of(this);

    @Input('puTemplateOutlet')
    puTemplateOutlet: string | TemplateRef<any> | undefined;

    @Input('puTemplateOutletContext')
    puTemplateOutletContext: { [P: string]: any } | undefined;

    @Input('puTemplateOutletInjector')
    puTemplateOutletInjector: Injector | undefined;

    get viewRef(): EmbeddedViewRef<any> | undefined {
        return this._props.viewRef;
    }

    constructor(
        protected tpls: PuTemplateService,
        protected vcr: ViewContainerRef,
        protected injector: Injector
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { puTemplateOutletContext: _context } = this;
        const { puTemplateOutletInjector: _injector } = this;
        const { puTemplateOutlet: _template } = this;
        const { vcr, tpls, _props: props } = this;
        const { _props: { viewRef } } = this;

        if (!!changes['puTemplateOutlet'] || !!changes['puTemplateOutletInjector'] || !viewRef) {
            if (props.viewRef) {
                // destroy previous view.
                vcr.remove(vcr.indexOf(props.viewRef));
                props.viewRef = undefined;
            }

            // recreate view
            const templateRef = _.isString(_template) ? tpls.get(_template) : _template;
            props.viewRef = templateRef && vcr.createEmbeddedView(templateRef, _context, {
                injector: _injector || this.injector
            });
            return;
        }

        const viewCtx = viewRef.context;
        const ctxChange = changes['puTemplateOutletContext'];
        if (!ctxChange || !viewCtx) return;

        const currCtx = ctxChange.currentValue || {}, prevCtx = ctxChange.previousValue || {};
        const allKeys = _.uniq(Object.keys(currCtx).concat(Object.keys(prevCtx)));

        _.forEach(allKeys, (key) => {
            const curVal = currCtx[key];
            if (curVal === undefined) {
                delete viewCtx[key];
                return;
            }

            const preVal = prevCtx[key];
            if (!Object.is(preVal, curVal)) {
                viewCtx[key] = curVal;
            }
        });
    }
}