import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

import { FieldProperty } from '../../utils/view/model/field.property';
import { Editor } from '../../utils/libs/editor';
import { Prj } from './backface/prj';

export namespace ExcelService {
    export interface ISummary {
        readonly bodyDataSource?: Prj.SumProjectData[] | Object[];
        readonly sumFieldsData?: Array<Editor.IFieldCol>[];
        readonly colFieldsData?: Editor.IFieldCols;
        readonly fielder?: FieldProperty;
    }

    export interface IFooterColCells {
        rows: { row: number, value: string }[],
        col: number,
    }

    export function getColspan(): (i: number, sumlen: number, collen: number) => number {
        return (i: number, sumlen: number, collen: number) => {
            var p = 1;
            if (sumlen <= 0 || collen <= 0 || collen <= sumlen) return p;
            p = Math.floor(collen / sumlen);
            if (collen % sumlen) {
                if (i == sumlen - 1) {
                    return p = (p == 1) ? (collen - 2 * i) : (collen - p * i);
                }

                if ((i + 1) * (p + 1) < collen && p == 1) {
                    p = p + 1;
                }
            }
            return p;
        }
    }

    export async function generateExcel(path: string, summary: ISummary, footlist?: Array<IFooterColCells>) {
        if (_.isNull(summary?.bodyDataSource) || _.isUndefined(summary?.bodyDataSource)) return;
        if (_.isNull(summary?.sumFieldsData) || _.isUndefined(summary?.sumFieldsData)) return;
        if (_.isNull(summary?.colFieldsData) || _.isUndefined(summary?.colFieldsData)) return;

        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(path);

        // Add Row and formatting
        const titleRow = worksheet.addRow([path]);
        worksheet.mergeCells(titleRow.number, 1, titleRow.number, summary.colFieldsData.length);

        titleRow.font = {
            name: '方正小标宋简体',
            family: 4, size: 20,
            bold: true
        };

        titleRow.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true,
            shrinkToFit: true
        };

        titleRow.getCell(1).border = {
            top: { style: 'thin' }, left: { style: 'thin' },
            bottom: { style: 'thin' }, right: { style: 'thin' }
        };

        titleRow.height = 50;

        const subTitleRow = worksheet.addRow(['报告时间: ' + new Date().toLocaleString()]);
        worksheet.mergeCells(subTitleRow.number, 1, subTitleRow.number, summary.colFieldsData.length);
        subTitleRow.getCell(1).border = {
            top: { style: 'thin' }, left: { style: 'thin' },
            bottom: { style: 'thin' }, right: { style: 'thin' }
        };

        subTitleRow.height = 15;
        subTitleRow.alignment = {
            horizontal: 'right',
            vertical: 'middle',
            wrapText: true,
            shrinkToFit: true
        };

        // Add Header Row
        const headerRows = [];
        const sumFileds = summary.sumFieldsData;
        for (let index = 0; index < sumFileds.length; index++) {
            const hRowHeaderData = [];
            for (let k = 0; k < sumFileds[index].length; k++) {
                hRowHeaderData.push(sumFileds[index][k].title);
                const cellColSpan = sumFileds[index][k].col ?? 0;
                for (let p = 0; p < cellColSpan - 1; p++) {
                    hRowHeaderData.push("");
                }
            }

            const headerRow = worksheet.addRow(hRowHeaderData);
            for (let m = 0, pos = 0; m < sumFileds[index].length; m++) {
                const cellColSpan = sumFileds[index][m].col ?? 0;
                worksheet.mergeCells(headerRow.number, pos + 1, headerRow.number, pos + cellColSpan);
                pos = pos + cellColSpan;
            }
            headerRows.push(headerRow);
        }

        const headerRowLast = worksheet.addRow(summary.colFieldsData.map(head => { return head.title }));
        headerRows.push(headerRowLast);

        // Cell Style : Fill and Border
        headerRows.forEach(r => {
            r.height = 40;
            r.eachCell((cell, number) => {
                cell.font = {
                    name: '宋体',
                    family: 4, size: 12,
                    bold: true
                };

                cell.alignment = {
                    horizontal: 'center',
                    vertical: 'middle',
                    wrapText: true
                };

                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF00' },
                    bgColor: { argb: 'FF0000FF' }
                };

                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            })
        });

        // Add Data and Conditional Formatting
        for (let index = 0; index < summary.bodyDataSource.length; index++) {
            const d = summary.bodyDataSource[index];
            const data: (string | number | boolean)[] = [];
            summary.colFieldsData.forEach(col => {
                if (col.key == "id") {
                    var n = index + 1;
                    data.push(n.toString());
                    return;
                }

                const v = summary.fielder?.getCellText?.(d, col.key, summary.colFieldsData);
                data.push(v == null ? "" : v);
            });

            const row = worksheet.addRow(data);
            let coldes: number = summary.colFieldsData.findIndex(col => col.key == 'description');
            let colyear: number = summary.colFieldsData.findIndex(col => col.key == 'budgetyeartarget');
            row.eachCell((cell, number) => {

                cell.font = {
                    name: '宋体',
                    family: 4, size: 15,
                    bold: false
                };

                cell.alignment = {
                    horizontal: (number != (coldes + 1) && number != (colyear + 1)) ? 'center' : 'left',
                    vertical: 'middle',
                    wrapText: true,
                    shrinkToFit: true
                };

                cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            });
        }

        for (let index = 0; index < summary.colFieldsData.length; index++) {
            const col = summary.colFieldsData[index];
            const colxls = worksheet.getColumn(index + 1);
            colxls.width = 15;
            if (col.key == 'id') colxls.width = 5;
            if (col.key == 'description') colxls.width = 25;
        }

        if (footlist) {
            const data: string[] = [];
            summary.colFieldsData.forEach(element => {
                data.push("initvalue");
            });

            for (let index = 0; index < 6; index++) {
                const row = worksheet.addRow(data);
                row.eachCell((cell, number) => {
                    cell.font = {
                        name: '宋体',
                        family: 4, size: 15,
                        bold: true
                    };

                    cell.alignment = {
                        horizontal: 'center',
                        vertical: 'middle',
                        wrapText: true,
                        shrinkToFit: true
                    };

                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFDDDDDD' },
                        bgColor: { argb: 'FFFFFFFF' }
                    };

                    cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                });
            }

            const lines = summary.bodyDataSource?.length;
            let col = 1;

            footlist.forEach(footcol => {
                let row = lines + 6;  //have 6 rows in header
                footcol.rows.forEach(fcell => {
                    const cell = worksheet.getCell(row, col);
                    cell.value = fcell.value;
                    if (footcol.col > 1 || fcell.row > 1) {
                        worksheet.mergeCells(row, col, row + fcell.row - 1, col + footcol.col - 1);
                    }

                    row = row + fcell.row;
                })
                col = col + footcol.col;
            });
        }

        // No Footer Row
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, path);
        });
    }
}
