<div class="h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
    <h3
        class="card-frame rounded-top-0 mx-1 my-0 p-1 header d-flex flex-row align-items-stretch justify-content-center flex-none">
        <strong>
            {{selectedYear}}{{'general.year' | translate}}{{searcherPool?.title}}{{'general.projects' | translate}}
        </strong>
    </h3>

    <div class="w-100 h-auto flex-grow-1 overflow-auto"
        [puDashboard]="{dashboard: 'reportgeneral' + (es.actions.$param?.pool?.[0] ? '_' : '') +  (es.actions.$param?.pool?.[0] ?? '')}">
    </div>
</div>