import { CurrencyPipe, DatePipe, DecimalPipe, JsonPipe, KeyValuePipe, LowerCasePipe, PercentPipe, SlicePipe, TitleCasePipe, UpperCasePipe } from "@angular/common";
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationStart, Resolve, Route, Router, RouterStateSnapshot } from "@angular/router";
import { forwardRef, Inject, Injectable, Injector, KeyValueDiffers, LOCALE_ID } from "@angular/core";
import { filter, map, shareReplay, take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { DateAdapter } from "@angular/material/core";
import { HttpClient } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { Observable } from "rxjs";

import { PuVersioinService } from "../../utils/puzzle/pu.service/pu.version.service";
import { PuSchemaService } from "../../utils/puzzle/pu.service/pu.schema.service";
import { PuAlertService } from "../../utils/puzzle/pu.service/pu.alert.service";
import { PuMsgService } from "../../utils/puzzle/pu.service/pu.msg.service";

import { Sys as TSys, Prj as TPrj, App as TApp } from './backface/types';
import { Editor } from "../../utils/libs/editor";
import { AuthService } from "./auth.service";
import { Backface } from "./backface/config";
import { Report } from "./backface/report";
import { Dict } from "./backface/dict";
import { Exec } from "./backface/exec";
import { Sys } from "./backface/sys";
import { Prj } from "./backface/prj";
import { Org } from "./backface/org";

export interface RouteInfo {
    children?: RouteInfo[];

    name?: string;

    path?: string;
    title?: string;
    icon?: string;
    class?: string;
}

const Value = Editor.Value;

const DefAppName = "重点项目建设信息管理系统";

// list any lang resource required while dynamic translation unavailable
//   such as: echarts option
//
// [NOTE]: strongly encourage to use translate pipe rather than this way
const Langs = {
    prepareproject: 'module.setting.prepareproject',
    systemconfig: 'systemconfig',
    sysenums: 'sysenums',
    stage: {
        name: 'stage.name'
    },
    report: {
        projectcountsummary: 'report.projectcountsummary',
        supervisesummary: 'report.supervisesummary',
        refproject: 'report.refproject',
        problemnotfixed: 'report.problemnotfixed',
        supervisecount: 'report.supervisecount',
        problemcount: 'report.problemcount',
        stagesummary: 'report.stagesummary',
        workitemexecount: 'report.workitemexecount',
        workitemexeongoing: 'report.workitemexeongoing',
        workitemexeoverdue: 'report.workitemexeoverdue',
        yearmonthcostchart: 'report.yearmonthcostchart'
    },
    general: {
        notfixed: 'general.notfixed',
        fixed: 'general.fixed',
        year: 'general.year',
        item: 'general.item',
        unit: 'general.unit',
        projects: 'general.projects',
        bunit: 'general.bunit',
        annual: 'general.annual',
        all: 'general.all',
        orgname: 'general.orgname',
        total: 'general.total',
        progresscomment: 'general.progresscomment',
        mediamaxamount: 'general.mediamaxamount',
        city_district: 'general.city_district',
        city_position: 'general.city_position',
        city_address: 'general.city_address',
        mapnotset: 'general.mapnotset',
        yearlyinvested: 'general.yearlyinvested',
        yearlyassetinvested: 'general.yearlyassetinvested',
        searchnone: 'general.searchnone',
        nonedept: 'general.nonedept',
        nonepeople: 'general.nonepeople',
        nonerole: "general.nonerole",
        noneactor: "general.noneactor",
        noneinspector: "general.noneinspector",
        noneplan: "general.noneplan",
        noneproject: "general.noneproject",
        nonebegunproject: "general.nonebegunproject",
        noneacceptproject: "general.noneacceptproject",
        nonepilotproject: "general.nonepilotproject",
        nonemeditateproject: "general.nonemeditateproject",
        nonecandidateproject: "general.nonecandidateproject",
        noneinputproject: "general.noneinputproject",
        nonesupervise: "general.nonesupervise",
        nonesuperviseaudit: "general.nonesuperviseaudit",
        noneproblem: "general.noneproblem",
        noneprojectexe: "general.noneprojectexe",
        noneexecuteaudit: "general.noneexecuteaudit"
    },
    budget: {
        percent: 'budget.percent',
        amount: 'budget.amount',
        consume: 'budget.consume',
        unit: 'budget.unit',
        startend: 'budget.startend',
        target: 'budget.target'
    },
    summary: {
        patchprjtable: 'summary.patchprjtable',
        cancelprjtable: 'summary.cancelprjtable',
        workitemprjtable: 'summary.workitemprjtable',
        workitemtitle: 'summary.workitemtitle',
        workitemsummary: 'summary.workitemsummary',
        projectinfo: 'summary.projectinfo',
        refedworkitem: 'summary.refedworkitem',
        sumnotused: 'summary.sumnotused',
        exed: 'summary.exed',
        subsum: 'summary.subsum',
        exeon: 'summary.exeon',
        exepre: 'summary.exepre',
        sumall: 'summary.sumall',
        peronused: 'summary.peronused',
        peronall: 'summary.peronall',
        workitemcatsum: 'summary.workitemcatsum',
        curstagestatus: 'summary.curstagestatus',
        workitemall: 'summary.workitemall',
        allcount: 'summary.allcount',
        sumyearlyinvested: 'summary.sumyearlyinvested',
        sumyearlyassetinvested: 'summary.sumyearlyassetinvested',
    },
    project: {
        id: 'project.id',
        name: 'project.name',
        plan: 'project.plan',
        owncompany: 'project.owncompany',
        location: 'project.location',
        description: 'project.description',
        constrtype: 'project.constrtype',
        owndept: 'project.owndept',
        memo: 'project.memo',
        position: 'project.position'
    },
    actor: {
        prjowner: 'actor.prjowner',
        header: 'actor.header',
        leaders: 'actor.leaders'
    },
    tabs: {
        summaryongoingproject: 'tabs.summaryongoingproject',
        summaryprojectstage: 'tabs.summaryprojectstage'
    },
    problem: {
        problem: 'problem.problem'
    },
    projectcard: {
        projectcount: 'projectcard.projectcount',
        budgettotal: 'projectcard.budgettotal',
        yearbudgettotal: 'projectcard.yearbudgettotal'
    },
    pool: {
        input: 'sysenums.pool.input',
        meditate: 'sysenums.pool.meditate',
        candidate: 'sysenums.pool.candidate',
        begun: 'sysenums.pool.begun',
        accept: 'sysenums.pool.accept'
    }
}

@Injectable({ providedIn: 'root' })
export class AppService implements Resolve<object>, TApp.IAppService {
    private _props = Prop.Of<AppService, {
        onLangChange: Observable<any>
    }>(this);

    readonly _ = _;

    get backface(): Backface {
        const { _props: props } = this;
        const backface = (props.backface || (props.backface = new Backface(
            this.httpClient, this
        )));

        Editor.detect.cud = Editor.detect.cud || backface.cud;
        return backface;
    }

    get dict(): Dict {
        const { _props: props, lang: { systemconfig: { modules } = {} } } = this;
        return props.dict || (
            props.dict = new Dict(this),
            this.excludeHeaders(modules),
            props.dict
        );
    }

    get report(): Report {
        const { _props } = this;
        return _props.report || (
            _props.report = new Report(this)
        );
    }

    get org(): Org {
        const { _props: props } = this;
        return props.org || (
            props.org = new Org(this, this.backface.org)
        );
    }

    get prj(): Prj {
        const { _props: props } = this;
        return props.prj || (
            props.prj = new Prj(this, this.backface.prj)
        );
    }

    get exec(): Exec {
        const { _props: props } = this;
        return props.exec || (
            props.exec = new Exec(this, this.backface.exec)
        );
    }

    get sys() {
        return Sys;
    }

    get editor() {
        return Editor;
    }

    get datasources() {
        const build = () => {
            const { superviseaudit: usesuperviseaudit, executeaudit: useexecuteaudit, prepareproject: useprepareproject } = this;
            const { dict, auth: { me: { priviledges = <AuthService.Priviledge>{} } = {} } } = this;
            const app = this;

            const dept: TSys.IDatasetModule = {
                dict: dict.org.dept,
                headers: XArray.createIf('key', dict.org.dept.headers),
                get rows() {
                    return app.org.depts
                },
                key: 'dept',
                accessable: !!(priviledges.orgnization?.dept),
                cudable: !!(priviledges.orgnization?.dept?.cud),
                nonehint: app.lang.general.nonedept
            }

            const people: TSys.IDatasetModule = {
                dict: dict.org.people,
                headers: XArray.createIf('key', dict.org.people.headers),
                get rows() {
                    return app.org.peoples
                },
                key: 'people',
                accessable: !!(priviledges.user?.people),
                cudable: !!(priviledges.user?.people?.cud),
                nonehint: app.lang.general.nonepeople
            }

            const role: TSys.IDatasetModule = {
                dict: dict.org.role,
                headers: XArray.createIf('key', dict.org.role.headers),
                get rows() {
                    return app.org.roles
                },
                key: 'role',
                accessable: !!(priviledges.user?.role),
                cudable: !!(priviledges.user?.role?.cud),
                nonehint: app.lang.general.nonerole
            }

            const actor: TSys.IDatasetModule = {
                dict: dict.org.actor,
                headers: XArray.createIf('key', dict.org.actor.headers),
                get rows() {
                    return app.org.actors
                },
                key: 'actor',
                accessable: !!(priviledges.team?.actor),
                cudable: !!(priviledges.team?.actor?.cud),
                nonehint: app.lang.general.noneactor
            }

            const inspector: TSys.IDatasetModule = {
                dict: dict.org.inspector,
                headers: XArray.createIf('key', dict.org.inspector.headers),
                get rows() {
                    return app.org.inspectors
                },
                key: 'inspector',
                accessable: !!(priviledges.team?.inspector),
                cudable: !!(priviledges.team?.inspector?.cud),
                nonehint: app.lang.general.noneinspector
            }

            const projectplan: TSys.IDatasetModule = {
                dict: dict.prj.projectplan,
                headers: XArray.createIf('key', dict.prj.projectplan.headers),
                get rows() {
                    return app.prj.plans
                },
                key: 'projectplan',
                accessable: !!(priviledges.plan?.projectplan),
                cudable: !!(priviledges.plan?.projectplan?.cud),
                nonehint: app.lang.general.noneplan
            }

            const project: TSys.IDatasetModule = {
                headers: XArray.createIf('key', dict.prj.project.headers),
                dict: dict.prj.project,
                get rows() {
                    return app.prj.projects
                },
                key: 'project',
                accessable: !!(priviledges.plan?.project),
                cudable: !!(priviledges.plan?.project?.cud),
                nonehint: app.lang.general.noneproject
            };

            const begunproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.project, title: 'tabs.begunproject' },
                headers: XArray.createIf('key', dict.prj.project.headers),
                get rows() {
                    return app.prj.projects.begun;
                },
                key: 'begunproject',
                accessable: !!(priviledges?.plan?.project),
                cudable: !!(priviledges?.plan?.project?.cud),
                nonehint: app.lang.general.nonebegunproject
            } : project;

            const acceptproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.project, title: 'tabs.acceptproject' },
                headers: XArray.createIf('key', dict.prj.project.headers),
                get rows() {
                    return app.prj.projects.accept;
                },
                key: 'acceptproject',
                accessable: !!(priviledges?.plan?.project),
                cudable: !!(priviledges?.plan?.project?.cud),
                nonehint: app.lang.general.noneacceptproject
            } : undefined;

            const pilotproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                headers: XArray.createIf('key', dict.prj.pilotproject?.headers),
                dict: dict.prj.pilotproject,
                get rows() {
                    return app.prj.pilotprojects
                },
                key: 'pilotproject',
                accessable: !!(priviledges.plan?.pilotproject),
                cudable: !!(priviledges.plan?.pilotproject?.cud),
                nonehint: app.lang.general.nonepilotproject
            } : undefined;

            const meditateproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.pilotproject, title: 'tabs.meditateproject' },
                headers: XArray.createIf('key', dict.prj.pilotproject?.headers),
                get rows() {
                    return app.prj.pilotprojects.meditate;
                },
                key: 'meditateproject',
                accessable: !!(priviledges.plan?.pilotproject),
                cudable: !!(priviledges.plan?.pilotproject?.cud),
                nonehint: app.lang.general.nonemeditateproject
            } : undefined;

            const candidateproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                dict: { ...dict.prj.pilotproject, title: 'tabs.candidateproject' },
                headers: XArray.createIf('key', dict.prj.pilotproject?.headers),
                get rows() {
                    return app.prj.pilotprojects.candidate;
                },
                key: 'candidateproject',
                accessable: !!(priviledges.plan?.pilotproject),
                cudable: !!(priviledges.plan?.pilotproject?.cud),
                nonehint: app.lang.general.nonecandidateproject
            } : undefined;

            const inputproject: TSys.IDatasetModule | undefined = useprepareproject ? {
                headers: XArray.createIf('key', dict.prj.inputproject?.headers),
                dict: dict.prj.inputproject,
                get rows() {
                    return app.prj.inputprojects
                },
                key: 'inputproject',
                accessable: !!(priviledges.plan?.inputproject),
                cudable: !!(priviledges.plan?.inputproject?.cud),
                creatable: !!(priviledges.plan?.inputproject?.cud?.create),
                deletable: !!(priviledges.plan?.inputproject?.cud?.delete),
                nonehint: app.lang.general.noneinputproject
            } : undefined;

            const projectsummary: TSys.IDatasetModule = {
                dict: dict.prj.projectsummary,
                headers: undefined,
                rows: undefined,
                key: 'projectsummary',
                template: 'reportgeneral',
                accessable: !!(priviledges.plan?.projectsummary)
            }

            const workitemexesummary: TSys.IDatasetModule = {
                dict: dict.exec.workitemexesummary,
                headers: undefined,
                rows: undefined,
                key: 'workitemexesummary',
                template: 'reportworkitemexe',
                accessable: !!(priviledges.audit?.auditsummary)
            }

            const supervisesummary: TSys.IDatasetModule = {
                dict: dict.exec.supervisesummary,
                headers: undefined,
                rows: undefined,
                key: 'supervisesummary',
                template: 'reportsupervise',
                accessable: !!(priviledges.supervise?.supervisesummary)
            }

            const supervise: TSys.IDatasetModule = {
                dict: dict.exec.supervise,
                headers: XArray.createIf('key', dict.exec.supervise.headers),
                rows: undefined,
                key: 'supervise',
                accessable: !!(priviledges.supervise?.supervise),
                cudable: !!(priviledges.supervise?.supervise?.supervisemodify),
                nonehint: app.lang.general.nonesupervise
            }

            const superviseaudit: TSys.IDatasetModule = {
                dict: dict.exec.superviseaudit,
                headers: XArray.createIf('key', dict.exec.superviseaudit.headers),
                rows: undefined,
                key: 'superviseaudit',
                accessable: !!(usesuperviseaudit ? (
                    priviledges.supervise?.supervise
                ) : undefined),
                cudable: !!(usesuperviseaudit ? (
                    priviledges.supervise?.supervise?.superviseaudit ||
                    priviledges.supervise?.supervise?.supervisemodify
                ) : undefined),
                nonehint: app.lang.general.nonesuperviseaudit
            }

            const problem: TSys.IDatasetModule = {
                dict: dict.exec.problem,
                headers: XArray.createIf('key', dict.exec.problem.headers),
                rows: undefined,
                key: 'problem',
                accessable: !!(priviledges.supervise?.problem),
                cudable: !!(priviledges.supervise?.problem?.problemupdate),
                nonehint: app.lang.general.noneproblem
            }

            const projectexe: TSys.IDatasetModule = {
                dict: dict.exec.audit,
                headers: XArray.createIf('key', dict.exec.audit.headers),
                rows: undefined,
                key: 'projectexe',
                accessable: !!(priviledges.audit?.audit),
                cudable: !!(
                    priviledges.audit?.audit?.normalmodify ||
                    priviledges.audit?.audit?.verifymodify
                ),
                nonehint: app.lang.general.noneprojectexe
            }

            const executeaudit: TSys.IDatasetModule = {
                dict: dict.exec.executeaudit,
                headers: XArray.createIf('key', dict.exec.executeaudit.headers),
                rows: undefined,
                key: 'executeaudit',
                accessable: !!(useexecuteaudit ? (
                    priviledges.audit?.audit
                ) : undefined),
                cudable: !!(useexecuteaudit ? (
                    priviledges.audit?.audit?.executeaudit ||
                    priviledges.audit?.audit?.normalmodify ||
                    priviledges.audit?.audit?.verifymodify
                ) : undefined),
                nonehint: app.lang.general.noneexecuteaudit
            }

            const checkpoint: TSys.IDatasetModule = {
                headers: XArray.createIf('key', dict.prj.checkpoint.headers),
                dict: dict.prj.checkpoint,
                get rows() {
                    return app.prj.checkpoints
                },
                key: 'checkpoint',
                accessable: !!(priviledges.process?.checkpoint),
                cudable: !!(priviledges.process?.checkpoint?.cud)
            }

            const workitem: TSys.IDatasetModule = {
                dict: dict.prj.workitem,
                headers: XArray.createIf('key', dict.prj.workitem.headers),
                get rows() {
                    return app.prj.workitems
                },
                key: 'workitem',
                accessable: !!(priviledges.process?.workitem),
                cudable: !!(priviledges.process?.workitem?.cud)
            }

            const workset: TSys.IDatasetModule = {
                dict: dict.prj.workset,
                headers: XArray.createIf('key', dict.prj.workset.headers),
                get rows() {
                    return app.prj.worksets
                },
                key: 'workset',
                accessable: !!(priviledges.process?.workset),
                cudable: !!(priviledges.process?.workset?.cud)
            }

            const stage: TSys.IDatasetModule = {
                dict: dict.prj.stage,
                headers: XArray.createIf('key', dict.prj.stage.headers),
                get rows() {
                    return app.prj.stages
                },
                key: 'stage',
                accessable: !!(priviledges.process?.stage),
                cudable: !!(priviledges.process?.stage?.cud)
            }

            const projecttype: TSys.IDatasetModule = {
                dict: dict.prj.projecttype,
                headers: XArray.createIf('key', dict.prj.projecttype.headers),
                get rows() {
                    return app.prj.projecttypes
                },
                key: 'projecttype',
                accessable: !!(priviledges.process?.projecttype),
                cudable: !!(priviledges.process?.projecttype?.cud)
            }

            const projecttypedefine: TSys.IDatasetModule = {
                dict: dict.prj.projecttypedefine,
                headers: XArray.createIf('key', dict.prj.projecttypedefine.headers),
                get rows() {
                    return app.prj.projecttypes
                },
                key: 'projecttypedefine',
                accessable: !!(priviledges.process?.projecttype),
                cudable: !!(priviledges.process?.projecttype?.cud),
                nopaginator: true
            }

            const systemconfig: TSys.IDatasetModule = {
                dict: dict.sys.systemconfig,
                headers: XArray.createIf('key', dict.sys.systemconfig.headers),
                rows: undefined,
                key: 'systemconfig',
                accessable: !!(priviledges.systemset?.settings)
            }

            const operationlog: TSys.IDatasetModule = {
                dict: dict.sys.operationlog,
                headers: XArray.createIf('key', dict.sys.operationlog.headers),
                rows: undefined,
                key: 'operationlog',
                accessable: !!(priviledges.systemset?.operationlog)
            }

            const summarypatchproject: TSys.IDatasetModule = {
                dict: dict.exec.summarypatchproject,
                headers: undefined,
                rows: undefined,
                key: 'summarypatchproject',
                template: 'sumpatchproject',
                accessable: !!(priviledges.summary?.summarypatchproject)
            }

            const summaryongoingproject: TSys.IDatasetModule = {
                dict: dict.exec.summaryongoingproject,
                headers: undefined,
                rows: undefined,
                key: 'summaryongoingproject',
                template: 'sumongoingproject',
                accessable: !!(priviledges.summary?.summaryongoingproject)
            }

            const summaryprojectstage: TSys.IDatasetModule = {
                dict: dict.exec.summaryprojectstage,
                headers: undefined,
                rows: undefined,
                key: 'summaryprojectstage',
                template: 'sumprojectstage',
                accessable: !!(priviledges.summary?.summaryprojectstage)
            }

            const summaryprojectworkitem: TSys.IDatasetModule = {
                dict: dict.exec.summaryprojectworkitem,
                headers: undefined,
                rows: undefined,
                key: 'summaryprojectworkitem',
                template: 'sumprojectworkitem',
                accessable: !!(priviledges.summary?.summaryprojectworkitem)
            }

            const summarycancelproject: TSys.IDatasetModule = {
                dict: dict.exec.summarycancelproject,
                headers: undefined,
                rows: undefined,
                key: 'summarycancelproject',
                template: 'sumcancelproject',
                accessable: !!(priviledges.summary?.summarycancelproject)
            }

            const projecttypes: TSys.IDatasetModules = this.createDatasetModules(projecttypedefine, /*projecttype, stage, workset,*/ workitem, checkpoint);
            const projectplans: TSys.IDatasetModules = this.createDatasetModules(projectsummary, inputproject, meditateproject, candidateproject, begunproject, acceptproject, projectplan);
            const teams: TSys.IDatasetModules = this.createDatasetModules(actor, inspector);

            const supervises: TSys.IDatasetModules = this.createDatasetModules(supervisesummary, supervise, superviseaudit, problem);
            const workitemexes: TSys.IDatasetModules = this.createDatasetModules(workitemexesummary, projectexe, executeaudit);

            const users: TSys.IDatasetModules = this.createDatasetModules(people, role);
            const depts: TSys.IDatasetModules = this.createDatasetModules(dept);

            const problems: TSys.IDatasetModules = this.createDatasetModules(problem);
            const systeminfo: TSys.IDatasetModules = this.createDatasetModules(systemconfig, operationlog);
            const summary: TSys.IDatasetModules = this.createDatasetModules(summarypatchproject, summaryongoingproject, /* summaryprojectstage, */ summaryprojectworkitem, summarycancelproject);

            const searchfilter: TSys.IDatasetModule = {
                dict: dict.sys.searchfilter,
                headers: XArray.createIf('key', dict.sys.searchfilter.headers),
                rows: undefined,
                key: 'searchfilter',
                template: 'searchfilter'
            }

            return {
                dept: dept,
                people: people,
                role: role,

                projecttype: projecttype,
                stage: stage,
                workset: workset,
                workitem: workitem,
                checkpoint: checkpoint,

                projectplan: projectplan,
                inspector: inspector,
                actor: actor,
                project: project,
                inputproject: inputproject,
                pilotproject: pilotproject,
                meditateproject: meditateproject,
                candidateproject: candidateproject,
                begunproject: begunproject,
                acceptproject: acceptproject,

                workitemexesummary: workitemexesummary,
                projectexe: projectexe,
                supervisesummary: supervisesummary,
                supervise: supervise,
                superviseaudit: superviseaudit,
                executeaudit: executeaudit,
                users: users,
                teams: teams,
                projecttypedefine: projecttypedefine,
                projecttypes: projecttypes,
                projectplans: projectplans,
                depts: depts,

                workitemexes: workitemexes,
                supervises: supervises,

                problem: problem,
                problems: problems,

                systemconfig: systemconfig,
                operationlog: operationlog,
                systeminfo: systeminfo,
                advfilter: searchfilter,

                summarypatchproject: summarypatchproject,
                summaryongoingproject: summaryongoingproject,
                summaryprojectstage: summaryprojectstage,
                summaryprojectworkitem: summaryprojectworkitem,
                summarycancelproject: summarycancelproject,
                summary: summary
            }
        }

        type DataSources = ReturnType<typeof build>;
        const props = this._props as { datasources: DataSources };
        return props.datasources || (props.datasources = build());
    }

    get mainnavs(): RouteInfo[] {
        const { _props: props, auth: { me: { priviledges = <AuthService.Priviledge>{} } = {} } } = this;
        if (props.mainnavs) return props.mainnavs;

        const build = (route: Route, path: string): RouteInfo[] => {
            return route.children?.reduce<{
                navs: RouteInfo[],
                path: string
            }>(({ navs, path }, i) => {
                path = path || "";

                const ipath = i.path || "";
                const curpath = `${path}${path && ipath && '/' || ''}${i.path}`

                if (true && !priviledges[ipath]) {
                    return { navs, path };
                }

                navs.push({
                    ...((i.data || {}) as RouteInfo),
                    path: curpath
                });

                return { navs, path }
            }, {
                navs: [],
                path: path
            }).navs || [];
        }

        const route: Route = (this.router.config || []).find(
            r => (r?.data?.level ?? 0) == 1
        ) || {};

        return props.mainnavs = build(route, route.path || '');
    }

    get curRoute() {
        const findLast = (route: ActivatedRoute): ActivatedRoute => {
            return route && route.children.length > 0 ? findLast(route.children[0]) : route;
        }

        return findLast(this.curroute);
    }

    get curRouteInfo() {
        return this.curRoute?.routeConfig?.data as RouteInfo || {};
    }

    get consumeByProject(): boolean {
        return !!this.auth?.me?.consumebyproject;
    }

    get superviseaudit(): boolean {
        return !!this.auth?.me?.superviseaudit;
    }

    get executeaudit(): boolean {
        return !!this.auth?.me?.executeaudit;
    }

    get prepareproject(): boolean {
        return this.lang?.prepareproject ?? this.auth?.me?.prepareproject;
    }

    get defaultfiler(): Prj.SearchFilter {
        if (this._props.defaultfiler) return this._props.defaultfiler;

        this._props.defaultfiler = new Prj.SearchFilter(this.org, this.prj, {
            name: "",
            description: "",
            nowyear: true,
            start: new Date(),
            end: new Date(),
            constrstatus: [],
            constrtype: [],
            workitems: []
        });

        return this._props.defaultfiler;
    }

    get lang(): TApp.Lang.Res<typeof Langs> {
        const { _props: { lang } } = this;
        console.assert(lang);
        return lang!;
    }

    constructor(
        public translate: TranslateService,
        public curroute: ActivatedRoute,
        public adapter: DateAdapter<any>,
        public httpClient: HttpClient,
        public titleService: Title,
        public injector: Injector,
        public router: Router,

        @Inject(forwardRef(() => AuthService))
        public auth: AuthService,

        public msg: PuMsgService,

        public alerts: PuAlertService,

        public version: PuVersioinService,

        public es: PuSchemaService
    ) {
        const app = this, { _props: props } = this;

        // create the language change resolve.
        const onLangChange = (props.onLangChange = (
            translate.onLangChange.pipe(
                map(({ lang, translations }) => {
                    return translations;
                }),
                shareReplay(1)
            )
        ));

        // create data which depends on the lang resource
        const subLang = onLangChange.subscribe({
            next(lang: any) {
                // now we can safely build language depent global definitions.
                props.lang = app.trans(Langs);

                // change the webpage name
                const appname = lang?.general?.title;
                titleService.setTitle(appname ?? DefAppName);
            },
            complete() {
                tick(() => subLang?.unsubscribe());
            }
        })

        // initialize the language options
        const subVersion = version.fetcher.subscribe(() => {
            const defLang = 'zh-CN';
            adapter.setLocale(defLang);
            translate.addLangs([defLang]);
            translate.setDefaultLang(defLang);
            translate.use(defLang);
            tick(() => subVersion?.unsubscribe());
        })

        // intercept the route jump to confirm login
        router.events.pipe(
            filter((e): e is NavigationStart => e instanceof NavigationStart)
        ).subscribe(
            (e: NavigationStart) => {
                if (!auth.me && e.url != "/login") {
                    auth.pathto = e.url;

                    router.navigate(['/login/'], {
                        queryParams: {}
                    });
                }
            }
        )

        // handle error/notice/warning etc message default
        msg.onRequestError().subscribe(status => {
            this.alerts.push({
                ...status,
                type: "danger",
                dismiss: 5000
            })
        })

        // clear the service data while logout
        msg.onMeChanged.subscribe(({ cur, pre }) => {
            if (!cur) {
                this.clearCaches();
            }
        })

        // register project sets for schema usage
        const sub = es.resolve().subscribe({
            complete() {
                tick(() => sub?.unsubscribe());
            },
            next() {
                // register translate action function into schemaservice
                es.actions['translate'] = translate.instant.bind(translate);
                es.actions['datetime'] = Date.from.bind(Date);

                // register number related action function to format number output
                const kvdiffers = injector.get(KeyValueDiffers);
                const localid = injector.get(LOCALE_ID);
                const pipes = {
                    currency: () => new CurrencyPipe(localid),
                    date: () => new DatePipe(localid),
                    number: () => new DecimalPipe(localid),
                    json: () => new JsonPipe(),
                    keyvalue: () => new KeyValuePipe(kvdiffers),
                    lowercase: () => new LowerCasePipe(),
                    percent: () => new PercentPipe(localid),
                    slice: () => new SlicePipe(),
                    titlecase: () => new TitleCasePipe(),
                    uppercase: () => new UpperCasePipe()
                }

                for (const pipe in pipes) {
                    type PipeType = keyof typeof pipes;
                    const pipeinstance = pipes[pipe as PipeType]();
                    es.actions[pipe] = pipeinstance.transform.bind(pipeinstance);
                }

                // register project/plan/projecttype/workitem recordset
                const asValue = { $Type: PuSchemaService.ApiType.AsValueGetter }
                es.regGetter('recordsets', 'projecttypes', _.extend(() => app.prj.projecttypes, asValue));
                es.regGetter('recordsets', 'workitems', _.extend(() => app.prj.workitems, asValue));
                es.regGetter('recordsets', 'project', _.extend(() => app.prj.project, asValue));
                es.regGetter('recordsets', 'plans', _.extend(() => app.prj.plans, asValue));

                _.extend(es.transParam, {
                    selectedYear(param: { [param: string]: any }): { [param: string]: any } {
                        const syear = param.selectedYear;
                        delete param.selectedYear
                        if (syear) {
                            param.year = [syear, syear]
                        }

                        return param
                    },

                    selectedProcess(param: { [param: string]: any }): { [param: string]: any } {
                        const process = param.selectedProcess;
                        delete param.selectedProcess;
                        if (process) {
                            param.projecttype = [{
                                id: process
                            }]
                        }

                        return param;
                    },

                    pool(param: { [param: string]: any }): { [param: string]: any } {
                        let pval: string | ((string)[]) = param.pool;
                        delete param.pool;
                        if (pval == null) {
                            return param;
                        }

                        pval = _.isArray(pval) ? pval : [pval];
                        param.pool = pval.map(v => {
                            return _.isString(v) ? v : Value.fromEnum(v, TPrj.Pool)
                        })

                        return param;
                    }
                })

                tick(() => sub?.unsubscribe());
            }
        })
    }

    resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
        const { _props: { onLangChange } } = this;
        return onLangChange;
    }

    trans<T extends string | TApp.Lang.Def>(key: T, interpolateParams?: Object): TApp.Lang.Res<T> {
        if (_.isString(key)) {
            return this.translate.instant(key, interpolateParams);
        }

        return _.reduce(key as TApp.Lang.Def, (res, r, k) => {
            return res[k] = this.trans(r as (string | TApp.Lang.Def), interpolateParams), res;
        }, <any>{})
    }

    clearCaches() {
        const { _props: props } = this;
        if (Editor.detect.cud == props.backface?.cud) {
            Editor.detect.cud = undefined;
        }

        const caches: (keyof typeof props)[] = [
            'alerts', 'datasources', 'mainnavs', 'backface',
            'org', 'prj', 'exec', 'report', 'dict'
        ];

        caches.forEach(key => {
            const cache = props[key];
            (cache as any)?.['ngOnDestroy']?.();
            delete props[key];
        })
    }

    getenumkey(input?: TPrj.IWorkitem.Type): string {
        const { dict: { WSProperties: { indexed } } } = this;
        return (input ? indexed[input]?.title : '') ?? '';
    }

    createDatasetModules(...mods: (TSys.IDatasetModule | undefined)[]): TSys.IDatasetModules {
        const _mods = (mods || <TSys.IDatasetModule[]>[]).filter(
            (m): m is TSys.IDatasetModule => m != undefined
        );

        return _.extend(_mods, {
            currentIndex: 0
        })
    }

    private excludeHeaders(modules?: JSON.Object) {
        //////////////////////////////////
        // Sample object
        //////////////////////////////////
        /*         
        const resource = {
            systemconfg: {
                modules: {
                    exclude: {
                        actor: {
                            header: true,
                            domains: true
                        }
                    }
                }
            }
        }
         */
        //////////////////////////////////
        // Sample object
        //////////////////////////////////

        const excludes: {
            [Module: string]: {
                [Header: string]: boolean
            }
        } = _.takeIf(_.isObject, modules?.['exclude'], {});

        const regField = /^([\w\d_]+)[\.\[]?/;
        const { dict: { prj, exec, org } } = this;

        _.forEach(excludes, (module, modulekey) => {
            const dicModule = _.get(prj, modulekey) || _.get(exec, modulekey) || _.get(org, modulekey);

            dicModule && _.forEach(module, (excluded, headerkey) => {
                const header = excluded && dicModule.headers?.find((h: { key: string }) => (
                    regField.exec(h.key)?.[1] == headerkey
                )) || null;

                if (header) {
                    dicModule.headers.remove(header);
                }
            })
        })
    }

}
