import { map, Observable, ReplaySubject } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';

import { NgxAMapCreaterService } from './ngx-amap.creater.service';
import { AMapLoggerService } from '../amap-logger.service';
import { Getter } from '../../base/types';

const TAG = 'AMapPolygon';

@Injectable()
export class NgxAMapPolygonCreaterService implements Getter<AMap.Polygon> {
    private readonly polygon$ = new ReplaySubject<AMap.Polygon>(1);
    private polygon?: AMap.Polygon;

    constructor(
        private readonly creater: NgxAMapCreaterService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    /**
     * 获取折线
     */
    get(): Observable<AMap.Polygon> {
        return this.polygon$;
    }

    /**
     * 创建折线 AMap.Polygon
     * @param options 选项
     */
    create(options: AMap.Polygon.Options): Observable<AMap.Polygon> {
        const { creater, ngZone, logger, polygon$ } = this;

        return creater.get().pipe(
            map(
                m => {
                    options.map = m;
                    this.polygon = ngZone.runOutsideAngular(
                        () => new AMap.Polygon(options)
                    );

                    logger.d(TAG, 'new Polygon created.');
                    polygon$.next(this.polygon);
                    polygon$.complete();
                    return this.polygon;
                }
            )
        );
    }

    /**
     * 销毁折线
     */
    destroy(): void {
        const { logger } = this;

        this.get().subscribe(
            m => {
                m.setMap(null);
                logger.d(TAG, 'Polygon destroyed.');
                delete this.polygon;
            }
        );
    }
}
