import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Input, Inject, OnDestroy, Optional, Injector } from "@angular/core";
import { JsonEditorOptions } from "ang-jsoneditor";
import { HttpClient } from "@angular/common/http";

import { Sys as TSys, Exec as TExec, Prj as TPrj } from "../../application/service/backface/types";
import { GovTableComponent } from "../../utils/view/gov.table.component/gov.table.component";
import { GovFormComponent } from "../../utils/view/gov.form.component/gov.form.component";
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { PuSysService } from "../../utils/puzzle/pu.service/pu.sys.service";
import { FieldProperty } from "../../utils/view/model/field.property";
import { Backface } from "../../application/service/backface/config";
import { Report } from "../../application/service/backface/report";
import { AppService } from "../../application/service/app.service";
import { Exec } from "../../application/service/backface/exec";
import { Prj } from "../../application/service/backface/prj";
import { XFilesComponent } from "./files.component";
import { Editor } from "../../utils/libs/editor";
import { PicsComponent } from "./pics.component";
import { PuDialogService } from "../../utils/puzzle/pu.dialog/pu.dialog.service";

export type ResultData = {
    title: string,
    from?: GovTableComponent,
    header?: TSys.IDatasetHeader,
    dataset?: TSys.IDatasetModule,
    object?: Prj.ProjWorkitem | Exec.Problem | Exec.AuditResult,
    results?: Exec.Result[]
};

const Hints = {
    project: ["project.plan.name", "project.name"],
    workitem: ["projecttype.name", "stage.name", "workset.name", "name"],
}

@Component({
    selector: "gov-results, [gov-results]",
    templateUrl: "./results.component.html",
    styleUrls: ['./results.component.scss'],
    providers: [PuTemplateService]
})
export class ResultsComponent extends FieldProperty implements OnDestroy {
    private _props = Prop.Of<ResultsComponent, {
        _asyncreport: Backface.AsyncReport;
    }>(this);

    get property() {
        return _.property
    }

    get projecthints(): string[] {
        return Hints.project;
    }

    get workitemhints(): string[] {
        return Hints.workitem;
    }

    get results(): Exec.Result[] {
        const { _props: props } = this;

        props.results = (
            props.results ||
            this.data?.results ||
            this.loadResults()
        );

        return props.results;
    }

    get workitem(): Prj.ProjWorkitem | undefined {
        const { data: { object } } = this;
        if (object instanceof Exec.Problem) return object.workitem;
        if (object instanceof Prj.Workitem) return object;
        return object?.workitem;
    }

    get project(): Prj.Project | undefined {
        const { data: { object } } = this;
        if (object instanceof Exec.Problem) return object.project;
        if (object instanceof Prj.Workitem) return object.project;
        return object?.project;
    }

    get headers(): Editor.IField[] | undefined {
        return this.workitem?.checkpoints;
    }

    get isaudit(): boolean {
        return this.data.object instanceof Exec.AuditResult;
    }

    get issuperviseaudit(): boolean {
        const { data: { object } } = this;
        return object instanceof Exec.AuditResult && (object.workitem.type == TPrj.IWorkitem.Type.supervise);
    }

    get isexecuteaudit(): boolean {
        const { data: { object } } = this;
        return object instanceof Exec.AuditResult && (object.workitem.type != TPrj.IWorkitem.Type.supervise);
    }

    get jsoneditoroptions(): JsonEditorOptions {
        const { _props: props } = this;

        return props.jsoneditoroptions || (
            props.jsoneditoroptions = new JsonEditorOptions(),
            props.jsoneditoroptions.modes = ['code', 'text', 'tree', 'view'],
            props.jsoneditoroptions.statusBar = false,
            //props.jsoneditoroptions.schema = schema,
            props.jsoneditoroptions.mode = 'code',
            props.jsoneditoroptions
        )
    };

    @Input('resultdata')
    get resultdata(): ResultData {
        return this.data;
    }

    set resultdata(val: ResultData) {
        this.data = val;
    }

    constructor(
        public sys: PuSysService,
        public override app: AppService,
        public fieldtpls: PuTemplateService,
        protected httpClient: HttpClient,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public data: ResultData
    ) {
        super(app);

        const { _props: props } = this;
        props._asyncreport = new Backface.AsyncReport(httpClient);
    }

    ngOnDestroy(): void {
        const { _props: { _asyncreport } } = this;
        _asyncreport.ngOnDestroy();
    }

    BOf(val: any) {
        return GovFormComponent.BOf(val);
    }

    loadResults(): Exec.Result[] {
        const { data: { object } } = this;
        if (!object) return [];

        const host: {
            project?: Prj.Project;
            workitem?: Prj.ProjWorkitem;
        } = {};

        let param: {};
        if (object instanceof Prj.Workitem) {
            const {
                id: workitemid,
                workset: { id: worksetid } = {},
                stage: { id: stageid } = {},
                projecttype: { id: projecttypeid } = {},
                project: { id: projectid } = {}
            } = object;

            param = {
                project: projectid,
                projecttype: projecttypeid,
                stage: stageid,
                workset: worksetid,
                workitem: workitemid
            }

            host.workitem = object;
            object.project && (host.project = object.project);
        } else if (object instanceof Exec.Problem) {
            param = { id: object.parent.id }
            object.workitem && (host.workitem = object.workitem);
            object.project && (host.project = object.project);
        } else {
            param = { audit: "hist", id: object.id }
            object.workitem && (host.workitem = object.workitem);
            object.project && (host.project = object.project);
        }

        const results: Exec.Result<any>[] = [];
        const { _props: { _asyncreport }, isaudit, app } = this;
        _asyncreport.report("/entity/results/retrieve", (res: TExec.IResult[] = []) => {
            if (isaudit) {
                Report.buildAuditResults(app, res, results as Exec.AuditResult[], host)
            } else {
                Report.buildResult(app, res, results, host);
            }
        }).query(param)

        return results;
    }

    toAuditResult(rst: any): Exec.AuditResult | undefined {
        return (rst instanceof Exec.AuditResult) ? rst : undefined
    }

    isProjectedResult() {
        return (this.data?.results == null);
    }

    isVisible(header: Editor.IField, editor: Editor.Editor<any>): boolean {
        if (header?.key == 'yearlyinvested' && this.app.consumeByProject) {
            return false;
        }

        const fieldvalue = this.getCellField(editor, header, this.headers)?.data;
        if (_.isArray(fieldvalue) && fieldvalue.isEmpty) {
            return false;
        }

        if ((header?.dependvalues?.length ?? 0) <= 0) {
            return true
        }

        const { dependfield: { key: targetheaderkey = '' } = {}, dependvalues = [] } = header ?? {};
        const targetheader = this.headers?.find(header => header.key == targetheaderkey);
        const targetfield = this.getCellField(editor, targetheader, this.headers);
        const targetvalue = targetfield?.data;

        const matchindex = dependvalues.findIndex(v => {
            switch (typeof v) {
                case 'function': {
                    return v(targetvalue, editor);
                }

                default: {
                    return v == targetvalue;
                }
            }
        })

        return matchindex >= 0;
    }

    openPics(pics: Exec.Pic[], current: Exec.Pic) {
        this.sys.openDialog(PicsComponent, {
            data: {
                pics, current
            }
        })
    }

    openXFiles(files: Exec.XFile[], current: Exec.XFile) {
        this.sys.openDialog(XFilesComponent, {
            data: {
                files, current
            }
        })
    }

    static open(dialog: PuDialogService, data: {} = {}, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: ResultsComponent,
            injector: injector,
            maximized: true,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '90vh',
            maxWidth: '100vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
        }, {
            ...data
        })
    }
}