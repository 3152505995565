import { Component, OnInit, AfterViewInit } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';

import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { AppService } from "../../application/service/app.service";
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';

@Component({
    selector: 'systemset.component',
    templateUrl: "./systemset.component.html",
    styleUrls: ['./systemset.component.scss'],
    providers: [
        PuTemplateService
    ]
})
export class SystemsetComponent implements AfterViewInit, OnInit {
    constructor(public app: AppService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
    }

    static open(dialog: PuDialogService): MatDialogRef<any, any> {
        return dialog.open({
            template: SystemsetComponent,
            maximized: false,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '50vh',
            maxWidth: '100vw',
            minWidth: '40vw',
            height: '40vh',
            width: '40vw'
        })
    }
}
