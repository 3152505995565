import { Component, AfterViewInit, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';

import { Prj as TPrj, Sys as TSys } from '../../../application/service/backface/types';
import { PuTemplateService } from '../../../utils/puzzle/pu.template/pu.template';
import { ExcelService } from '../../../application/service/excel.service';
import { FieldProperty } from '../../../utils/view/model/field.property';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Prj } from '../../../application/service/backface/prj';
import { Editor } from '../../../utils/libs/editor';

const Value = Editor.Value;

@Component({
    selector: "sumcancelproject, [sumcancelproject]",
    templateUrl: "./sumcancelproject.component.html",
    styleUrls: ['./sumcancelproject.component.scss'],
    providers: [PuTemplateService]
})
export class SumCancelProjectComponent extends GovEditor.ToolBar implements OnInit, AfterViewInit, ExcelService.ISummary {
    private readonly _props = Prop.Of(this);
    readonly noinputtxt: boolean = true;
    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = true;

    get fielder(): FieldProperty {
        const { _props: props, app } = this;
        return props.fielder || (
            props.fielder = new FieldProperty(app)
        )
    }

    // Summary Fields Parameter
    totalbudget: number = 0;
    yearbudget: number = 0;
    prjcount: number = 0;

    sumdisplayedColumns: string[] = [];
    get sumFields(): Editor.IFieldCol[] {
        const { _props: props } = this;
        const _this = this;

        return props.sumFields || (props.sumFields = [
            {
                key: "total.key",
                title: this.app.lang.general.total,
                col: 2
            },
            {
                key: "total.value",
                get title(): string {
                    const { prjcount, app: { lang } } = _this;
                    return `${prjcount}${lang.general.unit}`;
                },
                col: 2
            },
            {
                key: "amount.key",
                title: this.app.lang.budget.amount,
                col: 2
            },
            {
                key: "amount.value",
                get title(): string {
                    const { totalbudget, locale } = _this;
                    return formatNumber(totalbudget, locale, "1.0-0")
                },
                col: 3
            },
            {
                key: "budgetunit",
                title: `${this.app.lang.budget.unit}：${this.app.lang.general.bunit}`,
                col: 2
            }
        ]);
    }

    // Main Table Header & Data Zone parameter
    displayedColumns: string[] = [];
    dataSource: Prj.SumProjectData[] = [];
    colFields: Editor.IFieldCols = XArray.create<Editor.IFieldCol, 'key'>('key', {
        key: "id",
        title: 'project.id',
        col: 1
    }, {
        key: "name",
        title: 'project.name',
        col: 1
    }, {
        key: "actorleaders",
        title: 'actor.leaders',
        col: 1
    }, {
        key: "owndept",
        title: 'project.owndept',
        col: 1
    }, {
        key: "owncompany",
        title: 'project.owncompany',
        col: 1
    }, {
        key: "location",
        title: 'project.position',
        col: 1
    }, {
        key: "constrtype",
        title: 'project.constrtype',
        col: 1
    }, {
        key: "description",
        title: 'project.description',
        cellcls: 'largewidth',
        col: 1
    }, {
        key: "budgetstartend",
        title: 'budget.startend',
        col: 1
    }, {
        key: "budgetamount",
        title: 'budget.amount',
        col: 1
    }, {
        key: "memo",
        title: 'project.memo',
        col: 1
    });

    @Input('toolbar')
    toolbar?: GovEditor.ISetBinder;

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        public app: AppService,
        public override router: ActivatedRoute
    ) {
        super(router, 'sumcancelproject');
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
        this.sumdisplayedColumns = this.sumFields.map(p => p.key).filter(_.isString);
        this.displayedColumns = this.colFields.map(p => p.key).filter(_.isString);
        this.GetProjectData();
    }

    ngAfterViewInit(): void {
    }

    exportas(val: string) {
        const { app: { lang: { general: { orgname }, summary: { cancelprjtable } } } } = this;
        ExcelService.generateExcel(`${orgname}${cancelprjtable}`, this as ExcelService.ISummary);
    }

    GetProjectData() {
        this.dataSource = [];
        this.prjcount = 0;
        this.totalbudget = 0;
        this.yearbudget = 0;

        const { app: { prj: { project: projects } } } = this;
        projects.allOf({ ispatched: false, cancelled: true }, project => {
            const sumproject: Prj.SumProjectData = {
                id: project.id,
                name: project.name,
                pool: Value.fromEnum(project.pool, TPrj.Pool),
                owncompany: project.owncompany,
                owndept: project.owndept,
                location: project.location,
                positonDistrict: project.positonDistrict,
                description: project.description,
                budgetstartend: project.budget.startend,
                constrstatus: project.constrstatusname,
                constrtype: project.constrtypename,
                budgetamount: project.budget.amount != undefined && formatNumber(project.budget.amount, this.locale, "1.0-0") || '',
                budgetyearamount: formatNumber(0, this.locale, "1.0-0"),
                budgetyeartarget: formatNumber(0, this.locale, "1.0-0"),
                actordomainsdept: project.getActordomainsdept(),
                actoractorsdept: project.getActoractorsdept(),
                actorsitesdept: project.getActorsitesdept(),
                actorheader: project.actor?.header?.name,
                actorleaders: project.getActorleaders(),
                memo: project.memo
            }

            this.totalbudget += (project.budget.amount) ? (project.budget.amount) : 0;
            this.dataSource.push(sumproject);
        });

        this.prjcount = this.dataSource.length;
    }

    getColspan(row: number, i: number): number {
        if (row == 1) {
            return this.sumFields[i].col ?? 1;
        }

        if (row == 2) {
            return this.colFields[i].col ?? 1;
        }

        return 1;
    }

    get sumFieldsData(): Editor.IFieldCol[][] {
        return [this.sumFields];
    }

    get bodyDataSource(): Prj.SumProjectData[] {
        return this.dataSource;
    }

    get colFieldsData(): Editor.IFieldCols {
        return this.colFields;
    }
}
