import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, AfterViewInit, ViewChild, Inject, Injector } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";

import { Prj as TPrj, Sys as TSys } from "../../../application/service/backface/types";
import { GovTableComponent } from "../gov.table.component/gov.table.component";
import { PuDialogService } from "../../puzzle/pu.dialog/pu.dialog.service";
import { AppService } from "../../../application/service/app.service";

@Component({
    selector: "gov-wssumitem, [gov-wssumitem]",
    templateUrl: "./gov.wssumitem.component.html",
    styleUrls: ["./gov.wssumitem.component.scss"]
})
export class GovWSSumitemComponent implements OnInit, AfterViewInit {
    private _props = Prop.Of(this);

    @ViewChild("govtablemodels", { static: true })
    set govtablemodels(val: GovTableComponent) {
        const { _props: props } = this;
        props.govtablemodels = val;
    }

    get govtablemodels(): GovTableComponent {
        const { _props: props } = this;
        return props.govtablemodels!;
    }

    get currentmodule(): TSys.IDatasetModule {
        const { _props: props, app: { dict: { exec }, prj } } = this;

        return props.currentmodule || (props.currentmodule = {
            dict: exec.sumworkitems,
            headers: XArray.createIf('key', exec.sumworkitems.headers),
            rows: prj.workitems.filter(
                p => (p.type == TPrj.IWorkitem.Type.normal || p.type == TPrj.IWorkitem.Type.verify)
            ).reverse(),
            key: 'wssumitem',
        });
    }

    readonly component?: ComponentType<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectedWorkItems: Array<string>,
            object?: { fullName?: string }
        },
        public app: AppService
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.data.selectedWorkItems.forEach(wsid => {
            var temp = this.app.prj.workitems.find(ws => ws.id == wsid);
            temp ? this.govtablemodels?.selection?.select(temp) : temp;
        });
    }

    getSelected() {
        return this.govtablemodels?.selection?.selected;
    }

    static open(dialog: PuDialogService, data: {} = {}, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: GovWSSumitemComponent,
            injector: injector,
            maximized: false,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: false,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '90vh',
            maxWidth: '100vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
        }, {
            ...data
        })
    }

}