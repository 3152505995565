/*
 * The flags allowed in zone - flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 */

(window as any).__zone_symbol__PASSIVE_EVENTS = ['scroll'];

/*

(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

*/

/**
 * Additional zone flags.
 */

/*

(window as any).__Zone_symbol_prefix = ;    // replace default '__zone_symbol__'

*/

/*
 * in IE / Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass`zone.js` patch for IE / Edge
 */

/*

(window as any).__Zone_enable_cross_context_check = true;

*/