import { Component, AfterViewInit, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';

import { PuTemplateService } from '../../../utils/puzzle/pu.template/pu.template';
import { Prj as TPrj } from '../../../application/service/backface/types';
import { ExcelService } from '../../../application/service/excel.service';
import { FieldProperty } from '../../../utils/view/model/field.property';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Prj } from '../../../application/service/backface/prj';
import { Editor } from '../../../utils/libs/editor';

const Value = Editor.Value;

@Component({
    selector: "sumpatchproject, [sumpatchproject]",
    templateUrl: "./sumpatchproject.component.html",
    styleUrls: ['./sumpatchproject.component.scss'],
    providers: [PuTemplateService]
})
export class SumPatchProjectComponent extends GovEditor.ToolBar implements OnInit, AfterViewInit, ExcelService.ISummary {
    private _props = Prop.Of(this);
    readonly noinputtxt: boolean = true;
    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = false;

    get fielder(): FieldProperty {
        const { _props: props, app } = this;
        return props.fielder || (
            props.fielder = new FieldProperty(app)
        )
    }

    // Summary Fields Parameter
    totalbudget: number = 0;
    yearbudget: number = 0;
    prjcount: number = 0;

    sumdisplayedColumns: string[] = [];
    get sumFields(): Editor.IFieldCol[] {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields || (props.sumFields = [
            {
                key: "total.key",
                title: lang.general.total,
                col: 1
            },
            {
                key: "total.value",
                get title() {
                    const { prjcount } = _this;
                    return `${prjcount}${lang.general.unit}`;
                },
                col: 2
            },
            {
                key: "amount.key",
                title: lang.budget.amount,
                col: 2
            },
            {
                key: "amount.value",
                get title() {
                    const { totalbudget, locale } = _this;
                    return formatNumber(totalbudget, locale, "1.0-0")
                },
                col: 2
            },
            {
                key: "yearamount.key",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.budget.amount}`
                },
                col: 2
            },
            {
                key: "yearamount.value",
                get title() {
                    const { yearbudget, locale } = _this;
                    return formatNumber(yearbudget, locale, "1.0-0");
                },
                col: 2
            },
            {
                key: "budgetunit",
                title: `${lang.budget.unit}：${lang.general.bunit}`,
                col: 2
            }
        ]);
    }

    // Main Table Header & Data Zone parameter
    displayedColumns: string[] = [];
    dataSource: Prj.SumProjectData[] = [];
    get colFields(): Editor.IFieldCols {
        const { _props: props, app: { lang } } = this;
        return props.colFields || (props.colFields = XArray.create<Editor.IFieldCol, 'key'>('key',
            {
                key: "id",
                title: lang.project.id,
                col: 1
            },
            {
                key: "planname",
                title: lang.project.plan,
                col: 1
            },
            {
                key: "name",
                title: lang.project.name,
                col: 1
            },
            {
                key: "actorleaders",
                title: lang.actor.leaders,
                col: 1
            },
            {
                key: "owndept",
                title: lang.project.owndept,
                col: 1
            },
            {
                key: "owncompany",
                title: lang.project.owncompany,
                col: 1
            },
            {
                key: "location",
                title: lang.project.location,
                col: 1
            },
            {
                key: "constrtype",
                title: lang.project.constrtype,
                col: 1
            },
            {
                key: "description",
                title: lang.project.description,
                cellcls: 'largewidth',
                col: 1
            },
            {
                key: "budgetstartend",
                title: lang.budget.startend,
                col: 1
            },
            {
                key: "budgetamount",
                title: lang.budget.amount,
                col: 1
            },
            {
                key: "budgetyeartarget",
                title: `${lang.general.annual}${lang.budget.target}`,
                cellcls: 'largewidth',
                col: 1
            },
            {
                key: "memo",
                title: lang.project.memo,
                col: 1
            }
        ));
    }

    @Input('toolbar')
    toolbar?: GovEditor.ISetBinder;

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        public app: AppService,
        public override router: ActivatedRoute
    ) {
        super(router, 'sumpactchproject', {
            selectedYear: app.dict.currentYear
        });
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
        this.sumdisplayedColumns = this.sumFields.map(p => p.key).filter(_.isString);
        this.displayedColumns = this.colFields.map(p => p.key).filter(_.isString);
        this.GetProjectData();
    }

    ngAfterViewInit(): void {
    }

    override dosearch(changed: GovEditor.ToolBar.Changed): void {
        if (changed.selectedYear == null) return;
        this.GetProjectData();
    }

    exportas(val: string) {
        const { selectedYear, app: { lang: { general: { orgname, annual }, summary: { patchprjtable } } } } = this;
        ExcelService.generateExcel(`${orgname}${selectedYear}${annual}${patchprjtable}`, this as ExcelService.ISummary);
    }

    GetProjectData() {
        this.dataSource = [];
        this.prjcount = 0;
        this.totalbudget = 0;
        this.yearbudget = 0;

        const { app: { prj: { project: projects } }, selectedYear } = this;
        projects.allOf({ ispatched: false, cancelled: false, ispreproject: true }, project => {
            const sumproject: Prj.SumProjectData = {
                id: project.id,
                name: project.name,
                pool: Value.fromEnum(project.pool, TPrj.Pool),
                owncompany: project.owncompany,
                owndept: project.owndept,
                planname: project?.plan?.name,
                location: project.location,
                description: project.description,
                budgetstartend: project.budget.startend,
                constrtype: project.constrtypename,
                constrstatus: project.constrstatusname,
                budgetamount: project.budget.amount != undefined && formatNumber(project.budget.amount, this.locale, "1.0-0") || '',
                budgetyearamount: formatNumber(project.getBudgetAmount(selectedYear!), this.locale, "1.0-0"),
                budgetyeartarget: project.getBudgetTarget(selectedYear!),
                actordomainsdept: project.getActordomainsdept(),
                actorsitesdept: project.getActorsitesdept(),
                actorleaders: project.getActorleaders(),
                actoractors: project.getActoractors(),
                memo: project.memo
            };

            this.dataSource.push(sumproject);
            this.totalbudget += (project.budget.amount) ? (project.budget.amount) : 0;
            this.yearbudget += project.getBudgetAmount(selectedYear!);
        });

        this.prjcount = this.dataSource.length;
    }

    getColspan(row: number, i: number): number {
        if (row == 1) {
            return this.sumFields[i]?.col ?? 1;
        }

        if (row == 2) {
            return this.colFields[i]?.col ?? 1;
        }

        return 1;
    }

    get sumFieldsData(): Editor.IFieldCol[][] {
        return [this.sumFields];
    }

    get bodyDataSource(): Prj.SumProjectData[] {
        return this.dataSource;
    }

    get colFieldsData(): Editor.IFieldCols {
        return this.colFields;
    }
}
