import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AMapLoaderService } from './amap-loader.service';
import { AMapLoggerService } from './amap-logger.service';

const TAG = 'AMapUILoader';

@Injectable({ providedIn: 'root' })
export class AMapUILoaderService {
    private readonly state: Map<string, ReplaySubject<void>> = new Map();

    constructor(
        private mapLoader: AMapLoaderService,
        private logger: AMapLoggerService
    ) {
    }

    load(name: string | string[]): Observable<any> {
        const pKey = (Array.isArray(name) ? name.join(',') : name);
        const { state, logger, mapLoader } = this;

        if (state.has(pKey)) {
            return state.get(pKey)!;
        }

        logger.d(TAG, 'loading ui:', pKey, '...');

        const loading$ = new ReplaySubject<any>(1);
        mapLoader.load().subscribe({
            next: () => {

                mapLoader.loadUI().subscribe({
                    next: () => {
                        AMapUI.loadUI(Array.isArray(name) ? name : [name], (u => {
                            logger.d(TAG, 'loading ui:', pKey, 'COMPLETE');

                            loading$.next(u);
                            loading$.complete();
                        }));

                        state.set(pKey, loading$);
                    },
                });

            },
        });

        return loading$;
    }
}