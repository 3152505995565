import { Component, Inject, OnInit, AfterViewInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartialDeep } from 'type-fest';

import { Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { AppService } from '../../application/service/app.service';
import { Prj } from '../../application/service/backface/prj';

@Component({
    templateUrl: "./projectlist.component.html",
    styleUrls: ['./projectlist.component.scss'],
    providers: [PuTemplateService]
})
export class ProjectListComponent implements OnInit, AfterViewInit {
    private _props = Prop.Of(this);

    get currentmodule(): TSys.IDatasetModule {
        const { app: { dict: { prj: { project, project: { headers } } } } } = this;
        const { _props: props, data: { prjidlist: ids } = {} } = this;
        const { app: { prj: { project: projects } } } = this;

        return props.currentmodule || (props.currentmodule = {
            rows: ids?.map(({ id, pool }) => projects.firstOf({ id, pool })) ?? [],
            headers: XArray.createIf('key', headers?.filter(h => h.key == "projectdetails" || h.key == "name")),
            dict: project,
            key: 'projectlist',
        });
    }

    constructor(
        public app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            prjidlist: PartialDeep<Prj.Project>[],
            title: string, header: string,
        }
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
    }

    static open(dialog: PuDialogService, data: {
        prjidlist: PartialDeep<TPrj.IProject>[],
        title: string, header: string,
    }, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: ProjectListComponent,
            injector: injector,
            maximized: true,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '90vh',
            maxWidth: '100vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
        }, {
            ...data
        })
    }
}
