import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class AMapPixelService {
    constructor() { }

    create(options: AMap.IPixel | AMap.Pixel): AMap.Pixel {
        if (options instanceof AMap.Pixel) {
            return options;
        }

        if (Array.isArray(options)) {
            return new AMap.Pixel(options[0], options[1]);
        }

        return new AMap.Pixel(options.x || 0, options.y || 0, options.round);
    }
}
