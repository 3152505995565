import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

function refreshStyle(bar: GovSplitterBarComponent) {
    const { renderer, elRef: { nativeElement }, direction, width } = bar;
    renderer.setStyle(nativeElement, 'flex-basis', `${width}px`);

    // add a class to control the cursor
    renderer.removeClass(nativeElement, 'vertical');
    renderer.removeClass(nativeElement, 'horizental');
    direction && renderer.addClass(nativeElement, direction);

    // fix safari bug about gutter height when direction is horizontal
    renderer.setStyle(nativeElement, 'height', (direction === 'vertical') ? `${width}px` : `100%`);
}

@Component({
    templateUrl: "./gov.splitter.bar.component.html",
    styleUrls: ["./gov.splitter.bar.component.scss"],
    selector: "gov-splitter-bar, [gov-splitter-bar]",
    exportAs: "GovSplitterBar",
})
export class GovSplitterBarComponent implements OnInit {
    private _props = Prop.Of(this);

    @Input("width")
    set width(val: number | undefined) {
        this._props.width = val;
        refreshStyle(this);
    }

    get width(): number | undefined {
        return this._props.width;
    }

    @Input("direction")
    set direction(v: 'vertical' | 'horizontal' | undefined) {
        this._props.direction = v;
        refreshStyle(this);
    }

    get direction(): 'vertical' | 'horizontal' | undefined {
        return this._props.direction;
    }

    @Input('order')
    set order(v: number) {
        this.renderer.setStyle(this.elRef.nativeElement, 'order', v);
    }

    constructor(
        public elRef: ElementRef,
        public renderer: Renderer2
    ) {

    }

    ngOnInit() {

    }

}
