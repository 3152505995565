
import { DatePipe } from '@angular/common';

type DatePipeConstructor = typeof DatePipe;

const reg = /(?:(?:\d+Z\s*)|(?:[+-]{1}\d+:\d+\s*))$/i;
const timereg = /\d+:\d+:\d+/i;

const PolyfillSlot = Prop.Slot<{ date?: boolean, datepipe?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);

    function _datepolyfill(Date: DateConstructor) {
        if (!Date || slot.date) return;
        slot.date = true;

        if (!Date.prototype.format) {
            Object.defineProperties(Date.prototype, {
                format: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, template: string, locale?: string | undefined): string {
                        const items = {
                            "M+": this.getMonth() + 1, // Month
                            "d+": this.getDate(), // Day
                            "H+": this.getHours(), // Hour
                            "m+": this.getMinutes(), // Minute
                            "s+": this.getSeconds(), // Second
                            "q+": Math.floor((this.getMonth() + 3) / 3), // Season
                            "S": this.getMilliseconds() // Minisec
                        };

                        let fmt = template;

                        if (/(y+)/.test(fmt)) {
                            const flen = RegExp.$1.length;
                            const year = `${this.getFullYear()}`;
                            fmt = fmt.replace(RegExp.$1, year.substr(4 - flen));
                        }

                        for (const k in items) {
                            if (new RegExp(`(${k})`).test(fmt)) {
                                const flen = RegExp.$1.length;
                                const val = items[k as keyof typeof items] + "";
                                fmt = fmt.replace(RegExp.$1, (flen == 1) ? (val) : (("00" + val).slice(-flen)));
                            }
                        }

                        return fmt;
                    }
                },

                addYear: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, years: number): Date {
                        return this.setFullYear(this.getFullYear() + years), this;
                    }
                },
                addMonth: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, months: number): Date {
                        return this.setMonth(this.getMonth() + months), this;
                    }
                },
                addDay: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, days: number): Date {
                        return this.setDate(this.getDate() + days), this;
                    }
                },
                addHour: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, hours: number): Date {
                        return this.setHours(this.getHours() + hours), this;
                    }
                },
                addMinute: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, minutes: number): Date {
                        return this.setMinutes(this.getMinutes() + minutes), this;
                    }
                },
                addSecond: {
                    writable: true, enumerable: true, configurable: true,
                    value(this: Date, seconds: number): Date {
                        return this.setSeconds(this.getSeconds() + seconds), this;
                    }
                },

                year: {
                    enumerable: true, configurable: true,
                    value(this: Date): number {
                        return this.getFullYear();
                    }
                },
                month: {
                    enumerable: true, configurable: true,
                    get(this: Date): number {
                        return this.getMonth() + 1;
                    }
                },
                day: {
                    enumerable: true, configurable: true,
                    get(this: Date): number {
                        return this.getDate();
                    }
                },
                weekDay: {
                    enumerable: true, configurable: true,
                    get(this: Date): number {
                        return this.getDay();
                    }
                },
                hour: {
                    enumerable: true, configurable: true,
                    get(this: Date): number {
                        return this.getHours();
                    }
                },
                minute: {
                    enumerable: true, configurable: true,
                    get(this: Date): number {
                        return this.getMinutes();
                    }
                },
                second: {
                    enumerable: true, configurable: true,
                    get(this: Date): number {
                        return this.getSeconds()
                    }
                },
            })
        }

        Object.defineProperties(Date, {
            polyfill: {
                configurable: false, writable: false,
                value(wnd: Window) {
                    _polyfill(wnd);
                }
            },
            from: {
                writable: true, enumerable: true, configurable: true,
                value(str?: string): Date | undefined {
                    if (!str) return new Date();

                    if (!reg.test(str) && timereg.test(str)) {
                        str = str + "+00:00";
                    }

                    const val = new Date(str);
                    if (isNaN(val.valueOf())) {
                        return;
                    }

                    return val;
                }
            }
        })
    }

    function _datepipepolyfill(DatePipe: DatePipeConstructor) {
        if (!DatePipe || slot.datepipe) return;
        slot.datepipe = true;

        const transform = DatePipe.prototype.transform;
        Object.defineProperties(DatePipe.prototype, {
            transform: {
                enumerable: false, configurable: true,
                value(this: DatePipe, value: any, ...args: any[]) {
                    if (_.isString(value)) {
                        value = Date.from(value);
                    }

                    return transform.call(this, value, ...args);
                }
            }
        })
    }

    _datepolyfill((wnd as any)?.Date);
    _datepipepolyfill(DatePipe);
}

_polyfill(window);

export { };

declare global {
    interface Date {
        addYear(years: number): Date;
        addMonth(months: number): Date;
        addDay(days: number): Date;
        addHour(hours: number): Date;
        addMinute(minutes: number): Date;
        addSecond(seconds: number): Date;

        readonly year: number;
        readonly month: number;
        readonly weekDay: number;
        readonly day: number;
        readonly hour: number;
        readonly minute: number;
        readonly second: number;
    }

    interface DateConstructor {
        polyfill(wnd: Window): void;
        from(str?: string): Date;
    }
}
