<ngb-carousel [activeId]="'slide-ngb-slide-' + files.indexOf(current)"
  (slide)="BOf(carousel).current = $event.current;changeProgress($event.current)" #carousel="ngbCarousel"
  class="h-100 w-100 px-2" *ngIf="files">
  <ng-template ngbSlide [id]="'slide-ngb-slide-' + index" *ngFor="let file of files; index as index">
    <div class="h-100 w-100" [ngSwitch]="file.content_type">
      <video async controls *ngSwitchCase="'video'" class="mat-card-image border w-100 h-100 m-0 p-0" [stopif]="
              (BOf(carousel).current || carousel.activeId) !=
              'slide-ngb-slide-' + index
            ">
        <source [src]="file.url | safe: 'url'" type="{{ file.content_type + '/' + file.mime_type }}" />
        Your browser does not support HTML5 video.
      </video>
      <ng-container *ngSwitchCase="'application'">
        <ng-container *ngIf="file.mime_type=='pdf'; else msdocs">
          <div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
            <pdf-viewer async [src]="file.url" [render-text]="true" [original-size]="false"
              class="mat-card-image border w-100 h-100 m-0 p-0"></pdf-viewer>
          </div>
        </ng-container>
        <ng-template #msdocs>
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center">
            <a target="_blank" href="{{file.url}}">
              <button mat-button [style.object-fit]="'contain'" [style.background-position]="'center'"
                [style.background-repeat]="'no-repeat'" class="mat-card-image border w-10 h-10 m-0 p-0">
                <img async priority [src]="app.dict.getFormatObj(file.mime_type)?.icon | safe : 'url'" placeholder />
              </button>
            </a>
          </div>
        </ng-template>
      </ng-container>
      <img async *ngSwitchDefault priority [src]="file.url | safe: 'url'" placeholder [style.object-fit]="'contain'"
        [style.background-position]="'center'" [style.background-repeat]="'no-repeat'"
        class="mat-card-image border w-100 h-100 m-0 p-0" />
    </div>
    <div class="carousel-caption" *ngIf="file.comment">
      <h3>{{ file.comment }}</h3>
    </div>
  </ng-template>
</ngb-carousel>

<ng-template pu-mat-dialog-actions>
  <mat-dialog-actions align="end">
    <div class="h-100 w-50 d-flex flex-row align-items-center justify-content-end">
      <a *ngIf="files[currentidx].mime_type=='pdf' || 
      files[currentidx].mime_type=='vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
      files[currentidx].mime_type=='msword' || 
      files[currentidx].mime_type=='vnd.openxmlformats-officedocument.wordprocessingml.document'|| 
      files[currentidx].mime_type=='vnd.ms-excel' ||
      files[currentidx].mime_type=='vnd.ms-powerpoint' ||
      files[currentidx].mime_type=='vnd.openxmlformats-officedocument.presentationml.presentation'" target="_blank"
        href="{{files[currentidx].url}}">
        <button mat-button>
          {{ "general.download" | translate }}
        </button>
      </a>
      <button mat-button mat-dialog-close>
        {{ "general.close" | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</ng-template>