import { Sys as TSys } from "../../../application/service/backface/types";
import { AppService } from "../../../application/service/app.service";
import { Editor } from "../../libs/editor";

const emptyEditor: TSys.IDatasetModule = {
    headers: XArray.create<TSys.IDatasetHeader, 'key'>('key'),
    rows: [],
    key: ''
}

const FieldProp = Prop.Slot<{
    [P: string]: {
        getters: (() => TSys.IDatasetModule)[],
        optionSource: IData<TSys.IDatasetModule | undefined>
    }
} & {
    [P: string]: {
        optionSourceRows: IData<object[] | undefined>
    }
} & {
    [P: string]: {
        cellField: IData
    }
} & {
    property?: FieldProperty.IProperty
}>('FieldProp');

const Value = Editor.Value;

interface IData<T = any> {
    readonly prop: FieldProperty.IProperty,
    data: T
}

interface IPathProp {
    rowsindex: number,
    dispindex: number,
    isoption: boolean,
    paths: string[],
    isarray: boolean,
}

function isClass(value: any): value is new () => any {
    return typeof value === 'function' && /^class\s/.test(Function.prototype.toString.call(value));
}

function getter(data: object | undefined, header: Editor.IField, pathprop: IPathProp, start: number, end?: number): any | undefined {
    if (data === null || data === undefined) return data;

    if (pathprop.paths.length <= start || (end ?? pathprop.paths.length) <= start) {
        return data as any;
    }

    const path = pathprop.paths[start];
    if (path === '[') {
        const [sep, _idx] = pathprop.paths[start + 1] === ']' ? ['', start + 2] : [pathprop.paths[start + 1], start + 3];
        return (_.isArray(data) ? data : []).map(di => getter(di, header, pathprop, _idx, end)).join(sep);
    }

    if (path === '>') {
        start = start + 1;
    }

    return getter((data as any)[path], header, pathprop, ++start, end);
}

function setter(data: object | undefined, header: Editor.IField, pathprop: IPathProp, val: any, start: number, end?: number) {
    if (data === null || data === undefined) return;

    const path = pathprop.paths[start];
    if (pathprop.paths.length <= (start + 1) || (end ?? pathprop.paths.length) <= (start + 1)) {
        const tgt = (data as any)[path];
        if (pathprop.isarray) {
            tgt.recreate.apply(tgt, val);
        } else {
            if ((header.type ?? 0) & Value.Type.multi) {
                tgt.recreate.apply(tgt, val);
            } else {
                (data as any)[path] = val;
            }
        }

        return;
    }

    return setter((data as any)[path], header, pathprop, val, ++start, end);
}

function isFields(headers?: Editor.IFields | Editor.IField[]): headers is Editor.IFields {
    return _.has(headers, 'indexed');
}

function toFields(headers?: Editor.IFields | Editor.IField[]): Editor.IFields | undefined {
    if (!headers || isFields(headers)) return headers;
    return new XArray('key', ...headers);
}

export class FieldProperty {
    constructor(public app: AppService) {
    }

    getProperty(headerId: Editor.IField | string | undefined, headers?: Editor.IFields | Editor.IField[]): FieldProperty.IProperty | undefined {
        headerId = (_.isString(headerId) ? { key: headerId } : headerId);

        const _headers = toFields(headers);
        const indexedHeaders = _headers?.indexed;
        const header = indexedHeaders ? (indexedHeaders[headerId?.key ?? '']) : headerId;
        const headerkey = header?.key;

        if (!header || !headerkey || Value.isFieldType(header, Value.Type.added)) {
            return;
        }

        const headerprop = FieldProp.Of(header, true);
        if (headerprop.property) {
            return headerprop.property;
        }

        const pathprop: IPathProp = {
            paths: headerkey.split(/(\[)(.*)(\])|(>)|(?:\.)/g).filter(s => s),
            isoption: Value.isFieldType(header, Value.Type.option),
            isarray: false,
            rowsindex: 1,
            dispindex: 1,
        }

        if (!pathprop.isoption) {
            pathprop.dispindex = pathprop.paths.length;
            pathprop.rowsindex = pathprop.paths.length;
        } else {
            const plen = pathprop.paths.length;
            const arridx = pathprop.paths.indexOf('[');
            const optidx = pathprop.paths.indexOf('>');
            const residx = arridx < 0 && optidx < 0 ? 1 : Math.max(Math.min(arridx >= 0 ? arridx : plen, optidx >= 0 ? optidx : plen), 1);

            switch (pathprop.paths[residx]) {
                case '[':
                    pathprop.dispindex = pathprop.paths.indexOf(']') + 1;
                    pathprop.rowsindex = residx;
                    pathprop.isarray = true;
                    break;

                case '>':
                    pathprop.dispindex = residx + 1;
                    pathprop.rowsindex = residx;
                    pathprop.isarray = false;
                    break;

                default:
                    pathprop.dispindex = 1;
                    pathprop.rowsindex = 1;
                    pathprop.isarray = false;
                    break;
            }
        }

        const { app } = this;
        return headerprop.property = {
            pathProp: pathprop,
            headers: _headers,
            header: header,
            cellText(rowdata: object): string | number {
                return getter(rowdata, header, pathprop, 0);
            },
            cellField(rowdata: object): IData<any> {
                if (!rowdata) return {
                    get prop() { return headerprop.property! },
                    data: null,
                }

                const rowprop = FieldProp.Of(rowdata, true);
                rowprop[headerkey] = rowprop[headerkey] || { cellField: null }

                return rowprop[headerkey].cellField = rowprop[headerkey].cellField || {
                    get data() { return getter(rowdata, header, pathprop, 0, pathprop.rowsindex) },
                    set data(val) { setter(rowdata, header, pathprop, val, 0, pathprop.rowsindex) },
                    get prop() { return headerprop.property }
                }
            },
            optionCellText(rowdata: object): string | number {
                return getter(rowdata, header, pathprop, pathprop.dispindex)
            },
            optionSourceRows(rowdata: object): IData<object[] | undefined> {
                if (!rowdata) return {
                    get prop() { return headerprop.property! },
                    data: [],
                }

                const rowprop = FieldProp.Of(rowdata, true);
                rowprop[headerkey] = rowprop[headerkey] || { optionSourceRows: null }
                return rowprop[headerkey].optionSourceRows = rowprop[headerkey].optionSourceRows || {
                    get data(): object[] {
                        const { data } = headerprop.property!.optionSource(rowdata);
                        const rows = ('rows' in Object(data) ? data!.rows : data);
                        return _.isArray(rows) ? rows.array : [];
                    },
                    get prop() { return headerprop.property }
                }
            },
            optionSource(rowdata: object): IData<TSys.IDatasetModule | undefined> {
                const rowprop = FieldProp.Of(rowdata, true);
                rowprop[headerkey] = rowprop[headerkey] || { optionSource: null, getters: null };

                rowprop[headerkey].getters = rowprop[headerkey].getters || (() => {
                    if (!header.source) return [() => emptyEditor];

                    if (Value.isFieldType(header, Value.Type.enum)) {
                        return [(): TSys.IDatasetModule => {
                            const source = _.isFunction(header.source) ? (isClass(header.source) ? [] : header.source(app, rowdata)) : header.source;

                            return {
                                rows: source as Editor.IEnum,
                                headers: emptyEditor.headers,
                                key: ''
                            }
                        }]
                    }

                    const source = _.isFunction(header.source) ? (isClass(header.source) ? [] : header.source(app, rowdata)) : header.source;
                    return _.transform<any[], (() => TSys.IDatasetModule)[]>(
                        _.isString(source) ? [source] : (_.isArray(source) ? source : []),

                        (res, s) => {
                            if (!_.isString(s)) return;
                            const d = (s[0] == '@' ? rowdata : app);
                            const p = (s[0] == '@' ? s.slice(1) : s).split('.');
                            const mtd = _.property<object, TSys.IDatasetModule>(p);
                            res.push(mtd.bind(undefined, d));
                        },

                        []
                    )
                })();

                return rowprop[headerkey].optionSource = rowprop[headerkey].optionSource || {
                    get data(): TSys.IDatasetModule | undefined {
                        const { getters } = rowprop[headerkey];

                        for (let idx = 0, len = getters.length; idx < len; idx++) {
                            const v = getters[idx]?.();
                            if (v) return v;
                        }

                        return;
                    },
                    get prop() { return headerprop.property }
                }
            }
        }
    }

    getCellText(rowdata: object | undefined, headerId: Editor.IField | string | undefined, headers?: Editor.IFields | Editor.IField[]): string | number | boolean | undefined {
        if (rowdata == null) return;

        return this.getProperty(headerId, headers)?.cellText(rowdata);
    }

    getCellField(rowdata: object | undefined, headerId: Editor.IField | string | undefined, headers?: Editor.IFields | Editor.IField[]): IData | undefined {
        if (rowdata == null) return;

        return this.getProperty(headerId, headers)?.cellField?.(rowdata);
    }

    getOptionCellText(optionrowdata: object | undefined, headerId: Editor.IField | string | undefined, headers?: Editor.IFields | Editor.IField[]): string | number | boolean | undefined {
        if (optionrowdata == null) return;

        return this.getProperty(headerId, headers)?.optionCellText(optionrowdata);
    }

    getOptionSourceRows(rowdata: object | undefined, headerId: Editor.IField | string | undefined, headers?: Editor.IFields | Editor.IField[]): IData<object[] | undefined> | undefined {
        if (rowdata == null) return;

        return this.getProperty(headerId, headers)?.optionSourceRows?.(rowdata);
    }

    getOptionSource(rowdata: object | undefined, headerId: Editor.IField | string | undefined, headers?: Editor.IFields | Editor.IField[]): IData<TSys.IDatasetModule | undefined> | undefined {
        if (rowdata == null) return;

        return this.getProperty(headerId, headers)?.optionSource?.(rowdata);
    }
}

export namespace FieldProperty {

    export interface IProperty {
        pathProp: IPathProp,

        header: Editor.IField,

        headers?: Editor.IFields,

        cellField?(rowdata: object): IData,

        cellText(rowdata: object): string | number | boolean,

        optionCellText(rowdata: object): string | number | boolean,

        optionSourceRows(rowdata: object): IData<object[] | undefined>,

        optionSource(rowdata: object): IData<TSys.IDatasetModule | undefined>,
    }

}