import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit, OnDestroy, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { Backface } from '../../application/service/backface/config';
import { AppService } from '../../application/service/app.service';
import { Report } from '../../application/service/backface/report';
import { Prj } from '../../application/service/backface/prj';
import { Editor } from '../../utils/libs/editor';

const Value = Editor.Value;

@Component({
    templateUrl: "./workitemlist.component.html",
    styleUrls: ['./workitemlist.component.scss'],
    providers: [PuTemplateService]
})
export class WorkItemListComponent extends Backface.AsyncReport implements OnInit, OnDestroy, AfterViewInit {
    private _props = Prop.Of(this);

    get currentmodule(): TSys.IDatasetModule {
        const { _props: props, app: { dict: { exec: { audit, audit: { headers } } } } } = this;

        return (props.currentmodule = (props.currentmodule || {
            key: 'workitemlist',
            headers: XArray.createIf<TSys.IDatasetHeader, 'key'>('key', headers?.filter(h => h.key != "records")),
            dict: audit,
            rows: [],
        }));
    }

    constructor(
        public app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string
            header: string,
            prjidlist: string[],
            stageidlist: string[],
            workitemstatus: string[]
        },

        protected override httpClient: HttpClient,
    ) {
        super(httpClient)
    }

    ngOnInit() {
        this.GetWorkItemStatus();
    }

    ngAfterViewInit(): void {
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }


    GetWorkItemStatus(): void {
        const param = {
            stage: <{ id: string }[]>[],
            project: <{ id: string }[]>[],
            workitemstatus: {
                type: <string[]>[]
            }
        };

        const { data: { prjidlist, stageidlist, workitemstatus } = {} } = this;

        prjidlist?.forEach(prjid => {
            param.project.push({ id: prjid });
        });

        stageidlist?.forEach(stageid => {
            param.stage.push({ id: stageid });
        });

        workitemstatus?.forEach(status => {
            param.workitemstatus.type.push(status);
        });

        this.report("/report/project_workitem_status", (res: Report.IProjectWorkitems) => {
            if (res == null) { return; }

            if (res) {
                const { app: { prj: { project: projects } } } = this;
                const { currentmodule } = this;
                currentmodule.rows?.reset();

                const rows = res.reduce((result, { id: projectid, workitem: _workitems, pool }) => {
                    const project = projects.firstOf({ id: projectid, pool: Value.toEnum(pool, TPrj.Pool) });
                    if (!project) {
                        console.assert(!!project, 'cannot find project for project_workitem_status')
                        return result;
                    }

                    const { workitems } = project;
                    for (const { stage, workset, id, status, enddate } of _workitems) {
                        const projectworkitem = workitems.firstOf({
                            stage: { id: stage }, workset: { id: workset }, id
                        });

                        if (!projectworkitem) {
                            console.assert(!!projectworkitem, 'cannot find project workitem for project_workitem_status');
                            continue;
                        }

                        projectworkitem.status = Value.toEnum(status, TPrj.IWorkitem.Status);
                        projectworkitem.enddate = Value.toDate(enddate);
                        result.push(projectworkitem);
                    }

                    return result;
                }, <Prj.Workitem<any>[]>[]);

                rows.forEach(ws => {
                    currentmodule.rows?.push(ws);
                });
            }
        }).query(
            param
        );
    }

    static open(dialog: PuDialogService, data: {
        title?: string;
        header: string;
        workitemstatus?: (TPrj.IWorkitem.Status | string | undefined)[],
        stageidlist?: (string | undefined)[],
        prjidlist?: (string | undefined)[],
    }, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: WorkItemListComponent,
            injector: injector,
            maximized: true,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '90vh',
            maxWidth: '100vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
        }, {
            ...data
        })
    }

}
