import { Component, OnInit } from '@angular/core';

import { PuDialogService } from '../../../utils/puzzle/pu.dialog/pu.dialog.service';
import { SystemsetComponent } from '../../../systemset/view/systemset.component';
import { AppService } from '../../service/app.service';

@Component({
    selector: 'app-sidebar, [app-sidebar]',
    templateUrl: "./sidebar.component.html",
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    constructor(
        public app: AppService,
        public dialog: PuDialogService
    ) {

    }

    ngOnInit() {
    }

    onSysInfo() {
        SystemsetComponent.open(this.dialog);
    }

}
