import { Observable, ReplaySubject, Subject } from 'rxjs';
import type { PartialDeep } from 'type-fest';
import { EventEmitter } from '@angular/core';
import { If } from 'ts-toolbelt/out/Any/If';
import { A } from 'ts-toolbelt';

type KeysToHook = _Array.KeysToHook;
type EventValue<T extends Subject<any>> = T extends Subject<infer V> ? V : never;
type TEvent<T extends Subject<any>> = Observable<EventValue<T>> & Pick<
    T, Extract<keyof T, 'next' | 'error' | 'emit'>
>;

type TEvents<T> = {
    [P in keyof _Array.TEvents<T>]?: TEvent<
        Exclude<_Array.TEvents<T>[P], undefined>
    >
}

type TIndexed<T extends TI, TI, Indexer extends TIndexer<T, TI>> = _XArray.TIndexed<T, TI, Indexer>;
type RIndexer<T extends TI, TI, Indexer extends TIndexer<T, TI>> = _XArray.RIndexer<T, TI, Indexer>;
type TIndexer<T extends TI, TI> = _XArray.TIndexer<T, TI>;
type Namedset<T> = XArray.Namedset<T>;
type Index = XArray.Index;

const ArraySlot = Prop.Slot('ARRAY');

namespace _Index {
    function iscondok(item: any, cond: Index['cond']): boolean {
        let isok = true;
        for (const key in cond) {
            const ival = item?.[key];
            const cval = cond[key];
            isok = false;
            if (ival == cval) {
                return true;
            }
        }

        return isok;
    }

    function KeyOf(val: any, key: string | number): string | number {
        const $cid = val?.$cid, keyval = val?.[key], keyvaltype = typeof keyval;
        if (keyvaltype == 'string' || keyvaltype == 'number') {
            return keyval;
        }

        return $cid;
    }

    function makeIndex<T>(value: T, indexer: Index, clone: boolean = false): T {
        const {
            merge = false,
            deep = false,
            key = 'id',
            cond = {}
        } = indexer;

        type Indexed = {
            idxed: Record<string, any>,
            ttl: number
        }

        type TTL = {
            ttl: number
        }

        const buildidx = (item: any, idxeds: Indexed[], ttls: TTL[]): any => {
            idxeds = idxeds.map(({ idxed, ttl }) => ({ idxed, ttl })).filter(idx => (idx.ttl -= 1) > 0);
            ttls = ttls.map(({ ttl }) => ({ ttl })).filter(ttl => (ttl.ttl -= 1) > 0);
            const maxttl = _.maxBy(ttls, ({ ttl }) => ttl)?.ttl ?? 0;

            if (_.isArray(item)) {
                const arr = (clone ? [] : item) as XArray<any, any, true>;
                const idxed = (arr.indexed || (arr.indexed = {}));

                for (let curitem of item) {
                    if (!_.isObject(curitem)) continue;

                    const curkey = KeyOf(curitem, key);
                    if (!_.isArray(curitem) && (!curkey || !iscondok(curitem, cond))) {
                        clone && arr.push(curitem);
                        continue;
                    }

                    // curitem is an array, or indexable valid object
                    if (deep) {
                        if (maxttl > 0) {
                            curitem = buildidx(curitem, [...idxeds, {
                                idxed, ttl: maxttl
                            }], ttls);
                        } else {
                            curitem = buildidx(curitem, idxeds, ttls);
                        }
                    }

                    clone && arr.push(curitem);
                    curkey && [...idxeds, { idxed, ttl: 0 }].forEach(
                        ({ idxed }) => idxed[curkey] = curitem
                    )
                }

                return arr;
            }

            if (_.isObject(item)) {
                return _.reduce<
                    Record<string, any>, Record<string, any>
                >(item, (res, v, k) => {
                    if (_.isArray(v) || _.isObject(v)) {
                        if (_.isObject(merge) && merge[k] > 0) {
                            ttls.push({ ttl: merge[k] + 1 })
                        }

                        v = buildidx(v, idxeds, ttls);
                    }

                    return clone && ((res as any)[k] = v), res;
                }, clone ? {} : item);
            }

            return item;
        }

        return buildidx(value, [], (merge === true ? [{ ttl: 10000 }] : []))
    }

    export type NamedsetXArray<T> = XArray<Namedset<T>, Namedset<T>, Namedset<T>>;

    export function makeByIndex<T>(index: Index): {
        (item: T, indexed: Namedset<T>, add: boolean, array: XArray<T, T, Namedset<T>>): void
    } {
        return (item: T, indexed: Namedset<T>, add: boolean, array: XArray<T, T, Namedset<T>>): void => {
            const idxkey = index.key;

            if (add) {
                item = makeIndex(item, index);
                if (idxkey == null) return;

                const idxval = KeyOf(item, idxkey);
                if (idxval == null) return;
                indexed[idxval] = item;
                return;
            }

            if (idxkey == null) return;
            const idxval = KeyOf(item, idxkey);
            if (idxval == null) return;

            const $cid = _.isObject(item) ? item.$cid : '';
            delete indexed[idxval];
            delete indexed[$cid];
        }
    }

    export function makeNamedsetByIndex<T>(index: Index, clone: boolean): {
        (item: Namedset<T>, indexed: Namedset<T>, add: boolean, array: NamedsetXArray<T>): void,
        namedset: boolean
    } {
        return _.extend((item: Namedset<T>, indexed: Namedset<T>, add: boolean, array: NamedsetXArray<T>): void => {
            if (add) {
                _.forEach(item, (rcd, key) => {
                    item[key] = makeIndex(rcd, index, clone);
                    indexed[key] = item[key];
                });

                return;
            }

            // delete all keys in item from indexed
            const keys = Object.keys(item);
            for (const key of keys) {
                delete indexed[key];
            }
        }, { namedset: true });
    }

    export function buildIndex<T>(force: true, array: T[], added?: T[], removed?: T[]): TIndexed<T, T, TIndexer<T, T>>;
    export function buildIndex<T>(force: boolean, array: T[], added?: T[], removed?: T[]): TIndexed<T, T, TIndexer<T, T>> | undefined;
    export function buildIndex<T>(force: boolean, array: T[], added?: T[], removed?: T[]): TIndexed<T, T, TIndexer<T, T>> | undefined {
        const slot = ArraySlot.Of<_Array.ISlot<T>>(array, force);

        if (!force && !slot?.indexed) {
            // not indexed required, and not force to create
            return;
        }

        // the indexed has been created, or need to force create 
        if (!slot) throw new Error('XArray index state error');

        const { indexer = false } = slot;
        if (indexer === false) {
            // no indexed required
            return false;
        }

        if (indexer === true) {
            return slot.indexed || (
                slot.indexed = {}
            )
        }

        type Indexed = any;
        const build = (item: T, indexed: Indexed, add: boolean): Indexed => {
            if (item == null) {
                return indexed;
            }

            if (typeof indexer == 'function') {
                const xarray = array as XArray<T, T, TIndexer<T, T>>;
                (indexer as any)(item, indexed, add, xarray);
                return indexed;
            }

            const curkey = KeyOf(item, indexer);
            if (curkey == null) return indexed;

            if (add) {
                (indexed as any)[curkey] = item;
            } else {
                const $cid = item.$cid;
                delete (indexed as any)[$cid];
                delete (indexed as any)[curkey];
            }

            return indexed;
        }

        const indexed = (slot.indexed || (slot.indexed = (
            array.reduce<Indexed>((res, item) => {
                return build(item, res, true);
            }, {})
        )));

        // NOTE: must remove at first for replace case.
        removed?.forEach(item => {
            build(item, indexed, false);
        })

        added?.forEach(item => {
            build(item, indexed, true);
        })

        return indexed;
    }
}

namespace _Array {
    export const Empty: any[] = [];

    /**
     * Array modification monitoring
     */
    export type KeysToHook = 'reverse' | 'sort' | 'splice' | 'shift' | 'unshift' | 'push' | 'pop';
    export const KeysToHook: KeysToHook[] = ['reverse', 'sort', 'splice', 'shift', 'unshift', 'push', 'pop'];

    export type HookedPropertyDescriptors<T> = {
        [P in KeysToHook]: PropertyDescriptor;
    }

    export type HookedPropertyFunctions<T> = {
        [P in KeysToHook]: Array<T>[P];
    }

    type IIndex<T, Indexer extends TIndexer<T, T>> = {
        indexed?: TIndexed<T, T, Indexer>,
        indexer?: RIndexer<T, T, Indexer>,
        namedset?: boolean
    }

    export type ISlot<T> = IIndex<T, TIndexer<T, T>> & {
        original?: HookedPropertyFunctions<T>,
        removed?: T[],
        added?: T[],

        events?: {
            changed?: ReplaySubject<T[]>,

            preAddRemove?: EventEmitter<T[]>,
            addRemoved?: EventEmitter<{
                removed?: T[],
                added?: T[],
            }>,

            preCreateDestroy?: EventEmitter<T[]>,
            createDestroied?: EventEmitter<{
                destroied?: T[],
                created?: T[]
            }>
        }
    }

    export function newEventEmitter(): EventEmitter<any> {
        return new EventEmitter();
    }

    export function newReplaySubject(): ReplaySubject<any> {
        return new ReplaySubject(1);
    }

    type REvents<T> = Required<TEvents<T>>;
    export type TEvents<T> = Exclude<ISlot<T>['events'], undefined>;
    export function _createEvent<T, Key extends keyof TEvents<T>>(array: Array<T>, key: Key, creator: () => REvents<T>[Key]): REvents<T>[Key] {
        const slot = ArraySlot.Of<ISlot<T>>(array, true)!;
        const events = (slot.events || (slot.events = {})) as REvents<T>;
        return (events[key] || (events[key] = creator()));
    }

    export function _construct<T>(array: T[], vals: any[]): T[] {
        if (!array.construct) return vals;

        if (!array.isT) {
            return vals.map(v => (
                array.construct!(v) as T
            ));
        }

        return vals.reduce<T[]>((_vals, _val): T[] => {
            if (array.isT!(_val)) {
                return _vals.push(_val), _vals;
            }

            const _cval = array.construct!(_val);
            if (array.isT!(_cval)) {
                return _vals.push(_cval), _vals;
            }

            return _vals.push(..._construct(array, _cval)), _vals;
        }, []);
    }

    export function _destruct<T>(array: T[], vals: any[]) {
        if (!array.destruct) return vals;
        return vals.map(v => array.destruct!(v));
    }

    export function _validateArray<T>(array?: T[]): (T[]) | undefined {
        return (array?.length ?? 0) > 0 ? array : undefined;
    }

    const _emptyCache = {}
    export function _eventsOf<T>(array: T[]): TEvents<T> {
        return ArraySlot.Of<ISlot<T>>(array, false)?.events ?? _emptyCache;;
    }

    export function _slotOf<T>(array: T[], forceown: true): ISlot<T>;
    export function _slotOf<T>(array: T[], forceown?: boolean): ISlot<T>;
    export function _slotOf<T>(array: T[], forceown: boolean = false): ISlot<T> {
        return ArraySlot.Of<ISlot<T>>(array, forceown) ?? _emptyCache;
    }
}

namespace _XArray {
    type NamedsetXArray<T> = _Index.NamedsetXArray<T>;

    function uniqWhere<
        T extends TI, TI, Indexer extends TIndexer<T, TI>
    >(arr: XArray<T, TI, Indexer>, val: (T | TI | undefined)[]): T[] {
        return _.uniq((val || []).reduce<T[]>((r, v) => {
            if (v == null) return r;
            if (arr.isT?.(v)) return r.push(v), r;
            return r.push(...arr.where(v as any)), r;
        }, []));
    }

    function excludeNullUndefined<
        T extends TI, TI
    >(val?: (T | TI | undefined)[]): (T | TI)[] {
        return val?.filter(v => v != undefined) ?? _Array.Empty;
    }

    export type TIndexer<T extends TI, TI> = (
        (<Indexed extends object>(item: T, indexed: Indexed, add: boolean, arr: XArray<T, TI, TIndexer<T, TI>>) => void) |
        (<Indexed extends object>(item: T, indexed: Indexed, add: boolean) => void) |
        If<A.Equals<Array.IndexField<T>, never>, false, Array.IndexField<T>> |
        object | false | true
    );

    export type RIndexer<T extends TI, TI, Indexer extends TIndexer<T, TI>> = (
        Indexer extends false | true | string | number ? Indexer : ((Indexer extends ((...args: any[]) => any) ? Indexer : (
            ((item: T, indexed: Indexer, add: boolean, arr: XArray<T, TI, Indexer>) => void) |
            ((item: T, indexed: Indexer, add: boolean) => void)
        )) & { namedset?: boolean })
    )

    export type TIndexed<T extends TI, TI, Indexer extends TIndexer<T, TI>> = (
        Indexer extends ((item: T, indexed: infer Indexed, ...args: any[]) => void) ? Indexed : (
            If<
                A.Equals<Indexer, false>, false,
                If<
                    A.Equals<Indexer, true>, Namedset<any>,
                    Indexer extends string | number ? {
                        [Key in Extract<
                            T[Extract<keyof T, Indexer>],
                            string | number
                        >]: T
                    } : Indexer
                >
            >
        )
    )

    export class XArray<
        T extends TI, TI = T, Indexer extends TIndexer<T, TI> = false
    > extends Array<T> implements Array<T> {
        static override get [Symbol.species]() {
            return Array;
        }

        constructor(indexer: RIndexer<T, TI, Indexer>, ...items: (T | TI | undefined)[]) {
            super(), Object.setPrototypeOf(
                this, new.target.prototype
            )

            const slot = _Array._slotOf<T>(this, true);
            slot.indexer = indexer as RIndexer<T, T, TIndexer<T, T>>;
            slot.namedset = _.isObject(indexer) ? indexer.namedset : false;
            this.reset(...items);
        }

        get indexed(): TIndexed<T, TI, Indexer> {
            return _Index.buildIndex<T>(true, this) as TIndexed<T, TI, Indexer>;
        }

        set indexed(val: TIndexed<T, TI, Indexer>) {
            const slot = _Array._slotOf<T>(this, true);
            slot.indexed = val as TIndexed<T, T, TIndexer<T, T>>;
        }

        get indexer(): RIndexer<T, TI, Indexer> {
            const indexer = _Array._slotOf<T>(this, false)?.indexer;
            if (indexer != undefined) return indexer as RIndexer<T, TI, Indexer>;
            return false as RIndexer<T, TI, Indexer>;
        }

        clone(): XArray<T, TI, Indexer> {
            const slotself = _Array._slotOf<T>(this, true);
            const arraynew = new XArray(
                slotself.indexer as RIndexer<T, TI, Indexer>,
                ...this.map(v => _.clone(v))
            );

            if (slotself.indexed) {
                const slotnew = _Array._slotOf<T>(arraynew, true);
                if (!slotnew.indexed) {
                    slotnew.indexed = _.clone(
                        slotnew.indexed
                    )
                }
            }

            return arraynew;
        }

        override construct?(val: T | TI): T | (T | TI)[];
        override isT?(value: T | TI): value is T;
        override destruct?(val: T): T;

        override remove(...val: (T | TI | undefined)[]): T[] {
            return super.remove(...uniqWhere(this, val));
        }
        override add(val: (T | TI | undefined)[], at?: number): T[] {
            return super.add(excludeNullUndefined(val) as T[], at);
        }
        override reset(...val: (T | TI | undefined)[]): T[] {
            return super.reset(...(excludeNullUndefined(val) as T[]));
        }

        override destroy(...val: (T | TI | undefined)[]): T[] {
            return super.destroy(...uniqWhere(this, val));
        }
        override create(val: (T | TI | undefined)[], at?: number): T[] {
            return super.create(excludeNullUndefined(val) as T[], at);
        }
        override createnew(val: T | TI, at?: number): T {
            return super.createnew(val as T, at);
        }
        override recreate(...val: (T | TI | undefined)[]): T[] {
            return super.recreate(...(excludeNullUndefined(val) as T[]));
        }

        override splice(start: number, deleteCount?: number): T[];
        override splice(start: number, deleteCount: number, ...items: (T | TI | undefined)[]): T[];
        override splice(start: number, deleteCount: number, ...items: (T | TI | undefined)[]): T[] {
            items = excludeNullUndefined(items);
            if (items.length <= 0) return super.splice(start, deleteCount);
            return super.splice(start, deleteCount, ...(items as T[]));
        }

        override unshift(...items: (T | TI | undefined)[]): number {
            return super.unshift(...(excludeNullUndefined(items) as T[]));
        }

        override push(...items: (T | TI | undefined)[]): number {
            return super.push(...(excludeNullUndefined(items) as T[]));
        }

        static creator<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>): {
            (...items: (T | undefined)[]): XArray<T, T, Indexer>
        };
        static creator<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>): {
            (...items: (T | TI | undefined)[]): XArray<T, TI, Indexer>
        };
        static creator<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>): {
            (...items: (T | TI | undefined)[]): XArray<T, TI, Indexer>
        } {
            return (...items: (T | TI | undefined)[]): XArray<T, TI, Indexer> => {
                return new XArray<T, TI, Indexer>(indexer, ...items);
            }
        }

        static create<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>, ...items: (T | undefined)[]): XArray<T, T, Indexer>;
        static create<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, ...items: (T | TI | undefined)[]): XArray<T, TI, Indexer>;
        static create<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, ...items: (T | TI | undefined)[]): XArray<T, TI, Indexer> {
            return new XArray<T, TI, Indexer>(indexer, ...items);
        }

        static creatorIf<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>): {
            (items?: (T | undefined)[]): XArray<T, T, Indexer> | undefined;
            (items: (T)[]): XArray<T, T, Indexer>;
        }
        static creatorIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>): {
            (items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined;
            (items: (T | TI)[]): XArray<T, TI, Indexer>
        }
        static creatorIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>): {
            (items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined;
            (items: (T | TI)[]): XArray<T, TI, Indexer>
        } {
            function c(items: (T | TI)[]): XArray<T, TI, Indexer>;
            function c(items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined;
            function c(items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined {
                items = excludeNullUndefined(items);
                if (items.length <= 0) return;

                return new XArray<T, TI, Indexer>(indexer, ...items);
            }

            return c;
        }

        static createIf<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>, items: T[]): XArray<T, T, Indexer>;
        static createIf<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>, items?: (T | undefined)[]): XArray<T, T, Indexer> | undefined;
        static createIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, items: (T | TI)[]): XArray<T, TI, Indexer>;
        static createIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined;
        static createIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined {
            items = excludeNullUndefined(items);
            if (items.length <= 0) return;

            return new XArray(indexer, ...items);
        }

        static createByIndex<T>(
            index: Index, config: [namedset: false, clone?: boolean],
            ...items: (T | undefined)[]
        ): XArray<T, T, Namedset<T>>;
        static createByIndex<T>(
            index: Index, config: [namedset: true, clone?: boolean],
            ...items: (Namedset<T> | undefined)[]
        ): NamedsetXArray<T>;
        static createByIndex<T>(
            index: Index, [namedset, clone]: [namedset: boolean, clone?: boolean], ...items: any[]
        ): XArray<T, T, Namedset<T>> | NamedsetXArray<T> {
            if (namedset) {
                return XArray.create(_Index.makeNamedsetByIndex<T>(index, !!clone), ...items);
            }

            return XArray.create(_Index.makeByIndex<T>(index), ...items);
        }

        static creatorByIndex<T>(index: Index, config: [namedset: false, clone?: boolean]): {
            (...items: (T | undefined)[]): XArray<T, T, Namedset<T>>
        };
        static creatorByIndex<T>(index: Index, config: [namedset: true, clone?: boolean]): {
            (...items: (Namedset<T> | undefined)[]): NamedsetXArray<T>
        };
        static creatorByIndex<T>(index: Index, [namedset, clone]: [namedset: boolean, clone?: boolean]): {
            (...items: (Namedset<T> | undefined)[]): NamedsetXArray<T>
        } | {
            (...items: (T | undefined)[]): XArray<T, T, Namedset<T>>
        } {
            if (namedset) {
                return XArray.creator(_Index.makeNamedsetByIndex<T>(index, !!clone));
            }

            return XArray.creator(_Index.makeByIndex<T>(index));
        }
    }
}

const PolyfillSlot = Prop.Slot<{ array?: boolean, xarray?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);

    function fromIndexed<T>(array: Array<T>, attrs?: PartialDeep<T> | ((item: T) => boolean)): { val: T | undefined } | undefined {
        const slot = _Array._slotOf(array, false);
        const indexer = slot?.indexer;

        if (!attrs || !indexer || _.isFunction(indexer) || _.isFunction(attrs)) {
            return;
        }

        const key = (attrs as any)[indexer as any];
        if (key === undefined) return;

        let val = (array as any).indexed?.[key];
        if (!array.match.call(val, attrs)) {
            val = undefined;
        }

        return { val }
    }

    function polyfill_array(Array: ArrayConstructor) {
        if (!Array || slot.array) return;
        slot.array = true;

        const hookedPropertyDescs: _Array.HookedPropertyDescriptors<any> = {} as any;
        const hookedPropertyFuncs: _Array.HookedPropertyFunctions<any> = {} as any;
        const { buildIndex } = _Index;

        function collUnused<T>(array: Array<T>): T[] | undefined {
            const slot = _Array._slotOf(array, false), { namedset, indexed } = slot;
            if (!namedset) return;

            if (!_.isObject(indexed)) {
                throw new Error('Invalid index state, indexed must be valid');
            }

            const unused: T[] = [];
            const splice = hookedPropertyFuncs.splice;
            for (let idx = 0; idx < array.length;) {
                const item = array[idx];
                let used = false;

                for (const ikey in indexed) {
                    if (_.get(item, ikey) == _.get(indexed, ikey)) {
                        used = true;
                        break;
                    }
                }

                if (used) {
                    idx++;
                    continue;
                }

                unused.push(array.destruct?.(item) ?? item);
                splice.call(array, idx, 1);
            }

            return unused;
        }

        function addremove<T>(array: Array<T>, remove: T[], add?: T[]): T[] | undefined {
            const { preAddRemove, addRemoved, changed } = _Array._eventsOf<T>(array);
            const { namedset } = _Array._slotOf(array, false) ?? {};
            const { _onPreAddRemove, _onAddRemoved } = array;

            _onPreAddRemove?.call(array, array);
            preAddRemove?.emit(array);

            // collect and remove the values
            const splice = hookedPropertyFuncs.splice;
            let { removed, index } = _.transform<
                T, { removed: T[] | undefined, index: number }
            >(remove ?? [], (res, _val) => {
                let removed = false;

                for (
                    let index = array.indexOf(_val); index >= 0;
                    index = array.indexOf(_val)
                ) {
                    !removed && (
                        (res.index < 0) && (res.index = index),
                        res.removed!.push(_val),
                        removed = true
                    );

                    splice.call(array, index, 1);
                }

                if (!removed && namedset) res.removed!.push(_val);
            }, { removed: [], index: -1 });

            removed = _Array._validateArray(removed);
            removed && _Array._destruct(array, removed);

            // create the items and add
            const added = add && _Array._construct(array, add);
            index = index >= 0 ? index : array.length;

            added && splice.call(array, Math.max(index, array.length), 0, ...added);
            buildIndex(!!namedset, array, added, removed);
            removed = _Array._validateArray(
                collUnused(array) ?? removed
            )

            // final notify
            changed?.next(array);
            _onAddRemoved?.call(array, removed, added);
            addRemoved?.emit({ removed, added });
            return removed;
        }

        _Array.KeysToHook.forEach(key => {
            const desc = (hookedPropertyDescs[key] = Object.getOwnPropertyDescriptor(Array.prototype, key)!);
            const orgfunc = (hookedPropertyFuncs[key] = hookedPropertyDescs[key].value) as Function;

            Object.defineProperty(Array.prototype, key, {
                ...(desc), value<T>(this: Array<T>) {
                    const { preAddRemove, addRemoved, preCreateDestroy, createDestroied, changed } = _Array._eventsOf<T>(this);
                    const { _onPreAddRemove, _onAddRemoved, _onPreCreateDestroy, _onCreateDestroied } = this;
                    const { indexer, namedset } = _Array._slotOf(this, false) ?? {};
                    const { construct, destruct } = this;

                    if (!(
                        (_onPreCreateDestroy || _onCreateDestroied || _onPreAddRemove || _onAddRemoved) ||
                        (preAddRemove || addRemoved || preCreateDestroy || createDestroied) ||
                        (indexer || construct || destruct)
                    )) {
                        const rets = orgfunc?.apply(this, arguments);
                        changed?.next(this);
                        return rets;
                    }

                    const slot = _Array._slotOf(this, true);
                    delete slot.removed;
                    delete slot.added;

                    let args = _.toArray(arguments);
                    let removed: T[] | undefined;
                    let added: T[] | undefined;

                    switch (key) {
                        case 'push':
                            added = args = _Array._construct(this, args);
                            break;

                        case 'pop':
                            removed = _Array._destruct(this, this.length > 0 ? [this[this.length - 1]] : []);
                            break;

                        case 'shift':
                            removed = _Array._destruct(this, this.length > 0 ? [this[0]] : []);
                            break;

                        case 'unshift':
                            added = args = _Array._construct(this, args);
                            break;

                        case 'splice':
                            const [start, count, ...rest] = args;
                            const end = _.isNumber(count) ? start + count : count;
                            removed = _Array._destruct(this, this.slice(start, end));
                            added = _Array._construct(this, rest);

                            args = [start, count, ...added];
                            break;

                        default: // reverse/sort cause the order changed
                            return orgfunc.apply(this, args);
                    }

                    slot.added = added = _Array._validateArray(added);
                    slot.removed = removed = _Array._validateArray(removed);
                    const result = orgfunc.apply(this, args);

                    if (added || removed) {
                        _onPreAddRemove?.call(this, this);
                        preAddRemove?.emit(this);
                    }

                    buildIndex(!!namedset, this, added, removed);
                    slot.removed = removed = _Array._validateArray(
                        collUnused(this) ?? removed
                    )

                    changed?.next(this);
                    if (added || removed) {
                        _onAddRemoved?.call(this, removed, added);
                        addRemoved?.emit({ removed, added });
                    }

                    return result;
                }
            });
        })

        Object.defineProperties(Array.prototype, {
            events: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): _Array.TEvents<T> {
                    return _Array._eventsOf(this);
                }
            },
            changed: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): ReplaySubject<T[]> {
                    const event = _Array._createEvent(this, 'changed', _Array.newReplaySubject);
                    event.next(this);
                    return event;
                }
            },
            array: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): T[] {
                    return this;
                }
            },
            onAddRemoved: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): Observable<{
                    removed?: T[],
                    added?: T[],
                }> {
                    return _Array._createEvent(this, 'addRemoved', _Array.newEventEmitter);
                }
            },
            onPreAddRemove: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): Observable<T[]> {
                    return _Array._createEvent(this, 'preAddRemove', _Array.newEventEmitter);
                }
            },
            onCreateDestroied: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): Observable<{ created?: T[], destroied?: T[] }> {
                    return _Array._createEvent(this, 'createDestroied', _Array.newEventEmitter);
                }
            },
            onPreCreateDestroy: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): Observable<T[]> {
                    return _Array._createEvent(this, 'preCreateDestroy', _Array.newEventEmitter);
                }
            },
            first: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): T | undefined {
                    return this[0];
                }
            },
            last: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): T | undefined {
                    return this[this.length - 1];
                }
            },
            isEmpty: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): boolean {
                    return this.length <= 0;
                }
            },
            original: {
                enumerable: false, configurable: false,
                get<T>(this: Array<T>): _Array.HookedPropertyFunctions<T> {
                    const slot = _Array._slotOf(this, true);

                    if (!slot.original) {
                        const _this = this;
                        slot.original = {} as any;

                        _.each(hookedPropertyDescs, (desc, key) => {
                            Object.defineProperty(slot.original, key, {
                                ...desc, value<T>(this: Array<T>) {
                                    return desc?.value.apply(_this, arguments);
                                }
                            })
                        })
                    }

                    return slot.original!;
                }
            },
            toJSON: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>): any[] {
                    return this.map((v) => JSON.JsonOf(v));
                }
            },
            pluck: {
                writable: true, enumerable: false, configurable: false,
                value<
                    T, K extends (T extends object ? keyof T : never)
                >(this: Array<T>, key: K): (T[K])[] {
                    return this.map((val) => val[key]).filter(
                        v => v != undefined
                    );
                }
            },
            firstOf: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, attrs?: PartialDeep<T> | ((item: T) => boolean)): T | undefined {
                    // no filter condition, return the first one.
                    if (!attrs) return this[0];

                    const byidx = fromIndexed<T>(this, attrs);
                    if (byidx) {
                        if (byidx.val) return byidx.val;

                        // maybe as a tree, find downward
                        for (const item of this) {
                            const firstOf = (item as any)?.['firstOf'];
                            if (_.isFunction(firstOf)) {
                                const sub = firstOf.call(item, attrs);
                                if (sub) return sub;
                            }
                        }

                        return;
                    }

                    for (const item of this) {
                        if (this.match.call(item, attrs)) {
                            return item;
                        }

                        const firstOf = (item as any)?.['firstOf'];
                        if (_.isFunction(firstOf)) {
                            const sub = firstOf.call(item, attrs);
                            if (sub) return sub;
                        }
                    }
                }
            },
            has: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, item: T): boolean {
                    return this.indexOf(item) >= 0;
                }
            },
            objectify: {
                writable: true, enumerable: false, configurable: false,
                value<T, K extends Array.IndexField<T>>(
                    this: Array<T>, key: K
                ): Namedset<T> {
                    const res: Namedset<T> = {} as any;

                    for (const obj of this) {
                        const rkey = (obj as any)[key];
                        (rkey != undefined) && (res[rkey] = obj);
                    }

                    return res;
                }
            },
            where: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, attrs?: PartialDeep<T> | ((item: T) => boolean)): T[] {
                    // no filter condition, return all.
                    if (!attrs) return this;

                    const byidx = fromIndexed<T>(this, attrs);
                    if (byidx) return byidx.val ? [byidx.val] : [];

                    let res: T[] = [];
                    for (const obj of this) {
                        if (this.match.call(obj, attrs)) {
                            res.push(obj);
                        }
                    }

                    return res;
                }
            },

            // ok
            add: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, val: T[], at?: number): T[] {
                    if (val.length <= 0) return [];
                    this.splice(at ?? this.length, 0, ...val);
                    return _Array._slotOf(this)?.added ?? val;
                }
            },

            remove: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, ...val: T[]): T[] {
                    return addremove<T>(this, val) ?? [];
                }
            },

            replace: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, remove: T, add?: T): T | undefined {
                    if (add == null) add = remove;
                    const removed = addremove<T>(this, [remove], [add]);
                    return removed?.[0];
                }
            },

            // ok
            reset: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, ...val: T[]): T[] {
                    return this.splice(0, this.length, ...val);
                }
            },

            // ok
            createnew: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, val: T, at?: number): T {
                    return this.create([val], at)[0];
                }
            },

            // ok
            create: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, val: T[], at?: number): T[] {
                    if (val.length <= 0) return [];

                    const { preCreateDestroy, createDestroied } = _Array._eventsOf<T>(this);

                    this._onPreCreateDestroy?.(this);
                    preCreateDestroy?.emit(this);

                    const added = this.add(val, at);

                    this._onCreateDestroied?.(added);
                    createDestroied?.emit({ created: added });

                    return added;
                }
            },

            // ok
            destroy: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, ...val: T[]): T[] {
                    if (val.length <= 0) return [];

                    const { preCreateDestroy, createDestroied } = _Array._eventsOf<T>(this);

                    this._onPreCreateDestroy?.(this);
                    preCreateDestroy?.emit(this);

                    const removed = this.remove.apply(this, val);

                    this._onCreateDestroied?.(undefined, removed);
                    createDestroied?.emit({ destroied: removed });

                    return removed;
                }
            },

            // ok
            recreate: {
                writable: true, enumerable: false, configurable: false,
                value<T>(this: Array<T>, ...val: T[]): T[] {
                    if (this.length <= 0 && val.length <= 0) return [];

                    const { preCreateDestroy, createDestroied } = _Array._eventsOf<T>(this);

                    this._onPreCreateDestroy?.(this);
                    preCreateDestroy?.emit(this);

                    const removed = this.reset.apply(this, val ?? []);
                    const added = _Array._validateArray(this);

                    this._onCreateDestroied?.(added, removed);
                    createDestroied?.emit({ created: added, destroied: removed });

                    return removed;
                }
            }
        })

        Object.defineProperties(Array, {
            polyfill: {
                configurable: false, writable: false,
                value(wnd: Window) {
                    _polyfill(wnd);
                }
            }
        })
    }

    function polyfill_xarray() {
        if (slot.xarray) return;
        slot.xarray = true;

        const classXArray: typeof XArray<any, any, any> = _XArray.XArray;
        (wnd as any).XArray = classXArray;
    }

    polyfill_array((wnd as any)?.['Array']);
    polyfill_xarray();
}

_polyfill(window);

export { };
declare global {
    interface Array<T> {
        readonly changed: Observable<T[]>;

        readonly array: T[];

        readonly original: {
            [P in KeysToHook]: Array<T>[P];
        };

        readonly last: T | undefined;

        readonly first: T | undefined;

        readonly isEmpty: boolean;

        readonly onPreAddRemove: Observable<T[]>;

        readonly onAddRemoved: Observable<{
            removed: T[],
            added: T[],
        }>;

        readonly onPreCreateDestroy: Observable<T[]>;

        readonly onCreateDestroied: Observable<{
            destroied?: T[],
            created?: T[]
        }>

        readonly events: TEvents<T>;

        toJSON(): JSON.Type[];

        /**
         * extract the members value array by the member key
         * 
         * @param key : key of member from each item to extract
         */
        pluck<
            K extends (T extends object ? keyof T : never)
        >(key: K): (T[K])[];

        /**
         * build the key/value object for each item
         * @param key : key of member from each item to build the Key/Value
         */
        objectify<
            K extends Array.IndexField<T>
        >(key: K): Namedset<T>;

        firstOf(attrs?: {} | ((item: T) => boolean)): T | undefined;
        where(attrs?: {} | ((item: T) => boolean)): T[];
        has(item: T): boolean;

        /**
         * callback to determine the value is type of T
         * @returns the value is T or not.
         */
        isT?(value?: any): value is T;

        /**
         * callback while construct item from val
         * @returns An array containing to the elements that were constructed or to be constructed.
         */
        construct?(val: any): T | (T | any)[];

        /**
         * callback while item to be removed from this array
         * @returns the element that were removed.
         */
        destruct?(val: T): T;

        /**
         * remove and add new one, same replace and add just trigger rebuild index for the item
         * the added item will kept in place of removed
         * @param remove item to remove
         * @param add item to add
         * @returns the item removed
         */
        replace(remove: T, add: T): T | undefined;
        replace(remove4add: T): T | undefined;

        /**
         * add new item at the end of array if no at position specified
         * @note will trigger add/remove notification
         * @returns An array containing the elements that were added.
         */
        add(val: T[], at?: number): T[];

        /**
         * destroy spcified elements if in this array.
         * @note will trigger add/remove notification
         * @returns An array containing the elements that were deleted.
         */
        remove(...val: (T | undefined)[]): T[];

        /**
         * delete all exists, and add news
         * @note will trigger add/remove notification
         * @returns An array containing the elements that were deleted.
         */
        reset(...val: T[]): T[];

        /**
         * callback for driven to notify the add/remove 
         */
        _onAddRemoved?(removed?: T[], added?: T[]): void;
        _onPreAddRemove?(current: T[]): void;

        /**
         * special method same as create
         * @note will trigger create/destroy and add/remove notification
         * @returns the newly created.
         */
        createnew(val: T, at?: number): T;

        /**
         * create and add a new item 
         * @note will trigger create/destroy and add/remove notification
         * @returns An array containing the elements that were created.
         */
        create(val: T[], at?: number): T[];

        /**
         * destroy spcified elements if in this array.
         * @note will trigger create/destroy and add/remove notification
         * @returns An array containing the elements that were deleted.
         */
        destroy(...val: (T | undefined)[]): T[];

        /**
         * delete all exists, and create and add news
         * @note will trigger create/destroy and add/remove notification
         * @returns An array containing the elements that were deleted.
         */
        recreate(...val: T[]): T[];

        /**
         * callback for driven to notify the create/destroy 
         */
        _onCreateDestroied?(created?: T[], destroied?: T[]): void;
        _onPreCreateDestroy?(current: T[]): void;
    }

    interface ArrayConstructor {
        polyfill(wnd: Window): void;
    }

    namespace Array {
        type Keyable<T> = If<
            A.Equals<T, any>, 0,
            If<
                A.Equals<T, never>, 0,
                If<
                    A.Equals<T, unknown>, 0,
                    T extends string | number ? 1 : 0
                >
            >
        >;

        type IndexKeys<T extends object> = T extends unknown ? {
            [K in keyof T]-?: {
                0: never,
                1: K,
            }[Keyable<
                Exclude<
                    T[K],
                    undefined
                >
            >]
        }[keyof T] : never;

        export type IndexField<T> = T extends object ? Extract<keyof T, IndexKeys<T>> : never;
        export type ItemType<T> = T extends any ? (T extends ReadonlyArray<infer I> ? I : never) : never;
    }

    class XArray<
        T extends TI, TI = T, Indexer extends TIndexer<T, TI> = false
    > extends Array<T> implements Array<T> {
        static readonly [Symbol.species]: typeof Array;

        constructor(indexer: RIndexer<T, TI, Indexer>, ...items: (T | TI | undefined)[]);

        readonly indexer: RIndexer<T, TI, Indexer>;
        indexed: TIndexed<T, TI, Indexer>;

        /**
         * create new XArray, shadow copy each item and indexed,
         * the new XArray will inherit the indexer
         */
        clone(): XArray<T, TI, Indexer>;

        override construct?(val: T | TI): T | (T | TI)[];
        override isT?(value: any): value is T;
        override destruct?(val: T): T;

        override remove(...val: (T | TI | undefined)[]): T[];
        override add(val: (T | TI)[], at?: number): T[];
        override reset(...val: (T | TI)[]): T[];

        override destroy(...val: (T | TI | undefined)[]): T[];
        override create(val: (T | TI)[], at?: number): T[];
        override createnew(val: T | TI, at?: number): T;
        override recreate(...val: (T | TI)[]): T[];

        override splice(start: number, deleteCount?: number): T[];
        override splice(start: number, deleteCount: number, ...items: (T | TI)[]): T[];
        override unshift(...items: (T | TI)[]): number;
        override push(...items: (T | TI)[]): number;

        static creator<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>): {
            (...items: (T | undefined)[]): XArray<T, T, Indexer>
        }
        static creator<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>): {
            (...items: (T | TI | undefined)[]): XArray<T, TI, Indexer>
        }

        static create<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>, ...items: (T | undefined)[]): XArray<T, T, Indexer>;
        static create<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, ...items: (T | TI | undefined)[]): XArray<T, TI, Indexer>;

        static creatorIf<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>): {
            (items?: (T | undefined)[]): XArray<T, T, Indexer> | undefined;
            (items: T[]): XArray<T, T, Indexer>;
        }
        static creatorIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>): {
            (items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined;
            (items: (T | TI)[]): XArray<T, TI, Indexer>;
        }

        static createIf<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>, items: T[]): XArray<T, T, Indexer>;
        static createIf<
            T, Indexer extends TIndexer<T, T>
        >(indexer: RIndexer<T, T, Indexer>, items?: (T | undefined)[]): XArray<T, T, Indexer> | undefined;
        static createIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, items: (T | TI)[]): XArray<T, TI, Indexer>;
        static createIf<
            T extends TI, TI, Indexer extends TIndexer<T, TI>
        >(indexer: RIndexer<T, TI, Indexer>, items?: (T | TI | undefined)[]): XArray<T, TI, Indexer> | undefined;

        static createByIndex<T>(
            index: Index, config: [namedset: true, clone?: boolean],
            ...items: (Namedset<T> | undefined)[]
        ): XArray<Namedset<T>, Namedset<T>, Namedset<T>>;
        static createByIndex<T>(
            index: Index, config: [namedset: false, clone?: boolean],
            ...items: (T | undefined)[]
        ): XArray<T, T, Namedset<T>>;

        static creatorByIndex<T>(index: Index, config: [namedset: true, clone?: boolean]): {
            (...items: (Namedset<T> | undefined)[]): XArray<Namedset<T>, Namedset<T>, Namedset<T>>
        };
        static creatorByIndex<T>(index: Index, config: [namedset: false, clone?: boolean]): {
            (...items: (T | undefined)[]): XArray<T, T, Namedset<T>>
        };
    }

    namespace XArray {
        type TIndexed<T extends TI, TI, Indexer extends TIndexer<T, TI>> = _XArray.TIndexed<T, TI, Indexer>;
        type RIndexer<T extends TI, TI, Indexer extends TIndexer<T, TI>> = _XArray.RIndexer<T, TI, Indexer>;
        type TIndexer<T extends TI, TI> = _XArray.TIndexer<T, TI>;

        type Namedset<T> = {
            [key: string]: T
        }

        type Index = {
            /**
             * Whether need to index item from downside to upside,
             *  {[key:string]: number}: merge specified downside levels for specified object member indicated by key
             *  true: always for any downside level;
             *  false: don't merge;
             */
            merge?: boolean | {
                /**
                 * merge specified downside levels for specified object member indicated by key
                 */
                [key: string]: number
            },

            /**
             * Whether need to dive down deeply
             */
            deep?: boolean

            /**
             * The field name used to build index as key
             */
            key?: string

            /**
             * The item of array to build index if the match key/value match this condition
             */
            cond?: {
                [k: string]: string
            }
        }
    }
}

// type test case:
namespace test {
    // type v0 = { key?: string | string[], d: object, a?: X.c };
    // type tif = Array.IndexField<v0>;
    // type tk = Array.IndexKeys<v0>;
    // type tka = Array.Keyable<ta>;
    // type ta = v0['a'];

    type V = { id: string, r: object }
    type I = { [k: string]: string[] }

    type ff = XArray<V, V, (v: V, i: I) => void>;
    type findexer = ff['indexer'];
    type findexed = ff['indexed'];

    type kid = XArray<V, V, 'id'>;
    type kidindexer = kid['indexer'];
    type kidindexed = kid['indexed'];

    type tidxer = TIndexer<V, V>;
    // type tidxer =
    //     (<Indexed extends object>(item: V, indexed: Indexed, add: boolean, arr: _XArray.XArray<V, V, _XArray.TIndexer<V, V>>) => void)
    //     | (<Indexed extends object>(item: V, indexed: Indexed, add: boolean) => void)
    //     | object
    //     | false
    //     | "id"

    type tidxer_name = 'name' extends tidxer ? true : false;
    type tidxer_id = 'id' extends tidxer ? true : false;

    // type knm = XArray<V, V, 'name'>;
    // type knmindexer = knm['indexer'];
    // type knmindexed = knm['indexed'];

    type nn = XArray<V, V, false>;
    type nindexer = nn['indexer'];
    type nindexed = nn['indexed'];

    type Keys = XArray<string, string, {
        [k: string]: Keys | true
    }>
    type ksindexer = Keys['indexer']
    type ksindexed = Keys['indexed'];



    type ri0 = RIndexer<V, V, (item: V, idx: I, add: boolean) => void>
    type ri1 = RIndexer<V, V, false>
    type ri2 = RIndexer<V, V, 'id'>
    type ri3 = RIndexer<V, V, I>

    type ti0 = TIndexed<V, V, (item: V, idx: I, add: boolean) => void>
    type ti1 = TIndexed<V, V, false>
    type ti2 = TIndexed<V, V, 'id'>
    type ti3 = TIndexed<V, V, I>

    type tii0 = TIndexed<V, V, ri0>
    type tii1 = TIndexed<V, V, ri1>
    type tii2 = TIndexed<V, V, ri2>
    type tii3 = TIndexed<V, V, ri3>

}