import { Component, Input, OnInit } from "@angular/core";

import { Prj as TPrj, Sys as TSys, Exec as TExec } from '../../../application/service/backface/types';
import { AppService } from "../../../application/service/app.service";

@Component({
    selector: "gov-statusbar, [gov-statusbar]",
    templateUrl: "./gov.statusbar.component.html",
    styleUrls: ["./gov.statusbar.component.scss"]
})
export class GovStatusbarComponent implements OnInit {
    @Input("statuscount")
    statuscount?: Array<TSys.IStatusCount>;

    constructor(
        public app: AppService
    ) {
    }

    ngOnInit(): void {
    }
}