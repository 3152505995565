import { Directive, EventEmitter, Output } from "@angular/core";
import { Observable } from "rxjs";

function tryFire<T>(onEventGuard: EventEmitter<{
    key?: string, val?: any
}>, eventName: string, val: T | null | undefined) {
    if (val === undefined || val === null || (_.isBoolean(val) && !val)) {
        return;
    }

    onEventGuard.emit({
        key: eventName,
        val: val
    })
}

@Directive({
    selector: `[puEventGuard]`,
    exportAs: "PuEventGuard"
}) export class PuEventGuardDirective {
    private _props = Prop.Of(this);

    @Output(`puEventGuard`)
    get onEventGuard(): EventEmitter<{
        key?: string, val?: any
    }> {
        const { _props: props } = this;
        return props.onEventGuard || (
            props.onEventGuard = new EventEmitter()
        )
    }

    constructor() {
    }

    // fire puEventGuard if val is not false or null or undefined
    fireIf<T>(eventName: string, val: T | null | undefined | Observable<T | null | undefined>) {
        const { onEventGuard } = this;

        if (val instanceof Observable) {
            const sub = val.subscribe({
                next(val: T | null | undefined) {
                    tryFire(onEventGuard, eventName, val);
                },
                complete() {
                    tick(() => sub?.unsubscribe());
                }
            })

            return;
        }

        tryFire(onEventGuard, eventName, val);
    }

    // fire puEventGuard regardless any val
    fire<T>(eventName?: string, val?: T | null | undefined | Observable<T | null | undefined>) {
        this.onEventGuard.emit({
            key: eventName,
            val: val
        })
    }
}