<ng-template pu-mat-dialog-title>
    {{ "account.setting" | translate }}
</ng-template>

<div class="card card-chart w-100 h-100 px-2">
    <div class="card-header card-header-success">
        <div class="ct-chart" id="dailySalesChart">
            <h4 class="card-title">{{ auth.me?.name }}</h4>
        </div>
    </div>
    <div class="card-body" style="text-align: left">
        <h4 class="card-title">
            {{ "people.mobile" | translate }}:{{ auth.me?.mobile }}
        </h4>
        <h4 class="card-title">
            {{ "people.dept" | translate }}:{{ auth.me?.dept?.name }}
        </h4>
        <h4 *ngFor="let role of auth.me?.role; let i = index" class="card-title">
            {{ "people.roles" | translate }}{{ i + 1 }}:{{
            app.org.roles.firstOf({ id: (role.id ?? '') })?.name
            }}
        </h4>
    </div>
</div>