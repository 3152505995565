import { Component, Directive, EventEmitter, Injector, Input, OnInit, Output, resolveForwardRef, TemplateRef } from "@angular/core";
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ComponentType } from '@angular/cdk/portal';
import { JsonEditorOptions } from "ang-jsoneditor";
import { Observable } from "rxjs";

import { Sys as TSys, Prj as TPrj } from "../../../application/service/backface/types";
import { PuDialogService } from "../../puzzle/pu.dialog/pu.dialog.service";
import { PuTemplateService } from '../../puzzle/pu.template/pu.template';
import { XFilesComponent } from "../../../results/view/files.component";
import { AuthService } from "../../../application/service/auth.service";
import { AppService } from "../../../application/service/app.service";
import { PuSysService } from "../../puzzle/pu.service/pu.sys.service";
import { PicsComponent } from "../../../results/view/pics.component";
import { Exec } from "../../../application/service/backface/exec";
import { Prj } from "../../../application/service/backface/prj";
import { FieldProperty } from "../model/field.property";
import { GovEditor } from "../model/form.editting";
import { Editor } from "../../libs/editor";

type StatusMap<T extends object> = (
    WeakMap<T, NodeStatus<T>>
)

enum SelectStatus {
    indeterminate = 0.5,
    nonselected = 0,
    selected = 1,
}

type NodeStatus<T extends object> = {
    level: number,
    sels: number,
    parent?: T
};

const _EmptyNodes: any[] = [];
const _EmptyDataSet: TSys.IDatasetModule = {
    headers: XArray.create<TSys.IDatasetHeader, 'key'>('key'),
    rows: [],
    key: ''
};

const ElmBinder = Prop.Slot('ELMBINDER');

@Directive({
    selector: '[gov-nested-tree]',
    exportAs: "GovNestedTree",
}) export class GovNestedTreeDirective<TNode extends object = any> {
    private _props = Prop.Of<GovNestedTreeDirective<TNode>, {
        statusmap?: StatusMap<TNode>
    }>(this);

    readonly indeterminate = SelectStatus.indeterminate;
    readonly nonselected = SelectStatus.nonselected;
    readonly selected = SelectStatus.selected;

    @Input('gov-nested-tree')
    set setting(val: {
        source: TNode | TNode[],
        header: Editor.IField,
        leafonly: boolean,
        field: TNode[]
    }) {
        const { _props: props, setting: { header, source, field } = {} } = this;

        if (val?.header != header) {
            props.childrenAccessor = props.dataSource = props.whenChecker = props.statusmap = undefined;
        }

        if (val?.source != source) {
            props.dataSource = props.statusmap = undefined;
        }

        if (val?.field != field) {
            props.statusmap = undefined;
        }

        props.setting = val;
    };

    get setting(): {
        source: TNode | TNode[],
        header: Editor.IField,
        leafonly: boolean,
        field: TNode[]
    } | undefined {
        return this._props.setting;
    }

    get control(): NestedTreeControl<TNode> {
        const { _props: props, setting: { header: { children = '' } = {} } = {} } = this;
        return props.control || (props.control = (
            new NestedTreeControl<TNode>(node => (node as any)[children])
        ));
    }

    get childrenAccessor(): (dataNode: TNode) => TNode[] {
        const { _props: props, setting: { header: { children = '' } = {} } = {} } = this;

        return props.childrenAccessor || (
            props.childrenAccessor = (node: TNode): TNode[] => {
                return (node as any)?.[children] ?? _EmptyNodes;
            }
        )
    }

    get levelAccessor(): (dataNode: TNode) => number {
        const { _props: props } = this;

        return props.levelAccessor || (
            props.levelAccessor = (node: TNode): number => {
                const statusnode = this.update()?.get(node);
                return statusnode?.level ?? 0;
            }
        )
    }

    get expansionKey(): (dataNode: TNode) => string {
        const { _props: props, setting: { header: { children = '' } = {} } = {} } = this;

        return props.expansionKey || (
            props.expansionKey = (node: TNode): string => {
                return children;
            }
        )
    }

    get dataSource(): MatTreeNestedDataSource<TNode> {
        const { _props: props, setting: { header: { children = '' } = {}, source } = {} } = this;

        return props.dataSource || (props.dataSource = (
            props.dataSource = new MatTreeNestedDataSource<TNode>(),
            props.dataSource.data = _.isArray(source) ? source : (
                source && children ? (
                    (source as any)[children] ?? _EmptyNodes
                ) : _EmptyNodes
            ),
            props.dataSource
        ));
    }

    get whenChecker(): ((idx: number, node: TNode) => boolean) | undefined {
        const { _props: props, setting: { header: { children = '' } = {} } = {} } = this;

        return props.whenChecker || (props.whenChecker = (
            children ? ((idx: number, node: TNode) => (
                ((node as any)?.[children]?.length ?? 0) > 0
            )) : undefined
        ));
    }

    constructor(
    ) {
    }

    select(node: TNode) {
        this.update(node, true);
    }

    deselect(node: TNode) {
        this.update(node, false);
    }

    isselected(node: TNode): boolean {
        return this.status(node) == SelectStatus.selected;
    }

    isnonselected(node: TNode): boolean {
        return this.status(node) == SelectStatus.nonselected;
    }

    isindeterminate(node: TNode): boolean {
        return this.status(node) == SelectStatus.indeterminate;
    }

    status(node: TNode): SelectStatus {
        const { setting: { header: { children = '' } = {} } = {} } = this;
        if (!children) return SelectStatus.nonselected;

        // update the status map firstly;
        const carray: TNode[] | undefined = (node as any)?.[children];
        const statusnode = this.update()?.get(node);
        const slen = statusnode?.sels ?? 0;
        const clen = carray?.length ?? 0;

        if (clen <= 0) {
            if (slen > 0) return SelectStatus.selected;
            return SelectStatus.nonselected;
        }

        if (slen == 0) return SelectStatus.nonselected;
        if (clen != slen) return SelectStatus.indeterminate;
        return SelectStatus.selected;
    }

    update(node?: TNode, selected?: boolean): StatusMap<TNode> | undefined {
        const { _props: props, setting: { header: { children = '' } = {}, source, field } = {} } = this;

        const build = (node?: TNode | TNode[], level: number = 0, parent?: TNode, statusmap?: StatusMap<TNode>): [sels: number, map: StatusMap<TNode>] | undefined => {
            if (!node) return;

            statusmap = statusmap || new WeakMap();

            if (!_.isArray(node)) {
                const carray: TNode[] | undefined = (node as any)?.[children];
                const clen = carray?.length ?? 0;
                let sels = 0;

                if (clen <= 0) {
                    // leaf node
                    sels = field?.has(node) ? SelectStatus.selected : SelectStatus.nonselected;
                } else {
                    // non-leaf node
                    sels = build(carray, level + 1, node, statusmap)?.[0] ?? 0;
                }

                statusmap.set(node, { sels, level, parent });
                return [(sels <= 0 ? SelectStatus.nonselected : (sels > 0 && sels < clen ? SelectStatus.indeterminate : SelectStatus.selected)), statusmap];
            }

            const selcnt = _.reduce(node, (sels, n): number => {
                return sels + (build(n, level, parent, statusmap)?.[0] ?? 0);
            }, 0);

            return [selcnt, statusmap];
        }

        const statusmap: StatusMap<TNode> | undefined = (props.statusmap || (props.statusmap = (
            field ? (build(_.isArray(source) ? source : (source as any)?.[children])?.[1]) : undefined
        )))

        if (!(node && children && statusmap)) {
            return statusmap;
        }

        selected = !!selected;
        const targetcode = selected ? SelectStatus.selected : SelectStatus.nonselected;
        if (targetcode == this.status(node)) return statusmap;

        // mark and collect decendents
        const update = (node: TNode | TNode[], starting: boolean = true, colls?: TNode[]): TNode[] | undefined => {
            colls = (colls || []);

            if (starting) {
                // start point
                if (_.isArray(node)) {
                    console.assert(!_.isArray(node));
                    return;
                }

                const carray: TNode[] | undefined = (node as any)?.[children];
                const clen = carray?.length ?? 0, { sels } = statusmap.get(node)!;
                for (let _node: TNode | undefined = node, delta = ((selected ? Math.max(clen, 1) : 0) - sels); !!_node;) {
                    const statusbag: NodeStatus<TNode> = statusmap.get(_node)!;
                    const prestatus = this.status(_node);
                    statusbag.sels += delta;

                    const pststatus = this.status(_node);
                    if (prestatus == pststatus) break;

                    delta = pststatus - prestatus;
                    _node = statusbag.parent;
                }

                if (clen <= 0) return colls.push(node), colls;
                return update(carray!, false, colls);
            }

            if (!_.isArray(node)) {
                const carray: TNode[] | undefined = (node as any)?.[children];
                const clen = carray?.length ?? 0;
                const nbag = statusmap.get(node)!;

                if (clen <= 0) {
                    nbag.sels = targetcode;
                    return colls.push(node), colls;
                }

                nbag.sels = selected ? clen : 0;
                return update(carray!, false, colls);
            }

            return _.transform(node, (colls, n) => {
                if (this.status(n) == targetcode) return;
                update(n, false, colls);
            }, colls);
        }

        const colls = update(node) || [];
        if (selected) {
            field?.create(colls)
        } else {
            field?.destroy(...colls);
        }

        return statusmap;
    }
};

@Component({
    selector: "gov-form, [gov-form]",
    templateUrl: "./gov.form.component.html",
    styleUrls: ["./gov.form.component.scss"],
    providers: [PuTemplateService],
    exportAs: 'GovForm'
})
export class GovFormComponent extends FieldProperty implements OnInit {
    private _props = Prop.Of(this);

    get MapLocatable(): {
        [P in (keyof typeof TPrj.Pool) | TPrj.Pool | '']?: any
    } {
        const { _props: props } = this;
        return props.MapLocatable || (props.MapLocatable = {
            candidate: this.auth.me?.priviledges?.plan?.pilotproject?.maplocate,
            meditate: this.auth.me?.priviledges?.plan?.pilotproject?.maplocate,
            input: this.auth.me?.priviledges?.plan?.inputproject?.maplocate,
            accept: this.auth.me?.priviledges?.plan?.project?.maplocate,
            begun: this.auth.me?.priviledges?.plan?.project?.maplocate,
            [TPrj.Pool.candidate]: this.auth.me?.priviledges?.plan?.pilotproject?.maplocate,
            [TPrj.Pool.meditate]: this.auth.me?.priviledges?.plan?.pilotproject?.maplocate,
            [TPrj.Pool.input]: this.auth.me?.priviledges?.plan?.inputproject?.maplocate,
            [TPrj.Pool.accept]: this.auth.me?.priviledges?.plan?.project?.maplocate,
            [TPrj.Pool.begun]: this.auth.me?.priviledges?.plan?.project?.maplocate,
            [""]: false
        });
    }

    readonly JSON = JSON;
    nowdate = new Date();

    get mindate(): Date {
        const { _props: props, app: { dict: { rangeyearmin } } } = this;
        return props.mindate || (props.mindate = new Date(rangeyearmin, 0, 1));
    }

    get maxdate(): Date {
        const { _props: props, app: { dict: { rangeyearmax } } } = this;
        return props.maxdate || (props.maxdate = new Date(rangeyearmax, 11, 31));
    }

    @Input('gov-form')
    get govform(): TSys.IDatasetModule {
        return this.datasets;
    }

    set govform(val: TSys.IDatasetModule) {
        this.datasets = val;
    }

    @Input('datasets')
    get datasets(): TSys.IDatasetModule {
        const { _props: props } = this;
        return props.datasets = props.datasets || _EmptyDataSet;
    }

    set datasets(val: TSys.IDatasetModule) {
        this._props.datasets = val;
    }

    @Input("readonly")
    get readonly(): boolean {
        const { _props: props } = this;
        return !!props.readonly;
    }

    set readonly(val: boolean) {
        this._props.readonly = val;
    }

    @Output('onCommandTemplate')
    get onCommandTemplate(): EventEmitter<GovEditor.CommandConfig> {
        const { _props: props } = this;

        return props.onCommandTemplate = props.onCommandTemplate || new EventEmitter();
    }

    get jsoneditoroptions(): JsonEditorOptions {
        const { _props: props } = this;

        let options: JsonEditorOptions;
        return props.jsoneditoroptions = props.jsoneditoroptions || (
            options = new JsonEditorOptions(),
            options.modes = ['code', 'text', 'tree', 'view'],
            //options.schema = schema,
            options.statusBar = false,
            options.mode = 'code',
            options
        )
    };

    get editting(): GovEditor.IEditting | undefined {
        const { _props: props } = this;

        if (props.editting != GovEditor.Editting.get(this.datasets)) {
            Promise.resolve().then(() => {
                props.editting = GovEditor.Editting.get(this.datasets);
            })
        }

        return props.editting;
    }

    get forms(): GovEditor.IForm[] | undefined {
        const { _props: props, editting } = this;

        if (props.forms != editting?.form && props.forms?.[0] != editting?.form) {
            props.forms = _.isArray(editting?.form) ? editting?.form : (editting?.form && [editting?.form] || []);
        }

        return props.forms;
    }

    constructor(
        public injector: Injector,
        public sys: PuSysService,
        public override app: AppService,
        public auth: AuthService,
        public dialog: PuDialogService,
        public fieldtpls: PuTemplateService
    ) {
        super(app);
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
    }

    poolOf(editor: any): (keyof typeof TPrj.Pool) | TPrj.Pool | '' {
        return editor instanceof Prj.Project ? (editor.pool ?? 'begun') : ''
    }

    BOf(val: any) {
        return GovFormComponent.BOf(val);
    }

    stopevent(event: Event) {
        event?.stopImmediatePropagation?.();
        event?.stopPropagation?.();
    }

    isReadonly(header: Editor.IField, headers: Editor.IFields, data: Editor.Editor<any>): boolean {
        if (this.readonly || (_.isBoolean(header.readonly) && header.readonly)) return true;
        if (_.isFunction(header.readonly) && header.readonly(this.app, data)) return true;
        if (!(data instanceof Editor.Editor)) return false;

        return data.$readonly_(this.getProperty(header, headers)?.pathProp.paths[0] ?? '');
    }

    isVisible(header: Editor.IField, headers: Editor.IFields, editor: Editor.Editor<any>): boolean {
        if (_.isFunction(header.invisible) && header.invisible(this.app, editor)) return false;
        if (_.isBoolean(header.invisible) && header.invisible) return false;

        if ((header?.dependvalues?.length ?? 0) > 0) {
            const targetheaderkey = header?.dependfield?.key, dependvalues = header?.dependvalues;
            const targetheader = headers.find(header => header.key == targetheaderkey);
            const targetfield = this.getCellField(editor, targetheader, headers);
            if (!targetfield) return true;

            const targetvalue = targetfield?.data;
            const matchindex = dependvalues?.findIndex(v => {
                switch (typeof v) {
                    case 'function': {
                        return v(targetvalue, editor);
                    }

                    default: {
                        return v == targetvalue;
                    }
                }
            });

            return (matchindex ?? 0) >= 0;
        }

        return true;
    }

    isSelected(values: any[], opt: any) {
        return values?.find(v => (v?.['$base_'] == opt) || v == opt);
    }

    isSection(form: GovEditor.IForm): form is GovEditor.ISection {
        return GovEditor.isSection(form);
    }

    noactionbar(form: GovEditor.IForm): boolean {
        if (GovEditor.isSection(form)) return !!form.noactionbar;
        return !form.sections.find(s => !s?.noactionbar);
    }

    $changed_(form: GovEditor.IForm): boolean {
        if (GovEditor.isSection(form)) return form.editor?.$changed_?.();
        return !!form.sections.find(s => s?.editor?.$changed_?.());
    }

    $save_(form: GovEditor.IForm): boolean | Observable<boolean> | void {
        if (GovEditor.isSection(form)) return form.editor?.$save_?.();
        form.sections.forEach(s => s?.editor?.$save_?.());
    }

    $cancel_(form: GovEditor.IForm) {
        if (GovEditor.isSection(form)) return form.editor?.$cancel_?.();
        form.sections.forEach(s => s?.editor?.$cancel_?.());
    }

    getFieldTemplate(header: Editor.IField): string {
        const { app: { editor } } = this, { Value: { Type: FType } } = editor;

        if (editor.Value.isFieldType(header, FType.list)) {
            return _.isString(header.source) ? header.source : FType[FType.list];
        }

        return FType[editor.Value.getFieldType(header, 'multi') || FType.text];
    }

    onCommand(header: TSys.IDatasetHeader, editor: Editor.Editor<any>) {
        header.source = resolveForwardRef(header.source);
        const { dialog, injector } = this;

        const config: GovEditor.CommandConfig = {
            template: header.source as (ComponentType<any> | TemplateRef<any>),
            context: {
                object: editor,
                header: header,
                dataset: this.datasets
            },
            config: {
                autoFocus: true,
                hasBackdrop: true,
                disableClose: true,
                restoreFocus: true,

                injector: this.injector,
                resiable: true,
                maxHeight: '100vh',
                minHeight: '90vh',
                maxWidth: '100vw',
                minWidth: '80vw',
                height: '90vh',
                width: '80vw',
            }
        }

        this.onCommandTemplate.emit(config);
        const template = config.template;
        const context = config.context;
        if (!template) return;

        if (_.isFunction((template as any)['open'])) {
            (template as any)['open'](dialog, context, injector);
            return;
        }

        this.dialog.open({
            template: template,
            ...config.config
        }, context)
    }

    openPics(pics: Exec.Pic[], current: Exec.Pic) {
        this.sys.openDialog(PicsComponent, {
            data: {
                pics, current
            }
        })
    }

    openXFiles(files: Exec.XFile[], current: Exec.XFile) {
        this.sys.openDialog(XFilesComponent, {
            data: {
                files, current
            }
        })
    }

    drop(coll: any[], event: CdkDragDrop<any[]>): void {
        let { previousIndex: from, currentIndex: to } = event;
        const max = coll.length - 1, min = 0;
        if (max <= min) return;

        from = _.clamp(from, min, max);
        to = _.clamp(to, min, max);
        if (from === to) return;

        coll.splice(to, 0, ...(
            coll.splice(from, 1)
        ));
    }

    static BOf<
        T extends Record<string | symbol, any> = {
            panelOpenState: boolean
            ng2FileDropOver: boolean
            filter: string,
            hide: boolean
        }
    >(val: any) {
        return ElmBinder.Of<T>(val, true);
    }
}