import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { EventEmitter, Injector, Type } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';
import { If } from 'ts-toolbelt/out/Any/If';
import { A, L, O, U } from 'ts-toolbelt';
import * as CryptoJS from "crypto-js";
import * as mathjs from 'mathjs';
import * as UUID from 'uuid';

import {
    create as mathCreate,
    parse as mathParse,
    all as mathAll,
    ParseState,

    MathNode,

    /**
     * separated by a newline character '\n', or by a semicolon ';'
     */
    BlockNode,

    /**
     * Assignment of a function or variable,
     * - can be a variable like 'a=2.3'
     * - or a updating an existing variable like 'matrix(2,3:5)=[6,7,8]'
     * - defining a function like 'f(x) = x^2'
     *      a variable assignment like 'a = 2/3'
     *      a matrix subset assignment like 'A[1,2] = 4'
     *      function assignment like 'f(x) = x^2'
     */
    AssignmentNode,
    FunctionAssignmentNode,

    /**
     * conditional operation
     *     condition ? truePart : falsePart
     * Note: conditional operator is right-associative
     */
    ConditionalNode,

    /**
     * logical or, 'x or y'
     * logical exclusive or, 'x xor y'
     * logical and, 'x and y'
     * bitwise or, 'x | y'
     * bitwise exclusive or (xor), 'x ^| y'
     * bitwise and, 'x & y'
     * Bitwise left shift, bitwise right arithmetic shift, bitwise right logical shift
     * conversion operators 'to' and 'in'
     *      end of expression -> this is the unit 'in' ('inch')
     *      operator 'a to b' or 'a in b'
     * add or subtract
     * multiply, divide
     * implicit multiplication
     *      implicit multiplication like '2a', '(2+3)a', 'a b'
     *      implicit multiplication like '(2+3)2'
     *      implicit multiplication like '2(3+4)', '(3+4)(1+2)'
     * modulus and percentage
     *      If the expression contains only %, then treat that as /100
     * Unary plus and minus, and logical and bitwise not
     * power operator is right associative, such '2^-3'
     * Left hand operators: factorial x!, ctranspose x'
     */
    OperatorNode,

    /**
     * Parse a chained conditional, like 'a > b >= c'
     */
    RelationalNode,


    /**
     * range, "start:end", "start:step:end", ":", "start:", ":end", etc
     */
    RangeNode,

    /**
     * symbols: functions, variables, constants, units
     */
    SymbolNode,

    /**
     * true, false, null, undefined
     * NaN, Infinity
     * a single or double quoted string.
     * a number
     */
    ConstantNode,

    /**
     * accessors:
     * - function invocation in round brackets (...), for example sqrt(2)
     * - index enclosed in square brackets [...], for example A[2,3]
     * - dot notation for properties, like foo.bar
     * 
     * index notation like variable[2, 3]
     * dot notation like variable.prop
     */
    AccessorNode,
    IndexNode,

    /**
     * function invocation like fn(2, 3) or obj.fn(2, 3)
     */
    FunctionNode,


    /**
     * the matrix
     * matrix [...]
     * an empty matrix "[ ]"
     * a single comma-separated row from a matrix, like 'a, b, c'
     */
    ArrayNode,

    /**
     * an object, enclosed in angle brackets{...}, for example {value: 2}
     */
    ObjectNode,


    /**
     * parentheses, (...)
     */
    ParenthesisNode,
} from 'mathjs';
const MathNode = mathjs.Node;

type MathNodes = Remove<InstanceType<O.UnionOf<O.Select<typeof mathjs, Type<MathNode>, 'contains->'>>>, MathNode>;
type Remove<U, M> = U extends unknown ? If<A.Equals<U, M>, never, U> : never;
type ListOf<U, LN extends L.List = [], LastU = U.Last<U>> = {
    0: ListOf<Remove<U, LastU>, L.Prepend<LN, LastU>>, 1: LN
}[A.Equals<U, never>];

namespace _Prop {
    export function newSymbol(prefix: string): symbol {
        const warray = CryptoJS.lib.WordArray, enc = CryptoJS.enc.Hex;
        const rndkey = warray.random(4).toString(enc).toUpperCase();
        return Symbol(`@$${prefix}${rndkey}`)
    }

    export function newUuid(hex: boolean): string {
        const id: string = UUID.v4();
        return hex ? id.replace(/-/g, '') : id;
    }

    export const newIdx = (() => {
        var current = 1;
        return () => (
            ++current
        )
    })();

    const PropertySymbol: symbol = newSymbol('PROPBAG');

    function getLastSlot<
        TSlot extends {}, O extends object
    >(bag: Prop.PBag<O, {}> | undefined, key: symbol): TSlot | undefined {
        while (bag) {
            const slots = bag.slots;
            const slot = slots[key];
            if (slot) {
                return slot as TSlot;
            }

            bag = bag.parent;
        };
    }

    export function getBag<
        O extends object, TEX extends {} = {}
    >(obj: O, forceown: true): Prop.PBag<O, TEX>;
    export function getBag<
        O extends object, TEX extends {} = {}
    >(obj: O | undefined | null, forceown: boolean): Prop.PBag<O, TEX> | undefined;
    export function getBag(obj: object | undefined | null, forceown: boolean): Prop.PBag | undefined {
        if (obj == null) return;

        const _parent: Prop.PBag['parent'] = _.get(obj, PropertySymbol);
        if (forceown && !obj.hasOwnProperty(PropertySymbol)) {
            const parent: Prop.PBag['parent'] = _parent;
            const values: Prop.PBag['values'] = {};
            const before: Prop.PBag['before'] = {};
            const after: Prop.PBag['after'] = {};
            const events: Prop.PBag['events'] = {};
            const slots: Prop.PBag['slots'] = {};

            const bag: Prop.PBag = {
                get parent() { return parent; },
                get events() { return events; },
                get values() { return values; },
                get before() { return before },
                get slots() { return slots; },
                get after() { return after },
            };

            Object.defineProperty(obj, PropertySymbol, {
                enumerable: false, configurable: true,
                get(this: object): Prop.PBag {
                    return bag;
                }
            });
        }

        return _.get(obj, PropertySymbol);
    }

    export function Of<
        O extends object, TEX extends {} = {}
    >(obj: O, init?: (values: Prop.VBag<O, TEX>, obj: O) => void): Prop.VBag<O, TEX>;
    export function Of<
        O extends object, TEX extends {} = {}
    >(obj: O, init?: (values: Prop.VBag<O, TEX>) => void): Prop.VBag<O, TEX>;
    export function Of<
        O extends object, TEX extends {} = {}
    >(obj: O | undefined | null, init?: (values: Prop.VBag<O, TEX>, obj: O) => void): Prop.VBag<O, TEX> | undefined;
    export function Of<
        O extends object, TEX extends {} = {}
    >(obj: O | undefined | null, init?: (values: Prop.VBag<O, TEX>) => void): Prop.VBag<O, TEX> | undefined;
    export function Of(obj: object | undefined | null, init?: (values: Prop.VBag, obj?: object) => void): Prop.VBag | undefined {
        if (obj == null) return;
        const { values } = getBag(obj, true)!;
        init?.(values, obj);
        return values;
    }

    export function Slot<
        TSlot extends {} = {}
    >(key: symbol | string, creator?: (parent?: TSlot) => TSlot): {
        Of<FSlot extends {} = TSlot>(obj: object | undefined | null, forceown: boolean): FSlot | undefined;
        Of<FSlot extends {} = TSlot>(obj: object, forceown: true): FSlot;
        creator?: typeof creator;
    }
    export function Slot<
        TSlot extends {} = {}, TO extends object = {}
    >(key: symbol | string, creator?: (parent: TSlot | undefined, obj: TO) => TSlot): {
        Of<FSlot extends {} = TSlot, O extends TO = TO>(obj: O | undefined | null, forceown: boolean): FSlot | undefined;
        Of<FSlot extends {} = TSlot, O extends TO = TO>(obj: O, forceown: true): FSlot;
        creator?: typeof creator;
    }
    export function Slot(key: symbol | string, creator?: (parent?: {}, obj?: object) => {}) {
        const _key = _.isString(key) ? newSymbol(key) : key;

        return {
            get creator() { return creator },
            Of(obj: object | undefined | null, forceown: boolean): {} | undefined {
                if (obj == null) return;

                const bag = getBag(obj, forceown);
                if (bag == null) return;

                const lstslot = getLastSlot(bag, _key);
                if (!forceown || (lstslot && bag.slots[_key] == lstslot)) {
                    return lstslot;
                }

                return bag.slots[_key] = creator?.(lstslot, obj) ?? {
                    ...(lstslot || {})
                }
            }
        }
    }

    const defaultHandler: ProxyHandler<any> = {
        getOwnPropertyDescriptor(target, p): PropertyDescriptor | undefined {
            return undefined;
        },

        getPrototypeOf(target): object | null {
            return null;
        },

        defineProperty(target, property, attributes): boolean {
            return false;
        },

        construct(target, argArray, newTarget): object {
            return {};
        },

        apply(target, thisArg, argArray): any {
            return undefined;
        },

        setPrototypeOf(_target, v): boolean {
            return false;
        },

        isExtensible(target): boolean {
            return false;
        },

        preventExtensions(target): boolean {
            return true;
        },

        get(_target, key: string) {
            return;
        },

        set(_target, key: string, value: any): boolean {
            return false;
        },

        deleteProperty(_target, key: string): boolean {
            return false;
        },

        has(_target, key: string): boolean {
            return false;
        },

        ownKeys(_target): ArrayLike<string> {
            return [];
        }
    }

    export function makeProxyHandler<T extends object>(handler: ProxyHandler<T>): ProxyHandler<T> {
        return { ...defaultHandler, ...handler };
    }
}

namespace _Interpolation {
    // const Pattern = new RegExp("^\\s*\\{\\{(?<exp>(?:.|\\n|\\r)*)\\}\\}\\s*$", 'i');
    const Pattern = new RegExp("^\\s*`(?<exp>(?:.|\\n|\\r)*)`\\s*$", 'i');
    type Expression = Interpolation.Expression;
    type Handler = Interpolation.Handler;

    function Indent(depth: number = 0): string {
        return (new Array(depth).fill('    ')).join('');
    }

    namespace Unescape {
        type Keyword = {
            prefix: string,
            surfix: string,
            reform: {
                surround?: [
                    prefix: string,
                    surfix: string
                ],
                each?: [
                    prefix: string,
                    surfix: string,
                    sep?: string,
                ]
            },
            keywords: Keyword[]
        }

        type Value = (string | Value)[] & {
            word?: Keyword
        }

        const KW: { [prefix: string]: Keyword } = {
            ['${']: { prefix: '${', surfix: '}', reform: { surround: ["(", ")"] }, keywords: [] },
            ['`']: { prefix: '`', surfix: '`', reform: { each: ['"', '"', '+'] }, keywords: [] },
            ['"']: { prefix: '"', surfix: '"', reform: { surround: ['"', '"'] }, keywords: [] },
            ['{']: { prefix: '{', surfix: '}', reform: { surround: ["{", "}"] }, keywords: [] },
            // { prefix: "'", surfix: "'", reform: { surround: ["'", "'"] } },
            // { prefix: '(', surfix: ')', reform: { surround: ["(", ")"] } },
        }

        KW['${'].keywords.push(KW['`'], KW['${'], KW['"'], KW['{']);
        KW['{'].keywords.push(KW['`'], KW['${'], KW['"'], KW['{']);
        KW['`'].keywords.push(KW['${']);

        const Keywords: Keyword[] = [KW['`']];

        function trace(val?: Value | string, depth: number = 0) {
            if (val == null) return;

            const indent = Indent(depth);
            if (_.isString(val)) {
                console.log(indent, val);
                return;
            }

            console.log(indent, val.word?.prefix);

            for (const _val of val) {
                trace(_val, depth + 1);
            }

            console.log(indent, val.word?.surfix);
        }

        function scan(exp: string): Value | undefined {
            type Stack = { value: Value, keywords: Keyword[], word?: Keyword };

            const stack: Omit<Stack[], 'top' | 'push' | 'pop'> & {
                readonly top: Stack | undefined;
                push(s: Stack): void;
                pop(): void;
            } = Object.setPrototypeOf({
                get top(): Stack | undefined {
                    return stack[0];
                },
                push(s: Stack): void {
                    stack.splice(0, 0, s);
                },
                pop(): void {
                    stack.splice(0, 1);
                }
            }, <Stack[]>[]);

            stack.push({
                keywords: Keywords,
                value: <Value>[],
            });

            let start = 0, end = 0;
            function isKey(key?: string): key is string {
                if (!key) return false;

                const pre1 = exp.charAt(end - 1);
                const pre2 = exp.charAt(end - 2);
                if (pre1 == '\\' && pre2 != '\\') {
                    // escape char
                    return false;
                }

                return exp.slice(end, end + key.length) == key;
            }

            while (end < exp.length) {
                const current = stack.top;

                if (!current) {
                    throw new Error('invalid interpolation express parse state: stack empty!');
                }

                const {
                    keywords: curkeywords,
                    value: curvalue,
                    word: {
                        surfix: cursurfix
                    } = {},
                } = current;

                if (isKey(cursurfix)) {
                    // has meet the segment end
                    end > start && curvalue.push(exp.slice(start, end));
                    start = (end += cursurfix.length);
                    stack.pop();
                    continue;
                }

                // try to match the new segment
                let newsegmentfound = false;
                for (const word of curkeywords) {
                    const { prefix } = word;

                    if (isKey(prefix)) {
                        end > start && curvalue.push(exp.slice(start, end));
                        start = (end += prefix.length);
                        newsegmentfound = true;

                        const newval = _.extend(
                            [], { word }
                        );

                        curvalue.push(newval);

                        stack.push({
                            keywords: word.keywords,
                            value: newval,
                            word
                        });

                        break;
                    }
                }

                if (!newsegmentfound) {
                    end = end + 1;
                }
            }

            if (stack.length != 1) {
                throw new Error('invalid interpolation express parse state: stack size should be 1!');
            }

            return stack[0]?.value;
        }

        function reform(val?: Value): string {
            if (val == null) return '';

            const { word: { reform: _reform = {} } = {} } = val;
            const { surround: [prefix = '', surfix = ''] = [] } = _reform;
            const { each: [iprefix = '', isurfix = '', isep = ''] = [] } = _reform;
            const parts: string[] = [];

            for (const _val of val) {
                if (_.isString(_val)) {
                    parts.push([iprefix, _val, isurfix].join(''));
                    continue;
                }

                parts.push(reform(_val));
            }

            isep && parts.splice(0, parts.length, parts.join(isep));
            prefix && parts.splice(0, 0, prefix);
            surfix && parts.push(surfix);
            return parts.join('');
        }

        export function build(expression: string) {
            const values = scan(expression);
            return reform(values);
        }
    }

    namespace Executor {
        namespace Parser {
            enum TOKENTYPE {
                NULL = 0,
                DELIMITER = 1,
                NUMBER = 2,
                SYMBOL = 3,
                UNKNOWN = 4
            }

            function moreChar(state: ParseState, step: number): string {
                const { expression: _exp, nestingLevel, index } = state;
                if (!_.isString(_exp)) return '';

                function _moreChar(exp: string, index: number): string {
                    if (index < 0) return '';

                    const preChr = exp.charAt(index);
                    if (!parser.isWhitespace(preChr, nestingLevel)) {
                        return preChr;
                    }

                    return _moreChar(exp, index + step);
                }

                return _moreChar(_exp, index - 1 + step);
            }

            function nextTo(state: ParseState, [expect, rearmore]: [expect: string, rearmore: number], withtoken: boolean = true): string {
                const { expression: _all, index, token } = state;
                if (!_.isString(_all)) return '';

                const { length: _alllen } = _all;
                const { length: _tknlen } = token;
                const { length: _explen } = expect;
                const _spos = (index - (withtoken ? _tknlen : 0));
                let { index: _end } = state;

                do {
                    if (_all.slice(_end, _end + _explen) === expect) {
                        const _epos = (state.index = (_end + _explen + rearmore));
                        state.token = _all.charAt(_epos - 1);
                        return _all.slice(_spos, _epos);
                    }

                    _end = _end + 1;
                } while (_end + _explen <= _alllen);

                state.tokenType = TOKENTYPE.NULL;
                state.index = _alllen;
                state.token = '';
                return '';
            }

            type ParseAPI = O.Select<typeof parser, (
                ((state: ParseState, node: MathNode, ...args: any[]) => MathNode) |
                ((state: ParseState) => MathNode) |
                ((state: ParseState) => void)
            )>;

            type ParseFuncs = L.UnionOf<ListOf<ParseAPI[keyof ParseAPI]>>

            type ParseHooker = HookerFunc & {
                [pattern: string]: {
                    context?: ParseFuncs[],
                    parse?(state: ParseState): MathNode,
                    token?(state: ParseState): void,
                }
            }

            type HookerFunc = {
                <Func extends ParseFuncs>(defParse: Func, ...args: Parameters<Func>): ReturnType<Func>
            }

            export const math = mathCreate(mathAll, {
                matrix: 'Array'
            });

            // intercept the compile method of AccessNode to avoid the undefined object access exception by undefined value return
            const { AccessorNode } = math;
            const AccessNode_DefCompile = AccessorNode.prototype._compile;
            AccessorNode.prototype._compile = function AccessNode_Compile(this: AccessorNode, ...args: any[]) {
                const func = AccessNode_DefCompile.apply(this, args);
                return function evalAccessorNode(this: any, ...args: any[]): any {
                    try {
                        return func.apply(this, args);
                    } catch (e) {
                    }
                }
            }

            // register or replace the methods
            const { add: _add, equal: _equal } = math;
            math.import({
                // interceipt the add method to support string + any rather than number converter only
                add: _.extend(
                    function add(this: any, arg0: any, arg1: any) {
                        try {
                            return _add.apply(this, arguments as any);
                        } catch (e) {
                            arg0 = (arg0 != null ? arg0 : '');
                            arg1 = (arg1 != null ? arg1 : '');
                            return arg0 + arg1;
                        }
                    }, _add
                ),

                // interceipt the equal method to support any rather than number converter only
                equal: _.extend(
                    function equal(this: any, arg0: any, arg1: any) {
                        try {
                            return _equal.apply(this, arguments as any);
                        } catch (e) {
                            return arg0 === arg1;
                        }
                    }, _equal
                )
            }, { wrap: true, override: true })

            export const MaypipeTypes: {
                tryPipe(args: OperatorNode['args'], _math: typeof math, _scope: Map<string, any>): { val: any } | undefined,
                pipeFunc(node: MathNode, _scope: Map<string, any>): Function | undefined
            } & {
                [type in MathNodes['type']]?: {
                    tryPipe(arg0: MathNode, arg1: Extract<MathNodes, { type: type }>, _math: typeof math, _scope: Map<string, any>): [
                        fnode: MathNode, ...argsnode: MathNode[]
                    ]
                }
            } = {
                tryPipe(args: OperatorNode['args'], _math: typeof math, _scope: Map<string, any>): { val: any } | undefined {
                    const [p0, p1] = args, type = (p1 as MathNodes).type;
                    const tryPipe = MaypipeTypes[type]?.tryPipe;
                    if (!tryPipe) return;

                    const [fnode, ...argsnode] = tryPipe(p0, p1 as any, _math, _scope);
                    const func = MaypipeTypes.pipeFunc(fnode, _scope);
                    if (!func) return;

                    const argsval = argsnode.map(an => an.evaluate(_scope));
                    return { val: func.apply(null, argsval) };
                },
                pipeFunc(node: MathNode, _scope: Map<string, any>): Function | undefined {
                    try {
                        const val = node.compile().evaluate(_scope);
                        if (_.isFunction(val)) return val;
                    } catch (e) { };

                    const exp = node.toString().replace(/^\s*\$recordsets\./, '');
                    const $actions = _scope.get('$actions');
                    return _.get($actions, exp);
                },


                RangeNode: {
                    tryPipe(arg0: MathNode, arg1: RangeNode, _math: typeof math, _scope: Map<string, any>): [
                        fnode: MathNode, ...argsnode: MathNode[]
                    ] {
                        const fn = arg1.start, p0 = arg0, p1 = arg1.end, p2 = arg1.step;
                        return p2 ? [fn, p0, p1, p2] : [fn, p0, p1];
                    }
                },
                SymbolNode: {
                    tryPipe(arg0: MathNode, arg1: SymbolNode, _math: typeof math, _scope: Map<string, any>): [
                        fnode: MathNode, ...argsnode: MathNode[]
                    ] {
                        return [arg1, arg0];
                    }
                },
                AccessorNode: {
                    tryPipe(arg0: MathNode, arg1: AccessorNode, _math: typeof math, _scope: Map<string, any>): [
                        fnode: MathNode, ...argsnode: MathNode[]
                    ] {
                        return [arg1, arg0];
                    }
                }
            }

            const { expression: { mathWithTransform: { bitOr: _bitOr } } } = math as any;
            math.import({
                // interceipt the bitOr method to support pipe transformation
                bitOrPipe: _.extend(
                    function bitOrPipeTransform(this: any, _args: OperatorNode['args'], _math: typeof math, _scope: Map<string, any>) {
                        try {
                            const res = MaypipeTypes.tryPipe(_args, _math, _scope);
                            if (res) return res.val;
                        } catch (e) {
                        }

                        try {
                            return _bitOr.apply(this, arguments as any);
                        } catch (e) {
                        }
                    }, _bitOr
                )
            }, { override: true })

            // interceipt the mathjs parse method to support our schema used expression grammer
            export const parser = math.parse;
            const { parseSymbol: _parseSymbol, parseEnd: _parseEnd, getToken: _getToken } = parser;
            const parseHooker = _.extend<HookerFunc, Pick<ParseHooker, string>>(
                <Func extends ParseFuncs>(defParse: Func, state: ParseState, node?: MathNode, ...args: any[]): ReturnType<Func> => {
                    const { token } = state, chrs = [moreChar(state, -1), token, moreChar(state, 1)];
                    const tries = [[0, 1, 2], [0, 1], [1, 2], [1]];

                    for (const trial of tries) {
                        const ps = _.transform(trial,
                            (p, idx) => (p[idx] = chrs[idx]),
                            new Array(3).fill(' ')
                        ).join('');

                        const hooker = parseHooker[ps];
                        if (!hooker) continue;

                        if (defParse == _getToken) {
                            // token handle if have defined
                            if (!hooker.token) continue;
                            return hooker.token(state) as any;
                        }

                        const { context, parse } = hooker;
                        if (!parse || (context && context.indexOf(defParse) < 0)) {
                            continue;
                        }

                        const _node = parse(state);
                        if (!node?.isNode) {
                            return _node as any;
                        }

                        // special for parseAccessors
                        node = new IndexNode([node, _node]);
                        break;
                    }

                    return defParse(state, node as any, ...args) as any;
                },
                {
                    [' . ']: {
                        context: [_parseEnd],
                        parse(state: ParseState): MathNode {
                            state.tokenType = TOKENTYPE.SYMBOL;
                            return _parseSymbol(state);
                        }
                    },

                    [' >)']: {
                        token(state: ParseState): void {
                            const str = nextTo(state, [')', 0], false);
                            state.tokenType = TOKENTYPE.SYMBOL;
                            state.token = str;
                        }
                    },

                    [' .[']: {
                        token(state: ParseState): void {
                            const str = nextTo(state, [']', 0], false);
                            state.tokenType = TOKENTYPE.SYMBOL;
                            state.token = str;
                        }
                    },

                    [' .<']: {
                        token(state: ParseState): void {
                            const str = nextTo(state, ['>', 0], false);
                            state.tokenType = TOKENTYPE.SYMBOL;
                            state.token = str;
                        },
                        // context: [_parseAccessors],
                        // parse(state: ParseState): MathNode {
                        //     const str = nextTo(state, ['>', 0]);
                        //     state.index--, _getToken(state);
                        //     return new SymbolNode(str);
                        // }
                    },

                    [' [<']: {
                        token(state: ParseState): void {
                            const str = nextTo(state, [']', -1], false);
                            state.tokenType = TOKENTYPE.SYMBOL;
                            state.token = str;
                        },
                        // context: [_parseAccessors],
                        // parse(state: ParseState): MathNode {
                        //     const str = nextTo(state, [']', 0]);
                        //     state.index--, _getToken(state);
                        //     return new SymbolNode(str);
                        // }
                    }
                }
            )

            function hook<Func extends ParseFuncs>(func: Func): Func {
                return (parseHooker as typeof parseHooker<Func>).bind(undefined, func) as unknown as Func;
            }

            parser.parseEnd = hook(_parseEnd);
            parser.getToken = hook(_getToken);
        }

        namespace Transform {
            export const { parser, math, MaypipeTypes } = Parser;

            enum Trait {
                Init = 0x0000,

                /**
                 * reset node trait as init
                 */
                Reset = 0x0001,

                /**
                 * let nodes be until reset
                 */
                Keep = 0x0002,

                /**
                 * force all sub ident nodes to be built as fielder replacement
                 */
                Build = 0x0004,

                /**
                 * the sub nodes is a fielder or method,
                 * can be built to fielder replacement
                 */
                Ident = 0x0008,
            }

            const MathNodes: {
                traceOf(node: MathNode, path?: string, prefix?: string): string
                transformOf(node: MathNode): { node: MathNode, trait: Trait }
                fieldOf(node?: MathNode): string | undefined
                traitOf(node?: MathNode): Trait
            } & {
                [type in MathNodes['type']]: {
                    transform?(node: Extract<MathNodes, { type: type }>): { node: MathNode, trait: Trait };
                    fieldOf?(node: Extract<MathNodes, { type: type }>): string | undefined;
                    trait?: Trait | { (node: Extract<MathNodes, { type: type }>): Trait };
                    trace?(node: Extract<MathNodes, { type: type }>): string;
                }
            } = {
                transformOf(node: MathNode): { node: MathNode, trait: Trait } {
                    const transform = MathNodes[(node as MathNodes).type]?.transform;
                    return transform?.(node as any) ?? { node, trait: MathNodes.traitOf(node) };
                },
                traceOf(node: MathNode, path?: string, prefix: string = ''): string {
                    const trace = MathNodes[(node as MathNodes).type]?.trace;
                    const adt = trace?.(node as any) ?? '';
                    const exp = node?.toString() ?? '';
                    const type = node?.type ?? '';
                    path = path ?? '';

                    return `${prefix}path:${path}; type:[${type}, ${adt}]; exp:${exp}`;
                },
                traitOf(node?: MathNode): Trait {
                    if (!node) return Trait.Reset | Trait.Build;
                    const trait = MathNodes[(node as MathNodes).type]?.trait ?? Trait.Reset | Trait.Build;
                    return _.isFunction(trait) ? trait(node as any) : trait;
                },
                fieldOf(node?: MathNode): string | undefined {
                    return MathNodes[(node as MathNodes)?.type ?? '']?.fieldOf?.(node as any);
                },

                BlockNode: {
                    trait: Trait.Reset
                },
                AssignmentNode: {
                    trait: Trait.Reset | Trait.Build
                },
                FunctionAssignmentNode: {
                    trait: Trait.Reset | Trait.Build
                },
                ConditionalNode: {
                    trait: Trait.Reset | Trait.Build
                },
                RelationalNode: {
                    trait: Trait.Reset | Trait.Build
                },
                RangeNode: {
                    trait: Trait.Reset | Trait.Build
                },
                FunctionNode: {
                    trait: Trait.Keep | Trait.Build
                },
                ArrayNode: {
                    trait: Trait.Reset | Trait.Build
                },
                ObjectNode: {
                    trait: Trait.Reset | Trait.Build
                },
                ParenthesisNode: {
                    trait: Trait.Reset | Trait.Build
                },
                OperatorNode: {
                    trace(node: OperatorNode): string {
                        return node.op;
                    },
                    trait(node: OperatorNode<any, any>): Trait {
                        const { op, implicit } = node;
                        const isIdent = (op === '*' && implicit);
                        return isIdent ? Trait.Ident : Trait.Reset | Trait.Build;
                    },
                    transform(node: OperatorNode): { node: MathNode, trait: Trait } {
                        const trait = MathNodes.traitOf(node)
                        if (Trait.Ident !== trait) return { node, trait };

                        const hs = _.map(node.args, arg => arg.toString());
                        return { node: new SymbolNode(hs.join('')), trait };
                    }
                },
                SymbolNode: {
                    trait: Trait.Ident,
                    trace(node: SymbolNode): string {
                        return node.name;
                    },
                    fieldOf(node: SymbolNode): string | undefined {
                        return node.toString();
                    }
                },
                ConstantNode: {
                    trait: Trait.Ident,
                    trace(node: ConstantNode): string {
                        return node.value.toString();
                    }
                },
                AccessorNode: {
                    trait: Trait.Ident,
                    fieldOf(node: AccessorNode): string | undefined {
                        return MathNodes.fieldOf(node.object);
                    }
                },
                IndexNode: {
                    trait(node: IndexNode): Trait {
                        if (node.dotNotation) {
                            return Trait.Ident;
                        }

                        return Trait.Reset | Trait.Build;
                    }
                }
            }

            export class Handler {
                constructor(
                ) {
                }

                /**
                 * build method object self, ex: 
                 *      a.b(...); return ExpTree represented a.b
                 * NOTE:
                 *   1. must skip function assigment
                 *   2. must skip variable definition
                 * 
                 * CTQ:
                 *  1. if method is translate liked globally, use it
                 *  2. if method is local defined function, use it
                 *  3. if method is mathjs defined function, use it
                 *  4. if method is loadash defined function, use it
                 *  5. otherwise, error prompt
                 */
                buildFunc(node: MathNode): MathNode {
                    const exp = node.toString();

                    if (_.isFunction(_.get(math, exp))) {
                        // has defined in the math engine
                        return node;
                    }

                    if (exp.charAt(0) === '$') {
                        return node;
                    }

                    return parser(`$actions.${exp}`);
                }

                /**
                 * NOTE:
                 *   1. must skip function assigment
                 *   2. must skip variable definition
                 */
                buildField(node: MathNode, defFuncs: FunctionAssignmentNode[]): MathNode {
                    const exp = node.toString();

                    if (exp.charAt(0) === '$') {
                        return node;
                    }

                    const field = MathNodes.fieldOf(node);
                    if (field) {
                        for (const defFunc of [...defFuncs].reverse()) {
                            if (defFunc.params.indexOf(field) >= 0) {
                                // reference function param
                                return node;
                            }
                        }
                    }

                    return parser(`$recordsets.${exp}`);
                }
            }

            export function trace(node?: MathNode, msg: string = "") {
                function _trace(node?: MathNode, path: string = '', depth: number = 0) {
                    if (node == null) return;

                    const log = MathNodes.traceOf(node, path, Indent(depth));
                    const logs: string[] = [log];
                    console.log(log);

                    node.forEach((node, path, parent) => {
                        const sub = _trace(node, path, depth + 1);
                        if (sub) logs.push(sub);
                    })

                    return logs.join('\n');
                }

                console.log(msg);
                return _trace(node);
            }

            export function transform(node: MathNode, handler: Handler): MathNode {
                function buildHandler(node: MathNode, asFunc: boolean, defFuncs: FunctionAssignmentNode[]): MathNode {
                    return asFunc ? handler.buildFunc(node) : handler.buildField(node, defFuncs);
                }

                function traverse(node: MathNode, defFuncs: FunctionAssignmentNode[], path: string = '', parent?: MathNode): { node: MathNode, trait: Trait } {
                    if (node.type === 'OperatorNode') {
                        const { args: [arg1, arg2], fn, implicit, op } = node as OperatorNode;
                        if (op === '|' && fn === 'bitOr' && implicit === false) {
                            // maybe a pipe statement, check it
                            if (arg2.type in MaypipeTypes) {
                                node = Object.setPrototypeOf({
                                    get fn() {
                                        return 'bitOrPipe'
                                    }
                                }, node);
                            }
                        }
                    }

                    let _strait: Trait = Trait.Init;
                    node.forEach((node, path, parent) => {
                        defFuncs = [...defFuncs];
                        if (node.type === 'FunctionAssignmentNode') defFuncs.push(node as FunctionAssignmentNode);
                        const { node: _node, trait: _trait } = traverse(node, defFuncs, path, parent);
                        if (_node != node) _.set(parent, path, _node);
                        _strait = _strait | _trait;
                    })

                    const _ptrait = MathNodes.traitOf(parent);
                    const _transed = MathNodes.transformOf(node);
                    let _trait = _transed.trait | _strait;
                    node = _transed.node;

                    const _isPReset = !!(_ptrait & Trait.Reset);
                    const _isPBuild = !!(_ptrait & Trait.Build);

                    const _isIdent = !!(_trait & Trait.Ident) && (!!!(_trait & Trait.Keep)) && (node.type !== 'ConstantNode');
                    const _isKeep = !!(_trait & Trait.Keep) && !_isPReset;

                    if (_isPBuild && _isIdent) {
                        // force build the fielder or method
                        node = buildHandler(node, path === 'fn' && parent?.type === 'FunctionNode', defFuncs);
                    }

                    if (_isPReset) {
                        _trait = Trait.Init;
                    }

                    if (_isKeep) {
                        _trait = Trait.Keep;
                    }

                    return { node, trait: _trait };
                }

                return traverse(node as MathNodes, []).node;
            }
        }

        function make$Scope(ctx: any) {
            if (!_.isObject(ctx)) return {};
            return _.transform(ctx, (r, v, k) => {
                r[`$${k}`] = v;
            }, {})
        }

        export function build(expression: string): Handler | undefined {
            const { transform, Handler } = Transform;
            const { trace, parser, } = Transform;
            const expraw = expression;

            // unescape and tranform the ${ ... } to parsable expression.
            expression = Unescape.build(expression);

            // parse the expression by mathjs
            let node = parser(expression);
            // const parsed = `${expraw}\n${trace(node, `parsed: ${expraw}`)}`;

            // transform the ExpTree to handler formatted
            const handler = new Handler();
            node = transform(node, handler);
            // const transed = `${expraw}\n${trace(node, `transed: ${expraw}`)}`;

            // build final function
            const mathfunc = node.compile();

            return _.extend(
                (ctx: any, scope: { [k: string]: any } = {}) => {
                    const _node = node;

                    try {
                        // const transed = `${expraw}\n${trace(node, `transed: ${expraw}`)}`;
                        const _$param = ctx?.['actions']?.$param;
                        const $param = (_$param ? { $param: _$param } : {});
                        scope = { ...make$Scope(ctx), ...$param, ...scope };
                        return mathfunc.evaluate(scope);
                    } catch (e) {
                        console.log(e);
                    }
                }
            )
        }
    }

    export namespace API {
        /**
         * TODO: interpolation expression with patter {{  }}
         * 1. `xxx${***}zzz`: interpret each ***, and format final string
         * 2. localize functions for context based node
         * 3. $***: translate
         */

        const ExpCache: {
            [k: string]: Handler
        } = {}

        export function build(expression: string): Handler | undefined {
            if (!is(expression)) return;

            try {
                const cache = ExpCache[expression];
                if (cache) return cache;

                const handler = Executor.build(expression);
                if (!handler) return;

                return ExpCache[expression] = handler;
            } catch (e) {
                console.error('failed to build expression: ' + expression, '; error: ', e)
            }
        }

        export function is(val: any): val is Expression {
            return _.isString(val) && Pattern.test(val)
        }
    }
}

namespace _Uniq {
    interface ISlot {
        namedidx: { [P: string]: string },
        symbols: { [P: string]: symbol },
        uuid: { [P: string]: string },
        idx: { [P: string]: number }
    }

    const Uniqs: ISlot = {
        namedidx: {},
        symbols: {},
        uuid: {},
        idx: {}
    }

    const Namedidx = Uniqs.namedidx;
    const Symbols = Uniqs.symbols;
    const Uuid = Uniqs.uuid;
    const Idx = Uniqs.idx;

    const UniqSlot = _Prop.Slot<ISlot>('UNIQ', parent => {
        return {
            namedidx: {},
            symbols: {},
            uuid: {},
            idx: {},
        }
    })

    type Unipair = {
        m: WeakMap<any, Unipair>
        v?: object,
    }

    const Unipair: Unipair = {
        m: new WeakMap()
    }

    export type guid = string;

    export function uuid(obj?: any, prefix: string = 'uid', hex: boolean = true): string {
        prefix = `${prefix}${hex}`;

        const values = UniqSlot.Of(obj, true)?.uuid ?? Uuid;
        return values[prefix] || (values[prefix] = _Prop.newUuid(hex));
    }

    export function namedidx(obj?: any, prefix: string = 'idx'): string {
        const values = UniqSlot.Of(obj, true)?.namedidx ?? Namedidx;
        return values[prefix] || (values[prefix] = _.uniqueId(prefix));
    }

    export function idx(obj?: any, prefix: string = "idx"): number {
        const values = UniqSlot.Of(obj, true)?.idx ?? Idx;
        return values[prefix] || (values[prefix] = _Prop.newIdx())
    }

    export function symbol(prefix: string, obj?: any): symbol {
        const values = UniqSlot.Of(obj, true)?.symbols ?? Symbols;
        return values[prefix] || (values[prefix] = _Prop.newSymbol(prefix))
    }

    export function unimarker(...args: any[]): object {
        let m = Unipair.m, pair = Unipair;

        for (const key of args) {
            let _pair = m.get(key);

            if (!_pair) {
                _pair = { m: new WeakMap() }
                m.set(key, _pair);
            }

            pair = _pair;
            m = pair.m;
        }

        if (!pair.v) {
            pair.v = Object.create(null);
        }

        return pair.v!;
    }
}

namespace _Fielder {
    type IPath = Fielder.IPath;
    type ISourcor = Fielder.ISourcor;
    type Options<TOption> = Fielder.Options<TOption>;
    type IFielder<THost extends object, TOption> = Fielder.IFielder<THost, TOption>;
    type IHandler<THost extends object, TOption> = Fielder.IHandler<THost, TOption>;

    namespace _Parser {
        /**
            ^\s*
            (?:\.*(?<dpath>[\w\._$]+?)\.*)                                                  // DPath
            (?:
                (?:
                    (?:(?<mo>\[)(?:<(?:\.*(?<mopath>[\w\._$]+?)?\.*)>)?(?<osep>.*)\])       // MOPath
                    |
                    (?:(?<so><)(?:\.*(?<sopath>[\w\._$]+?)?\.*)>)                           // SOPath
                )
                (?:\.*(?<ipath>[\w\._$]+?)?\.*)                                             // IPath
                ?
            )?
            \s*$
    
            ^\s*(?:\.*(?<dpath>(?:[\w\._$]+?)|(?:[\w\._$]+?\(.+\))|(?:\(.+\)))\.*)(?:(?:(?:(?<mo>\[)(?:<(?:\.*(?<mopath>(?:[\w\._$]+?)|(?:[\w\._$]+?\(.+\))|(?:\(.+\)))?\.*)>)?(?<osep>.*)\])|(?:(?<so><)(?:\.*(?<sopath>(?:[\w\._$]+?)|(?:[\w\._$]+?\(.+\))|(?:\(.+\)))?\.*)>))(?:\.*(?<ipath>(?:[\w\._$]+?)|(?:[\w\._$]+?\(.+\))|(?:\(.+\)))?\.*)?)?\s*$
    
            test cases:
             log{x=="y"}.path{x=="y"}.{x=="y"}
             log.path[<aa.bb.cc>,].name.age
             log.path[<aa.bb.cc>,]name.
             .log.path...[<aa.bb.cc>,]....
             log.path[<aa.bb.cc>,]
             log.path[<>,]
             log.path[,]
             log.path[]
             log.path<aa.bb.cc>.name.age
             log.path<aa.bb.cc>.name.
             log.path<aa.bb.cc>.
             log.path<aa.bb.cc>
             log.path<>
             log.path
             log.
             log
        */

        const Ident = `[\\w_\\-$]+`; // ~!@#%^*
        const Cond = `\\{[^\\{\\}]*\\}`;
        const Path = (name: string, optinal: boolean = true): string => {
            const opt = `${optinal ? '?' : ''}`, ident = `(?:(?:${Ident})|(?:${Ident}${Cond})|(?:${Cond}))`;
            return `(?:\\.*(?<${name}>${ident}|(?:${ident}(?:\\.+${ident})+))${opt}\\.*)`;
        };

        const MOPath = `(?:(?<mo>\\[)(?:<${Path('mopath')}>)?(?<osep>.*)\\])`, SOPath = `(?:(?<so><)${Path('sopath')}>)`;
        const DPath = `${Path('dpath', false)}`, IPath = `${Path('ipath')}`, OPath = `(?:${MOPath}|${SOPath})`;
        const RegPathCond = new RegExp(`\\s*\\.*(?<Path>${Ident})?(?:\\{(?<Cond>[^\\{\\}]*)\\})?\\s*`, 'ig');
        const RegPath = new RegExp(`^\\s*${DPath}(?:${OPath}${IPath}?)?\\s*$`, 'i');
        const RegDots = /\.{2,}/i;

        export function parsePath(path: string): IPath | undefined {
            const { groups } = RegPath.exec(path) || {};
            if (!groups) return;

            // parse path parts.
            let { dpath = '', mo = '', mopath = '', osep = '', so = '', sopath = '', ipath = '' } = groups;
            mopath = mopath.replace(RegDots, '.'), sopath = sopath.replace(RegDots, '.');
            dpath = dpath.replace(RegDots, '.'), ipath = ipath.replace(RegDots, '.');

            // build standard path, return registered handler if have
            const opath = (mopath || sopath), option = !!(mo || so), multi = !!mo;
            const ol = option ? '<' : '', or = option ? '>' : '';
            const ml = multi ? '[' : '', mr = multi ? ']' : '';

            let stdpath = `${ol}${opath}${or}`;
            stdpath = `${ml}${stdpath}${osep}${mr}`;
            stdpath = `${dpath}${stdpath}${ipath}`;

            return {
                rawpath: path,
                stdpath,
                dpath,
                option,
                multi,
                opath,
                osep,
                ipath
            }
        }

        export function parseCond(path: string): IterableIterator<RegExpExecArray> {
            return path.matchAll(RegPathCond);
        }
    }

    namespace _Builder {
        /**
         * General relation:
         *  1. [path] => IHandler
         *  2. [source] => ISourcor: (path): IHandler
         *  2. source[path] => IHandler
         *  3. object[path] => <source, IFielder>
         * 
         *  IFielder
         *     └->IHandler
         */

        interface IAccessor {
            setter(obj: object | undefined, val: any): void,
            getter(obj: object | undefined): any,
        }

        interface ICondChecker {
            (val: any): boolean
        }

        class Handlers {
            private readonly handlers: {
                [path: string]: IHandler<any, any>
            } = {}

            constructor(public source?: object) {
            }

            get<
                THost extends object, TOption
            >(path: string): IHandler<THost, TOption> | undefined {
                return this.handlers[path];
            }

            set<
                THost extends object, TOption
            >(handler: IHandler<THost, TOption>, ...paths: string[]): Handlers {
                for (const path of paths) {
                    this.handlers[path] = handler;
                }

                return this;
            }

            static readonly global: Handlers = new Handlers();
        }

        /**
         * handle the binding: object[path]=> <source, fielder>
         */
        const FielderSlot = _Prop.Slot<{
            setFielder<THost extends object, TOption>(
                path: string, source: object | undefined, fielder: IFielder<THost, TOption>
            ): void,
            getFielder<THost extends object, TOption>(
                path: string, source: object | undefined
            ): IFielder<THost, TOption> | undefined,
        }>('FIELDER', (parent) => {
            type TSlot = Exclude<typeof parent, undefined>;
            if (parent) return parent;

            const paths: { [P: string]: WeakMap<object, IFielder<any, any>> } = {};
            const SourceKey = (source?: object): object => {
                return source ?? SourceKey;
            }

            const slot: TSlot = {
                setFielder<
                    THost extends object, TOption
                >(path: string, source: object | undefined, fielder: IFielder<THost, TOption>): void {
                    const sourcekey = SourceKey(source);
                    const bindmap = (paths[path] || (
                        paths[path] = new WeakMap()
                    ));

                    const current = bindmap.get(sourcekey);
                    if (current && current != fielder) {
                        throw new Error(`the fielder already exist for "${path}", object and source`)
                    }

                    bindmap.set(sourcekey, fielder);
                },
                getFielder<
                    THost extends object, TOption
                >(path: string, source: object | undefined): IFielder<THost, TOption> | undefined {
                    const bindmap = paths[path];
                    if (!bindmap) return;

                    const sourcekey = SourceKey(source);
                    return bindmap.get(sourcekey);
                }
            }

            return slot;
        });

        /**
         * handle the binding: source[path] => IHandler
         */
        const SourcorSlot = _Prop.Slot<ISourcor, object>('SOURCOR', (parent, source: object) => {
            const handlers = new Handlers(source);
            return {
                get source() {
                    return source;
                },
                getHandler<
                    THost extends object, TOption
                >(path: string): IHandler<THost, TOption> | undefined {
                    return _BuildHandler<THost, TOption>(path, handlers);
                }
            }
        })

        const Accessors: {
            [P: string]: IAccessor
        } = {};
        const CondCheckers: {
            [k: string]: ICondChecker
        } = {};

        const DefaultFielderKey = `df${_Prop.newUuid(true)}`;

        function isPrimitive(val: any): boolean {
            const valtype = typeof val;
            return valtype == 'string' || valtype == 'number';
        }

        function CondChecker(cond?: string): ICondChecker | undefined {
            if (!cond) return;

            if (!CondCheckers[cond]) {
                // build expression AST
                const node = mathParse(cond);
                node.traverse((n) => {
                    if (n.type !== "SymbolNode") return;
                    const _n = n as SymbolNode;
                    if (_n.name === '_') return;
                    _n.name = `$.${_n.name}`
                })

                // build expression function
                const exp = node.toString();
                CondCheckers[cond] = new Function(
                    '_, $', `return ${exp}`
                ).bind(null, _);
            }

            return CondCheckers[cond];
        };

        function BuildAccessor(path: string, multi: boolean = false): IAccessor {
            const akey = `${multi ? `MULTI${BuildAccessor.$cid}:` : ''}${path}`;
            let accessor = Accessors[akey];
            if (accessor) return accessor;

            // split the path
            const pathsegs: { path: string, cond: string, code: string }[] = [];
            for (const g of _Parser.parseCond(path)) {
                const { groups: { Path: path, Cond: cond } = {} } = g;
                if (!path && !cond) break;

                const code = '';
                pathsegs.push({
                    path, cond, code
                });
            }

            pathsegs.forEach((value, idx, array) => {
                const { path, cond } = value;

                if (!multi || (idx != array.length - 1) || !cond) {
                    value.code = `
                        if (obj == null) return;
                        ${cond ? `
                        const checker = condchecker("${cond}");
                        if (checker && !checker(obj)) return;
                        ` : ''}
                        ${path ? `obj = obj.${path};` : ''}
                    `;

                    return;
                }

                value.code = `
                    if (obj == null || !Array.isArray(obj)) return;
                    const checker = condchecker("${cond}");
                    obj = obj.reduce(function(objs, o) {
                        if (!checker || checker(o)) {
                            objs.push(${path ? `o.${path}` : `o`});
                        }

                        return objs;
                    }, []);
                `;
            })

            // build path informations
            const { length: len } = pathsegs;
            const last = pathsegs[pathsegs.length - 1];
            const { path: lpath, cond: lcond } = last || {};
            const pathnames = pathsegs.map(p => p.path).filter(p => !!p);
            const funcname = pathnames.map(_.capitalize).join('');

            const getfs = `
                function get${funcname}(condchecker, obj) {
                    if (obj == null) return;

                    try {
                        ${pathsegs.map(p => p.code).join('\n')}
                        return obj;
                    } catch(e) {
                        console.log(e)
                    }
                }
            `;

            const setfs = `
                function set${funcname}(condchecker, obj, val) {
                    if (obj == null) return;

                    try {
                        ${pathsegs.slice(0, len - 1).map(p => p.code).join('\n')}
                        if (obj == null) return;

                        ${lcond ? `
                        const checker = condchecker("${lcond}");
                        if (checker && !checker(obj)) return;
                        ` : ''}

                        ${lpath ? (multi ? `
                        const value = obj.${lpath};
                        if (!value) return;

                        const len = value.length;
                        value.splice(0, len, ...val);
                        ` : `
                        obj.${lpath} = val;
                        `) : `
                        return;
                        `}
                    } catch(e) {
                        console.log(e)
                    }
                }
            `;

            accessor = (Accessors[akey] = (new Function(`
                return {
                    setter: ${setfs},
                    getter: ${getfs},
                }
            `))());

            const { getter, setter } = accessor;
            accessor.setter = setter.bind(null, CondChecker);
            accessor.getter = getter.bind(null, CondChecker);
            return accessor;
        }

        function _BuildHandler<
            THost extends object, TOption
        >(path: string, handlers: Handlers): IHandler<THost, TOption> | undefined {
            const _currawhandler = handlers.get<THost, TOption>(path);
            if (_currawhandler) return _currawhandler;

            const paths = _Parser.parsePath(path);
            if (paths == null) return;

            const { stdpath, dpath, option, multi, opath, osep, ipath } = paths;
            const _curstdhandler = handlers.get<THost, TOption>(stdpath);
            if (_curstdhandler) return _curstdhandler;

            // not yet register, build it
            const da = BuildAccessor(dpath, multi), oa = BuildAccessor(opath), ia = BuildAccessor(ipath);
            const { getter: dget, setter: dset } = da, { getter: oget } = oa, { getter: iget } = ia;

            const handler = {
                getFielder<
                    RHost extends object = THost, ROption = TOption
                >(obj: RHost, source?: object): IFielder<RHost, ROption> {
                    source = source || handlers.source;
                    const slot = FielderSlot.Of(obj, true);
                    const fielder = slot.getFielder<RHost, ROption>(stdpath, source);
                    if (fielder) return fielder;

                    const _handler = handler as any as IHandler<RHost, ROption>;
                    const { value: hvalue, option: hoption } = _handler;
                    const { setFieldValue: hsetFieldValue } = _handler;
                    const { getFieldValue: hgetFieldValue } = _handler;

                    const temp: {
                        field?: { value: any },
                        option?: {
                            readonly options: Options<ROption> | undefined;
                            value(obj: ROption): any;
                        }
                    } = {};

                    const _fielder: IFielder<RHost, ROption> = {
                        get value(): any {
                            return hvalue(obj, source);
                        },

                        get option(): {
                            readonly options: Options<ROption> | undefined;
                            value(option: ROption): any;
                        } | undefined {
                            if (!option || !hoption) return;

                            const _option = temp.option;
                            if (_option) return _option;

                            return (temp.option = {
                                get options(): Options<ROption> | undefined {
                                    return hoption?.options(source);
                                },
                                value(obj: ROption): any {
                                    return hoption?.value(obj);
                                }
                            });
                        },

                        get field(): { value: any } {
                            const field = temp.field;
                            if (field) return field;

                            return (temp.field = {
                                set value(val: any) {
                                    hsetFieldValue(obj, val);
                                },
                                get value(): any {
                                    return hgetFieldValue(obj);
                                },
                            })
                        },

                        get source() {
                            return source;
                        },

                        handler: _handler,
                        path: paths,
                        host: obj,
                    }

                    slot.setFielder<RHost, ROption>(stdpath, source, _fielder);
                    return _fielder;
                },

                value(obj: THost, source?: object): any {
                    if (path == DefaultFielderKey) return;
                    source = source || handlers.source;
                    let dv = dget(obj);

                    if (dv == null || !option) {
                        return dv;
                    }

                    const { option: hoption } = handler;
                    const _source = hoption?.options(source!)!;
                    const _indexed = _source?.indexed;

                    if (!multi) {
                        dv = isPrimitive(dv) ? dv : dv?.id;
                        dv = _indexed && _indexed[dv];
                        return iget(dv);
                    }

                    const vals = _.castArray(dv).map(_dv => {
                        _dv = isPrimitive(_dv) ? _dv : _dv?.id;
                        _dv = _indexed && _indexed[_dv];
                        return iget(_dv)
                    })

                    return osep ? vals.join(osep) : vals;
                },

                option: option ? {
                    value(option: TOption): any {
                        if (path == DefaultFielderKey) return;
                        return iget(option as object);
                    },

                    options(source?: object): Options<TOption> | undefined {
                        if (path == DefaultFielderKey) return;
                        source = source || handlers.source;
                        return oget(source);
                    }
                } : undefined,

                getFieldValue(obj: THost): any {
                    if (path == DefaultFielderKey) return;
                    return dget(obj);
                },

                setFieldValue(obj: THost, val: any): void {
                    if (path == DefaultFielderKey) return;
                    dset(obj, val);
                },

                get source() {
                    return handlers.source;
                },

                path: paths,
            };

            handlers.set(handler, path, stdpath);
            return handler;
        }

        export function BuildHandler<
            THost extends object, TOption
        >(path: string): IHandler<THost, TOption> | undefined {
            return _BuildHandler<THost, TOption>(path, Handlers.global);
        }

        export function BuildSourcor(source: object): ISourcor {
            return SourcorSlot.Of(source, true);
        }

        export const DefaultHandler: IHandler<any, any> = BuildHandler(DefaultFielderKey)!;
        export const DefaultFielder: IFielder<any, any> = DefaultHandler.getFielder(DefaultHandler)!;
    }

    export const DefaultHandler = _Builder.DefaultHandler;
    export const DefaultFielder = _Builder.DefaultFielder;
    export const Fielder: Fielder = {
        getSourcor(source: object): ISourcor {
            return _Builder.BuildSourcor(source);
        },

        getHandler<
            THost extends object = object, TOption = any
        >(path: string, source?: object): IHandler<THost, TOption> | undefined {
            if (!source) return _Builder.BuildHandler<THost, TOption>(path);
            return _Builder.BuildSourcor(source).getHandler(path);
        },

        getFielder<
            THost extends object = object, TOption = any
        >(obj: THost, path: string, source?: object): IFielder<THost, TOption> | undefined {
            if (!source) return _Builder.BuildHandler<THost, TOption>(path)?.getFielder(obj);
            return _Builder.BuildSourcor(source).getHandler(path)?.getFielder(obj);
        },

        get source() {
            return undefined;
        }
    }
}

namespace _Schema {
    type PrimitiveValue = Schema.Value.PrimitiveValue;
    type PrimitiveType = Schema.Value.PrimitiveType;
    type ValueHandlers = Schema.Value.Handlers;

    type ISchemasset = Schema.ISchemasset;
    type ISchemaset = Schema.ISchemaset;
    type IHeaderset = Schema.IHeaderset;

    type ISchemaType = Schema.ISchema.Type;
    type IBase = Schema.ISchema.Base;
    type ISchema = Schema.ISchema;
    type IHeader = Schema.IHeader;

    type IEntities = Schema.IEntities;
    type IEntity = Schema.IEntity;

    type IRecordset = Schema.IRecordset;
    type IRecord = Schema.IRecord;
    type IValue = Schema.IValue;

    type ISource = Schema.ISource;

    export const ValueHandlers = {
        label: {
            is(val: any): boolean {
                return ValueHandlers.text.is(val);
            },
            convert(val: any): string | undefined {
                return ValueHandlers.text.convert(val);
            },
            init(val: any): string {
                return ValueHandlers.text.init(val);
            }
        },
        text: {
            is(val: any): val is string {
                return _.isString(val);
            },
            convert(val: any): string | undefined {
                return _.isString(val) ? val : val?.toString()
            },
            init(val: any): string {
                return ValueHandlers.text.convert(val) ?? '';
            }
        },
        texts: {
            is(val: any): val is string {
                return ValueHandlers.text.is(val);
            },
            convert(val: any): string | undefined {
                return ValueHandlers.text.convert(val);
            },
            init(val: any): string {
                return ValueHandlers.text.init(val);
            }
        },
        email: {
            is(val: any): val is string {
                return (ValueHandlers.text.is(val) && val.indexOf('@') > 0);
            },
            convert(val: any): string | undefined {
                val = ValueHandlers.text.convert(val);
                if (!ValueHandlers.email.is(val)) return;
                return val;
            },
            init(val: any): string | undefined {
                return ValueHandlers.email.convert(val);
            }
        },
        password: {
            is(val: any): val is string {
                return ValueHandlers.text.is(val);
            },
            convert(val: any): string | undefined {
                return ValueHandlers.text.convert(val);
            },
            init(val: any): string {
                return ValueHandlers.text.init(val);
            }
        },
        number: {
            is(val: any): val is number {
                return _.isNumber(val)
            },
            convert(val: any): number | undefined {
                if (!_.isNumber(val)) {
                    val = Number(val).valueOf();
                }

                if (!Number.isNaN(val)) return;
                return val;
            },
            init(val: any): number | undefined {
                return ValueHandlers.number.convert(val);
            }
        },
        phone: {
            is(val: any): val is string {
                return ValueHandlers.text.is(val);
            },
            convert(val: any): string | undefined {
                return ValueHandlers.text.convert(val);
            },
            init(val: any): string {
                return ValueHandlers.text.init(val);
            }
        },
        bool: {
            is(val: any): val is boolean {
                return _.isBoolean(val ?? false)
            },
            convert(val: any): boolean {
                return Boolean(val).valueOf();
            },
            init(val: any): boolean {
                return ValueHandlers.bool.convert(val);
            }
        },
        date: {
            is(val: any): val is Date {
                if (_.isDate(val)) return true;
                return _.isString(val) && !!val;
            },
            convert(val: any): Date | undefined {
                if (_.isDate(val)) {
                    if (val instanceof Date) return val;
                    return new Date(val);
                }

                return (_.isString(val) || _.isNumber(val)) ? new Date(val) : val?.toDate?.();
            },
            init(val: any): Date | undefined {
                return ValueHandlers.date.convert(val);
            }
        },
        json: {
            is(val: any): val is JSON.Type {
                if (!ValueHandlers.text.is(val)) return true;
                return true; // TODO
            },
            convert(val: any): JSON.Type {
                if (val == null) return val;
                if (_.isObject(val)) return JSON.stringify(val);
                return val;
            },
            init(val: any): JSON.Type {
                return ValueHandlers.json.is(val) ? val : undefined;
            }
        },
    }

    export function isPrimitiveType(type?: string): type is PrimitiveType {
        return !!ValueHandlers[(type ?? '') as PrimitiveType];
    }

    export function isPrimitiveValue(value: any): value is PrimitiveValue;
    export function isPrimitiveValue(type: string, value: any): value is PrimitiveValue;
    export function isPrimitiveValue<Type extends PrimitiveType>(type: Type, value: any): value is Exclude<ReturnType<ValueHandlers[Type]['convert']>, undefined>;
    export function isPrimitiveValue(typeorvalue: any, value?: any): boolean {
        if (arguments.length == 1) {
            for (let key in ValueHandlers) {
                const handler = ValueHandlers[key as PrimitiveType];
                if (handler.is(typeorvalue)) {
                    return true;
                }
            }
        }

        return ValueHandlers[(typeorvalue ?? '') as PrimitiveType]?.is(value);
    }

    export function convert(value: any): PrimitiveValue;
    export function convert(type: string, value: any): PrimitiveValue;
    export function convert<Type extends PrimitiveType>(type: Type, value: any): Exclude<ReturnType<ValueHandlers[Type]['convert']>, undefined>;
    export function convert(typeorvalue: any, value?: any): any {
        if (arguments.length == 1) {
            for (let key in ValueHandlers) {
                const handler = ValueHandlers[key as PrimitiveType];
                const val = handler.convert(typeorvalue)
                if (val != undefined) {
                    return val;
                }
            }
        }

        return ValueHandlers[(typeorvalue ?? '') as PrimitiveType]?.convert(value);
    }

    export function init(value: any): PrimitiveValue;
    export function init(type: string, value: any): PrimitiveValue;
    export function init<Type extends PrimitiveType>(type: Type, value: any): Exclude<ReturnType<ValueHandlers[Type]['convert']>, undefined>;
    export function init(typeorvalue: any, value?: any): any {
        if (arguments.length == 1) {
            for (let key in ValueHandlers) {
                const handler = ValueHandlers[key as PrimitiveType];
                const val = handler.init(typeorvalue)
                if (val != undefined) {
                    return val;
                }
            }
        }

        return ValueHandlers[(typeorvalue ?? '') as PrimitiveType]?.init(value);
    }

    export function typeOf(schema?: ISchema | IHeader): ISchemaType {
        const { type: stype, children } = schema || {};

        if (stype == null) return '';
        if (Array.isArray(stype)) {
            return 'select';
        }

        const isref = stype.indexOf('<') >= 0;
        const isrefs = stype.indexOf('[') >= 0;
        if (!(isref || isrefs)) {
            if (isPrimitiveType(stype)) {
                return stype;
            }

            return '';
        }

        if (children) {
            return 'tree'
        }

        if (isrefs) return 'options';
        if (isref) return 'option';
        return '';
    }

    export namespace Validate {

        function checkfield(value: IValue, schema: ISchema, compact: boolean, source?: ISource): {
            host: IValue
        } | undefined {
            const { type: stype, deps: sdeps, flds: sflds, optional: soptional } = schema;
            const skey = schema.key.toString();
            const fieldtype = typeOf(schema);
            let vkey = compact ? skey : '';

            if (compact) {
                if (sdeps || sflds) {
                    value = _.takeIf(_.isObject, value[vkey]) as IValue, vkey = '';
                }
            }

            compact = !!vkey;

            switch (fieldtype) {
                case 'select': {
                    if (!vkey) {
                        vkey = 'value.key';
                    }

                    const selvalue = (compact ? value[vkey] : value) as IValue;
                    const selkey = (isPrimitiveValue(selvalue) ? selvalue : (
                        selvalue?.value as IValue
                    )?.key) as string;

                    const _stype = stype as ISchemaset;
                    const sschema = _stype.indexed[selkey];
                    if (!sschema) {
                        if (soptional) {
                            return { host: value }
                        }

                        return;
                    }

                    const subvalue = !isPrimitiveValue(selvalue) ? selvalue : {
                        value: { key: selkey }
                    } as IValue;

                    if (!checkschema(subvalue.value as IValue, sschema, false, source)) {
                        return;
                    }

                    if (checkexpect(sschema, selvalue)) {
                        return;
                    }

                    return { host: value }
                }

                case 'options': {
                    if (!vkey) {
                        vkey = 'value';
                    }

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}`);
                    const fielder = Fielder.getFielder<IRecord>(value, _stype, source?.recordsets);
                    const { path: { opath = '' } = {} } = fielder || {};

                    const eschema = source?.entities[opath];
                    const selvalue = (_.takeIf(_.isArray, value[vkey]) || []) as IRecord[];
                    if (!eschema || selvalue.findIndex(s => s?.key !== eschema?.key) >= 0) {
                        return;
                    }

                    const selvalues = (fielder?.field.value || []) as IRecord[];
                    if (selvalues.length == 0) {
                        if (!soptional) {
                            return;
                        }
                    }

                    return { host: value }
                }

                case 'option': {
                    if (!vkey) {
                        vkey = 'value';
                    }

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}.id`);
                    const fielder = Fielder.getFielder<IRecord>(value, _stype, source?.recordsets);
                    const { path: { opath = '' } = {} } = fielder || {};

                    const eschema = source?.entities[opath];
                    const selvalue = _.takeIf(_.isObject, value[vkey]) as IValue;
                    if (!eschema || selvalue?.key !== eschema?.key) {
                        return;
                    }

                    const refid = fielder?.field.value;
                    const options = fielder?.option?.options as Fielder.Options<IRecord>;
                    if (options && (options.loaded ?? true) && !options?.indexed?.[refid]) {
                        if (!soptional) {
                            return;
                        }
                    }

                    return { host: value }
                }

                case 'tree': {
                    if (!vkey) {
                        vkey = 'value';
                    }

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}`);
                    const fielder = Fielder.getFielder<IRecord>(value, _stype, source?.recordsets);
                    const { path: { opath = '' } = {} } = fielder || {};

                    const eschema = source?.entities[opath];
                    const selvalue = (_.takeIf(_.isArray, value[vkey]) || []) as IRecord[];
                    if (!eschema || selvalue.findIndex(s => s?.key !== eschema?.key) >= 0) {
                        return;
                    }

                    const selvalues = (fielder?.field.value || []) as IRecord[];
                    if (selvalues.length == 0) {
                        if (!soptional) {
                            return;
                        }
                    }

                    return { host: value }
                }

                case '': {
                    return { host: value };
                }

                default: {
                    if (!vkey) { vkey = 'value'; }

                    const _stype = stype as PrimitiveType;
                    if (!soptional && !isPrimitiveValue(_stype, value[vkey])) {
                        return;
                    }

                    if (checkexpect(schema, value[vkey])) {
                        return;
                    }

                    return { host: value }
                }
            }
        }

        function checkschema(value: IValue, schema: ISchema, compact: boolean, source?: ISource): boolean {
            const field = checkfield(value, schema, compact, source);
            if (!field) return false;

            const { host } = field;
            const { deps: sdeps = [], flds: sflds = [] } = schema;
            for (const _sdep of sdeps) {
                for (const sdep of (_.isArray(_sdep) ? _sdep : [_sdep])) {
                    const { key: skey, optional: soptional } = sdep;
                    const dep = host.deps?.[skey];

                    if (!dep?.key && soptional) {
                        return true;
                    }

                    if (dep?.key !== skey) {
                        return false;
                    }

                    if (!checkschema(dep, sdep, false, source)) {
                        return false;
                    }
                }
            }

            for (const _sfld of sflds) {
                for (const sfld of (_.isArray(_sfld) ? _sfld : [_sfld])) {
                    if (!checkschema(host, sfld, true, source)) {
                        return false;
                    }
                }
            }

            return true;
        }

        export function validate(value: IValue, schema: ISchema, source?: ISource): boolean {
            return checkschema(value, schema, false, source);
        }

        export function checkexpect(schema: ISchema, value: IValue['value']): string | undefined {
            if (value === undefined || _.isArray(value) || (_.isObject(value) && !_.isDate(value))) return;

            const def = _.isObject(schema.expect) && !_.isArray(schema.expect) && !_.isDate(schema.expect) ? schema.expect : {
                value: schema.expect, regex: undefined, errmsg: "general.invalidinput",
            };

            const { value: expect, regex, errmsg } = def;
            if (expect !== undefined && (_.isArray(expect) ? !expect.includes(value as any) : expect != value)) {
                return errmsg;
            }

            if (!regex) return;
            const _regex: RegExp = !((regex as any) instanceof RegExp) ? (
                def.regex = RegExp(regex) as any
            ) : (
                regex as any as RegExp
            )

            if (!_regex.test(String(value))) {
                return errmsg;
            }
        }
    }

    export namespace Parser {
        function parsefield(value: { paths: string[], valuepath: string[], cond?: string }, schema: ISchema, compact: boolean, source?: ISource): {
            valuepath: string[],
            schema: ISchema,
            paths: string[]
        } | undefined {
            const { type: stype, deps: sdeps, flds: sflds } = schema;
            const { paths, valuepath, cond } = value;
            const skey = schema.key.toString();
            const fieldtype = typeOf(schema);
            let vkey = compact ? skey : '';

            if (compact) {
                if (sdeps || sflds) {
                    valuepath.push(vkey), vkey = '';
                }
            }

            switch (fieldtype) {
                case 'select': {
                    const [p1] = paths;

                    if (p1 && p1 != '*') {
                        if (!vkey) {
                            valuepath.push(`value{key=='${skey}'}`)
                        }

                        const _stype = stype as ISchemaset;
                        const selschema = _stype.indexed[p1];
                        if (!selschema) return;

                        return parseschema({
                            cond: `{key=='${p1}'}`,
                            paths: paths.slice(1),
                            valuepath,
                        }, selschema, false, source);
                    }

                    if (!vkey) {
                        vkey = `value${cond || ''}.key`;
                    }

                    valuepath.push(vkey);

                    return {
                        paths: p1 ? paths : paths.slice(1),
                        valuepath,
                        schema,
                    }
                }

                case 'options': {
                    if (!vkey) {
                        vkey = `value{key=='${skey}'}`;
                    }

                    valuepath.push(vkey);

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}`);
                    const handler = Fielder.getHandler(_stype);
                    const { path: { opath = '' } = {} } = handler || {};
                    const eschema = source?.entities[opath];
                    if (!eschema) return;

                    valuepath.push(_stype.replace(/^[^\\[<]*/i, `{key=='${eschema?.key}'}`));
                    return { valuepath, schema: schema, paths }
                }

                case 'option': {
                    if (!vkey) {
                        vkey = `value{key=='${skey}'}`;
                    }

                    valuepath.push(vkey);

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}.id`);
                    const handler = Fielder.getHandler(_stype);
                    const { path: { opath = '' } = {} } = handler || {};
                    const eschema = source?.entities[opath];
                    if (!eschema) return;

                    valuepath.push(_stype.replace(/^[^\\[<]*/i, `id{key=='${eschema?.key}'}`));
                    return { valuepath, schema: schema, paths }
                }

                case 'tree': {
                    if (!vkey) {
                        vkey = `value{key=='${skey}'}`;
                    }

                    valuepath.push(vkey);

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}`);
                    const handler = Fielder.getHandler(_stype);
                    const { path: { opath = '' } = {} } = handler || {};
                    const eschema = source?.entities[opath];
                    if (!eschema) return;

                    valuepath.push(_stype.replace(/^[^\\[<]*/i, `{key=='${eschema?.key}'}`));
                    return { valuepath, schema: schema, paths }
                }

                case '': {
                    return;//{ valuepath, schema, paths };
                }

                default: {
                    if (!vkey) {
                        vkey = `value{key=='${skey}'}`;
                    }

                    valuepath.push(vkey);

                    return {
                        valuepath: valuepath.concat(paths),
                        schema, paths: []
                    }
                }
            }
        }

        function parseschema(value: { paths: string[], valuepath: string[], cond?: string }, schema: ISchema, compact: boolean, source?: ISource): {
            valuepath: string[],
            schema: ISchema,
            paths: string[]
        } | undefined {
            const { deps: sdeps, flds: sflds } = schema;
            const { paths, valuepath, cond = '' } = value;
            const [p1, p2, p3] = paths;

            switch (p1) {
                case 'deps': {
                    const sdep = sdeps && sdeps?.indexed[p2];
                    if (!sdep) return;

                    valuepath.push(`${p1}${cond}`, p2);
                    return parseschema({
                        valuepath, paths: paths.slice(2)
                    }, sdep, false, source);
                }

                case 'flds': {
                    const sfld = sflds && sflds?.indexed[p2];
                    if (!sfld) return;

                    cond && valuepath.push(cond);
                    if (p3) valuepath.push(p2);

                    return parseschema({
                        valuepath, paths: paths.slice(2)
                    }, sfld, true, source);
                }
            }

            return parsefield(value, schema, compact, source);
        }

        export function pathOf(path: string, schema: ISchema, source?: ISource): {
            valuepath?: string,
            schema?: ISchema,
            xpaths?: string[],
            paths?: string[],
        } {
            // normalize path:
            //  1. remove whitespace 
            //  2. remove leading/tail '.'; 
            //  3. consecutive '.' replace by '.';
            const paths = path.replace(/^[\s.]+|[\s.]+$|[\s]+/ig, '').replace(/\.+/ig, '.').split('.');
            const value = parseschema({ paths, valuepath: [] }, schema, false, source);
            if (!value) return {};

            const { valuepath: vp, schema: scm, paths: xpaths } = value;
            const pathed = { valuepath: vp.join('.'), schema: scm, paths, xpaths };

            return pathed;
        }
    }

    export namespace Form {
        type IFormControl = Schema.Form.IFormControl;
        type IFormGroup = Schema.Form.IFormGroup;
        type IStage = Schema.Form.IStage;
        type IFielderHandler<
            THost extends object = IValue, TOption = any
        > = Schema.Form.IFielderHandler<THost, TOption>;
        type IFielder<
            RHost extends object = IValue, ROption = any
        > = Schema.Form.IFielder<RHost, ROption>;

        const VALUESLOT = _Prop.Slot<{
            options: Record<string, IValue>,
            fields: Record<string, IFielder>
        }>('VALUEBINDER', () => {
            return { options: {}, fields: {} }
        });

        const FORMSLOT = _Prop.Slot<{
            form?: IFormGroup,
            record?: IRecord
        }>('FORM', () => ({}));

        const FIELDERHANDLERSLOT = _Prop.Slot<{
            [path: string]: IFielderHandler | undefined
        }>('FIELDERHANDLER', () => ({}));

        type IMonoHeader = O.Overwrite<IHeader, { key: string }>;
        type IMultHeader = O.Overwrite<IHeader, { key: string[] }>;

        function getFieldHandler(schema: ISchema, stage: IStage, source?: ISource): IFielderHandler | undefined {
            const { key: skey, type: stype, $cid: scid } = schema;
            const slot = FIELDERHANDLERSLOT.Of(schema, true);
            if (slot[scid]) return slot[scid];

            let { compact = false, fieldpath = '' } = stage || {};
            const { deps: sdeps, flds: sflds } = schema;
            let _valueinit: IFielderHandler['valueinit'];
            let vkey = compact ? skey : '';

            if (compact) {
                fieldpath = `${fieldpath}.${vkey}`;
                if (sdeps || sflds) {
                    _valueinit = (value: IValue): IValue => (
                        value[vkey] = _.takeIf<IValue, any>(
                            _.isObject, value[vkey], { key: skey }
                        )
                    );

                    vkey = '';
                }
            }

            let handler: Fielder.IHandler<object, any> | undefined;
            const type = typeOf(schema);
            let fieldname = fieldpath;
            compact = !!vkey;

            let result: Partial<IFielder> = {
            }

            switch (type) {
                case 'select': {
                    let valueinit = _valueinit;
                    if (!vkey) {
                        valueinit = (value: IValue): IValue => (
                            (value = valueinit?.(value) ?? value).value = (
                                _.isPlainObject(value.value) ? value.value : {}
                            ), value
                        )

                        fieldname = `${fieldname}.value.key`;
                        vkey = 'value.key';
                    }

                    handler = Fielder.getHandler(`${vkey}<>name`, stype as ISchemaset);
                    result = { type, schema, valueinit, fieldname, fieldpath, compact, optionkey: '' };
                    break;
                }

                case 'options': {
                    if (!vkey) {
                        fieldname = `${fieldname}.value`;
                        vkey = 'value';
                    }

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}`);
                    handler = Fielder.getHandler(_stype, source?.recordsets);

                    const { path: { opath } } = handler!;
                    const optionkey = source?.entities[opath]?.key ?? '';
                    const valueinit = (value: IValue): IValue => (
                        (value = _valueinit?.(value) ?? value)[vkey] = (
                            _.takeIf<IValue[], any>(
                                _.isArray, value[vkey], []
                            )
                        ), value
                    )

                    result = { type, schema, valueinit, fieldname, fieldpath, compact, optionkey };
                    break;
                }

                case 'option': {
                    if (!vkey) {
                        fieldname = `${fieldname}.value`;
                        vkey = 'value';
                    }

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}.id`);
                    handler = Fielder.getHandler(_stype, source?.recordsets);

                    const { path: { opath } } = handler!;
                    const optionkey = source?.entities[opath]?.key;
                    const valueinit = (value: IValue): IValue => (
                        (value = _valueinit?.(value) ?? value)[vkey] = (
                            _.takeIf<IValue, any>(
                                _.isObject, value[vkey], { key: optionkey }
                            )
                        ), value
                    )

                    result = { type, schema, valueinit, fieldname: `${fieldname}.id`, fieldpath, compact, optionkey };
                    break;
                }

                case 'tree': {
                    if (!vkey) {
                        fieldname = `${fieldname}.value`;
                        vkey = 'value';
                    }

                    const _stype = (stype as string).replace(/^[^\\[<]*/i, `${vkey}`);
                    handler = Fielder.getHandler(_stype, source?.recordsets);

                    const { path: { opath } } = handler!;
                    const optionkey = source?.entities[opath]?.key;
                    const valueinit = (value: IValue): IValue => (
                        (value = _valueinit?.(value) ?? value)[vkey] = (
                            _.takeIf<IValue[], any>(
                                _.isArray, value[vkey], []
                            )
                        ), value
                    )

                    result = { type, schema, valueinit, fieldname, fieldpath, compact, optionkey };
                    break;
                }

                case '': {
                    if (!vkey) {
                        fieldname = `${fieldname}.$`;
                        vkey = '$';
                    }

                    const valueinit = _valueinit;
                    handler = Fielder.getHandler(vkey, source?.recordsets);
                    result = { type, schema, valueinit, fieldname, fieldpath, compact, optionkey: '' };
                    break;
                }

                default: {
                    if (!vkey) {
                        fieldname = `${fieldname}.value`;
                        vkey = 'value';
                    }

                    handler = Fielder.getHandler(vkey, source?.recordsets);
                    const valueinit = (value: IValue): IValue => (
                        (value = _valueinit?.(value) ?? value)[vkey] = Schema.Value.init(
                            schema.type as string, value[vkey]
                        ), value
                    )

                    result = { type, schema, valueinit, fieldname, fieldpath, compact, optionkey: '' };
                    break;
                }
            }

            let fielder: IFielder<any, any>;
            return slot[scid] = Object.setPrototypeOf({
                ...result,
                getFielder(obj: object, source?: object | undefined): IFielder {
                    return fielder || (fielder = Object.setPrototypeOf(
                        { ...result },
                        handler?.getFielder(obj, source) ?? null
                    ))
                }
            }, handler ?? null);
        }

        function getDynaFieldHandler(path: ReturnType<typeof Parser.pathOf>, tilcreator: IFielderHandler, entity?: IEntity, source?: ISource): IFielderHandler {
            const { paths = [], xpaths = [] } = path;
            const prepath = paths.slice(0, paths.length - xpaths.length).join('.');
            const { fieldpath: tfieldpath, fieldname: tfieldname } = tilcreator;
            const { schema: tschema, type: ttype, path: tpath } = tilcreator;
            const { source: tsource, optionkey: toptionkey } = tilcreator;
            const { valueinit: tvalueinit } = tilcreator;
            const { compact: tcompact } = tilcreator;
            const gISource = source;

            const realfielder = (obj: any, source?: object | undefined): IFielder => {
                let { handler, key } = xpaths.reduce<{
                    handler?: IFielderHandler,
                    key: string | null
                }>(({ handler, key }, xp) => {
                    if (key == null) {
                        return { handler, key };
                    }

                    switch (xp) {
                        case '*': {
                            handler = (handler || fieldHandlerOf({ key, name: '' }, entity, gISource));
                            const xv = handler?.getFielder(obj, source)?.field.value;
                            key = (xv ? `${key}.${xv}` : null);
                            handler = undefined;
                        } break;

                        default: {
                            key = `${key}.${xp}`;
                            handler = undefined;
                        } break;
                    }

                    return { handler, key };
                }, {
                    handler: tilcreator,
                    key: prepath
                });

                if (key == null) { return _Fielder.DefaultFielder; }
                handler = (handler || fieldHandlerOf({ key, name: '' }, entity, gISource));
                return handler?.getFielder(obj, source) ?? _Fielder.DefaultFielder;
            };

            let fielder: IFielder<any, any>;
            const handler: IFielderHandler = {
                getFielder<RHost extends object = IValue, ROption = any>(
                    obj: RHost, source?: object | undefined
                ): IFielder<RHost, ROption> {
                    return fielder || (fielder = {
                        get handler() {
                            return handler;
                        },

                        get path(): Fielder.IPath {
                            return realfielder(obj, source).path;
                        },

                        get option(): {
                            readonly options: Fielder.Options<any> | undefined;
                            value(option: any): any;
                        } | undefined {
                            return realfielder(obj, source).option;
                        },

                        get value(): any {
                            return realfielder(obj, source).value;
                        },

                        get field(): { value: any } {
                            return realfielder(obj, source).field;
                        },

                        get source(): object | undefined {
                            return realfielder(obj, source).source;
                        },

                        get host() {
                            return obj;
                        },

                        get type(): Schema.ISchema.Type | undefined {
                            return realfielder(obj, source).type;
                        },

                        get optionkey(): string | undefined {
                            return realfielder(obj, source).optionkey;
                        },

                        get fieldpath(): string | undefined {
                            return realfielder(obj, source).fieldpath;
                        },

                        get fieldname(): string | undefined {
                            return realfielder(obj, source).fieldname;
                        },

                        get compact(): boolean | undefined {
                            return realfielder(obj, source).compact;
                        },

                        get schema(): ISchema | undefined {
                            return realfielder(obj, source).schema;
                        },

                        get record(): IRecord | undefined {
                            return realfielder(obj, source).record;
                        }
                    })
                },
                value(obj: IValue, source?: object): any {
                    return realfielder(obj, source).value;
                },
                getFieldValue(obj: any): any {
                    realfielder(obj).field.value;
                },
                setFieldValue(obj: any, val: any): void {
                    realfielder(obj).field.value = val;
                },
                source: tsource,
                path: tpath,
                type: ttype,
                optionkey: toptionkey,
                fieldpath: tfieldpath,
                fieldname: tfieldname,
                compact: tcompact,
                schema: tschema,
                valueinit: tvalueinit
            };

            return handler;
        }

        function getMonoFieldHandler(header: IMonoHeader, entity?: IEntity, source?: ISource): IFielderHandler | undefined {
            if (header.type) {
                return getFieldHandler(header, {}, source);
            }

            const path = header.key;

            // schema relative path, entity must be provided atleast
            if (!entity) throw new Error(
                `Invalid entity expected by schema relative path: ${path}`
            )

            // build schema relative fielder creator
            const spath = Parser.pathOf(path, entity, source);
            const { valuepath = '', xpaths = [], schema } = spath;
            const stage: IStage = { compact: false, fieldpath: valuepath };
            const tilhandler = getFieldHandler(schema!, stage, source);
            if (!tilhandler || xpaths.length <= 0) return tilhandler;

            // dynamic path, construct dynamic fielder
            return getDynaFieldHandler(
                spath, tilhandler, entity, source
            )
        }

        function getMultFieldHandler(header: IMultHeader, entity?: IEntity, source?: ISource): IFielderHandler | undefined {
            const { key: xpaths } = header;
            if (xpaths.length == 0) return _Fielder.DefaultHandler;
            if (xpaths.length == 1) return getMonoFieldHandler(
                { ...header, key: xpaths[0] } as IMonoHeader,
                entity, source
            )

            const handlers = xpaths.map(p => {
                return fieldHandlerOf({ ...header, key: p }, entity, source);
            })

            const realfielder = (obj: any, source?: object | undefined): IFielder => {
                for (const handler of handlers) {
                    const fielder = handler?.getFielder(obj, source);
                    if (fielder?.field.value !== undefined) {
                        return fielder;
                    }
                }

                return _Fielder.DefaultFielder;
            }

            let fielder: IFielder<any, any>;
            const handler: IFielderHandler = {
                getFielder<RHost extends object = IValue, ROption = any>(
                    obj: RHost, source?: object | undefined
                ): IFielder<RHost, ROption> {
                    return fielder || (fielder = {
                        get handler() {
                            return handler;
                        },

                        get path(): Fielder.IPath {
                            return realfielder(obj, source).path;
                        },

                        get option(): {
                            readonly options: Fielder.Options<any> | undefined;
                            value(option: any): any;
                        } | undefined {
                            return realfielder(obj, source).option;
                        },

                        get value(): any {
                            return realfielder(obj, source).value;
                        },

                        get field(): { value: any } {
                            return realfielder(obj, source).field;
                        },

                        get source(): object | undefined {
                            return realfielder(obj, source).source;
                        },

                        get host() {
                            return obj;
                        },

                        get type(): Schema.ISchema.Type | undefined {
                            return realfielder(obj, source).type;
                        },

                        get optionkey(): string | undefined {
                            return realfielder(obj, source).optionkey;
                        },

                        get fieldpath(): string | undefined {
                            return realfielder(obj, source).fieldpath;
                        },

                        get fieldname(): string | undefined {
                            return realfielder(obj, source).fieldname;
                        },

                        get compact(): boolean | undefined {
                            return realfielder(obj, source).compact;
                        },

                        get schema(): ISchema | undefined {
                            return realfielder(obj, source).schema;
                        },

                        get record(): IRecord | undefined {
                            return realfielder(obj, source).record;
                        }
                    })
                },
                value(obj: IValue, source?: object): any {
                    return realfielder(obj, source).value;
                },
                getFieldValue(obj: any): any {
                    realfielder(obj).field.value;
                },
                setFieldValue(obj: any, val: any): void {
                    realfielder(obj).field.value = val;
                },
                source: source?.recordsets,
                path: {
                    option: false,
                    multi: false,
                    rawpath: '',
                    stdpath: '',
                    dpath: '',
                    opath: '',
                    osep: '',
                    ipath: ''
                },
            }

            return handler;
        }

        export function fieldHandlerOf(header: IHeader, entity?: IEntity, source?: ISource): IFielderHandler | undefined {
            const { key: hkey, $cid: scid } = header;
            const slot = FIELDERHANDLERSLOT.Of(header, true);
            if (slot[scid]) return slot[scid];

            if (_.isString(hkey)) {
                return slot[hkey] || (slot[hkey] = slot[scid] = getMonoFieldHandler(
                    header as IMonoHeader, entity, source
                ))
            }

            // build multi-path fielder
            const hkeys = hkey.filter(k => !!k);
            const rawpath = hkeys.sort((a, b) => (a < b ? 0 : 1)).join(',');
            return slot[rawpath] || (slot[rawpath] = slot[scid] = getMultFieldHandler(
                header as IMultHeader, entity, source
            ));
        }

        export function fieldOf(record: IRecord | undefined, value: IValue, schema: ISchema, stage: IStage, source?: ISource): IFielder | undefined {
            if (!(_.isObject(value) || _.isArray(value))) {
                return;
            }

            const handler = getFieldHandler(schema, stage, source);
            if (!handler) return;

            const { valueinit, fieldname } = handler;
            value = valueinit?.(value) ?? value;

            const { key: skey, $cid: scid } = schema;
            const fieldkey = `${fieldname}@${skey}@${scid}`;
            const fields = VALUESLOT.Of(value, true).fields;
            if (fields[fieldkey]) return fields[fieldkey];

            const fielder = handler.getFielder(value);
            const { type, optionkey, fieldpath, compact } = handler;
            return fields[fieldkey] = Object.setPrototypeOf({
                record, type, schema, fieldname, fieldpath, compact, optionkey
            }, fielder ?? null);
        }

        export function controlOf(field: IFielder, schema: ISchema, form: IFormGroup): IFormControl {
            const { fieldname } = field, { key: skey, $cid: scid } = schema;
            const controlkey = `${fieldname}@${skey}@${scid}`;
            const curcontrol = form.controls[controlkey];

            if (curcontrol?.field == field && form.$record == field.record) {
                return curcontrol;
            }

            // build the initial value for the formcontrol and track value change following.
            const { $hoster } = form;
            const initvalue = field.field.value;
            let value = initvalue;

            const control: IFormControl = Object.setPrototypeOf({
                defaultValue: initvalue,
                field: field,

                set value(v: any) {
                    value = v;

                    const { dirty, defaultValue } = control;
                    if (dirty && defaultValue == v) {
                        control.markAsPristine();
                    }
                },

                get value() {
                    return value;
                },

                get disabled(): boolean {
                    return control.readonly;
                },

                get readonly(): boolean {
                    return $hoster.isReadonly(field) || schema.readonly || $hoster.readonly;
                },

                commit() {
                    const { dirty } = control;
                    if (!dirty) return;

                    control.defaultValue = value;
                    control.reset(value);
                },

                rollback() {
                    const { dirty } = control;
                    if (!dirty) return;

                    const preval = control.defaultValue;
                    control.reset(preval);
                }
            }, new FormControl(initvalue));

            form.setControl(controlkey, control, {
                emitEvent: true
            });

            // setup the validator
            const valueError: {
                value?: string
            } = {
                value: ""
            }

            control.addValidators((control: AbstractControl): ValidationErrors | null => {
                const { value } = control;

                const error = (valueError.value = $hoster.hasError(field, value));
                if (error) return valueError;

                const errmsg = (valueError.value = Validate.checkexpect(schema, value));
                return errmsg ? valueError : null;
            })

            control.markAsTouched();
            return control;
        }

        export function optionOf(field: IFielder, opt: ISchema, schema: ISchema): IValue | string {
            const { type, deps, flds } = opt, { compact } = field;
            if (compact && (type || deps || flds)) {
                const { key: okey, $cid: ocid } = opt;
                const { key: skey } = schema;
                const { fieldname } = field;
                const host = field.host;

                const optionkey = `${fieldname}@${skey}-${okey}@${ocid}`;
                const optionslot = VALUESLOT.Of(host, true)!.options;
                const optvalue = optionslot[optionkey];
                if (optvalue) return optvalue;

                const svalue = _.takeIf(_.isObject, _.get(
                    host, skey
                ), _.takeIf(
                    _.isEqual(_.get(host, 'key'), skey),
                    host, {}
                ));

                if (_.get(svalue, 'key') === skey && _.get(svalue, 'value.key') === okey) {
                    return optionslot[optionkey] = svalue!;
                }

                const ovalue = _.takeIf(_.isObject, _.get(
                    svalue, 'value'
                ), {});

                return optionslot[optionkey] = {
                    ...svalue,
                    key: skey,
                    value: {
                        ...ovalue,
                        key: okey
                    }
                }
            }

            return opt.key
        }

        export function formOf(record?: IRecord): IFormGroup | undefined {
            return FORMSLOT.Of(record, true)?.form;
        }

        export function recordOf(form?: IFormGroup): IRecord | undefined {
            return FORMSLOT.Of(form, true)?.record;
        }

        export function bindeach(record: IRecord, form: IFormGroup): void {
            FORMSLOT.Of(form, true).record = record;
            FORMSLOT.Of(record, true).form = form;
        }

        export function unbindeach(val?: IFormGroup | IRecord): void {
            const { record, form } = FORMSLOT.Of(val, false) || {};
            const rcdslot = FORMSLOT.Of(record, false);
            const frmslot = FORMSLOT.Of(form, false);
            frmslot && (delete frmslot.record);
            rcdslot && (delete rcdslot.form);
        }
    }
}

const PolyfillSlot = _Prop.Slot<{ prop?: boolean }>(
    _Uniq.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);
    if (!wnd || slot.prop) return;
    slot.prop = true;

    // Interpolation section
    const { API } = _Interpolation;
    const interpolation: Interpolation = API;
    (wnd as any).Interpolation = interpolation;

    // Prop section
    const { newSymbol, newUuid, newIdx, getBag, Of, Slot, makeProxyHandler } = _Prop;
    const { symbol, idx, namedidx, uuid, unimarker } = _Uniq;
    const prop: Prop = {
        newSymbol, newUuid, newIdx, getBag, Of, Slot,
        symbol, idx, namedidx, uuid, unimarker, makeProxyHandler,
        polyfill: _polyfill,
    };

    (wnd as any).Prop = prop;

    // Fielder section
    const fieler: Fielder = _Fielder.Fielder;
    (wnd as any).Fielder = fieler;

    const { bindeach, unbindeach, fieldHandlerOf, fieldOf, controlOf, optionOf, formOf, recordOf } = _Schema.Form;
    const { typeOf, ValueHandlers, isPrimitiveValue, isPrimitiveType, convert, init } = _Schema;
    const { validate, checkexpect } = _Schema.Validate;
    const { pathOf } = _Schema.Parser;

    // Schema section
    const schema: Schema = {
        typeOf,

        pathOf,

        Value: {
            handlers: ValueHandlers,
            isPrimitiveValue,
            isPrimitiveType,
            convert,
            init
        },

        Form: {
            fieldHandlerOf,
            fieldOf,
            controlOf,
            optionOf,
            formOf,
            recordOf,
            unbindeach,
            bindeach
        },

        Validate: {
            validate,
            checkexpect
        }
    };

    (wnd as any).Schema = schema;
}

_polyfill(window);

type RawType = Exclude<Schema.Value.PrimitiveValue, JSON.Object | JSON.Array>;

export { };

declare global {
    //#region Interpolation section
    namespace Interpolation {
        type Expression = `\`${string}\`` & string;

        type Handler = {
            (ctx: any, scope?: { [k: string]: any }): any
        }
    }

    interface Interpolation {
        build(exp: Interpolation.Expression): Interpolation.Handler | undefined;
        is(val: any): val is Interpolation.Expression;
    }

    const Interpolation: Interpolation;
    //#endregion Interpolation section

    //#region Prop section
    namespace Prop {
        type guid = string;

        type VBag<O extends object = {}, TEX extends {} = {}> = {
            -readonly [P in Exclude<keyof O, keyof TEX>]?: O[P]
        } & {
            -readonly [P in keyof TEX]: TEX[P]
        }

        interface PBag<O extends object = {}, TEX extends {} = {}> {
            readonly parent?: PBag<O, TEX>,

            readonly after: {
                -readonly [P in keyof VBag<O, TEX>]?: VBag<O, TEX>[P]
            },

            readonly before: {
                -readonly [P in keyof VBag<O, TEX>]?: VBag<O, TEX>[P]
            },

            readonly values: {
                -readonly [P in keyof VBag<O, TEX>]: VBag<O, TEX>[P]
            },

            readonly events: {
                [P in keyof O]?: EventEmitter<O[P]>
            },

            readonly slots: {
                [F: symbol]: {}
            },
        }
    }

    interface Prop {
        polyfill(wnd: Window): void;
        newUuid(hex: boolean): string;
        newSymbol(prefix: string): symbol;
        newIdx(): number;

        getBag<
            O extends object, TEX extends {} = {}
        >(obj: O, forceown: true): Prop.PBag<O, TEX>;
        getBag<
            O extends object, TEX extends {} = {}
        >(obj: O | undefined | null, forceown: boolean): Prop.PBag<O, TEX> | undefined;

        Of<
            O extends object, TEX extends {} = {}
        >(obj: O, init?: (values: Prop.VBag<O, TEX>, obj: O) => void): Prop.VBag<O, TEX>;
        Of<
            O extends object, TEX extends {} = {}
        >(obj: O, init?: (values: Prop.VBag<O, TEX>) => void): Prop.VBag<O, TEX>;
        Of<
            O extends object, TEX extends {} = {}
        >(obj: O | undefined | null, init?: (values: Prop.VBag<O, TEX>, obj: O) => void): Prop.VBag<O, TEX> | undefined;
        Of<
            O extends object, TEX extends {} = {}
        >(obj: O | undefined | null, init?: (values: Prop.VBag<O, TEX>) => void): Prop.VBag<O, TEX> | undefined;

        Slot<
            TSlot extends {} = {}
        >(key: symbol | string, creator?: (parent?: TSlot) => TSlot): {
            Of<FSlot extends {} = TSlot>(obj: object, forceown: true): FSlot;
            Of<FSlot extends {} = TSlot>(obj: object | undefined | null, forceown: boolean): FSlot | undefined;
            creator?: typeof creator;
        }
        Slot<
            TSlot extends {} = {}, TO extends object = {}
        >(key: symbol | string, creator?: (parent: TSlot | undefined, obj: TO) => TSlot): {
            Of<FSlot extends {} = TSlot, O extends TO = TO>(obj: O, forceown: true): FSlot;
            Of<FSlot extends {} = TSlot, O extends TO = TO>(obj: O | undefined | null, forceown: boolean): FSlot | undefined;
            creator?: typeof creator;
        }

        uuid(obj?: any, prefix?: string /* = 'uid' */, hex?: boolean /* = true */): string;
        namedidx(obj?: any, prefix?: string /* = 'idx' */): string;
        idx(obj?: any, prefix?: string /* = 'idx' */): number;
        symbol(prefix: string, obj?: any): symbol;
        unimarker(...args: any[]): object;

        makeProxyHandler<T extends object>(handler: ProxyHandler<T>): ProxyHandler<T>
    }

    const Prop: Prop;
    //#endregion Prop section

    //#region Fielder section
    namespace Fielder {
        interface IPath {
            rawpath: string,
            stdpath: string,
            dpath: string,
            option: boolean,
            multi: boolean,
            opath: string,
            osep: string,
            ipath: string
        }

        interface Options<TOption = any> extends XArray<TOption, TOption, {
            [id: string]: TOption
        }> {
            readonly onloaded?: Map<object, ((source: Options<TOption>) => void)>,
            readonly loaded?: boolean,
        }

        type IFielder<
            THost extends object = object, TOption = any
        > = {
            readonly handler: IHandler<THost, TOption>;
            readonly path: IPath;
            readonly option?: {
                readonly options: Options<TOption> | undefined;
                value(option: TOption): any;
            };
            readonly value: any;
            readonly field: { value: any };
            readonly source?: object,
            readonly host?: THost,
        }

        type IHandler<
            THost extends object = object, TOption = any
        > = {
            getFielder<RHost extends object = THost, ROption = TOption>(
                obj: RHost, source?: object | undefined
            ): IFielder<RHost, ROption>;

            readonly source?: object
            readonly path: IPath,

            value(obj: THost, source?: object): any,
            setFieldValue(obj: THost, val: any): void,
            getFieldValue(obj: THost): any,
            readonly option?: {
                options(source?: object): Options<TOption> | undefined,
                value(option: TOption): any,
            }
        }

        type ISourcor = {
            getHandler<THost extends object = object, TOption = any>(
                path: string
            ): IHandler<THost, TOption> | undefined,

            readonly source?: object
        }
    }

    interface Fielder extends Fielder.ISourcor {
        getSourcor(source: object): Fielder.ISourcor;

        getHandler<
            THost extends object = object, TOption = any
        >(path: string): Fielder.IHandler<THost, TOption> | undefined;
        getHandler<
            THost extends object = object, TOption = any
        >(path: string, source?: object): Fielder.IHandler<THost, TOption> | undefined;

        getFielder<
            THost extends object = object
        >(obj: THost, path: string): Fielder.IFielder<THost> | undefined;
        getFielder<
            THost extends object = object, TOption = any
        >(obj: THost, path: string, source?: object): Fielder.IFielder<THost, TOption> | undefined;
    }

    const Fielder: Fielder;
    //#endregion Fielder section

    //#region Schema section
    namespace Schema {
        type Index = XArray.Index;
        type Indexes = {
            [k: string]: XArray.Index;
        }

        namespace ISchema {
            /**
             * reference and merge the schema definition,
             * eg: 
             *  original {
             *          schemas: {
             *              enum1: [
             *                  { name:'a', type:number, key:'a' }, 
             *                  { name:'b', type:number, key:'b' }
             *              ],
             *              x: { name:'x', type:number, key:'x' }
             *          },
             *          entities: {
             *              prj: {
             *                  name: "project type",
             *                  key: "projecttype",
             *                  type: [
             *                      '...@schemas.enum1',
             *                      { name:'c', type:number, key:'c' }, 
             *                      { name:'d', type:number, key:'d' },
             *                      '@schemas.x'
             *                  ],
             *                  flds: [
             *                      '...@schemas.enum1',
             *                      { name:'e', type:number, key:'e' }, 
             *                      { name:'f', type:number, key:'f' }
             *                  ]
             *              }
             *          }
             *     }
             *  
             *  final:{
             *          schemas: {
             *              enum1: [
             *                  { name:'a', type:number, key:'a' }, 
             *                  { name:'b', type:number, key:'b' }
             *              ],
             *              x: { name:'x', type:number, key:'x' }
             *          },
             *          entities: {
             *              prj: {
             *                  name: "project type",
             *                  key: "projecttype",
             *                  type: [
             *                      { name:'a', type:number, key:'a' }, 
             *                      { name:'b', type:number, key:'b' },
             *                      { name:'c', type:number, key:'c' }, 
             *                      { name:'d', type:number, key:'d' },
             *                      { name:'x', type:number, key:'x' },
             *                  ],
             *                  flds: [
             *                      { name:'a', type:number, key:'a' }, 
             *                      { name:'b', type:number, key:'b' }
             *                      { name:'e', type:number, key:'e' }, 
             *                      { name:'f', type:number, key:'f' }
             *                  ]
             *              }
             *          }
             *     }
             */
            // type RefKey = `...@${string}` | `@${string}`;

            type Type = 'select' | 'options' | 'option' | 'tree' | Value.PrimitiveType | '';

            interface Base {
                type?: undefined,

                children?: undefined,

                expect?: undefined,

                /**
                 * the caption display content or language translate key for IHeader's header cell, or ISchema's form field 
                 */
                name: string,

                /**
                 * to indicate whether this IHeader's column, or ISchema's form field cannot be shown.
                 */
                invisible?: boolean,

                /**
                 * additional style class for ISchema's field display, or IHeader's cell display in card view mode
                 */
                class?: string,
            }

            interface Select extends Omit<Base, 'type'> {
                /**
                 * select type
                 */
                type: ISchemaset,
            }

            interface Tree extends Omit<Base, 'type' | 'children'> {
                /**
                 * tree selection type, field[source]value
                 */
                type: `${string}[${string}]${string}`,

                /**
                 * children field name of source
                 */
                children?: string,
            }

            interface Options extends Omit<Base, 'type'> {
                /**
                 * the schema node type
                 */
                type: `${string}[${string}]${string}`, // options type
            }

            interface Option extends Omit<Base, 'type'> {
                /**
                 * the schema node type
                 */
                type: `${string}<${string}>${string}`, // option type
            }

            interface Primitive extends Omit<Base, 'type' | 'expect'> {
                /**
                 * the schema node type
                 */
                type: Value.PrimitiveType, // one of primitive type

                /**
                 * the expected value verification, 
                 * if errmsg provided, the errmsg will display in case unexpected value found
                 * if regex provided, the value will be validated by regex.
                 */
                expect?: RawType | RawType[] | {
                    value: RawType | RawType[],
                    regex?: string,
                    errmsg: string
                },
            }
        }

        /**
         * the column difinition of view, view normally can be table/card
         */
        type IHeader = (ISchema.Select | ISchema.Options | ISchema.Option | ISchema.Primitive | ISchema.Base) & {
            /**
             * if no type provided, the key corresponding to an entity schema by schema path
             */
            key: string | string[],

            /**
              * to indicate whether this header cannot be show in mobile mode.
              */
            immobilable?: boolean,

            /**
             * to indicate this header is stick at the start or end side,
             * "stick" means this header colum can always always visible 
             * regardlesss scroll if this header is visible
             */
            sticky?: 'start' | 'end',

            /**
             * the ngTemplate id to specially render this header's cell content
             */
            template?: string,

            /**
             * specially to show a delete icon to remove the record
             */
            action?: boolean,

            /**
             * the header's cell content fielder handler
             */
            fielderhandler?: Form.IFielderHandler,
        }

        /**
         * The schema node definition for form build
         */
        type ISchema = (ISchema.Select | ISchema.Tree | ISchema.Options | ISchema.Option | ISchema.Primitive | ISchema.Base) & {
            /**
             * the key of schema node, will be final field name of record
             */
            key: string,

            /**
             * optional or not to input
             */
            optional?: boolean,

            /**
             * readonly or not, that's editable or not
             */
            readonly?: boolean,

            /**
             * the field comment display content or language translate key
             */
            hint?: string,

            /**
             * the sub schema node
             */
            deps?: ISchemasset,
            flds?: ISchemasset,

            /**
             * callback func while value changed
             * @param record 
             * @param fielder
             */
            onchange?: (record: IRecord, fielder: Form.IFielder) => void;
        }

        /**
         * the key indexed header set
         */
        type IHeaderset = XArray<IHeader, IHeader, 'key'>

        interface IHeadersets {
            [key: string]: IHeaderset;
        }

        /**
         * the key indexed schema set
         */
        type ISchemaset = XArray<ISchema, ISchema, 'key'>

        /**
         * the key indexed schema or schemas set
         */
        type ISchemasset = XArray<ISchema | ISchemaset, ISchema | ISchemaset, {
            [key: string]: ISchema
        }>;

        type IEntity = ISchema & {
            index?: XArray.Index
            backface?: string | {
                load?: string,
                save?: string,
                delte?: string
            }
        }

        interface IEntities {
            [entity: string]: IEntity
        }

        interface IValue {
            [k: string | number]: IValue[] | IValue | Value.PrimitiveValue | undefined
            value?: IValue[] | IValue | Value.PrimitiveValue,
            deps?: { [k: string]: IValue },
            name?: string,
            key?: string,
        }

        interface IRecord extends IValue {
            id?: string,
        }

        interface IRecordset extends O.Omit<Fielder.Options<IRecord>, 'create'> {
            readonly autoload?: boolean,
            readonly entity?: string,
            readonly reqparam?: any,

            loader?: Observable<IRecord[]>,
            saver?: Observable<IRecord[]>,
            deleter?: Observable<boolean>,

            /**
             * reload the records according to param
             */
            load(param: any): Observable<false | IRecord[]>,

            create(val: IRecord[], at?: number | undefined): IRecord[]
            create(): IRecord,
        }

        interface IRecordsets {
            [key: string]: IRecordset;
        }

        interface ISource {
            readonly entities: IEntities;
            readonly recordsets: object
        }

        interface IService extends ISource {
            readonly recordsets: IRecordsets;
            readonly entities: IEntities,

            save(record: IRecord | IRecord[], recordset?: IRecordset): Observable<false | IRecord[]>;
            delete(record: IRecord | IRecord[], recordset?: IRecordset): Observable<boolean>;
            load(entity: string, recordset?: IRecordset): Observable<false | IRecord[]>;
        }

        namespace Form {
            interface IFielderHandler<
                THost extends object = IValue, TOption = any
            > extends Fielder.IHandler<THost, TOption> {
                getFielder<RHost extends object = THost, ROption = TOption>(
                    obj: RHost, source?: object | undefined
                ): IFielder<RHost, ROption>,

                readonly type?: Schema.ISchema.Type;
                readonly optionkey?: string,
                readonly fieldpath?: string,
                readonly fieldname?: string,
                readonly compact?: boolean,
                readonly schema?: ISchema,

                readonly valueinit?: (value: IValue) => IValue;
            }

            interface IFielder<
                RHost extends object = IValue, ROption = any
            > extends Fielder.IFielder<RHost, ROption> {
                readonly type?: Schema.ISchema.Type;
                readonly optionkey?: string,
                readonly fieldpath?: string,
                readonly fieldname?: string,
                readonly compact?: boolean,
                readonly schema?: ISchema,
                readonly record?: IRecord,

                readonly valueinit?: (value: IValue) => IValue;
            }

            interface IFormControl<TValue = any> extends FormControl<TValue> {
                readonly readonly: boolean;
                readonly field: IFielder;
                defaultValue: TValue;

                rollback(): void;
                commit(): void;
            }

            interface IFormGroup extends FormGroup {
                readonly controls: {
                    [key: string]: IFormControl;
                }

                readonly $hoster: {
                    hasError(field: IFielder, value: any): string | undefined
                    isReadonly(field: IFielder): boolean
                    readonly: boolean
                };

                readonly $isvalid: boolean;
                readonly $schema: ISchema;
                readonly $record: IRecord;

                $rollback(): void;
                $commit(): void;
            }

            interface IStage {
                fieldpath?: string,
                compact?: boolean,
            }
        }

        interface Form {
            fieldOf(record: IRecord | undefined, value: IValue, schema: ISchema, stage: Form.IStage, source?: ISource): Form.IFielder | undefined
            fieldHandlerOf(header: IHeader, entity?: IEntity, source?: ISource): Form.IFielderHandler | undefined;

            controlOf(field: Form.IFielder, schema: ISchema, form: Form.IFormGroup): Form.IFormControl;
            optionOf(field: Form.IFielder, opt: ISchema, schema: ISchema): IValue | string;
            formOf(record?: IRecord): Form.IFormGroup | undefined;
            recordOf(form?: Form.IFormGroup): IRecord | undefined;
            bindeach(record: IRecord, form: Form.IFormGroup): void;
            unbindeach(form?: Form.IFormGroup): void;
            unbindeach(record?: IRecord): void;
        }

        interface Validate {
            validate(value: IValue, schema: ISchema, source?: ISource): boolean,
            checkexpect(schema: ISchema, value: Value.PrimitiveValue | IValue | IValue[]): string | undefined
        }

        interface Value {
            readonly handlers: Value.Handlers,

            isPrimitiveType(type?: string): type is Value.PrimitiveType,

            isPrimitiveValue<Type extends Value.PrimitiveType>(type: Type, value: any): value is Exclude<ReturnType<Value.Handlers[Type]['convert']>, undefined>;
            isPrimitiveValue(type: string, value: any): value is Value.PrimitiveValue;
            isPrimitiveValue(value: any): value is Value.PrimitiveValue;

            convert<Type extends Value.PrimitiveType>(type: Type, value: any): Exclude<ReturnType<Value.Handlers[Type]['convert']>, undefined>
            convert(type: string, value: any): Value.PrimitiveValue,
            convert(value: any): Value.PrimitiveValue,

            init<Type extends Value.PrimitiveType>(type: Type, value: any): Exclude<ReturnType<Value.Handlers[Type]['convert']>, undefined>
            init(type: string, value: any): Value.PrimitiveValue,
            init(value: any): Value.PrimitiveValue,
        }

        namespace Value {
            type Handlers = typeof _Schema.ValueHandlers;
            type PrimitiveValue = Exclude<ReturnType<Value.Handlers[PrimitiveType]['convert']>, undefined>;
            type PrimitiveType = keyof Value.Handlers;
        }

        interface Dashboards {
            [key: string]: Dashboard;
        }

        interface Dashboard extends Array<Dashboard.Block.Types> {
        }

        namespace Dashboard {
            interface Block<Type extends Block.Types['type']> {
                /** board type */
                type: Type;

                /** special template id to render */
                template?: string;

                /** board definition specified by type */
                board: Extract<Block.Types, { type: Type }>['board'];

                /** class for layout or display*/
                class?: string;
            }

            namespace Block {
                type Types = (Chart | Cards | Table);

                interface Chart extends Block<'chart'> {
                    board: {
                        /** class for layout or display*/
                        class?: string;

                        option: EChartsOption;
                        initOpts?: Object;
                        merge?: Object;
                    },

                    type: 'chart';
                }

                interface Table extends Block<'table'> {
                    board: {
                        viewmode?: Table.ViewMode,
                        headers?: Schema.IHeader[],

                        /** entity name */
                        entity?: string,

                        /** value path definition */
                        value?: string,

                        searchable?: boolean,
                        noheader?: boolean,
                        nopanel?: boolean,
                    }

                    type: 'table'
                }

                namespace Table {
                    type ViewMode = 'table' | 'card';
                }

                interface Cards extends Block<'cards'> {
                    /** card group */
                    board: Cards.Card[];

                    type: 'cards'
                }

                namespace Cards {
                    type Item = {
                        /** content title to display */
                        title: string,

                        /** content value to display */
                        value?: string,

                        /** material-icons icon name to display */
                        icon?: string,

                        /** action difinition while click*/
                        $$action?: string,

                        /** special template id to render */
                        template?: string;

                        /** class for layout or display*/
                        class?: string;

                        /** whether render it*/
                        cond?: boolean;
                    }

                    type Header = Item & {
                        status?: 'primary' | 'info' | 'success' | 'rose' | 'warning' | 'danger'
                    }

                    type Card = {
                        /** card title definition */
                        header: string | Cards.Header;

                        /** card body definition */
                        body: (string | Cards.Item)[];

                        /** card footer definition */
                        footer?: (string | Cards.Item);

                        /** special template id to render */
                        template?: string;

                        /** class for layout or display in card group */
                        class?: string;
                    }
                }
            }
        }
    }

    interface Schema {
        typeOf(schema?: Schema.ISchema | Schema.IHeader): Schema.ISchema.Type,

        pathOf(path: string, schema: Schema.ISchema, source?: Schema.ISource): {
            schema?: Schema.ISchema;
            valuepath?: string;
            xpaths?: string[];
            paths?: string[];
        },

        Value: Schema.Value,

        Form: Schema.Form,

        Validate: Schema.Validate,
    }

    const Schema: Schema;
    //#endregion Schema section
}