const PolyfillSlot = Prop.Slot<{ event?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
export default function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);
    const Event: EventConstructor = (wnd as any)?.['Event'];
    if (!Event || slot.event) return;
    slot.event = true;

    const _addEventListener = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (
        this: EventTarget, type: string, callback: EventListenerOrEventListenerObject | null, options?: AddEventListenerOptions | boolean
    ) {
        options = options ?? {};

        if (_.isBoolean(options)) {
            options = {
                capture: options
            }
        }

        if (!_.has(options, 'passive') && !options.capture && /touch/.test(type)) {
            options = {
                ...options,
                passive: true
            }
        }

        return _addEventListener.call(this, type, callback, options);
    }

    _.forEach<keyof Event>(['preventDefault', 'stopPropagation', 'stopImmediatePropagation'], (funcname) => {
        const mtddesc = Object.getOwnPropertyDescriptor(Event.prototype, funcname);
        const mtdfunc = mtddesc?.value;

        if (mtddesc && mtdfunc && _.isFunction(mtdfunc)) {
            mtddesc.value = function (this: Event, ...args: any[]): any {
                (this.parentEvent?.[funcname] as Function)?.(...args);
                return mtdfunc.call(this, ...args)
            };

            Object.defineProperty(Event.prototype, funcname, mtddesc)
        }
    })

    _.forEach<keyof Event>(['htmlElementTarget', 'elementTarget', 'nodeTarget'], (key) => {
        Object.defineProperty(Event.prototype, key, {
            enumerable: true, configurable: true,
            get(this: Event): any {
                const target = this.target;
                switch (key) {
                    case 'htmlElementTarget':
                        return DOM.toNative(target, HTMLElement).el;

                    case 'elementTarget':
                        return DOM.toNative(target, Element).el;

                    case 'nodeTarget':
                        return DOM.toNative(target, Node).el;
                }
            }
        })
    })

    Object.defineProperties(Event.prototype, {
        dispatchClone: {
            writable: true, enumerable: true, configurable: true,
            value<T extends Event>(this: T): T {
                const creator = ((oev: T) => {
                    const constructor: (new (type: string, eventInitDict?: EventInit) => T) = this.constructor as any;

                    try {
                        return new constructor(oev.type, oev);
                    } catch (e) {
                    }

                    const nev: T = document.createEvent(constructor.name) as T;
                    nev.initEvent?.(oev.type, true, true);
                    return _.merge(nev, oev);
                })

                const oevent = creator(this), event = this;
                Object.defineProperty(oevent, 'parentEvent', {
                    enumerable: false, configurable: true,
                    get() {
                        return event;
                    }
                })

                return oevent;
            }
        },
        position: {
            enumerable: true, configurable: true,
            get(this: Event): Position | undefined {
                const fields: {
                    [x: string]: boolean
                } = {
                    clientX: true,
                    clientY: true,
                    screenX: true,
                    screenY: true,

                    translationX: false,
                    translationY: false,
                    velocityX: false,
                    velocityY: false,
                    offsetX: false,
                    offsetY: false,
                    pageX: false,
                    pageY: false,
                };

                const obj = (this as any)['touches']?.[0] || this;
                return _.reduce(fields, (res, req, key) => {
                    if (!res) return;

                    const val = (obj as any)?.[key];
                    if (req && _.isUndefined(val)) {
                        return;
                    }

                    if (!_.isUndefined(val)) {
                        (res as any)[key] = val;
                    }

                    return res;
                }, {} as (Position | undefined))
            }
        },
        keycode: {
            enumerable: true, configurable: true,
            get(this: Event): number {
                return (this as any)['which'] || (this as any)['keyCode'] || 0;
            }
        },
        keychar: {
            enumerable: true, configurable: true,
            get(this: Event): string {
                return String.fromCharCode(this.keycode);
            }
        },
        isESC: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 27;
            }
        },
        isALT: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 18;
            }
        },
        isSFT: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 16;
            }
        },
        isCTR: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 17;
            }
        },
        isCR: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 13;
            }
        },
        isDEL: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 46;
            }
        },
        isLEFT: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 37;
            }
        },
        isUP: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 38;
            }
        },
        isRIGHT: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 39;
            }
        },
        isDOWN: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 40;
            }
        },
        isPGUP: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 33;
            }
        },
        isPGDOWN: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 34;
            }
        },
        isHOME: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 36;
            }
        },
        isEND: {
            enumerable: true, configurable: true,
            get(this: Event): boolean {
                return this.keycode == 35;
            }
        }
    })

    type TickFunc = [fn: (...args: any) => void, thisArg: any, ...args: any];

    function OnOff(
        eventnanme: string[],
        eventtarget: EventTarget[],
        callback: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions | undefined
    ): Event.IOnOff {
        const handle: Event.IOnOff = {
            on(): Event.IOnOff {
                eventnanme.forEach(event => {
                    eventtarget.forEach(target => {
                        target?.addEventListener(event, callback, options)
                    })
                });

                return handle;
            },

            off(): Event.IOnOff {
                eventnanme.forEach(event => {
                    eventtarget.forEach(target => {
                        target?.removeEventListener(event, callback, options)
                    })
                });

                return handle;
            }
        }

        return handle;
    }

    const tick = {
        writable: true, enumerable: false, configurable: true,
        value(): void {
            const funcs: TickFunc[] = [];
            if (arguments.length > 0) {
                if (_.isFunction(arguments[0])) {
                    funcs.push(<TickFunc>[...arguments]);
                } else {
                    funcs.push(...<TickFunc[]>[...arguments]);
                }
            }

            Promise.resolve().then(() => {
                funcs.forEach(([fn, thisArg, ...args]) => {
                    _.isFunction(fn) && fn.apply(thisArg, args);
                })
            });
        }
    }

    Object.defineProperties(Event, {
        polyfill: {
            configurable: false, writable: false,
            value(wnd: Window) {
                _polyfill(wnd);
            }
        },
        tick: tick,
        on: {
            writable: true, enumerable: false, configurable: true,
            value(el: EventTarget | EventTarget[], ename: string | string[], fn: EventListenerOrEventListenerObject) {
                const evn = ename instanceof Array ? ename : ename.split(/\s+/);
                const evm = el instanceof Array ? el : [el];
                const onoff = OnOff(evn, evm, fn);
                return onoff.on()
            }
        },
        off: {
            writable: true, enumerable: false, configurable: true,
            value(el: EventTarget | EventTarget[], ename: string | string[], fn: EventListenerOrEventListenerObject) {
                const evn = ename instanceof Array ? ename : ename.split(/\s+/);
                const evm = el instanceof Array ? el : [el];
                const onoff = OnOff(evn, evm, fn);
                return onoff.off()
            }
        },
        preventDefault: {
            writable: true, enumerable: false, configurable: true,
            value(ev: Event) {
                ev?.preventDefault?.();
            }
        },
        stopPropagation: {
            writable: true, enumerable: false, configurable: true,
            value(ev: Event) {
                ev?.stopPropagation?.();
            }
        },
        stopImmediatePropagation: {
            writable: true, enumerable: false, configurable: true,
            value(ev: Event) {
                ev?.stopImmediatePropagation?.();
            }
        },
        stop: {
            writable: true, enumerable: false, configurable: true,
            value(ev: Event) {
                Event.stopImmediatePropagation(ev);
                Event.stopPropagation(ev);
                Event.preventDefault(ev);
            }
        }
    })

    Object.defineProperties(wnd, {
        tick: tick
    })
}

_polyfill(window);

export { };

declare global {
    interface Position {
        readonly clientX: number;
        readonly clientY: number;
        readonly screenX: number;
        readonly screenY: number;
        readonly translationX?: number;
        readonly translationY?: number;
        readonly velocityX?: number;
        readonly velocityY?: number;
        readonly offsetX?: number;
        readonly offsetY?: number;
        readonly pageX?: number;
        readonly pageY?: number;
    }

    interface Window {
        Event: EventConstructor;
    }

    namespace Event {
        export interface IOnOff {
            off(): IOnOff;
            on(): IOnOff;
        }
    }

    interface Event {
        readonly parentEvent?: Event;
        dispatchClone<T extends Event>(this: T): T;

        // cast eventTarget to wished possibally
        readonly htmlElementTarget?: HTMLElement;
        readonly elementTarget?: Element;
        readonly nodeTarget?: Node;

        readonly position: Position | undefined;

        readonly keychar: string;
        readonly keycode: number;
        readonly isESC: boolean;
        readonly isALT: boolean;
        readonly isSFT: boolean;
        readonly isCTR: boolean;
        readonly isCR: boolean;

        readonly isDEL: boolean;
        readonly isLEFT: boolean;
        readonly isUP: boolean;
        readonly isRIGHT: boolean;
        readonly isDOWN: boolean;
        readonly isPGUP: boolean;
        readonly isPGDOWN: boolean;
        readonly isHOME: boolean;
        readonly isEND: boolean;
    }

    type EventConstructor = (typeof Event) & {
        polyfill(wnd: Window): void;

        off(el: EventTarget | EventTarget[], ename: string | string[], fn: EventListenerOrEventListenerObject): Event.IOnOff;
        on(el: EventTarget | EventTarget[], ename: string | string[], fn: EventListenerOrEventListenerObject): Event.IOnOff;
        stopImmediatePropagation(ev: Event): void;
        stopPropagation(ev: Event): void;
        preventDefault(ev: Event): void;
        stop(ev: Event): void;

        // schedules asyn task
        tick(...funcs: [fn: (...args: any) => void, thisArg: any, ...args: any][]): void;
        tick(fn: (...args: any) => void, thisArg: any, ...args: any): void;
        tick(fn: () => void): void;
        tick(): void;
    }


    function tick(...funcs: [fn: (...args: any) => void, thisArg: any, ...args: any][]): void;
    function tick(fn: (...args: any) => void, thisArg: any, ...args: any): void;
    function tick(fn: () => void): void;
    function tick(): void;
}
