import { Injectable } from "@angular/core";

import { AMapPixelService } from "./amap-pixel.service";

@Injectable({ providedIn: 'root' })
export class AMapMarkerLabelService {
    constructor(private readonly pixel: AMapPixelService) {
    }

    create(options?: AMap.IMarkerLabel): AMap.Marker.Label | undefined {
        if (!options) return

        const label: AMap.Marker.Label = {};
        if (options.content !== undefined) {
            label.content = options.content;
        }

        if (options.offset !== undefined) {
            if (options.offset instanceof AMap.Pixel) {
                label.offset = options.offset;
            }
            else {
                label.offset = this.pixel.create(options.offset);
            }
        }

        if (options.direction !== undefined) {
            label.direction = options.direction;
        }

        return label;
    }
}
