import { OnDestroy, EventEmitter, SimpleChanges, OnChanges, ElementRef, NgZone, Component, Input, Output, output } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { Observable, Subscription, zip } from 'rxjs';

import { NgxAMapInfoWindowService } from '../../shared/inner/ngx-amap-info-window.service';
import { NgxAMapCreaterService } from '../../shared/inner/ngx-amap.creater.service';
import { AMapEventBinderService } from '../../shared/amap-binder-event.service';
import { AMapLoggerService } from '../../shared/amap-logger.service';
import { AMapPixelService } from '../../shared/amap-pixel.service';
import { AMapSizeService } from '../../shared/amap-size.service';

import { ChangeFilter, getOptions } from '../../utils/options';

const TAG = 'ngx-amap-info-window';
const ALL_OPTIONS: Extract<
    keyof NgxAMapInfoWindowComponent,
    keyof AMap.InfoWindow.Options
>[] = [
        'isCustom',
        'autoMove',
        'closeWhenClickMap',
        'content',
        'size',
        'anchor',
        'offset',
        'position',
        'showShadow',
    ];

@Component({
    selector: 'ngx-amap-info-window',
    templateUrl: "./ngx-amap-info-window.component.html",
    providers: [NgxAMapInfoWindowService],
    exportAs: 'ngxAMapInfoWindow',
    host: {
        '[style.pointer-events]': "'all'",
        '[style.display]': "'none'",
    }
})
export class NgxAMapInfoWindowComponent implements OnChanges, OnDestroy {
    protected readonly _binder = this.binder.bindEvent<AMap.InfoWindow.EventMap<AMap.Map>>();
    private subscriptions?: Subscription;
    private inited: boolean = false;

    /**
     * 是否自定义窗体
     */
    @Input()
    isCustom?: boolean;

    /**
     * 是否自动调整窗体到视野内
     */
    @Input()
    autoMove?: boolean;

    /**
     * 控制是否在鼠标点击地图后关闭信息窗体
     */
    @Input()
    closeWhenClickMap?: boolean;

    /**
     * 显示内容
     */
    @Input()
    content?: string | HTMLElement;

    /**
     * 信息窗体尺寸
     */
    @Input()
    size?: AMap.SizeValue | AMap.ISize;

    /**
     * 信息窗体锚点
     */
    @Input()
    anchor?: AMap.InfoWindow.Anchor;

    /**
     * 信息窗体显示位置偏移量
     */
    @Input()
    offset?: AMap.Pixel | AMap.IPixel;

    /**
     * 信息窗体显示基点位置
     */
    @Input()
    position?: AMap.LocationValue;

    /**
     * 是否显示信息窗体阴影
     */
    @Input()
    showShadow?: boolean;

    /**
     * 额外：是否开启
     */
    @Input()
    isOpen: boolean = false;

    readonly isOpenChange = output<boolean>();
    readonly naReady = output<AMap.InfoWindow<any>>();
    readonly naOpen = outputFromObservable(this._binder(this.os.get(), 'open'));
    readonly naClose = outputFromObservable(this._binder(this.os.get(), 'close'));
    readonly naChange = outputFromObservable(this._binder(this.os.get(), 'change'));

    hostMarker?: Observable<AMap.Marker<any>>;

    constructor(
        private readonly os: NgxAMapInfoWindowService,
        private readonly binder: AMapEventBinderService,
        private readonly amaps: NgxAMapCreaterService,
        private readonly el: ElementRef,
        private readonly logger: AMapLoggerService,
        private readonly pixels: AMapPixelService,
        private readonly sizes: AMapSizeService,
        private readonly ngZone: NgZone
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const filter = ChangeFilter.from(changes);
        const iw = this.get();

        if (!this.inited) {
            this.amaps.get().subscribe(
                () => {
                    this.logger.d(TAG, 'initializing ...');

                    // bind isOpenChange events:
                    this.subscriptions = this._binder(iw, 'open').subscribe(
                        () => {
                            if (!this.isOpen) {
                                this.ngZone.run(() => {
                                    this.isOpen = true;
                                    this.isOpenChange.emit(true);
                                })
                            }
                        }
                    );

                    this.subscriptions.add(this._binder(iw, 'close').subscribe(
                        () => {
                            if (this.isOpen) {
                                this.ngZone.run(() => {
                                    this.isOpen = false;
                                    this.isOpenChange.emit(false);
                                })
                            }
                        }
                    ));

                    this.content = this.content
                        ? this.content
                        : this.el.nativeElement.querySelector('.amap-info-window-content');

                    const options = getOptions<NgxAMapInfoWindowComponent, AMap.InfoWindow.Options>(this, ALL_OPTIONS);
                    if (this.offset) {
                        options.offset = this.pixels.create(this.offset);
                    }

                    if (this.size) {
                        options.size = this.sizes.create(this.size);
                    }

                    this.logger.d(TAG, 'options:', options);
                    this.os.create(options).subscribe(
                        m => {
                            this.ngZone.run(() => {
                                this.toggleOpen();
                                this.naReady.emit(m);
                            });

                            this.logger.d(TAG, 'InfoWindow is ready.');
                        }
                    );

                    this.inited = true;
                }
            );
        }

        filter.has('isOpen').subscribe(
            () => this.toggleOpen()
        );

        zip(filter.has('content'), iw).subscribe(
            ([v, w]) => w.setContent(v)
        );

        zip(filter.notEmpty('position'), iw).subscribe(
            ([v, w]) => w.setPosition(v)
        );

        zip(filter.notEmpty('size'), iw).subscribe(
            ([v, w]: [AMap.SizeValue | AMap.ISize | undefined, AMap.InfoWindow<any>]) => {
                if (!v) return;

                w.setSize(
                    (Array.isArray(v) || v instanceof AMap.Size) ?
                        v : [v.width!, v.height!]
                );

                // w.setSize(this.sizes.create(v));
            }
        );

        zip(filter.notEmpty('anchor'), iw).subscribe(
            ([v, w]) => w.setAnchor(v)
        );
    }

    ngOnDestroy(): void {
        this.subscriptions?.unsubscribe();
        this.os.destroy();
    }

    /**
     * 获取已创建的 AMap.InfoWindow 对象
     */
    get(): Observable<AMap.InfoWindow<any>> {
        return this.os.get();
    }

    /**
     * 开关窗体
     */
    toggleOpen(): void {
        this.logger.d(TAG, 'toggle open');
        this.isOpen ? this.open() : this.close();
    }

    /**
     * 打开窗体
     */
    open(): void {
        const { os, hostMarker } = this;
        hostMarker ? os.openOnMark(hostMarker) : os.open();
    }

    /**
     * 关闭窗体
     */
    close(): void {
        this.os.close();
    }
}
