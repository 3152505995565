import { Injector, Type } from '@angular/core';

type Class = Type<any>;

type ISlot = {
    handles: JSON.Handles,
    keys: JSON.Keys,
}

type IndexedKeys = JSON.Keys['indexed']

const CreateKeys = XArray.creator((item: string, indexed: IndexedKeys, add: boolean): void => {
    add ? (indexed[item] = true) : (delete indexed[item])
});

const JSONSlot = Prop.Slot<ISlot>('JSONSLOT', (parent?: ISlot): ISlot => {
    const _slot: ISlot = {
        handles: { ...(parent?.handles ?? {}) },
        keys: CreateKeys(),
    }

    const _clone = (dst: JSON.Keys, src?: JSON.Keys) => {
        if (src == null) return dst;
        dst.push(...src.array);

        const { indexed: srcidx } = src;
        const { indexed: dstidx } = dst;

        _.forEach(srcidx, (srcv, srck) => {
            if (_.isBoolean(srcv)) return;
            const keys = (dstidx[srck] = CreateKeys());
            _clone(keys, srcv);
        })
    }

    return _clone(_slot.keys, parent?.keys), _slot;
});

function HandlerOf<TObj extends Object | Class = any>(obj: TObj): ISlot | undefined {
    return JSONSlot.Of(typeof obj === 'function' ? obj.prototype : obj, false);
}

function JsonOf<
    TObj extends Object = any
>(this: any, obj: TObj, res?: JSON.Object, subkeys?: JSON.Keys): JSON.Type {
    if (obj == null) {
        return res || (obj ?? null);
    }

    const toJSON = (obj as any)?.['toJSON'];
    if (this != obj && !subkeys && _.isFunction(toJSON)) {
        return toJSON.call(obj, res);
    }

    const { keys = subkeys, handles } = HandlerOf(obj) ?? {};
    if (!keys || keys.length <= 0) {
        return obj as any;
    }

    return keys.reduce((res, key) => {
        if (subkeys && !subkeys.indexed[key]) {
            return res
        };

        const _handle = handles?.[key];
        if (_handle) {
            const _val = _handle(obj);
            return (_val !== undefined) && (res[key] = _val), res;
        }

        const keyval = (obj as any)[key];
        const _subkeys = _.takeIf<JSON.Keys, true | JSON.Keys>(
            (v): v is JSON.Keys => _.isArray(v),
            keys.indexed[key]
        );

        if (keyval == null && !_subkeys) {
            return (keyval !== undefined) && (res[key] = keyval), res;
        }

        const _val = JsonOf(keyval, {}, _subkeys);
        return (_val !== undefined) && (res[key] = _val), res;
    }, res || {});
}

type InterpretSlot = typeof InterpretSlot;
const InterpretSlot = Prop.Slot<{
    localizer?<CTX, Json = JSON.Type>(ctx: CTX, injector: Injector): Json,
    localed?: boolean,
    tolocal?: boolean
}>('INTERPRET', parent => {
    return {};
});

const PolyfillSlot = Prop.Slot<{ json?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);
    const JSON: globalThis.JSON = (wnd as any)?.['JSON'];
    if (!JSON || slot.json) return;
    slot.json = true;

    type Localizer<CTX, Json = JSON.Type> = JSON.Interpret.Localizer<CTX, Json>;
    type Pather<CTX, Json = JSON.Type> = JSON.Interpret.Pather<CTX, Json>;
    type Pathed<CTX, Json = JSON.Type> = JSON.Interpret.Pathed<CTX, Json>;
    type Valued<CTX, Json = JSON.Type> = JSON.Interpret.Valued<CTX, Json>;
    type Defer<CTX, Json = JSON.Type> = JSON.Interpret.Defer<CTX, Json>;
    type IDefer<Json = JSON.Type> = (ifulljson: Json) => void;
    type Path = JSON.Interpret.Path;

    interface PathCtx<CTX, Json = JSON.Type> extends Pather<CTX, Json> {
        [p: string | number]: PathCtx<CTX, Json>
    }

    type DPathCtx<CTX, Json = JSON.Type> = {
        ctx: PathCtx<CTX, Json>
        path: Path
    }

    const Ignore = {};
    const debugOut = false;

    function isPlainType(val: any): val is Record<string | number, any> {
        return (_.isPlainObject(val) || _.isArray(val)) && val != null
    }

    function tolocal(val: any): void {
        if (!_.isObject(val)) return;

        const slot = InterpretSlot.Of(val, true);
        slot.tolocal = true;
    }

    function localed(val: any): void {
        if (!_.isObject(val)) return;

        const slot = InterpretSlot.Of(val, true);
        slot.localed = true;
    }

    function islocaled(val: any): boolean {
        if (!_.isObject(val)) return false;

        const slot = InterpretSlot.Of(val, false);
        return !!slot?.localed;
    }

    function Indent(depth: number = 0): string {
        return (new Array(depth ?? 0).fill('    ')).join('');
    }

    function interpret<CTX, Json = JSON.Type>(
        rhost: Json | undefined, path: Path, rsub: Json,
        idefers: IDefer<Json>[], valued: Valued<CTX, Json>,
        pathctx?: DPathCtx<CTX, Json>, anyctx: DPathCtx<CTX, Json>[] = [], deepth: number = 0
    ): Pathed<CTX, Json> {
        type _Record = Record<string | number, Json>;
        type _Localizer = Localizer<CTX, Json>;
        type _DPathCtx = DPathCtx<CTX, Json>;
        type _PathCtx = PathCtx<CTX, Json>;
        type _Pathed = Pathed<CTX, Json>;
        type _Defer = Defer<CTX, Json>;
        type _Json = Json | undefined;

        if (!isPlainType(rsub)) {
            debugOut && console.log(Indent(deepth), 'call: valued(', path.join('.'), ')');;
            return valued(rhost, path, rsub);
        }

        const localizers: [
            rhost: Json, path: Path,
            isub: _Json,
            l: _Localizer
        ][] = [];

        anyctx = [...anyctx];
        if (pathctx?.ctx['**']) {
            anyctx.push({ ctx: pathctx?.ctx['**'], path: [...pathctx.path, '**'] });
        }

        anyctx = _.uniq(anyctx);
        const layerctx = pathctx?.ctx['*'] ? { ctx: pathctx?.ctx['*'], path: [...pathctx.path, '*'] } : undefined;

        function handleCurrent(_host: Json & _Record, _path: Path, _key: string | number, _sub: Json, _pathCtx: _DPathCtx[], anyctx: DPathCtx<CTX, Json>[], deepth: number): {
            l?: _Localizer[]
            s: Json
        } {
            let l: _Localizer[] | undefined;
            const _anyctx = [...anyctx];

            for (const ctx of _pathCtx) {
                // downward interpret
                const { isub: _issub, localizer: sl, idefer: sid } = interpret(
                    _host, _path, _sub, idefers, valued, ctx, _anyctx, deepth + 1
                );

                const anyidx = _anyctx.findIndex(ac => ac.ctx == ctx.ctx);
                anyidx >= 0 && _anyctx.splice(anyidx, 1);

                if (_issub !== undefined) { _.set(_host, _key, _issub); _sub = _issub }
                if (sid) idefers.push(sid);
                if (sl) tolocal(_issub);

                // handle current node
                const { isub: _ihsub, localizer: hl, idefer: hid, pathctx: _hpathctx } = (
                    _.isFunction(ctx.ctx) ? ctx.ctx(_host, _path, _sub) : <_Pathed>{}
                );

                if (debugOut && _.isFunction(ctx.ctx)) {
                    console.log(Indent(deepth), 'call:', ctx.path.join('.'), '(', _path.join('.'), ')');
                }

                if (_ihsub !== undefined) { _.set(_host, _key, _ihsub); _sub = _ihsub; }
                if (hid) idefers.push(hid);
                if (hl) tolocal(_ihsub);

                if (hl || sl) {
                    l = l || [];
                    sl && l.push(sl);
                    hl && l.push(hl);
                }

                if (_hpathctx && _hpathctx != ctx.ctx) {
                    const { l: _l, s: _isub } = handleCurrent(_host, _path, _key, _sub, [{ ctx: _hpathctx as _PathCtx, path: [...ctx.path] }], [], deepth + 1);
                    if (_isub !== undefined) { _.set(_host, _key, _isub); _sub = _isub; }
                    _l && (l = l || []).concat(_l);
                }
            }

            return { l, s: _sub };
        }

        _.forEach<_Record>(rsub, (_sub, _key) => {
            const matchctx = pathctx?.ctx[_key] ? { ctx: pathctx?.ctx[_key], path: [...pathctx.path, _key] } : undefined;
            const _path = [...path, _key];
            let _anyctx = anyctx;

            if (matchctx) {
                // need downgrade the pathctx incase in _anyctx
                const idx = _anyctx.findIndex(ac => ac.ctx == pathctx?.ctx);

                if (idx >= 0) {
                    _anyctx = [..._anyctx];

                    const rules = Object.keys(pathctx?.ctx ?? {}).length;
                    if (rules <= 1) {
                        _anyctx.splice(idx, 1);
                    } else {
                        _anyctx[idx].ctx = _.clone(_anyctx[idx].ctx);
                        delete _anyctx[idx].ctx[_key];
                    }
                }
            }

            const usingctx = [..._anyctx, layerctx, matchctx].filter(c => c != null);

            debugOut && console.log(Indent(deepth), pathctx?.path.join('.'), '[', usingctx.map(uc => uc.path.join('.')).join(', '), ']; ', _path.join('.'));
            const { l: _l, s: _isub } = handleCurrent(rsub, _path, _key, _sub, usingctx, _anyctx, deepth);
            if (_isub !== undefined) _sub = _isub;

            // combine if need
            (_l && _l.length > 0) && localizers.push([
                rsub, _path, _sub, (//_l.length == 1 ? _l[0] : 
                    lhost: _Json, path: Path, lsub: _Json, ctx: CTX, injector: Injector, ldefers: _Defer[], deepth: number = -1
                ): _Json => {
                    const _key = path[path.length - 1];

                    for (const l of _l) {
                        if (!l) continue;

                        debugOut && console.log(Indent(deepth), 'localized:', path.join('.'), '; ', pathctx?.path.join('.'), '[', usingctx.map(uc => uc.path.join('.')).join(', '), ']; ', _path.join('.'));
                        const _lsub = l(lhost, path, lsub, ctx, injector, ldefers, deepth + 1);
                        if (lhost && _lsub !== undefined && Ignore != _lsub && Ignore != lsub) {
                            _.set(lhost, _key, _lsub);
                        }

                        if (Ignore != lsub) {
                            lsub = _lsub ?? lsub;
                        }
                    }

                    return lsub;
                }
            ]);
        })

        if (localizers.length > 0) {
            return {
                isub: rsub, localizer: (
                    (lhost: _Json, path: Path, lsub: _Json, ctx: CTX, injector: Injector, ldefers: _Defer[], deepth: number = -1): _Json => {
                        // debugOut && console.log(Indent(deepth), 'localized:', path.join('.'));
                        // sub: rsub ~ isub ~ lsub; 
                        // lhost[path] ~ sub

                        const _lhost = lsub;
                        for (let [_rhost, _path, _isub, l] of localizers) {
                            // sub: rsub ~ isub ~ lsub ~ _rhost; 
                            // (lhost[path] ~ sub)[_key] ~ _isub;

                            const _key = _path[_path.length - 1];
                            _isub = islocaled((_lhost as any)[_key]) ? (_lhost as any)[_key] : _.clone(_isub);
                            const _lsub = l(_lhost, _path, _isub, ctx, injector, ldefers, deepth);
                            if (_lhost && _lsub !== undefined && Ignore != _lsub) {
                                _.set(_lhost, _key, _lsub);
                            }

                            localed(_lsub);
                        }

                        localed(_lhost);
                        return _lhost;
                    }
                )
            }
        }

        return { isub: rsub };
    }

    Object.defineProperties(JSON, {
        Key: {
            enumerable: false, configurable: false,
            value(this: JSON, subkeys?: (string) | (string[])): PropertyDecorator {
                const _subkeys = (subkeys == null ? true : CreateKeys(...(
                    _.isArray(subkeys) ? subkeys : [subkeys]
                )));

                // generate the property decorator function
                return function decorator(prototype: Object, propertyKey: string | symbol): void {
                    if (_.isSymbol(propertyKey)) return;

                    // attach the JSON keys bag to the target.
                    const { keys, keys: { indexed } } = JSONSlot.Of(prototype, true)!;
                    keys.push(propertyKey), indexed[propertyKey] = _subkeys;

                    // attach the toJSON method to the target which will be called by JSON.strinify with instance of target.
                    if (!_.isFunction((prototype as any)['toJSON'])) {
                        Object.defineProperty(prototype, 'toJSON', {
                            writable: true, enumerable: false, configurable: false,
                            value(this: any, res: JSON.Object) {
                                return JsonOf.call(this, this, res);
                            }
                        })
                    }
                }
            }
        },
        polyfill: {
            configurable: false, writable: false,
            value(wnd: Window) {
                _polyfill(wnd);
            }
        },
        KeysOf: {
            configurable: false, writable: false,
            value<TObj extends object | Class = any>(obj: TObj): JSON.Keys | undefined {
                return HandlerOf(obj)?.keys;
            }
        },
        JsonOf: {
            configurable: false, writable: false,
            value(obj: any, res?: JSON.Object): JSON.Type {
                return JsonOf(obj, res);
            }
        },
        HandlerOf: {
            configurable: false, writable: false,
            value<T extends object = object>(obj: T, forceown: boolean): JSON.Handler<T> {
                const handler = {
                    get handles(): JSON.Handles<T> | undefined {
                        return JSONSlot.Of(obj, forceown)?.handles;
                    },
                    handle(key: string): JSON.toJSON<T> | undefined {
                        return handler.handles?.[key];
                    },
                    to(key: string): JSON.Type {
                        return handler.handle(key)?.(obj);
                    },
                }

                return handler;
            }
        },
        Interpret: {
            configurable: false, writable: false,
            value: _.extend(
                <CTX, Json = JSON.Type>(
                    rfulljson: Json, valued: Valued<CTX, Json>, pathctx: PathCtx<CTX, Json>, postLocalizer?: JSON.Interpret.PostLocalizer<CTX, Json>
                ): {
                    localizer?(ctx: CTX, injector: Injector): Json,
                    ifulljson?: Json
                } => {
                    const idefers: IDefer<Json>[] = [];
                    const { isub: ifulljson, localizer: l } = interpret<CTX, Json>(
                        undefined, [], rfulljson, idefers, valued, { ctx: pathctx, path: [] }
                    );

                    if (l) tolocal(ifulljson);
                    if (!ifulljson) {
                        return {}
                    }

                    // execute interpret defer reference.
                    for (const d of idefers) {
                        d(ifulljson);
                    }

                    const result = {
                        ifulljson, localizer(ctx: CTX, injector: Injector) {
                            const ldefers: Defer<CTX, Json>[] = [];

                            let lfulljson = l?.(
                                undefined, [], _.clone(ifulljson),
                                ctx, injector, ldefers, 0
                            ) ?? ifulljson;

                            if (lfulljson != ifulljson) {
                                localed(lfulljson);
                            }

                            // execute localize defer reference
                            for (const d of ldefers) {
                                d(ctx, injector, lfulljson);
                            }

                            lfulljson = postLocalizer?.(lfulljson, ctx, injector) || lfulljson;
                            return lfulljson;
                        }
                    }

                    const slot = InterpretSlot.Of(result.ifulljson, true);
                    slot.localizer = result.localizer as ((ctx: any, injector: Injector) => any);
                    return result;
                },
                {
                    Slot: InterpretSlot,
                    PathCtx: _.extend,
                    Ignore: Ignore,
                }
            )
        }
    })
}

_polyfill(window);

export { };

declare global {
    interface JSON {
        polyfill(wnd: Window): void;

        /**
         * property decorator to define which property can be JSON serialize
         * @param keys; which property/properties of decorated property value can be serilized.
         */
        Key(keys?: (string) | (string[])): PropertyDecorator;

        JsonOf(obj: any, res?: JSON.Object): JSON.Type;
        KeysOf<TObj extends object | Class = any>(obj: TObj): JSON.Keys | undefined;
        HandlerOf<T extends object = object>(obj: T, forceown: boolean): JSON.Handler<T>

        Interpret: {
            <CTX, Json = JSON.Type>(
                rfulljson: Json, valued: JSON.Interpret.Pather<CTX, Json>, pathctx: JSON.Interpret.PathCtx<CTX, Json>, postLocalizer?: JSON.Interpret.PostLocalizer<CTX, Json>
            ): {
                localizer?(ctx: CTX, injector: Injector): Json,
                ifulljson: Json
            };

            PathCtx<CTX, Json = JSON.Type>(
                func: JSON.Interpret.Pather<CTX, Json>,
                handles: Exclude<JSON.Interpret.PathCtx<CTX, Json>, Function>
            ): JSON.Interpret.PathCtx<CTX, Json>;

            readonly Slot: InterpretSlot;
            readonly Ignore: object;
        }
    }

    namespace JSON {
        type Object = {
            [P: string]: null | undefined | string | number | boolean | Object | Array
        }

        type Array = (null | undefined | string | number | boolean | Object | Array)[];

        type Type = null | undefined | string | number | boolean | Object | Array;

        interface Handler<T extends object = object> {
            readonly handles: Handles<T> | undefined;

            handle(key: string): JSON.toJSON<T> | undefined;
            to(key: string): JSON.Type
        }

        interface toJSON<T extends object = object> {
            (obj: T): Type;
        }

        interface Handles<T extends object = object> {
            [P: string]: toJSON<T>
        }

        type Keys = XArray<string, string, {
            [k: string]: Keys | true
        }>

        namespace Interpret {
            type Path = (string | number)[];

            type PostLocalizer<CTX, Json = JSON.Type> = {
                (ljson: Json | undefined, ctx: CTX, injector: Injector): Json | undefined
            }

            type Localizer<CTX, Json = JSON.Type> = {
                (lhost: Json | undefined, path: Path, lsub: Json | undefined, ctx: CTX, injector: Injector, ldefers: Defer<CTX, Json>[], deepth?: number): Json | undefined
            }

            type Valued<CTX, Json = JSON.Type> = {
                (rhost: Json | undefined, path: Path, rsub: Json): Pathed<CTX, Json>
            }

            type Pather<CTX, Json = JSON.Type> = {
                (rhost: Json, path: Path, rsub: Json): Pathed<CTX, Json>
            }

            type Defer<CTX, Json = JSON.Type> = {
                (ctx: CTX, injector: Injector, lfulljson: Json): void
            }

            type Pathed<CTX, Json = JSON.Type> = {
                idefer?: (ifulljson: Json) => void,
                localizer?: Localizer<CTX, Json>,
                pathctx?: PathCtx<CTX, Json>,
                isub?: Json,
            }

            type PathCtx<CTX, Json = JSON.Type> = Pather<CTX, Json> | (
                (Pather<CTX, Json> | {}) & {
                    // [string | number] 
                    //   Priority: 1; apply to self
                    // [*] 
                    //   Priority: 2; apply to any children
                    // [**] 
                    //   Priority: 3; apply to any level of decendants
                    [p: string | number]: PathCtx<CTX, Json>
                }
            );
        }
    }
}

namespace Test {
    type PathCtx<CTX, Json = JSON.Type> = JSON.Interpret.PathCtx<CTX, Json>;
    type Pathed<CTX, Json = JSON.Type> = JSON.Interpret.Pathed<CTX, Json>;
    type Pather<CTX, Json = JSON.Type> = JSON.Interpret.Pather<CTX, Json>;
    type Valued<CTX, Json = JSON.Type> = JSON.Interpret.Valued<CTX, Json>;
    type Defer<CTX, Json = JSON.Type> = JSON.Interpret.Defer<CTX, Json>;
    type IDefer<Json = JSON.Type> = (ifulljson: Json) => void;

    type tt = (Pather<{}, {}> | {}) & Exclude<PathCtx<{}, {}>, Pather<{}, {}>>
}