import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { PuSchemaService } from '../../utils/puzzle/pu.service/pu.schema.service';
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuAlertService } from '../../utils/puzzle/pu.service/pu.alert.service';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { PuHttpService } from '../../utils/puzzle/pu.service/pu.http.service';
import { PuSysService } from '../../utils/puzzle/pu.service/pu.sys.service';
import { FieldProperty } from '../../utils/view/model/field.property';
import { AuthService } from '../service/auth.service';
import { AppService } from '../service/app.service';

@Component({
    selector: 'application, [application]',
    templateUrl: "./app.component.html",
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends FieldProperty implements OnInit {
    readonly Status = PuHttpService.Status;
    readonly currentUrl: string = "";
    readonly _ = _;

    constructor(
        public auth: AuthService,
        public alerts: PuAlertService,
        public override app: AppService,
        private platformLocation: PlatformLocation,
    ) {
        super(app);

        const { platformLocation: { protocol, hostname } } = this;
        this.currentUrl = `${protocol}//${hostname}/mobileapp/downapp.html`;
    }

    ngOnInit() {
    }

    disclaimer() {
        const { app: { version: { server: { version } = {} } } } = this;
        window.open(`assets/i18n/disclaimer?version=${version}`);
    }
}
