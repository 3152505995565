import { HasEventTargetAddRemove, EventListenerOptions } from 'rxjs/internal/observable/fromEvent';
import { Subscription, fromEvent, Observable, merge, ObservableInput } from 'rxjs';
import { outputToObservable } from '@angular/core/rxjs-interop';
import { OutputEmitterRef } from '@angular/core';

import { O } from 'ts-toolbelt';
import { Except } from 'type-fest';

abstract class _Differ {
    abstract clear(): void;
    abstract check(): void;
}

function isDifferMap(o?: Differ.Types | Differ.PartialMap): o is Differ.PartialMap {
    if (!o || o instanceof _Differ) return false;
    return true;
}

function _clear(d?: Differ.Types) {
    d?.clear();
}

function _check(d?: Differ.Types) {
    d?.check();
}

function _through(settings: Differ.Types | Differ.PartialMap | undefined, dfn: (d?: Differ.Types) => void) {
    if (!settings) {
        return;
    }

    if (!isDifferMap(settings)) {
        dfn(settings);
        return;
    }

    for (const _k in settings) {
        const _differ = settings[_k];

        if (isDifferMap(_differ)) {
            _through(_differ, dfn);
            continue;
        }

        dfn(_differ);
    }
}

function _extend(src: Differ.PartialMap, target: Differ.PartialMap) {
    for (const k in src) {
        const _target = target[k];
        const _src = src[k];

        if (_src !== _target) {
            // unset the differ
            _through(_target, _clear);
            delete target[k];

            if (_src) {
                // reset the differ
                target[k] = _src;
            }

            continue;
        }

        if (isDifferMap(_src) && isDifferMap(_target)) {
            _extend(_src, _target);
        }
    }
}

export class Differ<Map extends Differ.Map = Differ.Map> {
    private _props = Prop.Of<Differ<Map>, {
        map?: Differ.PartialMap<Map>
    }>(this);

    get map(): Differ.PartialMap<Map> {
        const { _props: props } = this;
        return (props.map || (
            props.map = {}
        ))
    }

    constructor() {
    }

    extend(settings: Differ.PartialMap<Map>): Differ<Map> {
        _extend(settings, this.map);
        return this;
    }

    check(): Differ<Map> {
        _through(this.map, _check);
        return this;
    }

    clear(): Differ<Map> {
        _through(this.map, _clear);
        delete this._props.map;
        return this;
    }

    static create<Map extends Differ.Map>(settings: Differ.PartialMap<Map> = {}): Differ<Map> {
        const differ: Differ<Map> = new Differ<Map>();
        return differ.extend(settings);
    };
};

export namespace Differ {
    export type Types = Differ.Value<any> | Differ.Shallow<any> | Differ.Event<any>;

    export type PartialMap<Map extends Differ.Map = Differ.Map> = (
        O.Partial<Map, 'deep'>
    )

    export interface Map {
        [key: string]: Types | Map;
    }
}

export namespace Differ {
    /**
     * A value difference checker.
     */
    export class Value<T = any> extends _Differ {
        private _props = Prop.Of<Value<any>, {
            _handler: (diff?: Value.Diffed<T>) => void;
            _cleared: boolean;
            _valf: () => T;
            _val?: T;
        }>(this);

        private value(val?: T): T | undefined {
            const { _props: { _valf } } = this;
            if (_.isUndefined(val) && _valf) {
                return _valf();
            }

            return val;
        }

        constructor(val: T | (() => T), handler: (diff?: Value.Diffed<T>) => void, thisArg?: any) {
            super();

            const { _props: props } = this;
            if (_.isFunction(val)) {
                props._valf = val;
                val = this.value()!;
            }

            props._handler = thisArg ? handler.bind(thisArg) : handler;
            props._cleared = false;
            props._val = val;
        }

        clear() {
            const { _props: props } = this;
            props._cleared = true;
        }

        check(val?: T): Value.Diffed<T> | undefined {
            const { _props: props, _props: { _cleared, _val, _handler } } = this;
            if (_cleared) return;

            val = this.value(val);
            if (_val == val) {
                return;
            }

            const diff = {
                current: val,
                previous: _val
            }

            _handler?.(diff);
            props._val = val;
            return diff;
        }

        static create<T>(val: T | (() => T), handler: (diff?: Value.Diffed<T>) => void, thisArg?: any): Value<T> {
            return new Value(val, handler, thisArg);
        }
    }

    export namespace Value {
        export interface Diffed<T> {
            current: T | undefined,
            previous: T | undefined
        }
    }
}

export namespace Differ {
    /**
     * A shadow difference checker.
     */
    export function shallow<
        T extends object = Object | Array<any>
    >(objOrig?: T, objNew?: T): Differ.Shallow.Diffed<T> | undefined {
        if (!objOrig && !objNew) return;

        const result = Array.from(new Set([
            ...Object.keys(objOrig || {}),
            ...Object.keys(objNew || {})
        ])).reduce((res, key) => {
            const hasold = objOrig?.hasOwnProperty(key);
            const hasnew = objNew?.hasOwnProperty(key);

            if (hasold && hasnew) {
                const vold = (objOrig as any)?.[key];
                const vnew = (objNew as any)?.[key];

                if (vold != vnew) {
                    (res.result.previous as any)[key] = vold;
                    (res.result.current as any)[key] = vnew;
                    res.changed = true;
                }
            } else if (hasold) {
                (res.result.previous as any)[key] = (objOrig as any)[key];
                res.changed = true;
            } else {
                (res.result.current as any)[key] = (objNew as any)[key];
                res.changed = true;
            }

            return res;
        }, {
            changed: false as boolean,
            result: <Differ.Shallow.Diffed<T>>{
                previous: {},
                current: {}
            }
        });

        return result.changed ? result.result : undefined;
    }

    export class Shallow<
        T extends object = Object | Array<any>
    > extends _Differ {
        private _props = Prop.Of<Shallow<T>, {
            _handler: (diff?: Shallow.Diffed<T>) => void;
            _cleared: boolean;
            _valf: () => T;
            _val: T;
        }>(this);

        private value(val?: T): T | undefined {
            const { _props: { _valf } } = this;
            if (_.isUndefined(val) && _valf) {
                return _valf();
            }

            return val;
        }

        constructor(val: T | (() => T), handler: ((diff?: Shallow.Diffed<T>) => void), thisArg?: any) {
            super();

            const { _props: props } = this;
            if (_.isFunction(val)) {
                props._valf = val as (() => T);
                val = this.value()!;
            }

            props._handler = thisArg ? handler.bind(thisArg) : handler;
            props._val = { ...val };
            props._cleared = false;
        }

        clear() {
            const { _props: props } = this;
            props._cleared = true;
        }

        check(val?: T): Shallow.Diffed<T> | undefined {
            const { _props: props, _props: { _cleared, _val, _handler } } = this;
            if (_cleared) return;

            val = this.value(val);
            const diff = shallow(_val, val);
            props._val = { ...(val ?? <T>{}) };

            diff && _handler?.(diff);
            return diff;
        }

        static create<
            T extends object = Object | Array<any>
        >(val: T | (() => T), handler: ((diff?: Shallow.Diffed<T>) => void), thisArg?: any): Shallow<T> {
            return new Shallow(val, handler, thisArg);
        }
    }

    export namespace Shallow {
        type Keys<T> = Exclude<keyof T, T extends object ? O.SelectKeys<T, Function> : keyof T>
        export interface Diffed<T> {
            current: {
                [K in Keys<T>]?: T[K];
            },
            previous: {
                [K in Keys<T>]?: T[K];
            }
        }
    }
}

export namespace Differ {
    /**
     * A event notifier checker.
     */
    export class Event<TE = globalThis.Event> extends _Differ {
        private _subscription?: Subscription;

        static fromEvent<TE extends globalThis.Event>(
            target: (HasEventTargetAddRemove<TE> | undefined) | (HasEventTargetAddRemove<TE> | undefined)[],
            eventName: string | (string[]), options?: EventListenerOptions
        ): Observable<TE> | undefined {
            target = _.uniq(_.castArray(target)).filter(t => !!t)
            if (target.length <= 0) return;

            const eventnames = _.castArray<string>(eventName).filter(event => !!event).
                reduce((res: string[], event: string) => {
                    return [...res, ...event.split(' ')];
                }, []);

            const os = eventnames.reduce<ObservableInput<TE>[]>((res, evt) => {
                res.push(fromEvent(target as HasEventTargetAddRemove<TE>[], evt, options!));
                return res;
            }, [])

            return merge(...os);
        }

        static create<TE>(event: OutputEmitterRef<TE> | Observable<TE> | undefined | (OutputEmitterRef<TE> | Observable<TE> | undefined)[], handler: (val: TE) => void, thisArg?: any): Event<TE> | undefined {
            const _event = _.reduce(_.castArray(event), (res: (OutputEmitterRef<TE> | Observable<TE>)[], e) => (
                e && res.push(e), res
            ), []);

            if (_event.isEmpty) return;
            return new Event(
                _event,
                handler,
                thisArg
            );
        }

        constructor(event: OutputEmitterRef<TE> | Observable<TE> | (OutputEmitterRef<TE> | Observable<TE>)[], handler: (val: TE) => void, thisArg?: any) {
            super();

            const _event = merge(...(_.isArray(event) ? event : [event]).map(e => {
                return e instanceof OutputEmitterRef ? outputToObservable(e) : e;
            }));

            handler = thisArg ? handler.bind(thisArg) : handler;
            this._subscription = _event.subscribe(handler);
        }

        clear() {
            this._subscription?.unsubscribe();
            delete this._subscription;
        }

        check() {

        }
    }
}
