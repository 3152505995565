import { RouterModule, Routes } from '@angular/router';

import { PuVersioinService } from '../../utils/puzzle/pu.service/pu.version.service';
import { PuSchemaService } from '../../utils/puzzle/pu.service/pu.schema.service';

import { OrgnizationComponent } from '../../orgnization/view/orgnization.component';
import { SystemsetComponent } from '../../systemset/view/systemset.component';
import { SuperviseComponent } from '../../supervise/view/supervise.component';
import { FrameComponent } from '../../application/view/frame/frame.component';
import { SummaryComponent } from '../../summary/view/summary.component';
import { ProcessComponent } from '../../process/view/process.component';
import { LoginComponent } from '../../login/view/login.component';
import { AuditComponent } from '../../audit/view/audit.component';
import { UserComponent } from '../../user/view/user.component';
import { TeamComponent } from '../../team/view/team.component';
import { PlanComponent } from '../../plan/view/plan.component';
import { HomeComponent } from '../../home/view/home.component';
import { AppService } from '../service/app.service';
import { NgModule } from '@angular/core';

const routes: Routes = [{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
}, {
    path: 'login',
    component: LoginComponent,
    resolve: {
        version: PuVersioinService
    }
}, {
    path: '',
    data: { level: 1 },
    component: FrameComponent,
    resolve: {
        version: PuVersioinService,
        schema: PuSchemaService,
        lang: AppService
    },
    children: [{
        path: 'home',
        data: {
            title: 'navigation.home',
            icon: 'home',
            class: ''
        },
        component: HomeComponent
    }, {
        path: 'orgnization',
        data: {
            title: 'navigation.orgnization',
            icon: 'domain',
            class: ''
        },
        component: OrgnizationComponent
    }, {
        path: 'user',
        data: {
            title: 'navigation.user',
            icon: 'group',
            class: ''
        },
        component: UserComponent
    }, {
        path: 'process',
        data: {
            title: 'navigation.process',
            icon: 'assignment',
            class: ''
        },
        component: ProcessComponent
    }, {
        path: 'team',
        data: {
            title: 'navigation.team',
            icon: 'apps',
            class: ''
        },
        component: TeamComponent
    }, {
        path: 'plan',
        data: {
            title: 'navigation.plan',
            icon: 'ballot',
            class: ''
        },
        component: PlanComponent
    }, {
        path: 'audit',
        data: {
            title: 'navigation.workitemexe',
            icon: 'list_alt',
            class: ''
        },
        component: AuditComponent
    }, {
        path: 'supervise',
        data: {
            title: 'navigation.supervise',
            icon: 'bookmarks',
            class: ''
        },
        component: SuperviseComponent
    }, {
        path: 'summary',
        data: {
            title: 'navigation.summary',
            icon: 'dashboard',
            class: ''
        },
        component: SummaryComponent
    }, {
        path: 'systemset',
        data: {
            title: 'navigation.systemset',
            icon: 'settings',
            class: ''
        },
        component: SystemsetComponent
    }]
}];

const ImportExports = [
    RouterModule.forRoot(routes, {
        useHash: true
    })
]

@NgModule({
    imports: [
        ImportExports
    ],
    providers: [
        // provideRouter(routes, withComponentInputBinding()),
    ],
    exports: [
        ImportExports
    ],
})
export class AppRoutingModule { }
