<div class="dropdown open show ngx-contextmenu" [ngClass]="menuClass" tabindex="0">
    <ul #menu class="dropdown-menu show" style="position: static; float: none;" tabindex="0">
        <li #li *ngFor="let menuItem of menuItems; let i = index" [class.disabled]="!isMenuItemEnabled(menuItem)"
            [class.divider]="menuItem.divider" [class.dropdown-divider]="useBootstrap4 && menuItem.divider"
            [class.active]="menuItem.isActive && isMenuItemEnabled(menuItem)"
            [attr.role]="menuItem.divider ? 'separator' : undefined">
            <a *ngIf="!menuItem.divider && !menuItem.passive" href [class.dropdown-item]="useBootstrap4"
                [class.active]="menuItem.isActive && isMenuItemEnabled(menuItem)"
                [class.disabled]="useBootstrap4 && !isMenuItemEnabled(menuItem)" [class.hasSubMenu]="!!menuItem.subMenu"
                (click)="onMenuItemSelect(menuItem, $event)" (mouseenter)="onOpenSubMenu(menuItem, $event)">
                <ng-template [ngTemplateOutlet]="menuItem.template"
                    [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
            </a>

            <span (click)="stopEvent($event)" (contextmenu)="stopEvent($event)" class="passive"
                *ngIf="!menuItem.divider && menuItem.passive" [class.dropdown-item]="useBootstrap4"
                [class.disabled]="useBootstrap4 && !isMenuItemEnabled(menuItem)">
                <ng-template [ngTemplateOutlet]="menuItem.template"
                    [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
            </span>
        </li>
    </ul>
</div>