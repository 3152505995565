import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

import { NgxAMapCreaterService } from './ngx-amap.creater.service';
import { AMapLoggerService } from '../amap-logger.service';

const TAG = 'PluginLoader';

@Injectable()
export class NgxAMapPluginService {
    private readonly state = new Map<string, ReplaySubject<void>>();

    constructor(
        private readonly creater: NgxAMapCreaterService,
        private readonly logger: AMapLoggerService
    ) {
    }

    load(name: string | string[]): Observable<void> {
        const pKey = (Array.isArray(name) ? name.join(',') : name);
        const { state, creater, logger } = this;

        if (state.has(pKey)) {
            return state.get(pKey)!;
        }

        logger.d(TAG, 'loading plugin:', pKey, '...');
        return creater.get().pipe(
            switchMap(
                (amap: AMap.Map) => {
                    const loading$ = new ReplaySubject<void>(1);

                    amap.plugin(name, () => {
                        logger.d(TAG, 'loading plugin:', pKey, 'COMPLETE');

                        loading$.next();
                        loading$.complete();
                    });

                    state.set(pKey, loading$);
                    return loading$;
                }
            )
        );
    }
}
