import { Directive, Input, NgZone, OnChanges, OnDestroy, Optional, output, SimpleChanges } from "@angular/core";
import { zip } from "rxjs";

import { NgxAMapPositionPickerService } from "../shared/inner/ngx-amap-positionpicker.service";
import { NgxAMapCreaterService } from "../shared/inner/ngx-amap.creater.service";
import { AMapEventBinderService } from "../shared/amap-binder-event.service";
import { NgxAMapComponent } from "./ngx-amap.component/ngx-amap.component";
import { AMapLoggerService } from "../shared/amap-logger.service";
import { ChangeFilter, getOptions } from "../utils/options";

const TAG = "ngx-amap-positionpicker";
const ALL_OPTIONS: Extract<
    keyof NgxAMapPositionPickerDirective,
    keyof AMap.PositionPicker.Options
>[] = [
        'mode',
        'iconStyle'
    ];

@Directive({
    selector: 'ngx-amap-positionpicker,[ngx-amap-positionpicker]',
    exportAs: 'ngxAMapPositionPicker',
    providers: [NgxAMapPositionPickerService],
    host: {
        '[style.display]': "'none'"
    }
})
export class NgxAMapPositionPickerDirective implements OnChanges, OnDestroy {
    private subscription: (() => void)[] = [];
    private inited: boolean = false;

    @Input('ngx-amap-positionpicker')
    ngxAMap?: NgxAMapComponent;

    @Input()
    mode?: AMap.PositionPicker.Options['mode'];

    @Input()
    iconStyle?: AMap.PositionPicker.Options['iconStyle']

    @Input()
    poi?: AMap.Poi;

    readonly naReady = output<AMap.PositionPicker>();
    readonly naPoi = output<AMap.Poi | undefined>({ alias: 'poiChange' });

    constructor(
        private readonly os: NgxAMapPositionPickerService,
        private readonly binder: AMapEventBinderService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone,

        @Optional()
        private readonly amaps?: NgxAMapCreaterService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const host = (this.ngxAMap || this.amaps);
        if (!host) return;

        const filter = ChangeFilter.from(changes);
        const pps = this.get();

        if (!this.inited) {
            host.get().subscribe(
                (amap) => {
                    this.logger.d(TAG, 'initializing ...');

                    const options = getOptions<
                        NgxAMapPositionPickerDirective, AMap.PositionPicker.Options
                    >(this, ALL_OPTIONS);
                    options.map = amap;

                    this.logger.d(TAG, 'options:', options);
                    this.os.create(options).subscribe(
                        m => {
                            this.ngZone.run(() => {
                                this.naReady.emit(m);
                            })

                            let handler: any;
                            m.on('fail', (handler = () => {
                                this.ngZone.run(() => {
                                    delete this.poi;
                                    this.naPoi.emit(this.poi);
                                })
                            }));
                            this.subscription.push(() => m.off('fail', handler));

                            m.on("success", (handler = ({ position, address }: AMap.PositionPicker.PositionPickerResult) => {
                                this.ngZone.run(() => {
                                    this.naPoi.emit((this.poi = {
                                        location: position,
                                        address: address
                                    }))
                                })
                            }));
                            this.subscription.push(() => m.off('success', handler));

                            const { poi: { location } = {} } = this;

                            if (location) {
                                const orgPoint = location instanceof AMap.LngLat ? location : (
                                    Array.isArray(location) ? new AMap.LngLat(
                                        typeof location[0] === 'string' ? Number.parseFloat(location[0]) : location[0],
                                        typeof location[1] === 'string' ? Number.parseFloat(location[1]) : location[1]
                                    ) : undefined
                                );

                                m.start(orgPoint);
                            } else {
                                m.start();
                            }

                            this.logger.d(TAG, 'Placesearch is ready.');
                        }
                    )

                    this.inited = true;
                }
            )
        }

        zip(filter.has('mode'), pps).subscribe(
            ([v, pp]) => {
                pp.setMode(v);
            }
        )
    }

    ngOnDestroy(): void {
        this.subscription.forEach(v => v());
        this.os.destroy();
    }

    start(orgPoint?: AMap.LngLat): void {
        this.get().subscribe(pp => pp.start(orgPoint));
    }

    stop(): void {
        this.get().subscribe(pp => pp.stop());
    }

    get() {
        return this.os.get();
    }
}