import { Component, ElementRef, Injector, Input, ViewChild } from "@angular/core";
import { ECElementEvent, EChartsOption } from "echarts";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe } from "@angular/common";

import { PuSchemaService } from "../pu.service/pu.schema.service";
import { PuDialogService } from "../pu.dialog/pu.dialog.service";
import { PuTemplateService } from "../pu.template/pu.template";

type GraphicOption = Exclude<EChartsOption['graphic'], any[] | undefined>;

type EChartEventNode = Exclude<ECElementEvent['event'], undefined>['target']['parent'] & {
    $$action?: ($event: ReturnType<PuDashboardComponent['graphicParams']>) => void;
    parent?: EChartEventNode,
    child?: EChartEventNode,
    index: number
}

interface ISetting {
    dashboard?: Schema.Dashboard | string;
}

@Component({
    templateUrl: "./pu.dashboard.component.html",
    styleUrls: ["./pu.dashboard.component.scss"],
    selector: "puDashboard, [puDashboard]",
    providers: [PuTemplateService],
    exportAs: "puDashboard"
}) export class PuDashboardComponent {
    private _props = Prop.Of<PuDashboardComponent, {
        puDashboard: ISetting
    }>(this, (values) => {
        values.puDashboard = {}
    });

    readonly _ = _;

    @Input('dashboard')
    set dashboard(val: ISetting['dashboard']) {
        const { _props: { puDashboard } } = this;
        puDashboard.dashboard = val;
    };

    get dashboard(): Schema.Dashboard | undefined {
        const { _props: { puDashboard: { dashboard } }, es } = this;
        return _.isString(dashboard) ? es.dashboards[dashboard] : dashboard;
    }

    @Input('puDashboard')
    set puDashboard(setting: ISetting) {
        this.dashboard = setting?.dashboard;
    }

    get puDashboard(): ISetting | undefined {
        const { _props: { puDashboard } } = this;
        return puDashboard;
    }

    constructor(
        public translate: TranslateService,
        public pudialog: PuDialogService,
        public tpls: PuTemplateService,
        public es: PuSchemaService,
        public datePipe: DatePipe,
        public injector: Injector
    ) {
    }

    onCardClick(action: (param: any) => void, card: Schema.Dashboard.Block.Cards.Card) {
        const param = { ...card, injector: this.injector };
        const { es: { actions: { $events } } } = this;

        $events.push(param);
        action(param);
        $events.pop();
    }

    onClickChart(params: ECElementEvent, setting: { option: EChartsOption, merge: EChartsOption }): void {
        const { es: { actions: { $events } } } = this;

        switch (params.componentType) {
            case 'graphic': {
                // params.event!.target.parent.parent.parent._children.indexOf(params.event!.target.parent.parent);
                const _param = this.graphicParams(params, setting);
                const _graphic = _param.graphic;

                let _last: EChartEventNode | undefined;
                for (_last = _graphic; _last.child; _last = _last.child);
                for (let node = _last; node; node = node.parent) {
                    const { $$action: act } = node;
                    if (act) {
                        _param.graphic = node;
                        $events.push(_param);
                        act?.(_param);
                        $events.pop();
                        break;
                    }
                }
            } return;

            case 'series': {
                const _param = this.seriesParams(params, setting);
                const { series: { $$action: sact } } = _param;
                const { data: { $$action: dact } } = _param;
                const act = (dact ?? sact);

                $events.push(_param);
                act?.(_param);
                $events.pop();
            } return;
        }
    }

    private graphicParams(params: ECElementEvent, setting: { option: EChartsOption, merge: EChartsOption }): ECElementEvent & {
        graphic: EChartEventNode,

        option: EChartsOption,

        merge: EChartsOption,

        injector: Injector
    } {
        const { option = {}, merge = {} } = setting, { injector } = this;
        params = { ...params, option, merge, injector };

        // build clicked component information
        const component = (params.component = { ...params.component || {} });
        component.id = params.id ?? option.id ?? merge.id;
        component.subType = params.componentSubType;
        component.index = params.componentIndex;
        component.type = params.componentType

        // build clicked graphic information
        // let graphic: EChartEventNode = (params.graphic = { ...params.graphic || {} });
        let node: EChartEventNode | undefined;
        for (let target = params.event!.target; target; target = target.parent) {
            const index = _.indexOf(target.parent?.childrenRef(), target);
            const newnode = <EChartEventNode>{ ...target, index }
            if (node) (newnode.child = node).parent = newnode;
            node = newnode;
        }

        const mapnode = (defs?: GraphicOption | GraphicOption[]): void => {
            let _node: EChartEventNode | undefined, _defs: GraphicOption[] | undefined;
            if (_.isArray(defs)) _node = node?.child, _defs = defs;
            else if (defs) _node = node, _defs = [defs];

            const merger = (oval: any, sval: any, key: any) => {
                if (sval == null) return oval;
                if (oval == null) return sval;

                if (!_.isObject(sval)) return sval;
                if (!_.isObject(oval)) return oval;

                if (_.isArray(sval) && !_.isArray(oval)) return sval;
                if (!_.isArray(sval) && _.isArray(oval)) return oval;

                return _.extendWith(_.clone(oval), sval, merger);
            }

            for (; _node; _node = _node?.child) {
                const _def = _defs?.[_node?.index ?? -1];
                if (_def == null) return;

                _.extendWith(_node, _def, merger);
                _defs = (_def as any).children;
            }
        }

        mapnode(option.graphic), mapnode(merge.graphic);
        params.graphic = node;
        return params as any;
    }

    private seriesParams(params: ECElementEvent, setting: { option: EChartsOption, merge: EChartsOption }): ECElementEvent & {
        component: {
            [key: string]: any;
            subType: string;
            index: number;
            type: string
        },

        series: {
            [key: string]: any;
            $$action?: ($event: ReturnType<PuDashboardComponent['seriesParams']>) => void;
            index?: number;
            type?: string;
            name?: string;
            id?: string;
        },

        data: {
            [key: string]: any;
            $$action?: ($event: ReturnType<PuDashboardComponent['seriesParams']>) => void;
            type?: (typeof params)['dataType'];
            percent?: number;
            index?: number;
        },

        option: EChartsOption,

        merge: EChartsOption,

        injector: Injector
    } {
        const { option = {}, merge = {} } = setting, { injector } = this;
        params = { ...params, option, merge, injector };

        // build clicked component information
        const component = (params.component = { ...params.component || {} });
        component.index = (params.componentIndex ?? -1) + 1;
        component.id = params.id ?? option.id ?? merge.id;
        component.subType = params.componentSubType;
        component.type = params.componentType;

        // build clicked series information
        const series = (params.series = { ...(params.series || {}) });
        series.index = (params.seriesIndex ?? -1) + 1;
        series.type = params.seriesType;
        series.name = params.seriesName;
        series.id = params.seriesId;

        const _oseries = (_.isArray(option.series) ? option.series[series.index - 1] : option.series) ?? {};
        const _mseries = (_.isArray(merge.series) ? merge.series[series.index - 1] : merge.series) ?? {};
        _.extend(series, _oseries, _mseries);

        // build clicked data information
        const data = (params.data = (_.isPlainObject(params.data) ? { ...(params.data as any) } : { value: params.data }));
        data.index = (params.dataIndex ?? -1) + 1;
        data.percent = params.percent;
        data.type = params.dataType;
        data.name = params.name;

        const _odata = (_.isArray(_oseries.data) ? _oseries.data[data.index - 1] : _oseries.data) ?? {};
        const _mdata = (_.isArray(_mseries.data) ? _mseries.data[data.index - 1] : _mseries.data) ?? {};
        _.extend(data, _odata, _mdata);

        return params as any;
    }
}