import { Component, OnInit, Input, NgZone, ElementRef, Renderer2, HostBinding, Output, EventEmitter } from '@angular/core';

import { GovSplitterPanelDirective } from './gov.splitter.panel.directive';

const index = (() => {
    let num = 0;
    return function () {
        num++;
        return num;
    }
})();

interface IPoint {
    x: number;
    y: number;
}

@Component({
    selector: 'gov-splitter, [gov-splitter]',
    templateUrl: "./gov.splitter.component.html",
    styleUrls: ['./gov.splitter.component.scss'],
    exportAs: 'GovSplitter'
})
export class GovSplitterComponent implements OnInit {
    private _props = Prop.Of(this);

    @Input('splitterBarWidth')
    splitterBarWidth: number = 8;

    @Input()
    set direction(v: 'horizontal' | 'vertical') {
        const { _props, displayedPanels, hidedPanels, isDragging, direction } = this;

        v = (v === 'vertical') ? 'vertical' : 'horizontal';
        _props.direction = v;

        [...displayedPanels, ...hidedPanels].forEach(panel => {
            panel.setStyleVisibleAndDir(panel.visible, isDragging, direction);
        });

        this.build();
    }

    get direction(): 'horizontal' | 'vertical' {
        return this._props.direction || 'horizontal';
    }

    @Output()
    sizeChange: EventEmitter<any> = new EventEmitter();

    @HostBinding('style.flex-direction')
    get cssFlexdirection() {
        return (this.direction === 'horizontal') ? 'row' : 'column';
    }

    @HostBinding('style.display')
    get cssDisplay() {
        return 'flex';
    }

    private readonly dragStartValues = {
        sizePixelContainer: 0,
        sizePixelA: 0,
        sizePixelB: 0,
        sizePercentA: 0,
        sizePercentB: 0,
    };

    draggingWithoutMove?: boolean;
    currentbarNum?: number;

    private isDragging: boolean = false;
    private readonly dragListeners: Array<Function> = [];
    private readonly hidedPanels: Array<GovSplitterPanelDirective> = [];
    public readonly displayedPanels: Array<GovSplitterPanelDirective> = [];

    constructor(
        private ngZone: NgZone,
        private elRef: ElementRef,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    build() {
        const { displayedPanels } = this;
        if (displayedPanels.every(dp => dp.index !== null)) {
            displayedPanels.sort((a, b) => (<number>a.index) - (<number>b.index));
        }

        displayedPanels.forEach((panel: GovSplitterPanelDirective, key: number) => {
            panel.order = 2 * key;
        })

        this.refreshStyleSizes();
    }

    private refreshStyleSizes(): void {
        const { displayedPanels, splitterBarWidth } = this;
        const sumbarSize = splitterBarWidth * (displayedPanels.length - 1);

        displayedPanels.forEach(panel => {
            const { size = 0 } = panel;
            panel.setStyleFlexbasis(`calc( ${size}% - ${sumbarSize * size / 100}px )`)
        });
    }

    addPanel(panel: GovSplitterPanelDirective): void {
        const { displayedPanels, hidedPanels, isDragging, direction } = this;
        panel.index = index();

        if (panel.visible) {
            displayedPanels.push(panel)
        } else {
            hidedPanels.push(panel);
        }

        panel.setStyleVisibleAndDir(panel.visible, isDragging, direction);

        this.build();
    }

    updatePanel(panel: GovSplitterPanelDirective): void {
        if (this.displayedPanels.find(dp => dp === panel)) {
            this.build();
        }
    }

    showPanel(panel: GovSplitterPanelDirective): void {
        const { displayedPanels, hidedPanels, isDragging, direction } = this;
        const area = hidedPanels.find(a => a === panel);

        if (area) {
            panel.setStyleVisibleAndDir(panel.visible, isDragging, direction);

            const panels = hidedPanels.splice(hidedPanels.indexOf(area), 1);
            displayedPanels.push(...panels);

            this.build();
        }
    }

    hidePanel(panel: GovSplitterPanelDirective): void {
        const { displayedPanels, hidedPanels, isDragging, direction } = this;
        const area = displayedPanels.find(a => a === panel);

        if (area) {
            panel.setStyleVisibleAndDir(panel.visible, isDragging, direction);

            const areas = displayedPanels.splice(displayedPanels.indexOf(area), 1);
            hidedPanels.push(...areas);

            this.build();
        }
    }

    removePanel(panel: GovSplitterPanelDirective): void {
        const { displayedPanels, hidedPanels } = this;
        if (displayedPanels.some(dp => dp === panel)) {
            displayedPanels.remove(panel);
            this.build();
            return;
        }

        if (hidedPanels.some(hp => hp === panel)) {
            hidedPanels.remove(panel);
        }
    }

    startDragging(startEvent: MouseEvent | TouchEvent, barOrder: number, barNum: number): void {
        startEvent.preventDefault();

        this.currentbarNum = barNum;
        this.draggingWithoutMove = true;

        this.ngZone.runOutsideAngular(() => {
            const { dragListeners, renderer } = this;
            dragListeners.push(renderer.listen('document', 'mouseup', (e: MouseEvent) => this.stopDragging()));
            dragListeners.push(renderer.listen('document', 'touchend', (e: TouchEvent) => this.stopDragging()));
            dragListeners.push(renderer.listen('document', 'touchcancel', (e: TouchEvent) => this.stopDragging()));
        });

        const { displayedPanels } = this;
        const panelA = displayedPanels.find(dp => dp.order === barOrder - 1);
        const panelB = displayedPanels.find(a => a.order === barOrder + 1);
        if (!panelA || !panelB) return;

        let alterPanelA: GovSplitterPanelDirective | undefined;

        const { dragStartValues, direction, elRef } = this;
        const prop = (direction === 'horizontal') ? 'offsetWidth' : 'offsetHeight';
        dragStartValues.sizePixelContainer = elRef.nativeElement[prop];
        dragStartValues.sizePixelA = panelA.getSizePixel(prop);
        dragStartValues.sizePixelB = panelB.getSizePixel(prop);
        dragStartValues.sizePercentA = panelA.size ?? 0;
        dragStartValues.sizePercentB = panelB.size ?? 0;

        let start: IPoint;
        if (startEvent instanceof MouseEvent) {
            start = {
                x: startEvent.screenX,
                y: startEvent.screenY,
            };
        }
        else if (startEvent instanceof TouchEvent) {
            start = {
                x: startEvent.touches[0].screenX,
                y: startEvent.touches[0].screenY,
            };
        }
        else {
            return;
        }

        const { ngZone, dragListeners } = this;
        ngZone.runOutsideAngular(() => {
            dragListeners.push(this.renderer.listen('document', 'mousemove', (e: MouseEvent) => this.dragEvent(e, start, panelA, panelB, alterPanelA)));
            dragListeners.push(this.renderer.listen('document', 'touchmove', (e: TouchEvent) => this.dragEvent(e, start, panelA, panelB, alterPanelA)));
        });

        this.isDragging = true;
    }

    private dragEvent(event: MouseEvent | TouchEvent, start: IPoint, panelA: GovSplitterPanelDirective, panelB: GovSplitterPanelDirective, alterPanelA?: GovSplitterPanelDirective): void {
        if (!this.isDragging) {
            return;
        }

        let end: IPoint;
        if (event instanceof MouseEvent) {
            end = {
                x: event.screenX,
                y: event.screenY,
            };
        }
        else if (event instanceof TouchEvent) {
            end = {
                x: event.touches[0].screenX,
                y: event.touches[0].screenY,
            };
        }
        else {
            return;
        }

        this.draggingWithoutMove = false;
        this.drag(start, end, panelA, panelB, alterPanelA);
    }

    private drag(start: IPoint, end: IPoint, panelA: GovSplitterPanelDirective, panelB: GovSplitterPanelDirective, alterPanelA?: GovSplitterPanelDirective): void {
        let offsetPixel = (this.direction === 'horizontal') ? (start.x - end.x) : (start.y - end.y);
        let newSizePixelA = this.dragStartValues.sizePixelA - offsetPixel;
        let newSizePixelB = this.dragStartValues.sizePixelB + offsetPixel;

        if (this.dragStartValues.sizePercentA === 0) {
            panelB.size = this.dragStartValues.sizePercentB / this.dragStartValues.sizePixelB * newSizePixelB;
            panelA.size = this.dragStartValues.sizePercentB - panelB.size;
        }
        else if (this.dragStartValues.sizePercentB === 0) {
            panelA.size = this.dragStartValues.sizePercentA / this.dragStartValues.sizePixelA * newSizePixelA;
            panelB.size = this.dragStartValues.sizePercentA - panelA.size;
        }
        else {
            panelA.size = this.dragStartValues.sizePercentA / this.dragStartValues.sizePixelA * newSizePixelA;
            panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;

            const { max: amax, min: amin } = panelA;
            if (amax != undefined && panelA.size >= amax) {
                panelA.size = amax;
                panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;
            } else if (amin != undefined && panelA.size <= amin) {
                panelA.size = amin;

                if (alterPanelA) {
                    let sizePixelalterA = this.dragStartValues.sizePixelContainer - this.dragStartValues.sizePixelA - this.dragStartValues.sizePixelB;
                    let offsetPixelalterA = offsetPixel - (this.dragStartValues.sizePixelA - this.dragStartValues.sizePixelContainer * amin / 100);
                    let newSizePixelalterA = sizePixelalterA - offsetPixelalterA;

                    if (newSizePixelalterA <= this.dragStartValues.sizePixelContainer * (alterPanelA.min ?? 0) / 100) {
                        alterPanelA.size = alterPanelA.min
                    } else {
                        alterPanelA.size = newSizePixelalterA / this.dragStartValues.sizePixelContainer * 100;
                        panelB.size = 100 - panelA.size - alterPanelA.size;
                    }
                } else {
                    panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;
                }
            } else {
                panelB.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelA.size;
            }

            const { max: bmax, min: bmin } = panelB;
            if (bmax != undefined && panelB.size >= bmax) {
                if (alterPanelA) {
                    panelB.size = bmax;
                } else {
                    panelB.size = bmax;
                    panelA.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelB.size;
                }
            } else if (bmin != undefined && panelB.size <= bmin) {
                panelB.size = bmin;
                panelA.size = (this.dragStartValues.sizePercentA + this.dragStartValues.sizePercentB) - panelB.size;
            }
        }

        let sizeArr = this.displayedPanels.map(value => value.size)
        if (sizeArr.length > 0) {
            this.sizeChange.emit({
                barNum: this.currentbarNum,
                sizes: sizeArr
            })
        }

        this.refreshStyleSizes();
    }

    private stopDragging(): void {
        if (this.isDragging === false && this.draggingWithoutMove === false) {
            return;
        }

        while (this.dragListeners.length > 0) {
            const fct = this.dragListeners.pop();
            if (fct) {
                fct();
            }
        }

        this.isDragging = false;
        this.draggingWithoutMove = false;
    }
}
