<ng-template id="authForms" let-mode="mode" let-onsubmit="onsubmit">
    <form novalidate #authform="ngForm" autocomplete="off" class="authForm ng-untouched ng-pristine ng-valid">
        @let ctls = authform.controls;

        <ng-template #mfabtn>
            <button mat-button (click)="auth.mfa.get()" [disabled]="auth.mfa.state == 'none'">
                @if (auth.mfa.state == "getting") {
                {{auth.mfa.seconds}}{{"general.second" | translate}}
                } @else if (auth.mfa.seconds == null) {
                {{"mfa.getmfa" | translate}}
                } @else {
                {{"mfa.regetmfa" | translate}}
                }
            </button>
        </ng-template>

        <ng-template #fieldinput let-icon="icon" let-type="type" let-name="name" let-field="field" let-label="label"
            let-onblur="onblur" let-readonly="readonly" let-command="command" let-pattern="pattern"
            let-patternalert="patternalert">

            @let idname = name || field.prop.header.key;

            <div class="form-group">
                <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                        <span class="input-group-text px-2 h-100 border-0">
                            <mat-icon>{{ icon }}</mat-icon>
                        </span>
                    </div>

                    <input matInput [type]="type" [pattern]="pattern" [readonly]="readonly" [placeholder]="label"
                        [name]="idname" (blur)="onblur && onblur()" [(ngModel)]="field.data"
                        [ngModelOptions]="{ name: idname, updateOn: 'blur' }" class="form-control px-1 nobg shows"
                        autocomplete="off" required />

                    <div *ngIf="command" class="input-group-append">
                        <button mat-button *ngIf="!command.template" (click)="command.action && command.action()"
                            class="btn btn-primary btn-sm border-0 nobg shows">
                            {{ command.label }}
                        </button>
                        <ng-container *ngIf="command.template">
                            <ng-container *ngTemplateOutlet="command.template; context: command.context"></ng-container>
                        </ng-container>
                    </div>
                </div>

                <mat-error *ngIf="ctls[idname]?.touched && ctls[idname]?.invalid && !readonly">
                    @if (ctls[idname].hasError("required")) {
                    {{"input.hint" | translate}}{{label}}
                    } @else if (ctls[idname].hasError("pattern")) {
                    {{label}}{{"input.error1" | translate}},{{(patternalert || ("input.error2" | translate) + label)}}
                    } @else {
                    }
                </mat-error>
            </div>
        </ng-template>

        <ng-template #emailwithmobile let-field="field" let-command="command">
            @let context = {
            onblur: auth.mobileByMail.bind(auth),
            icon: 'contact_mail',
            pattern: '[^@]+@.+',
            field: getCellField(auth.form, field),
            type: 'email',
            label: ('email.label' | translate)
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template #passwordinput let-field="field" let-command="command">
            @let context = {
            icon: 'vpn_key',
            patternalert: ('password.error' | translate),
            pattern: '.{8,20}',
            type: 'password',
            field: getCellField(auth.form, field),
            label: ('password.label' | translate)
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template #newpasswordinput let-field="field" let-command="command">
            @let context = {
            icon: 'vpn_key',
            patternalert: ('password.error' | translate),
            pattern: '.{8,20}',
            type: 'password',
            field: getCellField(auth.form, field),
            label: ('password.newpwd' | translate)
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template #confirmpasswordinput let-field="field" let-command="command">
            @let context = {
            icon: 'vpn_key',
            patternalert: ('password.error' | translate),
            pattern: '.{8,20}',
            type: 'password',
            field: getCellField(auth.form, field),
            label: ('password.confirmpwd' | translate)
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template #mobileinput let-field="field" let-command="command">
            @let context = {
            icon: 'mobile_friendly',
            patternalert: ('mobile.errorhint' | translate),
            pattern: '.{11,11}',
            name: 'username',
            field: getCellField(auth.form, field),
            type: 'text',
            label: ('mobile.label' | translate)
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template #mobilereminder let-field="field" let-command="command">
            @let context = {
            icon: 'mobile_friendly',
            patternalert: ('mobile.errorhint' | translate),
            pattern: '.{11,11}',
            name: 'username',
            field: getCellField(auth.form, field),
            type: 'text',
            readonly: true,
            label: ('mobile.label' | translate)
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template #mfainput let-field="field" let-command="command">
            @let context = {
            icon: 'verified_user',
            patternalert: ('mfa.error' | translate),
            pattern: '\\d{6}',
            label: ('mfa.label' | translate),
            field: getCellField(auth.form, field),
            type: 'text',
            command: {
            template: mfabtn
            }
            };

            <ng-container *ngTemplateOutlet="fieldinput; context: context"></ng-container>
        </ng-template>

        <ng-template id="login">
            <ng-container *ngTemplateOutlet="mobileinput; context: { field: 'mobile' }"></ng-container>

            <ng-container *ngTemplateOutlet="passwordinput; context: { field: 'password' }"></ng-container>

            <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

            <div class="text-center pt-2">
                <mat-error class="d-block" *ngIf="Status.toError(auth.status)">
                    {{ Status.toError(auth.status)!.errkey | translate }}
                </mat-error>
                <button type="submit" [disabled]="false" class="btn btn-primary nobg shows btnborder"
                    (click)="onsubmit ? onsubmit.status = auth.login() : auth.login()">
                    {{ "login.label" | translate }}
                </button>
            </div>

            <div class="text-center pt-2 shows">
                <label (click)="auth.mode = 'change-password'"
                    class="btn btn-link btn-sm border-0 nobg shows">{{ "login.forgetpwd" | translate }}</label>
                <span> | </span>
                <label (click)="disclaimer()"
                    class="btn btn-link btn-sm border-0 nobg shows">{{ "login.disclaimer" | translate }}</label>
            </div>
        </ng-template>

        <ng-template id="reset-password">
            <ng-container *ngTemplateOutlet="mobileinput; context: { field: 'mobile' }"></ng-container>

            <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

            <div class="text-center pt-2">
                <mat-error class="d-block" *ngIf="Status.toError(auth.status)">
                    {{ Status.toError(auth.status)!.errkey | translate }}
                </mat-error>
                <button type="button" class="btn btn-light nobg shows btnborder" (click)="auth.mode = 'login'">
                    {{ "login.backward" | translate }}
                </button>
                <button type="button" [disabled]="!authform.valid" class="btn btn-primary nobg shows btnborder"
                    (click)="onsubmit ? onsubmit.status = auth.resetPassword() : auth.resetPassword()">
                    {{ "login.resetpwd" | translate }}
                </button>
            </div>
        </ng-template>

        <ng-template id="change-password">
            <ng-container *ngTemplateOutlet="mobileinput; context: { field: 'mobile' }"></ng-container>

            <ng-container *ngTemplateOutlet="newpasswordinput; context: { field: 'new_password' }"></ng-container>

            <ng-container
                *ngTemplateOutlet="confirmpasswordinput; context: { field: 'confirm_password' }"></ng-container>

            <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

            @let newpassword = auth.form['new_password'];
            @let confirmpassword=auth.form['confirm_password'];
            <div class="text-center pt-2">
                @let notsamepassword = (newpassword != '' || confirmpassword != '') && (newpassword != confirmpassword);

                <mat-error *ngIf="notsamepassword">
                    {{ "password.errornotsame" | translate }}
                </mat-error>
            </div>

            <div class="text-center pt-2">
                <mat-error class="d-block" *ngIf="Status.toError(auth.status)">
                    {{ Status.toError(auth.status)!.errkey | translate }}
                </mat-error>
                <button type="button" class="btn btn-light nobg shows btnborder" (click)="auth.mode = 'login'">
                    {{ "general.backward" | translate }}
                </button>
                <button type="button" [disabled]="!(authform.valid && newpassword == confirmpassword)"
                    (click)="onsubmit ? onsubmit.status = auth.changePassword() : auth.changePassword()"
                    class="btn btn-primary nobg shows btnborder">
                    {{ "password.modifypwd" | translate }}
                </button>
            </div>
        </ng-template>

        <ng-template id="change-password-after-login">
            <ng-container *ngTemplateOutlet="mobilereminder; context: { field: 'mobile' }"></ng-container>

            <ng-container *ngTemplateOutlet="passwordinput; context: { field: 'password' }"></ng-container>

            <ng-container *ngTemplateOutlet="newpasswordinput; context: { field: 'new_password' }"></ng-container>

            <ng-container
                *ngTemplateOutlet="confirmpasswordinput; context: { field: 'confirm_password' }"></ng-container>

            <ng-container *ngTemplateOutlet="mfainput; context: { field: 'mfa' }"></ng-container>

            @let newpassword = auth.form['new_password'];
            @let confirmpassword=auth.form['confirm_password'];
            <div class="text-center pt-2">
                @let notsamepassword = (newpassword != '' || confirmpassword != '') && (newpassword != confirmpassword);

                <mat-error *ngIf="notsamepassword">
                    {{ "password.errornotsame" | translate }}
                </mat-error>
            </div>

            <div class="text-center pt-2">
                <mat-error class="d-block" *ngIf="Status.toError(auth.status)?.errkey">
                    {{ (Status.toError(auth.status)?.errkey ?? '') | translate }}
                </mat-error>
                <button type="button" [disabled]="!(authform.valid && newpassword == confirmpassword)"
                    (click)="onsubmit ? onsubmit.status = auth.changePassword() : auth.changePassword()"
                    class="btn btn-primary nobg shows btnborder">
                    {{ "password.modifypwd" | translate }}
                </button>
            </div>
        </ng-template>

        <ng-container *puTemplateOutlet="mode"></ng-container>
    </form>
</ng-template>

<ng-template id="mobileApp">
    <div class="appqrcode flex-column align-items-center justify-content-center">
        <div class="centered-child">
            <qrcode [qrdata]="currentUrl" [imageSrc]="'mobileapp/logo.png'" [imageHeight]="36" [imageWidth]="36">
            </qrcode>
        </div>
        <div class="centered-child">
            <p class="center-footer">
                {{ "general.appdownhintversion" | translate }}{{ "general.appversion" | translate }}
            </p>
            <p class="center-footer">{{ "general.appdownhinthelper" | translate }}</p>
        </div>
    </div>
    <!--   
  <img class="centered-child" src="mobileapp/appqrcode.png">
  <div class="qrcodeImage">
    <qrcode
      [qrdata]="'{{currentURL}}'"
      [allowEmptyString]="true"
      [ariaLabel]="'QR Code image with the following content...'"
      [cssClass]="'center'"
      [colorDark]="'#000000ff'"
      [colorLight]="'#ffffffff'"
      [elementType]="'canvas'"
      [errorCorrectionLevel]="'M'"
      [imageSrc]="'./assets/angular-logo.png'"
      [imageHeight]="75"
      [imageWidth]="75"
      [margin]="4"
      [scale]="1"
      [title]="'A custom title attribute'"
      [width]="300"
    ></qrcode>
  </div> 
  -->
</ng-template>

<!-- common templates -->
<ng-template id="save, delete, reset_password" let-context="context" let-content="content" let-caption="caption"
    let-definition="definition">

    <ng-template pu-mat-dialog-title>
        {{ caption | translate }}
    </ng-template>

    <div class="position-relative w-100 h-100 p-2">
        <span class="d-block pt-2">{{ content?.major.format(context)}}</span>
        <span *ngIf="content?.minor" class="d-block pt-2">
            {{ content?.minor.format(context) }}
        </span>
    </div>

    <ng-template pu-mat-dialog-actions>
        <div mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'general.confirm' | translate}}</button>
            <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
        </div>
    </ng-template>
</ng-template>

<ng-template id="app.prompt.template" let-type="type" let-context="context">
    @let caption = _.get(app.dict.prompt, [type, 'caption']);
    @let content = _.get(app.dict.prompt,[type, 'content']);
    @let definition = _.get(app.dict.prompt, [type]);
    @let ctx = {content, caption, definition, context};

    <ng-container *puTemplateOutlet="type; context: ctx">
    </ng-container>
</ng-template>

<router-outlet></router-outlet>

<div *puHttpRQOverlay class="overlayContainer">
    <div class="overlayContent">
        <div class="loadingcontainer animation-2">
            <div class="shape shape1"></div>
            <div class="shape shape2"></div>
            <div class="shape shape3"></div>
            <div class="shape shape4"></div>
        </div>
    </div>
</div>

<div class="app-alerts white-board app-alerts-container" *ngIf="!alerts.isEmpty">
    @for (alert of alerts; track $index) {
    <ngb-alert #ngbalert="ngbAlert" [animation]="true" [type]="alert.type" [dismissible]="true"
        (closed)="alerts.close(alert)" [timer]="alert.dismiss" (finish)="ngbalert.close()">
        {{ (alert.errkey ?? '') | translate }}
    </ngb-alert>
    }
</div>