import { Overlay, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { ElementRef, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject, Subscription } from 'rxjs';

import { NgxCMenuContentComponent } from '../view/ngx-ctxmenu-content.component/ngx-ctxmenu-content.component';
import { NgxCMenuComponent } from '../view/ngx-ctxmenu.component/ngx-ctxmenu.component';
import { NgxCMenuItemDirective } from '../view/ngx-ctxmenu-item.directive';

export interface NgxCMenuEventClick {
    parentContextMenu?: NgxCMenuContentComponent;
    anchorElement?: Element | EventTarget;
    contextMenu?: NgxCMenuComponent;
    event?: MouseEvent | KeyboardEvent;
    activeMenuItemIndex?: number;
    item: any;
}

export interface NgxCMenuContext extends NgxCMenuEventClick {
    menuItems: NgxCMenuItemDirective[];
    menuClass: string;
}

export interface NgxCMenuEventCloseLeaf {
    exceptRootMenu?: boolean;
    event?: MouseEvent | KeyboardEvent;
}

export interface NgxCMenuOverlayRef extends OverlayRef {
    contextMenu?: NgxCMenuContentComponent;
}

export interface NgxCMenuEventCancel {
    eventType: 'cancel';
    event?: MouseEvent | KeyboardEvent;
}

export interface NgxCMenuEventExecute {
    eventType: 'execute';
    event?: MouseEvent | KeyboardEvent;
    item: any;
    menuItem: NgxCMenuItemDirective;
}

export type NgxCMenuEventClose = NgxCMenuEventExecute | NgxCMenuEventCancel;

@Injectable()
export class NgxCMenuService {
    private readonly triggerClose = new Subject<NgxCMenuContentComponent>();
    readonly show = new Subject<NgxCMenuEventClick>();
    readonly close = new Subject<NgxCMenuEventClose>();
    private isDestroyingLeafMenu: boolean = false;
    private overlays: OverlayRef[] = [];
    private readonly fakeElement = {
        getBoundingClientRect: () => ({
            bottom: 0,
            right: 0,
            left: 0,
            top: 0,
            height: 0,
            width: 0,
        })
    };

    constructor(
        private readonly overlay: Overlay,
        private readonly scrollStrategy: ScrollStrategyOptions
    ) {
    }

    openContextMenu(context: NgxCMenuContext): void {
        const { anchorElement, event, parentContextMenu } = context;

        if (!parentContextMenu) {
            const mouseEvent = event as (MouseEvent | undefined);

            this.fakeElement.getBoundingClientRect = () => ({
                bottom: mouseEvent?.clientY ?? 0,
                right: mouseEvent?.clientX ?? 0,
                left: mouseEvent?.clientX ?? 0,
                top: mouseEvent?.clientY ?? 0,
                height: 0,
                width: 0,
            });

            this.closeAllContextMenus({ eventType: 'cancel', event });

            const positionStrategy = this.overlay.position().flexibleConnectedTo(
                new ElementRef(anchorElement || this.fakeElement)
            ).withPositions([{
                originX: 'start', originY: 'bottom',
                overlayX: 'start', overlayY: 'top'
            }, {
                originX: 'start', originY: 'top',
                overlayX: 'start', overlayY: 'bottom'
            }, {
                originX: 'end', originY: 'top',
                overlayX: 'start', overlayY: 'top'
            }, {
                originX: 'start', originY: 'top',
                overlayX: 'end', overlayY: 'top'
            }, {
                originX: 'end', originY: 'center',
                overlayX: 'start', overlayY: 'center'
            }, {
                originX: 'start', originY: 'center',
                overlayX: 'end', overlayY: 'center'
            }]);

            this.overlays = [
                this.overlay.create({
                    positionStrategy,
                    panelClass: 'ngx-contextmenu',
                    scrollStrategy: this.scrollStrategy.close(),
                })
            ];

            return this.attachContextMenu(this.overlays[0], context);
        }

        const positionStrategy = this.overlay.position().flexibleConnectedTo(
            new ElementRef(event ? event.target : anchorElement)
        ).withPositions([{
            originX: 'end', originY: 'top',
            overlayX: 'start', overlayY: 'top'
        }, {
            originX: 'start', originY: 'top',
            overlayX: 'end', overlayY: 'top'
        }, {
            originX: 'end', originY: 'bottom',
            overlayX: 'start', overlayY: 'bottom'
        }, {
            originX: 'start', originY: 'bottom',
            overlayX: 'end', overlayY: 'bottom'
        }]);

        const newOverlay = this.overlay.create({
            scrollStrategy: this.scrollStrategy.close(),
            panelClass: 'ngx-contextmenu',
            positionStrategy,
        });

        this.destroySubMenus(parentContextMenu);
        this.overlays = this.overlays.concat(newOverlay);
        this.attachContextMenu(newOverlay, context);
    }

    attachContextMenu(overlay: OverlayRef, context: NgxCMenuContext): void {
        const { event, item, menuItems, menuClass } = context;
        const contextMenuContent = overlay.attach(
            new ComponentPortal(NgxCMenuContentComponent)
        );

        contextMenuContent.instance.item = item;
        contextMenuContent.instance.isLeaf = true;
        contextMenuContent.instance.event = event;
        contextMenuContent.instance.overlay = overlay;
        contextMenuContent.instance.menuItems = menuItems;
        contextMenuContent.instance.menuClass = menuClass;
        (overlay as NgxCMenuOverlayRef).contextMenu = contextMenuContent.instance;

        const subscriptions = new Subscription();
        subscriptions.add(
            contextMenuContent.instance.execute.subscribe(
                (executeEvent) => this.closeAllContextMenus({
                    ...executeEvent,
                    eventType: 'execute'
                })
            )
        );

        subscriptions.add(
            contextMenuContent.instance.closeAllMenus.subscribe(
                (closeAllEvent) => this.closeAllContextMenus({
                    ...closeAllEvent,
                    eventType: 'cancel',
                })
            )
        );

        subscriptions.add(
            contextMenuContent.instance.closeLeafMenu.subscribe(
                closeLeafMenuEvent => this.destroyLeafMenu(closeLeafMenuEvent)
            )
        );

        subscriptions.add(
            contextMenuContent.instance.openSubMenu.subscribe(
                (subMenuEvent) => {
                    this.destroySubMenus(contextMenuContent.instance);

                    if (!subMenuEvent.contextMenu) {
                        contextMenuContent.instance.isLeaf = true;
                        return;
                    }

                    contextMenuContent.instance.isLeaf = false;
                    this.show.next(subMenuEvent);
                }
            )
        );

        contextMenuContent.onDestroy(() => {
            menuItems.forEach(menuItem => menuItem.isActive = false);
            subscriptions.unsubscribe();
        });

        contextMenuContent.changeDetectorRef.detectChanges();
    }

    closeAllContextMenus(closeEvent: NgxCMenuEventClose): void {
        if (this.overlays) {
            this.close.next(closeEvent);
            this.overlays.forEach((overlay, index) => {
                overlay.detach();
                overlay.dispose();
            });
        }

        this.overlays = [];
    }

    getLastAttachedOverlay(): NgxCMenuOverlayRef {
        let overlay = this.overlays[this.overlays.length - 1];

        while (this.overlays.length > 1 && overlay && !overlay.hasAttached()) {
            overlay.detach();
            overlay.dispose();
            this.overlays = this.overlays.slice(0, -1);
            overlay = this.overlays[this.overlays.length - 1];
        }

        return overlay;
    }

    destroyLeafMenu(menuevent?: NgxCMenuEventCloseLeaf): void {
        const { exceptRootMenu, event } = menuevent || {};
        if (this.isDestroyingLeafMenu) {
            return;
        }

        this.isDestroyingLeafMenu = true;
        setTimeout(() => {
            const overlay = this.getLastAttachedOverlay();
            if (this.overlays.length > 1 && overlay) {
                overlay.detach();
                overlay.dispose();
            }

            if (!exceptRootMenu && this.overlays.length > 0 && overlay) {
                this.close.next({ eventType: 'cancel', event });
                overlay.detach();
                overlay.dispose();
            }

            const newLeaf = this.getLastAttachedOverlay();
            if (newLeaf?.contextMenu) {
                newLeaf.contextMenu.isLeaf = true;
            }

            this.isDestroyingLeafMenu = false;
        });
    }

    destroySubMenus(contextMenu: NgxCMenuContentComponent): void {
        const { overlay } = contextMenu;
        if (!overlay) return;

        const index = this.overlays.indexOf(overlay);
        this.overlays.slice(index + 1).forEach(
            subMenuOverlay => {
                subMenuOverlay.detach();
                subMenuOverlay.dispose();
            }
        );
    }

    isLeafMenu(contextMenuContent: NgxCMenuContentComponent): boolean {
        const overlay = this.getLastAttachedOverlay();
        return contextMenuContent.overlay === overlay;
    }
}