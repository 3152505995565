import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Injector, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

function classify<T>(dialogRef: MatDialogRef<T, any>, val: boolean, classes: {
    [cls: string]: boolean | ((val: boolean) => boolean)
}) {
    _.forEach(classes, (_val, cls) => {
        if (_.isFunction(_val) ? _val(val) : (_val == val)) {
            dialogRef.addPanelClass(cls);
            return;
        }

        dialogRef.removePanelClass(cls)
    })
}

function updatePanelClass<T>(dialogRef: MatDialogRef<T, any>, dialog: PuDialog<T>) {
    const { maximized, resiable, } = dialog;
    classify(dialogRef, maximized, {
        ['mat-dialog-resizable']: () => !maximized && resiable,
        ['mat-dialog-maximized']: true,
    })
}

export class PuDialog<T> {
    private $props = Prop.Of(this);

    get maximized(): boolean {
        const { $props: props } = this;
        return !!props.maximized;
    }

    set maximized(val: boolean) {
        const { $props: props, $props: { maximized = false } } = this;
        if (val == maximized) return;
        props.maximized = val;

        updatePanelClass(this.dialogRef, this);
    }

    get resiable(): boolean {
        const { $props: props } = this;
        return !!props.resiable;
    }

    set resiable(val: boolean) {
        const { $props: props, $props: { resiable = false } } = this;
        if (val == resiable) return;
        props.resiable = val;

        updatePanelClass(this.dialogRef, this);
    }

    protected constructor(
        public dialogRef: MatDialogRef<T>,
    ) {
        this.maximized = false;
        this.resiable = true;
    }

    protected static _open<T, TOption>(component: ComponentType<T>, dialog: MatDialog, context?: TOption, config?: PuDialog.Config): MatDialogRef<T> {
        const _config: MatDialogConfig<TOption> = {
            closeOnNavigation: false,
            restoreFocus: true,
            disableClose: true,
            hasBackdrop: true,
            autoFocus: true,

            maxHeight: '100vh',
            minHeight: '50vh',

            maxWidth: '100vw',
            minWidth: '50vw',

            ...(config || {}),
            data: context,
        }

        if (_config?.injector) {
            _config.injector = Injector.create({
                parent: _config.injector,
                providers: [{
                    provide: MatDialogConfig,
                    useValue: _config
                }]
            });
        }

        return dialog.open(component, _config);
    }
}

export namespace PuDialog {
    export interface Template {
        content?: false | string | ComponentType<any> | TemplateRef<any>,
        footer?: false | string | ComponentType<any> | TemplateRef<any>,
        title?: false | string | ComponentType<any> | TemplateRef<any>,
    }

    export interface Config extends Pick<MatDialogConfig, Exclude<keyof MatDialogConfig, 'data'>> {
        template?: Template | ComponentType<any> | TemplateRef<any>,
        maximized?: boolean,
        resiable?: boolean,
        title?: string
    }
}
