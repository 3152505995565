import { OnInit, OnDestroy, EventEmitter, SimpleChanges, OnChanges, QueryList, AfterContentInit, NgZone, Directive, Input, ContentChildren, Output, output } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { combineLatest, map, Observable, zip } from 'rxjs';

import { NgxAMapMarkerClustererService } from '../shared/inner/ngx-amap-marker-clusterer.service';
import { NgxAMapCreaterService } from '../shared/inner/ngx-amap.creater.service';
import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { NgxAMapMarkerDirective } from './ngx-amap-marker.directive';
import { AMapLoggerService } from '../shared/amap-logger.service';
import { AMapPixelService } from '../shared/amap-pixel.service';
import { AMapSizeService } from '../shared/amap-size.service';
import { ChangeFilter, getOptions } from '../utils/options';

const TAG = 'ngx-amap-marker-clusterer';
const MarkerClusterOptions: Extract<
    keyof NgxAMapMarkerClustererDirective,
    keyof AMap.MarkerClusterer.Options
>[] = [
        'gridSize',
        'minClusterSize',
        'maxZoom',
        'averageCenter',
        'styles',
        'renderClusterMarker',
        'zoomOnClick',
    ];

@Directive({
    selector: 'ngx-amap-marker-clusterer',
    exportAs: 'ngxAMapMarkerClusterer',
    providers: [NgxAMapMarkerClustererService],
    host: {
        '[style.display]': "'none'"
    }
})
export class NgxAMapMarkerClustererDirective implements OnInit, OnChanges, OnDestroy, AfterContentInit {
    private readonly _binder = this.binder.bindEvent<AMap.MarkerClusterer.EventMap>();
    private inited: boolean = false;

    /**
     * 聚合计算时网格的像素大小，默认60
     */
    @Input()
    gridSize?: number;

    /**
     * 聚合的最小数量。默认值为2，即小于2个点则不能成为一个聚合
     */
    @Input()
    minClusterSize?: number;

    /**
     * 最大的聚合级别，大于该级别就不进行相应的聚合。默认值为18
     */
    @Input()
    maxZoom?: number;

    /**
     * 聚合点的图标位置是否是所有聚合内点的中心点。默认为否
     */
    @Input()
    averageCenter?: boolean;

    /**
     * 指定聚合后的点标记的图标样式，可缺省，缺省时为默认样式
     */
    @Input()
    styles?: AMap.MarkerClusterer.Style[];

    /**
     * 该方法用来实现聚合点的自定义绘制
     */
    @Input()
    renderClusterMarker?: (obj: {
        count: number;
        markers: AMap.Marker<any>[];
        marker: AMap.Marker<any>;
    }) => void;

    /**
     * 点击聚合点时，是否散开，默认值为：true
     */
    @Input()
    zoomOnClick?: boolean;

    readonly naReady = output<AMap.MarkerClusterer>();
    readonly naClick = outputFromObservable(this._binder(this.os.get(), 'click'));

    @ContentChildren(NgxAMapMarkerDirective)
    readonly markerList: QueryList<NgxAMapMarkerDirective> = new QueryList();

    constructor(
        private readonly os: NgxAMapMarkerClustererService,
        private readonly binder: AMapEventBinderService,
        private readonly amaps: NgxAMapCreaterService,
        private readonly pixels: AMapPixelService,
        private readonly sizes: AMapSizeService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    ngOnDestroy(): void {
        this.os.destroy();
    }

    ngOnInit(): void {
        this.amaps.get().subscribe(
            () => {
                const options = getOptions<NgxAMapMarkerClustererDirective, AMap.MarkerClusterer.Options>(this, MarkerClusterOptions);
                this.logger.d(TAG, 'initializing ...');

                if (options.styles) {
                    options.styles = options.styles.map(
                        style => {
                            if (style.size) {
                                style.size = this.sizes.create(style.size);
                            }

                            if (style.offset) {
                                style.offset = this.pixels.create(style.offset);
                            }

                            if (style.imageOffset) {
                                style.imageOffset = this.pixels.create(style.imageOffset);
                            }

                            return style;
                        }
                    );
                }

                this.logger.d(TAG, 'options:', options);
                this.os.create(options).subscribe();
                this.inited = true;
            }
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.inited) {
            return;
        }

        const filter = ChangeFilter.from(changes);
        const cluster = this.get();

        zip(filter.notEmpty('gridSize'), cluster).subscribe(
            ([v, c]) => c.setGridSize(v)
        );

        zip(filter.notEmpty('minClusterSize'), cluster).subscribe(
            ([v, c]) => c.setMinClusterSize(v)
        );

        zip(filter.has('maxZoom'), cluster).subscribe(
            ([v, c]) => c.setMaxZoom(v)
        );

        zip(filter.has('averageCenter'), cluster).subscribe(
            ([v, c]) => c.setAverageCenter(v)
        );

        zip(filter.has('styles'), cluster).subscribe(
            ([v, c]) => c.setStyles(v)
        );
    }

    ngAfterContentInit(): void {
        this.updateMarkers().subscribe(
            c => {
                this.ngZone.run(() => {
                    this.naReady.emit(c)
                });

                this.logger.d(TAG, 'markerClusterer is ready.');
            }
        );

        this.markerList.changes.subscribe(
            () => this.updateMarkers().subscribe()
        );
    }

    locate(index: number) {
        this.get().subscribe(v => {
            const markers = v.getMarkers();
            const marker = markers[index];
            if (!marker) return;

            v.getMap().setFitView([marker]);
        })
    }

    /**
     * 获取已创建的 AMap.MarkerClusterer 对象
     */
    get(): Observable<AMap.MarkerClusterer> {
        return this.os.get();
    }

    private updateMarkers() {
        return zip(
            combineLatest(
                this.markerList.map(
                    d => d.get()
                )
            ), this.get()
        ).pipe(
            map(
                ([markers, cluster]) => {
                    cluster.setMarkers(markers);
                    return cluster;
                }
            )
        );
    }
}
