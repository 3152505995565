import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from "ng2-file-upload";
import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { retry, map, filter, finalize, shareReplay } from "rxjs/operators";
import { EMPTY, interval, Observable, of, Subscription } from "rxjs";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as CryptoJS from "crypto-js";

import { PuSysService } from "../../utils/puzzle/pu.service/pu.sys.service";
import { PuHttpService } from "../../utils/puzzle/pu.service/pu.http.service";
import { PuMsgService } from "../../utils/puzzle/pu.service/pu.msg.service";

import { Org as TOrg, App as TApp } from './backface/types';

const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF");
const iv = {
    iv: CryptoJS.enc.Utf8.parse('ABCDEF1234123412'),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
}

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _props = Prop.Of<AuthService, {
        _login_subscription?: Subscription,
        _logout_subscription?: Subscription,
        _mobileByMail_subscription?: Subscription,
        _resetPassword_subscription?: Subscription,
        _changePassword_subscription?: Subscription,
        _changePassword?: Observable<boolean>,
        _resetPassword?: Observable<boolean>,
        _login?: Observable<AuthService.IMe>,
        _logout?: Observable<boolean>,
        _mobileByMail?: Observable<{
            mobile: string
        }>
    }>(this);

    get otlinkActivate(): "OTLinkAgain" | "OTLinkInvalid" | undefined {
        const { _props } = this;

        if (!_props.otlinkActivate) {
            _props.otlinkActivate = sessionStorage['OtlinkActivate'];
            delete sessionStorage['OtlinkActivate'];
            delete sessionStorage['AuthAccount'];
        }

        return _props.otlinkActivate;
    }

    get me(): AuthService.Me | undefined {
        if (this._props.me !== undefined) {
            return this._props.me;
        }

        let me: AuthService.IMe | undefined;

        try {
            me = JSON.parse(
                CryptoJS.AES.decrypt(
                    CryptoJS.enc.Base64.stringify(
                        CryptoJS.enc.Hex.parse(
                            sessionStorage['AuthAccount']
                        )
                    ), key, iv
                ).toString(CryptoJS.enc.Utf8)
                    .toString()
            );

            this.http.token = me?.token;
        } catch (e) {
            // console.log(e);
        }

        return this.setMe(me);
    }

    get form() {
        const { _props } = this;

        const form: {
            mobile: string;
            email: string;
            mfa: string;

            confirm_password: string;
            new_password: string;
            password: string;

            reset(all?: boolean): void;
        } = (_props.form = (_props.form || {
            mobile: '',
            email: '',

            confirm_password: '',
            new_password: '',
            password: '',
            mfa: '',

            reset(all?: boolean) {
                form.confirm_password = '';
                form.new_password = '';
                form.password = '';
                form.mfa = '';

                if (all) {
                    form.mobile = '';
                    form.email = '';
                }
            }
        }));

        return form;
    }

    get mfa() {
        const auth = this;
        const waitting = 90;

        let timer: Subscription | undefined;
        let httpsub: Subscription | undefined;

        const { _props } = this;
        const handler: {
            readonly state: 'none' | 'ready' | 'getting';
            seconds?: number;
            cancel(): void;
            get(): void;
        } = (_props.mfa = (_props.mfa || {
            seconds: undefined,

            get state(): 'none' | 'ready' | 'getting' {
                if (timer) return 'getting';

                const { form: { mobile } } = auth;
                if (mobile) return 'ready';

                return 'none';
            },

            cancel() {
                httpsub?.unsubscribe();
                timer?.unsubscribe();

                handler.seconds = undefined;
                httpsub = undefined;
                timer = undefined;
            },

            get() {
                switch (handler.state) {
                    case 'none':
                        return;

                    case 'getting':
                        handler.cancel();
                        return;
                };

                const { httpClient, form, form: {
                    mobile, email
                }, me } = auth;

                handler.cancel();
                handler.seconds = waitting;
                form.mfa = "";

                // to get the mfa code, change the mfa parameter for both login or not
                _props.status = undefined;
                httpsub?.unsubscribe();
                httpsub = httpClient.post('/auth/mfa', me ? {
                    email, mobile
                } : {
                    email, mobile
                }).subscribe({
                    next(value: Object): void {
                    },
                    error(error: PuHttpService.Error) {
                        _props.status = error;
                        error.handled = true;

                        handler.cancel();
                        handler.seconds = undefined;
                        return EMPTY;
                    }
                });

                // start the timer
                timer = interval(1000).subscribe(() => {
                    if ((--handler.seconds!) <= 0) {
                        // timeup and cancel the timer
                        return handler.cancel();
                    }
                })
            }
        }))

        return handler;
    }

    get mode(): AuthService.Mode {
        const { _props, sys, otlinkActivate } = this;
        let mode = _props.mode || 'login';

        if (otlinkActivate) {
            switch (otlinkActivate) {
                case 'OTLinkInvalid': {
                    sys.prompt('onetimelink_invalid');
                    mode = 'login';
                } break;

                case 'OTLinkAgain': {
                    sys.prompt('onetimelink_again');
                    mode = 'reset-password';
                } break;

                default: {
                    mode = 'change-password';
                } break;
            }

            _props.otlinkActivate = undefined;
        }

        this.mode = mode;
        return mode;
    }

    set mode(val: AuthService.Mode) {
        const { _props } = this;

        if (_props.mode === val) {
            return;
        }

        const { form, mfa } = this;
        _props.status = undefined;
        _props.mode = val;
        form.reset();
        mfa.cancel();
    }

    get status(): PuHttpService.Succeed | PuHttpService.Error | undefined {
        return this._props.status;
    }

    get profile() {
        const { _props, httpClient } = this;
        const _this = this;

        const form: {
            name?: string;
            email?: string;
            mobile?: string;
            readonly changed: boolean;
            readonly profileimg: FileUploader & {
                fileItem?: FileItem,
                url?: string,
            };
            save(): Observable<boolean>;
        } = (_props.profile = (_props.profile || {
            get profileimg() {
                const props = Prop.Of(form);

                const profileimg: FileUploader & {
                    fileItem?: FileItem,
                    url?: string,
                } = (props.profileimg = (props.profileimg || (new FileUploader({
                    itemAlias: "uploadedfile",
                    url: "/resource/upload",
                    method: "POST",
                    filters: [{
                        name: 'media_filter',
                        fn: (item?: FileLikeObject, options?: FileUploaderOptions): boolean => {
                            const props = Prop.Of(profileimg);

                            profileimg.fileItem = undefined;
                            profileimg.clearQueue();
                            props.url = undefined;

                            return true;
                        }
                    }]
                }))), props.profileimg.onAfterAddingFile = (fileItem: FileItem): void => {
                    const file = fileItem._file, windowURL = window.URL || window.webkitURL;
                    const url: string = windowURL?.createObjectURL?.(file);
                    const props = Prop.Of(profileimg);
                    profileimg.fileItem = fileItem;
                    profileimg.progress = 0;
                    props.url = url;
                }, Object.defineProperty(props.profileimg, 'url', {
                    enumerable: true, configurable: false,
                    get() {
                        const props = Prop.Of(profileimg);
                        return props.url ?? _this.me?.profileimg;
                    }
                }), props.profileimg);

                return profileimg
            },

            get name(): string | undefined {
                const props = Prop.Of(form);
                return props.name ?? _this.me?.name;
            },

            set name(val: string) {
                const props = Prop.Of(form);
                props.name = (val == _this.me?.name ? undefined : val);
            },

            get email(): string | undefined {
                const props = Prop.Of(form);
                return props.email ?? _this.me?.email;
            },

            set email(val: string) {
                const props = Prop.Of(form);
                props.email = (val == _this.me?.email ? undefined : val);
            },

            get mobile(): string | undefined {
                const props = Prop.Of(form);
                return props.mobile ?? _this.me?.mobile;
            },

            set mobile(val: string) {
                const props = Prop.Of(form);
                props.mobile = (val == _this.me?.mobile ? undefined : val);
            },

            get changed(): boolean {
                const { me: { name, email, mobile, profileimg } = {} } = _this;
                return form.name != name || form.email != email || form.mobile != mobile || form.profileimg.url != profileimg;
            },

            save(): Observable<boolean> {
                if (!form.changed) return of(true);

                const { profileimg: { fileItem }, profileimg, mobile, email, name } = form;
                const formData = new FormData();
                const file = fileItem?._file;
                const _formprops = Prop.Of<typeof form, {
                    _save_subscription?: Subscription,
                    _save?: Observable<boolean>,
                }>(form);

                if (_formprops._save) return _formprops._save;

                file && formData.append('profileimg', file, file.name);
                formData.set('mobile', mobile ?? '');
                formData.set('email', email ?? '');
                formData.set('name', name ?? '');
                _props.status = undefined;

                _formprops._save_subscription = (_formprops._save = httpClient.post<boolean>('/auth/profile/save', formData, {
                    reportProgress: true,
                    observe: 'events'
                }).pipe(
                    map((event: HttpEvent<boolean>) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress: {
                                if (event.total) {
                                    profileimg.progress = Math.round(100 * (event.loaded / event.total));
                                }
                            } break;
                        }

                        return event;
                    }),
                    filter((event: HttpEvent<boolean>): boolean => {
                        return event.type == HttpEventType.Response;
                    }),
                    map((event: HttpEvent<boolean>): boolean => {
                        return true;
                    }),
                    finalize(() => {
                        _formprops._save_subscription?.unsubscribe();
                        _formprops._save_subscription = undefined;
                        _formprops._save = undefined;
                    }),
                    shareReplay(1)
                )).subscribe({
                    next(value: boolean) {
                        const { me: { me: _me = {} } = {} } = _this;
                        _me.mobile = mobile;
                        _me.email = email;
                        _me.name = name;
                    },
                    error(error: PuHttpService.Error) {
                        _props.status = error;
                        error.handled = true;
                        return of(false);
                    }
                });

                return _formprops._save;
            }
        }))

        return form;
    }

    get pathto(): string | undefined {
        const { _props: { pathto: val } } = this;
        return val == "/" || val == '/login' ? undefined : val;
    }

    set pathto(val: string) {
        this._props.pathto = (val == "/" || val == '/login' ? undefined : val);
    }

    constructor(
        private httpClient: HttpClient,
        public router: Router,

        private msg: PuMsgService,

        private sys: PuSysService,

        private http: PuHttpService
    ) {
        this.alertPWDUpdate = this.alertPWDUpdate.bind(this);
        const me = this.me; me;

        msg.onMeChanged.subscribe(() => {

            try {
                // store into session storage
                sessionStorage['AuthAccount'] = CryptoJS.AES.encrypt(
                    JSON.stringify((this.me || undefined)), key, iv
                ).ciphertext.toString().toUpperCase();
            } catch (e) {
                // console.log(e);
            }

        })
    }

    login(): Observable<AuthService.IMe> {
        const { _props, httpClient, form: { mobile, mfa, email, password } } = this;
        if (_props._login) return _props._login;
        _props.status = undefined;

        const auth = this;
        _props._login_subscription = (_props._login = httpClient.post<AuthService.IMe>('/auth/login', {
            email, mobile, mfa, password, did: "abc"
        }).pipe(
            finalize(() => {
                _props._login_subscription?.unsubscribe();
                _props._login_subscription = undefined;
                _props._login = undefined;
            }),
            shareReplay(1)
        )).subscribe({
            next(value: AuthService.IMe) {
                if (!value) return;

                auth.setMe(value);
                auth.form.reset(true);
                auth.router.navigate([auth.pathto || '/home/'], {
                    queryParams: {}
                });

                _props.status = {
                    succeed: true
                }

                return of(value);
            },
            error(error: PuHttpService.Error) {
                _props.status = error;
                error.handled = true;
                auth.setMe(undefined);
                return EMPTY;
            }
        });

        return _props._login;
    }

    logout() {
        const { _props, httpClient } = this;
        if (_props._logout) return _props._logout;
        _props.status = undefined;

        const auth = this;
        _props._logout_subscription = (_props._logout = httpClient.post<boolean>('/auth/logout', { did: "abc" }).pipe(
            finalize(() => {
                _props._logout_subscription?.unsubscribe();
                _props._logout_subscription = undefined;
                _props._logout = undefined;

                this.form.reset(true);
                this.http.token = undefined;
                this.router.navigate(['/login/'], {
                    queryParams: {
                    }
                });
            }),
            shareReplay(1)
        )).subscribe({
            next(value: boolean) {
                auth.setMe(undefined);
                return of(value);
            }, error(error: PuHttpService.Error) {
                _props.status = error;
                error.handled = true;
                auth.setMe(undefined);
                return EMPTY;
            }
        });

        return _props._logout;
    }

    changePassword() {
        const { _props, me, httpClient, otlinkActivate, form: { mfa, mobile, confirm_password, new_password, password } } = this;
        if (_props._changePassword) return _props._changePassword;
        if (confirm_password != new_password) return false;
        _props.status = undefined;

        const auth = this;
        _props._changePassword_subscription = (_props._changePassword = httpClient.post<boolean>('/auth/change_password', me ? {
            password, confirm_password, new_password, mfa, mobile, otlinkActivate
        } : {
            confirm_password, new_password, mfa, mobile, otlinkActivate
        }).pipe(
            finalize(() => {
                _props._changePassword_subscription?.unsubscribe();
                _props._changePassword_subscription = undefined;
                _props._changePassword = undefined;
            }),
            shareReplay(1)
        )).subscribe({
            next(value: boolean) {
                if (!me && value) auth.mode = "login";
                return of(value);
            }, error(error: PuHttpService.Error) {
                _props.status = error;
                error.handled = true;
                return of(false);
            }
        });

        return _props._changePassword;
    }

    resetPassword() {
        const { _props, sys, me, httpClient, form: { mfa, email } } = this;
        if (_props._resetPassword) return _props._resetPassword;
        _props.status = undefined;

        const auth = this;
        sys.prompt('reset_password', () => {
            _props._resetPassword_subscription = (_props._resetPassword = httpClient.post<boolean>('/auth/reset_password', {
                mfa, email
            }).pipe(
                finalize(() => {
                    _props._resetPassword_subscription?.unsubscribe();
                    _props._resetPassword_subscription = undefined;
                    _props._resetPassword = undefined;
                }),
                shareReplay(1)
            )).subscribe({
                next(value: boolean) {
                    if (!me && value) auth.mode = "login";
                    return of(value);
                },
                error(error: PuHttpService.Error) {
                    _props.status = error;
                    error.handled = true;
                    return EMPTY;
                }
            })
        });

        return _props._resetPassword;
    }

    mobileByMail() {
        const { _props, httpClient, form: { email } } = this;
        if (_props._mobileByMail) return _props._mobileByMail;
        _props.status = undefined;

        _props._mobileByMail_subscription = (_props._mobileByMail = httpClient.post<{
            mobile: string
        }>('/auth/mobile_from_email', { email }).pipe(
            retry(3),
            finalize(() => {
                _props._mobileByMail_subscription?.unsubscribe();
                _props._mobileByMail_subscription = undefined;
                _props._mobileByMail = undefined;
            }),
            shareReplay(1)
        )).subscribe({
            next: (value) => {
                this.form.mobile = value?.mobile;
                return of(value);
            },
            error: (error: PuHttpService.Error) => {
                _props.status = error;
                error.handled = true;
                this.form.mobile = "";
                return EMPTY;
            }
        });

        return _props._mobileByMail;
    }

    clearMe() {
        this.form.reset(true);
        this.setMe(undefined);
    }

    setMe(val?: AuthService.IMe): AuthService.Me | undefined {
        const [ome, me] = [this._props.me, val];
        const nme = (this._props.me = ((me && new AuthService.Me(me, this, this.http)) || undefined));

        if (nme?.id !== ome?.id) {
            this.msg.emitMeChanged(nme, ome);
        }

        return nme;
    }

    alertPWDUpdate() {
        this.msg.emitAppAlert({
            type: "warning",
            msg: "common.alert.change-password",
            dismiss: this.sys.alertPWDUpdateinterval
        })
    }
}

export namespace AuthService {

    export type Mode = "login" | "reset-password" | "change-password";

    export type Priviledge = TApp.Priviledge;

    export type IMe = TApp.IMe;

    export class Me implements IMe {
        @JSON.Key()
        get id(): string {
            return this.me.id!;
        }

        @JSON.Key()
        get token(): string | undefined {
            return this.http.token;
        }

        @JSON.Key()
        get name(): string {
            return this.me.name!;
        }

        @JSON.Key()
        get dept(): TOrg.IDept | undefined {
            return this.me.dept;
        }

        @JSON.Key()
        get email(): string | undefined {
            return this.me.email;
        }

        @JSON.Key()
        get mobile(): string | undefined {
            return this.me.mobile
        }

        @JSON.Key()
        get language(): string | undefined {
            return this.me.language;
        }

        @JSON.Key()
        get profileimg(): string {
            const { me } = this;

            if (me?.profileimg) {
                return '/profile/' + me.id + '?profileimg=' + me.profileimg;
            }

            return '/profile/default.jpg';
        }

        @JSON.Key()
        get priviledges() {
            return this.me.priviledges!;
        }

        @JSON.Key()
        get role(): TOrg.IRole[] | undefined {
            return this.me.role;
        }

        @JSON.Key()
        get consumebyproject(): boolean {
            return !!this.me.consumebyproject;
        }

        @JSON.Key()
        get superviseaudit(): boolean {
            return !!this.me.superviseaudit;
        }

        @JSON.Key()
        get executeaudit(): boolean {
            return !!this.me.executeaudit;
        }

        @JSON.Key()
        get prepareproject(): boolean {
            return !!this.me.prepareproject;
        }

        constructor(
            public me: IMe,
            private auth: AuthService,
            private http: PuHttpService,
        ) {
            me = me || {} as IMe;
        }
    }
}