import { Observable, Subject, of, ObservableInput, Subscription } from 'rxjs';
import { switchMap, takeUntil, shareReplay, map } from 'rxjs/operators';

export namespace Async {

    export type Request<Value, Param> = {
        query?: (p?: Param) => Observable<Value>,
        param?: Param,
        value?: Value,
    }

    export class Cache<Value, Param = void, Output = Value> extends Observable<Output> {
        private _props = Prop.Of<Cache<Value, Param, Output>, {
            reloader: Subject<Request<Value, Param>>;
            finish: Subject<void>;
            sub: Subscription;
        }>(this, values => {
            values.sub = new Subscription();
            values.reloader = new Subject();
            values.finish = new Subject();
        });

        constructor(
            req: Request<Value, Param> & {
                transform?: (value: Value) => Output
            },
            bufferSize: number = 1,
            preload: boolean = true
        ) {
            super();

            const { _props: { reloader, finish, sub } } = this;
            const { value, param, query, transform } = req;

            {
                const _cache = reloader.pipe(
                    takeUntil(finish),

                    switchMap<
                        Request<Value, Param>, ObservableInput<Value>
                    >((next: Request<Value, Param>): ObservableInput<Value> => {
                        const { value: _value, query: _query = query, param: _param = param } = next;
                        return (_value !== undefined ? of(_value) : _query!(_param));
                    }),

                    map((val: Value): Output => {
                        return transform ? transform(val) : val as any;
                    }),

                    shareReplay<Output>({
                        refCount: false,
                        bufferSize
                    })
                )

                // bridge patch the methods from this to props._cache
                // Object.setPrototypeOf(Object.getPrototypeOf(this), _cache);
                const desces = Object.getOwnPropertyDescriptors(Cache.prototype);
                Object.defineProperties(_cache, desces);
                Object.setPrototypeOf(this, _cache);
            }

            if (value !== undefined) {
                // emit default value
                reloader.next({ value });
            }

            // only to trigger data loading firstly.
            if (preload && query) {
                sub.add(this.subscribe(_.noop));
                reloader.next({ query, param });
            }
        }

        query(param?: Param, query?: (p?: Param) => Observable<Value>): Cache<Value, Param, Output> {
            const { _props: { reloader } } = this;
            reloader.next({ query, param });
            return this;
        }

        emit(value: Value): Cache<Value, Param, Output> {
            const { _props: { reloader } } = this;
            reloader.next({ value });
            return this;
        }

        finish(): Cache<Value, Param, Output> {
            const { _props: { finish, sub } } = this;
            sub.unsubscribe(), finish.next();
            return this;
        }
    }
}