import { Component, OnInit, AfterViewInit, Inject, Optional, TemplateRef, ɵComponentType, ɵComponentDef, Injector, OnDestroy, EventEmitter } from "@angular/core";
import { MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Subscription } from "rxjs";

import { PuTemplateService } from "../pu.template/pu.template";
import { PuDialog } from "./pu.dialog";

function isComponentType(val: any): val is ComponentType<any> {
    return _.isFunction(val) && !!componentDef(val as any);
}

function isTempComp(val: any): val is (TemplateRef<any> | ComponentType<any>) {
    return isComponentType(val) || val instanceof TemplateRef;
}

function componentDef<T extends ComponentType<any>>(compType: T): ɵComponentDef<T> | undefined {
    return (compType as unknown as ɵComponentType<T>)?.ɵcmp as ɵComponentDef<T>
}

function componentInputs<T extends ComponentType<any>>(compType: T, context: any): Record<PropertyKey, any> {
    const inputs = componentDef(compType)?.inputs ?? {};
    context = (_.isObject(context) ? context : {});
    return _.pick(context, _.keys(inputs));
}

type MatTemplate = ((typeof PuTemplateService)['dialogTemplate'] extends EventEmitter<infer T> ? T : never) & {
    sub: Subscription
};

@Component({
    templateUrl: "./pu.dialog.component.html",
    styleUrls: ['./pu.dialog.component.scss'],
    providers: [PuTemplateService]
})
export class PuDialogComponent extends PuDialog<PuDialogComponent> implements OnInit, AfterViewInit, OnDestroy {
    private _props = Prop.Of<PuDialogComponent, {
        inputs: WeakMap<ComponentType<any>, Record<PropertyKey, any>>,
        subscription: Subscription

        footer?: MatTemplate,
        title?: MatTemplate
    }>(this, values => {
        values.subscription = new Subscription();
        values.inputs = new WeakMap();
    });

    get templateContent(): PuDialog.Template['content'] {
        const { config: { template } } = this;
        if (isTempComp(template)) return template;
        return template?.content;
    }

    get templateFooter(): PuDialog.Template['footer'] {
        const { config: { template }, _props: { footer } } = this;
        if (isTempComp(template)) return footer;
        return template?.footer || footer;
    }

    get templateTitle(): PuDialog.Template['title'] {
        const { config: { template }, _props: { title } } = this;
        if (isTempComp(template)) return title;
        return template?.title || title;
    }

    get title() {
        return this.config.title;
    }

    constructor(
        public override dialogRef: MatDialogRef<PuDialogComponent>,

        public injector: Injector,

        @Inject(MatDialogConfig) @Optional()
        protected config: PuDialog.Config = {},

        @Inject(MAT_DIALOG_DATA)
        public context: any,
    ) {
        super(dialogRef);

        const { resiable, maximized } = config;
        if (resiable != undefined && resiable != null) {
            this.resiable = resiable;
        }

        if (maximized != undefined && maximized != null) {
            this.maximized = maximized;
        }

        const used: { footer?: MatTemplate, title?: MatTemplate } = {};
        const { _props: props, _props: { subscription: subscription } } = this;
        subscription.add(PuTemplateService.dialogTemplate.subscribe((tpl) => {
            const { matinfo } = tpl, { type, onDestroy } = matinfo;
            const cur = props[type] || used[type];
            if (cur) return;

            used[type] = _.extend(tpl, {
                sub: onDestroy.subscribe(dtpl => {
                    const { matinfo } = dtpl, { type: dtype } = matinfo;
                    const _cur = used[dtype];
                    if (_cur != dtpl) return;

                    _cur.sub.unsubscribe();
                    tick(() => {
                        delete props[dtype];
                        delete used[dtype];
                    })
                })
            });

            tick(() => {
                props[type] = used[type]
            })
        }));
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this._props.subscription.unsubscribe();
    }

    ngOnDestroy(): void {
        this._props.footer?.sub.unsubscribe();
        this._props.title?.sub.unsubscribe();
    }

    isTemplateRef(template: string | ComponentType<any> | TemplateRef<any>): template is string | TemplateRef<any> {
        return template instanceof TemplateRef || _.isString(template);
    }

    isComponentType(template: string | ComponentType<any> | TemplateRef<any>): template is ComponentType<any> {
        return isComponentType(template);
    }

    componentInputs<T>(comp: ComponentType<T>): Record<keyof T, any> {
        const { _props: { inputs }, context } = this;
        let compinputs = inputs.get(comp);
        if (!compinputs) {
            compinputs = componentInputs(comp, context);
            inputs.set(comp, compinputs);
        }

        return compinputs;
    }

    static open<T>(dialog: MatDialog, config: PuDialog.Config, context?: T): MatDialogRef<any, any> {
        return PuDialog._open(PuDialogComponent, dialog, context, config);
    }
}
