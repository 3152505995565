import { MatDialogConfig } from "@angular/material/dialog";
import { EventEmitter, TemplateRef } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { ComponentType } from "@angular/cdk/portal";
import { ActivatedRoute } from "@angular/router";
import * as CryptoJS from "crypto-js";

import { Sys as TSys } from "../../../application/service/backface/types";
import { Editor } from "../../libs/editor";
import { PuDialog } from "../../puzzle";

const EdittingProp = Prop.Slot<{
    editting?: GovEditor.IEditting,
    bound?: {
        expandheader?: Editor.IField;
        editting: GovEditor.Editting;
    }
}>('Editting');

const key = CryptoJS.enc.Utf8.parse("DW$eY9@hc*6a1#$6");
const iv = {
    iv: CryptoJS.enc.Utf8.parse('8apPZ^md9XdpgODT'),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
}


export namespace GovEditor {
    export interface ISetBinder {
        bindsource(val: Object): void;
    }

    export type IFilterData = TSys.IFilterData;

    export interface IWorkItemSumData {
        stageitem?: TSys.IStageSumItem,
        workitemsum?: TSys.IWSSumItem
    }

    export interface IToolbar {
        reloader: EventEmitter<void>;

        // for CD(create/delete) editting
        selection?: SelectionModel<object>;
        delete?(): void;
        create?(): void;

        // control toolbar items use or not
        readonly noselectedProcess?: boolean;
        readonly noselectedYear?: boolean;
        readonly noinputtxt?: boolean;

        // for preconfiged filter
        readonly filterdata?: IFilterData[];
        searcher?: string | object;

        // for year select in range
        selectedYear?: number;
        minYear?: number;
        maxYear?: number;

        selectedProcess?: string;

        readonly advfilter?: TemplateRef<any> | (() => void);

        exportas?(val: string): void;
    }

    export interface ISection {
        exclude?: { [P: string]: boolean };
        editor: Editor.Editor<any>;
        headers: Editor.IFields;
        noactionbar?: boolean;
        readonly?: boolean;
    }

    export type IForm = (ISection | {
        sections: ISection[]
    }) & {
        invisible?: boolean | (<IAppService, T>(app: IAppService, obj: T) => boolean);
        title?: string | (<IAppService, T>(app: IAppService, obj?: T) => string);
    }

    export interface IEditting {
        style?: (row: any) => {};
        form?: IForm | IForm[];
        handled?: IEditting;
        primary?: object;
        source?: object;
    }

    export function isSection(form: IForm): form is ISection {
        return !(form as any)?.['sections'];
    }

    export interface ToEditting {
        (obj: any, def?: ((obj: any) => IEditting)): IEditting | undefined;
    }

    export interface CommandConfig<D = any> {
        template?: ComponentType<any> | TemplateRef<any>,
        context?: MatDialogConfig<D>['data'],
        config: PuDialog.Config,
    }

    export class Editting implements IEditting {
        private $props = Prop.Of(this);

        handled?: GovEditor.IEditting;
        style?: (row: any) => {};
        primary?: object;

        get form(): IForm | IForm[] | undefined {
            return this.handled?.form ?? this.$props.form;
        }

        set form(val: IForm | IForm[]) {
            this.$props.form = val;
        }

        get source(): object | undefined {
            return this.handled?.source ?? this.$props.source;
        }

        set source(val: object) {
            this.$props.source = val;
        }

        reset(editting: IEditting) {
            const { $props } = this;

            editting.hasOwnProperty('primary') && (this.primary = editting.primary);

            this.style = editting.style;
            this.handled = editting.handled;
            $props.source = editting.source;
            $props.form = editting.form;

            return this;
        }

        isSame(editting: IEditting = {}): boolean {
            return (
                this.form == editting.form &&
                this.source == editting.source &&
                this.handled == editting.handled &&
                this.primary == editting.primary
            );
        }

        static get(datasets?: TSys.IDatasetModule): IEditting | undefined {
            return EdittingProp.Of(datasets, true)?.editting;
        }

        static set(datasets: TSys.IDatasetModule, editting: IEditting): IEditting | undefined {
            console.assert(!!datasets);
            if (!datasets) return;

            return EdittingProp.Of(datasets, true).editting = editting;
        }

        static remove(datasets: TSys.IDatasetModule) {
            console.assert(!!datasets);
            if (!datasets) return;

            delete EdittingProp.Of(datasets, true).editting;
        }

        static getBound(datasets: TSys.IDatasetModule): {
            expandheader?: Editor.IField,
            editting: Editting,
        } {
            const editting = EdittingProp.Of(datasets, true);
            return editting.bound || (editting.bound = {
                editting: new Editting()
            });
        }

        static removeBound(datasets: TSys.IDatasetModule) {
            console.assert(!!datasets);
            if (!datasets) return;

            delete EdittingProp.Of(datasets, true).bound;
        }
    }

    export abstract class ToolBar implements GovEditor.IToolbar {
        private $props = Prop.Of<ToolBar, {
            content?: ToolBar.Content
        }>(this);

        get reloader(): EventEmitter<void> {
            const { $props: props } = this;
            return props.reloader || (
                props.reloader = new EventEmitter()
            )
        }

        get storageKey(): string {
            const { router: { snapshot: { url } }, module, modulekey } = this;
            return `${url.join('.')}.${module?.key ?? modulekey}`;
        }

        get module(): TSys.IDatasetModule | undefined {
            return this.$props.module;
        }

        set module(val: TSys.IDatasetModule) {
            const { $props: props } = this;
            if (props.module == val) return;
            delete props.content;
            props.module = val;
        }

        get filterdata(): GovEditor.IFilterData[] | undefined {
            return this.module?.dict?.filterdata;
        }

        get toolbarcontent(): ToolBar.Content {
            const { $props: props, defval } = this, _this = this;

            // load the toolbar content from local storage if need
            const _content = (): ToolBar.Content => (props.content || (
                props.content = this.loadToolbarContent()
            ))

            const trap = {
                get raw() {
                    return _content();
                }
            }

            return props.toolbarcontent || (
                props.toolbarcontent = new Proxy<ToolBar.Content>(<any>trap, Prop.makeProxyHandler<ToolBar.Content>({
                    getOwnPropertyDescriptor(target, p): PropertyDescriptor | undefined {
                        if (_.isSymbol(p)) return Object.getOwnPropertyDescriptor(trap, p);
                        return Object.getOwnPropertyDescriptor(trap.raw, p);
                    },

                    defineProperty(target, p, attributes): boolean {
                        if (_.isSymbol(p)) { Object.defineProperty(trap, p, attributes); return true; }
                        Object.defineProperty(trap.raw, p, attributes);
                        return true;
                    },

                    getPrototypeOf(target): object | null {
                        return {};
                    },

                    get(_target, key) {
                        if (_.isSymbol(key)) {
                            return (trap as any)[key];
                        }

                        if (key === 'hasOwnProperty') {
                            return trap.hasOwnProperty;
                        }

                        const content = trap.raw;
                        const val = _.get(content, key);
                        if (val != null) return val;
                        return _.get(defval, key);
                    },

                    set(_target, key, value): boolean {
                        if (_.isSymbol(key)) {
                            (trap as any)[key] = value;
                            return true;
                        }

                        const content = trap.raw;
                        if (_.isEqual(_.get(content, key), value)) {
                            return true;
                        }

                        value = _.clone(value);
                        _.set(content, key, value);
                        const changed = { [key]: value };
                        _this.saveToolbarContent();
                        _this._search(changed);
                        return true;
                    },

                    deleteProperty(_target, key): boolean {
                        if (_.isSymbol(key)) {
                            delete (trap as any)[key];
                            return true;
                        }

                        const content = trap.raw;
                        if (_.get(content, key) === undefined) return true;
                        delete content[key as string];

                        if (key === 'searcher') {
                            content.searcher = {}
                        }
                        
                        _this.saveToolbarContent();
                        return true;
                    },

                    has(_target, key: string): boolean {
                        if (_.isSymbol(key)) return _.has(trap, key);
                        if (key === 'hasOwnProperty') return true;

                        const content = trap.raw;
                        return _.has(content, key);
                    },

                    ownKeys(_target): ArrayLike<string> {
                        const content = trap.raw;
                        return Object.keys(content);
                    }
                }))
            );
        }

        get searcher(): string | object | undefined {
            return this.toolbarcontent.searcher;
        }

        set searcher(val: string | object) {
            this.toolbarcontent.searcher = val;
        }

        get selectedYear(): number | undefined {
            return this.toolbarcontent.selectedYear || undefined;
        }

        set selectedYear(val: number | undefined) {
            val = _.isString(val) ? _.toNumber(val) : val;
            this.toolbarcontent.selectedYear = val;
        }

        get selectedProcess(): string | undefined {
            return this.toolbarcontent.selectedProcess || undefined;
        }

        set selectedProcess(val: string | undefined) {
            this.toolbarcontent.selectedProcess = val;
        }

        constructor(
            public router: ActivatedRoute,
            public modulekey: string = '',
            public defval: ToolBar.Changed = {}
        ) {
            const { $props: props } = this;
            delete props.content;
        }

        protected dosearch?(changed: ToolBar.Changed, content: ToolBar.Content): void;

        private loadToolbarContent(): ToolBar.Content {
            const { $props: props, storageKey, defval } = this;

            try {
                delete props.content;

                // load the latest filter content
                props.content = JSON.parse(
                    CryptoJS.AES.decrypt(
                        CryptoJS.enc.Base64.stringify(
                            CryptoJS.enc.Hex.parse(
                                localStorage[storageKey]
                            )
                        ), key, iv
                    ).toString(CryptoJS.enc.Utf8)
                        .toString()
                );
            } catch (e) {
                // console.log(e)
            }

            if (!_.isObject(props.content)) delete props.content;
            props.content = _.merge({ searcher: {} }, defval, props.content ?? {});
            const content = (props.content || (props.content = {
                searcher: {}
            }));

            content.searcher = content.searcher || {};
            this._search(content);
            this.reloader.emit();
            return content;
        }

        private saveToolbarContent() {
            const { storageKey, $props: { content } } = this;

            try {
                // store the latest searcher content
                localStorage[storageKey] = CryptoJS.AES.encrypt(
                    JSON.stringify(content), key, iv
                ).ciphertext.toString().toUpperCase();
            } catch (e) {
                // console.log(e)
            }
        }

        private _search(changed: ToolBar.Changed) {
            const { dosearch, $props: { content } } = this;
            if (!dosearch) return;

            tick(dosearch, this, changed, content);
        }
    }

    export namespace ToolBar {
        export interface Content extends Record<string | number, any> {
            searcher: string | Record<string | number, any>,
            selectedProcess?: string | null,
            selectedYear?: number | null,
        }

        export type Changed = Partial<Content>;
    }
}