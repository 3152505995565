import { Inject, Injectable, InjectionToken, Optional, TemplateRef } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { ComponentType } from "@angular/cdk/portal";

import { PuDialogService } from "../pu.dialog/pu.dialog.service";
import { PuTemplateService } from "../pu.template/pu.template";
import { PuDialog } from "../pu.dialog/pu.dialog";

type Prompts = PuSysService.Prompts;

// size definition same as the bootstrap
const Size = {
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576
}

const Devices = {
    mobile: Size.sm,
    laptop: Size.lg,
    pad: Size.md,
}

@Injectable({ providedIn: 'root' })
export class PuSysService {
    get isPad() {
        return !!window.matchMedia(`(max-width: ${Devices.pad}px)`).matches;
    };

    get isMobile() {
        return !!window.matchMedia(`(max-width: ${Devices.mobile}px)`).matches;
    };

    get isLaptop() {
        return !!window.matchMedia(`(max-width: ${Devices.laptop}px)`).matches;
    };

    get isWindows() {
        return navigator.platform.toUpperCase().indexOf('Win') >= 0;
    }

    get isMac() {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }

    get isIPad() {
        return navigator.platform.toUpperCase().indexOf('IPAD') >= 0;
    }

    get isApple() {
        return this.isMac || this.isIPad;
    }

    get alertPWDUpdateinterval() {
        return 600000;
    }

    constructor(
        @Optional()
        @Inject(PuSysService.PROMPTTEMPLATE)
        public readonly prompttemplate: string | ComponentType<any> | TemplateRef<any>,

        public matIconRegistry: MatIconRegistry,
        public dialog: PuDialogService,
        public tpls: PuTemplateService,
    ) {
        this.prompttemplate = prompttemplate || PuSysService.DefaultTemplate;
        matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    }

    prompt<TPS extends Prompts, TP extends keyof TPS>(type: TP, yes?: () => void, no?: () => void, context?: TPS[TP]['context']) {
        const { dialog, tpls, prompttemplate: pmttpl } = this;

        const dialogRef = dialog.open({
            template: _.isString(pmttpl) ? tpls.get(pmttpl) : pmttpl,
            disableClose: true,
            hasBackdrop: true,

            maxHeight: '100vh',
            minHeight: '20%',

            maxWidth: '100vw',
            minWidth: '30vw',
            ...{}
        }, {
            context: context,
            type: type,
        });

        if (yes || no) {
            const sub = dialogRef.afterClosed().subscribe(result => {
                tick(() => sub.unsubscribe());
                (result ? yes : no)?.();
            });
        }

        return dialogRef;
    }

    openDialog<T>(template: ComponentType<any> | TemplateRef<any>, config?: PuDialog.Config & { data?: T }, yes?: () => void, no?: () => void) {
        config = config || {};

        const { dialog } = this;
        const { data } = config;
        delete config.data;

        const dialogRef = dialog.open({
            template: template,
            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '90vh',
            maxWidth: '100vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
            ...config
        }, data);

        if (yes || no) {
            const sub = dialogRef.afterClosed().subscribe(result => {
                tick(() => sub.unsubscribe());
                (result ? yes : no)?.();
            });
        }

        return dialogRef;
    }
}

export namespace PuSysService {
    export const PROMPTTEMPLATE: InjectionToken<string> = new InjectionToken('prompttemplate');
    export const DefaultTemplate: string = 'app.prompt.template';

    export type Prompts = {
        [catogry: string]: {
            caption: string,
            content: {
                major: string
            }
            context: Record<string | number, any>
        }
    }
}