<ng-template id="text" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>{{ header.title | translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [readonly]="true"
            [ngModel]="cellField.data"></textarea>
    </mat-form-field>
</ng-template>

<ng-template id="texts" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-12">
        <mat-label>{{ header.title | translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" class="p-1"
            [readonly]="true" [ngModel]="cellField.data"></textarea>
    </mat-form-field>
</ng-template>

<ng-template id="email" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>{{ header.title | translate }}</mat-label>
        <input matInput type="email" [readonly]="true" [ngModel]="cellField.data" />
    </mat-form-field>
</ng-template>

<ng-template id="password" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label #temp>{{ header.title | translate }}</mat-label>
        <input matInput [readonly]="true" [ngModel]="cellField.data" [type]="BOf(temp).hide ? 'password' : 'text'" />
        <button mat-icon-button matSuffix (click)="BOf(temp).hide = !BOf(temp).hide" [attr.aria-label]="'隐藏密码'"
            [attr.aria-pressed]="BOf(temp).hide">
            <mat-icon>{{ BOf(temp).hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
    </mat-form-field>
</ng-template>

<ng-template id="number" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>{{ header.title | translate }}</mat-label>
        <input matInput type="number" [readonly]="true" [ngModel]="cellField.data" />
    </mat-form-field>
</ng-template>

<ng-template id="phone" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>{{ header.title | translate }}</mat-label>
        <input matInput type="tel" [readonly]="true" [ngModel]="cellField.data" />
    </mat-form-field>
</ng-template>

<ng-template id="bool" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>{{ header.title | translate }}</mat-label>
        <input matInput [readonly]="true" class="d-none" />
        <div class="toggle toggle--knob">
            <input type="checkbox" class="toggle--checkbox" id="field-form-toggle-result--{{ header.key }}"
                [ngModel]="cellField.data" [readonly]="true" [disabled]="true" />
            <label class="ms-0 toggle--btn" for="field-form-toggle-result--{{ header.key }}">
                <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
                    [attr.data-label-on]="'general.yes' | translate"></span></label>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="date" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>{{ header.title | translate }}</mat-label>
        <input matInput [matDatepicker]="picker" [readonly]="true" [ngModel]="cellField.data" readonly />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [disabled]="true"></mat-datepicker>
    </mat-form-field>
</ng-template>

<ng-template id="option" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label> {{ header.title | translate }} </mat-label>

        <input matInput class="d-none" />
        <div class="h-100 d-flex flex-row align-items-stretch justify-content-start">
            <mat-chip-set class="flex-fill p-0 m-0">
                <mat-chip class="p-0 m-0" [removable]="false">
                    {{ getOptionCellText(cellField.data, header, headers) }}
                </mat-chip>
            </mat-chip-set>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="options" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-12">
        <mat-label> {{ header.title | translate }} </mat-label>

        <input matInput class="d-none" />
        <mat-chip-set class="flex-fill p-0 m-0">
            @for (val of cellField.data; track $index) {
            <mat-chip class="p-0 m-0" [removable]="false">
                {{ getOptionCellText(val, header, headers) }}
            </mat-chip>
            }
        </mat-chip-set>
    </mat-form-field>
</ng-template>

<ng-template id="enum" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-4">
        <mat-label>
            {{ header.title | translate }}
        </mat-label>
        <mat-select [disabled]="true" [value]="cellField.data"
            [multiple]="app.editor.Value.isFieldType(header, 'multi')">
            <mat-option *ngFor="let opt of optionSourceRows.data" [value]="opt.value">
                {{ opt.title | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>

<ng-template id="json" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-12">
        <mat-label>
            {{ header.title | translate }}
        </mat-label>

        <input #temp matInput class="d-none" />

        <json-editor #jsoneditor (change)="cellField.data = jsoneditor.get()" [options]="jsoneditoroptions"
            [data]="cellField.data"></json-editor>
    </mat-form-field>
</ng-template>

<ng-template id="command" let-cellField="cellField" let-header="header" let-editor="editor">
</ng-template>

<ng-template id="pics" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-12">
        <mat-label>
            {{ header.title | translate }}
        </mat-label>

        <input #temp matInput class="d-none" />

        <div class="row m-0">
            <div class="app-card-pic col-4 p-1" *ngFor="let pic of cellField.data">
                <mat-card class="card-frame w-100 h-100 p-1">
                    <mat-card-content
                        class="h-100 w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
                        <ng-container [ngSwitch]="pic.content_type">
                            <video async *ngSwitchCase="'video'" (click)="openPics(cellField.data, pic)"
                                (canplaythrough)="pic.onLoaded($event)" class="mat-card-image border m-0 p-0 me-1">
                                <source [src]="pic.url | safe : 'url'"
                                    type="{{ pic.content_type + '/' + pic.mime_type }}" />
                                Your browser does not support HTML5 video.
                            </video>

                            <img async *ngSwitchDefault priority [src]="pic.url | safe : 'url'" placeholder
                                (load)="pic.onLoaded($event)" (click)="openPics(cellField.data, pic)"
                                class="mat-card-image border m-0 p-0 me-1" [style.background-repeat]="'no-repeat'"
                                [style.background-position]="'center'" [style.object-fit]="'contain'" />
                        </ng-container>

                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                            class="border h-100 m-0 p-1" [ngModel]="pic.comment" [readonly]="true"></textarea>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="files" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-12">
        <mat-label>
            {{ header.title | translate }}
        </mat-label>

        <input #temp matInput class="d-none" />

        <div class="row m-0">
            <div class="app-card-file col-4 p-1" *ngFor="let file of cellField.data">
                <mat-card class="card-frame w-100 h-100 p-1">
                    <mat-card-content
                        class="h-100 w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
                        <ng-container [ngSwitch]="file.content_type">
                            <video async *ngSwitchCase="'video'" (click)="openXFiles(cellField.data, file)"
                                (canplaythrough)="file.onLoaded($event)" class="mat-card-image border m-0 p-0 me-1">
                                <source [src]="file.url | safe : 'url'"
                                    type="{{ file.content_type + '/' + file.mime_type }}" />
                                Your browser does not support HTML5 video.
                            </video>

                            <ng-container *ngSwitchCase="'application'">
                                <img async priority [src]="app.dict.getFormatObj(file.mime_type)?.icon  | safe : 'url'"
                                    placeholder (load)="file.onLoaded($event)"
                                    (click)="openXFiles(cellField.data, file)" [style.background-repeat]="'no-repeat'"
                                    [style.background-position]="'center'" [style.object-fit]="'contain'" />
                            </ng-container>

                            <img async *ngSwitchDefault priority [src]="file.url | safe : 'url'" placeholder
                                (load)="file.onLoaded($event)" (click)="openXFiles(cellField.data, file)"
                                class="mat-card-image border m-0 p-0 me-1" [style.background-repeat]="'no-repeat'"
                                [style.background-position]="'center'" [style.object-fit]="'contain'" />
                        </ng-container>

                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                            class="border h-100 m-0 p-1" [ngModel]="file.comment" [readonly]="true"></textarea>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="problems" let-cellField="cellField" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="fill" class="col-12">
        <mat-label>
            {{ header.title | translate }}
        </mat-label>

        <input #temp matInput class="d-none" />

        <div class="row m-0">
            @for (problem of cellField.data; track $index) {
            <div class="app-card-problem col-6 p-1">
                <mat-card class="card-frame w-100 h-auto p-1">
                    <mat-card-header class="row m-0 p-0 pb-2">
                        <mat-card-title class="w-100 p-1 d-flex flex-nowrap justify-content-between align-items-center">
                            <h5 class="m-0">
                                <b class="m-0 me-1">{{ "problem.auditid" | translate }}:</b>
                                {{ problem.auditid }}
                            </h5>
                            <div class="d-flex flex-nowrap align-items-center">
                                <h5 class="m-0">
                                    <b class="m-0 me-1">{{ "problem.fixed" | translate }}:</b>
                                </h5>
                                <div class="toggle toggle--knob">
                                    <input type="checkbox" class="toggle--checkbox"
                                        id="field-form-toggle-problem--{{ header.key }}--{{ $index }}"
                                        [ngModel]="problem.fixed" [disabled]="true" />
                                    <label class="ms-0 toggle--btn"
                                        for="field-form-toggle-problem--{{ header.key }}--{{ $index }}">
                                        <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
                                            [attr.data-label-on]="'general.yes' | translate"></span>
                                    </label>
                                </div>
                            </div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content
                        class="h-auto w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                            class="border m-0 p-1" [ngModel]="problem.problem" [readonly]="true"></textarea>
                    </mat-card-content>
                </mat-card>
            </div>
            }
        </div>
    </mat-form-field>
</ng-template>

<ng-template pu-mat-dialog-title>{{ data.title | translate }}</ng-template>

<div class="h-100 w-100 px-2 d-flex flex-column flex-nowrap justify-content-start align-items-stretch">
    <div *ngIf="isProjectedResult()" class="w-100 d-flex flex-row">
        <mat-form-field class="nowrap">
            <mat-label>{{ "general.projects" | translate }}</mat-label>

            <input matInput class="d-none" />
            <mat-chip-set class="nowrap p-0 m-0">
                @for (header of projecthints; track $index) {
                <mat-chip class="p-0 m-0" [removable]="false">
                    {{ property(header)(this) }}
                </mat-chip>
                }
            </mat-chip-set>
        </mat-form-field>

        <mat-form-field class="nowrap flex-fill">
            <mat-label>{{ app.getenumkey(workitem?.type) | translate }}</mat-label>

            <input matInput class="d-none" />
            <div class="d-flex list-group-horizontal align-items-end justify-content-between">
                <mat-chip-set class="nowrap p-0 m-0">
                    <ng-container *ngFor="let header of workitemhints">
                        @if (property(header)(workitem)) {
                        <mat-chip class="p-0 m-0" [removable]="false">
                            {{ property(header)(workitem) }}
                        </mat-chip>
                        }
                    </ng-container>
                </mat-chip-set>

                <div class="btn-group btn-group-sm pe-3">
                    <button type="button" (click)="accordion.openAll()" class="btn btn-sm btn-outline-secondary">
                        <i class="material-icons" style="font-size: inherit">expand_more</i>
                        {{ "projectdetail.expend" | translate }}
                    </button>

                    <button type="button" (click)="accordion.closeAll()" class="btn btn-sm btn-outline-secondary">
                        <i class="material-icons" style="font-size: inherit">expand_less</i>
                        {{ "projectdetail.fold" | translate }}
                    </button>
                </div>
            </div>
        </mat-form-field>
    </div>

    <div class="w-100 overflow-auto flex-fill">
        <mat-accordion #accordion="matAccordion" multi="true" class="w-100">
            @for (rst of results; track $index) {
            <mat-expansion-panel class="grid-list-row card-frame mt-2" #panel="matExpansionPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b class="me-1">{{ "general.projects" | translate }}:{{
                            rst.project.name
                            }}/{{ rst.project.plan?.name }}</b>
                    </mat-panel-title>
                    <mat-panel-description>
                        <b class="me-1">
                            {{ (issuperviseaudit ? "supervise.people" : "execute.people") | translate }}:{{
                            rst.people?.name }}
                        </b>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <b class="me-1">
                            {{ "general.datehint" | translate }}:{{
                            rst.date | date : app.sys.datetimeFormat
                            }}
                        </b>
                    </mat-panel-description>
                    <div *ngIf="isaudit" style="margin-right: 10px">
                        <b class="me-1">
                            {{ "audit.audit.status" | translate }}:{{
                            (app.dict.AuditStatus.indexed[toAuditResult(rst)?.audit?.status ?? ''].title ?? '')
                            | translate
                            }}
                        </b>
                    </div>
                </mat-expansion-panel-header>

                <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
                <mat-form-field *ngIf="isaudit" appearance="fill" floatLabel="always" class="col-12">
                    <mat-label>
                        {{ (issuperviseaudit ? "tabs.superviseaudit" : "tabs.executeaudit") | translate }}
                    </mat-label>

                    <input #temp matInput class="d-none" />
                    <mat-card class="card-frame w-100 h-auto p-1">
                        <mat-card-header class="row m-0 p-0 pb-2">
                            <mat-card-title
                                class="w-100 p-1 d-flex flex-nowrap justify-content-between align-items-center">
                                <h5 class="m-0">
                                    <b class="m-0 me-1">{{ "audit.audit.people" | translate }}:</b>
                                    {{ toAuditResult(rst)?.audit?.people?.name }}
                                </h5>
                                <h5 class="m-0">
                                    <b class="m-0 me-1">{{ "audit.audit.status" | translate }}:</b>
                                    {{ (app.dict.AuditStatus.indexed[toAuditResult(rst)?.audit?.status ?? ''].title ?? '') | translate }}
                                </h5>
                                <h5 class="m-0">
                                    <b class="m-0 me-1">{{ "audit.audit.date" | translate }}:</b>
                                    {{ toAuditResult(rst)?.audit?.date | date : app.sys.datetimeFormat }}
                                </h5>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content
                            class="h-auto w-100 mb-0 d-flex flex-row justify-content-start align-items-stretch">
                            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                class="border m-0 p-1" [ngModel]="toAuditResult(rst)?.audit?.comment"
                                [readonly]="true"></textarea>
                        </mat-card-content>
                    </mat-card>
                </mat-form-field>

                @if (panel.expanded) {
                @let editor = rst.value;
                @let Value = app.editor.Value;

                @for (header of headers; track $index) {
                @let usable = !Value.isFieldType(header, 'onlycolumn');

                @if (usable && isVisible(header, editor)) {
                @let cellField = getCellField(editor, header, headers);
                @let optionSourceRows = getOptionSourceRows(editor, header, headers);
                @let fieldtype = Value.getFieldType(header, 'multi') || Value.Type.text;
                @let template = fieldtpls.get(Value.Type[fieldtype]) || fieldtpls.get('text') || null;
                @let context = {optionSourceRows, cellField, editor, header};
                <ng-container *ngTemplateOutlet="template; context: context">
                </ng-container>
                }

                }

                }
            </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</div>