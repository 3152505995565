import { OnDestroy, SimpleChanges, OnChanges, NgZone, Directive, Input, Output, EventEmitter, output } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { O } from 'ts-toolbelt';

import { NgxAMapPolygonCreaterService } from '../shared/inner/ngx-amap.polygon.creater.service';
import { AMapPluginLoaderService } from '../shared/amap-loader-plugin.service';
import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { AMapLoggerService } from '../shared/amap-logger.service';
import { AMapPolygon, PolygonOptions } from '../base/amap-polygon';
import { ChangeFilter, getOptions } from '../utils/options';

const TAG = 'ngx-amap-polygon';

@Directive({
    selector: 'ngx-amap-polygon',
    exportAs: 'ngxAMapPolygon',
    providers: [NgxAMapPolygonCreaterService],
    host: {
        '[style.display]': "'none'"
    }
})
export class NgxAMapPolygonDirective extends AMapPolygon<AMap.Polygon> implements AMap.Polygon.Options, OnChanges, OnDestroy {
    private inited: boolean = false;

    @Input()
    district?: string;

    readonly onPath = output<AMap.LocationValue[] | AMap.LocationValue[][] | undefined>({ alias: 'pathChange' });

    readonly targetDistrict?: AMap.DistrictSearch.District;

    constructor(
        protected readonly polygoncreater: NgxAMapPolygonCreaterService,
        protected override readonly binder: AMapEventBinderService,
        protected readonly plugin: AMapPluginLoaderService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
        super(polygoncreater, binder);
    }

    ngOnDestroy(): void {
        this.polygoncreater.destroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { logger, polygoncreater, naReady, ngZone, plugin, onPath } = this;
        const filter = ChangeFilter.from(changes);
        const polygon$ = this.get();

        if (!this.inited) {
            logger.d(TAG, 'initializing ...');

            const options = this.options || getOptions<NgxAMapPolygonDirective, AMap.Polygon.Options>(
                this, PolygonOptions
            );

            logger.d(TAG, 'options:', options);
            polygoncreater.create(options).subscribe(
                m => {
                    logger.d(TAG, 'polygon is ready.');
                    ngZone.run(() => {
                        naReady.emit(m)
                    });
                }
            );

            this.inited = true;
        }

        zip(filter.has('path'), polygon$).subscribe(
            ([v, p]) => {
                if (!this.district) {
                    p.setPath(v)
                }
            }
        );

        zip(filter.has('options'), polygon$).subscribe(
            ([v, p]) => p.setOptions(v || {})
        );

        zip(filter.has('extData'), polygon$).subscribe(
            ([v, p]) => p.setExtData(v)
        );

        zip(filter.has('hidden'), polygon$).subscribe(
            ([v, p]) => (v ? p.hide() : p.show())
        );

        zip(filter.has('district'), polygon$, plugin.load('AMap.DistrictSearch')).subscribe(
            ([district, p]) => {
                const districtSearch = new AMap.DistrictSearch({
                    extensions: 'all',
                    level: 'district',
                    subdistrict: 1,
                });

                districtSearch.search(district!, (status, result): void => {
                    if (_.isString(result)) return;

                    const { districtList: [district] } = result;
                    Promise.resolve().then(() => {
                        p.setPath(district?.boundaries ?? []);

                        ngZone.run(() => {
                            (this as O.Writable<NgxAMapPolygonDirective>).targetDistrict = district;
                            this.path = district?.boundaries;
                            onPath.emit(district?.boundaries);
                        })
                    });
                });
            }
        )
    }

    /**
     * 获取已创建的 AMap.Polygon 对象
     */
    get(): Observable<AMap.Polygon> {
        return this.polygoncreater.get();
    }
}
