import { Inject, Injectable, InjectionToken, Injector, OnDestroy, Optional, SkipSelf } from "@angular/core";
import { map, merge, Observable, of, ReplaySubject, shareReplay, switchMap, tap } from "rxjs";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { If } from "ts-toolbelt/out/Any/If";
import { A, O, U } from "ts-toolbelt";

/**
 * interpolation expression need to interpret by client with pattern {{  }}
 * 1. `xxx${***}zzz`: interpret each ***, and format final string
 * 2. $***: translate

 * special value interpret:
 * backfaces.*:
 *     1. interpret as query
 * charts.*.merge: 
 *     1. any leaf string treat as value path, or interpret pattern
 * charts.*.backface.*: 
 *     1. any string value treat as backface reference
 * dashboards.*.*[type:'chart'].board.merge:
 *     2. need to clone
 */

/**
 * CTQ:
 * 1. can inherit and fork service, inherit all parent service and locally extend
 * 2. can identify which dashboards have been used to update locally
 */

/**
 * TODO: Dynamic schema update, add/remove/update
 */

type Settings = Record<string, number>;
type Actions = {
    [name: string]: Function | Actions
} & {
    $param: any;
    readonly $events: {
        readonly current: any;
        push(event: any): void;
        pop(): any;
    }
}

type TransFunc = {
    (param: { [param: string]: any }): { [param: string]: any }
}

interface RawSchema {
    indexes: Schema.Indexes,

    transParam: TransFunc & {
        [param: string]: TransFunc
    },

    recordsets: {
        [key: string]: Schema.IRecordset | {
            indexed: Record<string | number, any>
        };
    },

    dashboards: Schema.Dashboards,
    headers: Schema.IHeadersets,
    entities: Schema.IEntities,
    settings: Settings,
    actions: Actions,

    backfaces: any,
    cards: any,
    charts: any
}

interface ApiSchema extends Omit<RawSchema, 'indexes'> {
    // schema: RawSchema any, // 
}

type ApiKeys = keyof ApiSchema;
const ApiKeys: ApiKeys[] = [
    'actions', 'dashboards', 'entities',
    'headers', 'recordsets', 'settings',
    'backfaces', 'cards', 'charts',
    'transParam'
];

type UsedSchema = {
    [P in ApiKeys]?: Partial<ApiSchema[P]>;
}

type TargetSchema = {
    [P in ApiKeys]?: XArray<
        ApiSchema[P],
        ApiSchema[P],
        ApiSchema[P]
    >;
};

type Querier = {
    (param: any): Observable<any>
}

type Queriers = {
    [name: string]: Querier
};

type Updator = {
    (param: any, qomap: QuerierMap): Observable<void> | Observable<void>[]
}

type QDSlot = (QuerierMap['qdMap'] extends Map<
    any, infer D
> ? D : never)

class QuerierMap {
    private readonly qdMap: Map<Querier, Observable<any> & {
        records: {
            [rcdname: string]: Observable<any>
        }
    }> = new Map();

    private readonly dqMap: {
        [rcdname: string]: {
            q: Querier, o: Observable<any>
        }
    } = {};

    add(querier: Querier, observable: Observable<any>): QDSlot;
    add(querier: Querier, observable: Observable<any>, rcdname: string): Observable<any>;
    add(querier: Querier, observable: Observable<any>, rcdname?: string): Observable<any> | QDSlot {
        const { qdMap, dqMap } = this;

        if (!rcdname) {
            // only register querier observable
            let _qo = qdMap.get(querier);
            if (_qo && _qo != observable) {
                throw new Error(`internal QuerierMap state error, observable changed unexpected`);
            }

            if (_qo == null) {
                qdMap.set(querier, _qo = _.extend(
                    observable, { records: {} }
                ))
            }

            return _qo;
        }

        // register a recordset assigner
        const _dq = dqMap[rcdname];
        if (_dq) {
            if ((_dq.q != querier || _dq.o != observable) || (qdMap.get(querier)?.records[rcdname] != observable)) {
                throw new Error(`record "${rcdname}" been assigned by different query`);
            }

            return observable;
        }

        const _qd = qdMap.get(querier);
        if (_qd == null) {
            throw new Error(`internal QuerierMap state error, querier not yet register for record "${rcdname}" assigner`);
        }

        dqMap[rcdname] = { o: observable, q: querier }
        _qd.records[rcdname] = observable;
        return observable;
    }

    get(querier: Querier): QDSlot | undefined;
    get(rcdname: string): Observable<any> | undefined;
    get(rq: string | Querier): Observable<any> | QDSlot | undefined {
        if (_.isString(rq)) return this.dqMap[rq]?.o;
        return this.qdMap.get(rq);
    }
}

const DefSchemaUrl = '/schema/retrieve';

const QUSlot = Prop.Slot<{
    action?: (() => void)[],
    uses?: Updator[],
    raws?: Updator[],
    raw?: Updator,

    addaction(action: () => void): void,
    addraw(raw: Updator): void,
}>('SCHEMAQU', parent => {
    const slot: Exclude<typeof parent, undefined> = {
        addaction(action: () => void): void {
            slot.action = slot.action || [];
            slot.action.push(action);

            if (!slot.raw) {
                slot.addraw(() => of())
            }
        },
        addraw(raw: Updator): void {
            const raws = (slot.raws || (slot.raws = []));
            raws.push(raw);
            if (slot.raw) {
                return;
            }

            slot.raw = ((param: any, qomap: QuerierMap): Observable<void> | Observable<void>[] => {
                // call each updator and collect all observables
                const rawsobs = _.transform<Updator, Set<Observable<any>>>(
                    slot.raws!, (ro, r) => {
                        const ros = r(param, qomap);
                        _.castArray(ros).forEach(
                            o => ro.add(o)
                        )
                    }, new Set()
                );

                if (!slot.action) {
                    // no action to do
                    return [...rawsobs];
                }

                // wait updators, and execute actions
                if (rawsobs.size == 0) {
                    rawsobs.add(of())
                }

                const ob = merge(...rawsobs).pipe(
                    map(() => { }),
                    shareReplay(1)
                )

                const sub = ob.subscribe({
                    next() {
                        slot.action?.forEach(a => a());
                        tick(() => sub?.unsubscribe());
                    }
                })

                return ob;
            })
        }
    }

    return slot;
})

const LoaderSlot = Prop.Slot<{
    onloaded?: Schema.IRecordset['onloaded'],
    loaded: boolean
}>('RECORDSETLOADER', parent => {
    return { loaded: false }
})

const StateToken = new InjectionToken<{ building: boolean }>('InterpretState');
function interpret(schema: RawSchema, http: HttpClient): RawSchema {
    type Item = object | Function | string | number | Array<Item>;
    type PathCtx = JSON.Interpret.PathCtx<ApiSchema, Item>;
    type Pather = JSON.Interpret.Pather<ApiSchema, Item>;
    type Pathed = JSON.Interpret.Pathed<ApiSchema, Item>;
    type Valued = JSON.Interpret.Valued<ApiSchema, Item>;
    type Defer = JSON.Interpret.Defer<ApiSchema, Item>;
    type Path = JSON.Interpret.Path;

    const PCtx = JSON.Interpret.PathCtx<ApiSchema, Item>;
    const Ignore = JSON.Interpret.Ignore;
    const { Slot } = JSON.Interpret;

    function makeAssigner(param: any, qomap: QuerierMap, ctx: ApiSchema, backface?: Queriers): Observable<void> | Observable<void>[] {
        if (!backface) return [];

        const kos: Observable<void>[] = [];
        const recordsets = ctx.recordsets;

        for (const vk in backface) {
            const q = backface[vk];
            if (!_.isFunction(q)) {
                continue;
            }

            const qobv = qomap.get(q) ?? qomap.add(
                q, q(param)
            );

            const dobv = (qomap.get(vk) ?? qomap.add(q, qobv.pipe(
                tap((val) => { recordsets[vk] = val; }),
                shareReplay(1)
            ), vk));

            kos.push(dobv);
        }

        return kos;
    }

    function makeUpdatorChain(ljson: Item | undefined, ctx: ApiSchema): Item | undefined {
        if (!_.isObject(ljson)) return;

        const tryslot = QUSlot.Of(ljson, false);
        if (tryslot?.uses) {
            // has made chain
            return ljson;
        }

        // recursive deep build updator chain...
        const uses: Updator[] = [];
        _.forEach(ljson, (_lsub: Item | undefined, _key: string | number) => {
            _lsub = makeUpdatorChain(_lsub, ctx);
            if (_lsub !== undefined) _.set(
                ljson, _key, _lsub
            );

            if (!_.isObject(_lsub)) return;

            const _slot = QUSlot.Of(_lsub, false);
            const _uses = _slot?.uses;
            if (!_uses) return;

            // merge the sub uses.
            uses.push(..._uses);
        })

        // merge selves if have, and unique them
        tryslot?.raw && uses.push(tryslot?.raw);
        uses.splice(0, uses.length, ..._.uniq(uses));

        // whether has updators from subs or self
        if (uses.length <= 0) return;

        // update the uses updators
        const slot = QUSlot.Of(ljson, true);
        slot.uses = uses;

        if (uses.length == 1) {
            // only one updator from sub or self
            // anyway just delegate
            slot.raw = uses[0];
            return ljson;
        }

        // multi updators, combine to raw
        slot.raw = (param: any, qomap: QuerierMap): Observable<void> | Observable<void>[] => {
            const uos = _.transform<Updator, Set<Observable<void>>>(
                uses, (uo, u) => {
                    const uos = u(param, qomap);
                    _.castArray(uos).forEach(
                        o => uo.add(o)
                    )
                }, new Set()
            );

            return [...uos];
        }

        return ljson;
    }

    const PathCtx: {
        $valued: Valued,
        $schema: PathCtx,
    } = {
        $valued(rhost: Item | undefined, path: Path, rsub: Item): Pathed {
            if (Interpolation.is(rsub)) {
                const _rsub: Interpolation.Expression = rsub;

                // compile the interpolation
                return {
                    isub: _rsub, localizer(lhost: Item | undefined, path: Path, lsub: Item | undefined, ctx: ApiSchema, injector: Injector, ldefers: Defer[]): Item | undefined {
                        const key = path[path.length - 1];
                        if (!_.isObject(lhost) || key == null) {
                            throw new Error(`Invalid path '${path.join('.')}'`);
                        }

                        if (_rsub !== lsub) {
                            throw new Error(`cannot change the interpolation expression from '${_rsub}' to '${lsub}' for '${path.join('.')}'`);
                        }

                        const state = injector.get(StateToken);
                        const handler = Interpolation.build(_rsub);

                        if (key === '$$action') {
                            const _handler = ($event: any) => {
                                const { actions: { $param } } = injector.get(PuSchemaService);
                                if (_.isObject($param)) $event = { ...$event, ...$param };
                                handler?.(ctx, { $event });
                            }

                            Object.defineProperty(lhost!, key!, {
                                get() { return state.building ? undefined : _handler; }
                            })
                        } else {
                            Object.defineProperty(lhost!, key!, {
                                get() { return state.building ? undefined : handler?.(ctx); }
                            })
                        }

                        // ALERT: should not return lsub which 
                        // is replaced by property getter
                        return Ignore;
                    }
                }
            }

            if (_.isString(rsub) && rsub.trim().charAt(0) === '$') {
                // $ specified schema reference, need derefer after localized
                rsub = rsub.slice(1);

                return {
                    isub: rsub, localizer(lhost: Item | undefined, path: Path, lsub: Item | undefined, ctx: ApiSchema, injector: Injector, ldefers: Defer[]): Item | undefined {
                        if (rsub !== lsub) {
                            throw new Error(`cannot change the schema refer from '${rsub}' to '${lsub}' for '${path.join('.')}'`);
                        }

                        // add localized defer handler to reference final schema definition
                        ldefers.push((ctx: ApiSchema, injector: Injector, lfulljson: Item): void => {
                            if (!(_.isObject(lfulljson) && _.isObject(lhost) && _.isString(lsub))) {
                                throw new Error(`Invalid dashboard configuration for '${path.join('.')}'`);
                            }

                            const val = _.get(lfulljson, lsub);
                            _.set(lhost, path.last ?? '', val);
                        })

                        return lsub;
                    }
                }
            }

            return {
                isub: rsub
            };
        },

        $schema: {
            backfaces: {
                ['*'](rhost: Item, path: Path, rsub: Item): Pathed {
                    const { url, defaultparam = {} } = rsub as any;

                    const querier: Querier = (param: any): Observable<any> => {
                        param = { ...defaultparam, ...(param || {}) }
                        const ro = (url ? http.post(url, param) : of(param)).pipe(
                            shareReplay(1)
                        );

                        const sub = ro.subscribe(() => {
                            tick(() => sub?.unsubscribe());
                        });

                        return ro;
                    }

                    return {
                        isub: querier
                    }
                }
            },
            charts: {
                '*': (rhost: Item, path: Path, rsub: Item): Pathed => {
                    return {
                        isub: rsub, localizer(lhost: Item | undefined, path: Path, lsub: Item | undefined, ctx: ApiSchema, injector: Injector, ldefers: Defer[]): Item | undefined {
                            if (!_.isObject(lsub)) {
                                throw new Error(`Invalid backface configuration for '${path.join('.')}'`);
                            }

                            const chart: {
                                merge?: Record<string | number, any>
                                backface?: Queriers
                            } = lsub as any;

                            const { merge } = chart;
                            if (!_.isObject(merge)) return lsub;

                            // add query update
                            const slot = QUSlot.Of(chart, true);
                            const action = ((): void => {
                                chart.merge = { ...merge };
                            })

                            slot.addaction(action);
                        }
                    }
                }
            },
            '**': {
                backface: PCtx(
                    (rhost: Item, path: Path, rsub: Item): Pathed => {
                        return {
                            isub: rsub, localizer(lhost: Item | undefined, path: Path, lsub: Item | undefined, ctx: ApiSchema, injector: Injector, ldefers: Defer[]): Item | undefined {
                                /* lhost.backface = lsub; lsub = { [k:string]: query func } */

                                const backface = lsub as Queriers;
                                if (_.isObject(lhost) && _.isObject(backface) && Object.keys(backface).length > 0) {
                                    const slot = QUSlot.Of(lhost, true);

                                    const raw: Updator = ((param: any, qomap: QuerierMap): Observable<void> | Observable<void>[] => {
                                        return makeAssigner(param, qomap, ctx, backface);
                                    })

                                    slot.addraw(raw);
                                }

                                return lsub;
                            }
                        }
                    },
                    {
                        ['*'](rhost: Item, path: Path, rsub: Item): Pathed {
                            return {
                                isub: rsub, idefer(ifulljson: Item): void {
                                    // reference to real backface query method
                                    if (!(_.isObject(ifulljson) && _.isString(rsub))) {
                                        throw new Error(`Invalid backface configuration for '${path.join('.')}'`);
                                    }

                                    // derefer during interpreted stage, 
                                    // can only refer to one without localization required
                                    const val = _.get(ifulljson, rsub);
                                    if (!!Slot.Of(val, false)?.tolocal) {
                                        throw new Error(`${rsub} refer a localizing for '${path.join('.')}'`)
                                    }

                                    // assign real backface query method
                                    _.set(ifulljson, path, val);
                                }
                            }
                        }
                    }
                )
            }
        }
    }

    const interpreted = JSON.Interpret(schema, PathCtx.$valued, PathCtx.$schema, makeUpdatorChain);
    const { ifulljson, localizer } = interpreted;
    return ifulljson as RawSchema;
}

type KnownKeys<O extends object> = _KnowKeys<{ [K in keyof O]: string extends K ? never : number extends K ? never : K; }>;
type _KnowKeys<O extends object> = O extends { [K in keyof O]: infer U; } ? U & A.Keys<O> : never;
type Readonlies<O extends object> = {
    [K in KnownKeys<O>]: K extends keyof O ? If<
        A.Equals<Pick<O, K>, { readonly [k in K]: O[K] }>, {
            readonly [k in K]: () => O[K]
        },
        never
    > : never
}[KnownKeys<O>];

type DefaultsApis = typeof DefaultsApis;
const DefaultsApis: U.IntersectOf<{
    [P in ApiKeys]: If<
        A.Equals<Readonlies<ApiSchema[P]>, never>, never, {
            [p in P]: Readonlies<ApiSchema[P]>
        }
    >
}[ApiKeys]> = {
    actions: {
        $events: () => {
            const events: any[] = [];
            return {
                get current(): any {
                    return events[0];
                },

                push(event: any): void {
                    events.splice(0, 0, event);
                },

                pop(): any {
                    events.splice(0, 1);
                }
            }
        }
    }
}

function tryValueGetter(val: any): any {
    if (_.isFunction(val) && (val as any).$Type == PuSchemaService.ApiType.AsValueGetter) {
        return val();
    }

    return val;
}

type TrapGetter = (force: boolean, key: string, ...args: any[]) => any;
function makeProxy<ApiKey extends ApiKeys>(apikey: ApiKey, targets: TargetSchema, allused: UsedSchema, trapGetter?: TrapGetter, parent?: PuSchemaService): ApiSchema[ApiKey] {
    type DefaultsVal = { [P in keyof DefaultsApi]: DefaultsApi[P] extends (...args: any[]) => any ? ReturnType<DefaultsApi[P]> : DefaultsApi[P] };
    type DefaultsApi = ApiKey extends keyof DefaultsApis ? DefaultsApis[ApiKey] : {};
    type API = ApiSchema[ApiKey];

    const api = {
        use(key: string, val: any) {
            _.set(api.used, key, val);
            return val;
        },
        get used(): Exclude<UsedSchema[ApiKey], undefined | null> {
            return <any>(allused[apikey] || (allused[apikey] = {}));
        },

        get sets(): TargetSchema[ApiKey] | undefined {
            return targets[apikey];
        },

        get current(): API | undefined {
            return targets[apikey]?.indexed as API;
        },

        get parent(): API | undefined {
            const _parent = parent?.[apikey];
            if (!_.isFunction(_parent)) return _parent;
            return Object.getPrototypeOf(_parent);
        }
    }

    const defaultsApi: DefaultsApi = _.get<object, string, DefaultsApi>(DefaultsApis, apikey, <DefaultsApi>{});
    const defaultsVal: DefaultsVal = _.transform<DefaultsApi, DefaultsVal>(defaultsApi, (r, v, k) => {
        r[k] = _.isFunction(v) ? v() : v;
    }, <DefaultsVal>{});

    const trap = _.extend({
        get raw(): API {
            return _.transform(Object.keys(proxy), (raw, key) => {
                raw[key] = proxy[key]
            }, <API>{})
        }
    }, defaultsVal)

    const proxy = new Proxy<API>(<any>trap, Prop.makeProxyHandler<API>({
        getOwnPropertyDescriptor(target, p: string | symbol): PropertyDescriptor | undefined {
            if (_.isSymbol(p)) return Object.getOwnPropertyDescriptor(trap, p);

            const _current = api.current, _parent = api.parent;
            const desc = _current ? Object.getOwnPropertyDescriptor(_current, p) : undefined;
            if (desc) return desc;

            return _parent ? Object.getOwnPropertyDescriptor(_parent, p) : undefined;
        },

        defineProperty(target, p: string | symbol, attributes): boolean {
            if (_.isSymbol(p)) { Object.defineProperty(trap, p, attributes); return true; }
            if (api.current) Object.defineProperty(api.current, p, attributes);
            return !!api.current;
        },

        getPrototypeOf(target): object | null {
            // make sure this proxy is plain object liked
            return {};
        },

        get(_target, key: string | symbol) {
            if (_.isSymbol(key)) {
                return (trap as any)[key];
            }

            if (key === 'hasOwnProperty') {
                return trap.hasOwnProperty;
            }

            if (_.has(trap, key)) {
                // readonly property
                return trap[key];
            }

            // try from this schema service
            let val = tryValueGetter(api.current?.[key]);
            if (val != null) return api.use(key, val);

            // try registered specially for this schema service
            const trygotten = trapGetter?.(false, key);
            if (trygotten) return api.use(key, trygotten);

            // try from parent schema service
            val = tryValueGetter(api.parent?.[key]);
            if (val != null) return api.use(key, val);

            // try force registered from this schema service
            const forcegotten = trapGetter?.(true, key);
            if (forcegotten) return api.use(key, forcegotten);

            return;
        },

        set(_target, key: string | symbol, value: any): boolean {
            if (_.isSymbol(key)) {
                (trap as any)[key] = value;
                return true;
            }

            if (_.has(trap, key)) {
                // readonly property
                return false;
            }

            const _sets = api.sets;
            // if (_.isArray(_sets))
            _sets?.replace(<API>{
                [key]: value
            });

            return !!_sets;
        },

        deleteProperty(_target, key: string | symbol): boolean {
            if (_.isSymbol(key)) {
                delete (trap as any)[key];
                return true;
            }

            if (_.has(trap, key)) {
                // readonly property
                return false;
            }

            const _current = api.current;
            const item = _current?.[key];
            if (item == null) {
                return false;
            };

            const _sets = api.sets, _used = api.used;
            _sets?.remove(<API>{ [key]: item });
            delete _used[key];
            return true;
        },

        has(_target, key: string | symbol): boolean {
            if (_.isSymbol(key)) return _.has(trap, key);
            if (key === 'hasOwnProperty') return true;

            const _current = api.current, _parent = api.parent;
            return !!(_current?.[key] || _parent?.[key]);
        },

        ownKeys(_target): ArrayLike<string> {
            const _current = api.current, _parent = api.parent;
            return _.uniq(Object.keys(_current ?? {}).concat(
                Object.keys(_parent ?? {})
            ));
        }
    }))

    return proxy;
}

function makeTarget<ApiKey extends ApiKeys>(apikey: ApiKey, indexes: Schema.Indexes, raws?: RawSchema): TargetSchema[ApiKey] {
    type API = ApiSchema[ApiKey];
    type APIItem = API[string];

    const rawval: any = raws?.[apikey];
    return XArray.createByIndex<APIItem>(indexes[apikey] ?? {}, [true, false], rawval) as any;
}

@Injectable({ providedIn: 'root' })
export class PuSchemaService implements Resolve<object>, OnDestroy {
    private _props = Prop.Of<PuSchemaService, {
        // section of recordset CRUD
        loaders: {
            [P in ApiKeys]?: {
                [K: string]: any
            }
        },

        // section of schema build and track
        fetcher: ReplaySubject<RawSchema>,
        inherited: boolean,

        target: TargetSchema,
        allused: UsedSchema,
        api: ApiSchema,
    }>(this, (values) => {
        const _this = this as O.Writable<PuSchemaService>;

        /**
         * Section of schema build and track
         */
        const parent = (_this.parent = this.parent || undefined);
        const schemaUrl = (_this.schemaUrl = this.schemaUrl || DefSchemaUrl);
        const inherited = (values.inherited = (parent?.schemaUrl === schemaUrl));
        const allused: Record<string, any> = (values.allused = {});
        const fetcher = (values.fetcher = new ReplaySubject(1));
        const target: TargetSchema = (values.target = {});

        const state = { building: true };
        const { http } = this;

        const injector = Injector.create({
            name: 'SchemaInterpretState',
            parent: this.injector,
            providers: [{
                provide: StateToken,
                useValue: state
            }]
        });

        const trapGetter: {
            [P in ApiKeys]?: TrapGetter
        } = {
            recordsets: this.getRecordsetAccessor.bind(this)
        }

        // build api proxy
        const api: ApiSchema = (values.api = _.transform(
            ApiKeys, <ApiKey extends ApiKeys>(res: ApiSchema, apikey: ApiKey) => {
                res[apikey] = makeProxy(apikey, target, allused, trapGetter[apikey], parent);
            }, <ApiSchema>{}
        ));

        // fetch the schema and build localized values
        const interpretted = (inherited ? parent!.resolve() :
            http.get<RawSchema>(schemaUrl, {
                headers: {
                    ['Cache-Control']: 'no-cache',
                    ['Pragma']: 'no-cache',
                }
            }).pipe(
                map<RawSchema, RawSchema>((rschema) => {
                    return interpret(rschema, http);
                }),
                shareReplay(1)
            )
        );

        const sub = interpretted.subscribe({
            next(ischema) {
                // the schema is a interpreted schema, need to localize for this service.
                const { localizer } = JSON.Interpret.Slot.Of(ischema, true);
                const lschema = localizer?.<ApiSchema, RawSchema>(api, injector) ?? ischema;
                const { indexes = {} } = lschema;

                // build critical schema sections
                _.transform(
                    ApiKeys, <ApiKey extends ApiKeys>(res: TargetSchema, apikey: ApiKey) => {
                        res[apikey] = makeTarget(apikey, indexes, lschema);
                    }, target
                );

                state.building = false;

                // We have finished the schema localization:
                // 1. buffer the interpreted schema for any decendants; 
                // 2. notify preparation finished
                fetcher.next(ischema);
                fetcher.complete();
                tick(() => {
                    sub?.unsubscribe()
                });
            }
        })

        /**
         * Section of recordset CRUD
         */
        const loaders = (values.loaders = {});
    });

    get entities(): ApiSchema['entities'] {
        return this._props.api.entities;
    }

    get recordsets(): ApiSchema['recordsets'] {
        return this._props.api.recordsets;
    }

    get settings(): ApiSchema['settings'] {
        return this._props.api.settings;
    }

    get actions(): ApiSchema['actions'] {
        return this._props.api.actions;
    }

    get dashboards(): ApiSchema['dashboards'] {
        return this._props.api.dashboards;
    }

    get headers(): ApiSchema['headers'] {
        return this._props.api.headers;
    }

    get backfaces(): ApiSchema['backfaces'] {
        return this._props.api.backfaces;
    }

    get cards(): ApiSchema['cards'] {
        return this._props.api.cards;
    }

    get charts(): ApiSchema['charts'] {
        return this._props.api.charts;
    }

    get transParam(): ApiSchema['transParam'] {
        const { _props: props } = this;

        if (props.transParam == null) {
            props.transParam = Object.setPrototypeOf(
                (param: { [param: string]: any }): { [param: string]: any } => {
                    param = { ...param }

                    const _transParam = props.api.transParam;
                    const { searcher } = param;
                    delete param.searcher;
                    _.extend(param, searcher);

                    Object.keys(param).forEach(k => {
                        _transParam[k]?.(param);
                    })

                    return param;
                },

                props.api.transParam
            )
        }

        return props.transParam!;
    }

    get used(): {
        readonly current: UsedSchema,

        /**
         * if no path provide, empty used info,
         * else clear path specified if have
         */
        clear(path?: string | ((string | number)[])): void
    } {
        const { _props: props, _props: { allused } } = this;

        return props.used || (
            props.used = {
                get current() {
                    return allused
                },

                clear(path?: string | ((string | number)[])): void {
                    if (path == null) {
                        _.forEach(_.keys(allused), (k) => {
                            delete (allused as any)[k]
                        })

                        return;
                    }

                    if (_.isString(path)) {
                        path = _.toPath(path);
                    }

                    if (path.length == 0) return;

                    let cp: string | number | undefined, cv: any = allused;
                    for (let idx = 0; idx < path.length;) {
                        cv = cp != null ? cv[cp] : cv;
                        cp = path[idx];
                    }

                    if (_.isObject(cv) && cp != null) {
                        delete (cv as any)[cp];
                    }
                }
            }
        );
    }

    constructor(
        private readonly http: HttpClient,
        public readonly injector: Injector,

        @Optional() @SkipSelf()
        public readonly parent?: PuSchemaService,

        @Optional()
        @Inject(PuSchemaService.SCHEMA_URL)
        public readonly schemaUrl: string = DefSchemaUrl
    ) {
    }

    ngOnDestroy() {
    }

    regGetter<P extends keyof ApiSchema>(api: P, key: string, getter: {
        $Type: PuSchemaService.ApiType,
        (): ApiSchema[P][string]
    }) {
        this[api][key] = getter;
    }

    update(param: { [param: string]: any } = {}): Observable<void> {
        const { actions, transParam } = this;
        const $param = (actions.$param = {
            ...(
                param = {
                    ...param
                }
            )
        });

        // flatten the params.
        delete $param.searcher;
        param = this.transParam(param);
        _.extend($param, param);

        // all updator func from used schema
        const { _props: { allused, loaders } } = this;
        const updators: Set<Updator> = new Set();
        for (const apikey in allused) {
            const usedapi = allused[apikey as ApiKeys];
            for (const usedobjkey in usedapi) {
                const usedobj = usedapi[usedobjkey];
                if (!_.isObject(usedobj)) continue;

                const slot = QUSlot.Of(usedobj, false);
                const uses = slot?.uses;
                if (!uses) continue;

                uses.forEach(u => updators.add(u));
            }
        }

        // all used observables from schema
        const uos: Set<Observable<void>> = new Set();
        const qomap: QuerierMap = new QuerierMap();
        updators.forEach(ufunc => {
            const uo = ufunc(param, qomap);
            _.castArray(uo).forEach(
                o => uos.add(o)
            )
        })

        // all used observables from loaders
        for (const apikey in allused) {
            const usedloaders = loaders[apikey as ApiKeys];
            for (const usedobjkey in (usedloaders ?? {})) {
                const usedobj = usedloaders?.[usedobjkey];
                const load = usedobj?.load;
                if (!_.isFunction(load)) {
                    continue;
                }

                uos.add(load.call(usedobj, param))
            }
        }

        if (uos.size == 0) {
            uos.add(of());
        }

        // wait all async querier's observable
        return merge(...uos).pipe(
            map(() => { }),
            shareReplay(1)
        );
    }

    resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<RawSchema> {
        return this._props.fetcher;
    }


    load(entity: string, recordset?: Schema.IRecordset, reqparam?: Record<string | number, any>): Observable<false | Schema.IRecord[]> {
        recordset = (recordset ?? this.getRecordsetAccessor(true, entity ?? ''));
        if (!recordset || recordset.loader) return of(false);

        // not yet loaded, mark it
        const _slot = LoaderSlot.Of(recordset, true);
        _slot.loaded = false;

        const _entity = this.entities[entity ?? ''], { backface: _backface } = _entity;
        const _loaderurl = (_.isString(_backface) ? `${_backface}/load` : _backface?.load);
        if (!_loaderurl) return of(false);

        const loader = (recordset.loader = this.http.post<Schema.IRecord[]>(
            _loaderurl, { ...(recordset.reqparam ?? {}), ...(reqparam ?? {}) }
        ).pipe(
            switchMap((value: Schema.IRecord[]): Observable<Schema.IRecord[]> => {
                recordset.reset.apply(recordset, _.isArray(value) ? value : [value]);

                // has finished data initialize
                _slot.loaded = true;

                // notify the record changement
                const onloaded = recordset.onloaded;
                onloaded?.forEach((callback) => {
                    callback(recordset)
                })

                // has finished all load process
                return of(value);
            }),
            shareReplay(1)
        ));

        const sub = loader.subscribe({
            next() {
                tick(() => {
                    delete recordset.loader;
                    sub?.unsubscribe();
                });
            }
        })

        return loader;
    }

    save(record: Schema.IRecord | Schema.IRecord[], entity: string): Observable<boolean | Schema.IRecord[]> {
        if (_.isArray(record) && record.length <= 0) {
            return of(true);
        }

        const recordset = this.recordsets[entity] as Schema.IRecordset;
        if (!recordset || recordset.saver) return of(false);

        const _entity = this.entities[entity ?? ''], { backface: _backface } = _entity;
        const _saverurl = (_.isString(_backface) ? `${_backface}/save` : _backface?.save);
        if (!_saverurl) return of(false);

        const saver = (recordset.saver = this.http.post<Schema.IRecord[]>(
            _saverurl, record
        ).pipe(
            switchMap((resps: Schema.IRecord[]): Observable<Schema.IRecord[]> => {
                const reqs = _.castArray<Schema.IRecord>(record);
                const idxkey = _entity.index?.key ?? 'id';
                const indexed = recordset.indexed!;

                reqs.forEach((reqrcd) => {
                    if (reqrcd[idxkey]) return;

                    // newly created record
                    if (reqs.length !== 1 || resps.length !== 1) {
                        throw new Error(`only one newly create record can be process while saving, entity: "${entity}"`);
                    }

                    const resprcd = _.merge(reqrcd, resps[0]);
                    recordset.replace(reqrcd, resprcd);
                })

                resps.forEach((resprcd) => {
                    const idxval = resprcd[idxkey] as string;
                    const reqrcd = indexed[idxval!];
                    console.assert(idxval);

                    if (!reqrcd) {
                        // seems some new items created
                        indexed[idxval!] = resprcd;
                        if (!recordset.has(resprcd)) {
                            recordset?.push(resprcd);
                        }
                    } else {
                        _.merge(reqrcd, resprcd);
                    }
                })

                return of(reqs);
            }),
            shareReplay(1)
        ));

        const sub = saver.subscribe({
            next() {
                tick(() => {
                    delete recordset.saver;
                    sub?.unsubscribe();
                })
            }
        });

        return saver;
    }

    delete(record: Schema.IRecord | Schema.IRecord[], entity: string): Observable<boolean> {
        if (_.isArray(record) && record.length <= 0) {
            return of(true);
        }

        const recordset = this.recordsets[entity] as Schema.IRecordset;
        if (!recordset || recordset.deleter) return of(false);

        const _entity = this.entities[entity ?? ''], { backface: _backface } = _entity;
        const _deleterurl = (_.isString(_backface) ? `${_backface}/delete` : _backface?.delte);
        if (!_deleterurl) return of(false);

        const idxkey = _entity.index?.key ?? 'id';
        record = _.isArray(record) ? record : [record];
        const neweds = record.filter(r => r[idxkey] == null);
        neweds.map(r => { record.remove(r); recordset.remove(r); });

        // after remove all newed record to be deleted
        const idxval = record.map(r => r[idxkey]);

        if (idxval.length == 0) {
            // there no record with id to be delete
            return of(true);
        }

        const deleter = (recordset.deleter = this.http.post(
            _deleterurl, { [idxkey]: idxval }
        ).pipe(
            switchMap((resp: any): Observable<boolean> => {
                const { indexed } = recordset;

                record.forEach(reqrcd => {
                    const idxval = (reqrcd[idxkey] ?? '') as string;
                    const idxobj = indexed?.[idxval];

                    recordset?.remove(reqrcd, idxobj);
                    delete indexed[idxval];
                })

                return of(true);
            }),
            shareReplay(1)
        ));

        const sub = deleter.subscribe({
            next() {
                tick(() => {
                    delete recordset.deleter;
                    sub?.unsubscribe();
                })
            }
        })

        return deleter;
    }


    private getRecordsetAccessor(force: boolean, key: string, reqparam?: Record<string | number, any>, autoload: boolean = true): Schema.IRecordset | undefined {
        // if key in entities and has backface defined
        // means we have to load the recordset of this entity automatically
        const { entities } = this, _this = this;
        const entity = entities[key];
        if (entity == null) return;

        const { backface } = entity;
        if (backface == null || (_.isObject(backface) && backface.load == null)) return;

        // as a loadable recordset
        const { _props: props } = this;
        const { index = {} } = entity;

        const { loaders } = props;
        const rcdsloaders = (loaders.recordsets || (
            loaders.recordsets = {}
        ))

        if (!force) return rcdsloaders[key];

        // force create
        let rcdset = rcdsloaders[key];
        if (rcdset && (!reqparam || (reqparam && rcdset.reqparam == reqparam))) {
            return rcdset;
        }

        const prop = { enumerable: true, configurable: true };
        const propreadonly = { ...prop, writable: false };
        rcdset = (rcdsloaders[key] = Object.defineProperties(XArray.createByIndex(index, [false, false]), {
            reqparam: {
                ...propreadonly, value: reqparam ?? {}
            },
            autoload: {
                ...propreadonly, value: autoload
            },
            entity: {
                ...propreadonly, value: key
            },
            onloaded: {
                ...prop, get(): Schema.IRecordset['onloaded'] {
                    const { loaders: { recordsets } } = props;
                    const recordset = recordsets?.[key];
                    const slot = LoaderSlot.Of(recordset, true);
                    if (!slot) return;

                    return slot.onloaded || (
                        slot.onloaded = new Map()
                    )
                }
            },
            loaded: {
                ...prop, get(): boolean {
                    const { loaders: { recordsets } } = props;
                    const recordset = recordsets?.[key];
                    const slot = LoaderSlot.Of(recordset, false);
                    return !!slot?.loaded;
                }
            },
            create: {
                ...propreadonly, value(this: Schema.IRecordset, val?: Schema.IRecord[], at?: number | undefined): Schema.IRecord | Schema.IRecord[] {
                    if (val == null) {
                        const record: Schema.IRecord = {
                            key: key, name: entity.name
                        }

                        this.unshift(record);
                        return record;
                    }

                    return (XArray.prototype).create.call(this, val, at);
                }
            },
            load: {
                ...propreadonly, value(this: Schema.IRecordset, param: any): Observable<false | Schema.IRecord[]> {
                    return _this.load(key, this, param);
                }
            }
        }));

        if (rcdset.autoload && !rcdset.loaded) {
            this.load(key, rcdset)
        }

        return rcdset;
    }
}

export namespace PuSchemaService {
    export const SCHEMA_URL: InjectionToken<string> = new InjectionToken('schemaurl');
    export enum ApiType {
        AsValueGetter = 0x0001
    }
}