<ngx-cmenu #govstage>
    <ng-template ngx-cmenu-item let-item passive="true">
        <gov-form class="overflow-hidden h-100" [readonly]="readonly" [datasets]="stageModule"></gov-form>
    </ng-template>
</ngx-cmenu>

<ngx-cmenu #govworkitem>
    <ng-template ngx-cmenu-item let-item passive="true">
        <gov-form class="overflow-hidden h-100" [readonly]="readonly" [datasets]="workitemModule"></gov-form>
    </ng-template>
</ngx-cmenu>

<div class="h-100 w-100" [gov-editor]="app.datasources.projecttypes" [toeditting]="toeditting"
    (onDiagramImported)="onDiagramImported($event)" (onDiagramDestroied)="onDiagramDestroied($event)"
    (onCurrentModule)="onCurrentModule($event)">
</div>