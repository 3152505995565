import { Component, EventEmitter, Input, Output } from '@angular/core';

import { GovDiagramDirective } from "../diagram.directive/diagram.directive";
import { PuTemplateService } from '../../puzzle/pu.template/pu.template';
import { AppService } from '../../../application/service/app.service';
import { Sys } from '../../../application/service/backface/types';
import { GovEditor } from '../model/form.editting';

type Imported = GovDiagramDirective.Imported;
type Destroied = GovDiagramDirective.Destroied;

@Component({
    selector: "gov-editor, [gov-editor]",
    templateUrl: "./gov.editor.component.html",
    styleUrls: ['./gov.editor.component.scss'],
    providers: [PuTemplateService],
    exportAs: 'GovEditor'
})
export class GovEditorComponent {
    private _props = Prop.Of(this);

    @Input('gov-editor')
    get _modules(): Sys.IDatasetModules {
        return this.modules;
    }

    set _modules(val: Sys.IDatasetModules | undefined) {
        this.modules = val;
    }

    @Input('modules')
    set modules(val: Sys.IDatasetModules | undefined) {
        const { modules } = this;

        modules.reset();
        val?.forEach(v => {
            if (!_.has(v, 'accessable') || v.accessable) {
                modules.push(v);
            }
        });
    }

    get modules(): Sys.IDatasetModules {
        const { _props: props, app } = this;

        return props.modules || (
            props.modules = app.createDatasetModules()
        )
    }

    @Input('toeditting')
    set toeditting(val: GovEditor.ToEditting | undefined) {
        this._props.toeditting = val;
    }

    get toeditting(): GovEditor.ToEditting | undefined {
        return this._props.toeditting;
    }

    @Output('onDiagramImported')
    get onDiagramImported(): EventEmitter<{
        entity: any
    } & Imported> {
        const { _props: props } = this;

        return props.onDiagramImported || (
            props.onDiagramImported = new EventEmitter()
        )
    }

    @Output('onDiagramDestroied')
    get onDiagramDestroied(): EventEmitter<{
        entity: any
    } & Destroied> {
        const { _props: props } = this;

        return props.onDiagramDestroied || (
            props.onDiagramDestroied = new EventEmitter()
        )
    }

    @Output('onCommandTemplate')
    get onCommandTemplate(): EventEmitter<GovEditor.CommandConfig> {
        const { _props: props } = this;

        return props.onCommandTemplate = props.onCommandTemplate || new EventEmitter();
    }

    @Output('onFilterTemplate')
    get onFilterTemplate(): EventEmitter<GovEditor.IFilterData> {
        const { _props: props } = this;

        return props.onFilterTemplate = props.onFilterTemplate || new EventEmitter();
    }

    onFilter(data: GovEditor.IFilterData) {
        this.onFilterTemplate.emit(data);
    }

    @Output('onCurrentModule')
    get onCurrentModule(): EventEmitter<Sys.IDatasetModule> {
        const { _props: props } = this;

        return props.onCurrentModule = props.onCurrentModule || new EventEmitter();
    }

    @Output('onWorkItemDetailsTemplate')
    get onWorkItemDetailsTemplate(): EventEmitter<GovEditor.IWorkItemSumData> {
        const { _props: props } = this;

        return props.onWorkItemDetailsTemplate = props.onWorkItemDetailsTemplate || new EventEmitter();
    }

    onWorkItemDetails(data: GovEditor.IWorkItemSumData) {
        this.onWorkItemDetailsTemplate.emit(data);
    }

    get currentmodule(): Sys.IDatasetModule {
        const { modules, _props: props } = this;
        const cur = modules?.[modules?.currentIndex || 0];

        if (props.currentmodule != cur) {
            props.currentmodule = cur;
            this.onCurrentModule.emit(cur);
        }

        return cur;
    }

    get editting(): GovEditor.IEditting | undefined {
        const { _props: props } = this;

        if (props.editting != GovEditor.Editting.get(this.currentmodule)) {
            Promise.resolve().then(() => {
                props.editting = GovEditor.Editting.get(
                    this.currentmodule
                )
            })
        }

        return props.editting;
    }

    get noform(): boolean {
        return !!this.currentmodule?.noform;// || !this.editting?.form;
    }

    constructor(
        public fieldtpls: PuTemplateService,
        public app: AppService
    ) {
    }
}
