<div class="d-flex flex-row align-items-stretch app-navbar navbar navbar-transparent p-1 m-0">
    <div class="my-auto flex-none">
        <ng-content select="left"></ng-content>
    </div>
    <div class="my-auto flex-fill">
        <ng-content select="center"></ng-content>
    </div>
    <div class="my-auto flex-none d-flex flex-row align-items-stretch">
        <!-- <div class="nav-item my-auto">
      <button mat-icon-button>
        <i class="material-icons">notifications</i>
        <span class="notification">5</span>
      </button>
    </div> -->
        <div class="nav-item dropdown my-auto">
            <button mat-icon-button [matMenuTriggerFor]="userMenu" matTooltip="{{ auth.me?.name }}">
                <i class="material-icons">person</i>
            </button>
            <mat-menu #userMenu="matMenu">
                <button mat-menu-item (click)="userInfo()">
                    <mat-icon>manage_accounts</mat-icon>
                    <span>{{ "account.setting" | translate }}</span>
                </button>
                <button mat-menu-item (click)="resetPassword()">
                    <mat-icon>vpn_key</mat-icon>
                    <span>{{ "account.pwdchange" | translate }}</span>
                </button>
                <button mat-menu-item (click)="auth.logout()">
                    <mat-icon>logout</mat-icon>
                    <span>{{ "account.logout" | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <div class="my-auto flex-none">
        <ng-content select="right"></ng-content>
    </div>
</div>