import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AuthService } from '../../application/service/auth.service';
import { AppService } from '../../application/service/app.service';

@Component({
    templateUrl: "./login.component.html",
    styleUrls: ['./login.component.scss'],
    host: {
        class: "authForm"
    }
})
export class LoginComponent implements OnInit, AfterViewInit {
    constructor(
        public auth: AuthService,
        public app: AppService
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }
}
