import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

import { GovFormComponent } from '../../utils/view/gov.form.component/gov.form.component';
import { AppService } from '../../application/service/app.service';
import { Exec } from '../../application/service/backface/exec';

@Component({
    templateUrl: "./files.component.html",
    styleUrls: ['./files.component.scss'],
    providers: [NgbCarouselConfig]
})
export class XFilesComponent implements OnInit {
    get files() {
        return this.data.files;
    }

    get current() {
        return this.data.current;
    }

    currentidx: number = 0;

    constructor(
        public config: NgbCarouselConfig,
        public app: AppService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            files: Exec.XFile[],
            current: Exec.XFile
        }
    ) {
        config.interval = 0;
        config.showNavigationIndicators = true;
        config.showNavigationArrows = true;
        config.pauseOnHover = true;
        config.pauseOnFocus = true;
        config.keyboard = true;
        config.wrap = true;

        this.currentidx = this.files.indexOf(this.current);
    }

    ngOnInit() {
    }

    BOf(val: any) {
        return GovFormComponent.BOf<{
            current?: string
        }>(val);
    }

    changeProgress(slide: string): void {
        if (!slide) return;
        var idx: string = slide.substring(slide.lastIndexOf("-") + 1);
        this.currentidx = Number(idx);
    }
}
