import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Exec as TExec, Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { GovEditorComponent } from '../../utils/view/gov.editor.component/gov.editor.component';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { PuSysService } from '../../utils/puzzle/pu.service/pu.sys.service';
import { Backface } from '../../../app/application/service/backface/config';
import { ResultsComponent } from '../../results/view/results.component';
import { AppService } from '../../application/service/app.service';
import { Report } from '../../application/service/backface/report';
import { GovEditor } from '../../utils/view/model/form.editting';
import { Exec } from '../../application/service/backface/exec';
import { Prj } from '../../application/service/backface/prj';
import { Editor } from '../../utils/libs/editor';
import { Differ } from '../../utils/libs/differ';

const Value = Editor.Value;

enum SlotState {
    none = 0,
    tosave = 1,
    saved = 2,
};

@Component({
    templateUrl: "./audit.component.html",
    styleUrls: ['./audit.component.scss'],
    providers: [PuTemplateService],
})
export class AuditComponent extends Backface.AsyncReport implements OnInit, OnDestroy {
    private _props = Prop.Of<AuditComponent, {
        differ?: Differ<{
            onProblemSaved: Differ.Event,
            onAuditSaved: Differ.Event,
            onResultPreSave: Differ.Event,
            onResultPostSave: Differ.Event,
        }>
        slot?: {
            editting?: GovEditor.IEditting,
            workitem?: Prj.ProjWorkitem,
            result?: Exec.Result,
            state?: SlotState,
        }
    }>(this);

    @ViewChild(GovEditorComponent, { static: false })
    get goveditor(): GovEditorComponent {
        return this._props.goveditor!;
    }

    set goveditor(val: GovEditorComponent) {
        this._props.goveditor = val;
    }

    get currentModule(): TSys.IDatasetModule {
        return this.goveditor?.currentmodule;
    }

    get component() {
        return ResultsComponent;
    }

    get execute(): TSys.IDatasetModule {
        const { _props: props } = this;
        return props.execute = props.execute || {
            ...this.app.datasources.projectexe,
            rows: []
        }
    }

    get audit(): TSys.IDatasetModule {
        const { _props: props } = this;
        return props.audit = props.audit || {
            ...this.app.datasources.executeaudit,
            rows: []
        }
    }

    get problem(): TSys.IDatasetModule {
        const { _props: props } = this;
        return props.problem = props.problem || {
            ...this.app.datasources.problem,
            rows: []
        }
    }

    get modules(): TSys.IDatasetModules {
        const { _props: props, app, app: { datasources: { workitemexesummary } }, execute, audit } = this;

        return props.modules || (
            props.modules = app.createDatasetModules(
                workitemexesummary,
                execute,
                audit
            )
        )
    }

    constructor(
        public app: AppService,
        public sys: PuSysService,
        protected override httpClient: HttpClient,
    ) {
        super(httpClient);
        this.editting = this.editting.bind(this);
    }

    ngOnInit(): void {
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        const { _props: { differ } } = this;
        differ?.clear();
    }

    onCurrentModule(module: TSys.IDatasetModule) {
        const { execute, audit, problem } = this

        switch (module) {
            case execute:
                return this.loadWorkitems();

            case audit:
                return this.loadAudits();

            case problem:
                return this.loadProblems();
        }
    }

    editting<T extends object>(obj: T, def?: ((obj: T) => GovEditor.IEditting)): GovEditor.IEditting | undefined {
        const { currentModule } = this;
        if (!currentModule || !obj) return {};

        const { _props: props } = this;
        const differ = (props.differ || (
            props.differ = Differ.create()
        ))!

        if (obj instanceof Exec.AuditResult) {
            differ.extend({
                onAuditSaved: Differ.Event.create(obj.$postsave_, () => {
                    this.loadAudits();
                })
            })

            return def?.(obj);
        }

        if (obj instanceof Exec.Problem) {
            differ.extend({
                onProblemSaved: Differ.Event.create(obj.$postsave_, () => {
                    this.loadProblems();
                })
            })

            return def?.(obj);
        }

        if (!(obj instanceof Prj.Workitem)) {
            console.assert(obj instanceof Prj.Workitem)
            return;
        };

        const slot = (props.slot || (
            props.slot = {}
        ))

        if (slot.workitem == obj && slot.editting && slot.result) {
            // already created
            return slot.editting;
        }

        if (slot.state != SlotState.saved) {
            // destroy previous result.
            slot.result?.$destroy_();
        }

        // create new one
        slot.workitem = obj;
        slot.state = SlotState.none;
        delete slot.editting;
        delete slot.result;

        const { app, app: { auth: { me } } } = this;
        const waudit = obj['audit'], wvalue = obj['value'];
        const RType = waudit ? Exec.AuditResult : Exec.Result;

        const resultinit = {
            id: waudit?.id, project: obj.project, stage: obj.stage, workset: obj.workset, workitem: obj,
            result: TSys.Result.none, reason: TSys.Reason.none, date: new Date(),
            people: me, value: wvalue, audit: waudit
        } as TExec.IAuditResult;

        const _result = this.initResult(resultinit);
        const result = (slot.result = (
            new RType(app, _result) as Exec.Result
        ));

        const { value } = result;
        value.$fields_.push({
            type: Value.Type.datenow,
            title: 'general.submittime',
            key: "$parent_.date",
            readonly: false,
        })

        // value of status should to:
        // 1. auditting: prompt and let callback to refine
        // 2. nopass: prompt and let refine
        // 3. pass: only lock

        const { dict, dict: { auditheaders } } = app;
        const { torefine: htorefine } = auditheaders;
        const { callback: hcallback } = auditheaders;
        const { comment: hcomment } = auditheaders;
        const { status: hstatus } = auditheaders;
        const { value: hvalue } = auditheaders;

        const headers = waudit ? [
            hstatus(dict, true), hcomment(dict, true), hcallback(dict, true),
            htorefine(dict, true), hvalue(dict, true)
        ] : [hvalue(dict, true)];

        const editting = (slot.editting = {
            source: obj, form: {
                headers: XArray.create('key', ...headers),
                editor: result,
            }
        });

        differ.extend({
            onResultPreSave: Differ.Event.create(result.$presave_, (handle: {
                handled?: boolean | Editor.Editor<any> | Observable<{
                    handled?: boolean | Editor.Editor<any>
                }>
            }) => {
                !waudit && (result.$forcreate = true);
                (slot.state == SlotState.none && (
                    slot.state = SlotState.tosave
                ));

                handle.handled = false;
                result.$pushSaving_();
            }),
            onResultPostSave: Differ.Event.create(result.$postsave_, (succeed: boolean) => {
                if (succeed && slot.state != SlotState.saved) {
                    slot.state = SlotState.saved;
                }

                result.$popSaving_();
                this.loadWorkitems();
            })
        });

        return editting;
    }

    loadWorkitems(): void {
        const { app, execute, _props: props } = this
        const rows = (execute.rows || (
            execute.rows = []
        ));

        this.report("/report/project_workitem_status", (res: Report.IProjectWorkitems) => {
            Report.buildWorkitems(app, res, rows);
            delete props.slot;
        }).query({
            workitemstatus: { type: ["unknown", "notstart", "ongoing", "delaying"] }
        });
    }

    loadAudits(): void {
        const { app, audit } = this
        const rows = (audit.rows || (
            audit.rows = []
        ));

        this.report("/entity/results/retrieve", (res: TExec.IAuditResult[] = []) => {
            Report.buildAuditResults(app, res, rows);
        }).query({
            audit: ["auditting", "callback", "nopass"],
            workitem: { type: ["normal", "verify"] },
        });
    }

    loadProblems(): void {
        const { app, problem } = this
        const rows = (problem.rows || (
            problem.rows = []
        ));

        this.report("/entity/problems/retrieve", (res: Report.IProblemEx[] = []) => {
            Report.buildProblems(app, res, (rows.reset(), rows));
        }).query({
            fixed: false
        });
    }

    initResult(result: TExec.IAuditResult): TExec.IAuditResult {
        return _.extend(result, {
            value: result.value || {}
        });
    }

    onFilter(data: GovEditor.IFilterData) {
    }
}