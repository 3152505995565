import { Directive, EventEmitter, Injectable, Injector, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { PuDialogComponent } from './pu.dialog.component';
import { PuDialog } from './pu.dialog';

@Injectable({ providedIn: 'root' })
export class PuDialogService {
    constructor(
        public dialog: MatDialog,
        public injector: Injector
    ) {
    }

    open<T>(config: PuDialog.Config, context?: T): MatDialogRef<T> {
        const injector = config.injector || this.injector;
        config = { ...config, injector: injector }

        return PuDialogComponent.open(this.dialog, config, context);
    }
}

@Directive({
    selector: '[puDialog]',
    exportAs: 'PuDialog'
})
export class PuDialogDirective {
    private _props = Prop.Of(this);

    @Input('puDialog')
    puDialog: {
        context?: Record<string | number, any>,
        config: PuDialog.Config,
    } = { config: {} };

    @Output('closing')
    onClosing: EventEmitter<{
        closable: boolean,
        result: any,
    }> = new EventEmitter();

    get dialogRef(): MatDialogRef<any, any> | undefined {
        return this._props.dialogRef
    }

    constructor(
        public dialog: PuDialogService
    ) {
    }

    open(context?: Record<string | number, any>) {
        if (this._props.dialogRef) return;

        const { dialog, _props: props, onClosing } = this;
        const { puDialog: { config = {}, context: _context } = {} } = this;

        if (_context) {
            context = Object.setPrototypeOf(context ?? {}, _context);
        }

        const dialogRef = (props.dialogRef = dialog.open(config, context));

        dialogRef.beforeClosed().subscribe({
            next(result: any) {
                result = {
                    closable: true,
                    result,
                }

                onClosing.emit(result);
                dialogRef.disableClose = result.closable;
            }
        })

        dialogRef.afterClosed().subscribe({
            complete() {
                delete props.dialogRef;
            }
        })

        return dialogRef;
    }
}
