import { Component, Input, OnInit } from "@angular/core";

import { AppService } from "../../../application/service/app.service";
import { Sys } from "../../../application/service/backface/types";

@Component({
    selector: "gov-wscard, [gov-wscard]",
    templateUrl: "./gov.wscard.component.html",
    styleUrls: ["./gov.wscard.component.scss"]
})
export class GovWSCardComponent implements OnInit {

    @Input('cardsum')
    cardsum?: Sys.ICardSumItem;

    constructor(
        public app: AppService
    ) {
    }

    ngOnInit(): void {
    }

}