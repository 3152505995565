import { InjectionToken } from '@angular/core';

export const NGX_AMAP_CONFIG: InjectionToken<NgxAMapConfig> = new InjectionToken('NGX_AMAP_CONFIG');

export interface NgxAMapConfig {
    apiKey?: string;
    apiVersion?: string;
    uiVersion?: string;
    protocol?: 'http' | 'https';
    debug?: boolean;
    debugTags?: string;
}