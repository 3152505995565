import { Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Optional, output, Output, SimpleChanges } from "@angular/core";
import { Observable, of, Subscription, zip } from "rxjs";

import { NgxAMapPlacesearchService } from "../../shared/inner/ngx-amap-placesearch.service";
import { NgxAMapCreaterService } from "../../shared/inner/ngx-amap.creater.service";
import { AMapEventBinderService } from "../../shared/amap-binder-event.service";
import { NgxAMapComponent } from "../ngx-amap.component/ngx-amap.component";
import { AMapLoggerService } from "../../shared/amap-logger.service";
import { ChangeFilter, getOptions } from "../../utils/options";

const TAG = "ngx-amap-placesearch";
const ALL_PLACESEARCH_OPTIONS: Extract<
    keyof NgxAMapPlacesearchComponent,
    keyof AMap.PlaceSearch.Options
>[] = [
        'city',
        'citylimit',
        'children',
        'type',
        'lang',
        'pageSize',
        'pageIndex',
        'extensions',
        'panel',
        'showCover',
        'renderStyle',
        'autoFitView'
    ];

const ALL_AUTOCOMPLETE_OPTIONS: Extract<
    keyof NgxAMapPlacesearchComponent,
    keyof AMap.Autocomplete.Options
>[] = [
        'type',
        'city',
        'datatype',
        'citylimit',
        'input',
        'output',
        'outPutDirAuto'
    ];

@Component({
    selector: 'ngx-amap-placesearch, [ngx-amap-placesearch]',
    templateUrl: "./ngx-amap-placesearch.component.html",
    providers: [NgxAMapPlacesearchService],
    exportAs: 'ngxAMapPlacesearch',
    host: {
        '[style.pointer-events]': "'all'"
    }
})
export class NgxAMapPlacesearchComponent implements OnChanges, OnDestroy {
    protected readonly _autocompletebinder = this.binder.bindEvent<AMap.Autocomplete.EventMap>();
    protected readonly _placesearchbinder = this.binder.bindEvent<AMap.PlaceSearch.EventMap>();
    private subscription?: Subscription;
    private inited: boolean = false;

    locations?: string | AMap.PlaceSearch.SearchResult;

    @Input('ngx-amap-placesearch')
    ngxAMap?: NgxAMapComponent;

    //#region options of PlaceSearch.Options
    /**
     * 兴趣点城市
     */
    @Input()
    city?: string;

    /**
     * 是否强制限制在设置的城市内搜索
     */
    @Input()
    citylimit: boolean = true;

    /**
     * 是否按照层级展示子POI数据
     * children=1，展示子节点POI数据，children=0，不展示子节点数据
     */
    @Input()
    children?: number;

    /**
     * 输入提示时限定POI类型，多个类型用“|”分隔
     */
    @Input()
    type?: string;

    /**
     * 检索语言类型
     */
    @Input()
    lang?: AMap.Lang;

    /**
     * 单页显示结果条数
     */
    @Input()
    pageSize: number = 4;

    /**
     * 页码
     */
    @Input()
    pageIndex: number = 1;

    /**
     * 是否返回详细信息
     * base返回基本地址信息；all返回基本+详细信息
     */
    @Input()
    extensions?: "base" | "all";

    /**
     * 结果列表的HTML容器id或容器元素
     */
    @Input()
    panel?: string | HTMLElement;

    /**
     * 是否在地图上显示周边搜索的圆或者范围搜索的多边形
     */
    @Input()
    showCover?: boolean;

    /**
     * 绘制的UI风格
     */
    @Input()
    renderStyle?: "newpc" | "default";

    /**
     * 是否自动调整地图视野使绘制的Marker点都处于视口的可见范围
     */
    @Input()
    autoFitView: boolean = true;
    //#endregion


    //#region options of AutoComplete.Options; Same in PlaceSearch: type、city、citylimit、lang
    /**
     * 返回的数据类型
     */
    @Input()
    datatype?: AMap.Autocomplete.DataType;

    /**
     * 指定输入框
     */
    @Input()
    input?: string | HTMLInputElement;

    /**
     * 指定输出面板
     */
    @Input()
    output?: string | HTMLDivElement;

    /**
     * 是否在input位于页面较下方的时候自动将输入面板显示在input上方以避免被遮挡
     */
    @Input()
    outPutDirAuto?: boolean;
    //#endregion

    @Input()
    poi?: AMap.Poi;

    readonly naReady = output<{
        autocomplete: AMap.Autocomplete,
        placesearch: AMap.PlaceSearch
    }>();

    readonly naPoi = output<AMap.Poi>({ alias: 'poiChange' });

    constructor(
        private readonly os: NgxAMapPlacesearchService,
        private readonly binder: AMapEventBinderService,
        private readonly logger: AMapLoggerService,
        private readonly el: ElementRef,
        private readonly ngZone: NgZone,

        @Optional()
        private readonly amaps?: NgxAMapCreaterService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const host = (this.ngxAMap || this.amaps);
        if (!host) return;

        const filter = ChangeFilter.from(changes);
        const apc = this.get();

        if (!this.inited) {
            host.get().subscribe(
                (amap) => {
                    this.logger.d(TAG, 'initializing ...');
                    const el = this.el.nativeElement as HTMLElement;

                    const placesearchoptions = getOptions<
                        NgxAMapPlacesearchComponent, AMap.PlaceSearch.Options
                    >(this, ALL_PLACESEARCH_OPTIONS);

                    const autocompleteoptions = getOptions<
                        NgxAMapPlacesearchComponent, AMap.Autocomplete.Options
                    >(this, ALL_AUTOCOMPLETE_OPTIONS)

                    placesearchoptions.map = amap;
                    placesearchoptions.panel = placesearchoptions.panel || (el.querySelector<HTMLElement>(
                        '.ngx-amap-placesearch-locations'
                    ) ?? undefined);

                    autocompleteoptions.input = autocompleteoptions.input || (el.querySelector<HTMLInputElement>(
                        'input.ngx-amap-placesearch-input'
                    ) ?? undefined);

                    this.logger.d(TAG, 'options:', placesearchoptions, autocompleteoptions);
                    this.os.create(placesearchoptions, autocompleteoptions).subscribe(
                        m => {
                            const { placesearch, autocomplete } = m;
                            this.ngZone.run(() => {
                                this.naReady.emit(m);
                            })

                            const clearlocations = () => {
                                this.ngZone.run(() => {
                                    delete this.locations;
                                })
                            }

                            this.subscription = this._autocompletebinder(of(autocomplete), 'complete').subscribe(
                                clearlocations
                            )

                            this.subscription.add(this._autocompletebinder(of(autocomplete), 'error').subscribe(
                                clearlocations
                            ))

                            this.subscription.add(this._autocompletebinder(of(autocomplete), "select").subscribe(
                                (event: { poi: AMap.Autocomplete.Tip }) => {
                                    this.os.placesearch?.placesearch.search(event.poi.name, (status, result) => {
                                        this.ngZone.run(() => {
                                            this.locations = result;
                                        })
                                    });
                                }
                            ));

                            this.subscription.add(this._placesearchbinder(of(placesearch), 'selectChanged').subscribe(
                                (selectChangeResult: AMap.PlaceSearch.EventMap['selectChanged']) => {
                                    this.ngZone.run(() => {
                                        const { data } = selectChangeResult.selected;
                                        const poi = Array.isArray(data) ? data[0] : data;
                                        this.naPoi.emit((this.poi = {
                                            location: poi.location ?? undefined,
                                            address: poi.address
                                        }));
                                    });
                                }
                            ));

                            this.logger.d(TAG, 'Placesearch is ready.');
                        }
                    )

                    this.inited = true;
                }
            )
        }

        zip(filter.has('type'), apc).subscribe(
            ([v, { placesearch: ps, autocomplete: ac }]) => {
                ps.setType(v);
                ac.setType(v);
            }
        )

        zip(filter.has('city'), apc).subscribe(
            ([v, { placesearch: ps, autocomplete: ac }]) => {
                ps.setCity(v);
                ac.setCity(v);
            }
        )

        zip(filter.has('citylimit'), apc).subscribe(
            ([v, { placesearch: ps, autocomplete: ac }]) => {
                ps.setCityLimit(v);
                ac.setCityLimit(v);
            }
        )

        zip(filter.has('pageSize'), apc).subscribe(
            ([v, { placesearch: ps, autocomplete: ac }]) => {
                ps.setPageSize(v);
            }
        )

        zip(filter.has('pageIndex'), apc).subscribe(
            ([v, { placesearch: ps, autocomplete: ac }]) => {
                ps.setPageIndex(v);
            }
        )
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.os.destroy();
    }

    test() {
        this.naPoi.emit({ ...(this.poi || {}) });
    }

    get() {
        return this.os.get();
    }
}