import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit, OnDestroy, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { Backface } from '../../../app/application/service/backface/config';
import { ResultsComponent } from '../../results/view/results.component';
import { Sys as TSys } from '../../application/service/backface/types';
import { Report } from '../../application/service/backface/report';
import { AppService } from '../../application/service/app.service';
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';

@Component({
    templateUrl: "./problemlist.component.html",
    styleUrls: ['./problemlist.component.scss'],
    providers: [PuTemplateService]
})
export class ProblemListComponent extends Backface.AsyncReport implements OnInit, OnDestroy, AfterViewInit {
    private _props = Prop.Of(this);

    get currentmodule(): TSys.IDatasetModule {
        const { _props: props, app: { dict: { exec: { problem, problem: { headers } } } } } = this;

        return props.currentmodule || (props.currentmodule = {
            headers: XArray.createIf('key', headers?.filter(h => h.key != "id")),
            dict: problem,
            rows: [],
            key: 'problemlist',
        });
    }

    get component() {
        return ResultsComponent;
    }

    constructor(
        public app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string,
            header: string,
            prjidlist: string[],
            date: string[],
            fixed: boolean,
            type: string[]
        },

        protected override httpClient: HttpClient,
    ) {
        super(httpClient);
    }

    ngOnInit() {
        this.loadProblems();
    }

    ngAfterViewInit(): void {
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    loadProblems(): void {
        const { data: { title, prjidlist, date, fixed, type } } = this;
        const param: {
            fixed?: boolean;
            project: string[];
            date: string[];
            type: string[];
        } = {
            project: prjidlist?.slice() || [],
            date: date?.slice() || [],
            type: type?.slice() || []
        };

        if (fixed != undefined) {
            param["fixed"] = fixed;
        }

        this.report("/entity/problems/retrieve", (res: Report.IProblemEx[] = []) => {
            const { app, currentmodule: { rows } } = this;
            Report.buildProblems(app, res, (rows!.reset(), rows));
        }).query(param);
    }

    static open(dialog: PuDialogService, data: {
        title?: string;
        header: string;
        date?: [start: number | string | Date, end: number | string | Date],
        type?: ('newfound' | 'notfix' | 'fixed')[],
        prjidlist?: (string | undefined)[],
        fixed?: boolean,
    }, injector: Injector): MatDialogRef<any, any> {
        return dialog.open({
            template: ProblemListComponent,
            injector: injector,
            maximized: true,
            resiable: true,

            autoFocus: true,
            hasBackdrop: true,
            disableClose: true,
            restoreFocus: true,
            maxHeight: '100vh',
            minHeight: '90vh',
            maxWidth: '100vw',
            minWidth: '80vw',
            height: '90vh',
            width: '80vw',
        }, {
            ...data
        })
    }
}
