import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxCMenuEventCancel, NgxCMenuEventClose, NgxCMenuEventCloseLeaf, NgxCMenuService, NgxCMenuEventExecute, NgxCMenuEventClick, NgxCMenuContext, NgxCMenuOverlayRef } from './shared/ngx-ctxmenu.service';
import { NgxCMenuComponent, NgxCMenuMouseLocation } from './view/ngx-ctxmenu.component/ngx-ctxmenu.component';
import { NgxCMenuContentComponent } from './view/ngx-ctxmenu-content.component/ngx-ctxmenu-content.component';
import { NGX_CMENU_CONFIG, NgxCMenuConfig, NgxCMenuLinkConfig } from './shared/ngx-ctxmenu.config';
import { NgxCMenuAttachDirective } from './view/ngx-ctxmenu-attach.directive';
import { NgxCMenuItemDirective } from './view/ngx-ctxmenu-item.directive';

const Views = [
    NgxCMenuAttachDirective,
    NgxCMenuContentComponent,
    NgxCMenuItemDirective,
    NgxCMenuComponent,
]

@NgModule({
    imports: [
        OverlayModule,
        CommonModule,
    ],
    declarations: [
        Views
    ],
    exports: [
        OverlayModule,
        CommonModule,
        Views
    ]
})
class NgxCMenuModule {
    static forRoot(options?: NgxCMenuConfig): ModuleWithProviders<NgxCMenuModule> {
        return {
            ngModule: NgxCMenuModule,
            providers: [
                NgxCMenuService,
                {
                    provide: NGX_CMENU_CONFIG,
                    useValue: options,
                },
                { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
            ],
        };
    }
}

export {
    NgxCMenuLinkConfig,
    NgxCMenuMouseLocation,
    NgxCMenuEventClick,
    NgxCMenuContext,
    NgxCMenuEventCloseLeaf,
    NgxCMenuOverlayRef,
    NgxCMenuEventCancel,
    NgxCMenuEventExecute,
    NgxCMenuEventClose,

    NgxCMenuModule,

    NgxCMenuConfig,
    NGX_CMENU_CONFIG,

    NgxCMenuService,

    NgxCMenuAttachDirective,
    NgxCMenuContentComponent,
    NgxCMenuItemDirective,
    NgxCMenuComponent,
}