import { Component, OnInit, Input, HostBinding, QueryList, ContentChildren, OnDestroy, Renderer2 } from '@angular/core';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';

import { PuSplitterPanelDirective } from './pu.splitter.panel.directive';
import { PuSplitter } from './pu.splitter.types';
import { Differ } from '../../libs/differ';

interface IDragging {
    sizePercentA: number,
    sizePercentB: number,
    sizePixelA: number,
    sizePixelB: number,
}

@Component({
    templateUrl: "./pu.splitter.component.html",
    styleUrls: ['./pu.splitter.component.scss'],
    selector: 'puSplitter, [puSplitter]',
    exportAs: 'PuSplitter'
}) export class PuSplitterComponent implements OnInit, OnDestroy {
    private _props = Prop.Of<PuSplitterComponent, {
        overlayContainer: HTMLElement,
        dragStartValues: IDragging,
        differ: Differ<{
            stopdragging: Differ.Event<MouseEvent | TouchEvent>,
            dragging: Differ.Event<MouseEvent | TouchEvent>
        }>,
    }>(this, (values) => {
        values.differ = Differ.create();
        values.dragStartValues = {
            sizePercentA: 0,
            sizePercentB: 0,
            sizePixelA: 0,
            sizePixelB: 0,
        };
    });

    @ContentChildren(PuSplitterPanelDirective, { read: PuSplitterPanelDirective, descendants: false })
    set panels(v: QueryList<PuSplitterPanelDirective> | PuSplitterPanelDirective[]) {
        const panels = (_.isArray(v) ? v : v?.toArray() || []);
        panels.forEach((panel, index: number) => {
            panel.order = 2 * index
        })

        this._props.panels = panels;
    }

    get panels(): PuSplitterPanelDirective[] {
        return this._props.panels || (
            this._props.panels = []
        )
    }

    @Input("direction")
    @HostBinding('class')
    get direction(): PuSplitter.direction {
        const { _props: { direction = 'vertical' } } = this;
        return direction === 'vertical' ? 'vertical' : 'horizontal'
    }
    set direction(v: PuSplitter.direction) {
        this._props.direction = v;
    }

    @Input('barsize')
    set splitterBarSize(v: number) {
        this._props.splitterBarSize = Math.min(20, Math.max(0, v = v ?? 8));
    }

    get splitterBarSize(): number {
        return this._props.splitterBarSize ?? 8;
    }

    @HostBinding('style.flex-direction')
    get cssFlexdirection() {
        return this.isvertical ? 'column' : 'row';
    }

    get dragging(): boolean {
        return this._props.dragging ?? false;
    };

    get isvertical(): boolean {
        return this.direction == 'vertical'
    }

    get barsSize(): number {
        const { panels, splitterBarSize } = this;
        if (!panels) return 0;

        return Math.max(0, _.sumBy(panels, (p) => (p.visible ? splitterBarSize : 0)) - splitterBarSize);
    }

    get panelsSize(): number {
        const { panels } = this;
        if (!panels) return 0;

        return Math.max(0, _.sumBy(panels, (p) => (p.visible ? p.size : 0)));
    }

    set dragging(val: boolean) {
        const container = this.overlayContainer.getContainerElement();
        val ? this.renderer.addClass(container, 'splitter_drag_container') :
            this.renderer.removeClass(container, 'splitter_drag_container');
    }

    constructor(
        private overlayContainer: FullscreenOverlayContainer,
        private renderer: Renderer2
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._props.differ.clear();
    }

    nextVisiblePanel(fromIndex: number): PuSplitterPanelDirective | undefined {
        for (const panels = this.panels; fromIndex < panels.length; fromIndex++) {
            if (panels[fromIndex]?.visible) return panels[fromIndex];
        }

        return;
    }

    startDragging(event: MouseEvent | TouchEvent, panelA: PuSplitterPanelDirective, panelB: PuSplitterPanelDirective): void {
        if (!panelA || !panelB || this.dragging) return;
        window.Event.stop(event);

        const { _props: { dragStartValues } } = this;
        dragStartValues.sizePercentA = panelA.size;
        dragStartValues.sizePercentB = panelB.size;
        dragStartValues.sizePixelA = panelA.sizepx;
        dragStartValues.sizePixelB = panelB.sizepx;

        const { _props: props, _props: { differ } } = this;
        const owndoc = (event.target as Element).ownerDocument;
        const docs = owndoc ? [owndoc, document] : [document];
        const { position } = event, start = DOMPoint.fromPoint({
            x: position?.screenX, y: position?.screenY
        });

        differ.extend({
            stopdragging: Differ.Event.create(
                Differ.Event.fromEvent<MouseEvent | TouchEvent>(docs, 'mouseup touchend touchcancel dragend', { capture: true, passive: true }),
                () => {
                    props.dragging = false;
                    this.dragging = false;
                    differ.clear();
                }
            ),
            dragging: Differ.Event.create(
                Differ.Event.fromEvent<MouseEvent | TouchEvent>(docs, 'mousemove touchmove drag', { capture: true, passive: true }),
                (e: MouseEvent | TouchEvent) => {
                    const { position: epos } = e, estart = DOMPoint.fromPoint({
                        x: epos?.screenX, y: epos?.screenY
                    });

                    this.drag(start, estart, panelA, panelB);
                    props.dragging = true;
                    this.dragging = true;
                }
            )
        })
    }

    private drag(start: DOMPoint, end: DOMPoint, panelA: PuSplitterPanelDirective, panelB: PuSplitterPanelDirective): void {
        const { isvertical, _props: { dragStartValues: { sizePixelA, sizePixelB, sizePercentA, sizePercentB } } } = this;
        const offsetPixel = isvertical ? (start.y - end.y) : (start.x - end.x);
        const newSizePixelA = sizePixelA - offsetPixel;
        const newSizePixelB = sizePixelB + offsetPixel;

        if (sizePercentA === 0) {
            panelB.size = sizePercentB / sizePixelB * newSizePixelB;
            panelA.size = sizePercentB - panelB.size;
        }
        else if (sizePercentB === 0) {
            panelA.size = sizePercentA / sizePixelA * newSizePixelA;
            panelB.size = sizePercentA - panelA.size;
        }
        else {
            panelA.size = sizePercentA / sizePixelA * newSizePixelA;
            panelB.size = (sizePercentA + sizePercentB) - panelA.size;

            if (panelA.size >= panelA.max) {
                panelA.size = panelA.max;
                panelB.size = (sizePercentA + sizePercentB) - panelA.size;
            } else if (panelA.size <= panelA.min) {
                panelA.size = panelA.min;
                panelB.size = (sizePercentA + sizePercentB) - panelA.size;
            } else {
                panelB.size = (sizePercentA + sizePercentB) - panelA.size;
            }

            if (panelB.size >= panelB.max) {
                panelB.size = panelB.max;
                panelA.size = (sizePercentA + sizePercentB) - panelB.size;
            } else if (panelB.size <= panelB.min) {
                panelB.size = panelB.min;
                panelA.size = (sizePercentA + sizePercentB) - panelB.size;
            }
        }
    }
}