<!-- 
block:
 1. can be block of dashboard
 2. can be {board:card, class?: string} as card of cards
    a. the class is the class definition of card, and
       the card renderer should not use it again
 -->
<ng-template #Block let-template="template" let-block="block">
    @if (template) {
    @let multiboard = _.isArray(block.board);

    <div class="position-relative p-1 m-0 col {{ block.class ?? 'h-auto col-12 col-sm-6' }}"
        [ngClass]="{'dashboard-block-layout': multiboard}">

        @let context = {setting: block.board};
        <ng-container *puTemplateOutlet="template; context: context"></ng-container>
    </div>
    }
</ng-template>

<!-- setting is the card definition -->
<ng-template #Card let-setting="setting">
    @let _Card = setting;

    <!-- setting is card title definition, maybe undefined -->
    <ng-template #Header let-setting="setting">
        @let template = tpls.get(setting?.template);
        @if (template) {

        <!-- special template to render this card -->
        @let context = {setting};
        <ng-container *puTemplateOutlet="template; context: context"></ng-container>

        } @else if(setting) {

        @let icon = setting.icon;
        @let title = setting.title;
        @let value = setting.value;
        @let status = setting.status;
        @let action = setting.$$action;
        @let cond = setting.cond ?? true;
        @let sep = (title != null && value != null) ? ": " : '';
        <div class="card-header card-header-{{status || 'info'}} {{setting.class ?? ''}} p-2" *ngIf="cond">
            <div class="ct-chart">
                <h4 class="card-title color-white">
                    <a class="btn btn-link p-0 {{ setting.class ?? '' }}" (click)="onCardClick(action, _Card)"
                        *ngIf="action">
                        <i class="material-icons" *ngIf="icon">{{ icon }}</i>
                        {{ (title ?? '') | translate }}{{ sep }}{{ value }}
                    </a>

                    <a class="btn btn-light p-0 border-0 bg-transparent {{ setting.class ?? '' }}" *ngIf="!action"
                        disabled>
                        <i class="material-icons" *ngIf="icon">{{ icon }}</i>
                        {{ (title ?? '') | translate }}{{ sep }}{{ value }}
                    </a>
                </h4>
            </div>
        </div>

        }
    </ng-template>

    <!-- setting is card item definition of card body, maybe undefined -->
    <ng-template #Body let-setting="setting">
        @let template = tpls.get(setting?.template);
        @if (template) {

        <!-- special template to render this card -->
        @let context = {setting};
        <ng-container *puTemplateOutlet="template; context: context"></ng-container>

        } @else if (setting) {

        @let icon = setting.icon;
        @let title = setting.title;
        @let value = setting.value;
        @let action = setting.$$action;
        @let cond = setting.cond ?? true;
        @let sep = (title != null && value != null) ? ": " : '';
        <div class="card-title m-1 {{ setting.class ?? '' }}" *ngIf="cond">
            <button class="text-start btn btn-sm btn-link p-1 {{ setting.class ?? '' }}"
                (click)="onCardClick(action, _Card)" *ngIf="action">
                <i class="material-icons" *ngIf="icon">{{ icon }}</i>
                {{ (title ?? '') | translate }}{{ sep }}{{ value }}
            </button>

            <button class="text-start btn btn-sm btn-light p-1 border-0 bg-transparent {{ setting.class ?? '' }}"
                *ngIf="!action" disabled>
                <i class="material-icons" *ngIf="icon">{{ icon }}</i>
                {{ (title ?? '') | translate }}{{ sep }}{{ value }}
            </button>
        </div>
        }
    </ng-template>

    <!-- setting is card footer definition of card, maybe undefined -->
    <ng-template #Footer let-setting="setting">
        @let template = tpls.get(setting?.template);
        @if (template) {
        <!-- special template to render this card -->
        @let context = {setting: setting};
        <ng-container *puTemplateOutlet="template; context: context"></ng-container>
        } @else if(setting) {
        @let icon = setting.icon;
        @let title = setting.title;
        @let value = setting.value;
        @let action = setting.$$action;
        @let cond = setting.cond ?? true;
        @let sep = (title != null && value != null) ? ": " : '';
        <div class="card-footer {{ setting.class ?? '' }}" *ngIf="cond">
            <div class="stats {{ action ? ' btn-link' : '' }} {{ setting.class ?? '' }}"
                (click)="onCardClick(action, _Card)">
                <i class="material-icons" *ngIf="icon">{{ icon }}</i>
                {{ (title ?? '') | translate}}{{ sep }}{{ value }}
            </div>
        </div>
        }
    </ng-template>

    @let template = tpls.get(setting.template);
    @if (template) {
    <!-- special template to render this card -->
    @let context = {setting};
    <ng-container *puTemplateOutlet="template; context: context"></ng-container>
    } @else {
    <div class="h-100 p-0 pt-3 d-flex flex-column flex-nowrap align-items-stretch">
        <div class="card card-chart flex-grow-1 p-0 m-0 d-flex flex-column flex-nowrap align-items-stretch">
            <!-- render header -->
            @let header = setting.header;
            @let headerCtx = {setting: (_.isString(header) ? {title: header, status: 'info'} : header)};
            <ng-container *ngTemplateOutlet="Header; context: headerCtx"></ng-container>

            <div class="card-body flex-grow-1 p-2 d-flex flex-column flex-nowrap align-items-stretch">
                @for (body of setting.body; track $index) {
                <!-- render body -->
                @let bodyCtx = {setting: (_.isString(body) ? {title: body} : body)};
                <ng-container *ngTemplateOutlet="Body; context: bodyCtx"></ng-container>
                }
            </div>

            <!-- render body -->
            @let footer = setting.footer;
            @let footerCtx = {setting: (_.isString(footer) ? {title: footer} : footer)};
            <ng-container *ngTemplateOutlet="Footer; context: footerCtx"></ng-container>
        </div>
    </div>
    }
</ng-template>

<!-- setting is the board definition of cards, that is the array of card -->
<ng-template id="cards" let-setting="setting">
    <ng-container *ngFor="let card of setting">
        @let template = tpls.get(card.template) || Card;
        @let block = {board: card, class: card.class};
        @let context = {template, block};

        <ng-container *puTemplateOutlet="Block; context: context">
        </ng-container>
    </ng-container>
</ng-template>

<!-- setting is the board definition of chart, that is the option/merge/etc of chart -->
<ng-template id="chart" let-setting="setting">
    <div class="p-1 m-0 h-100 w-100 card card-chart card-full {{ setting.class }}">
        <div echarts class="h-100 w-100 d-inline-flex" [initOpts]="setting.initOpts" [merge]="setting.merge"
            [options]="setting.option" [autoResize]="true" (chartClick)="onClickChart($event, setting)">
        </div>
    </div>
</ng-template>

<!-- setting is the board definition of table, that is the header/entity/value/etc of table -->
<ng-template id="table" let-setting="setting">
    <div class="px-1 pt-3 pb-1 m-0 h-100">
        <div class="p-1 m-0 h-100 card card-chart card-full">
            <div [puEditor]="{es, value: null, headers:setting.headers}" [autosave]="false"
                [viewmode]="setting.viewmode" [searchable]="setting.searchable" [noheader]="setting.noheader"
                [nopanel]="setting.nopanel" [removable]="false" [readonly]="true" [managable]="false"
                class="h-100 w-100"
                [exporter]="{file: ('navigation.supervise' | translate) +' - ' + ('supervise.loadhours' | translate), sheet: 'supervise.loadhours'}">
            </div>
        </div>
    </div>
</ng-template>

<div class="p-0 m-0 dashboard-block-layout w-100 h-100 overflow-auto">
    @for (block of dashboard; track $index) {

    @let template = tpls.get(block.template) || tpls.get(block.type);
    @let context = {template, block};

    <ng-container *puTemplateOutlet="Block; context: context">
    </ng-container>
    }
</div>

<!--button class="btn btn-success position-absolute top-0 end-0 mt-1 me-1" style="z-index: 1030;"
      (click)="exportpdf()">保存</!-button-->

<ng-template id="reporter_dialog" let-title="title" let-uri="uri">
    <div class="w-100 h-100 m-0 border-0 p-0 pb-2 overflow-hidden">
        <iframe class="w-100 h-100 m-0 border-0 p-0 overflow-hidden" [src]="uri | safe: 'res'"></iframe>
    </div>
</ng-template>