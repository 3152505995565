import { map, Observable, of, ReplaySubject, zip } from "rxjs";
import { Injectable, NgZone } from "@angular/core";
import { O } from "ts-toolbelt";

import { NgxAMapCreaterService } from "./ngx-amap.creater.service";
import { AMapUILoaderService } from "../amap-loader-ui.service";
import { AMapLoggerService } from "../amap-logger.service";

const TAG = 'AMapPositionPickerCreater'

@Injectable()
export class NgxAMapPositionPickerService {

    private readonly positionpicker$ = new ReplaySubject<AMap.PositionPicker>(1);
    readonly positionpicker?: AMap.PositionPicker;

    constructor(
        private readonly creater: NgxAMapCreaterService,
        private readonly plugin: AMapUILoaderService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    get(): Observable<AMap.PositionPicker> {
        return this.positionpicker$;
    }

    create(options: AMap.PositionPicker.Options): Observable<AMap.PositionPicker> {
        const { creater, plugin, logger, ngZone } = this;
        const { map: amap } = options;

        return zip(amap ? of(amap) : creater.get(), plugin.load(['misc/PositionPicker'])).pipe(
            map(
                ([amap, PositionPicker]: [AMap.Map, typeof AMap.PositionPicker]) => {
                    const positionpicker = (
                        (this as O.Writable<NgxAMapPositionPickerService>).positionpicker = ngZone.runOutsideAngular(
                            () => new PositionPicker({
                                ...options,
                                map: amap
                            })
                        )
                    );

                    logger.d(TAG, 'new positionpicker created.');
                    this.positionpicker$.next(positionpicker);
                    this.positionpicker$.complete();
                    return positionpicker;
                }
            )
        );
    }

    destroy(): void {
        const { logger } = this;
        zip(this.get()).subscribe(
            ([m]) => {
                logger.d(TAG, 'positionpicker destroyed.');
                delete (this as O.Writable<NgxAMapPositionPickerService>).positionpicker;
            }
        );
    }
}