<ng-template id="bool" let-headers="headers" let-header="header" let-record="record" let-field="field">
    <div class="toggle toggle--knob" puEventStop="click" [stopPropagation]="false">
        <input type="checkbox" class="toggle--checkbox" id="field-form-toggle--{{ field.$cid }}"
            [(ngModel)]="field.field.value" [readonly]="header.readonly ?? field.schema?.readonly ?? true"
            (change)="(header.onchange || field.schema?.onchange)?.(record, field, field.fieldname)" />

        <label class="ms-0 toggle--btn" for="field-form-toggle--{{ field.$cid }}">
            <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
                [attr.data-label-on]="'general.yes' | translate">
            </span>
        </label>
    </div>
</ng-template>

<ng-template id="text, texts, email, phone, json" let-headers="headers" let-header="header" let-record="record"
    let-field="field">
    {{field.value || '&nbsp;'}}
</ng-template>

<ng-template id="label" let-headers="headers" let-header="header" let-record="record" let-field="field">
    {{(field.value || '&nbsp;') | translate}}
</ng-template>

<ng-template id="number" let-headers="headers" let-header="header" let-record="record" let-field="field">
    {{field.value ?? '&nbsp;'}}
</ng-template>

<ng-template id="date" let-headers="headers" let-header="header" let-record="record" let-field="field">
    {{field.value | date}}
</ng-template>

<ng-template id="option" let-headers="headers" let-header="header" let-record="record" let-field="field">
    {{ field.value || '&nbsp;' }}
</ng-template>

<ng-template id="options" let-headers="headers" let-header="header" let-record="record" let-field="field">
    {{ field.value || '&nbsp;' }}
</ng-template>

<ng-template id="select" let-headers="headers" let-header="header" let-record="record" let-field="field">
    {{ contentOf(header, record) || '&nbsp;' }}
</ng-template>

<ng-template #searcherpager let-expant="expant" let-matexporter="matexporter">
    <ng-template #createnew>
        <button #eventGuard="PuEventGuard" type="button" *ngIf="!readonly && removable"
            class="m-0 p-0 mt-1 none-border-left-radius btn btn-sm btn-secondary position-relative"
            style="height: 30px; width: 38px;" (click)="eventGuard.fireIf('record', createRecord(recordset, entity))"
            (puEventGuard)="_.isEqual($event.key, 'record') && _.noop(paginator.pageIndex = 0, expand.open(expant, onRowSelect($event.val)))">
            <i class="material-icons">add</i>
        </button>
    </ng-template>

    <div class="w-100 m-0 py-0 px-2 d-flex flex-row flex-nowrap justify-content-center" *ngIf="searchable">
        <input matInput [(ngModel)]="searcher" placeholder="{{ 'general.searchhint' | translate }}"
            class="flex-grow-1 y-auto mt-1 me-0 pe-4 text-end" />
        <ng-container *ngIf="!readonly && removable" [ngTemplateOutlet]="createnew"></ng-container>
    </div>

    <div class="w-100 m-0 pt-0 pb-1 px-2 d-flex flex-row flex-nowrap justify-content-center">
        <mat-paginator class="flex-grow-1 y-auto mt-1" [ngClass]="{ 'd-none': defaultpaginator !== paginator }"
            [pageSize]="pageSize[pageSize.current]" [pageSizeOptions]="pageSize" showFirstLastButtons>
        </mat-paginator>

        <div class="btn-group me-3 mt-1">
            @if (!isMobile) {
            @let fileName = translate.instant(exporter.file || 'general.export');
            @let sheet = translate.instant(exporter.sheet || 'general.exportrecord');
            <button type="button" class="btn btn-outline-primary p-1"
                (click)="exportTable(matexporter, {sheet, fileName, columnWidths: []})">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" height="24px" width="24px"
                    id="excel-file">
                    <path fill="#007732"
                        d="M80.016 96h-8.297L63.75 83.039 55.781 96H48l11.367-17.672-10.64-16.594h8.016l7.383 12.328 7.242-12.328h7.828L68.438 78.727 80.016 96zM104 80c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm10.882 16.988-.113.176-8.232 11.438c-.548.866-1.508 1.398-2.537 1.398s-1.989-.532-2.536-1.397l-8.346-11.614a3.01 3.01 0 0 1 .01-2.994 3.01 3.01 0 0 1 2.596-1.494H100V86c0-1.654 1.346-3 3-3h2c1.654 0 3 1.346 3 3v6.5h4.276c1.065 0 2.061.572 2.596 1.494a3.01 3.01 0 0 1 .01 2.994z">
                    </path>
                    <path fill="#ff9a30" d="m84 125.95-.05.05H84zM114 77v-.05l-.05.05z"></path>
                    <path fill="#007732"
                        d="M111.071 44.243 71.757 4.929A9.936 9.936 0 0 0 64.687 2H24c-5.514 0-10 4.486-10 10v104c0 5.514 4.486 10 10 10h59.95l-4-4H24c-3.309 0-6-2.691-6-6V12c0-3.309 2.691-6 6-6h40.687c1.603 0 3.109.624 4.242 1.757l39.314 39.314A6.044 6.044 0 0 1 110 51.313V72.95l4 4V51.313c0-2.67-1.04-5.181-2.929-7.07z">
                    </path>
                    <path fill="#fff" d="m113.95 77 .05-.05-4-4"></path>
                </svg>

            </button>

            <button type="button" class="btn btn-outline-primary p-1" (click)="
                viewMode = (viewMode == 'card' ? 'table' : 'card')
              ">
                @switch (viewMode) {
                @case ('table') {
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" height="24px" width="24px"
                    fill="#5f6368">
                    <path
                        d="M240-500v-220h220v220H240Zm0 260v-220h220v220H240Zm260-260v-220h220v220H500Zm0 260v-220h220v220H500ZM320-580h60v-60h-60v60Zm260 0h60v-60h-60v60ZM320-320h60v-60h-60v60Zm260 0h60v-60h-60v60ZM380-580Zm200 0Zm0 200Zm-200 0ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Z" />
                </svg>
                }

                @case ('card') {
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" height="24px" width="24px"
                    fill="#5f6368">
                    <path
                        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h133v-133H200v133Zm213 0h134v-133H413v133Zm214 0h133v-133H627v133ZM200-413h133v-134H200v134Zm213 0h134v-134H413v134Zm214 0h133v-134H627v134ZM200-627h133v-133H200v133Zm213 0h134v-133H413v133Zm214 0h133v-133H627v133Z" />
                </svg>
                }
                }
            </button>
            }
            <ng-container *ngIf="!searchable && !readonly && removable" [ngTemplateOutlet]="createnew"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #editorform let-schema="schema" let-record="record">
    <div class="w-100 m-0 p-0 flex-grow-1 overflow-auto" #form="PuForm" [puForm]="{schema, record}"
        [managable]="managable" [readonly]="readonly"></div>

    <div *ngIf="!readonly && !autosave" class="text-center border-2 border-top w-100 px-0 py-2">
        <button class="me-2" color="primary" mat-flat-button (click)="save(record)"
            [disabled]="!form.formGroup?.$isvalid || !form.formGroup?.dirty">
            {{ "general.save" | translate }}
        </button>
        <button color="primary" mat-flat-button (click)="cancel(record)" [disabled]="!form.formGroup?.dirty">
            {{ "general.cancel" | translate }}
        </button>
    </div>
</ng-template>

<ng-template #tableview let-expandable="expandable" let-headers="headers">
    <div #expant class="overflow-hidden h-100 w-100 m-0 p-0 d-flex flex-column flex-nowrap align-items-stretch">
        <div (puInit)="matDataSource.filterPredicate = filterPredicate(headers)"
            class="overflow-auto flex-grow-1 w-100 h-100">
            <table matTableExporter #matexporter="matTableExporter" [exporter]="puExporter" [dataSource]="matDataSource"
                matSort mat-table recycleRows class="min-w-100 tableview" multiTemplateDataRows="expandable">
                @for (header of headers; track $index) {
                @let sticky_end = header.sticky == 'end';
                @let sticky = !!(header.action || header.sticky);
                <ng-container [matColumnDef]="header.colkey" [sticky]="sticky && !sticky_end" [stickyEnd]="sticky_end">
                    <!-- Action Column -->
                    @if (!!header.action) {
                    <!-- action Header cell -->
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-nowrap w-10">
                        {{ header.name | translate }}
                    </th>

                    <!-- action content cell -->
                    <td mat-cell *matCellDef="let row" class="text-nowrap">
                        @let template = templateOf(header, 'table');

                        @if (template) {
                        @let context={record: recordOf(row), editor: this, headers, header };
                        <ng-container *puTemplateOutlet="template; context: context"></ng-container>
                        } @else {
                        <label *ngIf="!readonly && (isRemovable(row) || removable)" style="line-height: 1"
                            (click)="remove(row, recordset)" puEventStop="click" [stopDefault]="false"
                            class="btn btn-sm btn-secondary mx-1 p-0 h-90">
                            <i class="material-icons">close</i>
                        </label>
                        }
                    </td>
                    }

                    @if (!!!header.action) {
                    <!-- Normal Header cell -->
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [class.w-0]="sticky">
                        {{ (header.name || header.fielder?.schema?.name || '') | translate }}
                    </th>

                    <!-- normal content cell -->
                    <td mat-cell *matCellDef="let row" class="max-vw-50 text-truncate">
                        @let field = header.fielder?.(recordOf(row));
                        @let _template = templateOf(header, 'table') ?? field?.type;
                        @let template = _template || header.fielder?.type || header.type;
                        @let context = {field, headers, header, record: recordOf(row), editor: this};
                        <ng-container *puTemplateOutlet="template; context: context"></ng-container>
                    </td>
                    }
                </ng-container>
                }

                <!-- Table header details. -->
                <tr mat-header-row *matHeaderRowDef="headers?.ids; sticky: true"></tr>

                <!-- Normal Row Content -->
                <tr mat-row *matRowDef="let row; columns: headers?.ids"
                    [ngClass]="{ selected: editting.source == recordOf(row) }"
                    [style.background-color]="isValid(row) ? '' : '#ff0000 !important'" puEventStop="click"
                    [stopDefault]="false" #eventGuard="PuEventGuard"
                    (click)="eventGuard.fireIf('record', tryComplete())"
                    (puEventGuard)="_.isEqual($event.key, 'record') && _.noop(expand.toggle(expant, row), onRowSelect(row))">
                </tr>

                <!-- Expanded Row Content - The detail row is made up of this one column that spans across all columns -->
                @if (expandable) {
                <ng-container [matColumnDef]="expandColumn">
                    <ng-container *matCellDef="let row">
                        @if (expand.get(expant, row)) {
                        <td mat-cell [attr.colspan]="headers?.ids.length" class="p-0 m-0"
                            style="background-color: #dcdcdc;">
                            <div class="max-vw-100 w-100 px-2 pt-1 pb-0 m-0">
                                @let context = {schema: entity, record: recordOf(row)};
                                <ng-container *ngTemplateOutlet="editorform; context: context"></ng-container>
                            </div>
                        </td>
                        }
                    </ng-container>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: [expandColumn]"></tr>
                }

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="headers?.ids.length">
                        {{ "general.searchnone" | translate }}: "{{ searcher }}"
                    </td>
                </tr>
            </table>
        </div>

        @if (!nopanel) {
        <div class="border-2 border-top w-100 m-0 p-0">
            @let context = { matexporter: matexporter, expant };
            <ng-container *ngTemplateOutlet="searcherpager; context:context"></ng-container>
        </div>
        }
    </div>
</ng-template>

<ng-template #cardView let-expandable="expandable" let-headers="headers">
    <div #expant class="overflow-hidden h-100 w-100 m-0 p-0 d-flex flex-column flex-nowrap align-items-stretch">
        <div (puInit)="matDataSource.filterPredicate = filterPredicate(headers)"
            class="overflow-auto flex-grow-1 w-100 h-100">
            <table matTableExporter #matexporter="matTableExporter" [exporter]="puExporter" [dataSource]="matDataSource"
                matSort mat-table recycleRows class="max-w-100 w-100 px-2 cardview" [class.noheader]="noheader">
                <ng-container [matColumnDef]="header.colkey" *ngFor="let header of headers">
                    <!-- Action Column -->
                    @if (!!header.action) {
                    <ng-container *matHeaderCellDef>
                        <!-- Nothing for action header -->
                    </ng-container>
                    <div *matCellDef="let row" class="text-nowrap position-absolute end-0 top-0">
                        @let template = templateOf(header, 'card');
                        @if (template) {
                        @let context = {headers, header, record: recordOf(row), editor: this};
                        <ng-container *puTemplateOutlet="template; context: context"></ng-container>
                        } @else if (!readonly && (isRemovable(row) || removable)) {
                        <label class="btn btn-sm btn-secondary m-1 p-0 h-90" style="line-height: 1" puEventStop="click"
                            [stopDefault]="false" (click)="remove(row, recordset)">
                            <i class="material-icons">close</i>
                        </label>
                        }
                    </div>
                    }

                    @if (!!!header.action) {
                    <ng-container *matHeaderCellDef>
                        <div mat-header-cell *ngIf="!noheader" mat-sort-header>
                            {{ (header.name || header.fielder?.schema?.name || '') | translate }}
                        </div>
                    </ng-container>

                    <!-- normal content cell -->
                    <div class="m-0 px-0 py-1 d-flex flex-row flex-nowrap align-items-center justify-content-start {{ header.class ?? 'col-12 col-sm-6' }}"
                        *matCellDef="let row">
                        <strong class="flex-grow-0 pe-1 text-nowrap align-self-center">
                            {{ (header.name || header.fielder?.schema?.name || '') | translate }}:
                        </strong>
                        <div class="flex-grow-1 text-wrap text-break align-self-center m-0 py-0 px-1">
                            @let field = header.fielder?.(recordOf(row));
                            @let _template = templateOf(header, 'card') ?? field?.type;
                            @let template = _template || header.fielder?.type || header.type;
                            @let context = {field, headers, header, record: recordOf(row), editor: this};
                            <ng-container *puTemplateOutlet="template; context: context"></ng-container>
                        </div>
                    </div>
                    }
                </ng-container>

                <!-- Expanded Row Content - The detail row is made up of this one column that spans across all columns -->
                @if (expandable) {
                <ng-container [matColumnDef]="expandColumn">
                    <ng-container *matHeaderCellDef>
                        <!-- Nothing for action header -->
                    </ng-container>
                    <ng-container *matCellDef="let row">
                        @if (expand.get(expant, row)) {
                        <mat-divider [inset]="true" class="w-100 mx-0 my-2"></mat-divider>
                        <div class="w-100 p-1 m-0 mat-drawer-container" puEventStop="click" [stopDefault]="false">
                            @let context = {schema: entity, record: recordOf(row)};
                            <ng-container *ngTemplateOutlet="editorform; context: context"></ng-container>
                        </div>
                        }
                    </ng-container>
                </ng-container>
                }

                <!-- Table header details. -->
                <tr mat-header-row *matHeaderRowDef="expandable ? headers?.idswithexpand : headers?.ids; sticky: true">
                </tr>

                <!-- Normal Row Content -->
                <tr mat-row *matRowDef="let row; columns: expandable ? headers?.idswithexpand : headers?.ids"
                    class="card position-relative mt-1 mb-2 p-2 w-100 d-flex flex-row flex-wrap justify-content-between align-items-stretch"
                    [style.background-color]="isValid(row) ? '' : '#ff0000 !important'"
                    style="border: 1px solid #198754;" puEventStop="click" [stopDefault]="false"
                    [ngClass]="{ selected: editting.source == recordOf(row) }" #eventGuard="PuEventGuard"
                    (puEventGuard)="_.isEqual($event.key, 'record') && noop(expand.toggle(expant, row), onRowSelect(row))"
                    (click)="eventGuard.fireIf('record', tryComplete())">
                </tr>

                <!-- Row shown when there is no matching data. -->
                <tr mat-row class="mat-row" *matNoDataRow>
                    {{ "general.searchnone" | translate }}: "{{ searcher }}"
                </tr>
            </table>
        </div>

        @if (!nopanel) {
        <div class="border-2 border-top w-100 m-0 p-0">
            @let context = {matexporter: matexporter, expant: expant};
            <ng-container *ngTemplateOutlet="searcherpager; context: context"></ng-container>
        </div>
        }
    </div>
</ng-template>

<ng-template #viewformsplitter>
    <puSplitter class="overflow-hidden min-h-5 h-100 w-100" [direction]="'horizontal'">
        <div class="overflow-hidden m-0 px-0 pt-0 pb-0 d-flex flex-column flex-nowrap align-items-stretch"
            puSplitterPanel [size]="70">
            <ng-container [ngTemplateOutlet]="viewMode == 'card' ? cardView : tableview"
                [ngTemplateOutletContext]="{headers, expandable: false}">
            </ng-container>
        </div>
        <div puSplitterPanel class="overflow-hidden" [size]="30" [max]="80" [min]="20">
            <div class="overflow-hidden w-100 h-100 p-1 m-0 d-flex flex-column align-items-stretch">
                <ng-container [ngTemplateOutletContext]="{schema: entity, record: editting.source}"
                    [ngTemplateOutlet]="editorform">
                </ng-container>
            </div>
        </div>
    </puSplitter>
</ng-template>

@if (true) {
@let template = (isMobile || noform) ? (viewMode == 'card' ? cardView : tableview) : viewformsplitter;
@let _headers = (isMobile ? headers?.mobile : headers);
@let expandable = (isMobile ? !noform : false);
@let context = {headers: _headers, expandable};
<ng-container *ngTemplateOutlet="template; context: context"></ng-container>
}