import { Observable, Subscriber, switchMap, TeardownLogic } from 'rxjs';
import { NgZone, Injectable } from '@angular/core';

import { AMapLoggerService } from './amap-logger.service';

const TAG = 'EventBinder';

type EventMap = AMapEventBinderService.EventMap;
type KeyType<T, TDef> = keyof T extends never ? TDef : keyof T;

type EventType<
    TEventMap extends EventMap,
    TName extends KeyType<TEventMap, string>
> = TName extends keyof TEventMap ? TEventMap[TName] : any;

function binder<T extends AMap.EventEmitter>(
    this: AMapEventBinderService,
    logger: AMapLoggerService,
    ngZone: NgZone,
    target: Observable<T>,
    eventName: string
): Observable<any> {
    return target.pipe(
        switchMap((t: T) => {
            return new Observable(
                (subscriber: Subscriber<any>): TeardownLogic => {
                    const handler = AMap.event.addListener(t, eventName, e => {
                        ngZone.run((() => subscriber.next(e)));
                    }, this);

                    logger.d(TAG, `subscribed event: ${eventName}`);

                    return (() => {
                        AMap.event.removeListener(handler);
                        logger.d(TAG, `unsubscribed event: ${eventName}`);
                    });
                }
            )
        })
    )
}

@Injectable({ providedIn: 'root' })
export class AMapEventBinderService {
    private readonly _binder = binder.bind(this, this.logger, this.ngZone);

    constructor(
        private logger: AMapLoggerService,
        private ngZone: NgZone
    ) {
    }

    bindEvent<TEventMap extends EventMap = {}>(): {
        <
            T extends AMap.EventEmitter, TName extends KeyType<TEventMap, string>
        >(target: Observable<T>, eventName: TName): Observable<EventType<TEventMap, TName>>
    } {
        return this._binder as any;
    }
}

export namespace AMapEventBinderService {
    export type EventMap = {
        [k: string]: AMap.MapsEvent<string, any> | AMap.Map.HotspotEvent<string> | AMap.Event<string> | any
    }
}