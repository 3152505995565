
import { App as TApp } from "./types";

export abstract class Lang {
    trans<T extends string | TApp.Lang.Def>(key: T, interpolateParams?: Object): TApp.Lang.Res<T> {
        return this.app.trans(key, interpolateParams);
    }

    constructor(public app: TApp.IAppService) {
    }
}
