import { Component } from '@angular/core';

import { AppService } from '../../application/service/app.service';

@Component({
    selector: 'application-orgnization',
    templateUrl: "./orgnization.component.html",
    styleUrls: ['./orgnization.component.scss']
})
export class OrgnizationComponent {
    constructor(public app: AppService) {
    }
}
