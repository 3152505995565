import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class AMapSizeService {
    constructor() {
    }

    create(options?: AMap.ISize | AMap.SizeValue | AMap.Size): AMap.Size | undefined {
        if (!options) {
            return;
        }

        if (options instanceof AMap.Size) {
            return options;
        }

        if (Array.isArray(options)) {
            return new AMap.Size(options[0], options[1]);
        }

        if (options.width !== undefined && options.height !== undefined) {
            return new AMap.Size(options.width, options.height);
        }

        if (options.w !== undefined && options.h !== undefined) {
            return new AMap.Size(options.w, options.h);
        }
    }
}
