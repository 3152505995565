import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GovEditor } from '../../utils/view/model/form.editting';

import { ProjectDetailComponent } from '../../projectdetail/view/projectdetail.component';
import { PuSchemaService } from '../../utils/puzzle/pu.service/pu.schema.service';
import { PuDialogService } from '../../utils/puzzle/pu.dialog/pu.dialog.service';
import { AppService } from '../../application/service/app.service';
import { Prj } from '../../application/service/backface/prj';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [PuSchemaService]
})
export class HomeComponent extends GovEditor.ToolBar implements OnInit {
    private _props = Prop.Of(this);

    get customStyles(): AMap.MarkerClusterer.Style[] {
        const { _props: props, app: { version: { server: { version } = {} } } } = this;

        return props.customStyles || (props.customStyles = [{
            url: `assets/img/map/cluster.png?version=${version}`,
            offset: [-16, -16],
            size: [32, 32],
        }, {
            url: `assets/img/map/cluster.png?version=${version}`,
            offset: [-16, -16],
            size: [32, 32],
        }]);
    }

    get mark_icon(): AMap.IIcon {
        const { _props: props, app: { version: { server: { version } = {} } } } = this;

        return props.mark_icon || (props.mark_icon = {
            image: `assets/img/map/location-red.png?version=${version}`,
            size: [48, 48],
        })
    }

    // search panel
    searchtext: string = "";

    readonly mapnotset: string = '';
    readonly markerNull = ["-100", "-100"];
    readonly city_district: string = "610722";
    readonly city_position: string[] = ["107.333545", "33.156308"];
    readonly city_address: string = "陕西省汉中市城固县博望街道民主街33号城固县人民政府";

    readonly projectFielder: Fielder.IFielder = Fielder.getFielder(this.es.recordsets, 'project_pool_summary.projectcount.totalproject[<project>]', this.es.recordsets)!;
    get filteredprojects(): Prj.Project[] {
        const { _props: props, projectFielder } = this;
        return (props.filteredprojects || (props.filteredprojects =
            projectFielder.value ?? []
        ));
    }

    constructor(
        public override router: ActivatedRoute,
        protected httpClient: HttpClient,
        public dialog: PuDialogService,
        public es: PuSchemaService,
        public app: AppService
    ) {
        super(router, 'home', {
            selectedYear: app.dict.currentYear
        });

        const { app: { lang: { general } } } = this;
        this.city_district = general.city_district || this.city_district;
        this.city_position = general.city_position || this.city_position;
        this.city_address = general.city_address || this.city_address;
        this.mapnotset = general.mapnotset || this.mapnotset;
    }

    ngOnInit() {
    }

    ngAfterViewInit() { }

    override dosearch(changed: GovEditor.ToolBar.Changed, content: GovEditor.ToolBar.Content): void {
        const { _props: props } = this;

        const sub = this.es.update(content).subscribe({
            complete() {
                tick(() => sub?.unsubscribe())
            },
            next() {
                delete props.filteredprojects;
                tick(() => sub?.unsubscribe())
            }
        });
    }

    onProjectDetail(project: Prj.Project) {
        const { app: { datasources: { project: { headers } } }, dialog } = this;

        dialog.open({
            template: ProjectDetailComponent,
            maximized: true,
            resiable: true
        }, {
            header: headers?.indexed['projectdetails'],
            object: project,
            dataset: null,
            from: this,
        })
    }
}
