import { ECElementEvent } from 'echarts';
import * as echarts from 'echarts';
import { A, O } from 'ts-toolbelt';

import { Exec as TExec, Prj as TPrj, App as TApp } from './types';
import { Editor } from '../../../utils/libs/editor';
import { Lang } from './lang';
import { Dict } from './dict';
import { Exec } from './exec';
import { Prj } from './prj';
import { Org } from './org';
import { Sys } from './sys';

const Value = Editor.Value;

export type EChartOption = echarts.EChartsOption;
type IRangCondition = Report.IRangCondition;
type IChartConfig = Report.IChartConfig;
type IxAxisItem = Report.IxAxisItem;

export class Report extends Lang {
    readonly costRange: IRangCondition[] = [
        {
            id: this.trans('report.costrangeid1'),
            name: this.trans('report.costrange1'),
            min: 0,
            max: 100
        },
        {
            id: this.trans('report.costrangeid2'),
            name: this.trans('report.costrange2'),
            min: 100,
            max: 1000
        },
        {
            id: this.trans('report.costrangeid3'),
            name: this.trans('report.costrange3'),
            min: 1000,
            max: 2000
        },
        {
            id: this.trans('report.costrangeid4'),
            name: this.trans('report.costrange4'),
            min: 2000,
            max: 5000
        },
        {
            id: this.trans('report.costrangeid5'),
            name: this.trans('report.costrange5'),
            min: 5000,
            max: 1000000
        }
    ];

    readonly percentRange: IRangCondition[] = [
        {
            id: this.trans('report.percentrangeid1'),
            name: this.trans('report.percentrange1'),
            min: 0,
            max: 0.25
        },
        {
            id: this.trans('report.percentrangeid2'),
            name: this.trans('report.percentrange2'),
            min: 0,
            max: 0.5
        },
        {
            id: this.trans('report.percentrangeid3'),
            name: this.trans('report.percentrange3'),
            min: 0.5,
            max: 0.75
        },
        {
            id: this.trans('report.percentrangeid4'),
            name: this.trans('report.percentrange4'),
            min: 0.75,
            max: 1
        },
        {
            id: this.trans('report.percentrangeid5'),
            name: this.trans('report.percentrange5'),
            min: 1,
            max: 1.5
        },
        {
            id: this.trans('report.percentrangeid6'),
            name: this.trans('report.percentrange6'),
            min: 1.5,
            max: 1000000
        }
    ];

    readonly statuslist: IxAxisItem[] = [
        {
            id: 'all',
            name: this.trans('sysenums.all')
        },
        {
            id: 'newcreate',
            name: this.trans('sysenums.newcreate')
        },
        {
            id: 'continous',
            name: this.trans('sysenums.continous')
        },
        {
            id: 'ongoing',
            name: this.trans('sysenums.ongoing')
        },
        {
            id: 'delaying',
            name: this.trans('sysenums.delaying')
        },
        {
            id: 'delayed',
            name: this.trans('sysenums.delayed')
        },
        {
            id: 'finished',
            name: this.trans('sysenums.prjfinished')
        },
        {
            id: 'constrpre',
            name: this.trans('sysenums.constrpre')
        },
        {
            id: 'constring',
            name: this.trans('sysenums.constring')
        },
        {
            id: 'accepting',
            name: this.trans('sysenums.accepting')
        }
    ];

    readonly yearcostinvest: IxAxisItem[] = [
        {
            id: 'yearlyinvested',
            name: this.trans('general.yearlyinvested')
        },
        {
            id: 'yearlyassetinvested',
            name: this.trans('general.yearlyassetinvested')
        }
    ]

    readonly monthChartConfig: IChartConfig = {
        title: this.trans('report.yearmonthcostchart'),
        xAxis: this.trans('general.month2'),
        yAxis: this.trans('general.investedcost')
    };

    readonly prjcostChartConfig: IChartConfig = {
        title: this.trans('projectdetail.budgetchart'),
        xAxis: this.trans('general.annual'),
        yAxis: ""
    }

    readonly statusChartConfig: IChartConfig = {
        title: this.trans('report.statuschart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly plancostChartConfig: IChartConfig = {
        title: this.trans('report.costchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly plancostpercentChartConfig: IChartConfig = {
        title: this.trans('report.percentchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly yearplancostChartConfig: IChartConfig = {
        title: this.trans('report.yearcostchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    readonly yearplancostpercentChartConfig: IChartConfig = {
        title: this.trans('report.yearpercentchart'),
        xAxis: this.trans('report.statusaxisx'),
        yAxis: this.trans('report.statusaxisy')
    };

    //barcolors is default
    static readonly barcolors: string[] = ['#5470c6', '#91cc75', 'blanchedalmond', '#add8e6', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#3ba272'];
    readonly planbarcolors: string[] = [
        this.trans('syscolors.all'),
        this.trans('syscolors.newcreate'),
        this.trans('syscolors.continous'),
        this.trans('syscolors.ongoing'),
        this.trans('syscolors.delaying'),
        this.trans('syscolors.delayed'),
        this.trans('syscolors.finished'),
        this.trans('syscolors.constrpre'),
        this.trans('syscolors.constring'),
        this.trans('syscolors.accepting'),
    ];

    readonly costbarcolors: string[] = [
        this.trans('syscolors.costrangeid1'),
        this.trans('syscolors.costrangeid2'),
        this.trans('syscolors.costrangeid3'),
        this.trans('syscolors.costrangeid4'),
        this.trans('syscolors.costrangeid5'),
    ];

    readonly percentbarcolors: string[] = [
        this.trans('syscolors.percentrangeid1'),
        this.trans('syscolors.percentrangeid2'),
        this.trans('syscolors.percentrangeid3'),
        this.trans('syscolors.percentrangeid4'),
        this.trans('syscolors.percentrangeid5'),
        this.trans('syscolors.percentrangeid6'),
    ];

    readonly supervisemixlinebarcolors: string[] = [
        this.trans('syscolors.refproject'),
        this.trans('syscolors.problemnotfixed'),
        this.trans('syscolors.supervisecount'),
        this.trans('syscolors.problemcount')
    ];
    readonly executionmixlinebarcolors: string[] = [
        this.trans('syscolors.refproject'),
        this.trans('syscolors.workitemexecount'),
        this.trans('syscolors.workitemexeoverdue'),
        this.trans('syscolors.workitemexeongoing')
    ];
}

export namespace Report {
    export enum eChartType {
        bar,
        pie,
        line
    }

    export interface IChartConfig {
        title?: string,
        xAxis?: string,
        yAxis?: string
    }

    export interface IxAxisItem {
        id?: string,
        name?: string
    }

    export interface IRangCondition {
        id?: string,
        name?: string,
        min?: number,
        max?: number
    }

    export interface IChartParam {
        charttype: eChartType,
        chartid: string,
        option: EChartOption,
        reqURL: string,
        legends: Array<any>,
        xAxisItems: Array<any>,
        chartCfg: IChartConfig,
        legtag: string,
        dataMap?: Map<string, Report.Projects | undefined>
    }

    export interface IChartPieDataItem {
        value: number,
        name: string,
        key: string
    }

    export interface IChartPieData {
        id: string,
        title: string,
        totalname: string,
        totalsum: number,
        data: IChartPieDataItem[],
        trigger?(val: { serialid?: string, dataindex: number }): void;
    }

    type ECOption<T> = T & {
        readonly merge: T | undefined
        resetmerge(): ECOption<T>
    }

    export abstract class ReportTemplate {
        static get ChartPie(): ECOption<O.Required<O.Overwrite<EChartOption, {
            graphic: O.Required<ECGraphic<'text'>, 'style'>,
            series: [ECSeries<'pie'>]
        }>, 'graphic' | 'series'>> {
            type TOption = typeof ReportTemplate.ChartPie;
            type TMerge = TOption['merge'];
            let merge: TMerge;

            const option: TOption = {
                resetmerge(): TOption {
                    merge = undefined;
                    return option;
                },
                get merge() {
                    const { graphic, series: [series] } = option;
                    return merge || (merge = {
                        graphic: {
                            style: {
                                text: graphic.style.text
                            }
                        },
                        series: [{
                            name: series.name,
                            data: series.data,
                            id: series.id
                        }]
                    })
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    selectedMode: false,
                    show: false
                },
                graphic: {
                    type: 'text',
                    top: 'center',
                    left: 'center',
                    style: {
                        text: 'Need to input', //'项目总数\n' + String(sum).replace(/(\d)(?=(?:\d{6})+$)/g, '$1.'),
                        //textAlign: 'center',   // need to solve this problem
                        fontSize: 18
                    }
                },
                series: [
                    {
                        id: 'Need to input',
                        name: 'Need to input',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        minAngle: 30,
                        itemStyle: {
                            borderRadius: 5,
                            borderColor: '#fff',
                            borderWidth: 5
                        },
                        label: {
                            show: true,
                            position: 'inner',
                            formatter: '{b}\n{c}\n{d}%'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 18,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            }

            return option;
        }

        static get ChartStackLine(): EChartOption {
            return {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    align: 'auto',
                    top: 30,
                    padding: 0
                },
                grid: {
                    left: '5%',
                    right: '8%',
                    bottom: '5%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    data: []
                },
                yAxis: {
                    name: '',
                    type: 'value',
                    axisLine: { show: true }
                },
                series: []
            };
        }

        static get ChartLineItem(): Report.ECSeries<'line'> {
            return {
                id: '',
                name: '',
                type: 'line',
                smooth: true,
                data: []
            }
        }

        static get ChartBarStackItem(): Report.ECSeries<'bar'> {
            return {
                name: '',
                type: 'bar',
                stack: '',
                barMaxWidth: '10%',
                barMinWidth: '5%',
                // barMaxHeight: '10%', // TODO
                // barMinHeight: '1%',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            }
        }

        static get ChartBarItem(): Report.ECSeries<'bar'> {
            return {
                name: '',
                type: 'bar',
                id: '',
                barMaxWidth: '10%',
                barMinWidth: '5%',
                // barMaxHeight: '10%', // TODO:
                // barMinHeight: '1%',
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            }
        }

        static get ChartBar(): EChartOption {
            const { barcolors } = Report;

            return {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    align: 'auto',
                    top: 40,
                    padding: 0
                },
                grid: {
                    left: '5%',
                    right: '8%',
                    bottom: '5%',
                    containLabel: true
                },
                color: barcolors,
                tooltip: {
                    trigger: 'axis',
                    showContent: true,
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    name: '',
                    type: 'category',
                    axisLabel: { interval: 0, rotate: 40 },
                    data: []
                },
                yAxis: {
                    name: '',
                    type: 'value',
                    axisLine: { show: true }
                },
                series: []
            };
        }

        static MixedLineBar(mixlinebarcolors: string[], line: number, bar: number): EChartOption {
            const option: EChartOption = {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },

                color: mixlinebarcolors,

                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                grid: {
                    right: '20%'
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    align: 'auto',
                    top: 40,
                    padding: 0,
                    data: []
                },
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: { interval: 0, rotate: 40 },
                        axisTick: {
                            alignWithLabel: true
                        },
                        data: []
                    }
                ],
                yAxis: [],
                series: []
            };

            const series = option.series as echarts.SeriesOption[];
            const yAxis = option.yAxis as echarts.YAXisComponentOption[];

            for (let index = 0, offset = 0; index < (line + bar); index++, offset++) {
                if (index == 0 || index == line) offset = 0;

                yAxis[index] = {
                    type: 'value',
                    name: '',
                    min: 0,
                    max: 100,
                    position: index < line ? 'left' : 'right',
                    offset: offset * 80,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: mixlinebarcolors[index]
                        }
                    },
                    axisLabel: {
                        formatter: '{value} '
                    }
                };

                series[index] = {
                    name: '',
                    type: index < line ? 'line' : 'bar',
                    yAxisIndex: index,
                    barMinHeight: 10,
                    data: []
                };
            };

            return option;
        }
    }

    export function InitEchart(id: string, option: EChartOption): void {
        var chartDom = document.getElementById(id) as HTMLDivElement;
        if (chartDom == null) {
            console.log("InitEchart can't find Echart element: " + id);
            return;
        }

        var myChart = echarts.init(chartDom);
        if (myChart == null) {
            console.log("InitEchart can't init Echart element: " + id);
            return;
        }

        myChart.setOption(option);
        myChart.on('click', function (params) {
            const { seriesName = '', value, name } = params;
            alert(`${seriesName}${value}${name}`);
        });
    }

    export function RefreshEchart(id: string, option: EChartOption): void {
        var chartDom = document.getElementById(id) as HTMLDivElement;
        if (chartDom == null) {
            console.log("RefreshEchart: can't find Echart element: " + id);
            return;
        }

        var myChart = echarts.getInstanceByDom(chartDom);
        if (myChart == null) {
            console.log("RefreshEchart: can't init Echart element: " + id);
            return;
        }

        myChart.setOption(option);
    }

    export function onClickCharBar(params: ECElementEvent): void {
        const { seriesName, value, name } = params
        alert(`${seriesName}${value}${name}`);
    }

    export function InitChartParam(param: Report.IChartParam): void {
        const { option: ecoption, charttype, chartCfg, legends, xAxisItems, dataMap } = param;
        const xAxis = (ecoption.xAxis || (ecoption.xAxis = {})) as Report.ECXAxis<'category'>;
        const yAxis = (ecoption.yAxis || (ecoption.yAxis = {})) as echarts.YAXisComponentOption;
        const title = (ecoption.title || (ecoption.title = {})) as echarts.TitleComponentOption;
        const series = (ecoption.series || (ecoption.series = [])) as echarts.SeriesOption[];
        const xAxisData = (xAxis.data || (xAxis.data = []));

        title.text = chartCfg.title;
        xAxis.name = chartCfg.xAxis;
        yAxis.name = chartCfg.yAxis;

        legends.forEach((legend, idx) => {
            const legitem = (charttype == Report.eChartType.line) ?
                Report.ReportTemplate.ChartLineItem
                : Report.ReportTemplate.ChartBarItem;

            legitem.name = legend.name;
            legitem.id = legend.id;
            series[idx] = legitem;
        });

        xAxisItems.forEach(element => {
            xAxisData.add([element.name]);
        });

        if (!dataMap) return;

        xAxisItems.forEach(element => {
            legends.forEach(legend => {
                const { id, name } = legend, { name: ename } = element;
                dataMap.set(`${ename}${id}${name}`, undefined);
            });
        });
    }
}

export namespace Report {
    export type StatusCount = {
        [s in keyof typeof TPrj.IWorkitem.Status]: number
    } & {
        project: {
            patched: number,
            patchedchild: number,
            nonpatched: number,
        }
    }

    export type SuperviseCount = {
        count: number,   // count of supervise
        problem: {
            count: number,  // count of problem
            tofix: number   // count of problem to fix
        },
        project: {
            patched: number,
            patchedchild: number,
            nonpatched: number,
        }
    }

    export type Projects<TX extends {} = {}> = (TX & {
        id: string;
        name: string,
        pool: string,
        ispatched: boolean;
        project?: (TX & {
            id: string;
            name: string,
            pool: string,
            ispatched: boolean;
        })[]
    })[]

    export type ProjectsCount = {
        count: {
            project: {
                patched: number,
                patchedchild: number,
                nonpatched: number,
            }
        },
        project: Projects
    }

    export type IWorkitemCountPerProjectStageStatus = {
        count: StatusCount,
        project: Projects,
        stage: {
            id: string,
            name: string,
            status?: string,
            count: StatusCount,
            project: Projects
        }[]
    };

    export type ISupervisePerMonth = {
        count: SuperviseCount,
        project: Projects,
        month: {
            month: string, // month
            count: SuperviseCount,
            project: Projects
        }[]
    }

    export type ICostConsumePerMonth = {
        consume: {
            yearlyinvested: number,   // consume of yearlyinvested
            yearlyassetinvested: number,   // consume of yearlyinvested
        },
        project: Projects<{
            yearlyassetinvested: number,
            yearlyinvested: number,
            month: string,
        }>,
        month: {
            month: string, // month
            consume: {
                yearlyinvested: number,   // consume of yearlyinvested
                yearlyassetinvested: number,   // consume of yearlyinvested
            },
            project: Projects<{
                yearlyinvested: number,
                yearlyassetinvested: number
            }>
        }[]
    }

    export type ProjectCountCat = ((keyof typeof TPrj.ConstrType) | (keyof typeof TPrj.IWorkitem.Status));
    export type ProjectCountCats = ProjectCountCat | 'segment';
    export type IProjectCountPerPlan = {
        plan: O.Assign<{
            id: string, // plan id
            name: string, // plan name
        }, [{
            [item in ProjectCountCat]: ProjectsCount
        }, {
            segment: (ProjectsCount & {
                type: string,
            })[]
        }]>[]
    }

    export type IProjectCountBudget = {
        [item in ProjectCountCat]: {
            totalamount: number;    // total project budget
            yearsamount: number;    // total project budget for years
            yearsconsume: number;   // total project consume for years
            count: {
                project: {
                    patched: number,
                    patchedchild: number,
                    nonpatched: number
                }
            },
            project: Projects
        }
    }

    export interface IResult extends TExec.IResult {
        month: {
            month: string, // month
            consume: {
                yearlyinvested: number,   // consume of yearlyinvested
                yearlyassetinvested: number,   // consume of yearlyinvested
            }
        }[]
    }

    export interface IProblemEx extends TExec.IProblem {
        project: { id: string },
        projecttype: { id: string },
        stage: { id: string },
        workset: { id: string },
        workitem: { id: string },
        result: { id: string },
        people: { id: string },

        order: number;
        id?: Prop.guid;
        auditid?: number,
        problem?: string,
        date?: string | Date,
        fixed?: boolean,
    }

    export type IProjectWorkitems = {
        id: string;
        name: string;
        pool: string,
        workitem: {
            id: string;
            workset: string;
            stage_status?: string;
            stage_order?: number,
            stage: string;
            name: string;
            type: string;
            begindate: string;
            enddate: string;
            status: string;
            value: { // the last result value depends on the checkpoints in this workitem
                [P: string]: any
            };
            audit?: TExec.IAudit,
            checkpoint: string;
            closedate: string;
            dept: {
                id: string;
                name: string;
            }
        }[],
    }[]
}

export namespace Report {
    type Picker<T> = T extends { type?: infer T } ? (A.Equals<T, string> extends 1 ? never : T) : never;

    export type ECSeriesType = Picker<EChartOption['series']>;
    export type ECSeries<T extends ECSeriesType> = Extract<EChartOption['series'], { type?: T }>;

    export type ECXAxisType = Picker<EChartOption['xAxis']>;
    export type ECXAxis<T extends ECXAxisType> = Extract<EChartOption['xAxis'], { type?: T }>;

    export type ECYAxisType = Picker<EChartOption['yAxis']>;
    export type ECYAxis<T extends ECYAxisType> = Extract<EChartOption['yAxis'], { type?: T }>;

    export type ECGraphicType = Picker<EChartOption['graphic']>;
    export type ECGraphic<T extends ECGraphicType> = Extract<EChartOption['graphic'], { type?: T }>;
}

export namespace Report {
    export function buildProblems(app: TApp.IAppService, _problems: Report.IProblemEx[] = [], output: Exec.Problem[] = []): Exec.Problem[] {
        const results: { [id: string]: Exec.Result } = {};

        const rows = _.reduce(_problems, (rows, v) => {
            const result: Exec.Result = (results[v.result.id] || (
                results[v.result.id] = new Exec.Result(
                    app, <TExec.IResult>{
                        id: v.result.id,
                        project: v.project,
                        projecttype: v.projecttype,
                        stage: v.stage,
                        workset: v.workset,
                        workitem: v.workitem,
                        people: v.people,
                        date: v.date
                    }
                )
            ))

            return rows.push(new Exec.Problem(result, {
                id: v.id,
                auditid: v.auditid,
                problem: v.problem,
                date: v.date,
                fixed: v.fixed,
            })), rows;
        }, <Exec.Problem[]>[]);

        return output.splice(0, output.length, ...rows), output;
    }

    export function buildResult(app: TApp.IAppService, _results: TExec.IResult[] = [], output: Exec.Result[] = [], host: {
        project?: Prj.Project;
        workitem?: Prj.ProjWorkitem;
    } = {}): Exec.Result[] {
        const rows = _results.map(r => new Exec.Result(app, _.extend(r, host)));
        return output.splice(0, output.length, ...rows), output;
    }

    export function buildAuditResults(app: TApp.IAppService, _results: TExec.IAuditResult[] = [], output: Exec.AuditResult[] = [], host: {
        project?: Prj.Project;
        workitem?: Prj.ProjWorkitem;
    } = {}): Exec.AuditResult[] {
        const rows = _results.map(r => new Exec.AuditResult(app, _.extend(r, host))).filter((r) => !!r.workitem);
        return output.splice(0, output.length, ...rows), output;
    }

    export function buildWorkitems(app: TApp.IAppService, _projectworkitems: Report.IProjectWorkitems, output: Prj.ProjWorkitem[] = []): Prj.ProjWorkitem[] {
        const dict = Sys.cast_dict(app.dict, Dict);
        const prj = Sys.cast_prj(app.prj, Prj);
        const org = Sys.cast_org(app.org, Org);

        const { project: projects } = prj, { AllStatus } = dict;
        const _projects = _projectworkitems || [];
        type Status = TPrj.IWorkitem.Status;

        const rows = _projects.reduce((rows, { pool, id: projectid, workitem: _workitems }) => {
            const project = projects.firstOf({ id: projectid, pool: Value.toEnum(pool, TPrj.Pool) });
            if (!project) {
                console.assert(!!project, 'cannot find project for project_workitem_status')
                return rows;
            }

            const { workitems, projecttype: { stages } = {} } = project;
            const { workitems: gworkitems } = prj;
            if (!workitems || !_workitems) {
                return rows;
            }

            const staged: { [id: string]: Prj.ProjStage } = {};
            for (const { stage, workset, id, checkpoint, stage_status, type, value, audit, status, closedate, begindate, enddate } of _workitems) {
                let projectworkitem;

                if (type != 'supervise') {
                    if (!staged[stage]) {
                        const pstage = stages?.firstOf({
                            id: stage
                        });

                        pstage && (staged[stage] = pstage);
                        const status = AllStatus.indexed[stage_status!];
                        pstage && status && (pstage.status = status.value as Status);
                    }

                    projectworkitem = workitems.firstOf({
                        stage: { id: stage }, workset: { id: workset }, id
                    })
                } else {
                    const gworkitem = gworkitems.firstOf({ id });
                    projectworkitem = new Prj.Workitem(
                        org, prj, project, gworkitem as TPrj.IWorkitem
                    )
                }

                if (!projectworkitem) continue;

                projectworkitem.value = value;
                projectworkitem.audit = audit;
                projectworkitem.closedate = Value.toDate(closedate);
                projectworkitem.begindate = Value.toDate(begindate);
                projectworkitem.enddate = Value.toDate(enddate);
                projectworkitem.status = Value.toEnum(status, TPrj.IWorkitem.Status);
                rows.push(projectworkitem);

                if (checkpoint) {
                    // has closed, init the value of checkpoint identify closed
                    const cp = projectworkitem.checkpoints.find(
                        c => c.key == checkpoint
                    );

                    if (Value.isFieldType(cp?.type, Value.Type.bool)) {
                        value[checkpoint] = !!closedate;
                    }
                }

                const notused = projectworkitem.checkpoints.find(
                    c => c.key == 'notused'
                );

                if (notused) {
                    if (!Object.getOwnPropertyDescriptor(projectworkitem, 'notused')) {
                        Editor.Dynamic.build(projectworkitem, projectworkitem.parent, notused, Exec.FieldTypes, { isReadonly: false })
                    }

                    projectworkitem.$pushSaving_();
                    projectworkitem.notused = value?.notused;
                    projectworkitem.$popSaving_();
                }
            }

            return rows;
        }, <Prj.Workitem<any>[]>[]);

        return output.splice(0, output.length, ...rows), output;
    }

    export function buildCostConsumePerMonth(selectedYear: number, param: Report.IChartParam, res: Report.ICostConsumePerMonth) {
        const { dataMap, option: ecoption } = param;

        if (res?.month == null) { return; }

        const { consume: resconsume } = res;
        if (resconsume != undefined) {
            //update count card
        }

        const series = ecoption.series as Report.ECSeries<'line' | 'bar'>[];
        const { month: resmonths } = res;

        //update stage
        dataMap?.clear();

        for (let index = 1; index <= 12; index++) {
            const month = `${selectedYear}-${index < 10 ? '0' : ''}${index}`;
            const resmonth = resmonths.find(m => m.month == month);

            if (resmonth == null) {
                series[0].data![index - 1] = 0;
                series[1].data![index - 1] = 0;
                continue;
            };

            series[0].data![index - 1] = resmonth.consume?.yearlyinvested ? resmonth.consume.yearlyinvested : 0;
            series[1].data![index - 1] = resmonth.consume?.yearlyinvested ? resmonth.consume.yearlyassetinvested : 0;
            if (series[0].data![index - 1] != 0 && dataMap) {
                // no data needs to cache for next step
            }
        }
    }

    export function buildProjectCountPerPlan(param: Report.IChartParam, res: Report.IProjectCountPerPlan) {
        const { xAxisItems, legends, dataMap, legtag, option: ecoption } = param;

        if (res?.plan == null) { return; }

        const series = ecoption.series as Report.ECSeries<'line' | 'bar'>[];
        const { plan: resplans } = res;

        xAxisItems.forEach(({ id, name }, j) => {
            const resplan = resplans.find(obj => obj.id == id);
            if (resplan == null) return;

            legends.forEach(({ id: lid, name: lname }, i) => {
                const legval = legtag === 'segment' ? resplan.segment.find(
                    val => val.type == lid
                ) : resplan[lid as Report.ProjectCountCat];

                series[i] = series[i] || {};
                const data = (series[i].data = series[i].data || []);
                data[j] = (legval == null) ? 0 : legval.count.project.nonpatched;

                if (data[j] != 0 && dataMap) {
                    const key = `${name}${lid}${lname}`;
                    dataMap.set(key, legval?.project);
                }
            });
        });
    }
}