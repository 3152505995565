import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_BASE_HREF, CommonModule, IMAGE_CONFIG, NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { BrowserModule } from '@angular/platform-browser';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';

import { QRCodeComponent } from 'angularx-qrcode';

import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { NgbAlertModule, NgbDropdownModule, NgbCarouselModule, NgbProgressbarModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgJsonEditorModule } from 'ang-jsoneditor';
import { FileUploadModule } from 'ng2-file-upload';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxXml2jsonModule } from 'ngx-xml2json';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

import { NgxCMenuModule } from './utils/ctxmenu/ngx.ctxmenu';
import { NgxAMapModule } from './utils/amap/ngx.amap';

import { PuzzleUtilsModule } from './utils/puzzle/module';

import { Projectdetail } from './projectdetail/module';
import { WorkItemList } from './workitemlist/module';
import { ReportComponents } from './report/module';
import { ProblemList } from './problemlist/module';
import { Projectlist } from './projectlist/module';
import { Application } from './application/module';
import { Orgnization } from './orgnization/module';
import { Processhelp } from './processhelp/module';
import { Supervise } from './supervise/module';
import { Systemset } from './systemset/module';
import { Maplocate } from './maplocate/module';
import { Resetpwd } from './resetpwd/module';
import { Userinfo } from './userinfo/module';
import { Process } from './process/module';
import { Results } from './results/module';
import { Summary } from './summary/module';
import { Login } from './login/module';
import { Audit } from './audit/module';
import { Utils } from './utils/module';
import { Home } from './home/module';
import { User } from './user/module';
import { Team } from './team/module';
import { Plan } from './plan/module';

import { PuVersioinService } from './utils/puzzle/pu.service/pu.version.service';
import { PuHttpService } from './utils/puzzle/pu.service/pu.http.service';
import { AppComponent } from './application/view/app.component';
import { Sys } from './application/service/backface/sys';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle({ key, translateService: { parser, currentLang, translations }, interpolateParams: params }: MissingTranslationHandlerParams) {
        if (key?.indexOf("{{") < 0) return key;

        params = params || translations[currentLang];
        const res = parser.interpolate(key, params);
        return res ?? key;
    }
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgOptimizedImage,
        BrowserModule,
        CommonModule,
        FormsModule,
        OverlayModule,

        NgJsonEditorModule,
        FileUploadModule,
        PdfViewerModule,

        QRCodeComponent,

        Application.Modules,
        PuzzleUtilsModule,

        NgxEchartsModule.forRoot({
            echarts
        }),

        TranslateModule.forRoot({
            useDefaultLang: false,
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler
            }
        }),

        NgxAMapModule.forRoot({
            apiKey: '98b95d3c01dbc31995d1d3a1c4fd0d12'
        }),

        NgxCMenuModule.forRoot({
            autoFocus: true
        }),

        [
            NgbProgressbarModule,
            NgbCarouselModule,
            NgbDropdownModule,
            NgbPopoverModule,
            NgbAlertModule
        ],

        [
            CdkDropList,
            CdkDrag
        ],

        [
            MatMenuModule,
            MatIconModule,
            MatInputModule,
            MatButtonModule,
            MatRippleModule,
            MatSelectModule,
            MatTooltipModule,
            MatFormFieldModule,
            MatSidenavModule,
            MatButtonToggleModule,
            MatPaginatorModule,
            MatSortModule,
            MatTableModule,
            MatCheckboxModule,
            MatDialogModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatListModule,
            MatTabsModule,
            MatMomentDateModule,
            MatSlideToggleModule,
            MatExpansionModule,
            MatChipsModule,
            MatAutocompleteModule,
            MatStepperModule,
            MatCardModule,
            MatDividerModule,
            MatTreeModule,
            MatProgressBarModule,
            MatRadioModule,
            MatToolbarModule
        ],
    ],
    declarations: [
        Utils.Components,
        Application.Components,

        Login.Components,
        Resetpwd.Components,
        Userinfo.Components,
        Home.Components,
        Maplocate.Components,
        Orgnization.Components,
        User.Components,
        Process.Components,
        Results.Components,
        Team.Components,
        Plan.Components,
        Audit.Components,
        Supervise.Components,
        Summary.Components,
        Systemset.Components,

        Projectdetail.Components,
        ReportComponents.Components,
        Projectlist.Components,
        WorkItemList.Components,
        ProblemList.Components,
        Processhelp.Components,
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),

        [
            {
                provide: IMAGE_CONFIG,
                useValue: {
                    placeholderResolution: 40
                }
            },
            {
                provide: MatPaginatorIntl, useValue: Sys.paginatorIntl
            },
            {
                provide: DateAdapter,
                useClass: MomentDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            },
            {
                provide: MAT_DATE_LOCALE,
                useValue: 'zh-CN'
            },
            {
                provide: MAT_DATE_FORMATS, useValue: {
                    ...MAT_MOMENT_DATE_FORMATS,
                    parse: {
                        ...MAT_MOMENT_DATE_FORMATS.parse,
                    },
                    display: {
                        ...MAT_MOMENT_DATE_FORMATS.display,
                        monthYearLabel: Sys.yearMonthFormat,
                        monthYearA11yLabel: Sys.yearMonthFormat,
                    },
                }
            }, {
                provide: MAT_CHIPS_DEFAULT_OPTIONS,
                useValue: {
                    separatorKeyCodes: [ENTER, COMMA]
                }
            }, {
                provide: STEPPER_GLOBAL_OPTIONS,
                useValue: {
                    displayDefaultIndicatorType: false
                }
            }, {
                provide: MAT_TABS_CONFIG,
                useValue: {
                    animationDuration: "0ms"
                }
            },
            // {
            //     provide: MatStepperIntl,
            //     useClass: MyIntl
            // },
            {
                provide: HTTP_INTERCEPTORS,
                useExisting: PuHttpService,
                multi: true
            },
            {
                provide: APP_BASE_HREF,
                useValue: '/'
            }
        ],

        Application.Services,
        Utils.Services,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        public translate: TranslateService,
        public version: PuVersioinService,
        public http: PuHttpService
    ) {
        const { getParsedResult } = translate;

        // override getParsedResult of translate to support self-reference translate
        translate.getParsedResult = function (this: TranslateService, translations: any, key: any, interpolateParams?: Object) {
            key = (_.isString(key) && key[key.length - 1] === '.') ? `${key}.` : key;
            const res = getParsedResult.call(this, translations, key, interpolateParams);
            if (interpolateParams || !_.isString(res) || res.indexOf('{{') < 0) return res;

            // try once more for self reference
            let preres = res, postres = res;

            do {
                postres = getParsedResult.call(this, translations, (preres = postres), translations);
            } while (preres != postres && postres.indexOf('{{') >= 0)

            return postres;
        }
    }
}
