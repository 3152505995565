import { outputFromObservable } from '@angular/core/rxjs-interop';
import { Directive, Input } from '@angular/core';

import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { Getter } from './types';

export const OverlayOptions: Extract<keyof AMapOverlay<AMap.EventEmitter>, keyof AMap.Overlay.Options>[] = [
    'cursor',
    'extData',
    'bubble',
    'clickable',
    'draggable'
];

@Directive()
export class AMapOverlay<T extends AMap.EventEmitter> implements AMap.Overlay.Options {
    protected readonly _binder = this.binder.bindEvent<AMap.PathOverlay.EventMap<T>>();

    /**
     * 鼠标悬停时的鼠标样式
     */
    @Input()
    cursor?: string;

    /**
     * 自定义数据
     */
    @Input()
    extData?: any;

    /**
     * 事件是否穿透到地图
     */
    @Input()
    bubble?: boolean;

    /**
     * 是否支持点击
     */
    @Input()
    clickable?: boolean;

    /**
     * 是否支持拖拽
     */
    @Input()
    draggable?: boolean;

    naTouchStart = outputFromObservable(this._binder(this.os.get(), 'touchstart'));
    naTouchMove = outputFromObservable(this._binder(this.os.get(), 'touchmove'));
    naTouchEnd = outputFromObservable(this._binder(this.os.get(), 'touchend'));
    naClick = outputFromObservable(this._binder(this.os.get(), 'click'));
    naDblClick = outputFromObservable(this._binder(this.os.get(), 'dblclick'));
    naRightClick = outputFromObservable(this._binder(this.os.get(), 'rightclick'));
    naMouseOver = outputFromObservable(this._binder(this.os.get(), 'mouseover'));
    naMouseUp = outputFromObservable(this._binder(this.os.get(), 'mouseup'));
    naMouseMove = outputFromObservable(this._binder(this.os.get(), 'mousemove'));
    naMouseDown = outputFromObservable(this._binder(this.os.get(), 'mousedown'));

    constructor(
        protected readonly os: Getter<T>,
        protected readonly binder: AMapEventBinderService
    ) {
    }
}
