import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, Optional, output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActiveDescendantKeyManager, Highlightable } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

import { NGX_CMENU_CONFIG, NgxCMenuConfig, NgxCMenuLinkConfig } from '../../shared/ngx-ctxmenu.config';
import { NgxCMenuEventCloseLeaf, NgxCMenuEventClick } from '../../shared/ngx-ctxmenu.service';
import { NgxCMenuItemDirective } from '../ngx-ctxmenu-item.directive';

const ARROW_LEFT_KEYCODE = 37;

@Component({
    selector: 'ngx-cmenu-content',
    templateUrl: 'ngx-ctxmenu-content.component.html',
    styleUrls: ['ngx-ctxmenu-content.component.scss'],
    exportAs: 'ngxCMenuContent'
})
export class NgxCMenuContentComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() parentContextMenu?: NgxCMenuContentComponent;
    @Input() menuItems: NgxCMenuItemDirective[] = [];
    @Input() event?: MouseEvent | KeyboardEvent;
    @Input() isLeaf: boolean = false;
    @Input() overlay?: OverlayRef;
    @Input() menuClass?: string;
    @Input() item: any;

    readonly execute = output<{
        event: MouseEvent | KeyboardEvent;
        item: any;
        menuItem: NgxCMenuItemDirective;
    }>();

    readonly closeAllMenus = output<{
        event: MouseEvent;
    }>();

    readonly closeLeafMenu = output<NgxCMenuEventCloseLeaf>();
    readonly openSubMenu = output<NgxCMenuEventClick>();

    @ViewChild('menu', { static: true })
    menuElement!: ElementRef;

    @ViewChildren('li')
    readonly menuItemElements = new QueryList<ElementRef<HTMLLIElement>>;

    private _keyManager!: ActiveDescendantKeyManager<unknown>;
    private subscription = new Subscription();
    readonly useBootstrap4: boolean = false;
    private autoFocus: boolean = false;

    constructor(
        private readonly changeDetector: ChangeDetectorRef,
        private readonly elementRef: ElementRef,

        @Optional()
        @Inject(NGX_CMENU_CONFIG)
        private readonly options?: NgxCMenuConfig
    ) {
        if (options) {
            this.autoFocus = !!options.autoFocus;
            this.useBootstrap4 = !!options.useBootstrap4;
        }
    }

    ngOnInit(): void {
        this.menuItems.forEach(menuItem => {
            menuItem.currentItem = this.item;
            this.subscription.add(
                menuItem.execute.subscribe(
                    event => this.execute.emit(
                        Object.assign(
                            Object.assign({}, event),
                            { menuItem }
                        )
                    )
                )
            );
        });

        const queryList = new QueryList<Highlightable>();
        queryList.reset(this.menuItems);
        this._keyManager = new ActiveDescendantKeyManager(
            queryList
        ).withWrap();
    }

    ngAfterViewInit(): void {
        if (this.autoFocus) {
            setTimeout(() => this.focus());
        }

        this.overlay?.updatePosition();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    focus(): void {
        if (this.autoFocus) {
            this.menuElement.nativeElement.focus();
        }
    }

    stopEvent($event: MouseEvent): void {
        $event.stopPropagation();
    }

    isMenuItemEnabled(menuItem: NgxCMenuItemDirective): boolean {
        return this.evaluateIfFunction(menuItem && menuItem.enabled);
    }

    isMenuItemVisible(menuItem: NgxCMenuItemDirective): boolean {
        return this.evaluateIfFunction(menuItem && menuItem.visible);
    }

    evaluateIfFunction(value: any): any {
        if (value instanceof Function) {
            return value(this.item);
        }
        return value;
    }

    isDisabled(link: NgxCMenuLinkConfig): boolean {
        const { enabled } = link;
        if (!enabled) return false;
        return !(enabled?.(this.item));
    }

    @HostListener('window:keydown.ArrowDown', ['$event'])
    @HostListener('window:keydown.ArrowUp', ['$event'])
    onKeyEvent(event: KeyboardEvent): void {
        if (!this.isLeaf) {
            return;
        }

        this._keyManager.onKeydown(event);
    }

    @HostListener('window:keydown.ArrowRight', ['$event'])
    keyboardOpenSubMenu(event: KeyboardEvent): void {
        if (!this.isLeaf) {
            return;
        }

        this.cancelEvent(event);
        const { _keyManager: { activeItemIndex } } = this;
        if (activeItemIndex == null) return;

        const menuItem = this.menuItems[activeItemIndex];
        if (menuItem) this.onOpenSubMenu(menuItem);
    }

    @HostListener('window:keydown.Enter', ['$event'])
    @HostListener('window:keydown.Space', ['$event'])
    keyboardMenuItemSelect(event: KeyboardEvent): void {
        if (!this.isLeaf) {
            return;
        }
        this.cancelEvent(event);
        const { _keyManager: { activeItemIndex } } = this;
        if (activeItemIndex == null) return;

        const menuItem = this.menuItems[activeItemIndex];
        if (menuItem) this.onMenuItemSelect(menuItem, event);
    }

    @HostListener('window:keydown.Escape', ['$event'])
    @HostListener('window:keydown.ArrowLeft', ['$event'])
    onCloseLeafMenu(event: KeyboardEvent): void {
        if (!this.isLeaf) {
            return;
        }
        this.cancelEvent(event);
        this.closeLeafMenu.emit({
            exceptRootMenu: event.keyCode === ARROW_LEFT_KEYCODE,
            event
        });
    }

    @HostListener('document:click', ['$event'])
    @HostListener('document:contextmenu', ['$event'])
    closeMenu(event: MouseEvent): void {
        if (event.type === 'click' && event.button === 2) {
            return;
        }
        this.closeAllMenus.emit({ event });
    }

    onOpenSubMenu(menuItem: NgxCMenuItemDirective, event?: MouseEvent | KeyboardEvent): void {
        const { _keyManager: { activeItemIndex }, menuItemElements } = this;

        const anchorElementRef = activeItemIndex != undefined ? menuItemElements.toArray()[activeItemIndex] : undefined;
        const anchorElement = anchorElementRef?.nativeElement;

        this.openSubMenu.emit({
            contextMenu: menuItem.subMenu,
            parentContextMenu: this,
            item: this.item,
            anchorElement,
            event,
        });
    }

    onMenuItemSelect(menuItem: NgxCMenuItemDirective, event: MouseEvent | KeyboardEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.onOpenSubMenu(menuItem, event);
        if (!menuItem.subMenu) {
            menuItem.triggerExecute(this.item, event);
        }
    }

    private cancelEvent(event?: KeyboardEvent) {
        if (!event) {
            return;
        }

        const { target } = event;
        if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf((target as any)?.tagName) > -1 || (target as any)?.isContentEditable) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
    }
}