import { Component, AfterViewInit, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PuSchemaService } from '../../../utils/puzzle/pu.service/pu.schema.service';
import { AppService } from '../../../application/service/app.service';
import { GovEditor } from '../../../utils/view/model/form.editting';

@Component({
    selector: 'reportworkitemexe, [reportworkitemexe]',
    templateUrl: "./reportworkitemexe.component.html",
    styleUrls: ['./reportworkitemexe.component.scss'],
    providers: [PuSchemaService]
})
export class ReportWorkitemExeComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = false;
    readonly noinputtxt: boolean = true;

    get processName(): string | undefined {
        const { app: { prj: { projecttypes: { indexed } } }, selectedProcess } = this;
        return indexed[selectedProcess ?? '']?.name;
    }

    @Input('toolbar')
    toolbar?: GovEditor.ISetBinder;

    @Input('prjtypeid')
    get prjtypeid(): string | undefined {
        return this.selectedProcess;
    }

    set prjtypeid(val: string | undefined) {
        this.selectedProcess = val;
    }

    constructor(
        public app: AppService,
        public es: PuSchemaService,
        public override router: ActivatedRoute
    ) {
        super(router, 'reportworkitemexec', {
            get selectedProcess() {
                return app.prj.projecttypes[0]?.id
            }
        });

        const { app: { dict } } = this;
        this.selectedYear = undefined;
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    protected override dosearch(changed: GovEditor.ToolBar.Changed, content: GovEditor.ToolBar.Content): void {
        const { es } = this;

        const sub = es.update(content).subscribe({
            complete() {
                tick(() => sub?.unsubscribe())
            },
            next() {
                tick(() => sub?.unsubscribe())
            }
        });
    }
}
