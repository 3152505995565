<ng-template pu-mat-dialog-title>
  <div class="mb-0 d-flex flex-row flex-nowrap justify-content-start align-items-center">
    <div class="flex-fill">{{ "general.advfilter.management" | translate }}</div>
    <div ngbDropdown #filterDropDown="ngbDropdown" role="group" container="body" autoClose="true" display="dynamic"
      class="btn-group btn-group-sm pe-2">
      <button type="button" ngbDropdownToggle class="btn btn-sm d-flex list-group-horizontal align-items-center">
        <span class="d-flex flex-column align-items-end">
          <span>{{ searchFilter?.name }}</span>
          <small>
            <span class="pe-3">{{ searchFilter?.date| date : app.sys.dateFormat }}</span>
            <span>{{ searchFilter?.people?.name }}</span>
          </small>
        </span>
        <mat-icon>filter_alt</mat-icon>
      </button>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" (click)="filterDropDown.close();">
        <div class="vh-40" style="overflow: hidden auto !important; background-color: rgb(246 244 240);">
          <mat-selection-list (selectionChange)="searchFilter = $event.options[0].value" [multiple]="false">
            <ng-container *ngFor="let filter of searchFilters.array">
              <mat-list-option *ngIf="filter != searchFilter" ngbDropdownItem [value]="filter" class="mx-0">
                <span class="mat-chip" style="font-size: 14px">{{ filter.name }}</span>
              </mat-list-option>
            </ng-container>
          </mat-selection-list>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="overflow-hidden w-100 h-100 px-2 d-block">
  <gov-form class="overflow-hidden w-100 h-100 d-block flex-fill border badge" [readonly]="!isOwnded || !searchFilter"
    [datasets]="currentmodule"></gov-form>
</div>

<ng-template pu-mat-dialog-actions>
  <div mat-dialog-actions class="d-flex flex-row flex-nowrap justify-content-end align-items-stretch">

    <div *ngIf="isOwnded && isChanged">
      <button mat-button (click)="save()">
        {{ "general.save" | translate }}
      </button>
      <button mat-button (click)="cancel()">
        {{ "general.cancel" | translate }}
      </button>
    </div>

    <div *ngIf="isOwnded && !isChanged">
      <button mat-button (click)="create()">
        {{ "general.create" | translate }}
      </button>
      <button mat-button (click)="delete()">
        {{ "general.delete" | translate }}
      </button>
      <button mat-button (click)="close()">
        {{ "general.close" | translate }}
      </button>
    </div>

    <div *ngIf="!isOwnded || !searchFilter">
      <button mat-button (click)="create()">
        {{ "general.create" | translate }}
      </button>
      <button mat-button (click)="close()">
        {{ "general.close" | translate }}
      </button>
    </div>

  </div>
</ng-template>