import { Directive, Input, Output, EventEmitter, AfterViewChecked, OnDestroy, OnInit } from "@angular/core";

@Directive({
    selector: '[puInit],[puViewChecked],[puDestroy]'
})
export class PuInitDirective implements OnInit, AfterViewChecked, OnDestroy {

    @Input('fire')
    fire: boolean = true;

    @Output('puInit')
    readonly init: EventEmitter<any> = new EventEmitter();

    @Output('puViewChecked')
    readonly viewChecked: EventEmitter<any> = new EventEmitter();

    @Output('puDestroy')
    readonly destroy: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        if (this.fire) {
            this.init.emit();
        }
    }

    ngAfterViewChecked() {
        if (this.fire) {
            this.viewChecked.emit();
        }
    }

    ngOnDestroy(): void {
        if (this.fire) {
            this.destroy.emit()
        }
    }
}