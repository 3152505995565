import { InjectionToken } from '@angular/core';

export const NGX_CMENU_CONFIG = InjectionToken<'NGX_CMENU_CONFIG'>;

export interface NgxCMenuConfig {
    useBootstrap4?: boolean;
    autoFocus?: boolean;
}

export interface NgxCMenuLinkConfig {
    click: (item: any, $event?: MouseEvent) => void;
    enabled?: (item: any) => boolean;
    html: (item: any) => string;
}
