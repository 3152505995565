<ng-template pu-mat-dialog-title>{{'general.maptag' | translate}}</ng-template>

<ngx-amap class="h-100 w-100" [zoom]="10" [resizeEnable]="true" [center]="targetDistrict.targetDistrict?.center"
    [scrollWheel]="true" mapStyle="amap://styles/whitesmoke">
    <ngx-amap-tool-bar></ngx-amap-tool-bar>

    <ngx-amap-polygon #targetDistrict="ngxAMapPolygon" [district]="city_district" [strokeColor]="'#256edc'"
        [strokeOpacity]="1" [strokeWeight]="2" [fillColor]="'#256edc'" [fillOpacity]="0.15">
    </ngx-amap-polygon>

    <ngx-amap-info-window *ngIf="poi" [offset]="{ x: 0, y: -40 }" [position]="placeSearch?.poi?.position"
        [content]="placeSearch?.poi?.address" [isOpen]="placeSearch?.poi" [isCustom]="false" class="p-2">
        <div>{{ placeSearch?.poi?.address }}</div>
    </ngx-amap-info-window>

    <ngx-amap-placesearch #placeSearch="ngxAMapPlacesearch"
        class="maplocate w-25 d-flex flex-column align-items-stretch" [city]="city_district" [citylimit]="true"
        [pageIndex]="1" [pageSize]="4" [autoFitView]="true" [class.h-90]="!!placeSearch.locations">
        <input class="ngx-amap-placesearch-input w-100" placeholder="{{'general.mapinhint' | translate}}" />
        <div [class.d-none]="!!!placeSearch.locations"
            class="ngx-amap-placesearch-locations map-panel flex-fill overflow-auto w-100">
        </div>
    </ngx-amap-placesearch>

    <ngx-amap-positionpicker #positionPicker="ngxAMapPositionPicker" mode="dragMarker" [(poi)]="poi"
        [iconStyle]="picker_icon">
    </ngx-amap-positionpicker>
</ngx-amap>

<ng-template pu-mat-dialog-actions>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{'general.cancel' | translate}}</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="saveClick()">
            {{'general.save' | translate}}
        </button>
    </mat-dialog-actions>
</ng-template>