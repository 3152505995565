import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { PartialDeep } from 'type-fest';

import { WorkItemListComponent } from '../../../workitemlist/view/workitemlist.component';
import { ProblemListComponent } from '../../../problemlist/view/problemlist.component';
import { ProjectListComponent } from '../../../projectlist/view/projectlist.component';
import { PuSchemaService } from '../../../utils/puzzle/pu.service/pu.schema.service';
import { PuDialogService } from '../../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuSysService } from '../../../utils/puzzle/pu.service/pu.sys.service';
import { ResultsComponent } from '../../../results/view/results.component';
import { Prj as TPrj, Exec as TExec } from '../../service/backface/types';
import { Backface } from '../../service/backface/config';
import { AppService } from '../../service/app.service';
import { Report } from '../../service/backface/report';

@Component({
    templateUrl: "./frame.component.html",
    styleUrls: ['./frame.component.scss']
})
export class FrameComponent extends Backface.AsyncReport implements OnInit, OnDestroy {
    readonly _ = _;

    get slidemode(): 'over' | 'side' | 'push' {
        return localStorage['NaviSlideMode'] || 'side'
    }

    set slidemode(val: 'over' | 'side' | 'push') {
        localStorage['NaviSlideMode'] = val;
    }

    get slideopened(): boolean {
        localStorage['NaviSlideOpened'] = localStorage['NaviSlideOpened'] || 'true';

        try {
            return JSON.parse(localStorage['NaviSlideOpened']);
        } catch (e) { }

        return true;
    }

    set slideopened(val: boolean) {
        localStorage['NaviSlideOpened'] = val;
    }

    constructor(
        public override httpClient: HttpClient,
        public route: ActivatedRoute,
        public dialog: PuDialogService,
        public es: PuSchemaService,
        public sys: PuSysService,
        public app: AppService
    ) {
        super(httpClient);

        const appFrame = this;

        // register project sets for es usage
        const sub = es.resolve().subscribe({
            complete() {
                tick(() => sub?.unsubscribe());
            },
            next() {
                // register projectList action function into schemaservice
                es.actions['projectList'] = function (this: PuSchemaService['actions'], projects: PartialDeep<
                    TPrj.IProject
                >[], title: string, header: string = '') {
                    const injector = this?.$events?.current?.injector;

                    ProjectListComponent.open(dialog, {
                        prjidlist: projects,
                        header: header,
                        title: title,
                    }, injector);
                }

                // register workitemList action function into schemaservice
                es.actions['workitemList'] = function (this: PuSchemaService['actions'], workitem: {
                    workitemstatus?: (TPrj.IWorkitem.Status | string | undefined)[];
                    stageidlist?: (string | undefined)[];
                    prjidlist?: (string | undefined)[];
                }, title: string, header: string = '') {
                    const injector = this?.$events?.current?.injector;
                    workitem = workitem ?? {};

                    WorkItemListComponent.open(dialog, {
                        ...workitem,
                        header: header,
                        title: title,
                    }, injector);
                }

                // register problemList action function into schemaservice
                es.actions['problemList'] = function (this: PuSchemaService['actions'], problem: {
                    date?: [start: number | string | Date, end: number | string | Date],
                    type?: ('newfound' | 'notfix' | 'fixed')[],
                    prjidlist?: (string | undefined)[],
                    fixed?: boolean,
                }, title: string, header: string = '') {
                    const injector = this?.$events?.current?.injector;
                    problem = problem ?? {};

                    ProblemListComponent.open(dialog, {
                        ...problem,
                        header: header,
                        title: title,
                    }, injector);
                }

                // register problemList action function into schemaservice
                es.actions['superviseList'] = function (this: PuSchemaService['actions'], supervise: {
                    date?: [start: number | string | Date, end: number | string | Date],
                    project?: (string | undefined)[],
                }, title: string, header: string = '') {
                    const injector = this?.$events?.current?.injector;
                    supervise = supervise ?? {};

                    appFrame.report("/entity/results/retrieve", (res: TExec.IResult[] = []) => {
                        const { app, dialog } = appFrame;
                        ResultsComponent.open(dialog, {
                            object: app.prj.workitems.find(ws => ws.type == TPrj.IWorkitem.Type.supervise),
                            results: Report.buildResult(app, res),
                            title: `${title}/${header}`,
                        }, injector)
                    }).query({
                        ...supervise,
                        workitem: '77'
                    });
                }

                tick(() => sub?.unsubscribe());
            }
        })
    }

    ngOnInit() {
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    ngAfterViewInit() {
    }
}
