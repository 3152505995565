import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'safe'
}) export class PuSafePipe implements PipeTransform {
    private static default_pipe: PuSafePipe;
    private static injector: Injector;

    constructor(
        protected sanitizer: DomSanitizer
    ) {
    }

    transform(value: any, type: 'url'): SafeUrl;
    transform(value: any, type: 'html'): SafeHtml;
    transform(value: any, type: 'style'): SafeStyle;
    transform(value: any, type: 'script'): SafeScript;
    transform(value: any, type: 'res'): SafeResourceUrl;
    transform(value: any, type: string): SafeUrl | SafeHtml | SafeStyle | SafeScript | SafeResourceUrl;
    transform(value: any, type: string): SafeUrl | SafeHtml | SafeStyle | SafeScript | SafeResourceUrl {
        const { sanitizer } = this;

        switch (type) {
            case 'url': return sanitizer.bypassSecurityTrustUrl(value);
            case 'html': return sanitizer.bypassSecurityTrustHtml(value);
            case 'style': return sanitizer.bypassSecurityTrustStyle(value);
            case 'script': return sanitizer.bypassSecurityTrustScript(value);
            case 'res': return sanitizer.bypassSecurityTrustResourceUrl(value);
            default: throw new Error(`Invalid safe type specified: ${type}`);
        }
    }

    static transform(url: string, type: 'html' | 'res' | 'url' | 'script' | 'style' = 'html'): any {
        PuSafePipe.injector = PuSafePipe.injector || (Injector.NULL.get(PuSafePipe, null) && Injector.NULL);
        PuSafePipe.injector = PuSafePipe.injector || (Injector.create({
            providers: [{
                provide: PuSafePipe, deps: [DomSanitizer],
                useFactory(sanitizer: DomSanitizer) {
                    if (PuSafePipe.default_pipe) return PuSafePipe.default_pipe;
                    return (PuSafePipe.default_pipe = new PuSafePipe(sanitizer));
                }
            }]
        }));

        return PuSafePipe.injector.get(PuSafePipe).transform(url, type);
    }
}