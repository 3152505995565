import { map, Observable, of, ReplaySubject, zip } from "rxjs";
import { Injectable, NgZone } from "@angular/core";
import { O } from "ts-toolbelt";

import { AMapPluginLoaderService } from "../amap-loader-plugin.service";
import { NgxAMapCreaterService } from "./ngx-amap.creater.service";
import { AMapLoggerService } from "../amap-logger.service";

const TAG = 'AMapPlaceSearchCreater'

interface IPlaceSearch {
    autocomplete: AMap.Autocomplete,
    placesearch: AMap.PlaceSearch
}

@Injectable()
export class NgxAMapPlacesearchService {

    private readonly placesearch$ = new ReplaySubject<IPlaceSearch>(1);
    readonly placesearch?: IPlaceSearch;

    constructor(
        private readonly creater: NgxAMapCreaterService,
        private readonly plugin: AMapPluginLoaderService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    get(): Observable<IPlaceSearch> {
        return this.placesearch$;
    }

    create(placesearchoptions: AMap.PlaceSearch.Options, autocompleteoptions: AMap.Autocomplete.Options): Observable<IPlaceSearch> {
        const { creater, plugin, logger, ngZone } = this;
        const { map: amap } = placesearchoptions;

        return zip(amap ? of(amap) : creater.get(), plugin.load('AMap.PlaceSearch'), plugin.load('AMap.Autocomplete')).pipe(
            map(
                ([amap]) => {
                    const placesearch = ((this as O.Writable<NgxAMapPlacesearchService>).placesearch = {
                        placesearch: ngZone.runOutsideAngular(
                            () => new AMap.PlaceSearch({
                                ...placesearchoptions,
                                map: amap
                            })
                        ),
                        autocomplete: ngZone.runOutsideAngular(
                            () => new AMap.Autocomplete({
                                ...autocompleteoptions
                            })
                        )
                    });

                    logger.d(TAG, 'new placesearch created.');
                    this.placesearch$.next(placesearch);
                    this.placesearch$.complete();
                    return placesearch;
                }
            )
        );
    }

    destroy(): void {
        const { creater, logger } = this;
        zip(this.get()).subscribe(
            ([{ placesearch, autocomplete }]) => {
                logger.d(TAG, 'placesearch destroyed.');
                delete (this as O.Writable<NgxAMapPlacesearchService>).placesearch;
            }
        );
    }
}