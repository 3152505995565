import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { catchError, concatMap, finalize, Observable, of, shareReplay, zip } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EventEmitter, Type } from '@angular/core';

import { Exec as TExec, Sys as TSys, Prj as TPrj, App as TApp } from './types';
import { Editor } from '../../../utils/libs/editor';
import { Backface } from './config';
import { Org } from "./org";
import { Prj } from './prj';
import { Sys } from './sys';

const Value = Editor.Value;

export class Exec extends Editor.Editor<Exec, TExec.IExec> {//implements TExec.IExec {
    @JSON.Key()
    @Backface.Entity(() => Exec.ResourceType, "exec")
    @Editor.Field(Exec, Editor.Owneds('resource_types', {
        creator(this: Exec) {
            return new Exec.ResourceTypes(this.app);
        },
    })) readonly resource_types!: Exec.ResourceTypes;

    @Backface.Entity(() => Exec.Problem, "exec")
    readonly problems!: Exec.Problem[];

    @Backface.Entity(() => Exec.Result, "exec")
    readonly results?: Exec.Results;

    /*
    @JSON.Key()
    @Backface.Entity(() => Exec.Result, "exec")
    @Editor.Field(Exec, Editor.Type.owneds,
        Editor.Owneds(function (this: Exec) {
            return new Exec.Results(this.org, this.prj, this);
        }, 'results')
    ) readonly results: Exec.Results;

    @Backface.Entity(() => Exec.Problem, "exec")
    @Editor.Field(Exec, Editor.RefFlattens('problems', {
        children(this: Exec, obj: Exec | Exec.Result): Exec.Result[] | undefined {
            if (obj instanceof Exec) return obj.results;
            if (!obj) return;

            const { value } = obj;
            value?.$fields_?.forEach(
                ({ key }) => key && (value as any)[key]
            );

            return;
        }
    })) readonly problems!: Exec.Problem[];
    */

    constructor(
        public app: TApp.IAppService,
        setting?: TExec.IExec
    ) {
        super(setting);
    }
}

export namespace Exec {

    export class Result<
        T extends Result<T, TI> = Result<never>,
        TI extends TExec.IResult = TExec.IResult,
    > extends Editor.Editor<T, TI> {//implements TExec.IResult {
        private _props = Prop.Of<Result<T>, {
            lastsaving: ReturnType<Result<T>['$save_']>
        }>(this);

        get exec(): Exec {
            const { _props: props, app } = this;
            return props.exec || (
                props.exec = Sys.cast_exec(app.exec, Exec)
            )
        }

        get org(): Org {
            const { _props: props, app } = this;
            return props.org || (
                props.org = Sys.cast_org(app.org, Org)
            )
        }

        get prj(): Prj {
            const { _props: props, app } = this;
            return props.prj || (
                props.prj = Sys.cast_prj(app.prj, Prj)
            )
        }

        @JSON.Key()
        @Editor.Field(Result, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Result, Editor.RefOwned('project', {
            itemtype: () => Prj.Project,
            source(this: any) {
                const { prj } = (this as Result);
                return prj.project;
            },
        })) project!: Prj.Project;

        @JSON.Key()
        @Editor.Field(Result, Editor.RefOwned('stage', {
            itemtype: () => Prj.Stage,
            source(this: any) {
                const { projecttype } = (this as Result);
                return projecttype?.stages;
            },
            getter(this: any) {
                return () => {
                    const { workitem } = (this as Result);
                    const workset = workitem?.parent;
                    const parent = workset?.parent;
                    if (parent instanceof Prj.Stage) {
                        return parent;
                    }

                    return;
                }
            }
        })) stage?: Prj.ProjStage;

        @JSON.Key()
        @Editor.Field(Result, Editor.RefOwned('workset', {
            itemtype: () => Prj.Workset,
            source(this: any) {
                const { stage } = (this as Result);
                return stage?.worksets;
            },
            getter(this: any) {
                return () => {
                    const { workitem } = (this as Result);
                    const parent = workitem?.parent;
                    if (parent instanceof Prj.Workset) {
                        return parent;
                    }

                    return;
                }
            }
        })) workset?: Prj.ProjWorkset;

        @JSON.Key()
        @Editor.Field(Result, Editor.RefOwned('workitem', {
            itemtype: () => Prj.Workitem,
            source(this: any): Prj.Workitem<any>[] {
                const { _result: { projecttype: { id: ptid } = {}, stage: { id: sid } = {} } } = (this as Result);
                const { _result: { workset: { id: wsetid } = {}, workitem = {} } } = (this as Result);
                const { prj: { workitems: gworkitems } } = (this as Result);
                if (workitem instanceof Prj.Workitem) return [
                    // Type instantiation is excessively deep and possibly infinite.ts(2589)
                    workitem as any
                ];

                if (!workitem.type && workitem.id) {
                    workitem.type = (gworkitems.indexed[workitem.id] || gworkitems.find(
                        w => w.id == workitem.id
                    ))?.type
                }

                if (workitem.type == TPrj.IWorkitem.Type.supervise) {
                    return gworkitems.array as Prj.Workitem<any>[];
                }

                if (!ptid) {
                    return [];
                }

                const { projecttype } = (this as Result);
                return projecttype?.workitems ?? [];
            },
        })) workitem!: Prj.ProjWorkitem;

        @JSON.Key()
        @Editor.Field(Result, Editor.Owned('value', {
            itemtype: () => Editor.Dynamic,
            creator(this: any, item: { [key: string]: any }) {
                const { workitem: { checkpoints = [] } = {} } = (this as Result);
                return new Editor.Dynamic(item, this, checkpoints, Exec.FieldTypes);
            },
        })) value!: Editor.Dynamic<Result>;

        @JSON.Key()
        @Editor.Field(Result, [Editor.Type.owned, Value.Type.date])
        date!: Date;

        @JSON.Key()
        @Editor.Field(Result, Editor.RefOwned('people', {
            itemtype: () => Org.People,
            source(this: any) {
                const { org } = (this as Result);
                return org?.peoples;
            },
        })) people?: Org.People;

        @JSON.Key()
        @Editor.Field(Result, [Editor.Type.owned, Value.Type.enum, TSys.Result, TSys.Result.none])
        result!: TSys.Result;

        @JSON.Key()
        @Editor.Field(Result, [Editor.Type.owned, Value.Type.enum, TSys.Reason, TSys.Reason.none])
        reason!: TSys.Reason;

        @JSON.Key()
        get projecttype(): Prj.ProjProjectType | undefined {
            return this.project?.projecttype;
        }

        get pics(): Pics | undefined {
            return this.$props_.pics;
        }

        set pics(val: Pics) {
            this.$props_.pics = val;
        }

        get files(): XFiles | undefined {
            return this.$props_.files;
        }

        set files(val: XFiles) {
            this.$props_.files = val;
        }

        constructor(
            public app: TApp.IAppService,
            public _result: TExec.IResult
        ) {
            super(_result as TI);

            const { value } = this, { consumeByProject } = app;
            value.$exclude_['yearlyinvested'] = consumeByProject;
            value.$json.exclude['yearlyinvested'] = consumeByProject;
            value.$json.exclude['notused'] = true;
            value.$exclude_['notused'] = true;
        }

        override $save_(state?: { drilling?: boolean; }): boolean | Observable<boolean> {
            const { $eprops_: eprops, _props: props } = this;
            if (eprops.$saving_) return props.lastsaving;

            // wait all uploading pic/file to finish
            const uploads = [...(this.pics ?? []), ...(this.files ?? [])].map(
                (xfile) => xfile.uploaded
            ).filter(
                (e): e is EventEmitter<void> => !!e
            );

            if (uploads?.length > 0) {
                eprops.$saving_ = true;

                const _ret = (props.lastsaving = zip(...uploads).pipe(
                    concatMap((): Observable<boolean> => {
                        const ret = super.$save_(state);
                        return _.isBoolean(ret) ? of(ret) : ret;
                    }),
                    catchError((error: any): Observable<boolean> => {
                        eprops.$saving_ = false;
                        return of(false);
                    }),
                    finalize(() => {
                        tick(() => _sub.unsubscribe());
                    }),
                    shareReplay(1)
                )), _sub = _ret.subscribe();

                return _ret;
            }

            return props.lastsaving = super.$save_(state);
        }

        override $update_(value?: TI) {
            if (!value) return;

            super.$update_(value);
            const { project } = this;
            project?.$update_(value.project);
        }
    }

    export class Audit extends Editor.Editor<Audit, TExec.IAudit> {//implements TExec.IAudit {
        readonly exec: Exec;
        readonly org: Org;
        readonly prj: Prj;

        @JSON.Key()
        @Editor.Field(Audit, Editor.Type.owned)
        comment?: string;

        @JSON.Key()
        @Editor.Field(Audit, [Editor.Type.owned, Value.Type.date])
        date!: Date;

        @JSON.Key()
        @Editor.Field(Audit, [Editor.Type.owned, Value.Type.enum, TExec.IResult.Audit.Status, TExec.IResult.Audit.Status.auditting])
        status!: TExec.IResult.Audit.Status;

        @JSON.Key()
        @Editor.Field(Audit, Editor.RefOwned('people', {
            itemtype: () => Org.People,
            source(this: Audit) {
                return this.org?.peoples;
            },
        })) people?: Org.People;

        constructor(
            public result: AuditResult,
            public _audit: TExec.IAudit
        ) {
            super(_audit);

            this.exec = result.exec;
            this.org = result.org;
            this.prj = result.prj;
        }
    }

    export class AuditResult extends Result<AuditResult, TExec.IAuditResult> {//implements TExec.IAuditResult {
        @JSON.Key()
        @Editor.Field(AuditResult, Editor.Owned('audit', {
            itemtype: () => Audit,
            creator(this: AuditResult, item: TExec.IAudit) {
                return new Audit(this, item);
            },
        })) readonly audit!: Audit;

        get status(): TExec.IResult.Audit.Status {
            const { $props_: props } = this;
            return props.status!
        }

        get callback(): boolean {
            const { IResult: { Audit: { Status: { callback } } } } = TExec;
            return this.audit.status == callback;
        }

        set callback(val: boolean) {
            const { IResult: { Audit: { Status: { callback } } } } = TExec;
            this.audit.status = val ? callback : this.status;
        }

        constructor(
            public override app: TApp.IAppService,
            public override _result: TExec.IAuditResult
        ) {
            super(app, _result);

            const { audit: { status } = {} } = this;
            const { $props_: props } = this;
            props.status = status;
        }
    }

    @Editor.Collections(Result)
    export class Results extends XArray<Result, TExec.IResult, 'id'> {
        private _props = Prop.Of<Results, {
            map: Map<
                Prj.ProjWorkitem,
                Exec.Result[]
            >
        }>(this);

        constructor(
            public app: TApp.IAppService,
            results: TExec.IResult[] = []
        ) {
            super('id', ...results);
        }

        override isT(value: TExec.IResult | Result): value is Result {
            return value instanceof Result;
        }

        override construct(val: Result | TExec.IResult): Result {
            return val instanceof Result ? val : new Result(this.app, val);
        }

        override _onAddRemoved(removed?: Result[], added?: Result[]) {
            super._onAddRemoved?.(removed, added);

            (removed || []).forEach(r => {
                const workitem = r.workitem;
                workitem && this.perworkitem(workitem, true).remove(r);
            });

            (added || []).forEach(r => {
                const workitem = r.workitem;
                workitem && this.perworkitem(workitem, true).add([r]);
            });
        }

        perworkitem(item: Prj.ProjWorkitem, needcreate: boolean = false): Exec.Result[] {
            const { _props: cache } = this;
            const map = (cache.map = cache.map || new Map());
            if (!map.has(item) && needcreate) map.set(item, []);
            return map.get(item)!;
        }
    }
}

export namespace Exec {

    export class ResourceType extends Editor.Editor<ResourceType, TExec.IResourceType> {//implements TExec.IResourceType {
        @Editor.Field(ResourceType, Editor.Type.owned)
        readonly id!: number;

        @Editor.Field(ResourceType, Editor.Type.owned)
        readonly mime_type!: string;

        @Editor.Field(ResourceType, Editor.Type.owned)
        readonly content_type!: string;

        constructor(
            _resource_type: TExec.IResourceType = {}
        ) {
            super(_resource_type);
        }
    }

    @Editor.Collections(ResourceType)
    export class ResourceTypes extends XArray<ResourceType, TExec.IResourceType, 'id'> {
        constructor(
            public app: TApp.IAppService,
            _resource_types: TExec.IResourceType[] = []
        ) {
            super('id', ..._resource_types);
        }

        override isT(val: ResourceType | TExec.IResourceType): val is ResourceType {
            return val instanceof ResourceType;
        }

        override construct(val: ResourceType | TExec.IResourceType): ResourceType {
            return val instanceof ResourceType ? val : new ResourceType(val);
        }
    }

    export class Resource extends Editor.Editor<Resource, TExec.IResource> {//implements TExec.IResource {
        readonly exec: Exec;
        readonly org: Org;
        readonly prj: Prj;

        @JSON.Key()
        @Editor.Field(Resource, Editor.Type.owned)
        readonly id!: Prop.guid;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly digest!: string;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly url!: string;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly source!: string;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly size!: number;

        @Editor.Field(Resource, Editor.Type.owned)
        readonly duration!: number;

        @Editor.Field(Resource, Editor.RefOwned('resource_type', {
            itemtype: () => ResourceType,
            source(this: Resource) {
                return this.exec.resource_types;
            },
        })) readonly resource_type!: ResourceType;

        constructor(
            public parent: Pic | XFile,
            _resource: TExec.IResource = {}
        ) {
            super(_resource)
            this.exec = parent.exec;
            this.org = parent.org;
            this.prj = parent.prj;
        }
    }

    const fileItemHacks: Extract<Exclude<keyof FileItem, 'url'>, keyof Pic>[] = [
        '_prepareToUploading', 'onBuildForm', 'onComplete',
        'onProgress', 'onSuccess', 'onCancel', 'onError'
    ];

    const FileProp = Prop.Slot<{
        uploaded?: EventEmitter<void>,
        canupload: boolean,
    }>('FileItemProp');

    export class Pic extends Editor.Editor<Pic, TExec.IPic> {//implements TExec.IPic {
        readonly exec: Exec;
        readonly org: Org;
        readonly prj: Prj;

        @JSON.Key()
        @Editor.Field(Pic, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Pic, Editor.Type.owned)
        comment!: string;

        @JSON.Key()
        @Editor.Field(Pic, Editor.Owned('resource', {
            itemtype: () => Resource,
            creator(this: Pic, item: TExec.IResource) {
                return item && (new Resource(this, item))
            },
        })) resource!: Resource;

        get fileItem(): FileItem | undefined {
            const { $props_: props } = this;
            return props.fileItem;
        }

        set fileItem(val: FileItem | undefined) {
            const { $props_: props, $props_: { fileItem } } = this;

            if (val != fileItem && fileItem) {
                fileItemHacks.forEach(key => {
                    fileItem[key] = props[key] as any;
                });

                const fileProp = FileProp.Of(fileItem, true);
                delete fileProp?.uploaded;
                props.fileItem = undefined;
                props.url = undefined;
            }

            if (!val) return;

            const file = val._file;
            const windowURL = window.URL || window.webkitURL;
            props.url = windowURL?.createObjectURL?.(file);
            props.fileItem = val;

            const fileProp = FileProp.Of(val, true);
            fileProp.uploaded = new EventEmitter();
            fileItemHacks.forEach(key => {
                props[key] = val[key] as any;
                val[key] = this[key] as any;
            })
        }

        get url(): string | undefined {
            const { resource, $props_: props } = this;
            if (resource) {//location.origin + "/" + 
                return resource.url || resource.source;
            }

            return props.url;
        }

        get content_type(): string {
            return this.fileItem?.file.type?.split('/')[0] || this.resource?.resource_type?.content_type;
        }

        get mime_type(): string {
            return this.fileItem?.file.type?.split('/')[1] || this.resource?.resource_type?.mime_type;
        }

        get uploader(): FileUploader {
            return this.pics.uploader;
        }

        get uploaded(): EventEmitter<void> | undefined {
            const { fileItem } = this;
            const fileProp = FileProp.Of(fileItem, true);
            return fileProp?.uploaded;
        }

        constructor(
            public parent: Result,
            public pics: Pics,
            _pic: TExec.IPic = {}
        ) {
            super(_pic);

            this.org = parent.org;
            this.prj = parent.prj;
            this.exec = parent.exec;
            fileItemHacks.forEach(key => {
                this[key] = this[key].bind(this) as any;
            })

            this.fileItem = _pic.fileItem;
        }

        onLoaded(ev: Event) {
            const { fileItem } = this;
            if (!fileItem) { return; }

            if (ev.currentTarget instanceof HTMLVideoElement) {
                fileItem.formData = {
                    duration: ev.currentTarget.duration
                }
            }

            // start to upload
            const fileProp = FileProp.Of(fileItem, true);
            const { pics: { uploader } } = this;
            fileProp.canupload = true;
            uploader.uploadAll();
        }

        onBuildForm(form: FormData): any {
            const { $props_: props, fileItem, fileItem: { formData } = {} } = this;
            if (!(form && formData)) return;

            _.forEach(formData, (v, k) => {
                form.append(k, v);
            })

            return props.onBuildForm?.call(fileItem, form);
        }

        onSuccess(response: string | HttpResponse<any>, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            // successfully uploaded.
            this.resource = (response as HttpResponse<any>)?.body;
            this.uploaded?.emit();

            // clear file item related.
            const fileProp = FileProp.Of(fileItem, true);
            fileProp && (fileProp.uploaded = undefined);
            this.fileItem = undefined;

            return props.onSuccess?.call(fileItem, response, status, headers);
        }

        onError(response: string | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onError?.call(fileItem, response, status, headers);
        }

        onComplete(response: string | HttpResponse<any> | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onComplete?.call(fileItem, response, status, headers);
        }

        onCancel(response: string, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onCancel?.call(fileItem, response, status, headers);
        }

        onProgress(progress: number): any {
            const { $props_: props, fileItem } = this;

            return props.onProgress?.call(fileItem, progress);
        }

        _prepareToUploading(): void {
            const { $props_: props, fileItem } = this;
            const fileProp = FileProp.Of(fileItem, true);
            if (!fileProp?.canupload) return;

            props._prepareToUploading?.call(fileItem);
        }
    }

    @Editor.Collections(Pic)
    export class Pics extends XArray<Pic, TExec.IPic, 'id'> {
        private _props = Prop.Of(this);

        get uploader(): FileUploader {
            const { _props: props } = this;
            let uploader: FileUploader;

            return props.uploader || (uploader = props.uploader =
                new FileUploader({
                    itemAlias: "uploadedfile",
                    url: "/resource/upload",
                    removeAfterUpload: true,
                    autoUpload: false,
                    method: "POST",
                    filters: [{
                        name: 'media_filter',
                        fn: (
                            item?: FileLikeObject,
                            options?: FileUploaderOptions
                        ): boolean => {
                            if (this.parent.$saving_) {
                                return false;
                            }

                            return !this.exceedamount;
                        }
                    }]
                }),
                uploader.onAfterAddingFile = (item: FileItem): void => {
                    this.createnew({ fileItem: item });
                },
                uploader
            )
        }

        get exceedamount(): boolean {
            return this.length >= this.mediamaxamount;
        }

        get mediamaxamount(): number {
            const { parent: { app: { lang: { general: { mediamaxamount = 12 } = {} } } } } = this;
            return _.isString(mediamaxamount) ? Number.parseInt(mediamaxamount) : mediamaxamount;
        }

        constructor(
            public parent: Result,
            _pics: TExec.IPic[] = []
        ) {
            super('id', ..._pics);
            parent.pics = this;
        }

        override isT(val: Pic | TExec.IPic): val is Pic {
            return val instanceof Pic
        }

        override construct(val: Pic | TExec.IPic): Pic {
            return val instanceof Pic ? val : new Pic(this.parent, this, val);
        }

        override _onAddRemoved(removed?: Pic[], added?: Pic[]) {
            super._onAddRemoved?.(removed, added);

            const { uploader } = this;
            removed?.forEach(f => {
                const { fileItem } = f;
                fileItem && uploader.cancelItem(fileItem);
                fileItem && uploader.removeFromQueue(fileItem)
            });
        }
    }

    export class XFile extends Editor.Editor<XFile, TExec.IXFile> {//implements TExec.IXFile {
        readonly exec: Exec;
        readonly org: Org;
        readonly prj: Prj;

        @JSON.Key()
        @Editor.Field(XFile, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(XFile, Editor.Type.owned)
        comment!: string;

        @JSON.Key()
        @Editor.Field(XFile, Editor.Owned('resource', {
            itemtype: () => Resource,
            creator(this: XFile, item: TExec.IResource) {
                return item && (new Resource(this, item))
            },
        })) resource!: Resource;

        get fileItem(): FileItem | undefined {
            const { $props_: props } = this;
            return props.fileItem;
        }

        set fileItem(val: FileItem | undefined) {
            const { $props_: props, $props_: { fileItem } } = this;

            if (val != fileItem && fileItem) {
                fileItemHacks.forEach(key => {
                    fileItem[key] = props[key] as any;
                })

                const fileProp = FileProp.Of(fileItem, true);
                fileProp.uploaded = undefined;
                props.fileItem = undefined;
                props.url = undefined;
            }

            if (!val) return;

            const file = val._file;
            const windowURL = window.URL || window.webkitURL;
            props.url = windowURL?.createObjectURL?.(file);
            props.fileItem = val;

            const fileProp = FileProp.Of(val, true);
            fileProp.uploaded = new EventEmitter();
            fileItemHacks.forEach(key => {
                props[key] = val[key] as any;
                val[key] = this[key] as any;
            })
        }

        get url(): string | undefined {
            const { resource, $props_: props } = this;
            if (resource) {//location.origin + "/" + 
                return resource.url || resource.source;
            }

            return props.url;
        }

        get content_type(): string {
            return this.fileItem?.file.type?.split('/')[0] || this.resource?.resource_type?.content_type;
        }

        get mime_type(): string {
            return this.fileItem?.file.type?.split('/')[1] || this.resource?.resource_type?.mime_type;
        }

        get uploader(): FileUploader {
            return this.files.uploader;
        }

        get uploaded(): EventEmitter<void> | undefined {
            const { fileItem } = this;
            const fileProp = FileProp.Of(fileItem, true);
            return fileProp?.uploaded;
        }

        constructor(
            public parent: Result,
            public files: XFiles,
            _file: TExec.IXFile = {}
        ) {
            super(_file);

            this.org = parent.org;
            this.prj = parent.prj;
            this.exec = parent.exec;
            fileItemHacks.forEach(key => {
                this[key] = this[key].bind(this) as any;
            })

            this.fileItem = _file.fileItem;
        }

        onLoaded(ev?: Event) {
            const { fileItem } = this;
            if (!fileItem) { return; }

            if (ev?.currentTarget instanceof HTMLVideoElement) {
                fileItem.formData = {
                    duration: ev.currentTarget.duration
                }
            }

            // start to upload
            const fileProp = FileProp.Of(fileItem, true);
            const { files: { uploader } } = this;
            fileProp.canupload = true;
            uploader.uploadAll();
        }

        onBuildForm(form: FormData): any {
            const { $props_: props, fileItem, fileItem: { formData } = {} } = this;
            if (!(form && formData)) return;

            _.forEach(formData, (v, k) => {
                form.append(k, v);
            })

            return props.onBuildForm?.call(fileItem, form);
        }

        onSuccess(response: string | HttpResponse<any>, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            // successfully uploaded.
            this.resource = (response as HttpResponse<any>)?.body;
            this.uploaded?.emit();

            // clear file item related.
            const fileProp = FileProp.Of(fileItem, true);
            fileProp && (fileProp.uploaded = undefined);
            this.fileItem = undefined;

            return props.onSuccess?.call(fileItem, response, status, headers);
        }

        onError(response: string | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onError?.call(fileItem, response, status, headers);
        }

        onComplete(response: string | HttpResponse<any> | HttpErrorResponse, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onComplete?.call(fileItem, response, status, headers);
        }

        onCancel(response: string, status: number, headers: ParsedResponseHeaders): any {
            const { $props_: props, fileItem } = this;

            return props.onCancel?.call(fileItem, response, status, headers);
        }

        onProgress(progress: number): any {
            const { $props_: props, fileItem } = this;

            return props.onProgress?.call(fileItem, progress);
        }

        _prepareToUploading(): void {
            const { $props_: props, fileItem } = this;
            const fileProp = FileProp.Of(fileItem, true);
            if (!fileProp?.canupload) return;

            props._prepareToUploading?.call(fileItem);
        }
    }

    @Editor.Collections(XFile)
    export class XFiles extends XArray<XFile, TExec.IXFile, 'id'> {
        private _props = Prop.Of(this);

        get uploader(): FileUploader {
            const { _props: props } = this;
            let uploader: FileUploader;

            return props.uploader = props.uploader || (uploader = props.uploader =
                new FileUploader({
                    itemAlias: "uploadedfile",
                    url: "/resource/upload",
                    removeAfterUpload: true,
                    autoUpload: false,
                    method: "POST",
                    filters: [{
                        name: 'media_filter',
                        fn: (
                            item?: FileLikeObject,
                            options?: FileUploaderOptions
                        ): boolean => {
                            if (this.parent.$saving_) {
                                return false;
                            }

                            return !this.exceedamount;
                        }
                    }]
                }),
                uploader.onAfterAddingFile = (item: FileItem): void => {
                    const xfile = this.createnew({ fileItem: item });
                    xfile.onLoaded();
                },
                uploader
            )
        }

        get exceedamount(): boolean {
            return this.length >= this.mediamaxamount;
        }

        get mediamaxamount(): number {
            const { parent: { app: { lang: { general: { mediamaxamount = 12 } = {} } } } } = this;
            return _.isString(mediamaxamount) ? Number.parseInt(mediamaxamount) : mediamaxamount;
        }

        constructor(
            public parent: Result,
            _files: TExec.IXFile[] = []
        ) {
            super('id', ..._files);
            parent.files = this;
        }

        override isT(val: XFile | TExec.IXFile): val is XFile {
            return val instanceof XFile;
        }

        override construct(val: XFile | TExec.IXFile): XFile {
            return val instanceof XFile ? val : new XFile(this.parent, this, val);
        }

        override _onAddRemoved(removed?: XFile[], added?: XFile[]) {
            super._onAddRemoved?.(removed, added);

            const { uploader } = this;
            removed?.forEach(f => {
                const { fileItem } = f;
                fileItem && uploader.cancelItem(fileItem);
                fileItem && uploader.removeFromQueue(fileItem)
            });
        }
    }

    export class Problem extends Editor.Editor<Problem, TExec.IProblem> {//implements TExec.IProblem {
        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.numberid)
        auditid!: number;

        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.owned)
        problem!: string;

        @JSON.Key()
        @Editor.Field(Problem, [Editor.Type.owned, Value.Type.date])
        date!: Date;

        @JSON.Key()
        @Editor.Field(Problem, Editor.Type.owned)
        fixed!: boolean;

        get workitem(): Prj.ProjWorkitem {
            return this.parent.workitem;
        }

        get project(): Prj.Project {
            return this.parent.project;
        }

        constructor(
            public parent: Result,
            _problem: TExec.IProblem = {}
        ) {
            super((_problem.date = _problem.date || parent.date || new Date(), _problem));
        }
    }

    @Editor.Collections(Problem)
    export class Problems extends XArray<Problem, TExec.IProblem, 'id'> {
        readonly exec: Exec;
        readonly org: Org;
        readonly prj: Prj;

        constructor(
            public parent: Result,
            _problems: TExec.IProblem[] = []
        ) {
            super('id', ..._problems);
            this.exec = parent.exec;
            this.org = parent.org;
            this.prj = parent.prj;
        }

        override isT(val: Problem | TExec.IProblem): val is Problem {
            return val instanceof Problem;
        }

        override construct(val: Problem | TExec.IProblem): Problem {
            return val instanceof Problem ? val : new Problem(this.parent, val);
        }

        override _onAddRemoved(removed?: Problem[], added?: Problem[]) {
            super._onAddRemoved?.(removed, added);

            const { exec: { problems } } = this;
            removed && problems?.remove(...removed);
            added && problems?.add(added, 0);
        }
    }

    export const FieldTypes: {
        [P in Editor.Value.Type]?: Type<any>
    } = {
        [Value.Type.pic]: Pic,
        [Value.Type.pics]: Pics,
        [Value.Type.file]: XFile,
        [Value.Type.files]: XFiles,
        [Value.Type.problem]: Problem,
        [Value.Type.problems]: Problems
    }
}
