import { SimpleChanges } from "@angular/core";
import { Observable, EMPTY, of } from 'rxjs';

export function getOptions<TSrc extends {}, TDst extends {}>(src: TSrc, keys: Extract<keyof TSrc, keyof TDst>[]): TDst {
    return keys.reduce((dst, key) => {
        const val = src[key];
        if (val !== undefined && val !== null) {
            dst[key] = val as any;
        }

        return dst;
    }, <TDst>{});
}

export class ChangeFilter {
    constructor(
        public readonly changes: SimpleChanges
    ) {
    }

    static from(changes: SimpleChanges) {
        return new ChangeFilter(changes);
    }

    notEmpty(key: string): Observable<any> {
        const { changes } = this;
        if (changes[key]) {
            const value = changes[key].currentValue;
            if (value !== undefined && value !== null) {
                return of(value);
            }
        }

        return EMPTY;
    }

    has(key: string): Observable<any> {
        const { changes } = this;
        if (changes[key]) {
            const value = changes[key].currentValue;
            return of(value);
        }

        return EMPTY;
    }
}