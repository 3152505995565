import { Component, OnDestroy, ViewChild } from '@angular/core';

import { Event as BusEvent } from 'diagram-js/lib/core/EventBus';
import BaseViewer from 'bpmn-js/lib/BaseViewer';
import { Shape } from 'bpmn-js/lib/model/Types';

import { Exec as TExec, Sys as TSys, Prj as TPrj } from '../../application/service/backface/types';
import { GovEditorComponent } from '../../utils/view/gov.editor.component/gov.editor.component';
import { GovDiagramDirective } from '../../utils/view/diagram.directive/diagram.directive';
import { NgxCMenuComponent, NgxCMenuService } from '../../utils/ctxmenu/ngx.ctxmenu';
import { PuTemplateService } from '../../utils/puzzle/pu.template/pu.template';
import { Palette } from '../../utils/view/diagram.directive/palette.module';
import { AppService } from '../../application/service/app.service';
import { GovEditor } from '../../utils/view/model/form.editting';
import { Prj } from '../../application/service/backface/prj';
import { Differ } from '../../utils/libs/differ';
import { Editor } from '../../utils/libs/editor';

const Value = Editor.Value;

type Destroied = GovDiagramDirective.Destroied;
type Imported = GovDiagramDirective.Imported;
type Command = GovDiagramDirective.Command;

type Stage = {
    type: string;
    entity: string;
    bo: any;
}

@Component({
    templateUrl: "./process.component.html",
    styleUrls: ['./process.component.scss'],
    providers: [PuTemplateService]
})
export class ProcessComponent implements OnDestroy {
    private _props = Prop.Of<ProcessComponent, {
        differ: Differ<{
            contextMenuClosed: Differ.Event<void>
            shapeCommand: Differ.Event<Command>
            depstages: Differ.Event<void>
        }>
        contextMenus: {
            [k: string]: NgxCMenuComponent
        },
    }>(this, values => {
        values.differ = Differ.create();
    });

    @ViewChild(GovEditorComponent, { static: false })
    goveditor?: GovEditorComponent;

    @ViewChild('govstage', { read: NgxCMenuComponent, static: false })
    get contextMenuStage(): NgxCMenuComponent {
        const { _props: props } = this;
        return props.contextMenus?.['gov.stage']
    }

    set contextMenuStage(val: NgxCMenuComponent) {
        const { _props: props } = this;
        const contextMenus = (props.contextMenus || (
            props.contextMenus = {}
        ));

        contextMenus['gov.stage'] = val;
    }

    @ViewChild('govworkitem', { read: NgxCMenuComponent, static: false })
    get contextMenuWorkitem(): NgxCMenuComponent {
        const { _props: props } = this;
        return props.contextMenus?.['gov.workitem']
    }

    set contextMenuWorkitem(val: NgxCMenuComponent) {
        const { _props: props } = this;
        const contextMenus = (props.contextMenus || (
            props.contextMenus = {}
        ));

        contextMenus['gov.workitem'] = val;
    }

    get viewer(): BaseViewer | undefined {
        return this._props.viewer;
    }

    get projecttype(): Prj.PrjProjectType | undefined {
        return this._props.projecttype;
    }

    get diagram(): GovDiagramDirective | undefined {
        return this._props.diagram;
    }

    get shape(): Shape | undefined {
        return this._props.shape;
    }

    get readonly(): boolean {
        return false;
    }

    get toeditting(): GovEditor.ToEditting {
        const { _props: props } = this;
        return props.toeditting || (
            props.toeditting = this.editting.bind(this)
        );
    }

    get workitemModule(): TSys.IDatasetModule {
        const { _props: props, app } = this;
        const { set } = GovEditor.Editting;

        const module = (props.workitemModule || (
            props.workitemModule = { key: 'workitemmodule' },

            set(props.workitemModule, {
                form: {
                    noactionbar: true,
                    headers: XArray.create<TSys.IDatasetHeader, 'key'>('key', {
                        type: Value.Type.option, must: true,
                        source: "datasources.workitem",
                        title: 'workset.workitem',
                        key: "workitem.name"
                    }, {
                        type: Value.Type.bool, must: true,
                        title: 'workitem.critical',
                        key: "critical",
                    }),
                    editor: {
                        get workitem(): Prj.Workitem<Prj> | undefined {
                            const { shape } = props;
                            if (!shape) return;
                            if (shape['workitem']) {
                                return shape['workitem'];
                            }

                            const businessObject = Palette.getBizObject(shape);
                            if (!businessObject?.entity) return;

                            return shape['workitem'] = app.prj.workitems.firstOf({
                                id: businessObject.entity
                            })
                        },

                        set workitem(val: Prj.Workitem<Prj>) {
                            const { shape } = props;
                            if (!shape || shape['workitem'] == val) return;
                            shape['workitem'] = val;

                            const { diagram: { modeling } = {} } = props;
                            modeling?.updateProperties(shape, {
                                name: val?.name,
                                entity: val?.id
                            })
                        },

                        get critical(): boolean {
                            const { shape } = props;
                            const businessObject = Palette.getBizObject<Palette.IBizWorkitem>(shape);
                            return !!businessObject?.critical;
                        },

                        set critical(val: boolean) {
                            const { shape } = props;
                            if (!shape || shape['critical'] == val) return;
                            shape['critical'] = val;

                            const { diagram: { modeling } = {} } = props;
                            modeling?.updateProperties(shape, {
                                critical: val
                            })

                            modeling?.setColor([shape], {
                                stroke: val ?
                                    'rgb(244, 92, 4)' :
                                    'rgb(42, 42, 42)'
                            })
                        }
                    } as any
                }
            }),

            props.workitemModule
        ));

        return module;
    }

    get stageModule(): TSys.IDatasetModule {
        const { _props: props } = this, thisprocess = this;
        const { set } = GovEditor.Editting;

        const module = (props.stageModule || (
            props.stageModule = { key: 'stagemodule' },

            set(props.stageModule, {
                form: {
                    noactionbar: true,
                    headers: XArray.create<TSys.IDatasetHeader, 'key'>('key', {
                        title: 'stage.name',
                        key: "name",
                        must: true
                    }, {
                        title: 'stage.key',
                        key: "key",
                        must: false
                    }, {
                        type: Value.Type.options, must: true,
                        key: "depstage[,].bo.name",
                        title: 'stage.depstage',
                        source: "@stages",
                    }),
                    editor: {
                        get stages() {
                            return thisprocess.stages;
                        },
                        get depstage(): Stage[] | undefined {
                            const { shape, differ } = props;
                            const depstages = thisprocess.getDepStage(shape);
                            if (!shape || !depstages) return;

                            differ.extend({
                                depstages: Differ.Event.create(depstages.onAddRemoved, () => {
                                    const extensionElements = Palette.getBizObject(shape)?.extensionElements;
                                    const { diagram: { modeling } = {} } = props;
                                    modeling?.updateProperties(shape, {
                                        extensionElements
                                    })
                                })
                            })

                            return depstages;
                        },

                        get name(): string {
                            const { shape } = props;
                            const businessObject = Palette.getBizObject(shape);
                            return businessObject?.name ?? '';
                        },

                        set name(val: string) {
                            const { shape, } = props;
                            if (!shape || shape['name'] == val) return;
                            shape['name'] = val;

                            const { diagram: { modeling } = {} } = props;
                            modeling?.updateProperties(shape, {
                                name: val
                            })
                        },

                        get key(): string {
                            const { shape } = props;
                            const businessObject = Palette.getBizObject(shape);
                            return businessObject?.key ?? '';
                        },

                        set key(val: string) {
                            const { shape } = props;
                            if (!shape || shape['key'] == val) return;
                            shape['key'] = val;

                            const { diagram: { modeling } = {} } = props;
                            modeling?.updateProperties(shape, {
                                key: val
                            })
                        }
                    } as any
                }
            }),

            props.stageModule
        ))

        return module;
    }

    get stages(): Stage[] {
        const { _props: props, diagram: { elementRegistry } = {} } = this;
        if (props.stages) return props.stages;

        const stages = (props.stages = <Stage[]>[]);
        elementRegistry?.forEach((shape) => {
            const stage = this.getStage(shape as Shape);
            stage && stages.push(stage);
        })

        return stages;
    }

    get diagramEvent(): {
        selection: {
            changed(this: ProcessComponent, event: {
                type: "selection.changed";
                newSelection: Shape[];
                oldSelection: Shape[];
            }): void;
        },
        shape: {
            added(this: ProcessComponent, event: BusEvent & {
                type: "shape.added";
                element: Shape;
            }): void;
            removed(this: ProcessComponent, event: BusEvent & {
                type: "shape.removed";
                element: Shape;
            }): void;
        }
    } {
        const thisprocess = this, edittings: {
            [k: string]: GovEditor.IEditting | undefined
        } = {
            get ['gov.stage']() {
                return GovEditor.Editting.get(thisprocess.stageModule)
            },
            get ['gov.workitem']() {
                return GovEditor.Editting.get(thisprocess.workitemModule)
            }
        }

        const { _props: props } = this;
        return (props.diagramEvent || (props.diagramEvent = {
            selection: {
                changed(this: ProcessComponent, event: {
                    type: "selection.changed"
                    newSelection: Shape[]
                    oldSelection: Shape[]
                }) {
                    const { goveditor: { currentmodule } = {}, _props: props } = this;
                    const editting = GovEditor.Editting.get(currentmodule);
                    if (!editting) return;

                    const shape = event.newSelection[0];
                    const form = editting.form as GovEditor.IForm;
                    const businessObject = Palette.getBizObject(shape);
                    const shapeeditting = edittings[businessObject?.type ?? ''];
                    props.shape = shape;

                    if (!shapeeditting) {
                        // remove the shape editting.
                        if (GovEditor.isSection(form)) {
                            return;
                        }

                        editting.form = form.sections[0];
                        return;
                    }

                    // append the shape editting
                    const shapeform = shapeeditting.form as GovEditor.ISection;
                    if (GovEditor.isSection(form)) {
                        editting.form = { sections: [form, shapeform] }
                        return;
                    }

                    form.sections[1] = shapeform;
                }
            },
            shape: {
                added(this: ProcessComponent, event: BusEvent & {
                    type: "shape.added"
                    element: Shape
                }) {
                    const { element: shape } = event;
                    const businessObject = Palette.getBizObject(shape);
                    const { type } = businessObject || {};
                    if (type !== 'gov.stage') return;

                    // reset the stages
                    const { _props: props } = this;
                    props.stages = undefined;
                },
                removed(this: ProcessComponent, event: BusEvent & {
                    type: "shape.removed"
                    element: Shape
                }) {
                    const { element: shape } = event;
                    const businessObject = Palette.getBizObject(shape);
                    const { type } = businessObject || {};
                    if (type !== 'gov.stage') return;

                    // reset the stages
                    const { _props: props, diagram: { elementRegistry } = {} } = this;
                    props.stages = undefined;

                    // remove the reference from stage's depstage.
                    const stage: Stage | undefined = this.getStage(shape, false);
                    if (!stage) return;

                    elementRegistry?.forEach((_shape) => {
                        this.getDepStage(_shape as Shape, false)?.remove(stage);
                    })
                }
            }
        }));
    }

    constructor(
        public app: AppService,
        public contextMenuService: NgxCMenuService
    ) {
        this.editting = this.editting.bind(this);
    }

    ngOnDestroy(): void {
        const { _props: { differ } } = this;
        differ?.clear();
    }

    getStage(shape: Shape, force: boolean = true): Stage | undefined {
        const businessObject = Palette.getBizObject(shape);
        const { type, entity } = businessObject || {};
        if (type !== 'gov.stage') return;

        const { diagram: { moddle } = {} } = this;
        if (!force) return shape['stage'];

        const stage: Stage = (shape['stage'] || (
            shape['stage'] = moddle?.create('gov:DepStage'),
            shape['stage'].bo = businessObject,
            shape['stage'].entity = entity,
            shape['stage'].type = type,
            shape['stage']
        ));

        return stage;
    }

    getDepStage(shape?: Shape, force: boolean = true): Stage[] | undefined {
        if (!shape) return;

        const businessObject = Palette.getBizObject<Palette.IBizStage>(shape);
        const type = businessObject?.type;

        if (!businessObject || type !== 'gov.stage') return;
        if (!force || shape['depstages']) {
            return shape['depstages'];
        }

        const { diagram: { moddle, elementRegistry } = {} } = this;
        const extensionElements = (businessObject.extensionElements || (
            businessObject.extensionElements = moddle?.create('bpmn:ExtensionElements')
        ));

        const depstages: (Stage | undefined)[] = (extensionElements.get('values') ?? []);
        for (let idx = 0, cnt = depstages.length; idx < cnt; idx++) {
            const { entity } = depstages[idx] || {};

            const _shape: Shape = entity && elementRegistry?.find((s) => {
                return Palette.getBizObject(s)?.entity == entity;
            }) as any;

            depstages[idx] = this.getStage(_shape);
        }

        return shape['depstages'] = depstages.filter((v): v is Stage => v != undefined);
    }

    onCurrentModule(module: TSys.IDatasetModule) {
        const { app: { datasources: { projecttypedefine } } } = this

        switch (module) {
            case projecttypedefine:
                break;
        }
    }

    editting(obj: Prj.ProjWorkitem, def?: ((obj: object) => GovEditor.IEditting)): GovEditor.IEditting | undefined {
        const { app: { datasources: { projecttypedefine } } } = this
        const { goveditor: { currentmodule } = {} } = this;
        const editting = def?.(obj);

        if (currentmodule !== projecttypedefine) {
            return editting;
        }

        return editting;
    }

    onDiagramImported(event: {
        entity: any
    } & Imported) {
        const { _props: props, _props: { differ } } = this;
        const { entity, diagram: _diagram, viewer: _viewer } = event;

        const projecttype: Prj.PrjProjectType = (props.projecttype = entity);
        const diagram = (props.diagram = _diagram);
        const viewer = (props.viewer = _viewer);
        props.stages = undefined;

        // set the projecttype id and gov marker
        const definition = viewer.getDefinitions();
        definition.id = `sid-${projecttype.id}`;
        definition.type = 'gov.projecttype';
        definition.entity = projecttype.id;

        // set the diagram event handlers
        const { command } = diagram;
        const { diagramEvent } = this;
        diagram.on(diagramEvent, this);

        differ.extend({
            shapeCommand: Differ.Event.create(command, ({ command, event, target }: Command) => {
                event.stopPropagation(), event.preventDefault();
                if (command !== 'gov.setting') return;

                const { contextMenus } = props;
                const bussinessObject = Palette.getBizObject(target);
                const govType = bussinessObject?.type ?? '';
                const contextMenu = contextMenus?.[govType];
                if (!contextMenu) return;

                differ.extend({
                    contextMenuClosed: Differ.Event.create(contextMenu.close, () => {
                    })
                })

                const { contextPad } = diagram;
                const { contextMenuService } = this;
                const pad = contextPad.getPad(target).html as Element;
                props.shape = target as Shape;
                contextMenuService.show.next({
                    contextMenu: contextMenu,
                    anchorElement: pad,
                    event: event,
                    item: target,
                });
            })
        })
    }

    onDiagramDestroied(event: {
        entity: any
    } & Destroied) {
        const { diagramEvent, diagram, _props: { differ } } = this;
        differ.extend({ shapeCommand: undefined });
        diagram?.off(diagramEvent);

        window.tick(() => {
            diagramEvent.selection.changed.call(this, {
                type: "selection.changed",
                newSelection: [],
                oldSelection: [],
            })
        })

        const { _props: props } = this;
        props.projecttype = undefined;
        props.diagram = undefined;
        props.viewer = undefined;
        props.differ.clear();
    }
}
