<table id="sumongoingproject" class="summery h-100 w-100 d-flex flex-column align-items-stretch justify-content-start">
    <div class="w-100 d-flex flex-row justify-content-between align-items-center"
        style="margin-top: 10px; margin-bottom: 10px">
        <gov-statusbar style="visibility: hidden"></gov-statusbar>
        <table>
            <tr>
                <th style="text-align: center; font-size: 1.2em; white-space: nowrap">
                    {{ "general.orgname" | translate }}{{ selectedYear }}{{ "general.annual" | translate }}{{
                        app.dict.PrjPool.indexed[_.get(searcher, 'pool') ?? '']?.title
                    }}{{ "summary.ongoingprjtable" | translate }}
                </th>
            </tr>
        </table>
        <gov-statusbar style="visibility: hidden"></gov-statusbar>
    </div>
    <table class="summery min-w-100" mat-table [dataSource]="dataSource">
        <ng-container *ngFor="let sumitem of sumFields; index as i" matColumnDef="{{ sumitem.key }}">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="getColspan(1, i)">
                {{ (_.isFunction(sumitem.title) ? sumitem.title(app, sumitem) : (sumitem.title ?? '')) | translate }}
            </th>
        </ng-container>

        <ng-container *ngFor="let sumitem of sumFields2; index as m" matColumnDef="{{ sumitem.key }}">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="getColspan(2, m)">
                {{ sumitem.title| translate }}
            </th>
        </ng-container>

        <ng-container *ngFor="let col of colFields.array; index as j" matColumnDef="{{ col.key }}">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="getColspan(3, j)">
                {{ (_.isFunction(col.title) ? col.title(app, col) : (col.title ?? '')) | translate }}
            </th>
            <td mat-cell [class]="col.cellcls" *matCellDef="let element; index as k" [attr.colspan]="getColspan(3, j)">
                {{ col.key == "id" ? k + 1 : fielder.getCellText(element, col.key, colFields) }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="sumdisplayedColumns; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="sum2displayedColumns; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                {{ "general.searchnone" | translate }}
            </td>
        </tr>
    </table>
</table>