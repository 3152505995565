import { Component, AfterViewInit, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PuSchemaService } from '../../../../app/utils/puzzle/pu.service/pu.schema.service';
import { Prj as TPrj, Sys as TSys } from '../../../application/service/backface/types';
import { GovEditor } from "../../../../app/utils/view/model/form.editting";
import { AppService } from '../../../application/service/app.service';

@Component({
    selector: 'reportgeneral, [reportgeneral]',
    templateUrl: "./reportgeneral.component.html",
    styleUrls: ['./reportgeneral.component.scss'],
    providers: [PuSchemaService]
})
export class ReportGeneralComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit {
    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = false;
    readonly noinputtxt: boolean = true;

    get searcherPool(): {
        key: string;
        value: TPrj.Pool;
        title?: string;
    } | undefined {
        const { app: { dict: { PrjPool } } } = this;
        const def = PrjPool.indexed[_.get(this.searcher, 'pool') ?? ''];
        return def ? def as any : undefined;
    }

    @Input('toolbar')
    toolbar?: GovEditor.ISetBinder;

    @Input('module')
    // @ts-ignore
    module: TSys.IDatasetModule;

    constructor(
        public app: AppService,
        public es: PuSchemaService,
        public override router: ActivatedRoute
    ) {
        super(router, 'reportgeneral', {
            selectedYear: app.dict.currentYear
        });
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    override dosearch(changed: GovEditor.ToolBar.Changed, content: GovEditor.ToolBar.Content): void {
        const { es } = this;

        if (_.isObject(changed.searcher) && changed.searcher.pool != null) {
            const param = es.transParam(content);
            const poolkey = param.pool[0];

            es.used.clear();
            const dbkey = `reportgeneral${poolkey ? '_' : ''}${poolkey ?? ''}`;
            _.noop(es.dashboards[dbkey]);
        }

        const sub = es.update(content).subscribe({
            complete() {
                tick(() => sub?.unsubscribe())
            },
            next() {
                tick(() => sub?.unsubscribe())
            }
        });
    }
}
