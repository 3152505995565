import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppService } from '../../application/service/app.service';
import { Sys } from '../../application/service/backface/types';
import { Prj } from '../../application/service/backface/prj';
import { NgxAMapModule } from '../../utils/amap/ngx.amap';

@Component({
    templateUrl: "./maplocate.component.html",
    styleUrls: ['./maplocate.component.scss']
})
export class MaplocateComponent implements OnInit {
    private _props = Prop.Of<MaplocateComponent, {
        placeSearch?: AMap.PlaceSearch,
        _init: Promise<boolean>,
    }>(this);

    get project(): Prj.Project {
        return this.data.object
    }

    get mark_icon(): AMap.IIcon {
        const { _props: props, app: { version: { server: { version } = {} } } } = this;

        return props.mark_icon || (props.mark_icon = {
            image: `assets/img/map/location-red.png?version=${version}`,
            size: [48, 48],
        });
    }

    get picker_icon(): AMap.PositionPicker.Icon.Style {
        const { _props: props, app: { version: { server: { version } = {} } } } = this;

        return props.picker_icon || (props.picker_icon = {
            url: `/assets/img/map/position-picker2.png?version=${version}`,
            ancher: [24, 40],
            size: [48, 48]
        });
    }

    readonly city_district: string = "610722";
    readonly city_position: string[] = ["107.333545", "33.156308"];
    readonly city_address: string = "陕西省汉中市城固县博望街道民主街33号城固县人民政府";

    poi?: AMap.Poi;

    constructor(
        public readonly app: AppService,

        @Inject(MAT_DIALOG_DATA)
        public data: {
            object: Prj.Project,
            header: Sys.IDatasetHeader,
            dataset: Sys.IDatasetModule
        }
    ) {
        const { app: { lang: { general } } } = this;

        this.city_district = general.city_district || this.city_district;
        const city_position = (this.city_position = general.city_position || this.city_position);
        const city_address: string = (this.city_address = general.city_address || this.city_address);

        const postion = this.project.position?.[0]?.postion;
        const detail = this.project.position?.[0]?.detail;
        if (postion || detail) {
            this.poi = {
                location: (postion?.length ?? 0) == 2 ? postion : city_position,
                address: detail ?? city_address
            }
        }
    }

    ngOnInit() {
    }

    saveClick() {
        const { data: { object: project }, poi: { location, address } = {} } = this;
        const positionToSave = (location instanceof AMap.LngLat ? location.toString().split(',') : location)?.map(v => v.toString());

        const position = (project.position = [{
            postion: positionToSave,
            detail: address,
            district: ''
        }])[0];

        NgxAMapModule.districtFromPoi(location).subscribe(district => {
            position.district = district || '';
        })
    }
}
