<ng-content></ng-content>
<ng-container *ngFor="let panel of panels; index as index; last as last">
  <div
    puSplitterBar
    [order]="index * 2 + 1"
    *ngIf="panel.visible && nextVisiblePanel(index + 1)"
    (mousedown)="startDragging($event, panel, nextVisiblePanel(index + 1)!)"
    (dragstart)="startDragging($event, panel, nextVisiblePanel(index + 1)!)"
    (touchstart)="startDragging($event, panel, nextVisiblePanel(index + 1)!)"
  ></div>
</ng-container>
