import { Inject, Injectable, InjectionToken, OnDestroy, Optional } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, take, tap } from 'rxjs';

import { Async } from "../../libs/async";

const ForceGet = new HttpHeaders()
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache');

@Injectable({ providedIn: 'root' })
export class PuVersioinService implements Resolve<object>, OnDestroy {
    private _props = Prop.Of(this);

    get local(): PuVersioinService.IConfig | undefined {
        return this.versionCurrent;
    }

    get server(): PuVersioinService.IConfig | undefined {
        const { _props: { server } } = this;
        return server;
    }

    get fetcher(): Observable<PuVersioinService.IConfig> {
        const { _props: props, httpClient } = this

        if (!props.fetcher) {
            props.fetcher = new Async.Cache<PuVersioinService.IConfig>({
                query: (): Observable<PuVersioinService.IConfig> => {
                    const { versionUrl: url = '/assets/json/config.json' } = this;
                    return httpClient.get<PuVersioinService.IConfig>(url, {
                        headers: ForceGet
                    }).pipe(
                        tap((v) => {
                            props.server = v;
                        })
                    );
                }
            });
        }

        return props.fetcher;
    }

    constructor(
        private httpClient: HttpClient,

        @Optional()
        @Inject(PuVersioinService.VERSION_CURRENT)
        private versionCurrent?: PuVersioinService.IConfig,

        @Optional()
        @Inject(PuVersioinService.VERSION_URL)
        private versionUrl?: string
    ) {
        this.versionUrl = versionUrl || undefined;
        this.versionCurrent = versionCurrent || undefined;
        Promise.resolve().then(() => {
            const { _props: props, fetcher } = this;

            fetcher.subscribe((config: PuVersioinService.IConfig) => {
                props.server = config;
            })
        })
    }

    ngOnDestroy() {
        (this._props.fetcher as Async.Cache<any>)?.finish();
    }

    resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
        const { fetcher } = this;
        return fetcher;
    }
}

export namespace PuVersioinService {
    export interface IConfig {
        version?: string;
    }

    export const VERSION_CURRENT: InjectionToken<IConfig> = new InjectionToken('currentversion');
    export const VERSION_URL: InjectionToken<string> = new InjectionToken('versionurl');
}
