import { Component, Host, HostBinding, Input, Optional } from '@angular/core';

import { PuSplitterComponent } from './pu.splitter.component';
import { PuSplitter } from './pu.splitter.types';

@Component({
    templateUrl: "./pu.splitter.bar.component.html",
    styleUrls: ["./pu.splitter.bar.component.scss"],
    selector: "puSplitterBar, [puSplitterBar]",
    exportAs: "PuSplitterBar",
}) export class PuSplitterBarComponent {
    private props = Prop.Of(this);

    @Input('order')
    @HostBinding('style.order')
    order: number = 0;

    @Input("width")
    @HostBinding('style.flex-basis.px')
    get width(): number {
        const { props: { width = 5 }, splitter: { splitterBarSize } = {} } = this;
        return splitterBarSize ?? width;
    };

    set width(v: number | undefined) {
        this.props.width = v;
    }

    @Input("direction")
    @HostBinding('class')
    get direction(): PuSplitter.direction {
        const { props: { direction = 'vertical' }, splitter: { direction: splitterBarDirection } = {} } = this;
        return splitterBarDirection ?? (direction === 'vertical' ? 'vertical' : 'horizontal');
    }

    set direction(v: PuSplitter.direction | undefined) {
        this.props.direction = v;
    }

    @HostBinding('style.height')
    get gutter() {
        const { direction, width } = this;

        // fix safari bug about gutter height when direction is horizontal
        return (direction === 'vertical') ? `${width}px` : `100%`
    }

    get isvertical(): boolean {
        return this.direction == 'vertical'
    }

    constructor(
        @Host() @Optional()
        public splitter?: PuSplitterComponent
    ) {
    }
}
