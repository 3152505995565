import 'lodash';
import './prop';
import './object';
import './string';
import './lodash';
import './array';
import './dom';
import './event';
import './json';
import './date';

export module Ployfill {
    export function polyfills(wnd: Window) {
        if (wnd === window) {
            return;
        }

        Prop.polyfill(wnd);
        window.Event.polyfill(wnd);
        String.polyfill(wnd);
        Object.polyfill(wnd);
        Array.polyfill(wnd);
        Date.polyfill(wnd);
        JSON.polyfill(wnd);
        DOM.polyfill(wnd);
    }
}