import { Shape, Element, Moddle, } from "bpmn-js/lib/model/Types";
import { ModuleDeclaration } from 'bpmn-js/lib/BaseViewer';

import DirectEditing from 'diagram-js-direct-editing/lib/DirectEditing';
import EventBus from 'diagram-js/lib/core/EventBus.js';
import Canvas from 'diagram-js/lib/core/Canvas';

import DirectEditingModule from 'diagram-js-direct-editing';

import { GovDiagramDirective } from './diagram.directive';
import { Palette } from './palette.module';


export class EdittingHook extends DirectEditing {
    constructor(
        public diagram: GovDiagramDirective,
        public eventbus: EventBus,
        public canvas: Canvas
    ) {
        super(eventbus, canvas)
    }

    override activate(shape: Shape): boolean {
        const { diagram: { entries: { entries }, readonly } } = this;
        const entry = entries[Palette.getBizObject(shape)?.type ?? ''];

        if (readonly || (entry && !(entry.editable ?? true))) {
            return false;
        }

        return super.activate(shape);
    }

    static Module: ModuleDeclaration = {
        ...DirectEditingModule,
        directEditing: ['type', EdittingHook],
        __init__: ['directEditing'],
    }

    static override $inject = [
        'govDiagram',
        ...DirectEditing.$inject
    ];
}
