import { PartialDeep } from 'type-fest';

const defkey = Prop.newSymbol('DEFBIND');
const BindSlot = Prop.Slot<WeakMap<any, any>>(
    'BIND', () => {
        return new WeakMap();
    }
)

const PolyfillSlot = Prop.Slot<{ object?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);
    const Object: ObjectConstructor = (wnd as any)['Object'];
    if (!Object || slot.object) return;
    slot.object = true;

    Object.defineProperties(Object.prototype, {
        $cid: {
            enumerable: false, configurable: false,
            get(this: Object): string {
                return Prop.namedidx(this);
            }
        },
        forEach: {
            writable: true, enumerable: false, configurable: false,
            value<T extends object, K extends Extract<keyof T, string>>(this: T, callbackfn: (value: T[K], key: K, obj: T) => void, thisArg?: any): void {
                for (const k in this) {
                    if (this.hasOwnProperty(k)) {
                        callbackfn.call(thisArg, (this as any)[k], k as K, this);
                    }
                }
            }
        },
        reduce: {
            writable: true, enumerable: false, configurable: false,
            value<U, T extends object, K extends Extract<keyof T, string>>(this: T, callbackfn: (previousValue: U, currentValue: T[K], key: K, object: T) => U, initialValue: U, thisArg?: any): U {
                for (const k in this) {
                    if (this.hasOwnProperty(k)) {
                        initialValue = callbackfn.call(thisArg, initialValue, (this as any)[k], k as K, this);
                    }
                }

                return initialValue;
            }
        },
        match: {
            writable: true, enumerable: false, configurable: false,
            value<T>(this: T, attrs: PartialDeep<T> | ((item: T) => boolean)): boolean {
                // no filter condition, return matched.
                if (!attrs) return true;

                if (!_.isObject(this)) return false;

                if (_.isFunction(attrs)) {
                    return attrs(this);
                }

                return _.isMatchWith(this, <object>attrs, (value: any, other: any): boolean | undefined => {
                    if (other === undefined) return true;

                    if (_.isArray(value) || !_.isArray(other)) {
                        return;
                    }

                    for (other of other) {
                        if (_.isMatch(value, other)) {
                            return true;
                        }
                    }

                    return false;
                });
            }
        },

        unbind: {
            writable: true, enumerable: false, configurable: true,
            value(this: Object, key?: any): void {
                BindSlot.Of(this, true)?.delete(key ?? defkey);
            }
        },

        bind: {
            writable: true, enumerable: false, configurable: true,
            value<T>(this: Object, obj?: T | null, key?: any): void {
                BindSlot.Of(this, true)?.set(key ?? defkey, obj);
            }
        },

        unbind2Way: {
            writable: true, enumerable: false, configurable: true,
            value(this: Object, key?: any): void {
                BindSlot.Of(this.bindOf(key ?? defkey), true)?.delete(key ?? defkey);
                BindSlot.Of(this, true)?.delete(key ?? defkey);
            }
        },

        bind2Way: {
            writable: true, enumerable: false, configurable: true,
            value<T extends object>(this: Object, obj?: T | null, key?: any): void {
                this.unbind2Way(key ?? defkey);
                obj?.unbind2Way(key ?? defkey);
                this.bind(obj, key ?? defkey);
                obj?.bind(this, key ?? defkey);
            }
        },

        bindOf: {
            writable: true, enumerable: false, configurable: true,
            value<T>(this: Object, key?: any): T | undefined {
                return BindSlot.Of(this, true)?.get(key ?? defkey);
            }
        }
    })

    Object.defineProperties(Object, {
        polyfill: {
            configurable: false, writable: false,
            value(wnd: Window) {
                _polyfill(wnd);
            }
        },
        binder: {
            writable: true, enumerable: false, configurable: true,
            value(key?: string | symbol): Object.IBinder {
                key = key ?? Object.create(null);

                return {
                    bindOf<T = any, TObj extends object = any>(obj?: TObj | null): T | undefined {
                        return obj?.bindOf(key);
                    },
                    bind<Ta extends object, Tb>(obja?: Ta | null, objb?: Tb | null): void {
                        obja?.bind(objb, key);
                    },
                    bind2Way<Ta extends object, Tb extends object>(obja?: Ta | null, objb?: Tb | null): void {
                        (obja ? obja : objb)?.bind2Way((obja ? objb : obja), key);
                    },
                    unbind2Way<T extends object>(obj?: T | null): void {
                        obj?.unbind2Way(key);
                    },
                    unbind<T extends object>(obj?: T | null): void {
                        obj?.unbind(key);
                    }
                }
            }
        }
    })
}

_polyfill(window);

export { };

declare global {
    namespace Object {
        export interface IBinder {
            bind<Ta extends object, Tb>(obja?: Ta | null, objb?: Tb | null): void;
            bindOf<T = any, TObj extends object = any>(obj?: TObj | null): T | undefined;
            bind2Way<Ta extends object, Tb extends object>(obja?: Ta | null, objb?: Tb | null): void;
            unbind2Way<T extends object>(obj?: T | null): void;
            unbind<T extends object>(obj?: T | null): void;
        }
    }

    interface Object {
        readonly $cid: string;

        unbind(key?: any): void;

        unbind2Way(key?: any): void;

        bindOf<T>(key?: any): T | undefined;

        bind<T>(obj?: T | null, key?: any): void;

        bind2Way<T extends object>(obj?: T | null, key?: any): void;

        match<T>(this: T, attrs: PartialDeep<T> | ((item: any) => boolean)): boolean;

        forEach<T, K extends keyof T>(this: T, callbackfn: (value: T[K], key: K, obj: T) => void, thisArg?: any): void;

        reduce<U, T, K extends keyof T>(this: T, callbackfn: (previousValue: U, currentValue: T[K], key: K, object: T) => U, initialValue: U, thisArg?: any): U;
    }

    interface ObjectConstructor {
        polyfill(wnd: Window): void;
        binder(key?: string | symbol): Object.IBinder
    }
}