import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

import { Differ } from "../../libs/differ";

@Directive({
    selector: `[puEventStop]`
}) export class PuEventStopDirective implements OnDestroy {
    private _props = Prop.Of<PuEventStopDirective, {
        runPropagation: boolean,
        runDefault: boolean,
        differ: Differ<{
            onevent: Differ.Event<Event>
        }>,
    }>(this, (values) => {
        values.differ = Differ.create();
    });

    @Output(`puEventStop`)
    get onEventStop(): EventEmitter<Event> {
        return this._props.onEventStop || (
            this._props.onEventStop = new EventEmitter()
        )
    }

    @Input(`puEventStop`)
    set eventStop(eventname: string) {
        const { _props: props, el: { nativeElement: elm } } = this;
        const { _props: { eventStop, differ } } = this;
        if (eventname == eventStop) return;
        props.eventStop = eventname;

        differ.extend({
            onevent: eventname ? Differ.Event.create(
                Differ.Event.fromEvent<Event>(elm, eventname),
                function (this: PuEventStopDirective, ev: Event) {
                    if (this.stopPropagation) {
                        // window.Event.stopImmediatePropagation(ev);
                        window.Event.stopPropagation(ev);
                    }

                    if (this.stopDefault) {
                        window.Event.preventDefault(ev);
                    }

                    this.onEventStop.emit(ev);
                }, this
            ) : undefined
        })
    }


    get eventStop(): string | undefined {
        return this._props.eventStop;
    }

    @Input('stopDefault')
    set stopDefault(val: boolean) {
        this._props.runDefault = !!!val;
    }

    get stopDefault(): boolean {
        return !!!this._props.runDefault;
    }

    @Input('stopPropagation')
    set stopPropagation(val: boolean) {
        this._props.runPropagation = !!!val;
    }

    get stopPropagation(): boolean {
        return !!!this._props.runPropagation;
    }

    constructor(
        private el: ElementRef<any>
    ) {
    }

    ngOnDestroy(): void {
        this._props.differ.clear()
    }
}