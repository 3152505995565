import { ModuleDeclaration } from 'bpmn-js/lib/BaseViewer';
import EventBus from 'diagram-js/lib/core/EventBus.js';

import { GovDiagramDirective } from './diagram.directive';

export class EventHook {
    constructor(
        public diagram: GovDiagramDirective,
        public eventbus: EventBus
    ) {
        diagram.on(diagram.event, diagram, eventbus);
    }

    static Module: ModuleDeclaration = {
        govEventHook: ['type', EventHook],
        __init__: ['govEventHook']
    }

    static $inject = [
        'govDiagram',
        'eventBus'
    ];
}
