
import { GovFormComponent, GovNestedTreeDirective } from "./view/gov.form.component/gov.form.component";
import { GovToolbarComponent } from "./view/gov.toolbar.component/gov.toolbar.component";
import { GovEditorComponent } from "./view/gov.editor.component/gov.editor.component";
import { GovTableComponent } from "./view/gov.table.component/gov.table.component";
import { TemplateDefine, TemplateService } from "./view/template.directive";

import { GovSplitterBarComponent } from "./view/gov.splitter.component/gov.splitter.bar.component";
import { GovStatusbarComponent } from "./view/gov.statusbar.component/gov.statusbar.component";
import { GovWSSumitemComponent } from "./view/gov.wssumitem.component/gov.wssumitem.component";
import { GovAdvfilterComponent } from "./view/gov.advfilter.component/gov.advfilter.component";
import { GovSplitterComponent } from "./view/gov.splitter.component/gov.splitter.component";
import { GovPrjCardComponent } from "./view/gov.prjcard.component/gov.prjcard.component";
import { GovWSItemComponent } from "./view/gov.wsitem.component/gov.wsitem.component";
import { GovWSCardComponent } from "./view/gov.wscard.component/gov.wscard.component";
import { GovPrjSumCardComponent } from "./view/gov.prjsumcard.component/gov.prjsumcard.component";

import { GovSplitterPanelDirective } from "./view/gov.splitter.component/gov.splitter.panel.directive";
import { GovEventStopDirective } from "./view/gov.event.directive/gov.event.directive";
import { GovDiagramDirective } from "./view/diagram.directive/diagram.directive";
import { TimerDirective } from "./view/timer.directive";
import { SafePipe } from "./view/safe.pipe";

export const Utils = {
    Components: [
        GovEventStopDirective,
        GovDiagramDirective,
        GovToolbarComponent,
        GovEditorComponent,
        GovTableComponent,
        GovFormComponent,
        GovWSItemComponent,
        GovStatusbarComponent,
        GovPrjCardComponent,
        GovWSCardComponent,
        GovWSSumitemComponent,
        GovAdvfilterComponent,
        GovPrjSumCardComponent,
        TemplateDefine,
        SafePipe,

        GovSplitterComponent,
        GovSplitterBarComponent,
        GovSplitterPanelDirective,
        GovNestedTreeDirective,
        TimerDirective
    ],

    Services: [
        TemplateService
    ]
}