import { OnInit, ElementRef, OnDestroy, SimpleChanges, OnChanges, NgZone, Component, Input, output } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { Observable, zip } from 'rxjs';

import { NgxAMapCreaterService } from '../../shared/inner/ngx-amap.creater.service';
import { NgxAMapPluginService } from '../../shared/inner/ngx-amap.plugin.service';
import { AMapEventBinderService } from '../../shared/amap-binder-event.service';
import { AMapLoggerService } from '../../shared/amap-logger.service';
import { ChangeFilter, getOptions } from '../../utils/options';

const TAG = 'ngx-amap';
const ALL_OPTIONS: Extract<
    keyof NgxAMapComponent,
    keyof AMap.Map.Options
>[] = [
        'view',
        'layers',
        'zoom',
        'center',
        'labelzIndex',
        'zooms',
        'lang',
        'defaultCursor',
        'crs',
        'animateEnable',
        'isHotspot',
        'defaultLayer',
        'rotateEnable',
        'resizeEnable',
        'showIndoorMap',
        // 'indoorMap',
        'expandZoomRange',
        'dragEnable',
        'zoomEnable',
        'doubleClickZoom',
        'keyboardEnable',
        'jogEnable',
        'scrollWheel',
        'touchZoom',
        'touchZoomCenter',
        'mapStyle',
        'features',
        'showBuildingBlock',
        'viewMode',
        'pitch',
        'pitchEnable',
        'buildingAnimation',
        'skyColor',
        'preloadMode',
        'mask',
        'maxPitch',
        'rotation',
        'forceVector',
        'baseRender',
        'overlayRender',
        'showLabel',
        'gridMapForeign',
        'logoUrl',
        'logoUrlRetina',
        'copyright',
        'turboMode',
        'workerMode',
        // 'vectorMapForeign',
    ]

@Component({
    selector: 'ngx-amap',
    templateUrl: "./ngx-amap.component.html",
    styleUrls: ["./ngx-amap.component.scss"],
    providers: [NgxAMapCreaterService, NgxAMapPluginService],
    exportAs: 'ngxAMap',
})
export class NgxAMapComponent implements AMap.Map.Options, OnInit, OnDestroy, OnChanges {
    private readonly _binder = this.binder.bindEvent<AMap.Map.EventMap>();
    private inited: boolean = false;

    get amap(): Observable<AMap.Map> {
        return this.creater.get();
    }

    //#region AMap.Map event notification
    readonly naComplete = outputFromObservable(this._binder(this.amap, 'complete'));
    readonly naClick = outputFromObservable(this._binder(this.amap, 'click'));
    readonly naDblClick = outputFromObservable(this._binder(this.amap, 'dblclick'));
    readonly naRightClick = outputFromObservable(this._binder(this.amap, 'rightclick'));
    readonly naMouseMove = outputFromObservable(this._binder(this.amap, 'mousemove'));
    readonly naMouseOver = outputFromObservable(this._binder(this.amap, 'mouseover'));
    readonly naMouseWheel = outputFromObservable(this._binder(this.amap, 'mousewheel'));
    readonly naMouseUp = outputFromObservable(this._binder(this.amap, 'mouseup'));
    readonly naMouseOut = outputFromObservable(this._binder(this.amap, 'mouseout'));
    readonly naMouseDown = outputFromObservable(this._binder(this.amap, 'mousedown'));
    readonly naTouchStart = outputFromObservable(this._binder(this.amap, 'touchstart'));
    readonly naTouchMove = outputFromObservable(this._binder(this.amap, 'touchmove'));
    readonly naTouchEnd = outputFromObservable(this._binder(this.amap, 'touchend'));
    readonly naHotspotClick = outputFromObservable(this._binder(this.amap, 'hotspotclick'));
    readonly naHotspotOver = outputFromObservable(this._binder(this.amap, 'hotspotover'));
    readonly naHotspotOut = outputFromObservable(this._binder(this.amap, 'hotspotout'));
    readonly naDragStart = outputFromObservable(this._binder(this.amap, 'dragstart'));
    readonly naDragging = outputFromObservable(this._binder(this.amap, 'dragging'));
    readonly naDragEnd = outputFromObservable(this._binder(this.amap, 'dragend'));
    readonly naResize = outputFromObservable(this._binder(this.amap, 'resize'));
    readonly naZoomStart = outputFromObservable(this._binder(this.amap, 'zoomstart'));
    readonly naZoomEnd = outputFromObservable(this._binder(this.amap, 'zoomend'));
    readonly naZoomChange = outputFromObservable(this._binder(this.amap, 'zoomchange'));
    readonly naMoveStart = outputFromObservable(this._binder(this.amap, 'movestart'));
    readonly naMoveEnd = outputFromObservable(this._binder(this.amap, 'moveend'));
    readonly naMove = outputFromObservable(this._binder(this.amap, 'mapmove'));
    //#endregion

    //#region AMap.Map load status notification
    readonly naReady = output<AMap.Map>();
    readonly naPluginsLoaded = output<AMap.Map>();
    //#endregion

    //#region AMap.Map.Options input

    /**
     * 地图视口，用于控制影响地图静态显示的属性
     */
    @Input()
    view?: AMap.View2D;

    /**
     * 地图图层数组，数组可以是图层 中的一个或多个，默认为普通二维地图
     */
    @Input()
    layers?: AMap.Layer[];

    /**
     * 地图显示的缩放级别
     */
    @Input()
    zoom?: number;

    /**
     * 地图中心点坐标值
     */
    @Input()
    center?: AMap.LocationValue;

    /**
     * 地图标注显示顺序
     */
    @Input()
    labelzIndex?: number;

    /**
     * 地图显示的缩放级别范围
     */
    @Input()
    zooms?: [number, number];

    /**
     * 地图语言类型
     */
    @Input()
    lang?: AMap.Lang;

    /**
     * 地图默认鼠标样式
     */
    @Input()
    defaultCursor?: string;

    /**
     * 地图显示的参考坐标系
     */
    @Input()
    crs?: 'EPSG3857' | 'EPSG3395' | 'EPSG4326';

    /**
     * 地图平移过程中是否使用动画
     */
    @Input()
    animateEnable?: boolean;

    /**
     * 是否开启地图热点和标注的hover效果
     */
    @Input()
    isHotspot?: boolean;

    /**
     * 当前地图中默认显示的图层
     */
    @Input()
    defaultLayer?: AMap.TileLayer;

    /**
     * 地图是否可旋转
     */
    @Input()
    rotateEnable?: boolean;

    /**
     * 是否监控地图容器尺寸变化
     */
    @Input()
    resizeEnable?: boolean;

    /**
     * 是否在有矢量底图的时候自动展示室内地图
     */
    @Input()
    showIndoorMap?: boolean;

    /**
     * 在展示矢量图的时候自动展示室内地图图层
     */
    // @Input()
    // indoorMap?: any;

    /**
     * 是否支持可以扩展最大缩放级别
     */
    @Input()
    expandZoomRange?: boolean;

    /**
     * 地图是否可通过鼠标拖拽平移
     */
    @Input()
    dragEnable?: boolean;

    /**
     * 地图是否可缩放
     */
    @Input()
    zoomEnable?: boolean;

    /**
     * 地图是否可通过双击鼠标放大地图
     */
    @Input()
    doubleClickZoom?: boolean;

    /**
     * 地图是否可通过键盘控制
     */
    @Input()
    keyboardEnable?: boolean;

    /**
     * 地图是否使用缓动效果
     */
    @Input()
    jogEnable?: boolean;

    /**
     * 地图是否可通过鼠标滚轮缩放浏览
     */
    @Input()
    scrollWheel?: boolean;

    /**
     * 地图在移动终端上是否可通过多点触控缩放浏览地图
     */
    @Input()
    touchZoom?: boolean;

    /**
     * 当touchZoomCenter=1的时候，手机端双指缩放的以地图中心为中心，否则默认以双指中间点为中心
     */
    @Input()
    touchZoomCenter?: number;

    /**
     * 设置地图的显示样式
     */
    @Input()
    mapStyle?: string;

    /**
     * 设置地图上显示的元素种类
     */
    @Input()
    features?: AMap.Map.Feature[] | 'all' | AMap.Map.Feature;

    /**
     * 设置地图显示3D楼块效果
     */
    @Input()
    showBuildingBlock?: boolean;

    /**
     * 视图模式
     */
    @Input()
    viewMode?: AMap.Map.ViewMode;

    /**
     * 俯仰角度
     */
    @Input()
    pitch?: number;

    /**
     * 是否允许设置俯仰角度
     */
    @Input()
    pitchEnable?: boolean;

    /**
     * 楼块出现和消失的时候是否显示动画过程
     */
    @Input()
    buildingAnimation?: boolean;

    /**
     * 调整天空颜色
     */
    @Input()
    skyColor?: string;

    /**
     * 设置地图的预加载模式
     */
    @Input()
    preloadMode?: boolean;

    /**
     * 为 Map 实例指定掩模的路径，各图层将只显示路径范围内图像
     */
    @Input()
    mask?: [number, number][] | [number, number][][] | [number, number][][][];

    @Input()
    maxPitch?: number;

    @Input()
    rotation?: number;

    @Input()
    forceVector?: boolean;

    @Input()
    baseRender?: "vw" | "d" | "dv" | "v";

    @Input()
    overlayRender?: "c" | "d";

    @Input()
    showLabel?: boolean;

    @Input()
    gridMapForeign?: boolean;

    @Input()
    logoUrl?: string;

    @Input()
    logoUrlRetina?: string;

    @Input()
    copyright?: string;

    @Input()
    turboMode?: boolean;

    @Input()
    workerMode?: boolean;

    /**
     * 额外: 海外⽮量地图样式
     */
    // @Input()
    // vectorMapForeign: string;

    //#endregion

    /**
     * 额外: 设置城市
     */
    @Input()
    city?: string;

    /**
     * 额外：加载插件
     */
    @Input()
    plugins?: string[];

    constructor(
        private readonly creater: NgxAMapCreaterService,
        private readonly binder: AMapEventBinderService,
        private readonly plugin: NgxAMapPluginService,
        private readonly logger: AMapLoggerService,
        private readonly el: ElementRef,
        private readonly ngZone: NgZone
    ) {
    }

    ngOnInit(): void {
        const { logger, el, creater, ngZone, naReady } = this;
        logger.d(TAG, 'initializing ...');

        const container = el.nativeElement.querySelector('div.ngx-amap-container-inner');
        const options = getOptions<NgxAMapComponent, AMap.Map.Options>(this, ALL_OPTIONS);
        logger.d(TAG, 'options:', options);

        creater.create(container, options).subscribe(
            (amap: AMap.Map) => {
                logger.d(TAG, 'map is ready.');

                ngZone.run(() => {
                    naReady.emit(amap)
                });
            }
        );

        this.inited = true;
    }

    ngOnDestroy(): void {
        this.creater.destroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const filter = ChangeFilter.from(changes);
        const { logger, ngZone, plugin } = this;
        const amap = this.get();

        if (this.inited) {
            zip(filter.has('zoom'), amap).subscribe(
                ([v, m]) => {
                    logger.d(TAG, 'setZoom:', v);
                    m.setZoom(v);
                }
            );

            zip(filter.has('center'), amap).subscribe(
                ([v, m]) => {
                    logger.d(TAG, 'setCenter:', v);
                    m.setCenter(v);
                }
            );
        }

        // Not included in OPTIONS
        zip(filter.has('city'), amap).subscribe(
            ([v, m]) => {
                m.setCity(v, () => {
                    logger.d(TAG, 'setCity:', v);
                });
            }
        );

        zip(filter.notEmpty('plugins'), amap).subscribe(
            ([v, m]) => {
                plugin.load(v).subscribe(
                    () => {
                        logger.d(TAG, 'plugins loaded.');
                        ngZone.run(() => {
                            this.naPluginsLoaded.emit(m)
                        });
                    }
                );
            }
        );
    }

    get(): Observable<AMap.Map> {
        return this.creater.get();
    }
}
