import { Injector, ModuleWithProviders, NgModule } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { map, Observable } from "rxjs";
import { O } from "ts-toolbelt";

import { NGX_AMAP_CONFIG, NgxAMapConfig } from "./shared/ngx-amap.config";

import { AMapLoggerService, AMapDebugLoggerService } from "./shared/amap-logger.service";
import { AMapPluginLoaderService } from "./shared/amap-loader-plugin.service";
import { AMapEventBinderService } from "./shared/amap-binder-event.service";
import { AMapMarkerLabelService } from "./shared/amap-marker-label.service";
import { AMapUILoaderService } from "./shared/amap-loader-ui.service";
import { AMapLoaderService } from "./shared/amap-loader.service";
import { AMapPixelService } from "./shared/amap-pixel.service";
import { AMapSizeService } from "./shared/amap-size.service";
import { AMapIconService } from "./shared/amap-icon.service";

import { NgxAMapPlacesearchComponent } from "./view/ngx-amap-placesearch.component/ngx-amap-placesearch.component";
import { NgxAMapInfoWindowComponent } from "./view/ngx-amap-info-window.component/ngx-amap-info-window.component";
import { NgxAMapMarkerClustererDirective } from "./view/ngx-amap-marker-clusterer.directive";
import { NgxAMapPositionPickerDirective } from "./view/ngx-amap-positionpicker.directive";
import { NgxAMapComponent } from "./view/ngx-amap.component/ngx-amap.component";
import { NgxAMapToolBarDirective } from "./view/ngx-amap-tool-bar.directive";
import { NgxAMapPolygonDirective } from "./view/ngx-amap-polygon.directive";
import { NgxAMapMarkerDirective } from "./view/ngx-amap-marker.directive";

const Services = [
    AMapPluginLoaderService,
    AMapEventBinderService,
    AMapUILoaderService,
    AMapMarkerLabelService,
    AMapLoaderService,
    AMapPixelService,
    AMapSizeService,
    AMapIconService
]

const Views = [
    NgxAMapMarkerClustererDirective,
    NgxAMapToolBarDirective,
    NgxAMapPolygonDirective,
    NgxAMapMarkerDirective,

    NgxAMapPositionPickerDirective,
    NgxAMapPlacesearchComponent,
    NgxAMapInfoWindowComponent,
    NgxAMapComponent,
]

@NgModule({
    imports: [
    ],
    providers: [
        Services
    ],
    declarations: [
        Views
    ],
    exports: [
        Views
    ]
})
class NgxAMapModule {
    static readonly injector?: Injector;
    constructor(injector: Injector) {
        (NgxAMapModule as O.Writable<typeof NgxAMapModule>).injector = injector;
    }

    static forRoot(mapConfig?: NgxAMapConfig): ModuleWithProviders<NgxAMapModule> {
        return {
            ngModule: NgxAMapModule,
            providers: [
                {
                    provide: NGX_AMAP_CONFIG,
                    useValue: mapConfig || {}
                },
                {
                    provide: AMapLoggerService,
                    useClass: mapConfig?.debug ? AMapDebugLoggerService : AMapLoggerService,
                },
            ],
        };
    }

    static districtFromPoi(location?: AMap.LngLat | [lng: string | number, lat: string | number]): Observable<string | undefined> {
        const positionToSave = (location instanceof AMap.LngLat ? location.toString().split(',') : location)?.map(
            v => v.toString()
        );

        let url = "https://restapi.amap.com/v3/geocode/regeo?";
        url += "key=987e36227a29053373a38a244a606cc1";
        url += "&location=" + positionToSave;
        url += "&extensions=base";

        const { injector } = NgxAMapModule
        const httpClient = injector?.get(HttpClient);
        if (!httpClient) {
            throw new Error('no injector found')
        }

        return httpClient.get(url).pipe(
            map((response: any) => {
                if (response == null) {
                    console.log("No result for get district");
                    return;
                }

                const { status, info } = response;
                if (status != '1' || info != 'OK') {
                    console.log("Result Error for get district");
                    return;
                }

                const { regeocode: { addressComponent: district = undefined } = {} } = response;
                if (district == null) {
                    console.log("Result Error addressComponent for get district");
                    return;
                }

                return `${district.district}${district.township}`;
            })
        );
    }
}

export {
    NgxAMapModule,

    NGX_AMAP_CONFIG,
    NgxAMapConfig,

    AMapPluginLoaderService,
    AMapEventBinderService,
    AMapUILoaderService,
    AMapMarkerLabelService,
    AMapLoaderService,
    AMapLoggerService,
    AMapPixelService,
    AMapSizeService,
    AMapIconService,

    NgxAMapMarkerClustererDirective,
    NgxAMapToolBarDirective,
    NgxAMapPolygonDirective,
    NgxAMapMarkerDirective,

    NgxAMapPositionPickerDirective,
    NgxAMapPlacesearchComponent,
    NgxAMapInfoWindowComponent,
    NgxAMapComponent,
}