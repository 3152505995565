import { Component, Injector, Input, OnInit } from "@angular/core";

import { Prj, Sys } from "../../../application/service/backface/types";
import { AppService } from "../../../application/service/app.service";

import { ProjectListComponent } from "../../../projectlist/view/projectlist.component";
import { PuDialogService } from "../../puzzle/pu.dialog/pu.dialog.service";

@Component({
    selector: "gov-prjcard, [gov-prjcard]",
    templateUrl: "./gov.prjcard.component.html",
    styleUrls: ["./gov.prjcard.component.scss"]
})
export class GovPrjCardComponent implements OnInit {
    @Input('prjsumitem')
    prjsumitem?: Sys.IPrjBudgetSumItem;

    constructor(
        public app: AppService,
        public dialog: PuDialogService,
        public injector: Injector
    ) {
    }

    ngOnInit(): void {
    }

    getStatusName(): string {
        const { prjsumitem, app: { dict: { PrjConstrTypes, AllStatus } } } = this;

        if (prjsumitem == null) {
            return '';
        }

        const { constrtype } = prjsumitem;
        if (constrtype != undefined) {
            return PrjConstrTypes.indexed[constrtype]?.title ?? '';
        }

        const { constrstatus } = prjsumitem;
        if (constrstatus != undefined) {
            const { app: { lang: { sysenums = {} } = {} } } = this;
            if (constrstatus == Prj.IWorkitem.Status.finished) return sysenums.prjfinished;
            return AllStatus.indexed[constrstatus]?.title ?? '';
        }

        return '';
    }

    getPercent(): number {
        const { prjsumitem: { yearamount = 0, yearconsume = 0 } = {} } = this;

        if (yearamount <= 0 || yearconsume <= 0) return 0;

        return Math.round(yearconsume / yearamount * 1000) / 10;
    }

    summaryList() {
        const { prjsumitem: { count = 0 } = {} } = this;
        if (count == 0) return;

        const { app, prjsumitem: { year, prjlist } = {} } = this;
        const { lang: { general: { unit, projects }, report: { projectcountsummary } } } = app;

        const header = `${year}/${this.getStatusName()}/${count}${unit}${projects}`;
        ProjectListComponent.open(this.dialog, {
            title: projectcountsummary,
            prjidlist: prjlist ?? [],
            header: header,
        }, this.injector)
    }
}