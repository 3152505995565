import { map, Observable, ReplaySubject, zip } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';

import { NgxAMapCreaterService } from './ngx-amap.creater.service';
import { NgxAMapPluginService } from './ngx-amap.plugin.service';
import { AMapLoggerService } from '../amap-logger.service';
import { Getter } from '../../base/types';

const TAG = 'AMapMarkerCluster';

@Injectable()
export class NgxAMapMarkerClustererService implements Getter<AMap.MarkerClusterer> {
    private readonly markerClusterer$ = new ReplaySubject<AMap.MarkerClusterer>(1);
    private markerClusterer?: AMap.MarkerClusterer;

    constructor(
        private readonly amaps: NgxAMapCreaterService,
        private readonly plugins: NgxAMapPluginService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
    }

    /**
     * 获取点聚合
     */
    get(): Observable<AMap.MarkerClusterer> {
        return this.markerClusterer$.asObservable();
    }

    /**
     * 创建点聚合 AMap.MarkerClusterer
     * @param options 选项
     */
    create(options: AMap.MarkerClusterer.Options): Observable<AMap.MarkerClusterer> {
        return zip(this.amaps.get(), this.plugins.load('AMap.MarkerClusterer')).pipe(
            map(
                ([m]) => {
                    this.markerClusterer = this.ngZone.runOutsideAngular(
                        () => new AMap.MarkerClusterer(m, [], options)
                    );

                    this.logger.d(TAG, 'new markerClusterer created.');
                    this.markerClusterer$.next(this.markerClusterer);
                    this.markerClusterer$.complete();
                    return this.markerClusterer;
                }
            )
        );
    }

    /**
     * 销毁点聚合
     */
    destroy(): void {
        this.get().subscribe(
            m => {
                m.clearMarkers();
                m.setMap(null as any);
                this.logger.d(TAG, 'markerClusterer destroyed.');
                delete this.markerClusterer;
            }
        );
    }
}
