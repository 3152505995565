import { Component, Injector, OnInit } from '@angular/core';

import { ResetpwdComponent } from '../../../resetpwd/view/resetpwd.component';
import { UserinfoComponent } from '../../../userinfo/view/userinfo.component';
import { PuDialogService } from '../../../utils/puzzle/pu.dialog/pu.dialog.service';
import { PuSysService } from '../../../utils/puzzle/pu.service/pu.sys.service';
import { AuthService } from '../../service/auth.service';
import { AppService } from '../../service/app.service';

@Component({
    selector: 'app-navbar, [app-navbar]',
    templateUrl: "./navbar.component.html",
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    constructor(
        public auth: AuthService,
        public sys: PuSysService,
        public app: AppService,
        public dialog: PuDialogService,
        public injector: Injector
    ) {
    }

    ngOnInit() {
    }

    resetPassword() {
        const { dialog, injector } = this;
        ResetpwdComponent.open(dialog, injector)
    }

    userInfo() {
        const { dialog, injector } = this;
        UserinfoComponent.open(dialog, injector)
    }
}
