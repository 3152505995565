import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EnvironmentProviders, ModuleWithProviders, NgModule, Provider, Type } from "@angular/core";
import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS, DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule } from '@angular/forms';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TranslateModule } from '@ngx-translate/core';

import { NgbAccordionModule, NgbDropdownModule, NgbCarouselModule, NgbNavModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { DateAdapter, MatRippleModule, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatCalendarBody, MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatTreeModule } from '@angular/material/tree';

import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NgEventBus } from 'ng-event-bus';

import { PuTemplateDirective, PuTemplateOutletDirective, PuTemplateService } from "./pu.template/pu.template";
import { PuSplitterPanelDirective } from "./pu.splitter.component/pu.splitter.panel.directive";
import { PuFormComponent, PuNestedTreeDirective } from './pu.form.component/pu.form.component';
import { PuSplitterBarComponent } from "./pu.splitter.component/pu.splitter.bar.component";
import { PuHttpRQOverlayDirective, PuHttpService } from './pu.service/pu.http.service';
import { PuDroppableDirective } from "./pu.droppable.directive/pu.droppable.directive";
import { PuDashboardComponent } from './pu.dashboard.component/pu.dashboard.component';
import { PuEventGuardDirective } from './pu.event.directive/pu.event.guard.directive';
import { PuSplitterComponent } from "./pu.splitter.component/pu.splitter.component";
import { PuEventStopDirective } from "./pu.event.directive/pu.event.stop.directive";
// import { PuOutletDirective } from './pu.outlet.directive/pu.outlet.directive';
import { PuTimerDirective } from './pu.timer.directive.ts/pu.timer.directive';
import { PuEditorComponent } from './pu.editor.component/pu.editor.component';
import { PuAttrDirective } from './pu.attr.directive/pu.attr.directive';
import { PuInitDirective } from './pu.init.directive/pu.init.directive';
import { PuVersioinService } from './pu.service/pu.version.service';
import { PuDialogComponent } from './pu.dialog/pu.dialog.component';
import { PuSchemaService } from './pu.service/pu.schema.service';
import { PuDialogService } from './pu.dialog/pu.dialog.service';
import { PuAlertService } from './pu.service/pu.alert.service';
import { PuMsgService } from './pu.service/pu.msg.service';
import { PuSysService } from './pu.service/pu.sys.service';
import { PuSafePipe } from './pu.safe.pipe/pu.safe.pipe';

export const paginatorIntl = new MatPaginatorIntl();
paginatorIntl.itemsPerPageLabel = '每页显示:';
paginatorIntl.previousPageLabel = '上一页:';
paginatorIntl.nextPageLabel = '下一页:';
paginatorIntl.firstPageLabel = '首页:';
paginatorIntl.lastPageLabel = '尾页:';

paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
        return `0至${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1}至${endIndex} 共${length}条`;
};

const Views: (any[] | Type<any>)[] = [
    PuTemplateOutletDirective,
    PuTemplateDirective.Views,
    PuSplitterPanelDirective,
    PuHttpRQOverlayDirective,
    PuNestedTreeDirective,
    PuDroppableDirective,
    PuEventStopDirective,
    PuEventGuardDirective,
    // PuOutletDirective,
    PuTimerDirective,
    PuAttrDirective,
    PuInitDirective,

    PuSplitterBarComponent,
    PuDashboardComponent,
    PuSplitterComponent,
    PuEditorComponent,
    PuDialogComponent,
    PuFormComponent,

    PuSafePipe,
]

const Services: (Provider | EnvironmentProviders)[] = [
    PuTemplateService,
    PuVersioinService,
    PuSchemaService,
    PuDialogService,
    PuAlertService,
    PuHttpService,
    PuMsgService,
    PuSysService,
]

const ImportExport: (any[] | Type<any> | ModuleWithProviders<{}>)[] = [
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FileUploadModule,
    DragDropModule,
    OverlayModule,
    CommonModule,
    FormsModule,

    NgJsonEditorModule,
    NgxEchartsModule.forRoot({
        echarts
    }),

    [
        NgbProgressbarModule,
        NgbAccordionModule,
        NgbDropdownModule,
        NgbCarouselModule,
        NgbNavModule,
    ],

    [
        MatMenuModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatRippleModule,
        MatSelectModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        MatTabsModule,
        MatMomentDateModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatStepperModule,
        MatCardModule,
        MatDividerModule,
        MatTreeModule,
        MatProgressBarModule,
        MatRadioModule,
        MatToolbarModule,
        MatTableExporterModule,
    ]
]

@NgModule({
    imports: [
        ImportExport
    ],
    providers: [
        Services,

        NgEventBus,

        [
            provideHttpClient(withInterceptorsFromDi()),

            {
                provide: MatPaginatorIntl, useValue: paginatorIntl
            },
            {
                provide: DateAdapter,
                useClass: NativeDateAdapter,
                deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
            }, {
                provide: MAT_DATE_LOCALE,
                useValue: 'zh-CN'
            }, {
                provide: MAT_DATE_FORMATS, useValue: {
                    ...MAT_MOMENT_DATE_FORMATS,
                    parse: {
                        ...MAT_MOMENT_DATE_FORMATS.parse,
                    },
                    display: {
                        ...MAT_MOMENT_DATE_FORMATS.display,
                        monthYearA11yLabel: "yyyy年MM月",
                        monthYearLabel: "yyyy年MM月",
                    },
                }
            }, {
                provide: MAT_CHIPS_DEFAULT_OPTIONS,
                useValue: {
                    separatorKeyCodes: [ENTER, COMMA]
                }
            }, {
                provide: STEPPER_GLOBAL_OPTIONS,
                useValue: {
                    displayDefaultIndicatorType: false
                }
            }, {
                provide: MAT_TABS_CONFIG,
                useValue: {
                    animationDuration: "0ms"
                }
            }, {
                provide: HTTP_INTERCEPTORS,
                useExisting: PuHttpService,
                multi: true
            }, {
                provide: DATE_PIPE_DEFAULT_OPTIONS,
                useValue: {
                    dateFormat: 'yyyy/MM/dd'
                }
            },
            DatePipe
        ],
    ],
    declarations: [
        Views
    ],
    exports: [
        ImportExport,
        Views
    ],
}) export class PuzzleUtilsModule {
    constructor() {
        _.set(MatCalendarBody, 'ɵcmp.onPush', false);
    }
}