
import { Inject, Injectable, InjectionToken, Optional, Type } from '@angular/core';
import { If } from 'ts-toolbelt/out/Any/If';
import { PartialDeep } from 'type-fest';
import { A, O } from 'ts-toolbelt';

import { Org as TOrg, Prj as TPrj, Sys as TSys, Exec as TExec, App as TApp } from './types';
import { Editor } from "../../../utils/libs/editor";
import { Backface } from './config';
import { Dict } from './dict';
import { Org } from "./org"
import { Sys } from './sys';

type Pool = TPrj.Pool;
const Pool = TPrj.Pool;

type Investor = TPrj.Investor;
const Investor = TPrj.Investor;

type Catalog = TPrj.Catalog;
const Catalog = TPrj.Catalog;

const Value = Editor.Value;

type ProjectPools = Extract<O.SelectKeys<Prj, Prj.Projects>, any>;

type CollsOfPool<P extends Prj.Projects> = Extract<
    Exclude<
        O.SelectKeys<P, Prj.Project[] | undefined>,
        If<A.Equals<P, Prj.Projects>, never, keyof Prj.Projects>
    >,
    keyof typeof TPrj.Pool
>

type ProjectColls = {
    [P in ProjectPools]: CollsOfPool<Prj[P]>
}

@Injectable()
export class Prj extends Editor.Editor<Prj, TPrj.IPrj> {//implements OnDestroy, TPrj.IPrj {
    private _props = Prop.Of(this);

    get dict(): Dict {
        const { _props: props, app } = this;
        return props.dict || (
            props.dict = Sys.cast_dict(app.dict, Dict)
        )
    }

    get org(): Org {
        const { _props: props, app } = this;
        return props.org || (
            props.org = Sys.cast_org(app.org, Org)
        )
    }

    get prj(): Prj {
        const { _props: props, app } = this;
        return props.prj || (
            props.prj = Sys.cast_prj(app.prj, Prj)
        )
    }

    @JSON.Key()
    @Backface.Entity(() => Prj.Checkpoint, "prj")
    @Editor.Field(Prj, Editor.Owneds('checkpoints', {
        creator(this: Prj) {
            return new Prj.Checkpoints(this, this);
        },
    })) readonly checkpoints!: Prj.Checkpoints<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.Workitem, "prj")
    @Editor.Field(Prj, Editor.Owneds('workitems', {
        creator(this: Prj) {
            return new Prj.Workitems(this.org, this, this);
        },
    })) readonly workitems!: Prj.Workitems<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.Workset, "prj")
    @Editor.Field(Prj, Editor.Owneds('worksets', {
        creator(this: Prj) {
            return new Prj.Worksets(this.org, this, this);
        },
    })) readonly worksets!: Prj.Worksets<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.Stage, "prj")
    @Editor.Field(Prj, Editor.Owneds('stages', {
        creator(this: Prj) {
            return new Prj.Stages(this.org, this, this);
        },
    })) readonly stages!: Prj.Stages<Prj>;

    @JSON.Key()
    @Backface.Entity(() => Prj.ProjectType, "prj")
    @Editor.Field(Prj, Editor.Owneds('projecttypes', {
        creator(this: Prj) {
            return new Prj.ProjectTypes(this.org, this, this);
        },
    })) readonly projecttypes!: Prj.ProjectTypes;

    @JSON.Key()
    @Backface.Entity(() => Prj.Plan, "prj")
    @Editor.Field(Prj, Editor.Owneds('plans', {
        creator(this: Prj) {
            return new Prj.Plans(this.org, this, this);
        },
    })) readonly plans!: Prj.Plans;

    @JSON.Key()
    @Backface.Entity(() => Prj.Project, "prj")
    @Editor.Field(Prj, Editor.Owneds('projects', {
        creator(this: Prj) {
            return new Prj.Projects(this.org, this.prj, this);
        },
    })) readonly projects!: Prj.Projects;

    @JSON.Key()
    @Backface.Entity(() => Prj.PilotProject, "prj")
    @Editor.Field(Prj, Editor.Owneds('pilotprojects', {
        creator(this: Prj) {
            return new Prj.PilotProjects(this.org, this.prj, this);
        },
    })) readonly pilotprojects!: Prj.PilotProjects;

    @JSON.Key()
    @Backface.Entity(() => Prj.InputProject, "prj")
    @Editor.Field(Prj, Editor.Owneds('inputprojects', {
        creator(this: Prj) {
            return new Prj.InputProjects(this.org, this.prj, this);
        },
    })) readonly inputprojects!: Prj.InputProjects;

    get project(): {
        firstOf(prj: PartialDeep<Prj.Project>): Prj.Project | undefined,
        allOf(prj: PartialDeep<Prj.Project>, compare: (project: Prj.Project) => boolean | void): Prj.Project[],
        allOf(compare: (project: Prj.Project) => boolean | void): Prj.Project[],
        allOf(prj: PartialDeep<Prj.Project>): Prj.Project[],
        readonly indexed: { [id: string]: Prj.Project }
    } {
        const { $props_: props } = this, { poolmap } = Prj, _this = this;
        if (props.project) return props.project;

        const collOf = (prj?: PartialDeep<Prj.Project>): Prj.Project[] | undefined => {
            const coll: Pool = (Prj.Project.stdValue(prj as TPrj.IProject)?.pool ?? 0) as Pool;
            const poolmsk: keyof typeof poolmap = (coll & Pool.poolmask);
            if (poolmsk <= 0) return;

            // find in specified collection
            const poolname = poolmap[poolmsk];
            if (!poolname) return;

            const projects = _this[poolname];
            if ((coll & ~Pool.poolmask) == 0) {
                return projects.array;
            }

            const collname = Value.fromEnum(coll, Pool) as ProjectColls[typeof poolname];
            return (projects as any)?.[collname] || projects;
        }

        const trap = {};
        const indexedproxy = new Proxy<{ [id: string]: Prj.Project }>(<any>trap, Prop.makeProxyHandler({
            get(_target, key: string) {
                return props.project?.firstOf({ id: key });
            }
        }))

        return props.project = {
            firstOf(prj: PartialDeep<Prj.Project>): Prj.Project | undefined {
                const colls = collOf(prj);

                if (colls) {
                    return colls?.firstOf?.(prj);
                }

                for (const pool in poolmap) {
                    // find in loaded collection.
                    const poolname = poolmap[pool as unknown as TPrj.Pool];
                    if (!poolname) continue;

                    if (Backface.Of(_this, poolname)?.loaded) {
                        const ret = _this[poolname]?.firstOf(prj);
                        if (ret) return ret;
                    }
                }

                // find in not loaded collection
                for (const pool in poolmap) {
                    const poolname = poolmap[pool as unknown as TPrj.Pool];
                    if (!poolname) continue;

                    if (!(Backface.Of(_this, poolname)?.loaded)) {
                        const ret = _this[poolname]?.firstOf(prj);
                        if (ret) return ret;
                    }
                }

                return
            },
            allOf(prj?: PartialDeep<Prj.Project> | ((project: Prj.Project) => boolean | void), compare?: ((project: Prj.Project) => boolean | void)): Prj.Project[] {
                compare = _.isFunction(prj) ? prj : compare;
                prj = _.isFunction(prj) ? undefined : prj;
                const { project: _project } = _this;

                if (prj?.id) {
                    const project = _project.firstOf(prj);

                    if (project && (!compare || compare(project))) {
                        return [project]
                    }

                    return [];
                }

                const colls = collOf(prj);
                if (colls) {
                    const targets = colls.where(prj);
                    if (!compare) return targets;
                    return targets.filter(compare);
                }

                // worest case that we must search in all collections
                let targets: Prj.Project[] = [];
                for (const pool in poolmap) {
                    const poolname = poolmap[pool as unknown as TPrj.Pool];
                    if (!poolname) continue;

                    // find in loaded collection.
                    const projects = _this[poolname];
                    let parts = projects.where(prj);
                    if (compare) parts = parts.filter(compare);
                    if ((parts?.length ?? 0) > 0) targets = targets.concat(parts);
                }

                return targets;
            },
            get indexed() {
                return indexedproxy;
            }
        }
    }

    constructor(
        @Optional()
        @Inject(Prj.appservice)
        public app: TApp.IAppService,

        @Optional()
        @Inject(Prj.setting)
        setting?: TPrj.IPrj
    ) {
        super(setting);
    }

    ngOnDestroy(): void {
        _.forEach(Prj.poolmap, (pkey) => {
            if (Backface.Of(this, pkey!)?.loaded) {
                (this[pkey!] as any)?.['ngOnDestroy']?.();
            }
        })
    }

    static readonly poolmap: {
        [P in Pool]?: ProjectPools
    } = {
            [Pool.coll]: 'inputprojects',
            [Pool.pilot]: 'pilotprojects',
            [Pool.authed]: 'projects',
        }

    static readonly setting = new InjectionToken('PrjSetting');

    static readonly appservice = new InjectionToken('AppService');
}

export namespace Prj {
    export class Checkpoint<TParent extends Prj | Workitem<Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>>> extends Editor.Editor<Checkpoint<TParent>, TPrj.ICheckpoint> {//implements TPrj.ICheckpoint {
        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.guidid)
        readonly id!: string;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.owned)
        title!: string | ((app: TApp.IAppService, obj: any) => string);

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Checkpoint, [Editor.Type.owned, Value.Type.enum, Value.Type, Value.Type.text])
        type!: Editor.Value.Type;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.Type.inherit)
        must!: boolean;

        @JSON.Key()
        @Editor.Field(Checkpoint, Editor.RefOwned('dependfield', {
            itemtype: () => Checkpoint<Prj>,
            source(this: Checkpoint<TParent>) {
                return this.prj.checkpoints;
            },
        })) dependfield?: Checkpoint<Prj>;

        @JSON.Key()
        @Editor.Field(Checkpoint, [Editor.Type.owned, Value.Type.json])
        dependvalues?: any[];

        @JSON.Key()
        @Editor.Field(Checkpoint, [Editor.Type.owned, Value.Type.json])
        typesource?: string | { key: string, title: string, value: number | string }[];

        get source(): string | string[] | Editor.IEnum | undefined {
            const { $props_: { source } } = this;
            if (source !== undefined) return source;

            const { $props_: props, prj: { dict }, typesource, type } = this;
            if (type == Value.Type.enum && (_.isArray(typesource) || _.isString(typesource))) {
                if (_.isString(typesource)) {
                    type SourceTypes = Checkpoint<TParent>['source'];
                    type SourceKeys = O.SelectKeys<Dict, SourceTypes>;
                    return props.source = dict[typesource as SourceKeys];
                }

                return props.source = Editor.CreateIEnum(...typesource.map(val => {
                    const { key, title } = val, { value = key } = val;
                    return { key, value, title }
                }))
            }

            if (_.isString(typesource)) {
                props.source = typesource;
                return props.source;
            }

            return props.source = undefined;
        }

        get workitem(): Workitem<Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>> | undefined {
            const { $props_: { workitem } } = this;
            if (workitem) return workitem;

            const { parent, $props_: props } = this;
            return props.workitem = (parent instanceof Workitem ? parent : undefined);
        }

        get key(): string {
            return this.id?.toString();
        }

        constructor(
            public prj: Prj,
            public parent: TParent,
            _checkpoint: TPrj.ICheckpoint | Checkpoint<TParent> = {}
        ) {
            super(_checkpoint);
        }
    }

    @Editor.Collections(Checkpoint)
    export class Checkpoints<TParent extends Prj | Workitem<Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>>> extends XArray<Checkpoint<TParent>, TPrj.ICheckpoint, 'id'> {
        constructor(
            public prj: Prj,
            public parent: TParent,
            _checkpoints: (TPrj.ICheckpoint | Checkpoint<TParent>)[] = []
        ) {
            super('id', ..._checkpoints);
        }

        override construct(val: Checkpoint<TParent> | TPrj.ICheckpoint): Checkpoint<TParent> {
            return new Checkpoint<TParent>(this.prj, this.parent, val);
        }
    }

    export class Workitem<TParent extends Prj | Project | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>> extends Editor.Editor<Workitem<TParent>, TPrj.IWorkitem> {//implements TPrj.IWorkitem {
        audit?: TExec.IAudit;
        notused?: boolean;

        value: {
            [P: string]: any;
        } = {};

        get status(): TPrj.IWorkitem.Status | undefined {
            const { IWorkitem: { Status: { unknown } } } = TPrj;

            if (!this.project) {
                // only project assocated workitem can have real status value;
                return unknown;
            };

            const { $props_: { status } } = this;
            return status;
        }

        set status(s: TPrj.IWorkitem.Status | undefined) {
            const { $props_: props } = this;
            props.status = Value.toEnum(s, TPrj.IWorkitem.Status);
        }

        get enddate(): Date | undefined {
            const { $props_: { enddate } } = this;
            return Value.toDate(enddate);
        }

        set enddate(edate: Date | undefined) {
            const { $props_: props } = this;
            props.enddate = edate;
        }

        get begindate(): Date | undefined {
            const { $props_: { begindate } } = this;
            return Value.toDate(begindate);
        }

        set begindate(edate: Date | undefined) {
            const { $props_: props } = this;
            props.begindate = edate;
        }

        get closedate(): Date | undefined {
            const { $props_: { closedate } } = this;
            return Value.toDate(closedate);
        }

        set closedate(edate: Date | undefined) {
            const { $props_: props } = this;
            props.closedate = edate;
        }

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Workitem, [Editor.Type.owned, Value.Type.enum, TPrj.IWorkitem.Type, TPrj.IWorkitem.Type.normal])
        type?: TPrj.IWorkitem.Type;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.Type.inherit)
        duration?: number;

        @JSON.Key()
        @Editor.Field(Workitem, [Editor.Type.inherit, Value.Type.enum, TSys.Frequency, TSys.Frequency.randomly])
        occurancy?: TSys.Frequency;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.RefOwned('dept', {
            itemtype: () => Org.Dept,
            source(this: Workitem<TParent>) {
                return this.org.dept;
            },
        })) dept?: Org.Dept;

        @JSON.Key()
        @Editor.Field(Workitem, Editor.RefInherits('checkpoints', {
            source(this: Workitem<TParent>): Checkpoint<Prj>[] {
                return this.prj.checkpoints;
            },
            creator(this: Workitem<TParent>) {
                return new Checkpoints<Workitem<TParent>>(this.prj, this);
            }
        })) readonly checkpoints!: Checkpoint<Workitem<TParent>>[];

        @JSON.Key('id')
        get workset(): Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>> | undefined {
            const { $props_: { workset } } = this;
            if (workset) return workset;

            const { parent, $props_: props } = this;
            return props.workset = (parent instanceof Workset ? parent : undefined);
        }

        @JSON.Key('id')
        get stage(): Stage<Prj | ProjectType<Prj | Project>> | undefined {
            const { $props_: { stage } } = this;
            if (stage) return stage;

            const { workset: { parent } = {}, $props_: props } = this;
            return props.stage = (parent instanceof Stage ? parent : undefined);
        }

        @JSON.Key('id')
        get projecttype(): ProjectType<Prj | Project> | undefined {
            const { $props_: { projecttype } } = this;
            if (projecttype) return projecttype;

            const { stage: { parent } = {}, $props_: props } = this;
            return props.projecttype = (parent instanceof ProjectType ? parent : undefined);
        }

        @JSON.Key('id')
        get project(): Project | undefined {
            const { $props_: { project } } = this;
            if (project) return project;

            const { parent, $props_: props } = this;
            if (parent instanceof Project) {
                return props.project = parent;
            }

            const { projecttype: { parent: _parent } = {} } = this;
            return props.project = (_parent instanceof Project ? _parent : undefined);
        }

        get projectplan(): Plan | undefined {
            return this.project?.plan;
        }

        get processpath(): string {
            const { workset: { name: wname = '' } = {} } = this;
            const { stage: { name: sname = '' } = {} } = this;
            return `${sname}/${wname}`;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _workitem: TPrj.IWorkitem | Workitem<any> = {}
        ) {
            super(_workitem as any);
        }
    }

    @Editor.Collections(Workitem)
    export class Workitems<TParent extends Prj | Workset<Prj | Stage<Prj | ProjectType<Prj | Project>>>> extends XArray<Workitem<TParent>, TPrj.IWorkitem, 'id'> {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _workitems: (TPrj.IWorkitem | Workitem<TParent>)[] = []
        ) {
            super('id', ..._workitems);
        }

        override _onCreateDestroied(created?: Workitem<TParent>[], destroied?: Workitem<TParent>[]) {
            super._onCreateDestroied?.(created, destroied);

            [Workset, Stage, ProjectType, Project].reduce<[boolean, any]>(([tocheck, obj], type) => {
                let parent = obj?.parent;

                if (tocheck) {
                    if (parent instanceof type) {
                        destroied && parent.workitems.remove(...((destroied ?? []) as []));
                        created && parent.workitems.add(created as [], 0);
                    } else {
                        parent = null;
                    }
                }

                return [true, parent]
            }, [false, this]);
        }

        override construct(val: Workitem<TParent> | TPrj.IWorkitem): Workitem<TParent> {
            return new Workitem<TParent>(this.org, this.prj, this.parent, val);
        }
    }

    export class Workset<TParent extends Prj | Stage<Prj | ProjectType<Prj | Project>>> extends Editor.Editor<Workset<TParent>, TPrj.IWorkset> {//implements TPrj.IWorkset {
        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Workset, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Workset, Editor.RefInherits('workitems', {
            source(this: Workset<TParent>) {
                return this.prj.workitems.array;
            },
            creator(this: Workset<TParent>): Workitem<Workset<TParent>>[] {
                return new Workitems<Workset<TParent>>(this.org, this.prj, this);
            }
        })) readonly workitems!: Workitem<Workset<TParent>>[];

        get order(): number | undefined {
            return this.$inherits?.order;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _workset: TPrj.IWorkset | Workset<TParent> = {}
        ) {
            super(_workset);
        }
    }

    @Editor.Collections(Workset)
    export class Worksets<TParent extends Prj | Stage<Prj | ProjectType<Prj | Project>>> extends XArray<Workset<TParent>, TPrj.IWorkset, 'id'> {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _worksets: (TPrj.IWorkset | Workset<TParent>)[] = []
        ) {
            super('id', ..._worksets);
        }

        override construct(val: Workset<TParent> | TPrj.IWorkset): Workset<TParent> {
            return new Workset<TParent>(this.org, this.prj, this.parent, val);
        }
    }

    export class Stage<TParent extends Prj | ProjectType<Prj | Project>> extends Editor.Editor<Stage<TParent>, TPrj.IStage> {//implements TPrj.IStage {
        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Stage, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Stage, Editor.RefInherits('worksets', {
            source(this: Stage<TParent>) {
                return this.prj.worksets;
            },
            creator(this: Stage<TParent>): Workset<Stage<TParent>>[] {
                return new Worksets<Stage<TParent>>(this.org, this.prj, this);
            }
        })) readonly worksets!: Workset<Stage<TParent>>[];

        @Editor.Field(Stage, Editor.RefFlattens('workitems', {
            children(this: Stage<TParent>, obj: Stage<TParent> | Workset<Stage<TParent>>): Workset<Stage<TParent>>[] | undefined {
                return obj instanceof Stage ? obj.worksets : (_.noop(obj.workitems), undefined);
            }
        })) readonly workitems!: Workitem<Workset<Stage<TParent>>>[];

        @JSON.Key('id')
        get projecttype(): ProjectType<Prj | Project> | undefined {
            const { $props_: { projecttype } } = this;
            if (projecttype) return projecttype;

            const { parent, $props_: props } = this;
            return props.projecttype = (parent instanceof ProjectType ? parent : undefined);
        }

        @JSON.Key('id')
        get project(): Project | undefined {
            const { $props_: { project } } = this;
            if (project) return project;

            const { projecttype: { parent } = {}, $props_: props } = this;
            return props.project = (parent instanceof Project ? parent : undefined);
        }

        get projectplan(): Plan | undefined {
            return this.project?.plan;
        }

        get status(): TPrj.IWorkitem.Status {
            const { IWorkitem: { Status: { unknown } } } = TPrj;

            if (!this.project) {
                // only project assocated workitem can have real status value;
                return unknown;
            };

            const { $props_: { status = unknown } } = this;
            return status;
        }

        set status(s: TPrj.IWorkitem.Status) {
            const { $props_: props } = this;
            props.status = Value.toEnum(s, TPrj.IWorkitem.Status);
        }

        get order(): number | undefined {
            return this.$inherits?.order;
        }

        get children(): Workitem<Workset<Stage<TParent>>>[] {
            return this.workitems;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _stage: TPrj.IStage | Stage<TParent> = {}
        ) {
            super(_stage);
        }
    }

    @Editor.Collections(Stage)
    export class Stages<TParent extends Prj | ProjectType<Prj | Project>> extends XArray<Stage<TParent>, TPrj.IStage, 'id'> {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _stages: (TPrj.IStage | Stage<TParent>)[] = []
        ) {
            super('id', ..._stages);
        }

        override construct(val: Stage<TParent> | TPrj.IStage): Stage<TParent> {
            return new Stage<TParent>(this.org, this.prj, this.parent, val);
        }
    }

    export class ProjectType<TParent extends Prj | Project> extends Editor.Editor<ProjectType<TParent>, TPrj.IProjectType> {//implements TPrj.IProjectType {
        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.RefInherits('stages', {
            source(this: ProjectType<TParent>): Stage<Prj>[] {
                return this.prj.stages;
            },
            creator(this: ProjectType<TParent>) {
                return new Stages<ProjectType<TParent>>(this.org, this.prj, this);
            }
        })) readonly stages!: Stage<ProjectType<TParent>>[];

        @Editor.Field(ProjectType, Editor.RefFlattens('workitems', {
            children(this: ProjectType<TParent>, obj: ProjectType<TParent> | Stage<ProjectType<TParent>>): Stage<ProjectType<TParent>>[] | undefined {
                return obj instanceof ProjectType ? obj.stages : (_.noop(obj.workitems), undefined);
            }
        })) readonly workitems!: Workitem<Workset<Stage<ProjectType<TParent>>>>[];

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        workflow?: string; // bpmn formated xml data

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        svg?: string; // svg string

        @JSON.Key()
        @Editor.Field(ProjectType, Editor.Type.owned)
        json?: object; // json for to bpmn formated workflow xml data

        get children(): Stage<ProjectType<TParent>>[] {
            return this.stages;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _projecttype: TPrj.IProjectType | ProjectType<Prj> = {}
        ) {
            super(_projecttype as ProjectType<TParent>);
        }
    }

    @Editor.Collections(ProjectType)
    export class ProjectTypes extends XArray<ProjectType<Prj>, TPrj.IProjectType, 'id'> {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            projecttypes: TPrj.IProjectType[] = [],
        ) {
            super('id', ...projecttypes);
        }

        get children(): ProjectType<Prj>[] {
            return this;
        }

        override construct(val: ProjectType<Prj> | TPrj.IProjectType): ProjectType<Prj> {
            return new ProjectType<Prj>(this.org, this.prj, this.parent, val);
        }
    }
}

export namespace Prj {
    const pxa: XArray<Budget<Project>, TPrj.IBudget, false> = null as any;
    const pa: Array<Budget<Project>> = null as any;
    const pxap: TPrj.IBudget[] = pxa;
    const pap: TPrj.IBudget[] = pa;

    export class Budget<TParent extends Project> extends Editor.Editor<Budget<TParent>, TPrj.IBudget> {//implements TPrj.IBudget {
        private _props = Prop.Of<Budget<TParent>, {
            $onbreaking_: boolean

            yearrange: {
                start?: Date,
                end?: Date
            }
        }>(this, values => {
            values.yearrange = {}
        });

        @JSON.Key()
        @Editor.Field(Budget, [Editor.Type.owned, Value.Type.enum, TPrj.Source, TPrj.Source.unknown])
        source?: TPrj.Source;

        @JSON.Key()
        @Editor.Field(Budget, Editor.Type.owned)
        amount?: number;

        @JSON.Key()
        @Editor.Field(Budget, Editor.Type.owned)
        consume?: number;

        @JSON.Key()
        @Editor.Field(Budget, [Editor.Type.owned, Value.Type.date])
        start?: Date;

        @JSON.Key()
        @Editor.Field(Budget, [Editor.Type.owned, Value.Type.date])
        end?: Date;

        @JSON.Key()
        @Editor.Field(Budget, Editor.Type.owned)
        target?: string;

        get budgets(): Budgets<TParent> | undefined {
            const { $props_: props } = this;
            return props.budgets;
        }

        get startend(): string {
            const { start, end } = this;
            let rst = "";

            if (start != undefined) {
                rst = `${rst}${start.getFullYear().toString()}`;
            }

            rst = `${rst}~`;

            if (end != undefined) {
                rst = `${rst}${end.getFullYear().toString()}`;
            }

            return rst;
        }

        get percent(): number {
            const { amount = 0, consume = 0 } = this;
            if (amount <= 0) return 0;

            return Math.round(consume / amount * 1000) / 10;
        }

        checkYear(year: number): boolean {
            const { start, end } = this;
            if (start != undefined && end != undefined) {
                return (year >= start.getFullYear()) && (year <= end.getFullYear());
            }

            return false;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _budgets?: Budgets<TParent>,
            _budget: TPrj.IBudget = {}
        ) {
            super(_budget);

            const { $props_: props } = this;
            props.budgets = _budgets;
        }

        override $onvalue_<
            Key extends Extract<Editor.TKeys<Budget<TParent>>, 'start' | 'end'>
        >(key: Key, value: Budget<TParent>[Key], changed: boolean) {
            // if (!(('start' == key && this.$changed_(key)) || ('end' == key && this.$changed_(key)))) return;
            if ('start' !== key && 'end' !== key) return;

            const { _props: { yearrange } } = this;
            const _val = yearrange[key];
            if (_val == value) return;
            yearrange[key] = value;

            const { _props: props, budgets, start, end } = this;
            if (props.$onbreaking_ || !budgets) return;
            props.$onbreaking_ = true;

            {
                let startyear = start?.getFullYear(), endyear = end?.getFullYear();
                startyear = startyear ?? endyear ?? 0, endyear = endyear ?? startyear ?? 0;
                startyear = startyear == 0 ? endyear : startyear;
                endyear = endyear == 0 ? startyear : endyear;

                if (startyear == 0 || endyear == 0) {
                    budgets.length != 0 && budgets.reset();
                } else {
                    // sort the array, and then remove items not in-range
                    budgets.sort((a, b) => (a.start?.getFullYear() ?? 0) - (b.start?.getFullYear() ?? 0));

                    const exists: { [year: number]: boolean } = {};
                    const todel = budgets.filter((val) => {
                        const year = val.start?.getFullYear() || 0;
                        if (exists[year]) return true;
                        exists[year] = true;

                        return year < startyear || year > endyear;
                    })

                    todel.length > 0 && budgets.destroy(...todel)

                    // create budget if not exist
                    for (let idx = startyear; idx <= endyear; idx++) {
                        let pos: number | undefined = 0, nc = 0;

                        for (pos = 0, nc = budgets.length; pos < nc; pos++) {
                            const year = budgets[pos].start?.getFullYear();
                            if (year == idx) { pos = undefined; break; }
                            if ((year ?? 0) > idx) break;
                        }

                        if (pos != undefined) {
                            budgets.create([{ start: new Date(idx, 1, 1) }], pos)
                        }
                    }
                }
            }

            props.$onbreaking_ = false;
        }
    }

    @Editor.Collections(Budget)
    export class Budgets<TParent extends Project> extends XArray<Budget<TParent>, TPrj.IBudget, false> {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: TParent,
            _budgets: TPrj.IBudget[] = [],
        ) {
            super(false, ..._budgets);
        }

        override isT(val: Budget<TParent> | TPrj.IBudget): val is Budget<TParent> {
            return val instanceof Budget;
        }

        override construct(val: TPrj.IBudget): Budget<TParent> {
            return new Budget<TParent>(this.org, this.prj, this.parent, undefined, val);
        }
    }

    export class Plan extends Editor.Editor<Plan, TPrj.IPlan> {//implements TPrj.IPlan {
        @JSON.Key()
        @Editor.Field(Plan, Editor.Type.guidid)
        readonly id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Plan, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Plan, Editor.Type.owned)
        description?: string;

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            _plan: TPrj.IPlan = {}
        ) {
            super(_plan);
        }
    }

    @Editor.Collections(Plan)
    export class Plans extends XArray<Plan, TPrj.IPlan, 'id'> {
        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            plans: TPrj.IPlan[] = []
        ) {
            super('id', ...plans)
        }

        override isT(val: Plan | TPrj.IPlan): val is Plan {
            return val instanceof Plan;
        }

        override construct(val: TPrj.IPlan): Plan {
            return new Plan(this.org, this.prj, this.parent, val);
        }
    }

    export class Project extends Editor.Editor<Project, TPrj.IProject> {//implements TPrj.IProject {
        @JSON.Key()
        @Editor.Field(Project, Editor.Type.guidid)
        id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        location?: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        position?: TPrj.IPosition;

        get positionXY(): string[] | undefined {
            return this.position?.[0]?.postion;
        }

        get positonDistrict(): string | undefined {
            return this.position?.[0]?.district;
        }

        @JSON.Key()
        @Editor.Field(Project, Editor.RefInherit('projecttype', {
            source(this: Project): ProjectType<Prj>[] {
                //获取实例projecttype全集
                return this.prj.projecttypes;
            },
            creator(this: Project, item: ProjectType<Prj>): ProjectType<Project> | undefined {
                if (this.ispatched) {
                    //打包项目没有projecttype
                    return undefined;
                }

                return new ProjectType<Project>(this.org, this.prj, this, item);
            }
        })) projecttype?: ProjectType<Project>;

        @JSON.Key()
        @Editor.Field(Project, Editor.Owneds('budgets', {
            creator(this: Project) {
                return new Budgets(this.org, this.prj, this);
            }
        })) readonly budgets!: Budgets<Project>;

        @JSON.Key()
        @Editor.Field(Project, Editor.Owned('budget', {
            itemtype: () => Budget,
            creator(this: Project, item: TPrj.IBudget) {
                return new Budget(this.org, this.prj, this, this.budgets, item);
            },
        })) readonly budget!: Budget<Project>;

        @Editor.Field(Project, Editor.RefFlattens('workitems', {
            children(this: Project, obj: Project | ProjectType<Project>): ProjectType<Project>[] | undefined {
                return obj instanceof Project ? (obj.projecttype && [obj.projecttype]) : (_.noop(obj?.workitems), undefined);
            }
        })) readonly workitems!: Workitem<Workset<Stage<ProjectType<Project>>>>[];

        @JSON.Key()
        @Editor.Field(Project, Editor.RefOwned('plan', {
            itemtype: () => Plan,
            source(this: Project) {
                return this.prj.plans;
            },
        })) plan?: Plan;

        @JSON.Key()
        @Editor.Field(Project, Editor.RefOwned('inspector', {
            itemtype: () => Org.Inspector,
            source(this: Project) {
                return this.org.inspectors
            },
        })) inspector?: Org.Inspector;

        @JSON.Key()
        @Editor.Field(Project, Editor.RefOwned('actor', {
            itemtype: () => Org.Actor,
            source(this: Project) {
                return this.org.actors;
            },
        })) actor?: Org.Actor;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        ispatched!: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        processlock!: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        cancelled!: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        owncompany?: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        owndept?: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.RefOwneds('dept', {
            source(this: Project) {
                return this.org.depts;
            },
            creator(this: Project) {
                return [] as Org.Dept[];
            },
        })) dept!: Org.Dept[];

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        memo?: string;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        isuniplan!: boolean;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        uniplan?: string;

        @JSON.Key()
        @Editor.Field(Project, [Editor.Type.owned, Value.Type.enum, Pool, Pool.input])
        pool?: Pool;

        @JSON.Key()
        @Editor.Field(Project, [Editor.Type.owned, Value.Type.enum, Investor, Investor.government])
        investor?: Investor;

        @JSON.Key()
        @Editor.Field(Project, [Editor.Type.owned, Value.Type.enum, Catalog, Catalog.county])
        catalog?: Catalog;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        govinvest?: number;

        @JSON.Key()
        @Editor.Field(Project, Editor.Type.owned)
        entinvest?: number;

        @JSON.Key()
        @Editor.Field(Project, [Editor.Type.owned, Value.Type.date])
        preconstrdate?: Date;

        @JSON.Key()
        @Editor.Field(Project, [Editor.Type.owned, Value.Type.date])
        preacceptdate?: Date;

        @Editor.Field(Project, Editor.Type.owned)
        readonly myproject!: boolean;

        get exec(): TPrj.IPrjExec {
            const { $props_: { _project: { exec } = {} } } = this;
            if (exec) return exec;

            return this.resetExec(this._project?.exec);
        }

        get nextpool(): Pool | null {
            const { $props_: props } = this;
            return props.nextpool ?? null;
        }

        set nextpool(pool: Pool | null) {
            const { $props_: props } = this;
            props.nextpool = pool ?? null;

            if (!pool) {
                this.$cancel_('pool');
                return;
            }

            this.pool = pool;
        }

        get nextpools(): Pool[] | undefined {
            const { prj: { app } } = this, { prepareproject } = app;
            if (!prepareproject) return;

            const prepool = this.$preval_('pool') ?? Pool.input;
            const { exec: { keystages = {} } = {} } = this;
            const { auth: { me: { priviledges } = {} } } = app;

            const isDone = (pool: string): boolean => {
                if (_.has(keystages, '*')) return false;
                return !_.has(keystages, pool) || keystages[pool]
            }

            const actionMap: {
                [prepool in Pool]?: [
                    nextpool?: Pool,
                    condition?: boolean
                ][]
            } = {
                [Pool.input]: [
                    [Pool.meditate, !!priviledges?.plan?.inputproject?.cud?.pool]
                ],
                [Pool.meditate]: [
                    [Pool.input, true],
                    [Pool.candidate, isDone('meditate')]
                ],
                [Pool.candidate]: [
                    [Pool.begun, isDone('candidate')],
                ],
                [Pool.begun]: [
                    [Pool.candidate, true],
                    [Pool.accept, isDone('begun')]
                ],
                [Pool.accept]: []
            }

            const nextpools = actionMap[prepool]?.map(([nextpool, condition]) => {
                if (condition) return nextpool;
                return;
            })?.filter((pool): pool is TPrj.Pool => !!pool);

            return (nextpools?.length ?? 0) > 0 ? nextpools : undefined;
        }

        get constrstatus(): TPrj.IWorkitem.Status {
            return this.exec?.constrstatus || TPrj.IWorkitem.Status.unknown;
        };

        get ispreproject(): boolean {
            var rst = false;
            rst = (this.constrtype?.indexOf(TPrj.ConstrType.projectpre) >= 0);
            return rst;
        }

        get formal(): boolean {
            return !this.ispreproject && !this.cancelled;
        }

        get constrtype(): TPrj.ConstrType[] {
            return this.exec?.constrtype || [TPrj.ConstrType.unknown];
        }

        get constrtypename(): string {
            const { prj: { dict: { PrjConstrTypes: { indexed: constrTypes } } } } = this;
            var rst = this.constrtype.map(val => constrTypes[val]?.title || "").join(",");
            return rst;
        }

        get constrstatusname(): string {
            const { constrstatus, prj: { app: { lang }, dict: { AllStatus } } } = this;

            if (constrstatus == TPrj.IWorkitem.Status.finished) {
                return lang.sysenums.prjfinished;
            }

            return AllStatus.indexed[constrstatus]?.title || "";
        }

        get execend(): Date | undefined {
            return this.exec?.end;
        }

        get fullName(): string {
            let fName: string = `${this.plan?.name || ""}/${this.name || ""}`;
            return fName;
        }

        get prjConsume(): number {
            var consume = 0;
            this.budgets.forEach(budget => {
                if (budget?.consume) {
                    consume += budget?.consume;
                }
            });

            return consume;
        }

        get prjLevel(): string {
            const { prj: { dict: { enums: { PrjLevel: { PatchedProject, DetailProject } } } } } = this;
            return this.ispatched ? PatchedProject : DetailProject;
        }

        getBudgetAmount(year: number | number[]): number {
            var amount = 0;
            this.budgets.forEach(budget => {
                if (typeof year == 'number') {
                    if (budget.start?.getFullYear() == year) {
                        if (budget?.amount) amount = budget?.amount;
                    }
                }

                if (Array.isArray(year) && year.length > 0) {
                    if (year.firstOf(budget.start?.getFullYear())) {
                        if (budget?.amount) amount += budget?.amount;
                    }
                }
            });

            return amount;
        }

        getBudgetConsume(year: number | number[]): number {
            var consume = 0;
            this.budgets.forEach(budget => {
                if (typeof year == 'number') {
                    if (budget.start?.getFullYear() == year) {
                        if (budget?.consume) consume = budget?.consume;
                    }
                }

                if (Array.isArray(year) && year.length > 0) {
                    if (year.firstOf(budget.start?.getFullYear())) {
                        if (budget?.consume) consume += budget?.consume;
                    }
                }
            });

            return consume;
        }

        getBudgetPercent(year: number): number {
            var percent = 0;
            this.budgets.forEach(budget => {
                if (budget.start?.getFullYear() == year) {
                    if (budget?.percent) percent = budget?.percent;
                }
            });

            return percent;
        }

        getBudgetTarget(year: number | number[]): string {
            for (const budget of this.budgets) {
                if (typeof year == 'number') {
                    if (budget?.start?.getFullYear() == year) {
                        return budget?.target ?? '';
                    }
                }

                if (Array.isArray(year) && year.length > 0) {
                    if (budget?.start?.getFullYear() == year.last) {
                        return budget?.target ?? '';
                    }
                }
            };

            return '';
        }

        getActorleaders(): string {
            const leaders = this.actor?.leaders?.map(v => {
                return v.name;
            }).toString();

            return leaders ?? '';
        }

        getActoractors(): string {
            const actors = this.actor?.actors?.map(v => {
                return v.name;
            }).toString();

            return actors ?? '';
        }

        getActoractorsdept(): string {
            const depts = this.actor?.actors?.map(v => {
                return v.dept?.name;
            }).toString();

            return depts ?? '';
        }

        getActordomains(): string {
            const domains = this.actor?.domains?.map(v => {
                return v.name;
            }).toString();

            return domains ?? '';
        }

        getActordomainsdept(): string {
            const depts = this.actor?.domains?.map(v => {
                return v.dept?.name;
            }).toString();

            return depts ?? '';
        }

        getActorsites(): string {
            const sites = this.actor?.sites?.map(v => {
                return v.name;
            }).toString();

            return sites ?? '';
        }

        getActorsitesdept(): string {
            const depts = this.actor?.sites?.map(v => {
                return v.dept?.name;
            }).toString();

            return depts ?? '';
        }

        getInspectormembers(): string {
            const inspectormembers = this.inspector?.kpimembers?.map(v => {
                return v.name;
            }).toString();

            return inspectormembers ?? '';
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            public _project: TPrj.IProject = {}
        ) {
            super(_project);
        }

        override $update_(value?: TPrj.IProject) {
            value = Project.stdValue(value);
            if (!value) return;

            const pool = value.pool, { pool: curpool } = this;
            value.pool = (pool ?? curpool);

            // try to change project collection if pool changed
            if (this.resetCollection(value)) return;

            Project.UpdateIfHave(this, value, Project.ProjectUpdater);
            this.resetExec(value.exec);
            super.$update_(value);
        }

        private resetExec(_exec?: TPrj.IPrjExec): TPrj.IPrjExec {
            const { $props_: props } = this;
            const project = (props._project = props._project || {});
            const exec = (project.exec = project.exec || {});
            if (!_exec) return exec;

            // reset the setting.
            this._project.exec = this._project.exec || {};
            _.merge(this._project.exec, _exec);

            // update the exec value
            Project.UpdateIfHave(exec, _exec, Project.ExecUpdater);
            return exec;
        }

        private resetCollection(value: TPrj.IProject): boolean {
            const { pool = 0 } = value, prepool = this.$preval_('pool') ?? 0;
            if (pool == prepool) return false;

            const { poolmap } = Prj, { prj } = this;
            const curpoolmsk = (pool & Pool.poolmask) as Pool;
            const prepoolmsk = (prepool & Pool.poolmask) as Pool;
            const curcoll = poolmap[curpoolmsk], precoll = poolmap[prepoolmsk];
            if (!(curcoll && precoll)) return false;

            if (curcoll == precoll) {
                prj[precoll]?.refresh?.();
                return false;
            }

            prj[precoll]?.remove(this), prj[precoll]?.refresh?.();
            if (Backface.Of(prj, curcoll)?.loaded) {
                prj[curcoll]?.create([value], 0), prj[curcoll]?.refresh?.();
            }

            return true;
        }

        static stdValue(prj?: TPrj.IProject): TPrj.IProject | undefined {
            return Sys.stdEnumValue(prj, Project.EnumValue);
        }

        static readonly EnumValue = {
            pool: TPrj.Pool
        }

        static readonly ExecUpdater: {
            [P in keyof TPrj.IPrjExec]-?: (exec: TPrj.IPrjExec, val: any) => TPrj.IPrjExec[P]
        } = ({
            constrtype(exec: TPrj.IPrjExec, val: (string | number)[]) {
                return (exec.constrtype = val?.map(v => Value.toEnum(v, TPrj.ConstrType)!));
            },
            constrstatus(exec: TPrj.IPrjExec, val) {
                return (exec.constrstatus = Value.toEnum(val, TPrj.IWorkitem.Status))
            },
            begin(exec: TPrj.IPrjExec, val) {
                return (exec.begin = Value.toDate(val))
            },
            end(exec: TPrj.IPrjExec, val) {
                return (exec.end = Value.toDate(val))
            },
            keystages(exec: TPrj.IPrjExec, val) {
                return (exec.keystages = val)
            },
            duration(exec: TPrj.IPrjExec, val) {
                return (exec.duration = val)
            }
        })

        static readonly ProjectUpdater: {
            [P in keyof Project]?: (project: Project, val: any) => void
        } = ({
            pool(project: Project, val: Pool) {
                project.$setting_.pool = val;
            },
            myproject(project: Project, val: boolean) {
                project.$setting_.myproject = val;
            }
        })
    }

    export class PilotProject extends Project {

    }

    export class InputProject extends Project {

    }

    @Editor.Collections(Project)
    export class Projects extends XArray<Project, TPrj.IProject, 'id'> {
        protected _props = Prop.Of<Projects, {
            pools: {
                [pool: string]: {
                    projects: Project[],
                    pool: Pool
                } | undefined
            }
        }>(this);

        get begun(): Project[] | undefined {
            const { _props: { pools: pools } } = this;
            return (pools['begun'] || (
                pools['begun'] = this.createPool(
                    Pool.begun
                )
            ))?.projects
        }

        get accept(): Project[] | undefined {
            const { _props: { pools } } = this;
            return (pools['accept'] || (
                pools['accept'] = this.createPool(
                    Pool.accept
                )
            ))?.projects
        }

        constructor(
            public org: Org,
            public prj: Prj,
            public parent: Prj,
            _projects: TPrj.IProject[] = []
        ) {
            super('id', ..._projects);
            this._props.pools = {};
        }

        ngOnDestroy(): void {

        }

        override isT(val: Project | TPrj.IProject): val is Project {
            return val instanceof Project;
        }

        override construct(val: TPrj.IProject): Project {
            return new Project(this.org, this.prj, this.parent, val);
        }

        refresh() {
            window.tick(() => {
                _.forEach(this._props.pools, (c => {
                    c?.projects.reset(...this.extractPool(c.pool));
                }))
            })
        }

        protected extractPool(pool: Pool): Project[] {
            return this.filter(p => p.pool == pool);
        }

        protected createPool(pool: Pool): Projects['_props']['pools'][string] | undefined {
            const { prj: { app: { prepareproject } } } = this;
            if (!prepareproject) return;

            return {
                projects: this.extractPool(pool),
                pool: pool
            }
        }
    }

    @Editor.Collections(PilotProject)
    export class PilotProjects extends Projects {
        get meditate(): Project[] | undefined {
            const { _props: { pools } } = this;
            return (pools['meditate'] || (
                pools['meditate'] = this.createPool(
                    Pool.meditate
                )
            ))?.projects
        }

        get candidate(): Project[] | undefined {
            const { _props: { pools } } = this;
            return (pools['candidate'] || (
                pools['candidate'] = this.createPool(
                    Pool.candidate
                )
            ))?.projects
        }

        override isT(val: PilotProject | TPrj.IProject): val is PilotProject {
            return val instanceof PilotProject;
        }

        override construct(val: TPrj.IProject): PilotProject {
            return new PilotProject(this.org, this.prj, this.parent, val);
        }
    }

    @Editor.Collections(InputProject)
    export class InputProjects extends Projects {
        override isT(val: InputProject | TPrj.IProject): val is InputProject {
            return val instanceof InputProject;
        }

        override construct(val: TPrj.IProject): InputProject {
            return new InputProject(this.org, this.prj, this.parent, val);
        }
    }

    export class SearchFilter extends Editor.Editor<SearchFilter, TPrj.ISearchFilter> {//implements TPrj.ISearchFilter {
        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.guidid)
        id!: Prop.guid;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.owned)
        name!: string;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.RefOwned('people', {
            itemtype: () => Org.People,
            source(this: SearchFilter) {
                return this.org?.peoples;
            },
        })) people?: Org.People;

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owned, Value.Type.date])
        date?: Date;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.owned)
        description?: string;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.Type.owned)
        nowyear!: boolean;

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owned, Value.Type.date])
        start?: Date;

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owned, Value.Type.date])
        end?: Date;

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.RefOwneds('plan', {
            source(this: SearchFilter) {
                return this.prj.plans;
            },
            creator(this: SearchFilter) {
                return [] as Plan[];
            },
        })) readonly plan!: Plan[];

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.RefOwneds('dept', {
            source(this: SearchFilter) {
                return this.org.depts;
            },
            creator(this: SearchFilter) {
                return [] as Org.Dept[];
            },
        })) readonly dept!: Org.Dept[];

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owneds, Value.Type.enum, TPrj.IWorkitem.Status],
            Editor.Owneds('constrstatus', {
                creator(this: SearchFilter) {
                    return [];
                },
            })
        ) constrstatus!: TPrj.IWorkitem.Status[];

        @JSON.Key()
        @Editor.Field(SearchFilter, [Editor.Type.owneds, Value.Type.enum, TPrj.ConstrType],
            Editor.Owneds('constrtype', {
                creator(this: SearchFilter) {
                    return [];
                },
            })
        ) constrtype!: TPrj.ConstrType[];

        @JSON.Key()
        @Editor.Field(SearchFilter, Editor.RefOwneds('workitems', {
            source(this: SearchFilter) {
                return this.prj.workitems;
            },
            creator(this: SearchFilter) {
                return [] as Prj.Workitem<Prj>[];
            },
        })) readonly workitems!: Prj.Workitem<Prj>[];

        get searchelements(): TPrj.ISearchElement[] {
            const { prj: { projecttypes }, workitems } = this;
            return _.transform(projecttypes, (p, { stages }) => {
                _.transform(stages, (p, stage) => {
                    let temp: TPrj.ISearchElement | undefined = p.firstOf(se => se.name == stage.name);
                    if (!temp) temp = { name: stage.name, ids: [], children: [] }, p.push(temp);
                    temp.ids!.push(stage.id);

                    stage.workitems.forEach(wt => {
                        if (!temp.children!.firstOf((child) => child.id == wt.id) && ((workitems?.length ?? 0) <= 0 || workitems.firstOf(ws => ws.id == wt.id))) {
                            temp.children!.push({ id: wt.id, name: wt.name });
                        }
                    });
                }, p);
            }, <TPrj.ISearchElement[]>[]);
        };

        get stagecount(): number {
            return this.searchelements?.length ?? 0;
        }

        get workitemcount(): number {
            return _.sumBy(this.searchelements, se => (se.children?.length ?? 0));
        }

        checkstage(id: string): string | undefined {
            const stage = this.searchelements.find(se => se.ids?.has(id));
            return stage?.ids?.[0];
        }

        checkworkitem(id: string): boolean {
            for (const se of this.searchelements) {
                for (const p of (se.children ?? [])) {
                    if (p.id == id) return true;
                }
            }

            return false;
        }

        get selectedYears(): number[] {
            const { prj: { dict: { currentYear, rangeyearmin, rangeyearmax } } } = this;
            const { nowyear, start, end } = this;

            if (nowyear) {
                return [currentYear, currentYear];
            }

            const years = [rangeyearmin, rangeyearmax];
            if (start) years[0] = start.getFullYear();
            if (end) years[1] = end.getFullYear();
            return years;
        }

        get searchfilterparam() {
            const { prj: { dict: { AllStatus, PrjConstrTypes } } } = this;

            const param = {
                year: this.selectedYears,
                owndept: this.dept.map(v => ({ id: v.id })),
                plan: this.plan.map(v => ({ id: v.id })),
                workitem: {
                    id: this.workitems.map(v => v.id)
                },
                // constrstatus: _.transform(this.constrstatus, (res, v: string | number) => {
                //     res.push(AllStatus.indexed[v].key);
                // }, <string[]>[]),
                // constrtype: _.transform(this.constrtype, (res, v: string | number) => {
                //     res.push(PrjConstrTypes.indexed[v].key);
                // }, <string[]>[])
            };

            return param;
        }

        constructor(
            public org: Org,
            public prj: Prj,
            _searchfilter: TPrj.ISearchFilter = {}
        ) {
            super(_searchfilter);
        }
    }

    @Editor.Collections(SearchFilter)
    export class SearchFilters extends XArray<SearchFilter, TPrj.ISearchFilter, 'id'> {
        constructor(
            public org: Org,
            public prj: Prj,
            _searchfilters: TPrj.ISearchFilter[] = []
        ) {
            super('id', ..._searchfilters);
        }

        override isT(val: SearchFilter | TPrj.ISearchFilter): val is SearchFilter {
            return val instanceof SearchFilter;
        }

        override construct(val: TPrj.ISearchFilter): SearchFilter {
            return new SearchFilter(this.org, this.prj, val);
        }
    }

    export class SearchFiltersHost extends Editor.Editor<SearchFiltersHost, TPrj.IPrj> {
        private readonly _props = Prop.Of(this);

        get org(): Org {
            const { _props: props, app } = this;
            return props.org || (
                props.org = Sys.cast_org(app.org, Org)
            )
        }

        get prj(): Prj {
            const { _props: props, app } = this;
            return props.prj || (
                props.prj = Sys.cast_prj(app.prj, Prj)
            )
        }

        constructor(
            public app: TApp.IAppService
        ) {
            super(app.backface.prj);
        }

        @JSON.Key()
        @Backface.Entity(() => SearchFilter, ["prj", "search_filters", true])
        @Editor.Field(SearchFiltersHost, Editor.Owneds('searchFilters', {
            creator(this: SearchFiltersHost) {
                const { org, prj } = this;
                return new SearchFilters(org, prj);
            },
        })) readonly searchFilters!: SearchFilters;
    }
}

export namespace Prj {
    export type ProjProjectType = Prj.ProjectType<Prj.Project>;
    export type ProjStage = Prj.Stage<ProjProjectType>;
    export type ProjWorkset = Prj.Workset<ProjStage>;
    export type ProjWorkitem = Prj.Workitem<Project | ProjWorkset>;
    export type ProjCheckpoint = Prj.Checkpoint<ProjWorkitem>;

    export type PrjProjectType = Prj.ProjectType<Prj>;
    export type PrjStage = Prj.Stage<Prj | PrjProjectType>;
    export type PrjWorkset = Prj.Workset<Prj | PrjStage>;
    export type PrjWorkitem = Prj.Workitem<Prj | PrjWorkset>;
    export type PrjCheckpoint = Prj.Checkpoint<Prj | PrjWorkitem>;

    export type ProjStages = Prj.Stages<ProjProjectType>
    export type PrjStages = Prj.Stages<Prj | PrjProjectType>;

    export type ProjWorksets = Prj.Worksets<ProjStage>;
    export type PrjWorksets = Prj.Worksets<Prj | PrjStage>;

    export type ProjWorkitems = Prj.Workitem<ProjWorkset>;
    export type PrjWorkitems = Prj.Workitems<Prj | PrjWorkset>;

    export type ProjCheckpoints = Prj.Checkpoints<ProjWorkitem>;
    export type PrjCheckpoints = Prj.Checkpoints<Prj | PrjWorkitem>;

    export interface SumProjectData {
        id?: string,
        name?: string,
        pool?: string,
        owncompany?: string,
        owndept?: string,
        location?: string,
        positonDistrict?: string,
        description?: string,
        constrtype?: string,
        constrstatus?: string,
        memo?: string,

        planname?: string,

        budgetstartend?: string,
        budgetamount?: string,
        budgetyearamount?: string,
        budgetyeartarget?: string,
        budgetyearconsume?: string,
        budgetyearpercent?: string,

        actorheader?: string,

        actorleaders?: string,
        actorleadersdept?: string,

        actoractors?: string,
        actoractorsdept?: string,

        actordomains?: string,
        actordomainsdept?: string,

        actorsites?: string,
        actorsitesdept?: string,
        actorprjowner?: string,

        inspectorkpileader?: string,
        inspectorkpimembers?: string,

        progresscomment?: string,
        problems?: string
    };
}