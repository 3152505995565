<div class="home w-100 h-100">
    <div class="position-relative w-100 h-100">
        <ngx-amap class="h-100 w-100" [zoom]="10" [resizeEnable]="true" [center]="targetDistrict.targetDistrict?.center"
            mapStyle="amap://styles/whitesmoke" mapStyle="amap://styles/01393e9fec4d62c7c362b74355e55adf">
            <ngx-amap-tool-bar></ngx-amap-tool-bar>
            <ngx-amap-polygon #targetDistrict="ngxAMapPolygon" [district]="city_district" [strokeColor]="'#256edc'"
                [strokeOpacity]="1" [strokeWeight]="2" [fillColor]="'#256edc'" [fillOpacity]="0.15">
            </ngx-amap-polygon>

            <ngx-amap-marker-clusterer #markerClusterer="ngxAMapMarkerClusterer" [gridSize]="80"
                [styles]="customStyles">
                @for (project of filteredprojects; track $index) {
                <ngx-amap-marker [inCluster]="true" [position]="project.positionXY ?? markerNull"
                    [hidden]="!!!project.positionXY" [offset]="{ x: -15, y: -15 }" [icon]="mark_icon"
                    [title]="project.name" [label]="{ content: project.name, direction: 'top' }"
                    (naClick)="onProjectDetail(project)">
                </ngx-amap-marker>
                }
            </ngx-amap-marker-clusterer>
        </ngx-amap>

        <div class="map-panel h-5 w-30 m-0 px-1 d-flex flex-row align-items-center">
            <div class="flex-fill">
                <input matInput class="w-100 h-100 bg-transparent border-0"
                    placeholder="{{'general.searchprj' | translate}}" [matAutocomplete]="autoPanel"
                    [(ngModel)]="searchtext" />
            </div>

            <div ngbDropdown class="bg-transparent border-0 flex-none" container="body" [autoClose]="true">
                <button type="button" class="btn btn-sm btn-outline-primary" ngbDropdownToggle>
                    {{ selectedYear }}{{'general.year' | translate}}
                </button>
                <div ngbDropdownMenu>
                    <div class="max-vh-50 overflow-x-hidden overflow-y-auto d-flex flex-column align-items-stretch">
                        @for (year of app._.range(app.dict.rangeyearmin, app.dict.rangeyearmax + 1); track $index) {
                        <div ngbDropdownItem class="p-0 overflow-visible">
                            <button class="w-100 btn btn-sm" [class.btn-outline-success]="year == selectedYear"
                                (click)="selectedYear = year">
                                {{ year }}{{'general.year' | translate}}
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <mat-autocomplete #autoPanel="matAutocomplete">
                @for (project of filteredprojects; track $index) {
                @let projectname = (project.positionXY ? '' : mapnotset) + (project.name ?? '');

                <div *ngIf="projectname.includes(searchtext)" class="d-flex flex-row align-items-center">
                    <mat-option [style.color]="project.positionXY ? '' : 'red'" [value]="project"
                        (click)="searchtext = project.name; onProjectDetail(project);">
                        <p class="m-0 flex-fill text-truncate">
                            {{projectname}}
                        </p>
                    </mat-option>
                    @if (project.positionXY) {
                    <button class="flex-none" mat-icon-button matTooltip="{{ 'general.mapcenter' | translate }}"
                        (click)="markerClusterer.locate($index)">
                        <i class="material-icons">place</i>
                    </button>
                    }
                </div>
                }
            </mat-autocomplete>
        </div>

        <div class="map-panel h-35 w-60 m-0 d-flex flex-row align-items-center border-1 position-absolute start-50 translate-middle-x topend-unset bottom-5"
            [puDashboard]="{dashboard: 'home'}">
        </div>
    </div>
</div>