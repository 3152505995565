<ng-template id="text" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="cellField.data" [required]="header.must"
            [readonly]="readonly" />
    </mat-form-field>
</ng-template>

<ng-template id="label" let-header="header" let-editor="editor">
    <mat-toolbar style="
      background-color: rgb(165 42 42);
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      margin-bottom: 15px;
      height: auto;
    ">
        <mat-icon>alert</mat-icon>
        <span>{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate 
        }}</span>
    </mat-toolbar>
</ng-template>

<ng-template id="hint" let-header="header" let-editor="editor">
    <mat-toolbar style="
      margin-bottom: -15px;
      margin-top: 5px;
      height: auto;
    ">
        <mat-icon>alert</mat-icon>
        <span>{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate 
        }}</span>
    </mat-toolbar>
</ng-template>

<ng-template id="texts" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [readonly]="readonly"
            [(ngModel)]="cellField.data" [required]="header.must" class="p-1"></textarea>
    </mat-form-field>
</ng-template>

<ng-template id="email" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <input matInput type="email" autocomplete="off" [readonly]="readonly" [(ngModel)]="cellField.data"
            [required]="header.must" />
    </mat-form-field>
</ng-template>

<ng-template id="password" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label #temp>{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate 
        }}</mat-label>

        <input matInput autocomplete="off" [readonly]="readonly" [type]="BOf(temp).hide ? 'password' : 'text'"
            [(ngModel)]="cellField.data" [required]="header.must" />
        <button mat-icon-button matSuffix (click)="BOf(temp).hide = !BOf(temp).hide" [attr.aria-label]="'隐藏密码'"
            [attr.aria-pressed]="BOf(temp).hide">
            <mat-icon>{{ BOf(temp).hide ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
    </mat-form-field>
</ng-template>

<ng-template id="number" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate 
        }}</mat-label>
        <input matInput type="number" autocomplete="off" [readonly]="readonly" [(ngModel)]="cellField.data"
            [required]="header.must" />
    </mat-form-field>
</ng-template>

<ng-template id="phone" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <input matInput type="tel" autocomplete="off" [(ngModel)]="cellField.data" [required]="header.must"
            [readonly]="readonly" />
    </mat-form-field>
</ng-template>

<ng-template id="bool" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate 
        }}</mat-label>
        <input matInput class="d-none" />
        <div class="toggle toggle--knob">
            <input type="checkbox" class="toggle--checkbox" id="field-form-toggle--{{ header.key }}"
                [(ngModel)]="cellField.data" [disabled]="readonly" />
            <label class="ms-0 toggle--btn" for="field-form-toggle--{{ header.key }}">
                <span class="toggle--feature" [attr.data-label-off]="'general.no' | translate"
                    [attr.data-label-on]="'general.yes' | translate"></span></label>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="date" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <input matInput autocomplete="off" [min]="mindate" [max]="maxdate" [matDatepicker]="picker"
            [(ngModel)]="cellField.data" [required]="header.must" [readonly]="readonly" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [disabled]="readonly"></mat-datepicker>
    </mat-form-field>
</ng-template>

<ng-template id="datenow" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <input matInput autocomplete="off" [min]="mindate" [max]="nowdate" [matDatepicker]="picker"
            [(ngModel)]="cellField.data" [required]="header.must" [readonly]="readonly" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [disabled]="readonly"></mat-datepicker>
    </mat-form-field>
</ng-template>

<ng-template id="option" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-readonly="readonly"
    let-headers="headers" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }} </mat-label>

        <input matInput class="d-none" />
        <div class="d-flex flex-row align-items-stretch">
            <mat-chip-set class="btn btn-sm flex-fill p-0 m-0">
                <mat-chip [removable]="cellField.data && !readonly" (removed)="cellField.data = null"
                    class="w-100 p-0 m-0">
                    {{ getOptionCellText(cellField.data, header, headers) }}
                    <mat-icon *ngIf="cellField.data && !readonly" matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-set>

            <div ngbDropdown #dropDown="ngbDropdown" role="group" container="body" [autoClose]="true" display="dynamic"
                *ngIf="!readonly" class="btn-group btn-group-sm">
                <button #button type="button" ngbDropdownToggle
                    class="btn btn-sm d-flex list-group-horizontal align-items-center">
                    <mat-icon>playlist_add_check</mat-icon>
                </button>
                <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" (click)="dropDown.close();">
                    <div class="vh-40" style="
                        overflow: hidden auto !important;
                        background-color: rgb(246 244 240);
                    ">
                        <mat-selection-list (selectionChange)="cellField.data = $event.options[0].value"
                            [multiple]="false">
                            <ng-container *ngFor="let opt of optionSourceRows.data">
                                <mat-list-option *ngIf="
                                    opt != editor &&
                                    !isSelected([cellField.data], opt) &&
                                    (!BOf(button).filter ||
                                    app._.toString(
                                        getOptionCellText(opt, header, headers)
                                    ).includes(BOf(button).filter))
                                " class="mx-0" ngbDropdownItem [value]="opt">
                                    <span class="mat-chip" style="font-size: 14px">{{
                                        getOptionCellText(opt, header, headers)
                                    }}</span>
                                </mat-list-option>
                            </ng-container>
                        </mat-selection-list>
                    </div>
                    <div class="dropdown-divider mt-0 mb-1"></div>
                    <input class="w-100 px-2 border-0" placeholder="{{ 'general.searchhint' | translate }}"
                        [(ngModel)]="BOf(button).filter" (click)="$event.stopPropagation();" />
                </div>
            </div>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="options" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-readonly="readonly"
    let-headers="headers" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }} </mat-label>

        <input matInput class="d-none" />
        <div class="d-flex flex-row align-items-stretch">
            <mat-chip-set class="btn btn-sm flex-fill p-0 m-0" cdkDropList cdkDropListOrientation="mixed"
                (cdkDropListDropped)="drop(cellField.data, $event)">
                @for (val of cellField.data; track val) {
                <mat-chip class="p-0 m-0" (removed)="cellField.data.destroy(val)" [removable]="!readonly" cdkDrag>
                    {{ getOptionCellText(val, header, headers) }}
                    <mat-icon *ngIf="!readonly" matChipRemove>cancel</mat-icon>
                </mat-chip>
                }

                <div ngbDropdown #dropDown="ngbDropdown" role="group" container="body" [autoClose]="true"
                    display="dynamic" *ngIf="!readonly" class="btn-group btn-group-sm">
                    <button #button type="button" ngbDropdownToggle
                        class="btn btn-sm d-flex list-group-horizontal align-items-center">
                        <mat-icon>add</mat-icon>
                    </button>

                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end" (click)="dropDown.close();">
                        <div class="vh-40"
                            style="overflow: hidden auto !important; background-color: rgb(246 244 240);">
                            <mat-selection-list (selectionChange)="cellField.data.create([$event.options[0].value])"
                                [multiple]="true">
                                <ng-container *ngFor="let opt of optionSourceRows.data">
                                    <mat-list-option *ngIf="!isSelected(cellField.data, opt) &&
                                        (!BOf(button).filter || app._.toString(
                                            getOptionCellText(opt, header, headers)
                                        ).includes(BOf(button).filter))
                                    " class="mx-0" ngbDropdownItem [value]="opt">
                                        <span class="mat-chip" style="font-size: 14px">{{
                                            getOptionCellText(opt, header, headers)
                                        }}</span>
                                    </mat-list-option>
                                </ng-container>
                            </mat-selection-list>
                        </div>
                        <div class="dropdown-divider mt-0 mb-1"></div>
                        <input class="w-100 px-2 border-0" placeholder="{{ 'general.searchhint' | translate }}"
                            [(ngModel)]="BOf(button).filter" (click)="$event.stopPropagation();" />
                    </div>
                </div>
            </mat-chip-set>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="enum, enums" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-readonly="readonly"
    let-headers="headers" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <mat-select [(value)]="cellField.data" [multiple]="app.editor.Value.isFieldType(header, 'multi')"
            [required]="header.must" [disabled]="readonly">
            <mat-option *ngFor="let opt of optionSourceRows.data" [value]="opt.value">{{
                (app._.isFunction(opt.title) ? opt.title(app, editor, opt) : opt.title) | translate
            }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>

<ng-template id="json" let-optionSourceRows="optionSourceRows" let-cellField="cellField" let-readonly="readonly"
    let-headers="headers" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>

        <input matInput class="d-none" [required]="header.must" />

        <json-editor #jsoneditor (change)="cellField.data = jsoneditor.get()" [options]="jsoneditoroptions"
            [data]="cellField.data"></json-editor>
    </mat-form-field>
</ng-template>

<ng-template id="command" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>
        <div class="d-flex flex-row align-items-center">
            <input matInput autocomplete="off" [(ngModel)]="cellField.data" [readonly]="true" />

            <button *ngIf="MapLocatable[poolOf(editor)]" mat-icon-button (click)="onCommand(header, editor)"
                matTooltip="{{ 'general.mapsetxy' | translate }}">
                <i class="material-icons">place</i>
            </button>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="pics" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>

        <input matInput class="d-none" />

        <div class="d-flex flex-column justify-content-start align-items-stretch">
            <mat-accordion>
                <mat-expansion-panel (closed)="temppanel.expanded = false" (opened)="temppanel.expanded = true"
                    class="card-frame" #temppanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="flex-fill">
                            <ngb-progressbar [max]="100" [height]="'100%'" [striped]="true" [animated]="true"
                                [type]="'success'" class="position-relative w-100"
                                [value]="cellField.data.uploader.progress"
                                *ngIf="cellField.data.uploader.queue.length > 0">{{ cellField.data.uploader.queue.length }}</ngb-progressbar>
                        </mat-panel-title>

                        <mat-panel-description class="flex-none" (click)="stopevent($event)">
                            <label for="select-onsite-file" style="line-height: 1"
                                class="btn btn-sm btn-primary m-0 p-0" *ngIf="!readonly && !cellField.data.exceedamount"
                                matTooltip="{{ 'general.uploadpic' | translate }}" mat-flat-button>
                                <input multiple ng2FileSelect (click)="temppanel.expanded = true"
                                    [uploader]="cellField.data.uploader" id="select-onsite-file" class="d-none"
                                    type="file" />
                                <i class="material-icons">add</i>
                            </label>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
                    <div (fileOver)="BOf(tempfiledrop).ng2FileDropOver = true"
                        (onFileDrop)="BOf(tempfiledrop).ng2FileDropOver = false"
                        [ngClass]="{ ng2FileDropOver: BOf(tempfiledrop).ng2FileDropOver }"
                        [uploader]="cellField.data.uploader" class="app-card-pics" ng2FileDrop #tempfiledrop>
                        <mat-card class="app-card-pic w-100 m-1 p-1 d-flex flex-row" *ngFor="let pic of cellField.data">
                            <mat-card-content
                                class="mb-0 d-flex flex-row justify-content-start align-items-stretch flex-fill">
                                <div class="mat-card-image border m-0 p-0 me-1">
                                    <ng-container [ngSwitch]="pic.content_type">
                                        <video async *ngSwitchCase="'video'" (click)="openPics(cellField.data, pic)"
                                            (canplaythrough)="pic.onLoaded($event)" class="h-100 w-100 m-0 p-0">
                                            <source [src]="pic.url | safe : 'url'"
                                                type="{{ pic.content_type + '/' + pic.mime_type }}" />
                                            Your browser does not support HTML5 video.
                                        </video>

                                        <img async *ngSwitchDefault priority [src]="pic.url | safe : 'url'" placeholder
                                            (load)="pic.onLoaded($event)" (click)="openPics(cellField.data, pic)"
                                            [style.background-repeat]="'no-repeat'"
                                            [style.background-position]="'center'" [style.object-fit]="'contain'"
                                            class="h-100 w-100 m-0 p-0" />
                                    </ng-container>
                                    <ngb-progressbar [max]="100" [height]="'5px'" [striped]="true" [animated]="true"
                                        [style.bottom]="'10px'" class="position-relative w-100"
                                        [value]="pic.fileItem?.progress"
                                        [type]="pic.fileItem?.isError ? 'danger' : 'success'"
                                        *ngIf="pic.fileItem"></ngb-progressbar>
                                </div>

                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                    class="border h-100 m-0 p-1" [(ngModel)]="pic.comment"
                                    [readonly]="readonly"></textarea>
                            </mat-card-content>
                            <mat-card-actions *ngIf="!readonly" class="float-right text-right m-0 p-0">
                                <button mat-button class="p-0 m-0" (click)="cellField.data.destroy(pic)"
                                    style="min-width: 10px; line-height: 0">
                                    <i class="material-icons">clear</i>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="files" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>

        <input matInput class="d-none" />

        <div class="d-flex flex-column justify-content-start align-items-stretch">
            <mat-accordion>
                <mat-expansion-panel (closed)="temppanel.expanded = false" (opened)="temppanel.expanded = true"
                    class="card-frame" #temppanel>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="flex-fill">
                            <ngb-progressbar [max]="100" [height]="'100%'" [striped]="true" [animated]="true"
                                [type]="'success'" class="position-relative w-100"
                                [value]="cellField.data.uploader.progress"
                                *ngIf="cellField.data.uploader.queue.length > 0">{{ cellField.data.uploader.queue.length }}</ngb-progressbar>
                        </mat-panel-title>

                        <mat-panel-description class="flex-none" (click)="stopevent($event)">
                            <label for="select-onsite-file" style="line-height: 1"
                                class="btn btn-sm btn-primary m-0 p-0" *ngIf="!readonly && !cellField.data.exceedamount"
                                matTooltip="{{ 'general.uploadxfile' | translate }}" mat-flat-button>
                                <input multiple ng2FileSelect (click)="temppanel.expanded = true"
                                    [uploader]="cellField.data.uploader" id="select-onsite-file" class="d-none"
                                    type="file" />
                                <i class="material-icons">add</i>
                            </label>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
                    <div (fileOver)="BOf(tempfiledrop).ng2FileDropOver = true"
                        (onFileDrop)="BOf(tempfiledrop).ng2FileDropOver = false"
                        [ngClass]="{ ng2FileDropOver: BOf(tempfiledrop).ng2FileDropOver }"
                        [uploader]="cellField.data.uploader" class="app-card-files" ng2FileDrop #tempfiledrop>
                        <mat-card class="app-card-file w-100 m-1 p-1 d-flex flex-row"
                            *ngFor="let file of cellField.data">
                            <mat-card-content
                                class="mb-0 d-flex flex-row justify-content-start align-items-stretch flex-fill">
                                <div class="mat-card-image border m-0 p-0 me-1">
                                    <ng-container [ngSwitch]="file.content_type">
                                        <video async *ngSwitchCase="'video'" (click)="openXFiles(cellField.data, file)"
                                            (canplaythrough)="file.onLoaded($event)" class="h-100 w-100 m-0 p-0">
                                            <source [src]="file.url | safe : 'url'"
                                                type="{{ file.content_type + '/' + file.mime_type }}" />
                                            Your browser does not support HTML5 video.
                                        </video>

                                        <ng-container *ngSwitchCase="'application'">
                                            <img async priority placeholder class="h-100 w-100 m-0 p-0"
                                                [src]="app.dict.getFormatObj(file.mime_type)?.icon | safe : 'url'"
                                                (click)="openXFiles(cellField.data, file)"
                                                (load)="file.onLoaded($event)" [style.background-repeat]="'no-repeat'"
                                                [style.background-position]="'center'" [style.object-fit]="'contain'" />
                                        </ng-container>

                                        <img async *ngSwitchDefault priority [src]="file.url | safe : 'url'" placeholder
                                            (load)="file.onLoaded($event)" (click)="openXFiles(cellField.data, file)"
                                            [style.background-repeat]="'no-repeat'"
                                            [style.background-position]="'center'" [style.object-fit]="'contain'"
                                            class="h-100 w-100 m-0 p-0" />
                                    </ng-container>
                                    <ngb-progressbar [max]="100" [height]="'5px'" [striped]="true" [animated]="true"
                                        [style.bottom]="'10px'" class="position-relative w-100"
                                        [value]="file.fileItem?.progress"
                                        [type]="file.fileItem?.isError ? 'danger' : 'success'"
                                        *ngIf="file.fileItem"></ngb-progressbar>
                                </div>

                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                    class="border h-100 m-0 p-1" [(ngModel)]="file.comment"
                                    [readonly]="readonly"></textarea>
                            </mat-card-content>
                            <mat-card-actions *ngIf="!readonly" class="float-right text-right m-0 p-0">
                                <button mat-button class="p-0 m-0" (click)="cellField.data.destroy(file)"
                                    style="min-width: 10px; line-height: 0">
                                    <i class="material-icons">clear</i>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="problems" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>

        <input #temp matInput class="d-none" />

        <div class="d-flex flex-column justify-content-start align-items-stretch">
            <mat-accordion>
                <mat-expansion-panel (closed)="BOf(temp).panelOpenState = false"
                    (opened)="BOf(temp).panelOpenState = true" [expanded]="BOf(temp).panelOpenState" class="card-frame">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="flex-fill"> </mat-panel-title>

                        <mat-panel-description class="flex-none" (click)="stopevent($event)">
                            <label style="line-height: 1" class="btn btn-sm btn-primary m-0 p-0"
                                (click)="cellField.data.createnew(); BOf(temp).panelOpenState = true"
                                matTooltip="{{ 'general.siteproblem' | translate }}" *ngIf="!readonly" mat-flat-button>
                                <i class="material-icons">add</i>
                            </label>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-divider [inset]="true" class="w-100 mx-0"></mat-divider>
                    <div class="app-card-problems">
                        <mat-card class="app-card-problem m-0 mt-1 p-1 d-flex flex-row"
                            *ngFor="let problem of cellField.data">
                            <mat-card-content class="mb-0 flex-fill">
                                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                    class="border h-100 m-0 p-1" [(ngModel)]="problem.problem"
                                    [readonly]="readonly"></textarea>
                            </mat-card-content>
                            <mat-card-actions *ngIf="!readonly" class="float-right text-right m-0 p-0">
                                <button mat-button class="p-0 m-0" (click)="cellField.data.destroy(problem)"
                                    style="min-width: 10px; line-height: 0">
                                    <i class="material-icons">clear</i>
                                </button>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-form-field>
</ng-template>

<ng-template id="budgets-yearly" let-cellField="cellField" let-readonly="readonly" let-headers="headers"
    let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate 
        }}</mat-label>

        <input matInput class="d-none" />

        <table class="w-100">
            <colgroup>
                <col />
                <col width="30%" />
                <col width="30%" />
                <col width="30%" />
            </colgroup>
            <tr>
                <th class="text-nowrap text-center">
                    {{ "general.annual" | translate }}
                </th>
                <th class="text-nowrap text-center">
                    {{ "budget.amount" | translate }}
                </th>
                <th class="text-nowrap text-center">
                    {{ "budget.consume" | translate }}
                </th>
                <th class="text-nowrap text-center">
                    {{ "budget.percent" | translate }}
                </th>
            </tr>
            @for (budget of cellField.data; track $index) {
            <tr>
                <td class="text-nowrap text-center py-1 px-2">
                    {{ budget.start | date : app.sys.yearFormat }}
                </td>
                <td class="py-1">
                    <div class="card-frame h-100 w-100">
                        <input [readonly]="readonly" [(ngModel)]="budget.amount" class="p-1 h-100 w-100"
                            autocomplete="off" type="number" matInput />
                    </div>
                </td>
                <td class="py-1">
                    <div class="card-frame h-100 w-100">
                        <input [readonly]="readonly || !app.consumeByProject" [(ngModel)]="budget.consume"
                            class="p-1 h-100 w-100" autocomplete="off" type="number" matInput />
                    </div>
                </td>
                <td class="text-nowrap text-center py-1 px-2">
                    {{ budget.percent }}%
                </td>
            </tr>
            <tr>
                <td colspan="4" class="pt-2">
                    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
                        <mat-label class="m-1 d-inline-block">{{ "budget.target" | translate }}</mat-label>
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" class="p-1"
                            [readonly]="readonly" [(ngModel)]="budget.target"></textarea>
                    </mat-form-field>
                </td>
            </tr>
            }
        </table>
    </mat-form-field>
</ng-template>

<ng-template id="tree" let-optionSource="optionSource" let-cellField="cellField" let-readonly="readonly"
    let-headers="headers" let-header="header" let-editor="editor">
    <mat-form-field floatLabel="always" appearance="outline" class="w-100">
        <mat-label class="m-1 d-inline-block">{{
            (app._.isFunction(header.title) ? header.title(app, editor) : header.title) | translate
        }}</mat-label>

        <input matInput [ngModel]="cellField.data.length > 0 ? 'have' : ''" class="d-none" required />

        <mat-tree [gov-nested-tree]="{source: optionSource.data, field: cellField.data, leafonly: true, header: header}"
            [dataSource]="nesttree.dataSource" [treeControl]="nesttree.control" #nesttree="GovNestedTree">
            <mat-tree-node *matTreeNodeDef="let node">
                <mat-checkbox (change)="$event.checked ? nesttree.select(node) : nesttree.deselect(node)"
                    [disabled]="readonly" [stopDefault]="false" event.stop="click" [checked]="nesttree.isselected(node)"
                    class="checklist-leaf-node">
                    {{ (app._.isFunction(node.name) ? node.name(app, node) : node.name) | translate }}
                    <!--【{{ node.name }}:{{ node.id }}】-->
                </mat-checkbox>
            </mat-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: nesttree.whenChecker" #nestnode="matNestedTreeNode"
                matTreeNodeToggle>
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{
                                nestnode.isExpanded
                                ? "expand_more"
                                : "chevron_right"
                            }}
                        </mat-icon>
                    </button>

                    <mat-checkbox (change)="$event.checked ? nesttree.select(node) : nesttree.deselect(node)"
                        [disabled]="readonly" [stopDefault]="false" event.stop="click"
                        [checked]="nesttree.isselected(node)" [indeterminate]="nesttree.isindeterminate(node)">
                        {{ (app._.isFunction(node.name) ? node.name(app, node) : node.name) | translate }}
                        <!--【{{ node.name }}:{{ node.id }}】-->
                    </mat-checkbox>
                </div>

                <div [class.d-none]="!nestnode.isExpanded" role="group">
                    <ng-container matTreeNodeOutlet></ng-container>
                </div>
            </mat-nested-tree-node>
        </mat-tree>
    </mat-form-field>
</ng-template>

<ng-template id="editor" let-cellField="cellField" let-readonly="readonly" let-headers="headers" let-header="header"
    let-editor="editor">
    <ng-container *ngTemplateOutlet="
      fields;
      context: {
        readonly: readonly,
        editor: cellField.data,
        headers: cellField.data.$fields_,
        exclude: cellField.data.$exclude_
      }
    "></ng-container>
</ng-template>

<ng-template #fields let-readonly="readonly" let-exclude="exclude" let-headers="headers" let-editor="editor">
    @for (header of headers; track $index) {
    <!-- header?.key != 'id' && -->
    <ng-container *ngIf="
        header?.key != 'id' &&
        !(exclude && exclude[header.key]) &&
        isVisible(header, headers, editor) &&
        !app.editor.Value.isFieldType(header, 'onlycolumn')
      ">
        <ng-container *ngTemplateOutlet="
                fieldtpls.get(getFieldTemplate(header)) || fieldtpls.get('text') || null;
                context: {
                    cellField: getCellField(editor, header, headers),
                    optionSource: getOptionSource(editor, header, headers),
                    optionSourceRows: getOptionSourceRows(editor, header, headers),
                    readonly: readonly || isReadonly(header, headers, editor),
                    headers: headers,
                    header: header,
                    editor: editor
                }
            "></ng-container>
    </ng-container>
    }
</ng-template>

<ng-template #xfields let-section="section">
    <ng-container *ngTemplateOutlet="
        fields;
        context: {
        readonly:
            readonly ||
            (app._.isFunction(section.readonly)
            ? section.readonly()
            : section.readonly),
        exclude: section.exclude,
        headers: section.headers,
        editor: section.editor
        }
    "></ng-container>
</ng-template>

<ng-template #xform let-xform="xform">
    <div class="h-100 w-100 d-flex flex-column align-items-stretch">
        <div class="w-100 p-2 pt-3 overflow-auto d-flex flex-column align-items-stretch flex-fill">

            @if (isSection(xform)) {
            <ng-container *ngTemplateOutlet="
                xfields; context: {
                    section: xform
                }
            "></ng-container>
            } @else {
            <ng-container *ngFor="let section of xform.sections; let index=index">
                <div *ngIf="index !=0" class="mt-2 p-2 pt-3 w-100 card-frame d-flex flex-column align-items-stretch">
                    <ng-container *ngTemplateOutlet="
                        xfields; context: {
                            section: section
                        }
                    "></ng-container>
                </div>

                <ng-container *ngIf="index == 0">
                    <ng-container *ngTemplateOutlet="
                        xfields; context: {
                            section: section
                        }
                    "></ng-container>
                </ng-container>
            </ng-container>
            }

        </div>

        <div class="text-center app-action-bar" *ngIf="!noactionbar(xform)">
            <button class="me-2" color="primary" mat-flat-button (click)="$save_(xform)" [disabled]="!$changed_(xform)">
                {{ "general.save" | translate }}
            </button>
            <button color="primary" mat-flat-button (click)="$cancel_(xform)" [disabled]="!$changed_(xform)">
                {{ "general.cancel" | translate }}
            </button>
        </div>
    </div>
</ng-template>


@if (forms && forms.length == 1) {
<ng-container *ngTemplateOutlet="
    xform; context: {
        xform: forms[0]
    }
"></ng-container>
}@else if (forms) {
<mat-tab-group class="w-100 h-100">
    @for (item of forms; track $index) {
    <mat-tab *ngIf="!(app._.isFunction(item.invisible) ? item.invisible(app, item) : item.invisible)">
        <ng-template mat-tab-label>
            <mat-icon>thumb_up</mat-icon>
            {{ ((app._.isFunction(item.title) ? item.title(app, item) : item.title) ?? '') | translate }}
        </ng-template>

        <ng-template matTabContent>
            <ng-container *ngTemplateOutlet="
                    xform; context: {
                        xform: item
                    }
                "></ng-container>
        </ng-template>
    </mat-tab>
    }
</mat-tab-group>
}