import { ChangeDetectorRef, Component, ContentChildren, ElementRef, Inject, Input, OnDestroy, Optional, output, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { first, Subscription } from 'rxjs';

import { NgxCMenuEventClose, NgxCMenuService, NgxCMenuEventClick } from '../../shared/ngx-ctxmenu.service';
import { NGX_CMENU_CONFIG, NgxCMenuConfig, NgxCMenuLinkConfig } from '../../shared/ngx-ctxmenu.config';
import { NgxCMenuItemDirective } from '../ngx-ctxmenu-item.directive';

export interface NgxCMenuMouseLocation {
    left?: string;
    marginLeft?: string;
    marginTop?: string;
    top?: string;
}

@Component({
    selector: 'ngx-cmenu',
    styleUrls: ['ngx-ctxmenu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    template: ` `,
    exportAs: 'ngxCMenu'
})
export class NgxCMenuComponent implements OnDestroy {
    @Input() readonly useBootstrap4: boolean = false;
    @Input() readonly autoFocus: boolean = false;
    @Input() disabled: boolean = false;
    @Input() menuClass: string = '';

    readonly close = output<NgxCMenuEventClose>();
    readonly open = output<NgxCMenuEventClick>();

    @ContentChildren(NgxCMenuItemDirective)
    menuItems = new QueryList<NgxCMenuItemDirective>();

    @ViewChild('menu', { static: false })
    menuElement!: ElementRef;

    private visibleMenuItems: NgxCMenuItemDirective[] = [];
    private readonly subscription = new Subscription();
    private readonly links: NgxCMenuLinkConfig[] = [];
    private event?: MouseEvent | KeyboardEvent;
    item: any;

    constructor(
        private readonly _contextMenuService: NgxCMenuService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly elementRef: ElementRef,

        @Optional()
        @Inject(NGX_CMENU_CONFIG)
        private readonly options?: NgxCMenuConfig
    ) {
        if (options) {
            this.autoFocus = !!options.autoFocus;
            this.useBootstrap4 = !!options.useBootstrap4;
        }

        this.subscription.add(_contextMenuService.show.subscribe(
            menuEvent => {
                this.onMenuEvent(menuEvent);
            }
        ));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onMenuEvent(menuEvent: NgxCMenuEventClick): void {
        if (this.disabled) {
            return;
        }

        const { contextMenu, event, item } = menuEvent;
        if (contextMenu && contextMenu !== this) {
            return;
        }

        this.item = item;
        this.event = event;
        this.setVisibleMenuItems();
        this._contextMenuService.openContextMenu({
            ...menuEvent,
            menuItems: this.visibleMenuItems,
            menuClass: this.menuClass
        });

        this._contextMenuService.close.pipe(
            first()
        ).subscribe(
            closeEvent => this.close.emit(closeEvent)
        );

        this.open.emit(menuEvent);
    }

    isMenuItemVisible(menuItem: NgxCMenuItemDirective): boolean {
        return this.evaluateIfFunction(menuItem.visible);
    }

    setVisibleMenuItems(): void {
        this.visibleMenuItems = this.menuItems.filter(
            menuItem => this.isMenuItemVisible(menuItem)
        );
    }

    evaluateIfFunction(value: any): any {
        if (value instanceof Function) {
            return value(this.item);
        }

        return value;
    }
}