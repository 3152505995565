import { OnDestroy, SimpleChanges, OnChanges, QueryList, AfterContentInit, NgZone, Directive, Input, ContentChildren, output } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { Observable, Subscription, zip } from 'rxjs';

import { NgxAMapInfoWindowComponent } from './ngx-amap-info-window.component/ngx-amap-info-window.component';
import { NgxAMapCreaterService } from '../shared/inner/ngx-amap.creater.service';
import { NgxAMapMarkerService } from '../shared/inner/ngx-amap-marker.service';
import { AMapMarkerLabelService } from '../shared/amap-marker-label.service';
import { AMapEventBinderService } from '../shared/amap-binder-event.service';
import { AMapLoggerService } from '../shared/amap-logger.service';
import { AMapPixelService } from '../shared/amap-pixel.service';
import { AMapIconService } from '../shared/amap-icon.service';

import { AMapOverlay, OverlayOptions } from '../base/amap-overlay';
import { ChangeFilter, getOptions } from '../utils/options';

const TAG = 'ngx-amap-marker';
const ALL_OPTIONS: Extract<
    keyof NgxAMapMarkerDirective,
    keyof AMap.Marker.Options
>[] = [
        ...OverlayOptions,
        'position',
        'anchor',
        'offset',
        'icon',
        'content',
        'topWhenClick',
        'raiseOnDrag',
        'visible',
        'zIndex',
        'angle',
        'autoRotation',
        'animation',
        'shadow',
        'title',
        'shape',
        'label',
    ];

@Directive({
    selector: 'ngx-amap-marker',
    exportAs: 'ngxAMapMarker',
    providers: [NgxAMapMarkerService],
    host: {
        '[style.display]': "'none'"
    }
})
export class NgxAMapMarkerDirective extends AMapOverlay<AMap.Marker> implements OnChanges, OnDestroy, AfterContentInit {
    private readonly __binder = this.binder.bindEvent<AMap.Marker.EventMap>();

    //#region options of AMap.Marker
    /**
     * 点标记在地图上显示的位置
     */
    @Input()
    position?: AMap.LocationValue;

    /**
     * 标记锚点
     */
    @Input()
    anchor?: AMap.Marker.Anchor;

    /**
     * 点标记显示位置偏移量
     */
    @Input()
    offset?: AMap.Pixel | AMap.IPixel;

    /**
     * 需在点标记中显示的图标
     */
    @Input()
    icon?: string | AMap.Icon | AMap.IIcon;

    /**
     * 点标记显示内容
     */
    @Input()
    content?: string | HTMLElement;

    /**
     * 鼠标点击时marker是否置顶
     */
    @Input()
    topWhenClick?: boolean;

    /**
     * 拖拽点标记时是否开启点标记离开地图的效果
     */
    @Input()
    raiseOnDrag?: boolean;

    /**
     * 点标记是否可见
     */
    @Input()
    visible?: boolean;

    /**
     * 点标记的叠加顺序
     */
    @Input()
    zIndex?: number;

    /**
     * 点标记的旋转角度
     */
    @Input()
    angle?: number;

    /**
     * 是否自动旋转
     */
    @Input()
    autoRotation?: boolean;

    /**
     * 点标记的动画效果
     */
    @Input()
    animation?: AMap.AnimationName;

    /**
     * 点标记阴影
     */
    @Input()
    shadow?: AMap.Icon | string | AMap.IIcon;

    /**
     * 鼠标滑过点标记时的文字提示
     */
    @Input()
    title?: string;

    /**
     * 可点击区域
     */
    @Input()
    shape?: AMap.MarkerShape;

    /**
     * 文本标注
     */
    @Input()
    label?: AMap.Marker.Label | AMap.IMarkerLabel;

    /**
     * 额外: 是否置顶
     */
    @Input()
    isTop?: boolean;

    /**
     * 额外: 是否隐藏
     */
    @Input()
    hidden: boolean = false;

    /**
     * 额外: 是否包含在点聚合中
     */
    @Input()
    inCluster: boolean = false;

    /**
     * 额外: 点击时是否显示信息窗体
     */
    @Input()
    openInfoWindow: boolean = true;
    //#endregion

    //#region events of AMap.Marker
    naReady = output<AMap.Marker<any>>();
    readonly naMouseOut = outputFromObservable(this.__binder(this.markercreater.get(), 'mouseout'));
    readonly naDragStart = outputFromObservable(this.__binder(this.markercreater.get(), 'dragstart'));
    readonly naDragging = outputFromObservable(this.__binder(this.markercreater.get(), 'dragging'));
    readonly naDragEnd = outputFromObservable(this.__binder(this.markercreater.get(), 'dragend'));
    readonly naMoving = outputFromObservable(this.__binder(this.markercreater.get(), 'moving'));
    readonly naMoveEnd = outputFromObservable(this.__binder(this.markercreater.get(), 'moveend'));
    readonly naMoveAlong = outputFromObservable(this.__binder(this.markercreater.get(), 'movealong'));
    //#endregion

    @ContentChildren(NgxAMapInfoWindowComponent)
    readonly infoWindowComponent: QueryList<NgxAMapInfoWindowComponent> = new QueryList();

    private inited: boolean = false;
    private subscription?: Subscription;

    constructor(
        protected override readonly binder: AMapEventBinderService,
        private readonly labelcreater: AMapMarkerLabelService,
        private readonly markercreater: NgxAMapMarkerService,
        private readonly amapcreater: NgxAMapCreaterService,
        private readonly pixelcreater: AMapPixelService,
        private readonly iconcreater: AMapIconService,
        private readonly logger: AMapLoggerService,
        private readonly ngZone: NgZone
    ) {
        super(markercreater, binder);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.markercreater.destroy();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const filter = ChangeFilter.from(changes);
        const marker = this.get();

        if (!this.inited) {
            // do not draw marker when no poistion defined.
            if (!this.position) {
                return;
            }

            this.amapcreater.get().subscribe(
                () => {
                    this.logger.d(TAG, 'initializing ...');

                    // bind info window events:
                    this.subscription = this.__binder(marker, 'click').subscribe(
                        () => {
                            if (this.openInfoWindow) {
                                this.infoWindowComponent.forEach(
                                    w => w.open()
                                );
                            }
                        }
                    );

                    const options = getOptions<NgxAMapMarkerDirective, AMap.Marker.Options>(this, ALL_OPTIONS);
                    if (this.icon) {
                        options.icon = this.iconcreater.create(this.icon);
                    }

                    if (this.shadow) {
                        options.shadow = this.iconcreater.create(this.shadow);
                    }

                    if (this.label) {
                        options.label = this.labelcreater.create(this.label);
                    }

                    if (this.offset) {
                        options.offset = this.pixelcreater.create(this.offset);
                    }

                    this.logger.d(TAG, 'options:', options);
                    this.markercreater.create(options, !this.inCluster).subscribe(
                        m => {
                            this.ngZone.run(() => {
                                this.naReady.emit(m)
                            });

                            this.logger.d(TAG, 'marker is ready.');
                        }
                    );

                    this.inited = true;
                    this.updateInfoWindow();
                    this.updateInfoWindowPosition();
                }
            );
        }

        zip(filter.has('icon'), marker).subscribe(
            ([v, m]) => {
                const icon = this.iconcreater.create(v);
                icon && m.setIcon(icon);
            }
        );

        zip(filter.has('shadow'), marker).subscribe(
            ([v, m]) => m.setShadow(this.iconcreater.create(v))
        );

        zip(filter.has('label'), marker).subscribe(
            ([v, m]) => m.setLabel(this.labelcreater.create(v))
        );

        zip(filter.has('title'), marker).subscribe(
            ([v, m]) => m.setTitle(v)
        );

        zip(filter.has('content'), marker).subscribe(
            ([v, m]) => m.setContent(v)
        );

        zip(filter.has('extData'), marker).subscribe(
            ([v, m]) => m.setExtData(v)
        );

        zip(filter.has('clickable'), marker).subscribe(
            ([v, m]) => m.setClickable(!!v)
        );

        zip(filter.has('draggable'), marker).subscribe(
            ([v, m]) => m.setDraggable(!!v)
        );

        zip(filter.has('visible'), marker).subscribe(
            ([v, m]) => (v ? m.show() : m.hide())
        );

        zip(filter.has('cursor'), marker).subscribe(
            ([v, m]) => m.setCursor(v)
        );

        zip(filter.has('animation'), marker).subscribe(
            ([v, m]) => m.setAnimation(v)
        );

        zip(filter.has('angle'), marker).subscribe(
            ([v, m]) => m.setAngle(v)
        );

        zip(filter.has('zIndex'), marker).subscribe(
            ([v, m]) => m.setzIndex(v)
        );

        zip(filter.has('shape'), marker).subscribe(
            ([v, m]) => m.setShape(v)
        );

        zip(filter.notEmpty('offset'), marker).subscribe(
            ([v, m]) => m.setOffset(this.pixelcreater.create(v))
        );

        zip(filter.notEmpty('position'), marker).subscribe(
            ([v, m]) => m.setPosition(v)
        );

        zip(filter.has('isTop'), marker).subscribe(
            ([v, m]) => m.setTop(!!v)
        );

        zip(filter.has('hidden'), marker).subscribe(
            ([v, m]) => (v ? m.hide() : m.show())
        );
    }

    ngAfterContentInit(): void {
        this.updateInfoWindow();
        this.infoWindowComponent.changes.subscribe(
            () => this.updateInfoWindow()
        );
    }

    /**
     * 获取已创建的 AMap.Marker 对象
     */
    get(): Observable<AMap.Marker<any>> {
        return this.markercreater.get();
    }

    private updateInfoWindow(): void {
        if (this.infoWindowComponent && this.inited) {
            if (this.infoWindowComponent.length > 1) {
                this.logger.e(TAG, 'Expected no more than 1 info window.');
                return;
            }

            const marker = this.markercreater.get();
            this.infoWindowComponent.forEach(
                component => {
                    component.hostMarker = marker;
                }
            );
        }
    }

    private updateInfoWindowPosition(): void {
        if (this.infoWindowComponent && this.inited) {
            this.infoWindowComponent.forEach(
                component => {
                    component.toggleOpen();
                }
            );
        }
    }
}
