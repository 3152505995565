import { Component } from '@angular/core';

import { AppService } from '../../application/service/app.service';

@Component({
    selector: 'application-user',
    templateUrl: "./user.component.html",
    styleUrls: ['./user.component.scss']
})
export class UserComponent {
    constructor(public app: AppService) {
    }
}
