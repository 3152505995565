<div class="section-shaped">
    <div class="page-header page-header-small header-filter">
        <div class="page-header-image" style="
            background-image: url('assets/img/loginbg8.jpeg?version={{ app.version.server?.version }}');
            background-color: white;
        "></div>
    </div>
</div>

<section class="upper">
    <div class="container">
        <div class="col-lg-5 col-md-8 mx-auto">
            <div class="card nobg" style="border: 1px solid; border-radius: 5px">
                <div class="card-header">
                    <div class="text-muted text-center">
                        <h4 style="display: inline-block; color: rgb(0, 0, 0)">
                            <strong> {{ "general.title" | translate }} </strong>
                        </h4>
                        <ng-container *ngIf="'general.webshowname' | translate">
                            <br>
                            <h2 style="display: inline-block; color: rgb(0, 0, 0)">
                                <strong> {{ "general.appname" | translate }} </strong>
                            </h2>
                        </ng-container>
                    </div>
                </div>
                <div class="card-body px-lg-5 py-lg-5">
                    <ng-container *puTemplateOutlet="'authForms'; context: { mode: auth.mode }"></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <div class="container">
            <div class="row align-items-center justify-content-md-between">
                <div class="m-auto">
                    <div class="copyright text-center">
                        {{ "general.copyright" | translate }} |
                        <a target="_blank" href="https://beian.miit.gov.cn">
                            <!-- <img priority placeholder [src]="('/assets/img/beian.png?version=' + app.version.server?.version) | safe : 'url'"/> -->
                            <span style="color: black">
                                {{"general.govregister" | translate}}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="position-absolute end-5 top-5">
    <ng-container puTemplateOutlet="mobileApp"></ng-container>
</div>