import { Optional, Input, ElementRef, Directive, Host, HostBinding } from '@angular/core';

import { PuSplitterComponent } from './pu.splitter.component';
import { PuSplitter } from './pu.splitter.types';

@Directive({
    selector: '[puSplitterPanel]',
    exportAs: 'PuSplitterPanel'
}) export class PuSplitterPanelDirective {
    private props = Prop.Of(this);

    @Input()
    visible: boolean = true;

    @Input()
    set min(v: number) {
        this.props.min = Math.min(100, Math.max(0, v ?? 100))
    }

    get min(): number {
        return this.props.min ?? 0;
    }

    @Input()
    set max(v: number) {
        this.props.max = Math.min(100, Math.max(0, v ?? 100));
    }

    get max(): number {
        return this.props.max ?? 100;
    }

    @Input()
    size: number = 0;

    @Input()
    @HostBinding('style.order')
    order: number = 0;

    @Input("direction")
    @HostBinding('class')
    get direction(): PuSplitter.direction {
        const { props: { direction = 'vertical' }, splitter: { direction: splitterDirection } = {}, } = this;
        return splitterDirection ?? (direction === 'vertical' ? 'vertical' : 'horizontal');
    }

    set direction(v: PuSplitter.direction | undefined) {
        this.props.direction = v;
    }

    @HostBinding('style.flex-basis')
    get flexbasis(): string {
        const { visible, splitter } = this;
        if (!visible) return '0%';
        if (!splitter) return '0%';

        const { size } = this;
        const { barsSize, panelsSize } = splitter;
        const displaysize = 100 * size / panelsSize;
        return `calc( ${displaysize}% - ${barsSize * displaysize / 100}px )`;
    }

    @HostBinding('style.flex-grow')
    readonly flexgrow: string = '0';

    @HostBinding('style.flex-shrink')
    readonly flexshrink: string = '0';

    get isvertical(): boolean {
        return this.direction == 'vertical'
    }

    get dragging(): boolean {
        return this.splitter?.dragging ?? false;
    }

    get sizepx(): number {
        const { isvertical: isv } = this;
        return this.elRef.nativeElement[
            isv ? 'offsetHeight' : 'offsetWidth'
        ];
    }

    constructor(
        private elRef: ElementRef,

        @Host() @Optional()
        public splitter?: PuSplitterComponent,
    ) {
    }
}
