
const PolyfillSlot = Prop.Slot<{ string?: boolean }>(
    Prop.symbol('PolyfillSlot'), parent => ({ ...(parent ?? {}) })
)
function _polyfill(wnd: Window) {
    const slot = PolyfillSlot.Of(wnd, true);
    const String: StringConstructor = (wnd as any)['String'];
    if (!String || slot.string) return;
    slot.string = true;

    Object.defineProperties(String.prototype, {
        format: {
            writable: true, enumerable: false, configurable: false,
            value(this: string, args: any): string {
                const len = arguments.length;

                switch (len) {
                    case 0: {
                        args = null;
                    } break;

                    case 1: {
                        if (!_.isObject(args) && !_.isArray(args) && args != undefined && args != null) {
                            args = [args];
                        }
                    } break;

                    default: {
                        args = arguments;
                    } break;
                }

                if (!args) {
                    return this.replace(/\{\s*[^\{\}]+\s*\}/gi, '?');
                }

                // all tags to be replace
                return Array.from(this.matchAll(/\{\s*([^\{\}]+)\s*\}/g)).reduce(
                    ({ tags, val }, [, tag]) => {
                        if (!tags[tag]) {
                            const regex = new RegExp(`\\{\\s*${tag}\\s*\\}`, "gm");
                            const value: any = _.property(tag)(args) ?? '?';
                            val = val.replace(regex, value);
                            tags[tag] = true;
                        }

                        return { tags, val };
                    }, { tags: <Record<string, true>>{}, val: this }
                ).val;
            }
        }
    })

    Object.defineProperties(String, {
        polyfill: {
            configurable: false, writable: false,
            value(wnd: Window) {
                _polyfill(wnd);
            }
        }
    })
}

_polyfill(window);

export { };

declare global {
    interface String {
        format(arg: { [P: string]: any }): string;
        format(...args: any[]): string;
    }

    interface StringConstructor {
        polyfill(wnd: Window): void;
    }
}
