import { Component, AfterViewInit, OnInit, Input, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';

import { Prj as TPrj, Exec as TExec } from '../../../application/service/backface/types';
import { PuTemplateService } from '../../../utils/puzzle/pu.template/pu.template';
import { ExcelService } from '../../../application/service/excel.service';
import { FieldProperty } from '../../../utils/view/model/field.property';
import { Backface } from '../../../application/service/backface/config';
import { AppService } from '../../../application/service/app.service';
import { Report } from '../../../application/service/backface/report';
import { GovEditor } from '../../../utils/view/model/form.editting';
import { Prj } from '../../../application/service/backface/prj';
import { Editor } from '../../../utils/libs/editor';

const Value = Editor.Value;

interface SumProjectData extends Prj.SumProjectData {
    month: {
        [id: string]: {
            yearlyinvested: string,
            yearlyassetinvested: string,
        }
    }
}

@Component({
    selector: "sumongoingproject, [sumongoingproject]",
    templateUrl: "./sumongoingproject.component.html",
    styleUrls: ['./sumongoingproject.component.scss'],
    providers: [PuTemplateService]
})
export class SumOngoingProjectComponent extends GovEditor.ToolBar implements OnInit, OnDestroy, AfterViewInit, ExcelService.ISummary {
    private readonly _props = Prop.Of(this);
    readonly noinputtxt: boolean = true;
    readonly noselectedProcess: boolean = true;
    readonly noselectedYear: boolean = false;

    get reporter(): Backface.AsyncReport {
        const { _props: props, httpClient } = this;
        return props.reporter || (
            props.reporter = new Backface.AsyncReport(httpClient)
        )
    }

    get fielder(): FieldProperty {
        const { _props: props, app } = this;
        return props.fielder || (
            props.fielder = new FieldProperty(app)
        )
    }

    // Summary Fields Parameter
    readonly _ = _;
    prjcount: number = 0;
    totalbudget: number = 0;
    yearbudget: number = 0;
    yearconsume: number = 0;
    yearlyassetinvested: number = 0;

    sumdisplayedColumns: string[] = [];
    sum2displayedColumns: string[] = [];
    get sumFields(): Editor.IFieldCol[] {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields || (props.sumFields = [
            {
                key: "total.key",
                title: lang.general.total,
                col: 3
            },
            {
                key: "total.value",
                get title() {
                    const { prjcount } = _this;
                    return `${prjcount}${lang.general.unit}`;
                },
                col: 3
            },
            {
                key: "amount.key",
                title: lang.budget.amount,
                col: 3
            },
            {
                key: "amount.value",
                get title() {
                    const { totalbudget, locale } = _this;
                    return formatNumber(totalbudget, locale, "1.0-0")
                },
                col: 3
            },
            {
                key: "yearamount.key",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.budget.amount}`
                },
                col: 3
            },
            {
                key: "yearamount.value",
                get title() {
                    const { yearbudget, locale } = _this;
                    return formatNumber(yearbudget, locale, "1.0-0");
                },
                col: 3
            },
            {
                key: "yearconsume.key",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.budget.consume}`;
                },
                col: 6
            },
            {
                key: "yearconsume.value",
                get title() {
                    const { yearconsume, locale } = _this;
                    return formatNumber(yearconsume, locale, "1.0-0");
                },
                col: 6
            },
            {
                key: "general.yearlyassetinvested",
                get title() {
                    const { selectedYear } = _this;
                    return `${selectedYear}${lang.general.annual}${lang.general.yearlyassetinvested}`;
                },
                col: 4
            },
            {
                key: "yearlyassetinvested.value",
                get title() {
                    const { yearlyassetinvested, locale } = _this;
                    return formatNumber(yearlyassetinvested, locale, "1.0-0");
                },
                col: 6
            },
            {
                key: "budgetunit",
                title: `${lang.budget.unit}：${lang.general.bunit}`,
                col: 2
            }
        ]);
    }

    get sumFields2(): Editor.IFieldCol[] {
        const { _props: props, app: { lang } } = this;
        const _this = this;

        return props.sumFields2 || (props.sumFields2 = [
            {
                key: "projectinfo",
                title: lang.summary.projectinfo,
                col: 18
            },
            {
                key: 'yearlyinvested',
                get title() {
                    return `${lang.summary.sumyearlyinvested}`;
                },
                col: 12
            },
            {
                key: 'yearlyassetinvested',
                get title() {
                    return `${lang.summary.sumyearlyassetinvested}`;
                },
                col: 12
            }
        ]);
    }

    // Main Table Header & Data Zone parameter
    displayedColumns: string[] = [];
    dataSource: SumProjectData[] = [];
    get colFields(): Editor.IFieldCols {
        const { _props: props, app: { lang: { project, actor, budget, general, problem } } } = this;

        return props.colFields || (props.colFields = XArray.create<Editor.IFieldCol, 'key'>('key', {
            key: "id",
            title: project.id,
            col: 1
        }, {
            key: "planname",
            title: project.plan,
            col: 1
        }, {
            key: "name",
            title: project.name,
            col: 1
        }, {
            key: "actorleaders",
            title: actor.leaders,
            col: 1
        }, {
            key: "owndept",
            title: project.owndept,
            col: 1
        }, {
            key: "owncompany",
            title: project.owncompany,
            col: 1
        }, {
            key: "location",
            title: project.location,
            col: 1
        }, {
            key: "constrtype",
            title: project.constrtype,
            col: 1
        }, {
            key: "description",
            title: project.description,
            cellcls: 'largewidth',
            col: 1
        }, {
            key: "budgetstartend",
            title: budget.startend,
            col: 1
        }, {
            key: "budgetamount",
            title: budget.amount,
            col: 1
        }, {
            key: "budgetyearamount",
            title: `${general.annual}${budget.amount}`,
            col: 1
        }, {
            key: "budgetyeartarget",
            title: `${general.annual}${budget.target}`,
            cellcls: 'largewidth',
            col: 1
        }, {
            key: "budgetyearconsume",
            title: `${general.annual}${budget.consume}`,
            col: 1
        }, {
            key: "budgetyearpercent",
            title: `${general.annual}${budget.percent}`,
            col: 1
        }, {
            key: "progresscomment",
            title: general.progresscomment,
            col: 1
        }, {
            key: "problems",
            title: problem.problem,
            col: 1
        }, {
            key: "memo",
            title: project.memo,
            col: 1
        }
        ));
    }

    @Input('toolbar')
    toolbar?: GovEditor.ISetBinder;

    @Input('module')
    // @ts-ignore
    module: Sys.IDatasetModule;

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        public app: AppService,
        public override router: ActivatedRoute,

        protected httpClient: HttpClient,
    ) {
        super(router, 'sumongoingproject', {
            selectedYear: app.dict.currentYear
        });
    }

    ngOnInit(): void {
        this.toolbar?.bindsource(this);

        this.app.dict.months.forEach(({ id, name }) => {
            this.colFields.push({
                key: `month.${id}.yearlyinvested`,
                title: name,
                col: 1
            });
        });

        this.app.dict.months.forEach(({ id, name }) => {
            this.colFields.push({
                key: `month.${id}.yearlyassetinvested`,
                title: name,
                col: 1
            });
        });
        
        this.sumdisplayedColumns = this.sumFields.map(p => p.key).filter(_.isString);
        this.sum2displayedColumns = this.sumFields2.map(p => p.key).filter(_.isString);
        this.displayedColumns = this.colFields.map(p => p.key).filter(_.isString);
        this.GetProjectData();
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        const { _props: { reporter } } = this;
        reporter?.ngOnDestroy();
    }

    override dosearch(): void {
        //TODO get data from backend service
        this.GetProjectData();
    }

    get searcherPool(): {
        key: string;
        value: TPrj.Pool;
        title?: string;
    } | undefined {
        const { app: { dict: { PrjPool } } } = this;
        const def = PrjPool.indexed[_.get(this.searcher, 'pool') ?? ''];
        return def ? def as any : undefined;
    }

    exportas(val: string) {
        const { searcherPool: { title: poolTitle = '' } = {}, selectedYear, app: { lang: { general: { orgname, annual }, tabs: { summaryongoingproject } } } } = this;
        ExcelService.generateExcel(`${orgname}${selectedYear}${annual}${poolTitle}${summaryongoingproject}`, this as ExcelService.ISummary);
    }

    GetProjectData() {
        const { searcherPool: { key: poolKey, value: poolValue } = {}, selectedYear } = this;
        const param = {
            year: [selectedYear, selectedYear],
            project: <{ id: string }[]>[],
            "pool": poolKey ? [poolKey] : undefined,
        };

        this.dataSource = [];
        this.prjcount = 0;
        this.totalbudget = 0;
        this.yearbudget = 0;
        this.yearconsume = 0;

        const { app: { prj: { project: projects } } } = this;
        projects.allOf({
            ...(poolValue != undefined ? { pool: poolValue } : {}),
            ispatched: false, cancelled: false
        }, project => {
            if (!project.budget?.checkYear(selectedYear!)) return;

            param.project.push({
                id: project.id
            });

            if (project.ispreproject) return;

            const sumproject: SumProjectData = {
                id: project.id,
                name: project.name,
                pool: Value.fromEnum(project.pool, TPrj.Pool),
                owncompany: project.owncompany,
                owndept: project.owndept,
                planname: project?.plan?.name,
                location: project.location,
                positonDistrict: project.positonDistrict,
                description: project.description,
                budgetstartend: project.budget.startend,
                constrtype: project.constrtypename,
                constrstatus: project.constrstatusname,
                budgetamount: formatNumber(project.budget.amount ?? 0, this.locale, "1.0-0"),
                budgetyearamount: formatNumber(project.getBudgetAmount(selectedYear!), this.locale, "1.0-0"),
                budgetyeartarget: project.getBudgetTarget(selectedYear!),
                actorheader: project.actor?.header?.name,
                actorleaders: project.getActorleaders(),
                actoractorsdept: project.getActoractorsdept(),
                actordomainsdept: project.getActordomainsdept(),
                actorsitesdept: project.getActorsitesdept(),
                memo: project.memo,
                budgetyearconsume: project.getBudgetConsume(selectedYear!).toString(),
                budgetyearpercent: project.getBudgetPercent(selectedYear!).toString() + "%",
                progresscomment: "",
                problems: "",
                month: {}
            };

            // Map sum of project
            this.app.dict.months.forEach(({ id }) => {
                sumproject.month[id] = {
                    yearlyassetinvested: '0',
                    yearlyinvested: '0',
                }
            });

            this.dataSource.push(sumproject);
            this.totalbudget += project.budget.amount ?? 0;
            this.yearbudget += project.getBudgetAmount(selectedYear!);
            this.yearconsume += project.getBudgetConsume(selectedYear!);
            this.yearlyassetinvested += 0; //Project don't implement this interface
        });

        this.prjcount = this.dataSource.length;

        this.reporter.report("/xretrieve/lastsupervise", (res: Report.IResult[]) => {
            if (res == null) { return; }

            res.forEach(spitem => {
                const pid = spitem?.project?.id;
                if (pid == null) { return; }

                const sumprj = this.dataSource.find(p => p.id == pid);
                if (sumprj == null) { return; }

                const yearlyassetinvested = spitem?.value?.yearlyassetinvested ?? 0;
                this.yearlyassetinvested += yearlyassetinvested;

                const { month: monthvals } = spitem;
                if (monthvals != undefined) {
                    //update yearly month cost
                    for (let index = 1; index <= 12; index++) {
                        const month = `${selectedYear}-${index < 10 ? '0' : ''}${index}`;
                        const monthval = monthvals.find(m => m.month == month);
                        if (monthval == null) continue;

                        const { consume } = monthval;
                        if (consume == null) continue;

                        const id = formatNumber(index, this.locale, "2.0-0");
                        const { yearlyassetinvested, yearlyinvested } = consume;
                        sumprj.month[id] = {
                            yearlyassetinvested: formatNumber(yearlyassetinvested ?? 0, this.locale, "1.0-0"),
                            yearlyinvested: formatNumber(yearlyinvested ?? 0, this.locale, "1.0-0"),
                        }
                    }
                }

                const pcomments = spitem?.value?.progresscomment;
                if (pcomments != undefined) {
                    sumprj.progresscomment = pcomments;
                }

                const problem: TExec.IProblem[] = spitem?.value?.problem;
                if (problem != undefined) {
                    const { app: { lang: { general: { fixed, notfixed } } } } = this;

                    sumprj.problems = problem?.map((p, index) => {
                        if (p == null) return;
                        return `${index + 1} ${p.problem}(${p.fixed ? fixed : notfixed})`;
                    }).filter(p => p != undefined).join('\n') ?? '';
                }
            });
        }).query(param);
    }

    getColspan(row: number, i: number): number {
        if (row === 1) {
            return this.sumFields[i].col ?? 1;
        }

        if (row === 2) {
            return this.sumFields2[i].col ?? 1;
        }

        if (row === 3) {
            return this.colFields[i].col ?? 1;
        }

        return 1;
    }

    get sumFieldsData(): Editor.IFieldCol[][] {
        return [this.sumFields, this.sumFields2];
    }

    get bodyDataSource(): Prj.SumProjectData[] {
        return this.dataSource;
    }

    get colFieldsData(): Editor.IFieldCols {
        return this.colFields;
    }
}
