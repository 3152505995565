<ng-template pu-mat-dialog-title>
  {{ data.object?.fullName }}
</ng-template>

<gov-table #govtablemodels class="position-absolute w-100 h-100 px-2" (onCommandTemplate)="
        $event.config.disableClose = false;
        $event.config.data.title = 'general.operate';
        $event.template = component
      " [datasets]="currentmodule"></gov-table>

<ng-template pu-mat-dialog-actions>
  <div mat-dialog-actions class="d-flex flex-row flex-nowrap justify-content-center align-items-stretch">
    <button mat-button [mat-dialog-close]="getSelected()" cdkFocusInitial>
      {{ "general.confirm" | translate }}
    </button>
  </div>
</ng-template>